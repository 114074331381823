import '../scss/offers-refresh.scss';
import Swiper from 'swiper/bundle';
import ShareOfferModal from './offers/offer-share';
import offerDetailsTemplate from './offers/tpl/offer-detail.template';
import offerShareTemplate from './offers/tpl/offer-share.template';
import { component } from 'common/js/library/component';
import Modal from 'common/js/util/coral-modal/modal';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { isUserLoggedIn } from 'common/js/data/services/account/user';

// Selectors
const isEditModeCLass = 'offers-refresh__wrapper--edit-mode';
const offerCardSlide = '.offer-card__swiper-slide';
const offerListSwiper = '.offers-list--carousel__swiper-wrapper';
const offersListWrapperSelector = '.offers-list__wrapper';

// Selectors by class names
const navCtaSelector = '.offers-subnav__list-cta';
const navCtaActiveClass = 'offers-subnav__list-cta--active';
const navListSelector = '.offers-subnav__list';
const offerCardClass = 'offer-card-refresh';
const offersListWrapper = 'offers-list__wrapper';
const offersListWrapperCollapsed = 'offers-list__wrapper--is-collapsed';
let topNavScroll;
let swiper;
const init = (config) => {
    topNavScroll = document.getElementById('top-nav')?.offsetTop;
    document.body.classList.remove('xf-web-container');
};
const instanceInit = async (rootEl) => {
    console.log('rootEl', rootEl);
    let hasCarousel = rootEl.dataset.hasCarousel === 'carousel-view' ? true : false;
    let hasSubnav = rootEl.dataset.hasSubnav == 'true' ? true : false;
    let showCounters = rootEl.dataset.showCounters == 'false' ? true : false;
    let isEditMode = rootEl.classList.contains(isEditModeCLass);

    showCounters && setOffersCount(rootEl, hasSubnav);
    !hasCarousel && handleExpandOfferList(rootEl);
    excludeEmptyOffersList(rootEl);
    setAutoBGColor(rootEl);
    initSubnav(rootEl, hasCarousel);
    initSwiper(rootEl, isEditMode, hasCarousel);

    cfnaCardHandler();

    await setIsLoggedIn(rootEl);
};

const setIsLoggedIn = async (rootEl) => {
    const isLoggedIn = await isUserLoggedIn();
    if (isLoggedIn && rootEl) {
        rootEl.classList.add('signed-in');
    }
    cardClickHandler(rootEl);
};

const setOffersCount = (rootEl, hasSubnav) => {
    let allCount = 0;

    [...rootEl.getElementsByClassName(offersListWrapper)].forEach((elem) => {
        const count = elem.querySelector('.offer-list__count--hidden').value;
        const id = elem.id;
        elem.querySelector('.offers-list__count').innerHTML = `(${count})`;
        if (hasSubnav && elem.dataset.offerCategory && id) {
            const navCta = rootEl.querySelector(`${navCtaSelector}[data-offer-list-id="${id}"]`);
            if (navCta) {
                navCta.querySelector('.offers-subnav__list-cta-count').innerHTML = `(${count})`;
            }
        }

        allCount += Number(count);
    });

    const allCountEl = rootEl.querySelector('.offers-subnav__all-count');
    const headingCountEl = rootEl.querySelector('.offers-subnav__heading-count');

    if (allCountEl) {
        allCountEl.innerHTML = `(${allCount})`;
    }

    if (headingCountEl) {
        headingCountEl.innerHTML = `${allCount}`;
    }
};

const handleExpandOfferList = (rootEl) => {
    [...rootEl.getElementsByClassName(offersListWrapperCollapsed)].forEach((elem) => {
        elem.querySelector('.offers-list__expand-cta').addEventListener('click', (evt) => {
            evt.preventDefault();
            elem.classList.remove('offers-list__wrapper--is-collapsed');
            evt.target.remove();
        });
    });
};

const setAutoBGColor = (rootEl) => {
    const bgAutoSelector = 'offers-list__wrapper--background-auto';
    const bgWhiteSelector = 'offers-list__wrapper--background-white';
    const bgGraySelector = 'offers-list__wrapper--background-gray';

    rootEl.querySelectorAll(`.${bgAutoSelector}:not(.exclude)`).forEach((elem, index) => {
        if (index % 2 === 0) {
            elem.classList.add(bgWhiteSelector);
        } else {
            elem.classList.add(bgGraySelector);
        }
    });
};

// This function is used to hide Empty navigation item and offerlist component.
const excludeEmptyOffersList = (rootEl) => {
    rootEl.querySelectorAll('.offers-list__component').forEach((el) => {
        if (
            el.querySelectorAll('.swiper-slide').length === 0 &&
            el.querySelectorAll('.offers-list__xf-container').length === 0
        ) {
            el.classList.add('exclude');
            let wrapperElement = el.querySelector('.offers-list__wrapper');
            wrapperElement.classList.add('exclude');
            let id = wrapperElement.id;
            let subNavElement = rootEl.querySelector(`.offers-subnav__list-cta[data-offer-list-id=${id}]`);
            subNavElement && subNavElement.parentElement.classList.add('exclude');
        }
    });
    rootEl.classList.remove('is-loading');
};

const initSwiper = (rootEl, isEditMode, hasCarousel) => {
    if (isEditMode || !hasCarousel) return;
    const offersLists = rootEl.querySelectorAll('.offers-list:not(.offers-list--carousel)');
    [...offersLists].forEach((offerListEl) => {
        const offerCards = offerListEl.querySelectorAll(offerCardSlide);
        [...offerCards].forEach((offerCardEl) => {
            offerCardEl.classList.remove('offer-card--featured');
            rootEl.querySelector(offerListSwiper).appendChild(offerCardEl);
        });
        offerListEl.closest('.component').remove();
    });

    const carouselElem = rootEl.querySelector('.offers-refresh__carousel-container');
    const carousel = carouselElem.querySelector('.offers-list--carousel');
    const carouselBtnNext = carouselElem.querySelector('.swiper-button-next');
    const carouselBtnPrev = carouselElem.querySelector('.swiper-button-prev');
    const carouselscrollBar = carouselElem.querySelector('.swiper-scrollbar');

    let breakpoints = {};

    const regularBreakpoints = {
        300: {
            slidesPerView: 1,
            slidesPerGroup: 1
        },
        640: {
            slidesPerView: 2,
            slidesPerGroup: 1
        },
        980: {
            slidesPerView: 3,
            slidesPerGroup: 1
        },
        1280: {
            slidesPerView: 4,
            slidesPerGroup: 1
        }
    };

    const localPagesBreakpoints = {
        300: {
            slidesPerView: 1,
            slidesPerGroup: 1
        },
        768: {
            slidesPerView: 2,
            slidesPerGroup: 1
        },
        980: {
            slidesPerView: 4,
            slidesPerGroup: 1
        },
        1200: {
            slidesPerView: 4,
            slidesPerGroup: 1
        }
    };

    const localPagesEBWBreakpoints = {
        300: {
            slidesPerView: 1,
            slidesPerGroup: 1
        },
        768: {
            slidesPerView: 1,
            slidesPerGroup: 1
        },
        980: {
            slidesPerView: 2,
            slidesPerGroup: 1
        },
        1200: {
            slidesPerView: 3,
            slidesPerGroup: 1
        }
    };

    if (document.body.classList.contains('local-seo-page')) {
        const hasEBW = document.getElementById('service-types-data');
        breakpoints = localPagesBreakpoints;
        if (hasEBW) {
            breakpoints = localPagesEBWBreakpoints;
        }
    } else {
        breakpoints = regularBreakpoints;
    }

    swiper = new Swiper(carousel, {
        loop: false,
        navigation: {
            nextEl: carouselBtnNext,
            prevEl: carouselBtnPrev
        },

        scrollbar: {
            el: carouselscrollBar,
            draggable: true,
            hide: false
        },
        breakpoints: breakpoints
    });
};

const initSubnav = (rootEl, hasCarousel) => {
    let threshold = [];
    let section = 1 / 40;
    let nextThreshold = section;
    let stickySubnavHeight = '';
    if (rootEl.querySelector('.offers-subnav--sticky')) {
        stickySubnavHeight = rootEl.querySelector('.offers-subnav--sticky').offsetHeight;
    }

    stickySubnavHeight += 1;
    while (nextThreshold <= 1) {
        threshold.push(parseFloat(nextThreshold.toFixed(2)));
        nextThreshold += section;
    }

    const observerConfig = {
        rootMargin: '60px 0px 0px 0px',
        threshold: threshold
    };

    // If offers components is not an carousel then clicking on subnav items will scroll to the particular section
    if (!hasCarousel) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const id = entry.target.getAttribute('id');
                const cta = rootEl.querySelector(`${navCtaSelector}[data-offer-list-id='${id}']`);

                if (
                    entry.isIntersecting &&
                    entry.boundingClientRect.top <= stickySubnavHeight &&
                    entry.boundingClientRect.bottom > stickySubnavHeight
                ) {
                    rootEl.querySelectorAll(`${navCtaSelector}[data-offer-list-id]`).forEach((elem) => {
                        elem.classList.remove(navCtaActiveClass);
                    });

                    if (cta) {
                        cta.classList.add(navCtaActiveClass);
                        const navList = rootEl.querySelector(navListSelector);
                        if (navList) {
                            rootEl.querySelector(`.${navCtaActiveClass}`)?.offsetLeft - 20;
                        }
                        return;
                    }

                    rootEl
                        .querySelector(`${navCtaSelector}[data-offer-list-id='all-offers']`)
                        ?.classList.add(navCtaActiveClass);
                    const subnavList = rootEl.querySelector(navListSelector);
                    if (subnavList) {
                        subnavList.scrollLeft = rootEl.querySelector(`.${navCtaActiveClass}`)?.offsetLeft - 20;
                    }
                }
            });
        }, observerConfig);

        rootEl.querySelectorAll(offersListWrapperSelector).forEach((section) => {
            observer.observe(section);
        });

        rootEl.querySelectorAll(navCtaSelector).forEach((elem) => {
            elem.addEventListener('click', (e) => {
                e.preventDefault();
                const target = `#${e.currentTarget.dataset.offerListId}`;
                const scrollTop = rootEl.querySelector(target)
                    ? rootEl.querySelector(target).offsetTop - 60
                    : topNavScroll;

                window.scrollTo(0, scrollTop);

                let currentTarget = e.currentTarget;
                setTimeout(() => {
                    rootEl.querySelectorAll(`${navCtaSelector}[data-offer-list-id]`).forEach((navCta) => {
                        navCta.classList.remove(navCtaActiveClass);
                    });

                    currentTarget.classList.add(navCtaActiveClass);
                    rootEl.querySelector('.offers-subnav__list').scrollLeft =
                        rootEl.querySelector(`.${navCtaActiveClass}`).offsetLeft - 20;
                }, 1000);

                setAdobeDataLayer({ event: 'offer.filter_click', offerFilter: e.currentTarget.dataset.offerCategory });

                return false;
            });
        });
        return;
    }
    // If offers components is a carousel then clicking on subnav items will make carousel scroll to selected category
    rootEl.querySelectorAll(navCtaSelector).forEach((elem) => {
        elem.addEventListener('click', (e) => {
            e.preventDefault();

            const target = e.currentTarget;
            rootEl.querySelectorAll(`${navCtaSelector}[data-offer-list-id]`).forEach((elemA) => {
                elemA.classList.remove(navCtaActiveClass);
            });
            target.classList.add(navCtaActiveClass);
            rootEl.querySelector(navListSelector).scrollLeft =
                rootEl.querySelector(`.${navCtaActiveClass}`).offsetLeft - 20;

            const offerCategory = target.dataset.offerCategory;
            const firstCategoryCard = rootEl.querySelector(
                `.offer-card__swiper-slide[data-offer-category='${offerCategory}']`
            );
            const slideToIndex =
                offerCategory === 'all-offers'
                    ? 0
                    : [...firstCategoryCard.parentElement.children].indexOf(firstCategoryCard);
            swiper.slideTo(slideToIndex, 500, false);
        });
    });
};

const cardClickHandler = (rootEl) => {
    const isSignedIn = rootEl.classList.contains('signed-in');
    [...rootEl.getElementsByClassName(offerCardClass)].forEach((elem) => {
        const isLIDPromo = elem.classList.contains('offer-sign-in-only');
        if (!isLIDPromo || (isLIDPromo && isSignedIn)) {
            elem.addEventListener('click', (evt) => {
                let title = elem.querySelector('.offer-card-refresh__title')?.dataset?.modalTitle || '';
                let price = elem.querySelector('.offer-card-refresh__discount-copy')?.dataset?.modalPrice || '';
                let subText = elem.querySelector('.offer-card-refresh__subtitle')?.dataset?.modalSubText || '';
                let shareTitleValue = '';
                if (title) {
                    shareTitleValue += title;
                }
                if (price) {
                    shareTitleValue += ' ' + price;
                }
                if (subText) {
                    shareTitleValue += ' ' + subText;
                }
                if (evt.target.className.includes('offer-card-refresh__phone-cta')) {
                    const data = {
                        friendlyId: elem.dataset.friendlyId,
                        title: shareTitleValue
                    };
                    openShareOfferModal(data);
                    setAdobeDataLayer({
                        event: 'offer.modal_click',
                        modal_action: 'send_to_phone',
                        offerID: data.friendlyId,
                        offerName: data.title
                    });
                } else if (evt.target.className.includes('offer-card-refresh__print-cta')) {
                    const data = {
                        friendlyId: elem.dataset.friendlyId,
                        title: elem.querySelector('.offer-card-refresh__title')?.innerHTML || ''
                    };
                    setAdobeDataLayer({
                        event: 'offer.print',
                        offerID: data.friendlyId,
                        offerName: window.analytics_utils.getCouponData(data.friendlyId).thumbTitle
                    });
                    return true;
                } else if (evt.target.className.includes('offer-card-refresh__details-cta')) {
                    return true;
                } else if (evt.target.className.includes('signin-button')) {
                    return true;
                } else {
                    const offerImageElement = elem.querySelector('.offer-card-refresh__offer-image img');
                    const data = {
                        isFeatured: false,
                        friendlyId: elem.dataset.friendlyId,
                        title: title,
                        subText: subText,
                        modalTitle: elem.querySelector('.offer-card-refresh__title')?.dataset?.modalTitle || '',
                        modalSubText: elem.querySelector('.offer-card-refresh__subtitle')?.dataset?.modalSubText || '',
                        modalSubText1:
                            elem.querySelector('.offer-card-refresh__subtitle1')?.dataset?.modalSubText1 || '',
                        modalSubText2:
                            elem.querySelector('.offer-card-refresh__subtitle2')?.dataset?.modalSubText2 || '',
                        modalOfferDetails:
                            elem.querySelector('.offer-card-refresh__summary')?.dataset?.modalOfferDetails || '',
                        price: price,
                        shareModalTitle: shareTitleValue,
                        modalPrice: elem.querySelector('.offer-card-refresh__discount-copy')?.dataset?.modalPrice || '',
                        modalOfferDescription:
                            elem.querySelector('.offer-card-refresh__body-copy')?.dataset?.modalOfferDescription || '',
                        rebateText: elem.querySelector('.offer-card-refresh__body-copy')?.dataset?.rebateText || '',
                        landingUrl: elem.querySelector('.offer-card-refresh__body-copy')?.dataset?.landingUrl || '',
                        summary: elem.querySelector('.offer-card-refresh__summary')?.innerHTML,
                        validDateDays: elem.querySelector('.offer-card-refresh__valid-date-days').value,
                        validDateRange: elem.querySelector('.offer-card-refresh__valid-date-range').value,
                        disclaimer: elem.querySelector('.offer-card-refresh__disclaimer').value,
                        printUrl: elem.querySelector('.offer-card-refresh__print-cta').getAttribute('href'),
                        defaultOfferImage: offerImageElement?.src || offerImageElement?.dataset?.srcOffer || ''
                    };

                    const modal = new Modal({
                        modalId: data.friendlyId,
                        classNames: 'offer-modal offer-modal--details',
                        content: offerDetailsTemplate(data)
                    });
                    const offerOverlay = modal.modalElem;
                    setAdobeDataLayer({
                        event: 'offer.modal_open',
                        offerID: data.friendlyId,
                        offerName: window.analytics_utils.getCouponData(data.friendlyId).thumbTitle
                    });

                    offerOverlay?.querySelector('.offer-details__cta-phone').addEventListener('click', (ctaEvt) => {
                        ctaEvt.preventDefault();
                        const modalData = {
                            friendlyId: offerOverlay?.querySelector('.offer-details__offer')?.dataset?.friendlyId || '',
                            title:
                                offerOverlay?.querySelector('.offer-details__offer-header')?.dataset?.shareModalTitle ||
                                ''
                        };
                        openShareOfferModal(modalData);
                        setAdobeDataLayer({
                            event: 'offer.modal_click',
                            modal_action: 'send_to_phone',
                            offerID: modalData.friendlyId,
                            offerName: modalData.title
                        });
                    });

                    offerOverlay?.querySelector('.offer-details__cta-print').addEventListener('click', (ctaEvt) => {
                        let printTitle = offerOverlay?.querySelector('.offer-details__offer-title');
                        let printPrice = offerOverlay?.querySelector('.offer-details__offer-price');
                        let printSubtitle = offerOverlay?.querySelector('.offer-details__offer-subtitle');
                        let printSubtitle1 = offerOverlay?.querySelector('.offer-details__offer-subtitle1');
                        let printSubtitle2 = offerOverlay?.querySelector('.offer-details__offer-subtitle2');
                        printTitle = printTitle ? printTitle.innerHTML : '';
                        printPrice = printPrice ? printPrice.innerHTML : '';
                        printSubtitle = printSubtitle ? printSubtitle.innerHTML : '';
                        printSubtitle1 = printSubtitle1 ? printSubtitle1.innerHTML : '';
                        printSubtitle2 = printSubtitle2 ? printSubtitle2.innerHTML : '';
                        const modalData = {
                            friendlyId: offerOverlay.dataset.friendlyId,
                            title: `${printTitle} ${printPrice} ${printSubtitle} ${printSubtitle1} ${printSubtitle2}`
                        };
                        setAdobeDataLayer({
                            event: 'offer.print',
                            modal_action: 'print',
                            offerID: modalData.friendlyId,
                            offerName: window.analytics_utils.getCouponData(modalData.friendlyId).thumbTitle
                        });
                    });
                }
            });
        }
    });
};

const cfnaCardHandler = () => {
    const xfContainers = document.querySelectorAll('.offers-list__xf-container');
    [...xfContainers].forEach((element) => {
        const swiperWrapper = element.parentElement.querySelector('.offers-list__swiper-wrapper');
        swiperWrapper?.appendChild(element);
    });
};

const openShareOfferModal = (data) => {
    new Modal({
        modalId: data.friendlyId,
        classNames: 'offer-modal offer-modal__share-text-email',
        content: offerShareTemplate(data)
    });
    ShareOfferModal('[data-share-offer-modal]');
};

component({
    selector: '.offers-refresh__wrapper',
    init,
    instanceInit,
    name: 'OffersRefresh',
    canMutate: true,
    canReinit: true
});
