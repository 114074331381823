import { siblings } from 'common/js/library/util';

/**
 * Scrolls to the position of the element specified in the unordered list
 * @param {Element} el
 * @param {string} childSelector
 */
const scrollTopElement = (el, childSelector, delay = 250) => {
    if (!(el instanceof HTMLElement)) {
        return;
    }
    setTimeout(() => {
        const child = el.querySelector(`li[data-value="${childSelector}"]`);

        if (child) {
            const childTop = child.offsetTop;

            const sibs = siblings(child);
            const childSiblingTop = sibs.length ? sibs[0].offsetTop : 0;
            child.click();
            child.parentNode.scrollTop = childTop - childSiblingTop;
        }
    }, delay);
};

export { scrollTopElement };
