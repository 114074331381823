import '../scss/article-card.scss';
import { component } from 'common/js/library/component';

const instanceInit = (rootEl) => {
    console.log('ArticleCard init');

    if (rootEl.classList.contains('article-card--linkable')) {
        rootEl.addEventListener('click', handleEvent);
    }

    stripPrimaryCategory(rootEl);
};

const stripPrimaryCategory = (rootEl) => {
    let category = rootEl.querySelector('.article-card__category')?.innerText;
    if (category) {
        let currentCat = rootEl.getAttribute('data-current-category');
        let categoryArr = category.indexOf(',') ? category.split(',') : [category];
        let setCategory = categoryArr.filter((cat) => cat === currentCat)[0];

        rootEl.querySelector('.article-card__category').innerText = setCategory ? setCategory : categoryArr[0];
    }
};

const handleEvent = (evt) => {
    switch (evt.type) {
        case 'click':
            onCardClick(evt);
            break;
        default:
            throw TypeError('ArticleCard._handleEvent: Event type not recognized.');
    }
};

/**
 * Handles blog article card click
 * @memberof ArticleCard
 * @private
 * @param evt event
 * @return {void}
 */
const onCardClick = (evt) => {
    let ct = evt.currentTarget;
    let btn = ct?.querySelector('.article-card__btn');
    if (btn) {
        window.location.href = `${window.location.origin}${btn.getAttribute('href')}`;
    }
};

component({
    selector: '.article-card',
    instanceInit,
    name: 'ArticleCard'
});
