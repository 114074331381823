import { queryStringify, responseError, HTTPHeaders } from '../serviceUtilities';

export const getStoreApi = async (dataPackage) => {
    try {
        const url = `/bsro/services/store/location/get-list-by-zip${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const sendDataApi = async (dataPackage) => {
    try {
        const url = `/bsro/services/contact/contactus`;
        const response = await fetch(url, {
            method: 'POST',
            headers: HTTPHeaders(),
            body: JSON.stringify( dataPackage )
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};
