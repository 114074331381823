const labelG1 = 'good-better-best';
const labelG2 = 'in-stock';
const labelG3 = 'low-inventory';
const sublabel1 = 'good';
const sublabel2 = 'better';
const sublabel3 = 'best';

/**
 * Tracks the number of results in each inventory grouping (gbb, in stock, low stock)
 * @param {element} el - root element
 */
export const setGroupTracking = (el) => {
    if (!el) return;

    // give page a chance to render the class names to the cards
    setTimeout(() => {
        if (
            window.digitalData &&
            Array.isArray(window.digitalData.event) &&
            window.digitalData.event[0] &&
            window.digitalData.event[0].eventName === 'tires.results'
        ) {
            const g1 = [...el.querySelectorAll('.gbb-card')].length;
            const g2 = [...el.querySelectorAll('.in-stock-card')].length;
            const g3 = [...el.querySelectorAll('.call-card')].length;
            // NOTE: old implementation utilized an older tracking object and appended newer data to it
            //       newer implementation sends a new tracking object
            // window.digitalData.event[0].attributes['inventory-heading'] = [
            //     `${labelG1}|${g1}`,
            //     `${labelG2}|${g2}`,
            //     `${labelG3}|${g3}`
            // ];
            let data = {
                'search-type': window.digitalData.event[0].attributes['search-type'],
                year: window.digitalData.event[0].attributes.year,
                make: window.digitalData.event[0].attributes.make,
                model: window.digitalData.event[0].attributes.model,
                subModel: window.digitalData.event[0].attributes.subModel,
                'x-section': window.digitalData.event[0].attributes['x-section'],
                aspect: window.digitalData.event[0].attributes.aspect,
                'rim-size': window.digitalData.event[0].attributes['rim-size'],
                'inventory-heading': [`${labelG1}|${g1}`, `${labelG2}|${g2}`, `${labelG3}|${g3}`]
            };
            window._satellite.track('tires.results.inventory', data);
        }
    }, 1);
};

/**
 * Tracks result card click
 * @param {element} el - root element
 */
export const setCardClickTracking = (el) => {
    if (!el) return;

    // give page a chance to render the class names to the cards
    setTimeout(() => {
        if (
            window.digitalData &&
            Array.isArray(window.digitalData.event) &&
            window.digitalData.event[0] &&
            window.digitalData.event[0].eventName === 'tires.results'
        ) {
            el.addEventListener('click', (e) => {
                const productLink = e.target.closest('.product-name a');
                const btn = e.target.closest('.quote-annex-cta .btn');

                if (productLink || btn) {
                    const card = e.target.closest('.result-card');
                    const rating = card?.querySelector('.average-rating');
                    const reviews = card?.querySelector('.review-count');
                    const link_type = productLink ? 'tire_detail' : btn ? 'tire_quote' : '';

                    if (!card) return;

                    const invHeading = card.classList.contains('gbb-card')
                        ? labelG1
                        : card.classList.contains('in-stock-card')
                          ? labelG2
                          : card.classList.contains('call-card')
                            ? labelG3
                            : '';

                    const invSubheading =
                        invHeading === labelG1
                            ? card.classList.contains('gbb-good')
                                ? sublabel1
                                : card.classList.contains('gbb-better')
                                  ? sublabel2
                                  : card.classList.contains('gbb-best')
                                    ? sublabel3
                                    : ''
                            : '';
                    const scoreNumber = rating ? rating.textContent : '';
                    const numberOfReviews = reviews ? reviews.textContent.replace(/\D+/g, '') : '';

                    let data = {
                        'search-type': window.digitalData.event[0].attributes['search-type'],
                        'inventory-heading': invHeading,
                        'inventory-subheading': invSubheading,
                        productID: card.dataset.articleId,
                        year: window.digitalData.event[0].attributes.year,
                        make: window.digitalData.event[0].attributes.make,
                        model: window.digitalData.event[0].attributes.model,
                        subModel: window.digitalData.event[0].attributes.subModel,
                        'x-section': window.digitalData.event[0].attributes['x-section'],
                        aspect: window.digitalData.event[0].attributes.aspect,
                        'rim-size': window.digitalData.event[0].attributes['rim-size'],
                        link_type,
                        scoreNumber,
                        numberOfReviews
                    };

                    window._satellite.track('tires.results.click', data);
                }
            });
        }
    }, 1);
};
