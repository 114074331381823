import '../scss/maintenance-schedule.scss';
import Swiper from 'swiper/bundle';
import { queryStringify, responseError, HTTPHeaders } from 'common/js/data/services/serviceUtilities';
import { component } from 'common/js/library/component';
import { getCookie } from 'common/js/library/util';
import Handlebars from 'common/js/library/handlebar';

const initMilesSwiper = (rootEl) => {
    const milesSwiper = rootEl.querySelector('.miles-swiper');
    const swiperScrollBar = milesSwiper.querySelector('.swiper-scrollbar');
    const numSlides = getNumSlides(milesSwiper, 12, 8, 3);
    const swiperSlides = rootEl.querySelectorAll('.miles-carousel .swiper-slide');

    [...swiperSlides].forEach((el) => {
        milesSwiper.querySelector('.swiper-wrapper').insertAdjacentElement('beforeend', el);
    });

    rootEl.querySelector('.miles-carousel').remove();

    const swiper = new Swiper(milesSwiper, {
        loop: false,
        spaceBetween: 20,
        centeredSlides: swiperSlides.length === 1 ? true : false,
        scrollbar: {
            el: swiperScrollBar,
            draggable: true,
            hide: true
        },
        breakpoints: {
            320: {
                slidesPerView: numSlides.phone,
                slidesPerGroup: numSlides.phone
            },
            768: {
                slidesPerView: numSlides.tablet,
                slidesPerGroup: numSlides.tablet
            },
            1024: {
                slidesPerView: numSlides.desktop,
                slidesPerGroup: numSlides.desktop
            }
        }
    });
    return swiper;
};

const initDetailsSwiper = (rootEl) => {
    const detailsSwiper = rootEl.querySelector('.details-swiper');
    const carouselScrollBar = detailsSwiper.querySelector('.swiper-scrollbar');
    const numSlides = getNumSlides(detailsSwiper, 12, 8, 3);
    const swiperSlides = rootEl.querySelectorAll('.details-carousel .swiper-slide');

    [...swiperSlides].forEach((el) => {
        detailsSwiper.querySelector('.swiper-wrapper').insertAdjacentElement('beforeend', el);
    });

    rootEl.querySelector('.details-carousel').remove();

    const swiper = new Swiper(detailsSwiper, {
        loop: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        spaceBetween: 20,
        scrollbar: {
            el: carouselScrollBar,
            draggable: true,
            hide: true
        },
        breakpoints: {
            320: {
                slidesPerView: numSlides.phone,
                slidesPerGroup: numSlides.phone
            },
            768: {
                slidesPerView: numSlides.tablet,
                slidesPerGroup: numSlides.tablet
            },
            1024: {
                slidesPerView: numSlides.desktop,
                slidesPerGroup: numSlides.desktop
            }
        }
    });
    return swiper;
};

const initPeriodicSwiper = (rootEl) => {
    const periodicSwiper = rootEl.querySelector('.periodic-swiper');
    if (periodicSwiper != null) {
        const carouselScrollBar = periodicSwiper.querySelector('.periodic .swiper-scrollbar');
        const numSlides = getNumSlides(periodicSwiper, 4, 3, 1);
        const swiperSlides = rootEl.querySelectorAll('.periodic-carousel .swiper-slide');

        [...swiperSlides].forEach((el) => {
            periodicSwiper.querySelector('.swiper-wrapper').insertAdjacentElement('beforeend', el);
        });

        rootEl.querySelector('.periodic-carousel').remove();
        new Swiper(periodicSwiper, {
            loop: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            spaceBetween: 20,
            scrollbar: {
                el: carouselScrollBar,
                draggable: true,
                hide: true
            },
            breakpoints: {
                320: {
                    slidesPerView: numSlides.phone,
                    slidesPerGroup: numSlides.phone,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: numSlides.tablet,
                    slidesPerGroup: numSlides.tablet,
                    spaceBetween: 15
                },
                1024: {
                    slidesPerView: numSlides.desktop,
                    slidesPerGroup: numSlides.desktop
                }
            }
        });
    }
};

const instanceInit = (rootEl) => {
    const bossMileage = getCookie('bsro.ui.mileage') || 15000;
    const serviceType = rootEl.querySelector('#serviceType');
    let detailsSwiper;
    let milesSwiper;

    const getDetailsSwiper = () => {
        return detailsSwiper;
    };

    const setDetailsSwiper = (value) => {
        detailsSwiper = value;
    };

    const getMilesSwiper = () => {
        return milesSwiper;
    };

    const setMilesSwiper = (value) => {
        milesSwiper = value;
    };

    const _data = {
        serviceType: serviceType?.value || 'ALL',
        ymm: window.siteProfile.vehicles.main.ymm,
        trim: window.siteProfile.vehicles.main.trim
    };

    render(rootEl, _data, bossMileage, { getDetailsSwiper, setDetailsSwiper, getMilesSwiper, setMilesSwiper });

    // Periodic Carousel
    initPeriodicSwiper(rootEl);

    // event handlers
    serviceType?.addEventListener('change', (evt) =>
        handleServiceTypeChange(evt, rootEl, {
            bossMileage,
            getDetailsSwiper,
            setDetailsSwiper,
            getMilesSwiper,
            setMilesSwiper
        })
    );

    setVehicleInformation(rootEl);

    if (window.storeOnlineAppointmentActiveFlag == 0) {
        const callStoreMessageEl = rootEl.querySelector('.store-message.call-store-message');
        callStoreMessageEl && (callStoreMessageEl.style.display = 'block');
    } else {
        const apptBtnEl = rootEl.querySelector('.schedule-appt-btn');
        apptBtnEl && (apptBtnEl.style.display = 'block');
    }
};

const handleMilesSwiperEvent = (evt, props) => {
    const ct = evt.target;
    const slide = ct.closest('.swiper-slide');
    const slides = slide.parentElement?.parentElement?.querySelectorAll('.swiper-slide') || [];
    const index = [...slides].indexOf(slide);

    evt.preventDefault();
    goToSlide(index, props, 'miles');
};

const handleServiceTypeChange = (evt, rootEl, props) => {
    const { bossMileage } = props;
    const serviceType = evt.currentTarget.value;

    const _data = {
        serviceType: serviceType || 'ALL',
        ymm: window.siteProfile.vehicles.main.ymm,
        trim: window.siteProfile.vehicles.main.trim
    };

    render(rootEl, _data, bossMileage, props);
};

const getMaintenanceMilestones = async (paramData) => {
    const url = `/bsro/services/vehicle/get-maintenance-milestones${queryStringify(paramData)}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const render = async (rootEl, _data, bossMileage, props) => {
    const { getDetailsSwiper, setDetailsSwiper, getMilesSwiper, setMilesSwiper } = props;
    const templateMileage = document.querySelector('[data-mileage-template]').cloneNode(true);
    const templateDetails = document.querySelector('[data-mileage-content-template]').cloneNode(true);
    const container = rootEl.querySelector('.carousel-container');
    const coralWaitContainer = rootEl.querySelector('.coral-wait-container');

    // display load state
    coralWaitContainer && (coralWaitContainer.style.display = 'block');

    // service request for maintenance schedule
    const response = await getMaintenanceMilestones(_data);
    if (response.success === 'true' && parseInt(response.status) === 200) {
        let servicesData = (response.data && response.data.data) || {};

        // remove old carousels and event listeners if they exist
        rootEl.querySelector('.miles-swiper')?.remove();
        rootEl.querySelector('.details-swiper')?.remove();

        // append mileage template
        let templateHTML = templateMileage.cloneNode(true).innerHTML;
        let template = Handlebars.compile(templateHTML);
        let templateOutput = template(servicesData);
        container.insertAdjacentHTML('beforeend', templateOutput);

        // append details template
        templateHTML = templateDetails.cloneNode(true).innerHTML;
        template = Handlebars.compile(templateHTML);
        templateOutput = template(servicesData);
        container.insertAdjacentHTML('beforeend', templateOutput);

        // Miles Carousel
        setMilesSwiper(initMilesSwiper(rootEl));
        const milesSwiper = rootEl.querySelector('.miles-swiper');
        const milesSwiperSlides = milesSwiper ? milesSwiper.querySelectorAll('.slide') : [];
        let props = {
            milesSwiperSlides,
            getDetailsSwiper,
            setDetailsSwiper,
            getMilesSwiper,
            setMilesSwiper
        };

        // Details Carousel
        setDetailsSwiper(initDetailsSwiper(rootEl));
        const detailsSwiper = rootEl.querySelector('.details-swiper');
        const detailsSwiperSlides = detailsSwiper ? detailsSwiper.querySelectorAll('.slide') : [];

        // Select the starting slide
        const mileageIndex = getIndexByMileage(bossMileage, milesSwiperSlides);
        props = { ...props, detailsSwiper, detailsSwiperSlides };

        goToSlide(mileageIndex, props, 'miles');

        // remove the loading state
        coralWaitContainer && (coralWaitContainer.style.display = 'none');

        // add event listeners
        milesSwiper?.addEventListener('click', function (evt) {
            if (evt.target.closest('.slide a')) {
                handleMilesSwiperEvent(evt, props);
            }
        });

        getDetailsSwiper?.().on('slideChange', function () {
            handleDetailsSwiperChange(this, props, 'details');
        });

        const handleDetailsSwiperChange = (data, props) => {
            console.log(data.realIndex);
            goToSlide(data.realIndex, props, 'details');
        };
    }
};

/**
 *
 * @param {jquery} el
 * @param {number} d - number of slides to show on desktop
 * @param {number} t - number of slides to show on tablet
 * @param {number} p - number of slides to show on phone
 */
const getNumSlides = (el, d, t, p) => {
    return {
        desktop: Number(el.getAttribute('data-slides-desktop')) || d,
        tablet: Number(el.getAttribute('data-slides-tablet')) || t,
        phone: Number(el.getAttribute('data-slides-phone')) || p
    };
};

const goToSlide = (idx, props, slider) => {
    const { milesSwiperSlides, detailsSwiperSlides, getDetailsSwiper, getMilesSwiper } = props;
    let swiper;
    if (slider == 'miles') {
        swiper = getDetailsSwiper();
    } else {
        swiper = getMilesSwiper();
    }

    swiper.slideTo(idx, 500, false);
    setActive(idx, milesSwiperSlides, detailsSwiperSlides);
};

const setActive = (idx, milesSwiperSlides, detailsSwiperSlides) => {
    const activeClass = 'active';

    [...milesSwiperSlides].forEach((slide) => {
        slide.classList.remove(activeClass);
    });
    milesSwiperSlides[idx]?.classList.add(activeClass);

    [...detailsSwiperSlides].forEach((slide) => {
        slide.classList.remove(activeClass);
    });
    detailsSwiperSlides[idx]?.classList.add(activeClass);
};

/**
 * Compares mileage carousel labels with a provided mileage and returns the index of the most appropriate slide.
 * If the mileage isn't specified it will default to index = 1
 * @param {string} mileage - mileage value that can be converted to a number
 * @param {jquery} slides
 */
const getIndexByMileage = (mileage, slides) => {
    if (!mileage || !slides) return 1;

    mileage = Number(mileage);

    // find first slide that is larger than the provided mileage
    for (let i = 0; i < slides.length; i++) {
        let el = slides[i];
        let node = el.querySelector('.mileage');
        let nodeValue = (node && Number(node.innerText)) || 0;

        if (nodeValue > mileage) return i;
    }

    // if provided mileage is larger than any value, use largest available index
    return slides.length - 1;
};

const setVehicleInformation = (rootEl, _tmpVehicle) => {
    _tmpVehicle = _tmpVehicle ? _tmpVehicle : window.siteProfile.vehicles.main;

    if (_tmpVehicle) {
        var vehicleInfo = `Your ${_tmpVehicle.year} ${_tmpVehicle.make} ${_tmpVehicle.model} ${_tmpVehicle.trim} Scheduled Maintenance`;
        rootEl.querySelector('h1.title').textContent = vehicleInfo;
    }
};

component({
    instanceInit,
    selector: '.maintenance-schedule',
    name: 'MaintenanceSchedule'
});
