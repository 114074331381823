// accepts a store number and array of service IDs,
// fetches the total service duration, and updates the total service time displayed on the page
import { fetchTotalServiceDuration } from 'common/js/util/fetchTotalServiceDuration';
import { formatTimeToHrsMins } from 'common/js/util/date-time/formatTime';

const updateTotalServiceTime = async (storeNumber, serviceIdList, serviceStep) => {
    const totalServiceTime = (await fetchTotalServiceDuration(storeNumber, serviceIdList)) || 0;
    let totalTimeCopy = '';

    if (totalServiceTime.error) return totalServiceTime.error;

    if (totalServiceTime) {
        totalTimeCopy = 'Total Service Time: est. ' + formatTimeToHrsMins(totalServiceTime);
    }
    if (serviceStep?.querySelector('#total-service-duration')) {
        serviceStep.querySelector('#total-service-duration').innerText = totalTimeCopy;
    } else {
        const durationCounter = document.createElement('span');
        durationCounter.id = 'total-service-duration';
        durationCounter.classList.add('total-service-duration');
        durationCounter.innerText = totalTimeCopy;
        serviceStep?.querySelector('.service-items.list ul')?.after(durationCounter);
    }
    if (!serviceIdList.length || !totalServiceTime) {
        serviceStep?.querySelector('#total-service-duration')?.remove();
    }

    return totalServiceTime;
};

export { updateTotalServiceTime };
