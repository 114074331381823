import { getUrlParameter } from 'common/js/library/util';

const notifications = () => {
    const notice = getUrlParameter('notification');

    // check if notifications exist on global level first
    if (!window.bsroNotifications || !window.bsroNotifications[notice]) {
        window.bsroNotifications = {
            bad_store: {
                heading: 'Store Not Found',
                message: 'We could not find the store you selected. Please choose your location.'
            },
            bad_vehicle: {
                heading: 'Vehicle Not Found',
                message: 'We could not find the vehicle you selected. Please choose your vehicle.'
            },
            bad_size: {
                heading: 'Tire Size Not Found',
                message: 'The size you selected is not available. Try another tire search to find a match.'
            },
            article_fitment_not_found: {
                heading: 'Tire Match Not Found',
                message: 'The tire you selected is not available. Please check available tires matching your vehicle.'
            },
            article_size_not_found: {
                heading: 'Tire Not Found',
                message: 'We could not find the tire you chose. Please check tires of similar sizes.'
            }
        };
    }

    const heading = (window.bsroNotifications[notice] && window.bsroNotifications[notice].heading) || null;
    const message = (window.bsroNotifications[notice] && window.bsroNotifications[notice].message) || null;
    const response = heading && message ? { heading, message } : null;

    return response;
};

export { notifications };
