export const getLocalStorageItem = (name) => {
    let lsItem;

    if (navigator.cookieEnabled) {
        lsItem = localStorage.getItem(name);
    }

    if (lsItem) {
        try {
            const dataObj = JSON.parse(lsItem);
            const now = new Date();
            const expiration = new Date(dataObj.expiration);

            if (!dataObj.expiration || (expiration && now < expiration)) {
                const value = dataObj.data;
                return value;
            } else {
                deleteLocalStorageItem(name);
            }
        } catch (e) {
            // Error parsing JSON. pretend it's not there
        }
    }
    return false;
};

export const getLocalStorageItemExpiration = (name) => {
    let lsItem;

    if (navigator.cookieEnabled) {
        lsItem = localStorage.getItem(name);
    }

    try {
        if (lsItem) {
            const dataObj = JSON.parse(lsItem);

            return dataObj.expiration || false;
        }
    } catch (e) {
        // Error parsing JSON. Do nothing
    }

    return false;
};

export const setLocalStorageItem = (name, jsonData, expiration = null) => {
    const dataObject = {
        data: jsonData,
        expiration
    };
    const data = JSON.stringify(dataObject);

    if (navigator.cookieEnabled) {
        localStorage.setItem(name, data);
    }
};

// if the name doesn't exist, return "false" in
// the event the developer needs to take an alternate
// action if there was nothing to clear
export const deleteLocalStorageItem = (name) => {
    if (navigator.cookieEnabled) {
        if (localStorage.getItem(name)) {
            localStorage.removeItem(name);
            return true;
        } else {
            return false;
        }
    }
};

// here for the sake of completeness
export const clearAllLocalStorage = () => localStorage.clear();
