import Handlebars from 'common/js/library/handlebar';

/**
 * Method to render selected Products in a Modal window
 *
 * @param {Object} tireData - tire data
 * @return {void}
 */
const getTireResults = (tireData) => {
    let selectedTires = [];
    let tireSetList = [];
    let $tireResultContainer = $('#product-result-placeholder');
    let productResultsTmpl = $('[data-product-compare-template]').clone();
    let storePhone = $('[data-store-phone]').attr('data-store-phone');

    // To get selected list of tires
    $(".compare-checkbox input[type='checkbox']:checked").each(function () {
        selectedTires.push($(this).val());
    });

    $.each(selectedTires, (index, value) => {
        let tireSet = {};
        if (value.indexOf('_') > -1) {
            let valueSplitted = value.split('_');
            tireSet.tire = tireData[valueSplitted[0]];
            tireSet.rearTire = tireData[valueSplitted[1]];
        } else {
            tireSet.tire = tireData[value];
        }
        tireSet.storePhone = storePhone;
        tireSetList.push(tireSet);
    });

    $tireResultContainer.html('');
    $tireResultContainer.append($('[data-product-compare-header-template]').html());

    console.log('getTireResults v2:', { tireData, selectedTires, storePhone, tireSetList });

    $.each(tireSetList, (key, value) => {
        let productResultsTemplate = productResultsTmpl.clone().html();
        let productResultCompiled = Handlebars.compile(productResultsTemplate);
        let productResultOutput = $(productResultCompiled(value));
        $tireResultContainer.append(productResultOutput);

        console.log('getTireResults v2 clone:', { value });

        let quoteBtn = $(`.tire-quote-btn[data-article=${value?.tire?.tire?.article}]`);
        if (value?.rearTire != null) {
            quoteBtn = $(
                `.tire-quote-btn[data-article=${value?.tire?.tire?.article}_${value?.rearTire?.tire?.article}]`
            );
        }

        productResultOutput.find('button.fpo').replaceWith(quoteBtn.clone(true));
        $('.quotebtn-section').each(function () {
            const $this = $(this);
            let countbtn = $this.children().length;
            for (let i = 1; countbtn > i; countbtn--) {
                $this.children().not(':first').remove();
            }
        });

        // if hidePrice, we need to hide the price and the promo
        // search for the cards that match the front/rear articles
        // check if it has the hide price class on it
        const frontArticle = value?.tire?.tire?.article;
        const rearArticle = value?.rearTire?.tire?.article || null;
        const isStaggeredFit = value?.rearTire != null;
        const cardClassv1 = '.result-card';
        const cardClassv2 = '.tsr-card';
        const staggeredSelector = `[data-article-id="${frontArticle}"][data-rear-article-id="${rearArticle}"]`;
        const standardSelector = `[data-article-id="${frontArticle}"]:not([data-rear-article-id])`;
        const cardEl = isStaggeredFit
            ? document.querySelector(`${cardClassv1}${staggeredSelector}, ${cardClassv2}${staggeredSelector}`)
            : document.querySelector(`${cardClassv1}${standardSelector}, ${cardClassv2}${standardSelector}`);
        const isHidePrice = cardEl.classList?.contains('with-hidden-price') || false;

        if (isHidePrice) {
            const retailPrice = productResultOutput.find('td.retail-price')[0];
            const offerDescriber = productResultOutput.find('td.offer-describer')[0];

            if (retailPrice && offerDescriber) {
                retailPrice.textContent = 'Add to cart to see price.';
                offerDescriber.textContent = ' ';
            }
        }
    });

    $('tr.product-img img').each(function () {
        const noPic = 'https://s7d1.scene7.com/is/image/bridgestone/nopic-images-tires-zoom-60-global-web-bsro';
        $(this)
            .attr('src', $(this).attr('data-src-jpg'))
            .on('error', function () {
                $(this)
                    .attr('src', $(this).attr('data-src-png'))
                    .on('error', function () {
                        $(this).attr('src', noPic);
                    });
            });
    });
};

export { getTireResults };
