import { combineReducers } from 'redux';
import account from './account';
import ecomm from './ecomm';
import expeditedBooking from './expedited-booking';
import meta from './meta';
import reviews from './reviews';
import tireComparison from './tire-comparison';
import tireDecisionGuide from './tire-decision-guide';
import tireSearchResults from './tire-search-results';
import vehicleSelector from './vehicle-selector';

const rootReducer = combineReducers({
    meta,
    account,
    ecomm,
    reviews,
    tireDecisionGuide,
    tireSearchResults,
    tireComparison,
    expeditedBooking,
    vehicleSelector
});

export default rootReducer;
