import { getCookie, setCookie, removeCookie } from 'common/js/library/util';

/**
 * do a little cleanup of abandoned quotes
 *
 * @param {string} quoteId
 */
const cleanupTasks = (quoteId) => {
    let prevQuoteID = getCookie('bsro.ui.quoteID');

    if (prevQuoteID) {
        removeCookie(prevQuoteID);
    }

    setCookie('bsro.ui.quoteID', quoteId);
    removeCookie('bsro.ui.catitem.full');
    removeCookie('bsro.ui.catitem.brand');
    removeCookie('bsro.ui.catitem.name');

    $('.product-results .compare-checkbox input').attr('checked', false);
};

/**
 * Redirects page if the provided data object contains a quote id
 *
 * @param {string} quoteId - data object from service
 * @param {boolean} [cleanup] - tasks to optionally be completed before redirect
 * @return {void}
 */
const createQuoteComplete = (quoteId, cleanup = false) => {
    if (!quoteId) {
        return;
    }

    if (cleanup) cleanupTasks(quoteId);

    let path = '/tires/tire-quote/' + quoteId;

    if (window.location.port) {
        path = `/content/bsro/${window.AEM.siteName}/en/tires/tire-quote.html?quoteId=${quoteId}`;
    }

    window.location.href = path;
};

export { createQuoteComplete };
