import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';

const middleware = [thunk, createLogger()];

const configureStore = (preloadedState) => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(...middleware)));

    if ( import.meta.webpackHot) {
        // enable webpack HMR for reducers
        import.meta.webpackHot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
};

export default configureStore;
