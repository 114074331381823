/**
 * LocalStorage Caching and API Promise Management
 *
 * This module provides functionality for caching API responses and managing API promises
 * to optimize network usage and improve performance. The cache uses local storage and includes
 * a time-to-live (TTL) to expire data.
 *
 * Functions include:
 * - getCache(key): Retrieves cached data for a specific key, or null if not found.
 * - setCache(key, data): Saves data to cache with the specified key.
 * - setPromise(key, promise): Stores a promise to prevent redundant API calls.
 * - getPromise(key): Retrieves a stored promise for a specific key, or null if not found.
 *
 * Constants:
 * - CACHE_NAME: The key under which the cache is saved in local storage.
 * - HOUR_MS: Time in milliseconds used to calculate cache TTL.
 * - CACHE_TTL: Expiration date for the current cache in local storage.
 *
 * @module sessionCache
 * @exports getCache
 * @exports setCache
 * @exports setPromise
 * @exports getPromise
 */
import { getLocalStorageItem, setLocalStorageItem } from "../data/localstorage/localStorageService";

const CACHE_NAME = 'apiCache'; // Name of the localstorage key.

const HOUR_MS = (60 * 60 * 1000); //3600000; Referenced from the Cart localstorage TTL. (Time To Live.)

const CACHE_TTL = new Date(Date.now() + HOUR_MS);  // Expiration date for the cache.

/**
 * Returns the cached data for the given key.
 * If the key is not found, returns null.
 * 
 * @param {String} key The URL of the API call.
 * @returns 
 */
export const getCache = (key) => {
    const cache = getLocalStorageItem(CACHE_NAME);

    if (cache) {
        const resource = cache.find((item) => item.key === key);

        if (resource) {
            return resource.data;
        } else {
            return null;
        }
    }

    return null;
};

/**
 * Sets the cache for the given key.
 * 
 * @param {String} key // The URL of the API call.
 * @param {Object} data  // The data to be cached.
 */
export const setCache = (key, data) => {
    const cache = getLocalStorageItem(CACHE_NAME) || [];

    const isCached = cache.find((item) => item.key === key);

    if (!isCached) {
        setLocalStorageItem(CACHE_NAME, [...cache, { key, data }], CACHE_TTL);
    }
};

/**
 * Sets the promise for the given key.
 * This is used to prevent multiple calls to the same API at the same time from different components on the page.
 * If a promise is already cached, the promise is returned.
 * If no promise is cached, the promise is cached and returned.
 * 
 * @param {String} key // The URL of the API call.
 * @param {Promise} promise // The promise to be cached.
 */
export const setPromise = (key, promise) => {
    window.apiPromises = window.apiPromises || [];

    window.apiPromises.push({key, promise});
};


/**
 * Returns the promise for the given key.
 * If no promise is cached, returns null.
 * 
 * @param {String} key // The URL of the API call.
 * @returns {Promise} // The promise to be cached.
 */
export const getPromise = (key) => {
    const apiPromises = window.apiPromises || [];

    const promise = apiPromises.find((item) => item.key === key);

    return promise;
};