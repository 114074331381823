import { getStoreLocationsByCityState } from 'common/js/data/services/store-location';
import { getStoresData } from './getStoresData';

const getStoresByCityState = async (rootEl, props) => {
    const {
        city,
        state,
        getRadius,
        queryStoreNumber,
        isLoggedIn,
        preferredStoreNumber,
        setDateSelectorIndex,
        dateStoreStep,
        numDays,
        datesArr,
        addedServicesListArr,
        getStoresInfo,
        setStoresInfo
    } = props;
    const dataPackage = {
        city,
        state
    };
    const response = await getStoreLocationsByCityState(dataPackage);
    getStoresData(rootEl, {
        response,
        getRadius,
        queryStoreNumber,
        isLoggedIn,
        preferredStoreNumber,
        setDateSelectorIndex,
        dateStoreStep,
        numDays,
        datesArr,
        addedServicesListArr,
        getStoresInfo,
        setStoresInfo
    });
};

export { getStoresByCityState };
