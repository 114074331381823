// takes a store Item and returns a map URL based on user device.

export const storeToMapUrl = (store) => {
        
    let mapUrl;
    const userAgent = navigator.userAgent;

    // build address from store
    const storeAddress = store.address + ' ' + store.city + ', ' + store.state;
    // Encode the address for the URL
    const encodedAddress = encodeURIComponent(storeAddress);

    if (/Android/i.test(userAgent)) {
        mapUrl = `https://maps.google.com/maps?q=${encodedAddress}`;
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        mapUrl = `http://maps.apple.com/?q=${encodedAddress}`;
    }  else {
        mapUrl = `https://maps.google.com/maps?q=${encodedAddress}`
    }

    return mapUrl
}