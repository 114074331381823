/**
 * Determines the appropriate ordinal suffix for a given day of the month.
 * It supports the common English suffixes: 'st' for 1st, 21st, and 31st;
 * 'nd' for 2nd and 22nd; 'rd' for 3rd and 23rd; and 'th' for all other days.
 *
 * @param {string} day - The day of the month as a string or number.
 * @returns {string} The ordinal suffix ('st', 'nd', 'rd', or 'th').
 */
const daySuffix = (day) => {
    const stRegex = /^(1|21|31)$/g;
    const ndRegex = /^(2|22)$/g;
    const rdRegex = /^(3|23)$/g;
    return stRegex.test(day) ? 'st' : ndRegex.test(day) ? 'nd' : rdRegex.test(day) ? 'rd' : 'th';
};

export { daySuffix };
