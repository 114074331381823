import { confirm } from 'common/js/data/services/ecomm/appointment';
import { deleteLocalStorageItem, getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { pubsub } from 'common/js/library/pubsub';
import { removeCart } from 'common/js/data/services/ecomm/cart';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { getDiscounts } from 'common/js/data/services/ecomm/discountUtil';
import { getEcid } from 'common/js/library/get-ecid';

const confirmPost = async (rootEl, props) => {
    const {
        dataPackage,
        submitButton,
        getAppointmentDetails,
        dateStoreStep,
        addedServicesListArr,
        getStoresInfo,
        getEventsAdded,
        setEventsAdded,
        getApptDataPackage
    } = props;
    const { store, vehicle, vehicle_search_type } = getAppointmentDetails();
    const { year, make, model, submodel = vehicle.trim } = vehicle;
    const searchType = vehicle_search_type;
    submitButton.disabled = true;
    const ecid = getEcid();

    if (ecid) {
        dataPackage.visitorID = ecid;
    }

    const response = await confirm(dataPackage);

    const data = response?.data;
    const status = response?.status;

    submitButton.disabled = false;

    if (status === '200') {
        if (data.duplicate) {
            const appointmentWarning = rootEl.querySelector('.appointmentWarning');
            appointmentWarning.dispatchEvent(new Event('click'));

            if (!getEventsAdded()) {
                const cancelAppointment = rootEl.querySelector('[data-modal-cancel-appointment]');
                const confirmAppointment = rootEl.querySelector('[data-modal-confirm-appointment]');

                cancelAppointment.addEventListener('click', (evt) => {
                    window.stepsList.goTo = 'date-store';
                });
                confirmAppointment.addEventListener('click', (evt) => {
                    dataPackage.verify = 'false';

                    confirmPost(rootEl, {
                        dataPackage,
                        submitButton,
                        getAppointmentDetails,
                        dateStoreStep,
                        addedServicesListArr,
                        getStoresInfo,
                        getApptDataPackage
                    });
                });
                setEventsAdded(true);
            }
        } else {
            const apptDetailsHTML = rootEl.querySelector('.appointment-details .appointment-details-info').innerHTML;
            const dateTimeText = rootEl.querySelector(
                '.appointment-details-info .date-time:nth-of-type(1) .date-time-item'
            ).textContent;
            [...rootEl.querySelectorAll('.confirmation-item .appointment-details-info')].forEach((detailsInfo) => {
                detailsInfo.innerHTML = apptDetailsHTML;
            });
            rootEl.querySelector('.confirmation-item .appointment-details-info-date-time-item').textContent =
                dateTimeText;

            window.stepsList.scrollTo = 0;
            pubsub.publish('steps-next');

            pubsub.publish('Appointment-vehicle-selected', [vehicle]);

            removeCart();
            const dateStoreStepdateData = JSON.parse(dateStoreStep.dataset.date);
            const pricingDetailsStorageItem = getLocalStorageItem('pricingDetails');
            const discounts = await getDiscounts(pricingDetailsStorageItem);

            const sdata = {
                vehicle: {
                    year,
                    make,
                    model,
                    submodel,
                    mileage: dataPackage.mileage
                },
                discounts: discounts,
                store: {
                    store_position: getStoresInfo().storesAsDataObj[dateStoreStepdateData.storeNumber].count,
                    city: store.city || null,
                    state: store.state.trim() || null,
                    zip: store.zip || null,
                    storeNumber: dateStoreStepdateData.storeNumber
                },
                vehicle_search_type: searchType,
                services: addedServicesListArr,
                date: dateStoreStepdateData.availableDate,
                time: dateStoreStepdateData.selectedTime,
                email: getApptDataPackage().customerEmailAddress,
                preference_selected: rootEl.querySelector('.pickup-preference option:checked')?.value || 'N/A',
                appt_flow_type: 'regular flow',
                event: 'sa.v2.complete',
                telephone: getApptDataPackage().customerDayTimePhone
            };

            let methodType = window.sessionStorage.getItem('sa.methodType');
            if (methodType) {
                sdata.method_type = methodType;
            }
            window.sessionStorage.removeItem('sa.methodType');

            setAdobeDataLayer(sdata);
            if (!year || !make || !model || !submodel) {
                console.error(
                    `submit appointment completion error: vehicle information missing or incomplete, {vehicleProfile: {year: ${year}, make: ${make}, model: ${model}, submodel: ${submodel}}}`
                );
            }
            Array.from(rootEl.querySelectorAll('.confirmation-item .comments')).forEach((commentsEl) => {
                const rawComment = document.createComment(dataPackage.comments.toString());
                const userComment = dataPackage.comments
                    .toString()
                    .replace(/^.* quote End;\s*/i, '')
                    .replace(/^none$/i, '')
                    .trim();
                commentsEl.append(rawComment, userComment);
                if (userComment) {
                    commentsEl.classList.remove('hide');
                }
            });
        }

        // disable recently viewed tires data if tire service appointment created
        if (data.selectedServices) {
            const services = ['2745', '2762'];
            const matches = services.filter((service) => {
                return data.selectedServices.includes(service);
            });

            if (matches.length > 0) {
                deleteLocalStorageItem('recentlyViewedTire');
            }
        }
    } else if (status === '053') {
        rootEl.querySelector('.pastAppointmentWarning').dispatchEvent(new Event('click'));
    } else {
        rootEl.querySelector('.generalAppointmentWarning').dispatchEvent(new Event('click'));
    }
};

export { confirmPost };
