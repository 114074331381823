const JSIWrapperDynamicIncludeReinitialization = () => {
    // Select the target node
    const targetNode = document.querySelector('.jsi-wrapper');

    // Function to be called to load images lazily.
    const imageLazyLoad = () => {
        const images = targetNode.querySelectorAll('img[data-src]');
        images.forEach((img) => {
            img.src = img.dataset.src;
        });
    };

    // Options for the observer (which mutations to observe)
    const config = { childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationsList) => {
        for (let mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.target.id?.includes('dynamic_include_filter_div')) {
                imageLazyLoad();
                //Disconnect the observer after the first mutation
                observer.disconnect();
                break;
            }
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    targetNode && observer.observe(targetNode, config);
};

export { JSIWrapperDynamicIncludeReinitialization };
