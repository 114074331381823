/**
 * @module lidUserStates
 * @description
 * A utility method to determine the state of a user for LID.
 * This method will check the user's cookie and profile data to determine if they are enabled for LID.
 *
 * The necessary data is as follows:
 * - Year
 * - Make
 * - Model
 * - Trim
 * - Zip
 * - Store Number
 *
 * The business logic for this method is as follows:
 * - If the user has the necessary data in their cookie, they are enabled for LID.
 * - If the user has the necessary data in their profile, they are enabled for LID.
 * - If the user has the necessary data in both their cookie and profile, they are enabled for LID.
 * - If the user does not have the necessary data in either their cookie or profile, they are not enabled for LID.
 *
 * @returns {boolean} True if the user is enabled for LID, false if not.
 *
 * @example
 * import { isLIDEnabled } from 'common/js/util/lidUserStates';
 *
 * const init = async () => {
 *    const isEnabled = await isLIDEnabled();
 *
 *   if (isEnabled) {
 *      // Do something.
 *  }
 * };
 *
 */

import { getCookie } from '../library/util';
import { checkUserToken, getUserDetails } from '../data/services/account/user';
import { tiresNearYouInInventory } from './lidTiresNearYouInInventory';

/**
 * @description
 * Object used to store the necessary data from the cookie or profile.
 */
export let lidData = {};

/**
 * @description
 * A dictionary of the necessary attribute mapping for LID.
 * The key is the name of the attribute.
 * The value is an object with two properties, cookie and profile.
 * The cookie property is the name of the attribute in the cookie.
 * The profile property is the name of the attribute in the profile.
 * This is used to map the necessary data from the cookie or profile to the lidData object.
 */
const attributeDictionary = {
    year: {
        cookie: 'year',
        profile: 'YEAR'
    },
    make: {
        cookie: 'make',
        profile: 'MAKE'
    },
    model: {
        cookie: 'model',
        profile: 'MODEL'
    },
    trim: {
        cookie: 'trim',
        profile: 'SUBMODEL'
    },
    zip: {
        cookie: 'myZip',
        profile: 'ZIP'
    },
    storeNumber: {
        cookie: 'storeNumber',
        profile: 'STORE_NUMBER'
    }
};

/**
 *
 * @param {String} item // the value to check
 * @returns // true if the value is not null or an empty string, false if not.
 *
 */
const hasValue = (item) => {
    return item && item !== '';
};

/**
 *
 * @returns // the necessary data from the cookie.
 *
 */
const getCookieData = () => {
    // Reference cookie, then reference logged in user data.
    const vehicleCookie = getCookie('bsro.cp-fcac');

    // Return the necessary data from the cookie.
    return { ...vehicleCookie.vehicles.tce, ...vehicleCookie.location };
};

/**
 *
 * @returns // the necessary data from the profile.
 *
 */
const getProfileData = async () => {
    // Reference the tokenId cookie.
    const tokenId = getCookie('tokenId');

    // If the tokenId is not available, return null.
    if (!tokenId) return null;

    // If the tokenId is not valid, return null.
    if (await !isLoggedIn(tokenId)) return null;

    // Reference the user details from the profile.
    const userDetails = await getUserDetails(tokenId);

    if (userDetails.success === 'true') {
        // Reference three areas of interest, vehicle, location, and driver.
        const vehicleProfile = userDetails.data?.account?.VEHICLE?.[0]; // First vehicle in "garage".
        const locationProfile = userDetails.data?.account?.STORE?.[0]; // First store in profile.
        const driverProfile = userDetails.data?.account?.DRIVER?.[0]; // Users personal information from profile.

        // Return the necessary data from the profile.
        if(vehicleProfile && locationProfile && driverProfile) {
            return { ...vehicleProfile, ZIP: driverProfile.ZIP, STORE_NUMBER: locationProfile.STORE_NUMBER || '', FIRSTNAME: driverProfile.FIRSTNAME, PHONE: locationProfile.PHONE };
        }else {
            return null;
        }
        
    } else {
        // Error
        console.error('USER DATA NOT AVAILABLE');
        return null;
    }
};

/**
 * Returns true if the user is logged in, false if not.
 * The method will check the user's token to determine if they are logged in.
 *
 * @param {String} tokenId // the tokenId cookie.  Logged in users have a tokenId cookie.
 * @returns {boolean}
 */
const isLoggedIn = async (tokenId) => {
    try {
        const tokenCheck = await checkUserToken(tokenId);

        // If the token check is not successful, return false.
        if (tokenCheck.success === 'false') return false;

        // If the token check is successful, return true.
        return tokenCheck?.data?.loggedIn === 'true';
    }
    catch (err) {
        console.error('isLoggedIn checkUserToken', err);
        return false;
    }
};

/**
 * 
 * @param {Object} cookieData // the necessary data from the cookie.
 */
const memoizeCookieData = (cookieData) => {
    for (const [key, value] of Object.entries(attributeDictionary)) {
        // Add the necessary data to the lidData object.
        lidData[key] = cookieData[value.cookie];
    }
};

/**
 * 
 * @param {Object} profileData // the necessary data from the profile.
 */
const memoizeProfileData = (profileData) => {
    for (const [key, value] of Object.entries(attributeDictionary)) {
        lidData[key] = profileData[value.profile];
    }

    // Add the vehicle nickname to the lidData object.
    // This is not in the attributeDictionary because
    // it is not a core attribute.
    lidData.vehicleNickName = profileData.NAME || '';
    lidData.username = profileData.FIRSTNAME || '';
    lidData.storePhone = profileData.PHONE || '';
};

/**
 *
 * @returns // true if the user has the necessary data in their cookie, false if not.
 *
 */
const isLIDCookieEnabled = () => {
    const cookieData = getCookieData();
    const isCookieEnabled = [...Object.keys(attributeDictionary)].every((item) =>
        hasValue(cookieData[attributeDictionary[item].cookie])
    );

    // memoize the cookie data
    if (isCookieEnabled) {
        memoizeCookieData(cookieData);
    }

    return isCookieEnabled;
};

/**
 *
 * @returns // true if the user has the necessary data in their profile, false if not.
 *
 */
const isLIDGProfileEnabled = async () => {
    let profileData = await getProfileData();

    if (profileData) {
        const isProfileEnabled = [...Object.keys(attributeDictionary)].every((item) =>
            hasValue(profileData[attributeDictionary[item].profile])
        );

        fillProfileData(profileData);

        // memoize the profile data
        if (isProfileEnabled) {
            memoizeProfileData(profileData);
        }

        return isProfileEnabled;
    }

    return false;
};

/**
 * Fills the Profile Data with the cookie data if the profile data is missing.
 * If a user is logged in, but the profile data is missing, we will use the cookie data instead.
 * The ZIP and STORE_NUMBER are the only fields that will be filled.
 *
 * @param {Object} profileData
 */
const fillProfileData = (profileData) => {
        if (!profileData.ZIP || !profileData.STORE_NUMBER) {
            const cookieData = getCookieData();

            // If a zip is not in the profile, but is in the cookie, use the cookie zip.
            if (!profileData.ZIP && cookieData.myZip) {
                profileData.ZIP = cookieData.myZip;
            } else {
                delete profileData.ZIP;
            }

            // If a store number is not in the profile, but is in the cookie, use the cookie store number.
            if (!profileData.STORE_NUMBER && cookieData.storeNumber) {
                profileData.STORE_NUMBER = cookieData.storeNumber;
            } else {
                delete profileData.STORE_NUMBER;
            }
        }
};

/**
 * @description Determines wheather a user is Enabled for LID.
 * @async
 * @returns // true if the user is enabled for LID, false if not.
 */
export const isLIDEnabled = async () => {
    const isEnabledAccountUser = await isLIDGProfileEnabled();
    const isEnabledCookieUser = isLIDCookieEnabled();

    return isEnabledAccountUser || isEnabledCookieUser;
};

/**
 * @description Returns the tires near you in inventory.
 * @param {Object} lidData // the necessary data for LID.
 * @returns // the tires near you in inventory.
 */
export const getTiresNearYouInInventory = async () => {
    const isEnabled = await isLIDEnabled();

    if (!isEnabled) {
        return [];
    }

    return await tiresNearYouInInventory(lidData);
}