/**
 * Used for Tire search and detail page analytics.
 * Returns the last element in the URL, which is where
 * the articleId is located.
 * Ensures it is an integer.
 * Return an empty array when a valid articleId is not available.
 */
const getArticleId = () => {
    let articleId = window.location.pathname.replace(/\/+$/, '').split('/').reverse()[0];

    if (!/^\d+$/.test(articleId)) {
        return []; // no articleId found in URL
    }

    return [{ productId: articleId }];
};

export { getArticleId };
