import { getUrlParam } from 'common/js/library/util';

export default function (data) {
    const storeNumber = getUrlParam('storeNumber');
    return `
        <div class="offer-modal__body" data-share-offer-modal>
            <div class="offer-modal__body--success">
                <h1 class="offer-modal__title">Your offer has been ${data.type === 'send' ? 'sent' : 'printed'}</h1>
                <h2 class="offer-modal__subtitle">Would you like to schedule an appointment?</h2>
                <div class="offer-modal__cta-wrapper">
                    <a
                    href="/appointment/schedule-appointment/?sa_source=offer-${data.type === 'send' ? data.source : 'print'}${storeNumber ? `&storeNumber=${storeNumber}` : ''}" 
                    class="btn btn--v2 offer-modal__cta offer-modal__btn-schedule-appointment">
                    Schedule Appointment
                   </a>
                    <a href="#" class="btn btn--v2 btn--v2-secondary offer-modal__cta offer-modal__btn-close" data-dismiss="modal">
                        No Thanks
                    </a>
                </div>
            </div>
        </div>
    `;
}
