import '../scss/request-a-quote.scss';
import {
    cleanHolidays,
    cleanHours,
    cleanPhoneNumber,
    validateElement,
    formatPhoneNumber,
    serializeForm
} from 'common/js/library/util';
import { responseError, queryStringify, HTTPHeaders } from 'common/js/data/services/serviceUtilities';
import { component } from 'common/js/library/component';
import { createQuote } from 'common/js/data/services/ecomm/quote';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { testZip } from 'common/components/content/react-shared/utils/validation';

let emailErrorEl;
let zipErrorEl;
let raqEl;
let componentEls;
let heroEl;
let confirmationContainer;
const init = (config) => {
    emailErrorEl = document.querySelector('.emailError');
    zipErrorEl = document.querySelector('.zipError');
    raqEl = document.querySelector('.raq');
    heroEl = document.querySelector('.hero');
    componentEls = document.querySelectorAll('#main .component');
    confirmationContainer = document.querySelector('.confirmation');
};

const instanceInit = (rootEl) => {
    const selServices = rootEl.querySelector('select[name=services]');
    const submitBtn = rootEl.querySelector('button[data-submit]');
    const cta = rootEl.querySelector('button[data-cta]');
    const zipElem = rootEl.querySelector('input[name=zip]');
    const submitErrorEl = rootEl.querySelector('.submit-error');
    let firstTime = true;
    let state = '';
    let storeData = {};

    const setState = (value) => {
        state = value;
    };

    const getState = () => {
        return state;
    };

    const setFirstTime = (value) => {
        firstTime = value;
    };

    const getFirstTime = () => {
        return firstTime;
    };

    const setStoreData = (value) => {
        storeData = value;
    };

    const getStoreData = () => {
        return storeData;
    };

    const enableCTA = (zipElement) => {
        cta.disabled = !testZip(zipElement?.value);
    };

    zipElem?.addEventListener('input', (evt) => {
        enableCTA(evt.currentTarget);
    });

    const phoneNumber = rootEl.querySelector('.phoneNum');
    phoneNumber?.addEventListener('keyup', (evt) => {
        evt.target.value = formatPhoneNumber(evt.target.value);
    });

    hideSections(rootEl);
    enableCTA(zipElem);

    selServices.addEventListener('change', (evt) => {
        var option = selServices.querySelector('option:checked');

        if (getState() !== option.getAttribute('data-state')) {
            setState(option.getAttribute('data-state'));
            if (!getFirstTime()) {
                hideSections(rootEl);
                [...rootEl.querySelectorAll(`.${getState()}`)].forEach((stateEl) => {
                    stateEl.style.display = 'block';
                });
            }
        }

        emailErrorEl.innerHTML = '';
        zipErrorEl.innerHTML = '';

        if (window.siteProfile) {
            if (window.siteProfile.vehicles.tce.year) {
                rootEl.querySelector('select[name=year]').value = window.siteProfile.vehicles.tce.year;
            }
            if (window.siteProfile.vehicles.tce.make) {
                rootEl.querySelector('select[name=make]').value = window.siteProfile.vehicles.tce.make;
            }
            if (window.siteProfile.vehicles.tce.model) {
                rootEl.querySelector('select[name=model]').value = window.siteProfile.vehicles.tce.model;
            }
            if (window.siteProfile.vehicles.tce.trim) {
                rootEl.querySelector('select[name=submodel]').value = window.siteProfile.vehicles.tce.trim;
            }
        }
    });

    [...rootEl.querySelectorAll('input, select, textarea')].forEach((htmlEl) => {
        htmlEl.addEventListener('change', (evt) => handleFieldChange(rootEl));
        htmlEl.addEventListener('blur', (evt) => handleFieldChange(rootEl));
        if (htmlEl.tagName === 'INPUT' || htmlEl.tagName === 'TEXTAREA') {
            htmlEl.addEventListener('keyup', (evt) => {
                if (evt.currentTarget.value !== '') {
                    handleFieldChange(rootEl);
                }
            });
        }
    });

    cta.addEventListener('click', (evt) =>
        handleStateChange(rootEl, { submitBtn, getState, cta, setFirstTime, setStoreData, getStoreData, submitErrorEl })
    );

    if (window.siteProfile) {
        if (window.siteProfile.location.myZip) {
            const zipElement = rootEl.querySelector('input[name=zip]');
            if (zipElement) {
                zipElement.value = window.siteProfile.location.myZip;
                enableCTA(zipElement);
            }
        }
        if (window.siteProfile.vehicles.tce.year) {
            rootEl.querySelector('select[name=year]').value = window.siteProfile.vehicles.tce.year;
        }
        if (window.siteProfile.vehicles.tce.make) {
            rootEl.querySelector('select[name=make]').value = window.siteProfile.vehicles.tce.make;
        }
        if (window.siteProfile.vehicles.tce.model) {
            rootEl.querySelector('select[name=model]').value = window.siteProfile.vehicles.tce.model;
        }
        if (window.siteProfile.vehicles.tce.trim) {
            rootEl.querySelector('select[name=submodel]').value = window.siteProfile.vehicles.tce.trim;
        }
    }

    window.addEventListener('load', (evt) => componentInit(selServices));
};

const hideSections = (rootEl) => {
    [...rootEl.querySelectorAll('.quote')].forEach((quoteEl) => {
        quoteEl.style.display = 'none';
    });

    [...rootEl.querySelectorAll('.call')].forEach((callEl) => {
        callEl.style.display = 'none';
    });

    [...rootEl.querySelectorAll('.free')].forEach((freeEl) => {
        freeEl.style.display = 'none';
    });
};

const componentInit = (selServices) => {
    const serviceAttr = selServices.getAttribute('data-services');
    const stateAttr = selServices.getAttribute('data-states');
    const services = JSON.parse(serviceAttr);
    const states = JSON.parse(stateAttr);
    let state = '';

    for (let key in services) {
        if (states['quote'].includes(key)) {
            state = 'quote';
        }

        if (states['call'].includes(key)) {
            state = 'call';
        }

        if (states['free'].includes(key)) {
            state = 'free';
        }
        if (Object.hasOwn(services, key)) {
            let option = document.createElement('option');
            option.value = key;
            option.setAttribute('data-state', state);
            option.textContent = services[key];
            selServices.appendChild(option);
        }
    }
};

const handleFieldChange = (rootEl) => {
    hideFormErrors(rootEl);
};

const getProductInfo = (service) => {
    const info = {};
    const serviceVal = service.length ? service[0] : service;

    switch (serviceVal) {
        case 'oil-change':
            info.name = 'Oil Change';
            info.code = '194';
            break;
        case 'tire-rotation':
            info.name = 'Tire Rotation';
            info.code = '195';
            break;
        case 'wheel-alignment':
            info.name = 'Wheel Alignment';
            info.code = '196';
            break;
        case 'tire-installation':
            info.name = 'Tire Installation';
            info.code = '197';
            break;
        case 'complete-vehicle-inspection':
            info.name = 'Complete Vehicle Inspection';
            info.code = '198';
            break;
        case 'wheel-balance':
            info.name = 'Wheel Balance';
            info.code = '199';
            break;
        case 'flat-repair':
            info.name = 'Flat Repair';
            info.code = '200';
            break;
        case 'engine-diagnostics':
            info.name = 'Engine Diagnostics';
            info.code = '201';
            break;
        case 'brake-fluid-exchange':
            info.name = 'Brake Fluid Exchange';
            info.code = '202';
            break;
        default:
            info.name = '';
            info.code = '';
    }

    return info;
};

const handleSubmit = (rootEl, { submitBtn, getState, getStoreData, submitErrorEl }) => {
    submitErrorEl && (submitErrorEl.style.display = 'none');
    const data = getFormValues(rootEl);

    const email = data.email.trim();

    const emailValidRes = validateElement(email, 'email');
    const zipValidRes = validateElement(data.zip, 'zipcode');
    const phoneValidRes = validateElement(data.phone, 'phoneNum');
    if (validate(data, getState) && emailValidRes && zipValidRes && phoneValidRes) {
        hideFormErrors(rootEl);
        const productInfo = getProductInfo(data.services);
        const { name, code } = productInfo;
        const { location, site } = window.siteProfile;
        const { make, model, year, submodel, mileage, firstName, lastName, zip, email, phone, optin, message } = data;

        const payloadData = {
            quoteType: 'email',
            vehicle: {
                make: make.length ? make[0] : make,
                model: model.length ? model[0] : model,
                year: year.length ? year[0] : year,
                submodel: submodel.length ? submodel[0] : submodel,
                mileage
            },
            products: [
                {
                    category: name,
                    items: [
                        {
                            code
                        }
                    ]
                }
            ],
            contactInfo: {
                firstName,
                lastName,
                communicationOptions: [
                    {
                        contactable: `${optin === 'Yes' ? 'true' : 'false'}`,
                        type: 'email',
                        id: email,
                        isPreferred: 'true',
                        marketingPreferences: []
                    },
                    {
                        contactable: `${optin === 'Yes' ? 'true' : 'false'}`,
                        type: 'phone',
                        id: phone,
                        isPreferred: 'false',
                        marketingPreferences: []
                    }
                ]
            },
            location: {
                zipCode: zip,
                city: location.myCity,
                state: location.myState,
                siteName: site
            },
            other: {
                comments: message
            }
        };

        sendData(rootEl, payloadData, { submitBtn, getStoreData, submitErrorEl });
    } else {
        if (!emailValidRes) {
            setAdobeDataLayer({
                event: 'forms.user_generated_error',
                form_name: 'request quote',
                step_name: 'Your Info',
                field_name: 'email',
                error_message: 'Enter valid email address'
            });
        }
        showFormErrors(rootEl);
    }
};

const hideFormErrors = (rootEl) => {
    Array.from(rootEl.querySelectorAll('input')).forEach((inputEl) => {
        inputEl.classList.remove('error');
    });

    Array.from(rootEl.querySelectorAll('select')).forEach((selectEl) => {
        selectEl.parentNode.classList.remove('error');
    });

    Array.from(rootEl.querySelectorAll('textarea')).forEach((areaEl) => {
        areaEl.classList.remove('error');
    });
};

const showFormErrors = (rootEl) => {
    const inputEls = rootEl.querySelectorAll('input');

    [...inputEls].forEach(function (inputEl, i) {
        if (inputEl.value === '' && (inputEl.id !== 'mileage' || inputEl.name !== 'mileage')) {
            inputEl.classList.add('error');
        }

        if (i === 0) {
            inputEl.focus();
        }
    });

    const selectEls = rootEl.querySelectorAll('select');

    [...selectEls].forEach(function (selectEl) {
        if (selectEl.value === '') {
            selectEl.parentNode.classList.add('error');
        }
    });

    const areaEls = rootEl.querySelectorAll('textarea');

    [...areaEls].forEach(function (areaEl) {
        if (areaEl.value === '') {
            areaEl.classList.add('error');
        }
    });
};

const handleStateChange = async (rootEl, props) => {
    const zip = rootEl.querySelector('input.zip');
    const zipValidRes = validateElement(zip.value, 'zipcode');
    const storeContainer = rootEl.querySelector('.col.store');
    const serviceSelVal = rootEl.querySelector('[name=services]').value;

    hideFormErrors(rootEl);

    if (zipValidRes && props.getState() !== '' && serviceSelVal !== '' && zip.value !== '') {
        setAdobeDataLayer({
            event: 'select.service.type',
            zip: zip.value,
            serviceType: serviceSelVal
        });
        hideSections(rootEl);

        [...rootEl.querySelectorAll(`.${props.getState()}`)].forEach((stateEl) => {
            stateEl.style.display = 'block';
        });

        props.cta.style.display = 'none';
        zip.parentNode.parentNode.style.display = 'none';
        props.submitBtn.addEventListener('click', (evt) => handleSubmit(rootEl, props));
        [...rootEl.querySelectorAll('input[name=zip]')].forEach((zipEl) => {
            zipEl.value = zip.value;
        });
        props.setFirstTime(false);
        storeContainer.style.display = 'none';

        window.setTimeout(() => {
            if (window.siteProfile.vehicles.tce.year && window.siteProfile.vehicles.tce.year !== '') {
                rootEl.querySelector('select[name=year]').value = window.siteProfile.vehicles.tce.year;
            }
        }, 1000); //this is required becuase of the loading of the component
        if (window.siteProfile.vehicles.tce.year && window.siteProfile.vehicles.tce.year !== '') {
            rootEl.querySelector('select[name=year]').value = window.siteProfile.vehicles.tce.year;
        }
        if (window.siteProfile.vehicles.tce.make && window.siteProfile.vehicles.tce.make !== '') {
            rootEl.querySelector('select[name=make]').value = window.siteProfile.vehicles.tce.make;
        }
        if (window.siteProfile.vehicles.tce.model && window.siteProfile.vehicles.tce.model !== '') {
            rootEl.querySelector('select[name=model]').value = window.siteProfile.vehicles.tce.model;
        }
        if (window.siteProfile.vehicles.tce.trim && window.siteProfile.vehicles.tce.trim !== '') {
            rootEl.querySelector('select[name=submodel]').value = window.siteProfile.vehicles.tce.trim;
        }

        const storeData = await getStore(zip.value);
        props.setStoreData(storeData);
        const storeNumberContainer = rootEl.querySelector('[data-store-number]');
        const storeAddressContainer = rootEl.querySelector('[data-store-address]');
        const storeStateContainer = rootEl.querySelector('[data-store-state]');
        const storePhoneContainer = rootEl.querySelector('[data-store-phone]');
        const storeHoursContainer = rootEl.querySelector('[data-hours-list]');
        const storeHolidaysContainer = rootEl.querySelector('[data-holidays-list]');
        const mapContainer = rootEl.querySelector('.map-container');
        const mapImg = rootEl.querySelector('.map');

        if (storeData.storeNumber && storeData.localPageURL) {
            storeNumberContainer.innerHTML = `Store: <a href=${storeData.localPageURL}>#${storeData.storeNumber}</a>`;
        } else {
            storeNumberContainer.style.display = 'none';
        }

        if (storeData.storeNumber) {
            mapContainer.querySelector('a')?.setAttribute('href', `/locate/get-directions/${storeData.storeNumber}`);
        } else {
            mapContainer.style.display = 'none';
        }

        if (storeData.address) {
            storeAddressContainer.innerHTML = storeData.address;
        } else {
            storeAddressContainer.style.display = 'none';
        }

        if (storeData.city && storeData.state && storeData.zip) {
            storeStateContainer.innerHTML = `${storeData.city}, ${storeData.state}  ${storeData.zip}`;
        } else {
            storeStateContainer.style.display = 'none';
        }

        if (storeData.phone) {
            storePhoneContainer.innerHTML = `<a class="store-phone" href="tel:${storeData.phone}"> ${cleanPhoneNumber(
                storeData.phone
            )}</a>`;
        } else {
            storePhoneContainer.style.display = 'none';
        }

        if (storeData.hours && storeData.hours.length > 0) {
            const cleanedHours = cleanHours(storeData.hours);
            let htmlHours = '';

            cleanedHours.forEach((item) => {
                htmlHours += `<li class="hours-display">${item.weekDay}<span class="hours">${item.times}</li>`;
            });

            while (storeHoursContainer.firstChild) {
                storeHoursContainer.removeChild(storeHoursContainer.firstChild);
            }

            storeHoursContainer.innerHTML = htmlHours;
        } else {
            storeHoursContainer.style.display = 'none';
        }

        if (
            storeData.holidayHours &&
            storeData.holidayHours.length > 0 &&
            storeData.holidays &&
            storeData.holidays.length > 0
        ) {
            const cleanedHolidays = cleanHolidays(storeData.holidays, storeData.holidayHours);
            let htmlHours = '';

            cleanedHolidays.forEach((holiday) => {
                htmlHours += `<li class="hours-display"><span class="holidays">${holiday.description}</span> <span class="hours">${holiday.times}</span></li>`;
            });

            while (storeHolidaysContainer.firstChild) {
                storeHolidaysContainer.removeChild(storeHolidaysContainer.firstChild);
            }

            storeHolidaysContainer.innerHTML = htmlHours;
        } else {
            storeHolidaysContainer.style.display = 'none';
        }

        if (storeData.latitude && storeData.longitude) {
            mapImg.setAttribute(
                'src',
                `//dev.virtualearth.net/REST/v1/Imagery/Map/Road/${storeData.latitude},${storeData.longitude}/13?ms=128,118&pp=${storeData.latitude},${storeData.longitude};7&ml=TrafficFlow&fmt=jpeg&mmd=0&key=AlGvGS0ffMJjgLqnheFD2z6MiUGHw9_QzsErZT9qJT21H_FW-mmSVjNh4Vq7MFwV`
            );
        } else {
            mapImg.style.display = 'none';
        }

        if (props.getState() === 'call' || props.getState() === 'free') {
            storeContainer.style.display = 'block';
        }

        [...rootEl.querySelectorAll('.custom-select.services')].forEach((el) => el.classList.remove('error'));
        [...rootEl.querySelectorAll('input[name=zip]')].forEach((el) => el.classList.remove('error'));
    } else {
        //error handler
        if (serviceSelVal === '') {
            rootEl.querySelector('.custom-select.services')?.classList.add('error');
            rootEl.querySelector('[name=services]')?.focus();
            setAdobeDataLayer({
                event: 'forms.user_generated_error',
                form_name: 'request quote',
                step_name: 'service needed',
                field_name: 'services',
                error_message: 'Select Service'
            });
        }

        if (!zipValidRes || zip.value === '') {
            const zip = rootEl.querySelector('input[name=zip]');
            zip.classList.add('error');
            zip.focus();
            setAdobeDataLayer({
                event: 'forms.user_generated_error',
                form_name: 'request quote',
                step_name: 'service needed',
                field_name: 'zipcode',
                error_message: 'Enter Valid Zipcode'
            });
        }
    }
};

const getFormValues = (rootEl) => {
    return serializeToObject(rootEl);
};

const validate = (data, getState) => {
    var currentState = getState();

    if (currentState === 'quote') {
        if (
            data.service !== '' &&
            data.firstName !== '' &&
            data.lastName !== '' &&
            data.email !== '' &&
            data.zip !== '' &&
            data.phone !== '' &&
            data.message !== '' &&
            data.year !== '' &&
            data.make !== '' &&
            data.model !== '' &&
            data.trim !== ''
        ) {
            return true;
        }
    }

    return false;
};

const getStore = async (zip) => {
    const paramData = {
        zipCode: zip
    };

    const url = `/bsro/services/store/location/get-list-by-zip${queryStringify(paramData)}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        if (parseInt(responseJson.status) === 200) {
            return responseJson.data.stores[0];
        } else {
            console.log(`No stores found for zip: ${zip}`);
            return '';
        }
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const sendData = async (rootEl, data, { submitBtn, getStoreData, submitErrorEl }) => {
    submitBtn.disabled = true;

    try {
        const dataResponse = await createQuote(data);

        if (dataResponse.success === 'true') {
            raqEl.style.display = 'none';

            fillConfirmation(data, getStoreData);

            heroEl && (heroEl.style.display = 'none');
            [...componentEls].forEach((componentEl) => {
                componentEl.style.display = 'none';
            });

            confirmationContainer.style.display = 'block';
        } else {
            submitBtn.disabled = false;

            submitErrorEl.textContent = 'There was an error submitting your information';
            submitErrorEl && (submitErrorEl.style.display = 'block');
        }
    } catch (error) {
        console.error(error);
        submitBtn.disabled = false;
        submitErrorEl.textContent = 'There was an error submitting your information';
        submitErrorEl && (submitErrorEl.style.display = 'block');
    }
};

const fillConfirmation = (data, getStoreData) => {
    const firstNameEls = confirmationContainer.querySelectorAll('[data-conf-firstName]');
    const lastNameEls = confirmationContainer.querySelectorAll('[data-conf-lastName]');
    const emailEls = confirmationContainer.querySelectorAll('[data-conf-email]');
    const phoneEls = confirmationContainer.querySelectorAll('[data-conf-phone]');
    const zipEls = confirmationContainer.querySelectorAll('[data-conf-zip]');
    const commentsEls = confirmationContainer.querySelectorAll('[data-conf-comments]');
    const vehicleEls = confirmationContainer.querySelectorAll('[data-conf-vehicle]');

    if (data.contactInfo.firstName) {
        firstNameEls.forEach((firstNameEl) => {
            firstNameEl.innerHTML = data.contactInfo.firstName;
        });
    }

    if (data.contactInfo.lastName) {
        lastNameEls.forEach((lastNameEl) => {
            lastNameEl.innerHTML = data.contactInfo.lastName;
        });
    }

    if (data.contactInfo.communicationOptions[0].id) {
        emailEls.forEach((emailEl) => {
            emailEl.innerHTML = data.contactInfo.communicationOptions[0].id;
        });
    }

    if (data.contactInfo.communicationOptions[1].id) {
        phoneEls.forEach((phoneEl) => {
            phoneEl.innerHTML = data.contactInfo.communicationOptions[1].id;
        });
    }

    if (data.location.zipCode) {
        zipEls.forEach((zipEl) => {
            zipEl.innerHTML = data.location.zipCode;
        });
    }

    if (data.other.comments) {
        commentsEls.forEach((commentEl) => {
            commentEl.innerHTML = data.other.comments;
        });
    }

    if (data.vehicle.year && data.vehicle.make && data.vehicle.model && data.vehicle.submodel) {
        vehicleEls.forEach((vehicleEl) => {
            vehicleEl.innerHTML = `${data.vehicle.year} ${data.vehicle.make} ${data.vehicle.model} ${data.vehicle.submodel}`;
        });
    }
    console.log('request a data', data);
    setAdobeDataLayer({
        event: 'service.request.quote.thankyouPage',
        zip: data.location.zipCode,
        serviceType: data.products[0].category,
        storeName: getStoreData().storeName,
        vehicle: data.vehicle,
        telephone: data.contactInfo.communicationOptions[1].id,
        email: data.contactInfo.communicationOptions[0].id
    });
};

const serializeToObject = (rootEl) => {
    let _s_obj = serializeForm(rootEl);

    delete _s_obj['signup-optin'];
    _s_obj['optin'] = rootEl.querySelector('#signup-optin')?.checked ? 'Yes' : 'No';

    return _s_obj;
};

component({
    init,
    instanceInit,
    name: 'Raq',
    selector: '[data-raq]'
});
