import '../scss/blog-article.scss';
import { getLocalStorageItem, setLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
import { viewport } from 'common/js/library/viewport';

let progressBar;
let stickyCtaPopup;
let observer = '';
let offer;
let offerArea;
const init = (config) => {
    progressBar = document.querySelector('.blog-article-progress__bar');
    stickyCtaPopup = document.createElement('div');
    stickyCtaPopup.setAttribute('class', 'sticky-cta-popup');
    stickyCtaPopup.innerHTML = `<button type="button" class="close-cta-popup"></button>`;
    offer = document.querySelectorAll('.offer');
    offerArea = document.querySelectorAll('.offers-area');
};

const instanceInit = (rootEl) => {
    console.log('BlogArticle init');
    let ctaSection = rootEl.querySelector('.blog-sidebar .title-text-cta');
    let articleBody = rootEl.querySelector('[itemprop="articleBody"]');
    let sidebar = rootEl.querySelector('.blog-sidebar');
    let teaserImage = rootEl.querySelector('.teaser-image');
    let backToTop = rootEl.querySelector('.back-to-top__wrapper');
    let currentQuery = viewport.getCurrentQuery();

    injectCtaSection(articleBody, ctaSection);
    createPopupOverlay(rootEl, ctaSection, currentQuery);

    pubsub.subscribe('viewportquerychange', (evt, currentQuery) =>
        handleViewportChange(evt, currentQuery, teaserImage, backToTop)
    );
    initBackToTop(currentQuery, backToTop);
    showReadProgress(rootEl, progressBar);
    updateSidebarArticleCards(sidebar);
    formatPrimaryCategory(rootEl);

    window.addEventListener('scroll', (evt) => showReadProgress(rootEl, progressBar));
    observer.observe(teaserImage);

    checkOfferImage();
};

const checkOfferImage = () => {
    if (offerArea.length && offer.length) {
        [...offer.querySelectorAll('.image-wrap img')].forEach((imageWrap) => {
            imageWrap.addEventListener('error', (e) => {
                e.target.style.display = 'none';
            });
        });
    }
};

const formatPrimaryCategory = (rootEl) => {
    let articleTag = rootEl.querySelector('.article-tag');
    let tag = articleTag.innerText;

    articleTag.innerText = tag.replace(/,/g, ', ');
};

const updateSidebarArticleCards = (sidebar) => {
    [...sidebar.querySelectorAll('.article-card')].forEach((ele) => {
        ele.classList.remove('article-card--is-border-rollover');
        ele.classList.add('article-card--is-animate');
    });
};

const showReadProgress = (rootEl, progressBar) => {
    let progress = 0;
    const scrollTop = window.pageYOffset;
    const offsetTop = rootEl.offsetTop;
    const articleHeight = rootEl.clientHeight - 200;

    if (scrollTop >= offsetTop) {
        if (scrollTop <= offsetTop + articleHeight) {
            progress = (((scrollTop - offsetTop) / articleHeight) * 100).toFixed(2);
        } else {
            progress = 100;
        }
    } else {
        progress = 0;
    }

    progressBar.style.width = `${progress}%`;
    progressBar.setAttribute('aria-valuenow', progress);
};

const handleEvent = (evt) => {
    let currentTarget = evt.currentTarget;

    switch (evt.type) {
        case 'click':
            if (currentTarget.classList.contains('close-cta-popup')) {
                let milliseconds = 24 * 60 * 60 * 1000;
                let expiration = new Date(Date.now() + milliseconds);
                setLocalStorageItem('blogArticleCtaPopupState', { closed: 1 }, expiration);
                removePopupOverlay();
            }
            break;
        default:
            throw TypeError('BlogArticle._handleEvent: Event type not recognized.');
    }
};

const removePopupOverlay = (evt) => {
    window.removeEventListener('scroll', handleScroll);
    pubsub.unsubscribe('windowresize', handleResize);
    stickyCtaPopup.classList.add('close');
    setTimeout(() => {
        stickyCtaPopup.remove();
    }, 300);
};

/**
 * Injects sidebar cta section in article body, after 4 paragraphs.
 * if less then 4 then injects after the last paragraph.
 * @memberof BlogArticle
 * @private
 * @param evt event
 * @return {void}
 */
const injectCtaSection = (articleBody, ctaSection) => {
    let length = 0;

    if (articleBody.querySelectorAll('p').length >= 4) {
        length = 4;
    } else {
        length = articleBody.querySelectorAll('p').length;
    }

    if (length != 0) {
        let ctaSectionClone = ctaSection.cloneNode(true);
        ctaSectionClone.classList.add('inject-cta-section');
        let pElement = articleBody.querySelectorAll('p')[length];
        pElement.parentNode.insertBefore(ctaSectionClone, pElement);
    }
};

/**
 * Create a overlay popup for showing the sidebar cta.
 * Upon closing this overlay won't show for 1 day
 * @memberof BlogArticle
 * @private
 * @param
 * @return {void}
 */
const createPopupOverlay = (rootEl, ctaSection, currentQuery) => {
    let lsItem = getLocalStorageItem('blogArticleCtaPopupState');

    if (!lsItem) {
        rootEl.insertAdjacentElement('beforeend', stickyCtaPopup);
        rootEl.querySelector('.sticky-cta-popup').insertAdjacentElement('beforeend', ctaSection.cloneNode(true));

        stickyCtaPopup?.querySelector('.close-cta-popup').addEventListener('click', handleEvent);

        pubsub.subscribe('windowresize', (evt, currentQuery) => handleResize(currentQuery, rootEl));

        window.addEventListener('scroll', (evt) => handleScroll(evt, currentQuery));

        handleResize(currentQuery, rootEl);
    }
};

const handleResize = (currentQuery, rootEl) => {
    let rightOffset = currentQuery === 'phone' ? 0 : window.innerWidth - (rootEl.offsetLeft + rootEl.clientWidth);
    stickyCtaPopup.style.right = rightOffset;
};

const handleScroll = (evt, currentQuery) => {
    if (currentQuery === 'phone') {
        let top = evt.target.scrollingElement.scrollTop;
        top > 200 ? (stickyCtaPopup.style.display = 'block') : (stickyCtaPopup.style.display = 'none');
    }
};

const handleViewportChange = (evt, currentQuery, teaserImage, backToTop) => {
    if (currentQuery === 'phone') {
        if (stickyCtaPopup) {
            evt.target.scrollTop > 200
                ? (stickyCtaPopup.style.display = 'block')
                : (stickyCtaPopup.style.display = 'none');
        }

        observer.observe(teaserImage);
    } else {
        stickyCtaPopup && (stickyCtaPopup.style.display = 'block');
        observer.unobserve(teaserImage);
        backToTop.style.display = 'none';
    }
};

const initBackToTop = (currentQuery, backToTop) => {
    observer = new window.IntersectionObserver(
        ([entry]) => {
            if (currentQuery === 'phone') {
                if (entry.isIntersecting) {
                    backToTop.style.display = 'none';
                    return;
                }

                if (entry.boundingClientRect.top > 0) {
                    backToTop.style.display = 'none';
                } else {
                    backToTop.style.display = 'block';
                }
            }
        },
        {
            root: null,
            threshold: 0
        }
    );

    backToTop?.querySelector('.back-to-top__link').addEventListener('click', (e) => {
        e.preventDefault();
        scrollToTop(100);
    });
};

const scrollToTop = (duration) => {
    // cancel if already on top
    if (document.scrollingElement.scrollTop === 0) return;

    const totalScrollDistance = document.scrollingElement.scrollTop;
    let scrollY = totalScrollDistance,
        oldTimestamp = null;

    const step = (newTimestamp) => {
        if (oldTimestamp !== null) {
            // if duration is 0 scrollY will be -Infinity
            scrollY -= (totalScrollDistance * (newTimestamp - oldTimestamp)) / duration;
            if (scrollY <= 0) return (document.scrollingElement.scrollTop = 0);
            document.scrollingElement.scrollTop = scrollY;
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    };
    window.requestAnimationFrame(step);
};

component({
    selector: '.blog-article-container',
    init,
    instanceInit,
    name: 'BlogArticle'
});
