import { queryStringify, responseError, HTTPHeaders } from '../serviceUtilities';

// const getRandomInt = (min, max) => {
//     min = Math.ceil(min);
//     max = Math.floor(max);
//     return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
// };

// const generateSummaries = (ids) => {
//     const summaries = ids.map((id) => {
//         let averageRating = getRandomInt(0, 50) / 10;
//         let reviewCount = getRandomInt(0, 352);

//         // make review count 0 more likely to occur.
//         const rand = getRandomInt(0, 5);
//         if (rand == 0) {
//             reviewCount = 0;
//             averageRating = 0;
//         }

//         return { product_id: id, summary: { product_id: id, average_rating: averageRating, review_count: reviewCount, rating_histogram: [2, 0, 2, 1, 5] } };
//     });

//     return summaries;
// };

const getProductSummaries = async (dataPackage) => {
    // /bsro/services/get-review-summaries-by-product-ids?ids=<id1>,….,<idN>[&locale=en_US][&groupId=1234]

    console.log('getProductSummaries:', {
        dataPackage,
        queryStringify: queryStringify(dataPackage)
    });

    // TEMP TEST SERVICE
    // const summaries = generateSummaries(dataPackage.id.split(','));
    // return { success: true, data: summaries };

    // FUTURE SERVICE
    try {
        const url = `/bsro/services/reviews/get-review-summaries-by-product-ids${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const getProductSummariesV2 = async (dataPackage) => {
    // /bsro/services/get-review-summaries-by-product-ids?ids=<id1>,….,<idN>[&locale=en_US][&groupId=1234]

    console.log('getProductSummaries:', {
        dataPackage,
        queryStringify: queryStringify(dataPackage)
    });

    // TEMP TEST SERVICE
    // const summaries = generateSummaries(dataPackage.id.split(','));
    // return { success: true, data: summaries };

    // FUTURE SERVICE
    try {
        const url = `/bsro/services/reviews/get-review-summaries-by-product-ids${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders(['powerreviews'])
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export {getProductSummaries, getProductSummariesV2};