import '../scss/thirty-seventy.scss';
import { component } from 'common/js/library/component';

const instanceInit = (rootEl) => {
    console.log('inside thirty-seventy.js');
    let data = rootEl.getAttribute('data-img-src');

    if (rootEl.classList.contains('thirty-seventy-background-img')) {
        if (data) {
            rootEl.style.backgroundImage = `url(${data})`;
        }
    }
};

component({
    selector: '.thirty-seventy',
    instanceInit,
    name: 'ThirtySeventy'
});
