import { formatTime } from "./formatTime";

const cleanHolidays = (holidays, holidayHours) => {
    var output, len, current, item, i;

    if (!holidays || holidays.length === 0) {
        return [];
    }

    output = [];
    item = {};
    len = holidays.length;

    for (i = 0; i < len; i += 1) {
        current = holidays[i];

        item = current;

        if (!holidayHours) {
            item.openTime = '';
            item.closeTime = '';
        } else {
            // loop through and get the hours
            var hoursFound = false;

            for (var j = 0, len2 = holidayHours.length; j < len2; j += 1) {
                var item2 = holidayHours[j];

                if (current.holidayId === item2.holidayId) {
                    item.openTime = item2.openTime;
                    item.closeTime = item2.closeTime;
                    hoursFound = true;
                }
            }

            if (hoursFound === false) {
                item.openTime = '';
                item.closeTime = '';
            }
        }

        output.push(item);
    }

    // format the times to 12-hour or Closed
    for (i = 0, len = output.length; i < len; i += 1) {
        item = output[i];

        if (item.openTime === '' || item.closeTime === '') {
            item.times = 'Closed';
        } else {
            item.times = formatTime(item.openTime) + '-' + formatTime(item.closeTime);
        }
    }

    return output;
}

export { cleanHolidays };
