import { queryStringify, responseError, HTTPHeaders } from '../serviceUtilities.js';
import { getCache, setCache, getPromise, setPromise } from 'common/js/util/sessionCache';

export const getVehicleLookupApi = async (path, dataPackage, bypassCache = false) => {
    // If bypassCache is true, then fetch the data without using the cache.
    // This is needed for the data-seturl attribute on the submit button in the vehicle lookup form.
    if (bypassCache) {
        try {
            const url = `${path}${queryStringify(dataPackage)}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: HTTPHeaders()
            });
            const responseJson = await response.json();

            if (!response.ok) {
                throw Error(responseError(responseJson));
            }

            return responseJson;
        } catch (err) {
            return { error: err, success: 'false' };
        }
    }

    try {
        const url = `${path}${queryStringify(dataPackage)}`;  // the url is the cache key

        const cachedData = getCache(url);

        // If data cached then return it immediately.
        if (cachedData) {
            return cachedData;
        }

        // If promise cached then wait for it to finish.
        const cachedPromise = getPromise(url);

        if (cachedPromise?.promise) {
            return await cachedPromise.promise  // Wait for the cached promise
                                      .then(response => response.clone())  // Clone the response to be read twice
                                      .then(responseClone => responseClone.json());  // Parse the response to JSON
        }

        // If no promise cached then fetch the data.
        const promise = fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        setPromise(url, promise);  // Cache the promise of the initial fetch

        const response = await promise;  // Wait for the initial fetch

        const responseClone = response.clone();  // Clone the response to be read twice

        const responseJson = await responseClone.json();  // Parse the response to JSON

        if (responseClone.ok) {
            setCache(url, responseJson);  // Cache the response
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};