import { addStoreToProfile } from 'common/js/data/redux/actionCreators/account';
import { setStoreByZip } from 'common/js/data/services/store-location';
import { setZipStoreHtml } from 'common/js/util/geolocation/setZipStoreHtml';
import { store } from 'common/js/data/redux/store/exportedStore';
import { setLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

const setStoreError = (msg) => {
    const errorEl = document.querySelector('.bar .zipcodeError');
    errorEl.innerHTML = msg;
};

const clearStoreError = () => {
    const errorEl = document.querySelector('.bar .zipcodeError');
    errorEl.replaceChildren();
};

const validateZipCode = (zip) => {
    // Pattern for 5-digit zip code or zip+4 code
    let zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;

    if (zipCodePattern.test(zip)) {
        return zip;
    } else {
        return null; // Return null for invalid zip code
    }
};

const handleSetStoreByZip = async (zip, callback) => {
    let validatedZip = validateZipCode(zip);

    if (!validatedZip) {
        setStoreError(`Invalid zip code: ${zip}`);
        return; // Exit early if validation fails
    }

    try {
        let response = await setStoreByZip(validatedZip);

        if (response.success === 'false' || response?.data?.success === 'false') {
            setStoreError(`No stores found for zip: ${validatedZip}`);
        } else {
            clearStoreError();
            callback(response);
        }
    } catch (error) {
        console.error('Error setting store by zip:', error);
        setStoreError(`Error setting store by zip: ${error.message}`);
    }
};

const setStore = () => {
    console.group('set store');

    const zipForms = document.querySelectorAll('#SetZipForm, .set-store-by-zip');
    zipForms.forEach((item) => {
        item.addEventListener('submit', (evt) => {
            evt.preventDefault();

            const currentTarget = evt.currentTarget;
            const zipEl = currentTarget.querySelector('[name*=zip]');
            const zip = zipEl ? zipEl.value.trim() : '';

            // TODO: might need to limit this to the #SetZipForm element in the nav
            if (!window.GEO_FLAG) {
                window._satellite.setVar('Event Data', { zip: zip });
                window._satellite.track('geolocation.submit');
            }

            const callback = (response) => {
                setLocalStorageItem('storeData', response);
                const formAction = currentTarget.getAttribute('action');

                if (formAction === '' || formAction === '#') {
                    // if form action is blank or #: refresh page
                    const storeNumber = response?.data?.storeNumber || '';
                    const globalState = store.getState();

                    if (globalState.account.isLoggedIn === 'true') {
                        store.dispatch(addStoreToProfile(storeNumber));
                    }

                    setZipStoreHtml(zip, storeNumber);

                    if (document.getElementById('tire-search-results-app')) {
                        window.location.reload();
                    }
                } else {
                    // else redirect to action value, find a store, or sched appt
                    window.location.href = formAction;
                }
            };

            handleSetStoreByZip(zip, callback);
        });
    });

    const geoEl = document.querySelectorAll('.set-store-by-geo');
    geoEl.forEach((item) => {
        item.addEventListener('click', (evt) => {
            evt.preventDefault();
            const action =
                window.location.port > 1000 ? '/content/bsro/fcac/en/locate/display-map.html' : '/locate/display-map/';
            const zip = window?.siteProfile?.location?.autoZip || '';

            if (!zip) {
                const storeModal = document.getElementById('find-store-modal');
                storeModal.style.display = '';
                storeModal.querySelector('.search-block .error').innerHTML =
                    'Location services appear to be disabled or not supported for this browser.<br />Enter a zip code and/or enable location services.';
            } else {
                window.location.href = action;
            }
        });
    });

    const findStoresEls = document.querySelectorAll('.find-stores-user-zip, .find-stores-auto-zip');
    findStoresEls.forEach((item) => {
        item.addEventListener('click', (evt) => {
            evt.preventDefault();

            const currentTarget = evt.currentTarget;
            const parent = currentTarget?.parentNode;
            const zipEl = parent?.querySelector('[name*=zip]');
            let zip = zipEl ? zipEl.value.trim() : '';
            const autoZip = currentTarget?.classList?.contains('find-stores-auto-zip');
            let city = null;
            let state = null;

            if (autoZip) {
                zip = window?.siteProfile?.location?.autoZip || null;
            }

            if (parseInt(zip)) {
                const callback = (response) => {
                    const globalState = store.getState();
                    if (globalState.account.isLoggedIn === 'true') {
                        const storeNumber = response?.data?.storeNumber;
                        store.dispatch(addStoreToProfile(storeNumber));
                        setTimeout(() => {
                            window.location.href = '/locate/display-map/';
                        }, 150);
                    } else {
                        window.location.href = '/locate/display-map/';
                    }
                };
                handleSetStoreByZip(zip, callback);
            } else {
                let tmp = zip.split(',');

                if (typeof tmp[1] != 'undefined') {
                    city = tmp[0];
                    state = tmp[1];
                }

                if (city && state) {
                    window.localStorage.setItem('nmsCityState', zip);
                    clearStoreError();
                    window.location.href = '/locate/display-map/';
                } else {
                    setStoreError('Invalid city and state');
                }
            }
        });
    });

    const widgetFindStoreBtns = document.querySelectorAll('.widget-find-store-btn');
    widgetFindStoreBtns.forEach((item) => {
        item.addEventListener('click', (evt) => {
            evt.preventDefault();

            const currentTarget = evt.currentTarget;
            const bar = currentTarget?.parentNode;
            const zipEl = bar?.querySelector('[name=widget-zip]');
            const zip = zipEl ? zipEl.value.trim() : '';
            const callback = (response) => {
                const src = currentTarget.dataset.src;
                const href = currentTarget.getAttribute('href');
                const storeNumber = response?.data?.storeNumber;

                let redirect = href + '?storeNumber=' + storeNumber;

                if (src) {
                    redirect = redirect + '&src=' + src;
                    window.location.href = redirect;
                } else {
                    setZipStoreHtml(zip, storeNumber);
                }
            };
            handleSetStoreByZip(zip, callback);
        });
    });

    if (window?.siteProfile?.location) {
        if (
            window.siteProfile.location.locationLvl == 0 ||
            (window.siteProfile.location.locationLvl == 1 &&
                window.siteProfile.location.autoZip == window?.AEM?.defaultZip)
        ) {
            // Remove the storeNumber property from the object
            delete window.digitalData.user[0].profile[0].attributes.storeNumber;
            showZipForm();
        } else {
            document.body.classList.remove('no-local-store');
            document.body.classList.add('has-local-store');
        }
    }

    console.groupEnd();
};

export { setStore };

const showZipForm = () => {
    if (
        window.siteProfile.location.locationLvl == 0 ||
        (window.siteProfile.location.locationLvl == 1 && window.siteProfile.location.autoZip == window?.AEM?.defaultZip)
    ) {
        document.body.classList.add('no-local-store');
        document.body.classList.remove('has-local-store');
    }
};

export { showZipForm };

window.showZipForm = showZipForm;
