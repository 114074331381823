import { addStores } from './addStores';
import { addStoresHours } from './addStoresHours';
import { filterDateTime } from 'common/js/util/appointment/filter-date-time/filter-date-time';
import { getAvailabilityForMultistores } from 'common/js/data/services/ecomm/appointment';

const getAvailabilityForMultipleStores = async (rootEl, props) => {
    const {
        addedServicesListArr,
        serviceDate,
        numDays,
        getStoresInfo,
        setStoresInfo,
        dateStoreStep,
        completeDateTimeSelection,
        setDateSelectorIndex,
        datesArr,
        preferredStoreNumber
    } = props;

    const dataPackage = {
        storeNumber: getStoresInfo().storeNumbers.toString(),
        serviceNamesList: addedServicesListArr.toString().replace('+', ' Plus').replace(/\s+/g, '+'),
        startDate: serviceDate.toString(),
        numDays
    };

    const response = await getAvailabilityForMultistores(dataPackage);
    const data = response.data;
    const hasTireReplacement = !!document.querySelector('[data-remove-service="Tire Replacement"]');
    const queryParams = new URLSearchParams(window.location.search);
    const isCartFlow = queryParams.get('cartFlow') === 'true';
    const scheduleAppointmentStore = window.scheduleAppointment.stores;

    const hasData = data && data.length > 0;

    if (hasData) {
        const dataObj = filterDateTime(data, scheduleAppointmentStore, hasTireReplacement, isCartFlow);

        setStoresInfo({ availableStores: dataObj });

        addStoresHours(rootEl, {
            data: dataObj,
            dateStoreStep,
            completeDateTimeSelection,
            getStoresInfo,
            setStoresInfo,
            numDays
        });
    } else {
        addStores(rootEl, {
            hasAvailability: false,
            numDays,
            stores: getStoresInfo().stores, //Object.assign({}, getStoresInfo().stores),
            setDateSelectorIndex,
            datesArr,
            preferredStoreNumber,
            getStoresInfo,
            setStoresInfo
        });
    }

    // not sure if this is used anywhere
    window.scheduleAppointment.availableStores = getStoresInfo().availableStores;
};

export { getAvailabilityForMultipleStores };
