/**
 * Check if a field needs to be updated.
 * @param {any} oldValue Current
 * @param {any} newValue Value for the update
 * @returns true if the newValue is set and different from oldValue
 */
function needsUpdate(oldValue, newValue) {
    const hasValue = typeof newValue !== 'undefined' && newValue !== null && newValue !== '';
    return hasValue && newValue !== oldValue;
}

/**
 * Compare two values which are optional. If either value is undefined return true.
 * Otherwise compare the values.
 * @param {any} a
 * @param {any} b
 * @returns true if values match or don't care.
 */
function compareOptional(a, b) {
    if (typeof a === 'undefined' || typeof b === 'undefined') {
        return true;
    }
    return a == b;
}

export class Vehicle {
    id;
    year;
    make;
    model;
    trim;
    tpms;
    engine;
    acesid;
    nickname;
    odometer;
    drivingCondition;
    imageUrl;

    constructor(specs) {
        const {
            id,
            year,
            make,
            model,
            trim,
            tpms,
            engine,
            acesid,
            nickname,
            odometer,
            imageUrl,
            drivingCondition,
            vehicleId
        } = specs;
        const yr = parseInt(year);
        this.year = isNaN(yr) ? undefined : yr;
        if (!this.year) {
            return;
        }
        this.make = make;
        if (!this.make) {
            return;
        }
        this.model = model;
        if (!this.model) {
            return;
        }
        this.id = id;
        this.trim = trim;
        this.tpms = tpms;
        this.engine = engine;
        this.acesid = vehicleId ? vehicleId : acesid;
        this.nickname = nickname;
        this.odometer = odometer;
        this.imageUrl = imageUrl;
        this.drivingCondition = drivingCondition;
    }

    toYMMString() {
        return `${this.year}-${this.make}-${this.model}`;
    }

    /**
     * Check if two vehicles are equal.
     * @param {*} vehicle
     * @returns true if the vehicles are equal, false otherwise
     */
    equals(vehicle) {
        if (!vehicle) {
            return false;
        }
        const ymmEquals = this.year === vehicle.year && this.make === vehicle.make && this.model === vehicle.model;
        // compare optional properties
        return (
            ymmEquals &&
            compareOptional(this.id, vehicle.id) &&
            compareOptional(this.trim, vehicle.trim) &&
            compareOptional(this.engine, vehicle.engine) &&
            compareOptional(this.nickname, vehicle.nickname)
        );
        // don't bother comparing other properties.
    }

    /**
     * Update this vehicle with the properties of the given vehicle.
     * @param {*} vehicle
     * @returns true if any properties were updated, false otherwise
     */
    update(vehicle) {
        let changed = false;
        if (needsUpdate(this.trim, vehicle.trim)) {
            this.trim = vehicle.trim;
            changed = true;
        }
        if (needsUpdate(this.tpms, vehicle.tpms)) {
            this.tpms = vehicle.tpms;
            changed = true;
        }
        if (needsUpdate(this.engine, vehicle.engine)) {
            this.engine = vehicle.engine;
            changed = true;
        }
        if (needsUpdate(this.acesid, vehicle.acesid)) {
            this.acesid = vehicle.acesid;
            changed = true;
        }
        if (needsUpdate(this.nickname, vehicle.nickname)) {
            this.nickname = vehicle.nickname;
            changed = true;
        }
        if (needsUpdate(this.odometer, vehicle.odometer)) {
            this.odometer = vehicle.odometer;
            changed = true;
        }
        if (needsUpdate(this.drivingCondition, vehicle.drivingCondition)) {
            this.drivingCondition = vehicle.drivingCondition;
            changed = true;
        }
        if (needsUpdate(this.imageUrl, vehicle.imageUrl)) {
            this.imageUrl = vehicle.imageUrl;
            changed = true;
        }
        return changed;
    }
}
