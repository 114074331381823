import { getProductReviewsV2 } from 'common/js/data/services/reviews/reviews';

/**
 * helper function for making and responding to review data request
 * @param {function} callback - callback function to run after successful response
 * @param {string} id - PowerReviews groupId?
 * @param {string} sortBy - PowerReviews locale
 * @returns
 */
const setProductReviews = async (callback, id, sortBy, pageSize) => {
    let responseData = {};

    const tireResponse = await getProductReviewsV2({ id, sortBy, pageSize });
    if (tireResponse) {
        responseData = tireResponse;
    }

    if (Object.keys(responseData).length > 0) {
        callback(responseData);
        return true;
    } else {
        return false;
    }
};

export { setProductReviews };
