import {
    ACCOUNT_LOGIN_LOADING,
    ACCOUNT_LOGIN_USER,
    ACCOUNT_LOGOUT_USER,
    ACCOUNT_REMOVE_VEHICLE,
    ACCOUNT_SET_FULL_PROFILE,
    ACCOUNT_SET_VEHICLES
} from '../actions';

import { addStoreLocationToAccount, setPreferredStoreToAccount } from '../../services/account/store-location';

export function accountSetFullProfile(account) {
    return {
        type: ACCOUNT_SET_FULL_PROFILE,
        payload: account
    };
}

export function accountRemoveVehicle(newProfile) {
    console.log('new profile IN ACTION CREATOR: ', newProfile);
    return {
        type: ACCOUNT_REMOVE_VEHICLE,
        payload: newProfile
    };
}

export function setStoreAsPreferredLocation(storeNumber) {
    return async (dispatch, getState) => {
        try {
            const response = await setPreferredStoreToAccount(storeNumber);
            console.log('setStoreAsPreferredLocation(' + storeNumber + ') response: ', response);
            if (response.success === 'true') {
                dispatch(accountSetFullProfile(response.data.account));
                return true;
            } else {
                return false;
            }
        } catch (err) {
            // TODO: dispatch an error message to be consumed by UI or fire an event notifying someone/thing of the failure
            console.log("there's been an error in setStoreAdPreferredLocation(storeNumber); ", { err });
        }
    };
}

export function addStoreToProfile(storeNumber) {
    return async (dispatch, getState) => {
        try {
            const response = await addStoreLocationToAccount(storeNumber);

            if (response.success === 'true') {
                dispatch(accountSetFullProfile(response.data.account));
            }

            return response;

            // TODO: consider adding redux-level error states as opposed to form level
            // probably doesn't make sense to do so but worth discussing
        } catch (err) {
            console.log('error');
        }
    };
}

export function accountLoginLoading() {
    return {
        type: ACCOUNT_LOGIN_LOADING,
        payload: {
            isLoggedIn: 'loading',
            tokenId: null
        }
    };
}

export function accountLoginUser(profile) {
    console.log('PROFILE: ', { profile });
    return {
        type: ACCOUNT_LOGIN_USER,
        payload: {
            isLoggedIn: profile.isLoggedIn,
            tokenId: profile.tokenId
        }
    };
}

export function accountLogoutUser() {
    return {
        type: ACCOUNT_LOGOUT_USER,
        payload: {
            isLoggedIn: 'false',
            tokenId: null
        }
    };
}

export function setAccountVehicles(vehicles) {
    return {
        type: ACCOUNT_SET_VEHICLES,
        payload: vehicles
    };
}
