import Handlebars from 'handlebars';
import { storeToMapUrl } from '../util/storeToMapURL';

let zoom60Img = 'https://s7d1.scene7.com/is/image/bridgestone/nopic-images-tires-zoom-60-global-web-bsro';
let full60Img = 'https://s7d1.scene7.com/is/image/bridgestone/nopic-images-tires-full-60-h175-global-web-bsro';

Handlebars.registerHelper('if', function (conditional, options) {
    if (conditional) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
    switch (operator) {
        case 'eq':
            return v1 == v2 ? options.fn(this) : options.inverse(this);
        case 'eq1':
            return v1 === v2 ? options.fn(this) : options.inverse(this);
        case 'ne':
            return v1 != v2 ? options.fn(this) : options.inverse(this);
        case 'lt':
            return v1 < v2 ? options.fn(this) : options.inverse(this);
        case 'lte':
            return v1 <= v2 ? options.fn(this) : options.inverse(this);
        case 'gt':
            return v1 > v2 ? options.fn(this) : options.inverse(this);
        case 'gte':
            return v1 >= v2 ? options.fn(this) : options.inverse(this);
        case 'and':
            return v1 && v2 ? options.fn(this) : options.inverse(this);
        case 'or':
            return v1 || v2 ? options.fn(this) : options.inverse(this);
        default:
            return options.fn(this);
    }
});

Handlebars.registerHelper('yearConvertor', function (month) {
    var year = 0;
    try {
        year = parseInt(month);
        year = year / 12;
    } catch (e) {
        console.error('Performance Warranty Month, is not a number');
    }
    return year;
});

Handlebars.registerHelper('formatCurrency', function (price) {
    var properties = {},
        num,
        dollars;
    num = parseFloat(price);
    if (num !== undefined && num !== null) dollars = Math.floor(num);
    properties.dollars = dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    properties.cents = Math.round((num - dollars) * 100);
    if (properties.cents < 10) {
        properties.cents = '0' + properties.cents;
    }

    return properties.dollars + '.<span class="cents">' + properties.cents + '*</span>';
});

function selectHelper(value, options) {
    var selectEl = document.createElement('select');
    selectEl.innerHTML = options.fn(this);

    var optionsEls = selectEl.querySelectorAll('option');
    for (var i = 0; i < optionsEls.length; i++) {
        var optionEl = optionsEls[i];
        if (optionEl.value === value) {
            optionEl.selected = true;
            break;
        }
    }

    return selectEl.innerHTML;
}

Handlebars.registerHelper('select', selectHelper);

Handlebars.registerHelper('numberFormatter', function (num) {
    var data = parseFloat(num) || 0;
    if (data !== undefined && data !== null) {
        var value = data.toFixed(2);
        var price = value.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    return price;
});

Handlebars.registerHelper('formatNumber', function (num) {
    if (num !== undefined && num !== null) var mileage = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return mileage;
});

Handlebars.registerHelper('priceSeparator', function (price) {
    var properties = {},
        num,
        dollars;
    num = parseFloat(price);
    if (price !== undefined && price !== null) dollars = Math.floor(num);
    properties.dollars = dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    properties.cents = Math.round((num - dollars) * 100);
    if (properties.cents < 10) {
        properties.cents = '0' + properties.cents;
    }

    return properties.dollars + '.<span class="cents">' + properties.cents + '*</span>';
});

Handlebars.registerHelper('bundleWithSpan', function (data) {
    if (data === undefined) return '';
    else return '<span>' + data + '</span>';
});

Handlebars.registerHelper('offerDescriber', function (tireCoupon, rearTireCoupon) {
    var couponsTag = '';
    var tempPath = '';
    if (tireCoupon !== undefined) {
        $.each(tireCoupon.offers, function (key, value) {
            tempPath = '/offers/offer-detail/' + value.promoName;
            couponsTag +=
                "<span><a href='" + tempPath + "'  target='_blank'>" + value.promoSmallImgText + '</a></span>';
        });
    }
    if (rearTireCoupon !== undefined) {
        $.each(tireCoupon.offers, function (key, value) {
            tempPath = '/offers/offer-detail/' + value.promoName;
            couponsTag +=
                "<span><a href='" + tempPath + "'  target='_blank'>" + value.promoSmallImgText + '</a></span>';
        });
    }
    return couponsTag != '' ? couponsTag : 'N/A';
});

Handlebars.registerHelper('checkForRetailPrice', function (price, rearPrice) {
    var priceSection = '';
    var valid = Handlebars.helpers.priceValidator(price, rearPrice);
    if (valid) {
        priceSection = '<span>' + price + '</span>';
        if (rearPrice) {
            priceSection += '<span>' + rearPrice + '</span>';
        }
    } else {
        priceSection = '<span>Please call your local store for pricing</span>';
    }
    return priceSection;
});

Handlebars.registerHelper(
    'checkForGetQuoteBtn',
    function (tire, rearTire, phone, tireInventoryId, tireInventoryQuantity) {
        var getQuoteBtnSection = '';
        var valid = false;
        // var articleId = tire.article;
        // var quantity = 4;
        var cleanPhone = (phone && phone.toString().replace('(', '').replace(')', '').replace(' ', '-')) || '';

        if (tire) {
            if (rearTire != null) {
                valid = Handlebars.helpers.priceValidator(tire.retailPrice, rearTire.retailPrice);
                // articleId = articleId + '_' + rearTire.article;
                // quantity = 2;
            } else {
                valid = Handlebars.helpers.priceValidator(tire.retailPrice, rearTire);
            }
        }

        if (window.isEcom) {
            if (!window.tceStoreId) {
                valid = false;
            }
        }

        if (valid) {
            //var url="/content/bsro/"+window.AEM.siteName+"/en/tires/tire-quote.html?articleId=" + articleId + '&quantity=' + quantity;
            /* if (tireInventoryId && tireInventoryQuantity) {
    		getQuoteBtnSection = '<button class="fpo">Get Quote</button>'; // '<button data-article="'+ articleId +'" data-quantity="' + quantity + '" data-inventory-id="' + tireInventoryId + '" data-inventory-quantity="' + tireInventoryQuantity + '" class="btn tire-quote-btn">Get Quote</button>';
    	} else {
    		getQuoteBtnSection = '<button data-article="'+ articleId +'" data-quantity="' + quantity + '" class="btn tire-quote-btn">Get Quote</button>';
    	} */
            getQuoteBtnSection = '<button class="fpo">Get Quote</button>';
        } else {
            getQuoteBtnSection = '<span>Please call your local store for pricing</span>';
            if (phone) {
                getQuoteBtnSection += ' <span>' + cleanPhone + '</span>';
            }
        }
        return getQuoteBtnSection;
    }
);

Handlebars.registerHelper('priceValidator', function (price, rearPrice) {
    var tireStatus = false;
    var rearTireStatus = true;
    if (price) {
        if (price == '0.0') {
            tireStatus = false;
        } else {
            tireStatus = true;
        }
    }
    if (rearPrice) {
        if (rearPrice == '0.0') {
            rearTireStatus = false;
        } else {
            rearTireStatus = true;
        }
    }
    if (tireStatus && rearTireStatus) {
        return true;
    } else {
        return false;
    }
});

Handlebars.registerHelper('concat', function (type, text, section, path, suffix, target, classname) {
    var prefix;
    if (section == 'offer-detail') prefix = '/content/bsro/' + window.AEM.siteName + '/en/offers/offer-detail-html.';

    if (section == 'warranty-options') prefix = '/content/bsro/' + window.AEM.siteName + '/en/tires/warranty-options';

    var url = prefix + path + suffix;

    if (section == 'battery-quote')
        url = '/bridgestone/' + path + '-static-' + window.AEM.siteName + '-' + window.AEM.siteName + '-web-bsro';

    if (type == 'link') {
        return Handlebars.helpers.link(text, url, target, classname);
    } else {
        return url;
    }
});

Handlebars.registerHelper('link', function (text, url, target, classname) {
    url = Handlebars.Utils.escapeExpression(url);
    target = Handlebars.Utils.escapeExpression(target);

    target = target == 'null' ? '' : target;

    classname = Handlebars.Utils.escapeExpression(classname);

    var result = '<a href="' + url + '" target="' + target + '" class="' + classname + '">' + text + '</a>';

    return new Handlebars.SafeString(result);
});

Handlebars.registerHelper('link-icon', function (text, url, target, classname) {
    url = Handlebars.Utils.escapeExpression(url);
    target = Handlebars.Utils.escapeExpression(target);
    target = target == 'null' ? '' : target;

    classname = Handlebars.Utils.escapeExpression(classname);

    var result =
        '<a href="' +
        url +
        '" target="' +
        target +
        '" class="' +
        classname +
        '">' +
        '<div class="icon"></div>' +
        '<div class="label">' +
        text +
        '</div></a>';

    return new Handlebars.SafeString(result);
});

Handlebars.registerHelper('imgZoom60', function (data) {
    if (data === undefined) return '<img src="' + zoom60Img + '" alt="">';
    else {
        var result =
            '<img src="https://s7d1.scene7.com/is/image/bridgestone/' +
            data +
            '-60-zoom-web-global-bsro" onError="this.onerror=null;this.src=' +
            zoom60Img +
            '" alt="">';
        return new Handlebars.SafeString(result);
    }
});

Handlebars.registerHelper('imgFull60', function (data) {
    var result;
    if (data === undefined) {
        return '<img src="' + full60Img + '" alt="">';
    } else if (window.isEcom) {
        result =
            '<img src="https://s7d1.scene7.com/is/image/bridgestone/' +
            data +
            '-60-full-web-global-bsro" onError="this.onerror=null;this.src=' +
            full60Img +
            '" alt="">';
        return new Handlebars.SafeString(result);
    } else {
        var _imgPath =
            data.indexOf('full-60') != -1
                ? data
                : 'https://s7d1.scene7.com/is/image/bridgestone/' + data + '-60-full-web-global-bsro';
        result = '<img src="' + _imgPath + '" onError="this.onerror=null;this.src=' + full60Img + '" alt="">';
        return new Handlebars.SafeString(result);
    }
});

Handlebars.registerHelper('currentDate', function () {
    var tmp = new Date();
    var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    var year = tmp.getFullYear();
    var month = months[tmp.getMonth()];
    var date = tmp.getDate();
    var currentDate = month + ' ' + date + ', ' + year;
    return currentDate;
});

Handlebars.registerHelper('checkCharacteristic', function (tireClassName, tireName) {
    var icons = '';
    if (tireClassName && tireClassName.indexOf('All Season') != -1) {
        icons = icons + "<i class='icon-all-season'></i>";
    }
    if (tireClassName && tireClassName.indexOf('Performance') != -1) {
        icons = icons + "<i class='icon-performance'></i>";
    }
    if (tireClassName && tireClassName.indexOf('Summer') != -1) {
        icons = icons + "<i class='icon-summer'></i>";
    }
    if (tireClassName && tireClassName.indexOf('Snow') != -1) {
        icons = icons + "<i class='icon-winter'></i>";
    }
    /*if (tireClassName && tireClassName.indexOf("Max Traction") != -1){
		icons = icons + "<i class=''></i>";
	}*/
    if (tireClassName && tireClassName.indexOf('All Terrain') != -1) {
        icons = icons + "<i class='icon-all-terrain'></i>";
    }
    if (tireName && tireName.indexOf('Ecopia') != -1) {
        icons = icons + "<i class='icon-eco-friendly'></i>";
    }
    /*if(tireName && tireName.indexOf("RFT") != -1){
		icons = icons + "<i class=''></i>";
	}*/
    return icons;
});

Handlebars.registerHelper('quoteMath', function (quoteItem) {
    var wheelBalance = isNaN(quoteItem.wheelBalance) ? 0 : parseFloat(quoteItem.wheelBalance);
    var valveStem = isNaN(quoteItem.valveStem) ? 0 : parseFloat(quoteItem.valveStem);
    var tpmsValveServiceKit = isNaN(quoteItem.tpmsValveServiceKit) ? 0 : parseFloat(quoteItem.tpmsValveServiceKit);
    var tpmsValveServiceKitLabor = isNaN(quoteItem.tpmsValveServiceKitLabor)
        ? 0
        : parseFloat(quoteItem.tpmsValveServiceKitLabor);
    var stateEnvironmentalFee = isNaN(quoteItem.stateEnvironmentalFee)
        ? 0
        : parseFloat(quoteItem.stateEnvironmentalFee);
    var scrapTireRecyclingCharge = isNaN(quoteItem.scrapTireRecyclingCharge)
        ? 0
        : parseFloat(quoteItem.scrapTireRecyclingCharge);
    var shopSupplies = isNaN(quoteItem.shopSupplies) ? 0 : parseFloat(quoteItem.shopSupplies);
    var tax = isNaN(quoteItem.tax) ? 0 : parseFloat(quoteItem.tax);

    var total =
        wheelBalance +
        valveStem +
        tpmsValveServiceKit +
        tpmsValveServiceKitLabor +
        stateEnvironmentalFee +
        scrapTireRecyclingCharge +
        shopSupplies +
        tax;

    return total.toFixed(2);
});

Handlebars.registerHelper('cleanPhone', function (num) {
    if (num !== undefined && num !== null)
        var phone = num.toString().replace('(', '').replace(') ', '.').replace('-', '.');

    return phone;
});

Handlebars.registerHelper('timeConvertor', function (time) {
    if (time !== undefined && time !== null)
        var splitTime = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (splitTime.length > 1) {
        // If time format correct
        splitTime = splitTime.slice(1); // Remove full string match value
        splitTime[5] = +splitTime[0] < 12 ? 'am' : 'pm'; // Set am/pm
        splitTime[0] = +splitTime[0] % 12 || 12; // Adjust hours
    }
    return splitTime.join(''); // return adjusted time or original string
});

Handlebars.registerHelper('getDirectionsLink', function (storeNumber) {
    var url = '';

    if (location.hostname == 'localhost') {
        url = '/content/bsro/' + window.AEM.siteName + '/en/locate/get-directions.' + storeNumber + '.html';
    } else {
        url = '/locate/get-directions/' + storeNumber;
    }

    var result =
        '<a href="' +
        url +
        '" target="_blank" class="directions-cta directions"><img src="/etc.clientlibs/bsro/common/clientlibs/clientlib-images/v1/resources/icon/post.png" alt=""><div class="label directions">Directions</div></a>';

    return new Handlebars.SafeString(result);
});

Handlebars.registerHelper('siteFullName', function () {
    var siteName = window.AEM.siteName;
    var siteFullName = '';

    if (siteName == 'fcac') {
        siteFullName = 'Firestone Complete Auto Care';
    } else if (siteName == 'tiresplus') {
        siteFullName = 'Tires Plus';
    } else if (siteName == 'wheelworks') {
        siteFullName = 'Wheel Works';
    }

    return siteFullName;
});

Handlebars.registerHelper('checkSiteName', function (siteName, options) {
    if (siteName == window.AEM.siteName) return options.fn(this);
    else return options.inverse(this);
});

Handlebars.registerHelper('formatPhone', function (phoneNumber, separator) {
    var _cleanPhoneNumber = phoneNumber.replace(/\D/g, ' ');
    var _numbers = _cleanPhoneNumber.match(/\d{3,4}/g);
    var _separator = separator || '';

    return _numbers.join(_separator);
});

Handlebars.registerHelper('hrefAttr', function (url) {
    return new Handlebars.SafeString(`href="${url}"`);
});

Handlebars.registerHelper('localPageMapUrl', function (state, city, address) {
    let store = {
        address: address,
        state: state,
        city: city
    };
    let addressUrl = storeToMapUrl(store);
    return new Handlebars.SafeString(addressUrl);
});

export default Handlebars;
