import { HTTPHeaders, queryStringify } from '../serviceUtilities.js';

export const batterySearchByVehicle = (dataPackage) => {
    const url = `/bsro/services/battery/search-by-vehicle${queryStringify(dataPackage)}`;
    return fetch(url, {
        method: 'GET',
        headers: HTTPHeaders()
    })
        .then((response) => response.json())
        .catch((error) => {
            return { error, success: 'false' };
        });
};
