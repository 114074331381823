import '../scss/footer.scss';
import { component } from 'common/js/library/component';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

let footerNavigation;

const init = (config) => {
    footerNavigation = document.querySelector(config?.selector);

    if (!footerNavigation) return;

    const mobileBreakpoint = window.matchMedia('(max-width: 767px)');

    mobileBreakpoint.addEventListener('change', () => {
        breakpointFooterChange(mobileBreakpoint);
    });

    breakpointFooterChange(mobileBreakpoint);
};

const instanceInit = (rootEl) => {
    if (!rootEl) return;

    const links = footerNavigation?.querySelector('.links');
    const tools = footerNavigation?.querySelector('.tools');
    const columnsLinks = links?.querySelectorAll('.column');
    const columnsTools = tools?.querySelectorAll('.column');

    [...columnsLinks].forEach((el) => {
        const simpleText = el?.querySelectorAll('.simple-text');
        [...simpleText].forEach((el) => {
            el.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                if (event.currentTarget.classList.contains('is-active')) {
                    event.currentTarget.classList.remove('is-active');
                } else {
                    event.currentTarget.classList.add('is-active');
                    setAdobeDataLayer({
                        event: 'ui_interaction',
                        ui_type: 'accordion',
                        ui_text: event.currentTarget.innerText,
                        ui_location: 'footer navigation'
                    });
                }
                const footerlinks = event.currentTarget.parentElement?.querySelectorAll('.footerlinks');
                const simpleTexts = event.currentTarget.parentElement?.querySelectorAll('.simple-text');
                if (simpleTexts.length > 1) {
                    if (event.currentTarget.nextElementSibling.style.maxHeight) {
                        event.currentTarget.nextElementSibling.style.maxHeight = null;
                    } else {
                        event.currentTarget.nextElementSibling.style.maxHeight =
                            event.currentTarget.nextElementSibling.scrollHeight + 'px';
                    }
                } else {
                    [...footerlinks].forEach((el) => {
                        if (el.style.maxHeight) {
                            el.style.maxHeight = null;
                        } else {
                            el.style.maxHeight = el.scrollHeight + 'px';
                        }
                    });
                }
            });
        });
    });

    [...columnsTools].forEach((el) => {
        let haveQuestionsLink = el?.querySelector('.have-questions .cta-link');
        if (el?.querySelector('.component .footer-tool .footer-heading')) {
            const footerHeading = el?.querySelector('.component .footer-tool .footer-heading');
            footerHeading.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                if (event.currentTarget.classList.contains('is-active')) {
                    event.currentTarget.classList.remove('is-active');
                } else {
                    event.currentTarget.classList.add('is-active');
                    setAdobeDataLayer({
                        event: 'ui_interaction',
                        ui_type: 'accordion',
                        ui_text: event.currentTarget.innerText,
                        ui_location: 'footer navigation'
                    });
                }
                const wrap = event.currentTarget.parentElement?.querySelector('.wrap');
                if (wrap.style.maxHeight) {
                    wrap.style.maxHeight = null;
                } else {
                    wrap.style.maxHeight = el.scrollHeight + 'px';
                }
            });
        } else if (haveQuestionsLink) {
            haveQuestionsLink.addEventListener('click', () => {
                setAdobeDataLayer({
                    event: 'cta_click',
                    cta_type: 'contact',
                    cta_text: 'contact us',
                    cta_location: 'footer navigation'
                });
            });
        }
    });
};

const breakpointFooterChange = (breakpoint) => {
    if (breakpoint.matches) {
        const tools = footerNavigation?.querySelector('.tools');
        const columnToolFirestoneApp = tools?.querySelector('.column.firestone-app');
        const footerTool = columnToolFirestoneApp?.querySelector('.footer-tool');
        const footerHeading = footerTool?.querySelector('.footer-heading');
        const wrap = footerTool?.querySelector('.wrap');
        if (!footerTool.classList.contains('active') && !footerHeading.classList.add('is-active')) {
            footerTool.classList.add('active');
            footerHeading.classList.add('is-active');
            if (wrap.style.maxHeight) {
                wrap.style.maxHeight = null;
            } else {
                wrap.style.maxHeight = columnToolFirestoneApp.scrollHeight + 'px';
            }
        }
    }
};

component({
    selector: '.footer-navigation.footer-v2',
    instanceInit,
    init,
    name: 'FooterNavigation'
});
