import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
let hamburger;
let mainNavWrapperContainer;
let tireSubNavContent;
let repairSubNavContent;
let maintenanceSubNavContent;
let mobileBreakpoint;
let isNavMobile;

const init = (config) => {
    hamburger = document.querySelector('.hamburger--btn');
    mainNavWrapperContainer = document.querySelector('.menu-left');
    tireSubNavContent = document.querySelector('.top-nav-v2 .sub-nav-content.tires');
    repairSubNavContent = document.querySelector('.top-nav-v2 .sub-nav-content.repair');
    maintenanceSubNavContent = document.querySelector('.top-nav-v2 .sub-nav-content.maintenance');

    mobileBreakpoint = window.matchMedia('(max-width: 767px)');

    mobileBreakpoint.addEventListener('change', () => {
        breakpointChange(mobileBreakpoint);
    });

    breakpointChange(mobileBreakpoint);
};

const breakpointChange = (breakpoint) => {
    if (breakpoint.matches) {
        isNavMobile = true;
    } else {
        isNavMobile = false;
        if (mainNavWrapperContainer) {
            mainNavWrapperContainer.classList.remove('is-active');
            tireSubNavContent.classList.remove('is-active');
            repairSubNavContent.classList.remove('is-active');
            maintenanceSubNavContent.classList.remove('is-active');
        }
    }
};

const instanceInit = (rootEl) => {
    let tireSubNav = rootEl.querySelector('li.tires > a');
    let repairSubNav = rootEl.querySelector('li.repair > a');
    let maintenanceSubNav = rootEl.querySelector('li.maintenance > a');
    let mobileBackEls = rootEl.querySelectorAll('h3.back');
    let closeBtn = rootEl.querySelector('.mobile-close-btn');

    hamburger.addEventListener('click', (evt) => {
        if (hamburger.classList.contains('is-active')) {
            hamburger.classList.remove('is-active');
            mainNavWrapperContainer.classList.remove('is-active');
        } else {
            hamburger.classList.add('is-active');
            mainNavWrapperContainer.classList.add('is-active');
            pubsub.publish('on-subnav-open');
        }
    });

    tireSubNav.addEventListener('click', (event) => {
        if (isNavMobile) {
            event.preventDefault();
            tireSubNavContent.classList.add('is-active');
        }
    });

    repairSubNav.addEventListener('click', (event) => {
        if (isNavMobile) {
            event.preventDefault();
            repairSubNavContent.classList.add('is-active');
        }
    });

    maintenanceSubNav.addEventListener('click', (event) => {
        if (isNavMobile) {
            event.preventDefault();
            maintenanceSubNavContent.classList.add('is-active');
        }
    });

    [...mobileBackEls].forEach((mobileBackEl) => {
        mobileBackEl.addEventListener('click', (event) => {
            if (event.target.classList.contains('mobile')) {
                event.target.closest('.sub-nav-content')?.classList?.remove('is-active');
            }
        });
    });

    closeBtn?.addEventListener('click', () => {
        hamburger.classList.remove('is-active');
        mainNavWrapperContainer.classList.remove('is-active');
        tireSubNavContent.classList.remove('is-active');
        repairSubNavContent.classList.remove('is-active');
        maintenanceSubNavContent.classList.remove('is-active');
    });
};

component({
    selector: '.top-nav-v2 .primary-nav-v2 .main-nav-wrapper-container .main-nav-wrapper',
    instanceInit,
    init,
    name: 'MobileNavV2'
});
