import { setCookie } from 'common/js/library/util';
import { store as ReduxStore } from 'common/js/data/redux/store/exportedStore';

const getAccountState = (setIsLoggedIn, setPreferredStoreNumber) => {
    const currentState = ReduxStore.getState();
    if (currentState.account.isLoggedIn === 'true') {
        setIsLoggedIn('true');

        let profile = currentState.account.profile;
        let accountDriver = profile.DRIVER[0];
        let accountStore = profile.STORE;
        let accountPreferredStoreId = accountDriver && accountDriver.PREFERRED_STORE;

        for (var i = 0; i < accountStore.length; i++) {
            let _store = accountStore[i];

            if (_store.ID == accountPreferredStoreId) {
                setPreferredStoreNumber(_store.STORE_NUMBER);
                setCookie('hasSetPreferredStore', true);

                break;
            }
        }
    } else {
        setCookie('hasSetPreferredStore', false);
    }
};

export { getAccountState };
