import '../scss/email-signup.scss';
import { getUrlParam, validateElement } from 'common/js/library/util';
import { component } from 'common/js/library/component';
import { emailSignUp } from 'common/js/data/services/emailSignUp';
import { pubsub } from 'common/js/library/pubsub';
import { viewport } from 'common/js/library/viewport';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const instanceInit = (rootEl) => {
    console.log('inside email-signup.js');

    let signupBtn = rootEl.querySelector('.email-signup-btn');
    let coralWaitContainer = rootEl.querySelector('.coral-wait-container');

    // event handlers
    signupBtn.addEventListener('click', (evt) => handleSubmit(evt, signupBtn, rootEl, coralWaitContainer));

    let inputFields = rootEl.querySelectorAll('#email-signup-form input');
    [...inputFields].forEach((inputField) => {
        inputField.addEventListener('change', (evt) => handleFieldChange(evt, signupBtn, rootEl));
        inputField.addEventListener('blur', (evt) => handleFieldChange(evt, signupBtn, rootEl));
        inputField.addEventListener('keyup', (evt) => handleFieldChange(evt, signupBtn, rootEl));
        inputField.addEventListener('blur', (evt) => handleLabelBlur(evt));
        inputField.addEventListener('focus', (evt) => handleLabelFocus(evt));
    });

    pubsub.subscribe('viewportquerychange', (evt, currentQuery) => handleResize(evt, currentQuery, rootEl));

    // initialize
    initEmailSignUp(rootEl, coralWaitContainer);
};

const initEmailSignUp = (rootEl, coralWaitContainer) => {
    let emailId = localStorage.getItem('emailID');
    let _emailParam = getUrlParam('name') || getUrlParam('email');

    if (_emailParam) {
        console.log('_emailParam:', _emailParam);

        emailId = decodeURIComponent(_emailParam);
        localStorage.setItem('emailID', _emailParam);
    }

    if (emailId) {
        rootEl.querySelector('#email-address').value = emailId;
        rootEl.querySelector('#email-confirm').value = emailId;
    }

    coralWaitContainer && (coralWaitContainer.style.display = 'none');

    hideCompletedInputs(viewport.currentQuery, rootEl);
};

const handleResize = (evt, currentQuery, rootEl) => {
    hideCompletedInputs(currentQuery, rootEl);
};

const hideCompletedInputs = (viewport, rootEl) => {
    let inputEls = rootEl.querySelectorAll('input[type="text"],input[type="email"],input[type="password"],textarea');

    if (!viewport) {
        viewport = 'desktop';
    }

    [...inputEls].forEach((inputEl) => {
        let label = inputEl.previousElementSibling;
        label && (label.style.transition = 'opacity 1s');
        if (inputEl.value && inputEl.value.length > 0 && viewport === 'phone') {
            label && (label.style.opacity = 0);
        } else {
            label && (label.style.opacity = 1);
        }
    });
};

const handleLabelBlur = (evt) => {
    const label = evt.currentTarget.previousElementSibling;
    const inputEl = evt.currentTarget;
    label && (label.style.transition = 'opacity 1s');
    if (inputEl.value && inputEl.value.length > 0 && viewport.currentQuery === 'phone') {
        label && (label.style.opacity = 0);
    } else {
        label && (label.style.opacity = 1);
    }
};

const handleLabelFocus = (evt) => {
    const label = evt.currentTarget.previousElementSibling;
    label && (label.style.opacity = 1);
};

const handleFieldChange = (evt, signupBtn, rootEl) => {
    let data = getFormValues(rootEl);
    let disabled = true;

    console.log('email-signup form data: ', data);

    if (validate(data)) {
        disabled = false;
    }

    signupBtn.disabled = disabled;
};

const handleSubmit = (evt, signupBtn, rootEl, coralWaitContainer) => {
    let data = getFormValues(rootEl);

    if (validate(data)) {
        let emailValidRes = validateElement(data.email, 'email');
        let zipValidRes = validateElement(data.zip, 'zipcode');

        if (emailValidRes && zipValidRes) {
            sendData(data, signupBtn, rootEl, coralWaitContainer);
        }
    } else {
        console.log('invalid data');
    }
};

const getFormValues = (rootEl) => {
    return {
        firstName: rootEl.querySelector('#first-name')?.value,
        lastName: rootEl.querySelector('#last-name')?.value,
        email: rootEl.querySelector('#email-address')?.value.trim(),
        confirmEmail: rootEl.querySelector('#email-confirm')?.value.trim(),
        address: rootEl.querySelector('#address')?.value,
        city: rootEl.querySelector('#city')?.value,
        state: rootEl.querySelector('#state')?.value,
        zip: rootEl.querySelector('#zip')?.value,
        optin: rootEl.querySelector('#signup-optin')?.checked ? 'Yes' : 'No'
    };
};

const validate = (data) => {
    if (
        data.firstName !== '' &&
        data.lastName !== '' &&
        data.email !== '' &&
        data.confirmEmail !== '' &&
        data.email === data.confirmEmail
    ) {
        return true;
    }

    return false;
};

const sendData = async (data, signupBtn, rootEl, coralWaitContainer) => {
    signupBtn.disabled = true;

    try {
        const response = await emailSignUp(data);
        if (response.success === 'false') {
            signupBtn.disabled = false;
        } else {
            console.log('Email Subscribed', data);
            let emailSignupEl = rootEl.querySelector('.email-signup');
            let emailConfirmationEl = rootEl.querySelector('.email-confirmation');
            emailSignupEl && (emailSignupEl.style.display = 'none');
            emailConfirmationEl && (emailConfirmationEl.style.display = 'block');
            let edata = {
                event: 'email.signup',
                email: response.data.emailAddress,
                form_name: "newsletter signup"
            };
            setAdobeDataLayer(edata);
        }
    } catch (err) {
        console.log(err);
    } finally {
        coralWaitContainer && (coralWaitContainer.style.display = 'none');
    }
};

if (String(window.performance.getEntriesByType('navigation')[0].type) === 'back_forward') {
    window.location.reload();
}

component({
    selector: '.email-signup-component',
    instanceInit,
    name: 'EmailSignup'
});
