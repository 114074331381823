const initModalPopover = () => {
    console.group('init Modal Popover');
    const modalsPopovers = document.querySelectorAll('[data-toggle="modal"], [data-toggle="popover"]');
    const coralModalCloseBtn = document.querySelectorAll('.coral-Modal-closeButton, [data-dismiss=modal]');

    const onCoralModalClick = (evt) => {
        evt.preventDefault();

        let el = evt.currentTarget;
        const type = el.dataset.toggle;
        let target = document.querySelector(el.dataset.target || el.getAttribute('href'));
        let parent = document.querySelector(el.dataset.parent) || null;
        if (type === 'modal') {
            document.body.classList.add('coral-Modal.is-open');

            if (parent) {
                parent.append(target);
            }

            if (target) {
                target.insertAdjacentHTML('beforebegin', '<div class="coral-Modal-backdrop" aria-hidden="true"></div>');
                target.style.display = 'block';
                $(target).trigger('show');
            }
            const backdrops = document.querySelectorAll('.coral-Modal-backdrop');
            backdrops.forEach((item) => {
                item.addEventListener('click', (evt) => {
                    evt.preventDefault();

                    document.body.classList?.remove('coral-Modal.is-open');
                    const coralModals = document.querySelectorAll('.coral-Modal');
                    coralModals.forEach((item) => {
                        item.style.display = 'none';
                        // $('.coral-Modal').trigger('hide');
                    });
                    document.querySelector('.coral-Modal-backdrop')?.remove();
                });
            });
        }

        if (type === 'popover') {
            if (el.classList.contains('is-selected')) {
                el.classList?.remove('is-selected');
                target.classList?.remove('coral-Popover');
                target.style.display = 'none';
            } else {
                el.classList?.add('is-selected');
                target.classList?.add('coral-Popover');
                target.style.display = 'block';
            }
        }
    };

    const onCoralModalButtonClick = (evt) => {
        evt.preventDefault();

        document.body.classList.remove('coral-Modal.is-open');
        const coralModals = document.querySelectorAll('.coral-Modal');
        coralModals.forEach((item) => {
            item.style.display = 'none';
        });
        document.querySelector('.coral-Modal-backdrop')?.remove();
    };

    modalsPopovers.forEach((item) => {
        item.addEventListener('click', onCoralModalClick);
    });
    coralModalCloseBtn.forEach((item) => {
        item.addEventListener('click', onCoralModalButtonClick);
    });

    console.groupEnd();
};

export { initModalPopover };
