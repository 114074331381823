/**
 * Checks if the store is open.
 *
 * @param {Array<{ weekDay: string, openTime: string, closeTime: string }>} hours - The regular weekly operating hours of the store.
 * @param {Array<{ day: string, month: string, year: string, description: string, holidayId: string }>} holidays - An array of holiday objects with day, month, year, description, and holidayId properties.
 * @param {Array<{ holidayId: string, openTime: string, closeTime: string }>} holidayHours - The specific operating hours of the store on holidays.
 * @returns {boolean} True if the store is open, false otherwise.
 */

export const isStoreOpen = (hours, holidays, holidayHours) => {
    const today = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(new Date()).toUpperCase().slice(0, 3);
    const currentTime = new Intl.DateTimeFormat([], { hour: '2-digit', minute: '2-digit', hour12: false }).format(
        new Date()
    );

    let todayHoliday = null;
    if (holidays && Array.isArray(holidays)) {
        todayHoliday = holidays.find((h) => {
            const now = new Date();
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const [month, day, year] = new Intl.DateTimeFormat('en-US', options).format(now).split('/');
            return h.day === day && h.month === month && h.year === year;
        });
    }

    if (todayHoliday) {
        if (!holidayHours || !Array.isArray(holidayHours)) {
            return false; // If holidayHours is undefined or not an array, assume the store is closed
        }
        const todaysHolidayHours = holidayHours.find((h) => h.holidayId === todayHoliday.holidayId);
        if (todaysHolidayHours) {
            return currentTime >= todaysHolidayHours.openTime && currentTime < todaysHolidayHours.closeTime;
        } else {
            return false; // If no specific holiday hours, assume the store is closed
        }
    } else {
        const todaysHours = hours.find((day) => day.weekDay === today);
        if (todaysHours) {
            return currentTime >= todaysHours.openTime && currentTime < todaysHours.closeTime;
        }
    }

    return false;
};
