import { showServiceDateHours } from './showServiceDateHours';

const buildDays = (rootEl, props) => {
    const { buildDaysNum, numDays, datesArr, getDateSelectorIndex, setDateSelectorIndex } = props;
    const idx = getDateSelectorIndex();
    const dateItemsAll = rootEl.querySelectorAll('.date-items');
    dateItemsAll.forEach((dateItems) => {
        const _numDays = buildDaysNum || numDays;
        for (let i = 0; i < _numDays; i++) {
            const tmpDate = datesArr[i];
            const tmpStr = `<li data-service-date="${tmpDate.yearMonthDateStr}" class="hide"><span class="day">${tmpDate.dayStr}</span><span class="date">${tmpDate.monthStr} ${tmpDate.dateStr}</span></li>`;
            dateItems.insertAdjacentHTML('beforeend', tmpStr); //append(tmpStr);
        }

        const dateItemsLi = Array.from(rootEl.querySelectorAll('.date-items li'));
        [...dateItemsLi].forEach((item, index) => {
            if (index < numDays) {
                item.classList.remove('hide');
            }
        });
        setDateSelectorIndex(idx + 1);

        dateItemsLi.forEach((item) => {
            item.addEventListener('click', (evt) => {
                showServiceDateHours(evt, rootEl);
            });
        });
    });
};

export { buildDays };
