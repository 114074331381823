import '../scss/offer-details-refresh.scss';
import ShareOfferModal from '../../../offers-refresh/v1/js/offers/offer-share';
import offerShareTemplate from '../../../offers-refresh/v1/js/offers/tpl/offer-share.template';
import offerSuccessTemplate from '../../../offers-refresh/v1/js/offers/tpl/offer-success.template';
import { component } from 'common/js/library/component';
import Modal from 'common/js/util/coral-modal/modal';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { getDaysLeft } from 'common/js/util/daysLeft';

const instanceInit = (rootEl) => {
    console.log('inside OfferDetailsRefresh');
    let printCta = rootEl.querySelector('.offer-details__cta-print');
    let phoneCta = rootEl.querySelector('.offer-details__cta-phone');
    let offersDiv = rootEl.getElementsByClassName('offer-details__offer');
    let titleValue = '';
    let offerData;
    const offer = rootEl.querySelector('.offer-details__offer');
    if (offer) {
        setupDaysLeftOffer(offer);
        const offerTitle = rootEl.querySelector('.offer-details__offer-title');
        const offerPrice = rootEl.querySelector('.offer-details__offer-price');
        const offerSubtitle = rootEl.querySelector('.offer-details__offer-subtitle');
        const offerSubtitle1 = rootEl.querySelector('.offer-details__offer-subtitle1');
        const offerSubtitle2 = rootEl.querySelector('.offer-details__offer-subtitle2');
        if (offerTitle) {
            titleValue += offerTitle.innerHTML;
        }

        if (offerPrice) {
            titleValue += ' ' + offerPrice.innerHTML;
        }

        if (offerSubtitle) {
            titleValue += ' ' + offerSubtitle.innerHTML;
        }

        if (offerSubtitle1) {
            titleValue += ' ' + offerSubtitle1.innerHTML;
        }

        if (offerSubtitle2) {
            titleValue += ' ' + offerSubtitle2.innerHTML;
        }

        offerData = {
            friendlyId: offer.dataset.friendlyId,
            title: titleValue
        };
    }

    phoneCta &&
        phoneCta.addEventListener('click', (evt) => {
            sendToPhoneClickHandler(offerData);
        });

    printCta &&
        printCta.addEventListener('click', (evt) => {
            showPrintDialogHandler(offerData);
        });

    handleAfterPrint(offersDiv, offerData);

    if (window?.digitalData?.page && offersDiv.length) {
        window.digitalData.page.attributes = {};
        window.digitalData.page.attributes.offer = [];
        [...offersDiv].forEach((elem) => {
            window.digitalData.page.attributes.offer.push({
                offerID: elem.dataset.friendlyId,
                offerName: offerData.title
            });
        });
    }
};

const setupDaysLeftOffer = (offer) => {
    const offerEndDate = offer.dataset?.offerEndDate || '';
    if (offerEndDate) {
        const daysLeft = getDaysLeft(offerEndDate);

        const daysLeftInput = offer.querySelector('.offer-details__offer-validity-days');
        if (daysLeftInput) {
            daysLeftInput.innerHTML = '(' + daysLeft + ' days left!)';
        }
    }
};

const sendToPhoneClickHandler = (offerData) => {
    setAdobeDataLayer({
        event: 'offer.modal_click',
        modal_action: 'send_to_phone',
        offerID: offerData.friendlyId,
        offerName: offerData.title
    });

    new Modal({
        modalId: offerData.friendlyId,
        classNames: 'offer-modal offer-modal__share-text-email',
        content: offerShareTemplate(offerData)
    });
    ShareOfferModal('[data-share-offer-modal]');
};

const showPrintDialogHandler = (offerData) => {
    setAdobeDataLayer({
        event: 'offer.print',
        offerID: offerData.friendlyId,
        offerName: window.analytics_utils.getCouponData(offerData.friendlyId).thumbTitle
    });
};

const showPrintSuccessModal = (offerData) => {
    new Modal({
        modalId: offerData.friendlyId,
        classNames: 'offer-modal offer-modal--success',
        content: offerSuccessTemplate({ type: 'print' })
    });
};

const handleAfterPrint = (offersDiv, offerData) => {
    if (offersDiv.length) {
        if (window.location.hash.substring(1) == 'print') {
            setAdobeDataLayer({
                event: 'offer.print',
                offerID: offerData.friendlyId,
                offerName: window.analytics_utils.getCouponData(offerData.friendlyId).thumbTitle
            });

            window.setTimeout(function () {
                window.print();
            }, 1000);
        }
    }

    if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener((mql) => {
            if (!mql.matches) {
                setTimeout(() => {
                    showPrintSuccessModal(offerData);
                }, 1000);
            }
        });
    }
};

component({
    selector: '.offer-details--refresh',
    instanceInit,
    name: 'OfferDetailsRefresh'
});
