export const quoteDataPackage = (data) => {
    // const quantityStr =
    //     quantity || quantity === 0 ? String(quantity) : data.quantity;

    let tpms = data.tpms || (data.vehicle && data.vehicle.tpmsInd) || window.siteProfile.vehicles.tce.tpms || '';
    tpms = tpms === 'true' ? '1' : tpms === 'false' ? '0' : tpms;

    return {
        quoteType: data.type,
        zipCode:
            data.zipCode ||
            data.zip || // not sure if this is actually used
            window.siteProfile.location.myZip ||
            window.siteProfile.location.autoZip ||
            '',
        storeNumber: data.lwsStoreId || window.lwsStoreId || '',
        tceStoreNumber: data.tceStoreId || window.tceStoreId || data.lwsStoreId || '',
        frontArticleNumber: data.article || '',
        rearArticleNumber: data.rearArticleNumber || '',
        frontInventory: data.frontInventory || '0',
        rearInventory: data.rearInventory || '0',
        frontQuantity: data.quantity,
        rearQuantity: data.isSplitFitment ? data.quantity : '0',
        standardOptional: data.standardOptional || '0',
        driveGuard: data.driveGuard || 'false',
        quoteURL: `${window.location.origin}/tires/tire-quote/`,
        brgtTireLogoURL: data.logo,
        brgtTireImageURL: data.image,
        brand: data.brand,
        tireName: data.tireName,
        size: data.size || (data.type === 'size' ? window.siteProfile.tires.tce.tireSize || '' : ''),
        year:
            data.year ||
            (data.type === 'vehicle'
                ? (data.vehicle && data.vehicle.year) || window.siteProfile.vehicles.tce.year || ''
                : ''),
        make:
            data.make ||
            (data.type === 'vehicle'
                ? (data.vehicle && data.vehicle.make) || window.siteProfile.vehicles.tce.make || ''
                : ''),
        model:
            data.model ||
            (data.type === 'vehicle'
                ? (data.vehicle && data.vehicle.model) || window.siteProfile.vehicles.tce.model || ''
                : ''),
        trim:
            data.trim ||
            (data.type === 'vehicle'
                ? (data.vehicle && data.vehicle.trim) ||
                  (data.vehicle && data.vehicle.submodel) ||
                  window.siteProfile.vehicles.tce.trim ||
                  ''
                : ''),
        tpms
        // acesID: data.vehicleId || '', // shouldn't need to provide
        // visitorId: '' // shouldn't need to provide
    };
};
