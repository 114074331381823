import '../scss/brand-specs.scss';
import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
import { viewport } from 'common/js/library/viewport';

let currentQuery = viewport.currentQuery;

const instanceInit = (rootEl) => {
    let labels = rootEl.querySelectorAll('.label');
    let labelPositions = [];

    [...labels].forEach((el, idx) => {
        el.style.top = '10px';
        labelPositions[idx] = parseInt(el.style.top);
    });

    console.log(labelPositions);

    pubsub.subscribe(viewport.topics.RESIZE, (evt) => handleEvent(evt, labels, labelPositions));
    pubsub.subscribe('viewportquerychange', (e, q) => {
        currentQuery = q;
    });
    rootEl.querySelector('img').addEventListener('load', (evt) => handleEvent(evt, labels, labelPositions));
    // image load isn't always reliable, so also using window as a backup.
    window.addEventListener('load', (evt) => handleEvent(evt, labels, labelPositions));
};

/**
 * Event handler for all event listeners.
 * @memberof BrandSpecs
 * @private
 * @evt {event} event to be handled
 * @return {void}
 */
const handleEvent = (evt, labels, labelPositions) => {
    switch (evt.type) {
        case 'resize':
            console.log('on resize event');
            update(labels, labelPositions);
            break;
        case 'load':
            console.log('on load event');
            update(labels, labelPositions);
            break;
        default:
            throw TypeError('BrandSpecs.handleEvent: Event type not recognized.');
    }
};

/**
 * Updates the height of the container divs to allow contents to be vertically aligned
 * @memberof BrandSpecs
 * @public
 * @return {void}
 */
const update = (labels, labelPositions) => {
    let ratio = currentQuery === 'phone' ? 2 : 1;

    [...labels].forEach((el, idx) => {
        let height = el.innerHeight;
        el.style.top = labelPositions[idx] / ratio - height / 2;
    });
};

component({
    selector: '.brand-specs',
    instanceInit,
    name: 'BrandSpecs'
});
