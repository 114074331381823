import { dateToYYYYMMDD } from './dateToYYYYMMDD';

/**
 * determine if we need to provide salt
 * if no appointment times are available today and it's tomorrow add salt
 * @param {boolean} force - determines if we need to verify if no times. if true the other params aren't needed
 * @param {string} yyyymmdd - the date of the appointment being scheduled in YYYYMMDD format
 * @param {array} daytimes - array of objects containing the available appointment times
 */
const getSaltHHZ = (force, yyyymmdd, daytimes) => {
    const todayDate = new Date();
    const hours = ('0' + todayDate.getHours()).slice(-2);
    const timezone = ('0' + Math.ceil(todayDate.getTimezoneOffset() / 60)).slice(-2);
    const salt = `${hours}-${timezone}`;

    if (force) return salt;

    const todayYYYYMMDD = dateToYYYYMMDD(todayDate);
    const tomorrowDate = new Date(todayDate.setDate(todayDate.getDate() + 1));
    const tomorrowYYYYMMDD = dateToYYYYMMDD(tomorrowDate);
    const isTomorrow = yyyymmdd == tomorrowYYYYMMDD;
    let hasTimesToday = false;

    // no salt if no daytimes or if the appointment isn't for tomorrow.
    if (!daytimes || daytimes.length == 0 || !isTomorrow) return '';

    daytimes.map((entry, idx) => {
        if (entry.availableDate == todayYYYYMMDD) {
            // are there any times available for tomorrow?
            hasTimesToday = entry.times && entry.times.length > 0;
        }
    });

    // if no available times today and the appointment is for tomorrow, give a salt.
    if (!hasTimesToday) {
        return salt;
    }
    return '';
};

export { getSaltHHZ };
