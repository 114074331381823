import { queryStringify, responseError, HTTPHeaders } from '../serviceUtilities';
import { setLocalStorageItem } from '../../localstorage/localStorageService';
import { getCache, setCache } from 'common/js/util/sessionCache';
import { quoteDataPackage } from 'common/components/content/ecomm-quote-annex/v1/js/util/quoteDataPackage';

/**
 *
 * @param {string} quoteType vehicle or size
 * @param {object} tires tires data
 */
export const handleQuote = async (quoteType, tires) => {
    let tireQuantity = 4;
    const tire = tires[0].tire;
    let modifiedData = tire;
    const isMatchSet = tires.length === 1;
    const rear = (!isMatchSet && tires[1]?.tire) || {};
    const tireFriendlyName = tire.tireFriendlyName;
    const tireName = tire.tireName;
    const storeNumber = window.siteProfile.location.storeNumber;
    const vehicleData = window.siteProfile.vehicles.tce;
    const sizeData = { size: modifiedData.tireSize };

    tireQuantity = isMatchSet && tire?.frb === 'B' ? '4' : '2';

    modifiedData.name = tireFriendlyName || tireName;
    modifiedData.quantity = tireQuantity;
    modifiedData.frontInventory = (modifiedData.inventory && modifiedData.inventory.quantity) || 0;

    if (quoteType && (quoteType === 'vehicle' || quoteType === 'size')) {
        modifiedData.type = quoteType;
    }
    if (vehicleData?.year && vehicleData?.make && vehicleData?.model && vehicleData?.trim) {
        modifiedData = { ...modifiedData, ...vehicleData };
    }
    if (sizeData?.article) {
        modifiedData = { ...modifiedData, ...sizeData };
    }

    if (storeNumber) {
        modifiedData.lwsStoreId = modifiedData.tceStoreId = storeNumber;
    } else if (vehicleData && vehicleData.storeNumber) {
        modifiedData.lwsStoreId = modifiedData.tceStoreId = storeNumber;
    }

    if (!isMatchSet && rear?.article) {
        modifiedData.rearArticleNumber = rear.article;
    }

    // if size is split-fit we need to change front/rear quantities to 2 and 2
    if (modifiedData.rearArticleNumber) {
        modifiedData.isSplitFitment = true;
    }

    return quoteDataPackage(modifiedData);
};

export const createQuote = async (dataPackage) => {
    try {
        const url = '/bsro/services/quote/create';
        const headers = HTTPHeaders(['token']);

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataPackage),
            headers
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        // store the request. if this quote is used to create a cart it will be stored again as quoteRequestData
        setLocalStorageItem('quoteRequestTempData', dataPackage);
        setLocalStorageItem('quoteResponseTempData', responseJson.data);

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getQuote = async (dataPackage) => {
    try {
        const headers = HTTPHeaders(['token']);

        const url = `/bsro/services/tires/get-quote?quoteID=${dataPackage}`;
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const pricingDetails = async (dataPackage) => {
    const storeNumber = dataPackage.storeNumber;
    const articleIds = Array.isArray(dataPackage.articleIds)
        ? dataPackage.articleIds.join(',')
        : dataPackage.articleIds?.replace(/%2C/g, ',');
    const tireBrand = dataPackage.tireBrand;
    const tireName = dataPackage.tireName;
    try {
        let url = `/bsro/services/store/pricing-details?storeNumber=${storeNumber}`;
        if (articleIds) {
            url += `&articleIDs=${encodeURIComponent(articleIds)}`;
        }
        if (tireBrand) {
            url += `&brand=${encodeURIComponent(tireBrand)}`;
        }
        if (tireName) {
            url += `&tireName=${encodeURIComponent(tireName)}`;
        }
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const emailQuote = async (dataPackage) => {
    try {
        const url = '/bsro/services/contact/emailquote';
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataPackage),
            headers: HTTPHeaders()
        });
        console.log('email quote response:', response);
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const allOffers = async (code) => {
    try {
        const url = `/bsro/services/promotions/alloffers?friendlyId=${code}`;

        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getInventory = async (store, articleIds) => {
    try {
        const url = `/bsro/services/store/inventory?storeNumber=${store}&articleIDs=${articleIds}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getInventoryPricing = async (store, searchCriteria) => {
    const year = searchCriteria?.year || '';
    const make = searchCriteria?.make || '';
    const model = searchCriteria?.model || '';
    const trim = searchCriteria?.submodel || searchCriteria?.trim || '';
    const crossSection = searchCriteria?.crossSection || '';
    const aspectRatio = searchCriteria?.aspectRatio || '';
    const rimSize = searchCriteria?.rimSize || '';
    let dataPackage = {};

    console.log('inventory_pricing request A:', { store, searchCriteria, dataPackage });

    if (store && year && make && model && trim) {
        dataPackage = { store, year, make, model, trim };
    } else if (store && crossSection && aspectRatio && rimSize) {
        dataPackage = { store, crossSection, aspectRatio, rimSize };
    } else {
        // data package has insufficient data, fail
        return;
    }

    console.log('inventory_pricing request B:', { store, searchCriteria, dataPackage });

    try {
        const url = `/bsro/services/store/inventory_pricing${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        console.log('inventory_pricing response:', { responseJson });

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};
