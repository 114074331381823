import { resetWaitDropoff } from './resetWaitDropoff';

const resetHours = (rootEl) => {
    const stores = rootEl.querySelectorAll('.stores, .otherStoresList');

    stores.forEach((store) => {
        if (store.classList.contains('storeSelected')) {
            const hours = store.querySelectorAll('.hours');
            const selected = store.querySelectorAll('li.available.selected');
            const showMore = store.querySelector('.show-more');

            store.classList.remove('storeSelected');
            showMore?.classList?.remove('hide');
            hours.forEach((hour) => {
                hour.classList.add('preview-hours');
            });
            selected.forEach((item) => {
                item.classList.remove('selected');
            });

            resetWaitDropoff(rootEl);
        }
    });
};

export { resetHours };
