import { debounce } from '../util/debounce.js';
import { pubsub } from 'common/js/library/pubsub';

let viewport = {},
    currentQuery,
    topics;

/**
 * Map of pubsub topics.
 * Values:
 * <pre>
 * SCROLL_START: 'tcom.window.scrollstart'
 * SCROLL: 'tcom.window.scroll'
 * SCROLL_END: 'tcom.window.scrollend'
 * RESIZE: 'tcom.window.resize'
 * QUERY_CHANGE: 'tcom.viewport.query.change'
 * </pre>
 * @static
 * @type {object}
 */
topics = viewport.topics = {
    /**
     * Scroll start topic.
     * @event viewport#'windowscrollstart'
     * @type {object}
     * @property {string} topic - The name of the topic.
     * @property {object} data - The data for the event.
     * @property {string} data.event - The original dom event.
     */
    SCROLL_START: 'windowscrollstart',
    /**
     * Scroll topic. This is throttled to 50ms.
     * @event viewport#'windowscroll'
     * @type {object}
     * @property {string} topic - The name of the topic.
     * @property {object} data - The data for the event.
     * @property {string} data.event - The original dom event.
     */
    SCROLL: 'windowscroll',
    /**
     * Scroll end topic.
     * @event viewport#'windowscrollend'
     * @type {object}
     * @property {string} topic - The name of the topic.
     * @property {object} data - The data for the event.
     * @property {string} data.event - The original dom event.
     */
    SCROLL_END: 'windowscrollend',
    /**
     * Resize topic.
     * @event viewport#'windowresize'
     * @type {object}
     * @property {string} topic - The name of the topic.
     * @property {object} data - The data for the event.
     * @property {string} data.event - The original dom event.
     */
    RESIZE: 'windowresize',
    /**
     * query change topic. Published on resize when current query changes.
     * @event viewport#'viewportquerychange'
     * @type {object}
     * @property {string} topic - The name of the topic.
     * @property {object} data - The data for the event.
     * @property {string} data.event - The original dom event.
     * @property {string} data.currentQuery The current query.
     */
    QUERY_CHANGE: 'viewportquerychange'
};

/**
 * Media Query Helpers
 * @type {object}
 */
const mqDefinitions = {
    phone: '(max-width:767px)',
    tablet: '(min-width:768px) and (max-width:1024px)',
    desktop: '(min-width:1025px)',
    retina: '(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)'
};

const breakpointNames = {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    PHONE: 'phone'
};

/**
 * Get the current media query.
 * @return {string} The current media query
 */
viewport.getCurrentQuery = function () {
    return (
        (this.mq(breakpointNames.PHONE) && breakpointNames.PHONE) ||
        (this.mq(breakpointNames.TABLET) && breakpointNames.TABLET) ||
        (this.mq(breakpointNames.DESKTOP) && breakpointNames.DESKTOP) ||
        false
    );
};

/**
 * [mq description]
 * @param  {String} breakPoint The names breakpoint you want to test.
 * A value of "desktop", "tablet", "phablet", or "phone".
 * @return {Boolean} `true` if the breakpoint matches; `false` otherwise.
 */
viewport.mq = function (breakPoint) {
    // we have MQ here
    if (breakPoint === 'retina') {
        // If checking for retina breakpoint, return device ratio
        return window.devicePixelRatio > 1 || window.matchMedia(mqDefinitions[breakPoint]).matches;
    }

    if (breakPoint) {
        // MatchMedia friendly browsers, return true breakpoint
        return window.matchMedia(mqDefinitions[breakPoint]).matches;
    }
};

const onResize = debounce(function (evt) {
    let newQuery = viewport.getCurrentQuery();
    console.log('viewport onResize()', 'newQuery', newQuery, 'currentQuery', currentQuery);

    // Checking if the cached query differs from the new query
    if (currentQuery !== newQuery) {
        currentQuery = newQuery;

        // Publishing the new query
        pubsub.publish(topics.QUERY_CHANGE, [evt, currentQuery]);
    }

    pubsub.publish(topics.RESIZE, [evt]);
}, 100);

window.addEventListener('resize', onResize);
window.addEventListener('orientationchange', onResize);

// Caching the initial viewport/query
currentQuery = viewport.currentQuery = viewport.getCurrentQuery();

export { viewport };
