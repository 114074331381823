import { component } from 'common/js/library/component';
let hamburger;
let wrapper;
let mainNavWrapperContainer;
let subNavContent;
let tireSubNavContent;
let repairSubNavContent;
let maintenanceSubNavContent;

const init = (config) => {
    hamburger = document.querySelector('.hamburgler--btn');
    wrapper = document.querySelector('#wrapper');
    mainNavWrapperContainer = document.querySelector('.primary-nav .main-nav-wrapper-container');
    subNavContent = document.querySelectorAll('.primary-nav .sub-nav-content');
    tireSubNavContent = document.querySelector('.primary-nav .sub-nav-content.tires');
    repairSubNavContent = document.querySelector('.primary-nav .sub-nav-content.repair');
    maintenanceSubNavContent = document.querySelector('.primary-nav .sub-nav-content.maintenance');
};

const instanceInit = (rootEl) => {
    console.log('inside mobile-nav.js');

    let mainNavLinks = rootEl.querySelectorAll('ul > li > a');
    let tireSubNav = rootEl.querySelector('li.tires > a');
    let repairSubNav = rootEl.querySelector('li.repair > a');
    let maintenanceSubNav = rootEl.querySelector('li.maintenance > a');
    let mobileBackEls = rootEl.querySelectorAll('.mobile.back');

    hamburger.addEventListener('click', (evt) => {
        if (hamburger.classList.contains('is-active')) {
            // reset nav
            hamburger.classList.remove('is-active');
            rootEl.classList.remove('is-active', 'slide-left');
            [...subNavContent].forEach((el) => {
                if (el.classList.contains('is-active')) {
                    el.classList.remove('is-active');
                }
            });
            wrapper.classList.remove('no-scroll');
            mainNavWrapperContainer.classList.remove('is-active');
        } else {
            // open main nav
            hamburger.classList.add('is-active');
            rootEl.classList.add('is-active');
            wrapper.classList.add('no-scroll');
            mainNavWrapperContainer.classList.add('is-active');
        }
    });

    [...mainNavLinks].forEach((mainNavLink) => {
        mainNavLink.addEventListener('click', (evt) => {
            let target = evt.currentTarget;
            let par = target.parentElement;
            let isActive = par.classList.contains('is-active');
            let hasSub = target.nextElementSibling && target.nextElementSibling.classList.contains('sub-nav');

            if (hasSub && !isActive) {
                if (window.matchMedia('(pointer: coarse)').matches || window.matchMedia('(max-width: 680px)').matches) {
                    evt.preventDefault();
                }

                if (window.matchMedia('(min-width: 768px)').matches) {
                    let childrenClassList = par?.parentElement?.children;
                    if (childrenClassList) {
                        [...childrenClassList].forEach((el) => {
                            el.classList.remove('is-active');
                        });
                    }
                    par.trigger('mouseover').toggleClass('is-active');
                }
            }
        });
    });

    tireSubNav.addEventListener('click', (evt) => {
        slideLeft(rootEl, subNavContent);
        tireSubNavContent.classList.add('is-active');
    });

    repairSubNav.addEventListener('click', (evt) => {
        slideLeft(rootEl, subNavContent);
        repairSubNavContent.classList.add('is-active');
    });

    maintenanceSubNav.addEventListener('click', (evt) => {
        slideLeft(rootEl, subNavContent);
        maintenanceSubNavContent.classList.add('is-active');
    });

    [...mobileBackEls].forEach((mobileBackEl) => {
        mobileBackEl.addEventListener('click', (evt) => {
            rootEl.classList.remove('slide-left');
        });
    });
};

const slideLeft = (rootEl, subNavContent) => {
    rootEl.classList.add('slide-left');
    [...subNavContent].forEach((el) => {
        if (el.classList.contains('is-active')) {
            el.classList.remove('is-active');
        }
    });
};

component({
    selector: '.top-nav .main-nav-wrapper',
    instanceInit,
    init,
    name: 'MobileNav'
});
