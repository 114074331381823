import { 
    REVIEWS_MERGE_PRODUCTS, 
    REVIEWS_MERGE_REVIEWS, 
    REVIEWS_SET_REVIEWS, 
    SET_REVIEWS_V2, 
    SET_SORTBY_REVIEWS_V2, 
    UPDATE_REVIEWS_V2,
    SET_RATING_REVIEWS_V2,
    MERGE_REVIEWS_V2,
    REVIEWS_MERGE_PRODUCTS_V2
 } from '../actions';

export function reviewsMergeProducts(payload) {
    return {
        type: REVIEWS_MERGE_PRODUCTS,
        payload
    };
}

export function reviewsMergeProductsV2(payload) {
    return {
        type: REVIEWS_MERGE_PRODUCTS_V2,
        payload
    };
}

export function reviewsSetReviews(id, payload, nextPageKey) {
    return {
        type: REVIEWS_SET_REVIEWS,
        id,
        payload,
        nextPageKey
    };
}

export function reviewsMergeReviews(id, payload, nextPageKey) {
    return {
        type: REVIEWS_MERGE_REVIEWS,
        id,
        payload,
        nextPageKey
    };
}

export function setReviewsV2(payload) {
    return {
        type: SET_REVIEWS_V2,
        payload
    }
}

export function setSortByReviewsV2(payload) {
    return {
        type: SET_SORTBY_REVIEWS_V2,
        payload
    }
}

export function setRatingReviewsV2(payload) {
    return {
        type: SET_RATING_REVIEWS_V2,
        payload
    }
}

export function updateReviewsV2(payload) {
    return {
        type: UPDATE_REVIEWS_V2,
        payload
    }
}

export function mergeReviewsV2(payload) {
    return {
        type: MERGE_REVIEWS_V2,
        payload
    }
}
