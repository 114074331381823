/**
 * removes and clean specific characters
 * @param {string} email - string to modify
 */

const emailSanitizer = (email) => {
    if (!email) return email;

    const sanitizedEmail = email.toLocaleLowerCase();
    return sanitizedEmail;
};

export { emailSanitizer };
