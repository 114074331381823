import React from 'react';
import { render } from 'react-dom';
import { updateAppointmentDetails } from './updateAppointmentDetails';
import { getAvailabilityForMultipleStores } from './getAvailabilityForMultipleStores';
import { hasSmallViewPort } from './hasSmallViewPort';
import Modal from 'common/components/content/react-shared/elements/modal/v1/Modal';
import { getAvailabilityForStore, checkAndReserve } from 'common/js/data/services/ecomm/appointment';
import { getSaltHHZ } from 'common/js/util/date-time/getSaltHHZ';
import { pubsub } from 'common/js/library/pubsub';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { dateToYYYYMMDD } from 'common/js/util/date-time/dateToYYYYMMDD';

const onDateTimeSubmit = async (evt, rootEl, props) => {
    let serviceDate = dateToYYYYMMDD(new Date());
    const {
        getAppointmentDetails,
        setAppointmentDetails,
        dateStoreStep,
        serviceStep,
        completeDateTimeSelection,
        addedServicesListArr,
        getStoresInfo,
        isPinnacle,
        setDateSelectorIndex,
        setStoresInfo,
        datesArr
    } = props;
    const { year, make, model, trim } = getAppointmentDetails().vehicle;
    const searchType = getAppointmentDetails().vehicle_search_type;
    evt.preventDefault();

    const dateStoreData = JSON.parse(dateStoreStep.dataset.date);
    const storeData = getStoresInfo().availableStores[dateStoreData.storeNumber.toString()];
    const startDate = dateStoreData.availableDate.toString();
    const { employeeId, locationId, serviceId } = storeData.service;
    const salt = getSaltHHZ(true);
    const numDays = 1;
    const timeSlotErrorMessage = rootEl.querySelector('.time-slot-error-message');
    const dateStoreCtas = rootEl.querySelectorAll('.nav-control, .otherStoresContainer .arrow-down');
    const storeHoursLoaders = rootEl.querySelectorAll('.store-hours .wait');

    if (completeDateTimeSelection) {
        completeDateTimeSelection.forEach((completeDateTimeSelectionCta) => {
            completeDateTimeSelectionCta.disabled = true;
        });
        //disabling other cta events while callCheckAndReserve api is being called.
        dateStoreCtas.forEach((dateStoreCta) => {
            dateStoreCta.style.pointerEvents = 'none';
            dateStoreCta.style.opacity = '0.5';
        });
    }

    const callCheckAndReserve = async () => {
        const rootDiv = document.getElementsByClassName('rm-modal')[0];
        const selectedData = dateStoreStep?.dataset.date ? JSON.parse(dateStoreStep.dataset.date) : null;
        const dateStoreData = JSON.parse(dateStoreStep.dataset.date);
        const storeData = getStoresInfo().availableStores[dateStoreData.storeNumber.toString()];
        const { locationId, employeeId, serviceId, additionalServices } = storeData.service;
        const serviceIds = [serviceId, ...additionalServices.split(',')].filter((item) => Boolean(item)).toString();

        let modalErrorStatus = false;

        if (selectedData) {
            const payload = {
                locationId,
                employeeId,
                serviceId: serviceIds,
                date: selectedData.availableDate,
                timeSlot: selectedData.selectedTime
            };

            try {
                const checkAndReserveResponse = await checkAndReserve(payload);

                if (
                    checkAndReserveResponse.success === 'false' ||
                    (checkAndReserveResponse.success === 'true' && checkAndReserveResponse?.data?.reserved === 'false')
                ) {
                    modalErrorStatus = true;
                    render(
                        <Modal
                            isModalOpen={modalErrorStatus}
                            closeModal={() => {
                                render(<></>, rootDiv);
                            }}
                            containerClass="xb-info-form__modal"
                            modalContent={<ModalError />}
                        />,
                        rootDiv
                    );
                    // TO DO: rebuild time slots
                    getAvailabilityForMultipleStores(rootEl, {
                        addedServicesListArr,
                        serviceDate,
                        setDateSelectorIndex,
                        numDays: hasSmallViewPort() ? 3 : 5,
                        getStoresInfo,
                        setStoresInfo,
                        dateStoreStep,
                        completeDateTimeSelection,
                        datesArr
                    });
                    if (storeHoursLoaders.length) {
                        storeHoursLoaders.forEach((item) => {
                            item.style.display = 'block';
                        });
                    }
                    dateStoreCtas.forEach((dateStoreCta) => {
                        if (!dateStoreCta.classList.contains('disable')) {
                            dateStoreCta.style.pointerEvents = 'auto';
                            dateStoreCta.style.opacity = '1';
                        }
                    });
                    document.body.querySelector('.pinnacle-container').style.display = 'none';

                    return Promise.reject('Error');
                }

                if (checkAndReserveResponse.success === 'true' && checkAndReserveResponse?.data?.reserved === 'true') {
                    dateStoreData.timeSlotId = checkAndReserveResponse?.data?.timeSlotId;

                    const storeDetails = getStoresInfo().storesAsDataObj[dateStoreData.storeNumber];

                    let sdata = {};
                    if (searchType) {
                        sdata.vehicle = {
                            year,
                            make,
                            model,
                            trim
                        };
                        sdata.vehicle_search_type = searchType;
                    }

                    sdata.store = {
                        store_position: storeDetails.count,
                        city: storeDetails.city,
                        state: storeDetails.state,
                        zip: storeDetails.zip,
                        storeNumber: dateStoreData.storeNumber,
                        telephone: storeDetails.phone
                    };
                    sdata.services = addedServicesListArr;
                    sdata.date = dateStoreData.availableDate;
                    sdata.time = dateStoreData.selectedTime;
                    sdata.preference_selected =
                        rootEl.querySelector('.pickup-preference option:checked')?.value || 'wait in store';
                    sdata.event = 'sa.v2.dateandstore';

                    setAdobeDataLayer(sdata);

                    updateAppointmentDetails(rootEl, {
                        dateStoreData,
                        getAppointmentDetails,
                        setAppointmentDetails,
                        getStoresInfo,
                        serviceStep
                    });

                    if (completeDateTimeSelection) {
                        pubsub.publish('steps-next');
                        completeDateTimeSelection.forEach((completeDateTimeSelectionCta) => {
                            completeDateTimeSelectionCta.disabled = false;
                        });
                        //enabling other cta events once callCheckAndReserve api got called.
                        dateStoreCtas.forEach((dateStoreCta) => {
                            dateStoreCta.style.pointerEvents = 'auto';
                            dateStoreCta.style.opacity = '1';
                        });
                    }

                    return Promise.resolve('Success');
                }
            } catch (err) {
                console.error(err);
                return Promise.reject('Error');
            }
        }
        if (storeHoursLoaders.length) {
            storeHoursLoaders.forEach((item) => {
                item.style.display = 'block';
            });
        }
    };

    const callGetAvailabilityForStore = async () => {
        let dataPackage = {
            employeeId,
            locationId,
            serviceId,
            startDate,
            numDays
        };
        if (salt) dataPackage = Object.assign({}, dataPackage, { salt });

        const response = await getAvailabilityForStore(dataPackage);

        try {
            if (response.success === 'true') {
                const data = response?.data;
                const times = data.daytimes && data.daytimes[0].times.toString();

                timeSlotErrorMessage.classList.add('hide');

                const storeDetails = getStoresInfo().storesAsDataObj[dateStoreData.storeNumber];

                if (times.indexOf(dateStoreData.selectedTime) != -1) {
                    let sdata = {};
                    if (searchType) {
                        sdata.vehicle = {
                            year,
                            make,
                            model,
                            trim
                        };
                        sdata.vehicle_search_type = searchType;
                    }

                    sdata.store = {
                        store_position: storeDetails.count,
                        city: storeDetails.city,
                        state: storeDetails.state,
                        zip: storeDetails.zip,
                        storeNumber: dateStoreData.storeNumber
                    };
                    sdata.services = addedServicesListArr;
                    sdata.date = dateStoreData.availableDate;
                    sdata.time = dateStoreData.selectedTime;
                    sdata.preference_selected =
                        rootEl.querySelector('.pickup-preference option:checked')?.value || 'wait in store';
                    sdata.event = 'sa.v2.dateandstore';

                    setAdobeDataLayer(sdata);

                    if (searchType && (!year || !make || !model || !trim)) {
                        console.error(
                            `get availability for store completion error: vehicle information missing or incomplete, {vehicleProfile: {year: ${year}, make: ${make}, model: ${model}, trim: ${trim}}}`
                        );
                    }

                    updateAppointmentDetails(rootEl, {
                        dateStoreData,
                        getAppointmentDetails,
                        setAppointmentDetails,
                        getStoresInfo,
                        serviceStep
                    });

                    if (completeDateTimeSelection) {
                        pubsub.publish('steps-next');
                        completeDateTimeSelection.forEach((completeDateTimeSelectionCta) => {
                            completeDateTimeSelectionCta.disabled = false;
                        });
                    }
                } else {
                    const availableSelected = rootEl.querySelectorAll('li.available.selected');

                    timeSlotErrorMessage.classList.remove('hide');
                    if (completeDateTimeSelection) {
                        completeDateTimeSelection.forEach((completeDateTimeSelectionCta) => {
                            completeDateTimeSelectionCta.disabled = true;
                        });
                    }
                    availableSelected.forEach((item) => {
                        item.innerHTML = '-';
                        item.classList.remove('selected', 'available');
                    });
                }
            } else {
                document.querySelector('.systemErrorModal').dispatchEvent(new Event('click'));
            }
        } catch (err) {
            console.error(err);
        }
    };

    const closeModalError = () => {
        render(<></>, document.getElementsByClassName('rm-modal')[0]);
    };
    //Error Modal
    const ModalError = () => {
        return (
            <div className="time-slot-error">
                <h3 className="time-slot-error__heading">Sorry!</h3>
                <div>
                    <p>The time you selected is no longer available. Please select a new time.</p>
                </div>
                <div>
                    <a className="btn" onClick={closeModalError}>
                        SELECT NEW TIME
                    </a>
                </div>
            </div>
        );
    };

    await callCheckAndReserve();
};

export { onDateTimeSubmit };
