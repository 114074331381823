import { Vehicle } from './vehicle';

export const convertVehicleFromAccountToVehicle = (vehicleFromAccount) => {
    return new Vehicle({
        id: vehicleFromAccount.ID,
        year: vehicleFromAccount.YEAR,
        make: vehicleFromAccount.MAKE,
        model: vehicleFromAccount.MODEL,
        trim: vehicleFromAccount.SUBMODEL,
        /* TPMS is not stored on server. New vehicles always have it. */
        tpms: vehicleFromAccount.YEAR >= 2007 ? 1 : undefined,
        engine: vehicleFromAccount.ENGINE,
        acesid: vehicleFromAccount.BASE_VEHICLE_ID,
        nickname: vehicleFromAccount.NAME,
        odometer: vehicleFromAccount.ODOMETER,
        drivingCondition: vehicleFromAccount.DRIVING_CONDITION,
        imageUrl: vehicleFromAccount.IMAGE_URL
    });
};
