/**
 * Quote Service Factory
 */
import { getCookie, isEmpty, isObject, removeCookie, setCookie } from 'common/js/library/util';
import { pubsub } from 'common/js/library/pubsub';

function QuoteServiceFactory() {
    var self = this;

    // Events
    self.CREATE_QUOTE = 'create_quote';
    self.CREATE_QUOTE_COMPLETE = 'create_quote_complete';
    self.GET_QUOTE = 'get_quote';
    self.GET_QUOTE_COMPLETE = 'get_quote_complete';
    //

    // Services Endpoints
    self.CREATE_QUOTE_SERVICE = '/bsro/services/tires/create-quote';
    self.CREATE_QUOTE_ECOM_SERVICE = '/bsro/services/tires/create-ecomm-quote';
    self.GET_QUOTE_SERVICE = '/bsro/services/tires/get-quote';
    self.GET_QUOTE_ECOM_SERVICE = '/bsro/services/tires/update-ecomm-quote';

    /* Data
     * TO DO:
     * 	- Sync up with Ankur's documentation
     *
     * Notes: get oject using extend is probably overkill
     *
     */
    self.DATA = {
        get ecomWithFitment() {
            return $.extend({}, self.DATA.ecom, self.DATA.vehicle);
        },
        get quoteId() {
            return this.DATA.ecom.quoteID;
        },
        set quoteId(quoteId) {
            var _ecomData = this.ecom;
            var _vehicleData = this.vehicle;

            // *** TODO: this zip should be the zip of the store the quote was made for, not the users zip
            var _tmpZip = window.siteProfile.location.myZip || window.siteProfile.location.autoZip;

            var _quoteCookie = getCookie(quoteId);

            if (_quoteCookie) {
                var _quoteData = decodeURIComponent(_quoteCookie);
                var _quoteDataObj = JSON.parse(_quoteData) || {};
                var _tmpVehicle = null;

                if (_quoteDataObj.quoteType == 'vehicle') {
                    _tmpVehicle = window.isEcom ? window.siteProfile.vehicles.tce : window.siteProfile.vehicles.main;
                } else if (_quoteDataObj.quoteType == 'size') {
                }

                _ecomData.quoteType = _quoteDataObj.quoteType;
                _ecomData.zipCode = _tmpZip;
                _ecomData.storeNumber = _quoteDataObj.lwsStoreId || window.lwsStoreId;
                _ecomData.tceStoreNumber = _quoteDataObj.tceStoreId || window.tceStoreId;
                //_ecomData.firstName = "firstName";
                //_ecomData.lastName = "lastName";
                //_ecomData.emailAddress = "email@address.com";
                _ecomData.frontArticleNumber = _quoteDataObj.frontArticleNumber || 'articleID';
                _ecomData.rearArticleNumber = _quoteDataObj.rearArticleNumber || '';
                _ecomData.frontInventory = _quoteDataObj.frontInventory || '0';
                _ecomData.rearInventory = _quoteDataObj.rearInventory || '0';
                //_ecomData.frontTireInventoryID = _quoteDataObj.frontTireInventoryID || "ftiid";
                //_ecomData.rearTireInventoryID = _quoteDataObj.rearTireInventoryID || "";
                _ecomData.frontQuantity = _quoteDataObj.frontQuantity || 4;
                _ecomData.rearQuantity = _quoteDataObj.rearQuantity || 0;
                _ecomData.standardOptional = _quoteDataObj.standardOptional || 'S';
                _ecomData.driveGuard = _quoteDataObj.driveGuard || 'false';
                _ecomData.quoteURL = _quoteDataObj.quoteURL;
                _ecomData.brgtTireLogoURL = _quoteDataObj.brgtTireLogoURL || 'logo.png';
                _ecomData.brgtTireImageURL = _quoteDataObj.brgtTireImageURL || 'tire.png';
                _ecomData.brand = _quoteDataObj.brand || 'brand';
                _ecomData.tireName = _quoteDataObj.tireName || 'tire';
                _ecomData.quoteID = quoteId;

                // TODO:
                // --- need to know if quote is created using vehicle or tire size
                // --- need to flush this out more
                if (_tmpVehicle) {
                    _vehicleData.year = _tmpVehicle.year;
                    _vehicleData.make = _tmpVehicle.make;
                    _vehicleData.model = _tmpVehicle.model;
                    _vehicleData.trim = _tmpVehicle.trim;
                    _vehicleData.tpms = _tmpVehicle.tpms;

                    $.extend(_ecomData, _vehicleData);
                }
            } else {
                _ecomData.quoteID = quoteId;
            }
        },
        create: {
            articleNumber: '',
            quantity: '',
            type: '',
            inventory: '',
            get inventoryId() {
                var tmpInventoryId = this._inventoryId;

                delete this._inventoryId;

                return tmpInventoryId;
            },
            set inventoryId(id) {
                this._inventoryId = id;
            }
        },
        /*
		frontArticleNumber
		rearArticleNumber
		frontQuantity
		rearQuantity
		driveGuard (Sample values: true/false)
		quoteURL
		brgtTireLogoURL
		brgtTireImageURL
		brand
		tireName
		frontInventory - Optional
		rearInventory - Optional
		year - Optional
		make - Optional
		model - Optional
		trim - Optional
		tpms - Optional (Sample values: 1/0)
		size - Optional
		standardOptional (Sample Values: S/O) - Optional
		articleNumber - Optional
	 */
        ecom: {
            quoteType: '',
            quoteID: '',
            zipCode: '',
            storeNumber: '',
            tceStoreNumber: '',
            //firstName: "",
            //lastName: "",
            //emailAddress: "",
            frontArticleNumber: '',
            rearArticleNumber: '',
            frontInventory: '',
            rearInventory: '',
            //frontTireInventoryID: "",
            //rearTireInventoryID: "",
            frontQuantity: '',
            rearQuantity: '',
            standardOptional: '',
            driveGuard: '',
            quoteURL: '',
            brgtTireLogoURL: '',
            brgtTireImageURL: '',
            brand: '',
            tireName: '',
            size: ''
        },
        vehicle: {
            year: '',
            make: '',
            model: '',
            trim: '',
            tpms: '',
            acesID: ''
        },
        size: {
            ar: '',
            cs: '',
            rs: '',
            set aspectRatio(ar) {
                this.ar = ar;
            },
            set crossSection(cs) {
                this.cs = cs;
            },
            set rimSize(rs) {
                this.rs = rs;
            }
        }
    };

    self.RESPONSE_DATA = null;

    self.init();
}

QuoteServiceFactory.prototype = {
    //
    // public getters/setters
    //
    get quoteId() {
        return this.DATA.quoteId;
    },
    set quoteId(quoteId) {
        this.DATA.quoteId = quoteId;
    },
    get ecomData() {
        var _vehicleData = this.DATA.vehicle;
        var _ecomData = this.DATA.ecom;

        if (!isEmpty(_vehicleData)) {
            $.extend(_ecomData, _vehicleData);
        }

        return _ecomData;
    },
    get vehicleData() {
        return this.DATA.vehicle;
    },
    set vehicleData(vehicle) {
        this.DATA.vehicle = vehicle;
    },
    get responseData() {
        return this.RESPONSE_DATA;
    },
    get satelliteData() {
        var _tmpData = {
            cart: {
                item: [
                    {
                        productID: this.DATA.ecom.frontArticleNumber.toString() || '0',
                        size: this.DATA.ecom.size.toString() || 'NA',
                        quantity: this.DATA.ecom.frontQuantity.toString() || '0'
                    }
                ]
            }
        };
        //window._satellite = _satellite;

        return _tmpData;
    }
};

QuoteServiceFactory.prototype.init = function (evt) {
    console.log('QuoteServiceFactory -> init()');
    console.log(this.DATA.ecom);
    console.log(this.DATA.vehicle);

    var self = this;

    pubsub.subscribe(self.CREATE_QUOTE, $.proxy(self.createQuote, self));
    pubsub.subscribe(self.GET_QUOTE, $.proxy(self.getQuote, self));
};

// keeping data, options, callback arguments for now since it's unclear
// if one unified create-quote was suppose to be created instead of
// two calls; ecom (create-ecomm-quote) and non-ecom (create-quote)
QuoteServiceFactory.prototype.createQuote = function (dat, options, callback) {
    //TODO: shift arguments if one is omitted, see commented callback code
    // callback = options
    // options = undefined

    console.log('QuoteServiceFactory -> createQuote()');
    console.log(this.DATA.ecom);
    console.log(this.DATA.vehicle);

    var data = dat ? dat : null;
    var self = this;

    var _service = self.CREATE_QUOTE_SERVICE;
    var $method = $.getJSON;
    console.log('data in create quote: ', data);
    if (arguments.length == 0) {
        data = self.DATA.create;
    }
    console.log('data in createQuote after if-check', data);
    if (window.isEcom) {
        // prevent empty tpms value
        if (self.DATA.vehicle.tpms.length === 0) {
            if (self.DATA.vehicle.year < 2007 || siteName == 'hibdontire') {
                self.DATA.vehicle.tpms = 0;
            } else {
                self.DATA.vehicle.tpms = 1;
            }
        }

        _service = self.CREATE_QUOTE_ECOM_SERVICE;
        $method = $.post;

        data = $.extend(self.DATA.ecom, self.DATA.vehicle);
    }

    setCookie('bsro.ui.quantity', data['quantity'], 1);
    setCookie('bsro.ui.productID', data['articleNumber'], 1);
    setCookie('bsro.ui.inventory', data['articleNumber'] + '_' + data['inventoryId'], 1);

    $method(_service, data).done(function (localData) {
        //console.log("QuoteServiceFactory -> createQuote() --> Complete");

        var _data = isObject(localData) ? localData : JSON.parse(localData);
        var _options = options || {};

        _data = _data.data;

        self.setQuoteIdCookie(_data);

        //if (callback) {
        //callback();
        //return;
        //}

        pubsub.publish(self.CREATE_QUOTE_COMPLETE, [_data, _options]);
    });

    return self;
};

// TODO: Hook up ecom data from response
QuoteServiceFactory.prototype.getQuote = function (dat, options, callback) {
    // TODO: shift arguments if one is omitted, see commented callback code
    // callback = options
    // options = undefined
    console.log('QuoteServiceFactory -> getQuote()');
    console.log(this.DATA.ecom);
    console.log(this.DATA.vehicle);

    var data = dat ? dat : null;
    var self = this;
    var _service = self.GET_QUOTE_SERVICE;
    var $method = $.getJSON;

    if (arguments.length == 0) {
        data = self.DATA.ecom;
    }

    if (window.isEcom) {
        //_service = self.GET_QUOTE_ECOM_SERVICE;
        //$method = $.post;
    }

    //console.log("QuoteServiceFactory -> getQuote() --> data:", data);

    $method(_service, { quoteID: data.quoteID }).done(function (localData) {
        //console.log("QuoteServiceFactory -> getQuote() --> Complete");

        // check if data return is an json object
        var _data = isObject(localData) ? localData : JSON.parse(localData);
        var _options = options || {};

        _data = _data.data.data || _data.data;

        self.setGetQuoteData(_data);
        self.setQuoteIdCookie(_data);

        self.RESPONSE_DATA = _data;

        //if (callback) {
        //callback();
        //return;
        //}

        pubsub.publish(self.GET_QUOTE_COMPLETE, [_data, _options]);
    });
};

QuoteServiceFactory.prototype.setCreateQuoteData = function (articleNumber, quantity, type, inventory, inventoryId) {
    //console.log("QuoteServiceFactory -> setCreateQuoteData()");

    console.log('QuoteServiceFactory -> createQuote()');
    console.log(this.DATA.ecom);
    console.log(this.DATA.vehicle);

    var self = this;
    var _createQuoteData = self.DATA.create;
    var _vehicleData = self.DATA.vehicle;

    _createQuoteData.articleNumber = articleNumber;
    _createQuoteData.quantity = quantity;
    _createQuoteData.type = type;
    _createQuoteData.inventory = inventory;
    _createQuoteData.inventoryId = inventoryId;

    var _ecomData = self.DATA.ecom;
    var _quoteUrlPath = '';

    if (window.location.port) {
        _quoteUrlPath = window.location.origin + '/content/bsro/' + window.AEM.siteName + '/en/tires/tire-quote.html';
    } else {
        _quoteUrlPath = window.location.origin + '/tires/tire-quote/';
    }

    _ecomData.quoteURL = _quoteUrlPath;
    _ecomData.frontQuantity = quantity || 4;
    _ecomData.rearQuantity = _ecomData.rearQuantity != 0 ? quantity : 0;

    //console.log('_createQuoteData ', _createQuoteData);

    return self;
};

QuoteServiceFactory.prototype.setCreateQuoteDataByEvt = function (evt) {
    console.log('QuoteServiceFactory -> setCreateQuoteDataByEvt()');
    console.log(this.DATA.ecom);
    console.log(this.DATA.vehicle);

    var self = this;

    // from product results

    var $el = $(evt.currentTarget);
    var _elData = $el.data();
    var _ecomData = self.DATA.ecom;

    var article = $el.attr('data-article'),
        qty = $el.attr('data-quantity') || 2,
        type = $el.attr('data-type'),
        tireName = $el.attr('data-tire-name') || 'ProductResults tireName',
        inventoryQuantity = $el.attr('data-inventory-quantity'),
        inventoryId = $el.attr('data-inventory-id');

    var art_arr = article ? article.split('_') : [article];
    var invId_arr = inventoryId ? inventoryId.split('_') : [inventoryId];
    var invQty_arr = inventoryQuantity ? inventoryQuantity.split('_') : [inventoryQuantity];

    // *** TODO: this zip should be the zip of the store the quote was made for, not the users zip
    var _tmpZip = window.siteProfile.location.myZip || window.siteProfile.location.autoZip;
    var _origin = window.location.origin;
    var _damGlobalPath = 'https://s7d1.scene7.com/is/image/bridgestone/';

    var isDriveGuard = false;
    tireName = tireName.toLowerCase();

    if (tireName.indexOf('driveguard') >= 0 || tireName.indexOf('rft') >= 0) {
        isDriveGuard = true;
    }

    var stdOpt = $el.parents('div.product').hasClass('Standard') ? 'S' : 'O';

    var _tireBrandImage = _elData.tireLogo
        ? _damGlobalPath + _elData.tireLogo.toString().split('/').pop() + '-logo-images-tires-brands-global-web-bsro'
        : 'none';

    // set ecom data
    _ecomData.quoteType = type;
    _ecomData.zipCode = _tmpZip;
    _ecomData.storeNumber = window.lwsStoreId;
    _ecomData.tceStoreNumber = window.tceStoreId;
    //_ecomData.firstName = "firstName";
    //_ecomData.lastName = "lastName";
    //_ecomData.emailAddress = "email@address.com";
    _ecomData.frontArticleNumber = art_arr[0] || article;
    _ecomData.rearArticleNumber = art_arr[1] || '';
    _ecomData.frontInventory = invQty_arr[0] || 0;
    _ecomData.rearInventory = invQty_arr[1] || 0;
    //_ecomData.frontTireInventoryID = invId_arr[0] || inventoryId;
    //_ecomData.rearTireInventoryID = invId_arr[1] || "";
    _ecomData.frontQuantity = qty || 4;
    _ecomData.rearQuantity = art_arr[1] ? qty : 0;
    _ecomData.standardOptional = stdOpt;
    _ecomData.driveGuard = isDriveGuard;
    _ecomData.brgtTireLogoURL = _tireBrandImage;
    _ecomData.brgtTireImageURL = _origin + _elData.tireImage;
    _ecomData.brand = _elData.brand || _elData.catitemTirebrand || 'brand';
    _ecomData.tireName = _elData.tireName || _elData.catitemTirename || 'tire';

    if (type == 'vehicle') {
        var _vehicleData = self.DATA.vehicle;
        var _vehicleFitment = window.isEcom ? window.siteProfile.vehicles.tce : window.siteProfile.vehicles.main;

        _vehicleData.year = _vehicleFitment.year;
        _vehicleData.make = _vehicleFitment.make;
        _vehicleData.model = _vehicleFitment.model;
        _vehicleData.trim = _vehicleFitment.trim;
        _vehicleData.tpms = _vehicleFitment.tpms;
    }

    // set create quote data
    self.setCreateQuoteData(article, qty, type, inventoryQuantity, inventoryId);

    return self;
};

QuoteServiceFactory.prototype.setGetQuoteData = function (data) {
    console.log('QuoteServiceFactory -> setGetQuoteData()');
    console.log('top', this.DATA.ecom);
    console.log('top', this.DATA.vehicle);

    var self = this;
    var _ecomData = self.ecomData;
    var _tire = data.tire;
    var _rearTire = data.rearTire || {};
    var _vehicleFitment = data['vehicleFitment'] || null;
    var _storeInventory = data.storeInventory;
    var _quoteItem = data.quoteItem;
    var _damGlobalPath = 'https://s7d1.scene7.com/is/image/bridgestone/';

    // *** TODO: this zip should be the zip of the store the quote was made for, not the users zip
    var _tmpZip = window.siteProfile.location.myZip || window.siteProfile.location.autoZip;

    var isDriveGuard = false;
    var tireName = _tire.tireName.toLowerCase();

    if (tireName.indexOf('driveguard') >= 0 || tireName.indexOf('rft') >= 0) {
        isDriveGuard = true;
    }

    var _quoteUrlPath = '';

    if (window.location.port) {
        _quoteUrlPath = window.location.origin + '/content/bsro/' + window.AEM.siteName + '/en/tires/tire-quote.html';
    } else {
        _quoteUrlPath = window.location.origin + '/tires/tire-quote/';
    }

    var _tireBrandImage = _tire.tireBrandImage
        ? _damGlobalPath + _tire.tireModelName.toString().toLowerCase() + '-60-full-web-global-bsro'
        : 'none';

    _ecomData.quoteType = _vehicleFitment ? 'vehicle' : 'size';
    _ecomData.zipCode = _tmpZip;
    _ecomData.storeNumber = data.storeNumber;
    _ecomData.tceStoreNumber = data.tceStoreNumber;
    _ecomData.frontArticleNumber = _tire.article || 'articleID';
    _ecomData.rearArticleNumber = _rearTire.article || '';
    _ecomData.frontInventory = data.inventoryFront || 0;
    _ecomData.rearInventory = data.inventoryRear || 0;
    _ecomData.frontQuantity = _quoteItem.totalUnits || 4;
    _ecomData.rearQuantity = _quoteItem.rearTotalUnits || 0;
    _ecomData.standardOptional = _tire.standardOptional || 'S';
    _ecomData.driveGuard = isDriveGuard;
    _ecomData.quoteURL = _quoteUrlPath;
    _ecomData.brgtTireLogoURL = _tireBrandImage;
    _ecomData.brgtTireImageURL = _tire.tireImage || 'tire.png';
    _ecomData.brand = _tire.brand || 'brand';
    _ecomData.tireName = _tire.tireName || 'tire';
    _ecomData.quoteID = data.tireQuoteId;

    if (!data.inventoryFront && data.storeInventory) {
        _ecomData['frontInventory'] = data.storeInventory.quantityOnHand;
    }

    // TODO: need to know if quote is created using vehicle or tire size
    if (_vehicleFitment) {
        _ecomData.year = _vehicleFitment.year;
        _ecomData.make = _vehicleFitment.make;
        _ecomData.model = _vehicleFitment.model;
        _ecomData.trim = _vehicleFitment.submodel || _vehicleFitment.trim;
        _ecomData.tpms = _vehicleFitment.tpmsInd == 'true' ? 1 : 0;
        _ecomData.acesID = _vehicleFitment.acesVehicleId || '000000';
    }
    console.log('bottom: ', this.DATA.ecom);
    console.log('bottom: ', this.DATA.vehicle);
};

QuoteServiceFactory.prototype.setQuoteIdCookie = function (data) {
    console.log('QuoteServiceFactory -> setQuoteIdCookie()');
    console.log(this.DATA.ecom);
    console.log(this.DATA.vehicle);

    var self = this;

    var _data = data;
    var _quoteId = _data.tireQuoteId;
    var _ecomData = self.DATA.ecom;
    var _vehicleData = self.DATA.vehicle;

    if (_quoteId != undefined && _quoteId != null) {
        var prevQuoteID = getCookie('bsro.ui.quoteID');

        if (prevQuoteID) {
            //console.log("QuoteServiceFactory -> setQuoteIdCookie() --> Clean Abandoned Quote Cookie:", prevQuoteID);
            removeCookie(prevQuoteID);
        }

        setCookie('bsro.ui.quoteID', _quoteId);

        removeCookie('bsro.ui.catitem.full');
        removeCookie('bsro.ui.catitem.brand');
        removeCookie('bsro.ui.catitem.name');

        _ecomData['quoteID'] = _quoteId;
        _ecomData['frontQuantity'] = _data.quantity;
        _ecomData['rearQuantity'] = _data.rearQuantity || 0;
        _ecomData['frontInventory'] = _data.inventoryFront || 0;
        _ecomData['rearInventory'] = _data.inventoryRear || '0';

        _ecomData['frontArticleNumber'] = _data.tire.article || 'articleId';
        _ecomData['rearArticleNumber'] = _data.rearTire ? _data.rearTire.article : '';

        if (!_data.inventoryFront && _data.storeInventory) {
            _ecomData['frontInventory'] = _data.storeInventory.quantityOnHand;
        }

        if (_data.tire) {
            _ecomData['size'] = _data.tire.tireSize;
        }

        if (!isEmpty(_data.vehicleFitment)) {
            var _vehicle = _data.vehicleFitment;

            _ecomData['quoteType'] = 'vehicle';

            _vehicleData['year'] = _vehicle.year;
            _vehicleData['make'] = _vehicle.make;
            _vehicleData['model'] = _vehicle.model;
            _vehicleData['trim'] = _vehicle.submodel;
            _vehicleData['tpms'] = _vehicle.tpmsInd == 'true' ? 1 : 0;
            _vehicleData['acesID'] = _vehicle.acesVehicleId;

            // TODO: decouple ecom and vehicle data or unify? seem liks might be an issue of syncing data later
            $.extend(_ecomData, _vehicleData);
        }
        console.log('_ecomData: ', _ecomData);
        console.log('_vehicleData', _vehicleData);
        var _eDataStr = JSON.stringify(_ecomData);

        setCookie(_quoteId, encodeURIComponent(_eDataStr), 1); // 0.0035 expires in 5 min
    }

    return self;
};

//let QuoteServiceFactory = new QuoteServiceFactory1();

export { QuoteServiceFactory };
