/**
 * Constructs a Scene7 image URL for tire assets based on provided options.
 *
 * @param {Object} tireOptions - Options for the tire image.
 * @param {string} tireOptions.brand - The brand of the tire (e.g., "bridgestone", "firestone").
 * @param {string} tireOptions.subBrand - The sub-brand of the tire (e.g., "potenza", "blizzak").
 * @param {string} tireOptions.pattern - The pattern of the tire (e.g., "s007a-rft", "ws-90").
 * @param {string} tireOptions.angle - The angle of the tire image (e.g., "60", "90", "180").
 * @param {boolean} [tireOptions.full] - Indicates if the image is a full image.
 * @param {boolean} [tireOptions.zoom] - Indicates if the image is zoomed in.
 * @param {string} [tireOptions.web] - Indicates the web context for the image (e.g., "web").
 * @param {string} [tireOptions.buScope] - The scope of the business unit (e.g., "bst", "shared").
 * @param {string} [tireOptions.buOwnership] - The business unit owner of the image (e.g., "consumer", "bsro").
 * @param {Object} scene7Options - Additional options for Scene7 parameters.
 *
 * @returns {string} The constructed URL for the tire image.
 */
/*
    Tire Naming Convention
    Tire naming structure is more specific to the needs of these assets.

    TBD - the below is courtesy of Sean Dunlop (Unlicensed) as it is setup on Consumer Tire - let's talk about it as well and make it a standard:

    Make image names all “lower” case.  We have run into conflicts with caching and redirects with names that are not lower case
    Convert “_” to “-“ to be consistent.
    And our recommendation is to include this as the naming pattern for tire images.
    <brand> - bridgestone or firestone
    <sub-brand> - ex: potenza, blizzak, alenza,
    <pattern> - ex: s007a-rft, ws-90, as-ultra
    <angle> - angle of the tire image with these three being the most commonly used
    60 – The “tilted” view
    90 – The “front” view
    180 – The “side” view
    <full||zoom> - explanation if image is the full image or zoomed in part of an image
    <web> - The place the web is supposed to be used, with consumer tire and bsro wanted to use images for the “web”, representing the website.
    <bu scope> - If this is for a particular website, such as “bst” or “fst”, or if “shared” across websites, for example BSRO sharing across, FCAC, Tires Plus, Hibdon Tire, and Wheel Works.
    <bu ownership> - The BU owner of the image.
    https://s7d1.scene7.com/is/image/bridgestone/bridgestone-potenza-s007a-rft-60-full-web-bst-consumer
    Proposed by Stephen (Mike made edits per an email from Dov on Friday, August 12)
    https://s7d1.scene7.com/is/image/bridgestone/bridgestone-potenza-s007a-rft-60-full-global-web-bsro
    <brand>-<sub-brand>-<pattern>-<angle>-<full||zoom>-<web>-<bu scope>-<bu ownership>
    Key as follows to support consumer tire and bsro tire images
    So based on the examples you have bellow we would recommend the following
*/
/*
https://s7d1.scene7.com/is/image/bridgestone/bridgestone-blizzak-lm005-60-full-web-bst-consumer
https://s7d1.scene7.com/is/image/bridgestone/bridgestone-firehawk-as-60-web-global-bsro
<brand>-<sub-brand>-<pattern>-<angle>-<full||zoom>-<web>-<bu scope>-<bu ownership>
*/

import { scene7Params } from './scene7Params';

const tirePath = (tireOptions, scene7Options) => {
    const imageProperties = ['brand', 'subBrand', 'pattern', 'angle', 'full', 'zoom', 'web', 'buScope', 'buOwnership'];
    const isSVG = false;
    const domain = 'https://s7d1.scene7.com';
    const path = `/is/${isSVG ? 'content' : 'image'}/bridgestone`;
    let file = '/';

    imageProperties.forEach((imageProperty, index) => {
        let value = tireOptions[imageProperty];

        if (value) {
            if (imageProperty === 'pattern') {
                //console.warn('image pattern:', value);
                value = String(value)
                    .trim() // remove trailing whitespaces
                    .toLowerCase() // make all lowercase
                    .replace(/ - /g, ' ') // replace ' - ' with a space
                    .replace(/\s/g, '-') // repkaces white spaces with a dash
                    .replace(/\/|\+/g, ''); // strip out forward slashes and plus signs
            }

            //  Use the key when the value is a true(Boolean)
            if (value === true) {
                value = imageProperty;
            }

            file += `${index > 0 ? '-' : ''}${value}`;
        }
    });

    return `${domain}${path}${file}${scene7Params(scene7Options)}`;
};

export { tirePath };
