import { getCookie } from 'common/js/library/util';

/**
 * Looks up a cookie by partial name match.
 * @param {RegExp} reName Regular expression to match the cookie name
 * @returns Value for first matching cookie name
 */
function getCookieByNameMatch(reName) {
    const decodedCookies = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookies.split(';');

    let result = '';

    for (let i = 0; i < cookiesArray.length; i++) {
        const [name, value] = cookiesArray[i].trim().split('=');
        if (reName.test(name)) {
            result = value;
            break;
        }
    }
    return result;
}

/**
 * Gets the ECID value from which ever cookie is found first.
 * @returns ECID value from cookie
 */
function getEcid() {
    const targetExp = /MCMID\|(\d*)/;
    let cookieValue = getCookie('s_ecid');
    let matches = targetExp.exec(cookieValue);
    if (!matches) {
        cookieValue = getCookieByNameMatch(/^AMCV_.*@AdobeOrg/);
        matches = targetExp.exec(cookieValue);
    }
    return matches ? matches[1] : null;
}

export { getEcid };
