import { postOptions } from '../serviceUtilities';
import { getCookie } from 'common/js/library/util';

export const addStoreLocationToAccount = async (storeId) => {
    try {
        const url = '/bsro/services/account/add-store';
        const tokenCookie = getCookie('tokenId');
        const requestPayload = { storeId: storeId };
        const options = postOptions(requestPayload, tokenCookie);

        const response = await fetch(url, options);
        console.log('addStoreLocationToAccount response: ', response);

        if (response.ok) {
            const data = await response.json();
            return data;
        }
    } catch (err) {
        console.log('error');
    }
};

export const setPreferredStoreToAccount = async (localStoreId) => {
    try {
        const url = '/bsro/services/account/set-preferred-store';
        const tokenCookie = getCookie('tokenId');
        const requestPayload = { storeId: localStoreId };
        const options = postOptions(requestPayload, tokenCookie);

        const response = await fetch(url, options);

        if (response.ok) {
            const data = await response.json();
            return data;
        }
    } catch (err) {
        console.log('error');
    }
};
