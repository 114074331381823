/**
 * separates phone number with provided separator
 * @param {string} phoneNumber
 * @param {string} separator
 */
const formatPhoneWithSeparators = (phoneNumber, separator = '') => {
    const cleanPhoneNumber = phoneNumber.replace(/\D/g, ' ');
    const numbers = cleanPhoneNumber.match(/\d{3,4}/g);
    separator = separator || '';

    return numbers.join(separator);
};

export { formatPhoneWithSeparators };
