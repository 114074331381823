import Handlebars from 'common/js/library/handlebar';
import { formatPhoneWithSeparators } from 'common/js/util/phone/formatPhoneWithSeparators';
import { goToStep } from './goToStep';
import { toCapitalCase } from 'common/js/library/util';

const updateAppointmentDetails = (rootEl, props) => {
    const { dateStoreData, getAppointmentDetails, setAppointmentDetails, serviceStep, getStoresInfo } = props;
    const { year, make, model, trim } = getAppointmentDetails().vehicle;
    const monthStr = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ];
    const apptDetailsInfoTemplate = rootEl.querySelector('[data-appointment-details-info-template]');
    let container = rootEl.querySelector('.appointment-details .appointment-details-info');
    let htmlCopy = apptDetailsInfoTemplate.cloneNode(true).innerHTML;
    let template = Handlebars.compile(htmlCopy);
    const labelWait = 'wait in store';
    const labelDrop = 'drop off';
    const labelNoPref = 'no preference';
    const dropOffValue = rootEl.querySelector('[name=wait-or-drop-off]:checked').value.toString();
    const dropOff = dropOffValue === '0' ? labelWait : labelDrop;
    const pickupValue = rootEl.querySelector('[name=pickupPreference]').value.toString();
    const pickup = dropOff == labelWait ? labelNoPref : pickupValue;

    setAppointmentDetails({
        date: dateStoreData,
        store: getStoresInfo().storesAsDataObj[dateStoreData.storeNumber],
        vehicle: {
            year,
            make,
            model,
            trim
        },
        services: serviceStep.dataset?.selectedServices?.split(','),
        dropoff: toCapitalCase(dropOff),
        pickup: toCapitalCase(pickup)
    });

    const phone = getAppointmentDetails().store.phone;

    // where is this used?
    dateStoreData.availableMonthStr = toCapitalCase(monthStr[dateStoreData.availableMonth - 1]);

    [...container.children].forEach((child) => {
        child.remove();
    });
    //container.children.remove();
    container.insertAdjacentHTML('beforeend', template(getAppointmentDetails()));

    [...rootEl.querySelectorAll('.icon-pencil[data-step-to]')].forEach((stepToButton) => {
        stepToButton.addEventListener('click', (evt) => {
            goToStep(evt);
        });
    });

    // update the duplicate appointment warning modal with the selected store's phone number
    if (phone) {
        const apptPhone = document.querySelectorAll('.appt-phone');
        const apptPhoneTel = document.querySelectorAll('.appt-phone-tel');

        apptPhone.forEach((item) => {
            item.innerHTML = formatPhoneWithSeparators(phone, '.');
        });
        apptPhoneTel.forEach((item) => {
            item.setAttribute('href', `tel:${formatPhoneWithSeparators(phone)}`);
        });
    }
};

export { updateAppointmentDetails };
