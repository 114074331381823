import '../scss/product-results.scss';
import { QuoteServiceFactory } from 'common/js/library/quote-service-factory';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { component } from 'common/js/library/component';

const quoteService = new QuoteServiceFactory();

const instanceInit = (rootEl) => {
    console.log('inside product-result.js');

    document.addEventListener('click', (evt) => {
        if (evt.target.matches('.tire-quote-btn')) {
            evt.preventDefault();

            const el = evt.target;
            const parent = el.closest('.product:not(.gbb-card)');

            if (!parent.classList.contains('inactive')) {
                quoteService.setCreateQuoteDataByEvt(evt).createQuote();
            }
        }
    });

    if (rootEl.classList.contains('catitem-quote')) {
        const quoteBtn = rootEl.querySelector('.tire-quote-btn');
        quoteBtn?.click();
    }

    loadSortFunctionality();
    attachDisclamierEventListener();
};

const attachDisclamierEventListener = () => {
    const showDisclaimerBtn = document.querySelector('.show_full_disclaimer');

    showDisclaimerBtn?.addEventListener('click', function (event) {
        const showDisclaimerText = 'Show Full Disclaimer';
        const hideDisclaimerText = 'Hide Full Disclaimer';

        if (event.target.textContent.trim() === showDisclaimerText) {
            event.target.textContent = hideDisclaimerText;
        } else {
            event.target.textContent = showDisclaimerText;
        }
        event.preventDefault();
    });
};

const checkBoxes = document.querySelectorAll('#product_results .category input');
const brandCheckboxes = document.querySelectorAll('#product_results .brand-category input');
const typeCheckboxes = document.querySelectorAll('#product_results .type-category input');
const priceCheckboxes = document.querySelectorAll('#product_results .price-category input');
const saleCheckboxes = document.querySelectorAll('#product_results .sale-only input');
const matchedsetCheckboxes = document.querySelectorAll('#product_results .matchedSet-category input');
const sizeCheckboxes = document.querySelectorAll('#product_results .size-category input');
const tireRatingCheckboxes = document.querySelectorAll('#product_results .rating-category input');
const mileageCheckboxes = document.querySelectorAll('#product_results .mileage-category input');
const stdoptCheckboxes = document.querySelectorAll('#product_results .stdOpt-category input');

[...checkBoxes].forEach((checkBoxEl) => {
    checkBoxEl.addEventListener('change', () => handleChange());
});

const handleChange = () => {
    const selectedBrands = [];
    const selectedTypes = [];
    const selectedSize = [];
    const selectedPrice = [];
    const selectedSale = [];
    const selectedMatchedSet = [];
    const selectedTireRating = [];
    const selectedMileage = [];
    const selectedStdOpt = [];

    brandCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
            selectedBrands.push(checkbox.value);
        }
    });

    typeCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
            selectedTypes.push(checkbox.value);
        }
    });

    priceCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
            selectedPrice.push(checkbox.value);
        }
    });

    saleCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
            selectedSale.push(checkbox.value);
        }
    });

    matchedsetCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
            selectedMatchedSet.push(checkbox.value);
        }
    });

    sizeCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
            selectedSize.push(checkbox.value);
        }
    });

    tireRatingCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
            selectedTireRating.push(checkbox.value);
        }
    });

    mileageCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
            selectedMileage.push(checkbox.value);
        }
    });

    stdoptCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
            selectedStdOpt.push(checkbox.value);
        }
    });

    document.querySelectorAll('div.product:not(.gbb-card)').forEach((productEl) => {
        let brandStatus = false;
        if (selectedBrands.length == 0) {
            brandStatus = true;
        } else {
            for (let i = 0; i < selectedBrands.length; ++i) {
                brandStatus = productEl.classList.contains(selectedBrands[i]);
                if (brandStatus == true) {
                    break;
                }
            }
        }

        let tireStatus = false;
        if (selectedTypes.length == 0) {
            tireStatus = true;
        } else {
            for (let i = 0; i < selectedTypes.length; ++i) {
                tireStatus = productEl.classList.contains(selectedTypes[i]);
                if (tireStatus == true) {
                    break;
                }
            }
        }

        let priceStatus = false;
        if (selectedPrice.length == 0) {
            priceStatus = true;
        } else {
            for (let i = 0; i < selectedPrice.length; ++i) {
                priceStatus = productEl.classList.contains(selectedPrice[i]);
                if (priceStatus == true) {
                    break;
                }
            }
        }

        let saleStatus = false;
        if (selectedSale.length == 0) {
            saleStatus = true;
        } else {
            for (let i = 0; i < selectedSale.length; ++i) {
                saleStatus = productEl.classList.contains(selectedSale[i]);
                if (saleStatus == true) {
                    break;
                }
            }
        }

        let matchedsetStatus = false;
        if (selectedMatchedSet.length == 0) {
            matchedsetStatus = true;
        } else {
            for (let i = 0; i < selectedMatchedSet.length; ++i) {
                matchedsetStatus = productEl.classList.contains(selectedMatchedSet[i]);
                if (matchedsetStatus == true) {
                    break;
                }
            }
        }

        let sizeStatus = false;
        if (selectedSize.length == 0) {
            sizeStatus = true;
        } else {
            for (let i = 0; i < selectedSize.length; ++i) {
                sizeStatus = productEl.classList.contains(selectedSize[i]);
                if (sizeStatus == true) {
                    break;
                }
            }
        }

        let tireRatingStatus = false;
        if (selectedTireRating.length == 0) {
            tireRatingStatus = true;
        } else {
            for (let i = 0; i < selectedTireRating.length; ++i) {
                tireRatingStatus = productEl.classList.contains(selectedTireRating[i]);
                if (tireRatingStatus == true) {
                    break;
                }
            }
        }

        let mileageStatus = false;
        if (selectedMileage.length == 0) {
            mileageStatus = true;
        } else {
            for (let i = 0; i < selectedMileage.length; ++i) {
                mileageStatus = productEl.classList.contains(selectedMileage[i]);
                if (mileageStatus == true) {
                    break;
                }
            }
        }

        let stdoptStatus = false;
        if (selectedStdOpt.length == 0) {
            stdoptStatus = true;
        } else {
            for (let i = 0; i < selectedStdOpt.length; ++i) {
                stdoptStatus = productEl.classList.contains(selectedStdOpt[i]);
                if (stdoptStatus == true) {
                    break;
                }
            }
        }

        if (
            brandStatus == true &&
            tireStatus == true &&
            priceStatus == true &&
            saleStatus == true &&
            matchedsetStatus == true &&
            sizeStatus == true &&
            tireRatingStatus == true &&
            mileageStatus == true &&
            stdoptStatus == true
        ) {
            productEl.style.display = 'block';
        } else {
            productEl.style.display = 'none';

            const inputs = productEl.querySelectorAll('input');
            inputs.forEach((input) => {
                input.checked = false;
            });
        }
    });

    // Get all visible product elements
    const products = document.querySelectorAll('div.product:not(.gbb-card):not([style*="display: none"])');

    // Get the number of visible product elements
    const resultCount = products.length;

    // Update the result count element
    const resultCountEl = document.querySelector('.number-of-results span.result-count');
    resultCountEl.innerHTML = resultCount;

    // Show/hide the no-result-error element based on the result count
    const noResultErrorEl = document.querySelector('.no-result-error');
    if (noResultErrorEl) {
        if (resultCount === 0) {
            noResultErrorEl.style.display = 'block';
        } else {
            noResultErrorEl.style.display = 'none';
        }
    }
};

const brand = [];
const price = [];
const mileage = [];
const price_zero_Arr = [];
let undefined_arr = [];
let tire_survey_details = [];

document.querySelectorAll('.product:not(.gbb-card)').forEach((product) => {
    const brandArr = [];
    const priceArr = [];
    const mileageArr = [];

    let product_name = product.getAttribute('data-product-name') || ''; // Setting default for those elements that don't have the data attributes on them.
    product_name = product_name.replace(/ /g, '').toLowerCase();
    brandArr.push(product_name);
    brandArr.push(product);
    brand.push(brandArr);

    const priceData = product.querySelector('.price span')?.getAttribute('data-price');
    if (priceData != undefined) {
        priceArr.push(priceData);
        priceArr.push(product);
        price.push(priceArr);
    } else {
        price_zero_Arr.push(product);
    }
    price.sort();

    const mile = product.querySelector('.mileage')?.textContent?.replace(',', '');
    mileageArr.push(mile);
    mileageArr.push(product);
    mileage.push(mileageArr);
    mileage.sort();

    price.sort(function (a, b) {
        return a[0] - b[0];
    });

    product.remove();

    for (let i = 0; i < price.length; i++) {
        document.querySelector('.results')?.appendChild(price[i][1]);
    }
    for (let i = 0; i < price_zero_Arr.length; i++) {
        document.querySelector('.results')?.appendChild(price_zero_Arr[i]);
    }
});

let tire_brand_price = [];
let tire_brand_zero = [];
let tire_brand_priceData = [];

let data_rank = document.querySelector('.results')?.getAttribute('data-tire-rank');
document.querySelector('.results .product:not(.gbb-card):first-child')?.classList?.add('brand-one');

[...document.querySelectorAll('.product:not(.gbb-card)')].forEach(function (elem) {
    let tire_product_name = elem.getAttribute('data-product-name') || '';
    let brand_name = tire_product_name.indexOf(data_rank) > -1;

    if (brand_name == true) {
        elem.classList.add('tire-brand');
        let tire_brand_priceArr = [];
        tire_brand_priceData = elem.querySelector('.tire-brand .price span')?.getAttribute('data-price');

        if (tire_brand_priceData != undefined) {
            tire_brand_priceArr.push(tire_brand_priceData);
            tire_brand_priceArr.push(elem);
            tire_brand_price.push(tire_brand_priceArr);
        } else {
            tire_brand_zero.push(elem);
        }
        tire_brand_price.sort();
        tire_brand_price.sort(function (a, b) {
            return b[0] - a[0];
        });

        [...document.querySelectorAll('.tire-brand')].forEach(function (elem) {
            elem.remove();
        });

        for (let i = 0; i < tire_brand_zero.length; i++) {
            document
                .querySelector('.brand-one')
                .parentNode.insertBefore(tire_brand_zero[i], document.querySelector('.brand-one'));
        }

        for (let i = 0; i < tire_brand_price.length; i++) {
            document
                .querySelector('.brand-one')
                .parentNode.insertBefore(tire_brand_price[i][1], document.querySelector('.brand-one'));
        }
    }
});

/* Tire rank priority sorting End*/

const tireSurveySort = (sortData) => {
    [...document.querySelectorAll('.product:not(.gbb-card)')].forEach(function (elem) {
        let product_spec_Arr = [];
        let attribute = elem.querySelector('.product-specs').getAttribute(sortData);

        if (attribute != undefined) {
            product_spec_Arr.push(attribute);
            product_spec_Arr.push(elem);
            tire_survey_details.push(product_spec_Arr);
        } else {
            undefined_arr.push(elem);
        }
        tire_survey_details.sort();
    });

    tire_survey_details.sort(function (a, b) {
        return b[0] - a[0];
    });

    document.querySelectorAll('.product:not(.gbb-card)').forEach(function (elem) {
        elem.remove();
    });

    tire_survey_details.forEach(function (elem) {
        document.querySelector('.results')?.appendChild(elem[1]);
    });

    undefined_arr.forEach(function (elem) {
        document.querySelector('.results')?.appendChild(elem);
    });

    tire_survey_details = [];
    undefined_arr = [];
};

const lowToHigh = () => {
    const _sortSelect = document.querySelectorAll(
        '.sort-select-1 option[value=lowtohigh],#sort-select-2 option[value=lowtohigh]'
    );

    if (_sortSelect.length) {
        document.querySelectorAll('.sort-select-1,#sort-select-2').forEach(function (e) {
            e.value = 'hightolow';
        });

        if (window.isEcom) {
            sortByPrice();
            return;
        }

        price.sort(function (a, b) {
            return a[0] - b[0];
        });

        const results = document.querySelector('.results');
        if (results) {
            results.querySelectorAll('.product:not(.gbb-card)').forEach(function (element) {
                element.remove();
            });
        }

        for (let i = 0; i < price.length; i++) {
            results.appendChild(price[i][1]);
        }

        for (let i = 0; i < price_zero_Arr.length; i++) {
            results.appendChild(price_zero_Arr[i]);
        }
    }
};

const highToLow = () => {
    const _sortSelect = document.querySelectorAll(
        '.sort-select-1 option[value=hightolow],#sort-select-2 option[value=hightolow]'
    );

    if (_sortSelect.length) {
        document.querySelectorAll('.sort-select-1,#sort-select-2').forEach(function (e) {
            e.value = 'hightolow';
        });

        if (window.isEcom) {
            sortByPrice('high');
            return;
        }

        price.sort(function (a, b) {
            return b[0] - a[0];
        });

        const results = document.querySelector('.results');
        if (results) {
            results.querySelectorAll('.product:not(.gbb-card)').forEach(function (element) {
                element.remove();
            });
        }

        for (let i = 0; i < price.length; i++) {
            results.appendChild(price[i][1]);
        }

        for (let i = 0; i < price_zero_Arr.length; i++) {
            results.appendChild(price_zero_Arr[i]);
        }
    }
};

const loadSortFunctionality = () => {
    [...document.querySelectorAll('.sort-select-1,#sort-select-2')].forEach(function (el) {
        el.addEventListener('change', (event) => {
            const selectedValue = event.currentTarget.value;

            setAdobeDataLayer({
                event: 'tires.results.sort',
                sort: selectedValue,
                isSearchResult: 'false'
            });

            // removing headers to avoid duplicate when
            // sort filter append new content
            document.querySelectorAll('.in-stock-header, .out-stock-header').forEach(function (header) {
                header.remove();
            });

            document.querySelectorAll('.sort-select-1,#sort-select-2').forEach((select) => {
                select.value = selectedValue;
            });

            if (selectedValue === 'lowtohigh') {
                lowToHigh();
            } else if (selectedValue === 'hightolow') {
                highToLow();
            } else if (selectedValue === 'brand') {
                document.querySelectorAll('.product:not(.gbb-card)').forEach(function (product) {
                    product.remove();
                });

                brand.sort();
                for (let i = 0; i < brand.length; i++) {
                    document.querySelector('.results')?.append(brand[i][1]);
                }
            } else if (selectedValue === 'mileage') {
                mileage.sort((a, b) => {
                    return b[0] - a[0];
                });

                document.querySelectorAll('.product:not(.gbb-card)').forEach((product) => {
                    product.remove();
                });

                for (let i = 0; i < mileage.length; i++) {
                    document.querySelector('.results')?.append(mileage[i][1]);
                }
            } else if (selectedValue === 'wet') {
                tireSurveySort('data-wet');
            } else if (selectedValue === 'dry') {
                tireSurveySort('data-dry');
            } else if (selectedValue === 'comfort') {
                tireSurveySort('data-comfort');
            } else if (selectedValue === 'winter') {
                tireSurveySort('data-snow');
            } else if (selectedValue === 'tread') {
                tireSurveySort('data-treadwear');
            }
        });
    });
};

const sortByPrice = (sortBy) => {
    const products = document.querySelectorAll('.results .product[data-price]:not(.gbb-card)');
    const sortedProducts = Array.from(products);

    sortedProducts.sort(function (a, b) {
        return parseFloat(a.dataset.price) - parseFloat(b.dataset.price);
    });

    if (sortBy == 'high') {
        sortedProducts.reverse();
    }

    for (let i = 0; i < products.length; i++) {
        products[i].remove();
    }

    // using prepend instead of append
    // some products will not have pricing so
    // they stay at the end
    const results = document.querySelector('.results');
    for (let i = 0; i < sortedProducts.length; i++) {
        results?.prepend(sortedProducts[i]);
    }

    return sortedProducts;
};

component({
    selector: '[data-product-results] .container',
    instanceInit,
    name: 'product-results'
});
