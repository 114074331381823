import React from 'react';
import ReactModal from 'react-modal';

import classNames from 'classnames';

const Modal = (props) => {

    const {
        closeModal,
        containerClass = '',
        isModalOpen,
        isRoute ,
        modalContent
    } = props;

    function getParent() {
        return document.querySelector('#wrapper'); //return document.querySelector('.root'); 
    }

    const handleCloseModal = (e) => {
        e.preventDefault();
        closeModal();
    };

    ReactModal.setAppElement(document.getElementById('main'));

    const allowClose = isRoute ? false : true;

    const containerClassNames = classNames("rm-container", "app-global", containerClass)

    return (
        <ReactModal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            portalClassName="rm-modal"
            parentSelector={getParent}
            overlayClassName="rm-overlay"
            className={containerClassNames}
            bodyOpenClassName="rm-open-body"
            htmlOpenClassName={null}
            shouldCloseOnOverlayClick={allowClose}
            shouldFocusAfterRender={true}
            shouldCloseOnEsc={allowClose}
            role="dialog"
        >
            <div className="rm-header">
                <p onClick={handleCloseModal}> X </p>
            </div>
            <div className="rm-content">{modalContent}</div>
        </ReactModal>
    );
};

export default Modal;
