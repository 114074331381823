import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import Button from '../../../elements/button/v1/Button';
import { VehicleSelectorForm } from './VehicleSelectorForm';
import { setVehicle } from 'common/js/data/services/vehicle/vehicleData';
import { pubsub } from 'common/js/library/pubsub';
import { setStoreByZip } from 'common/js/data/services/store-location';
import { setZipStoreHtml } from 'common/js/util/geolocation/setZipStoreHtml';

const VehicleSelectorColumn = (props) => {
    const {
        years,
        makes,
        models,
        trims,
        selectedYear,
        selectedMake,
        selectedModel,
        selectedTrim,
        selectedVehicleId,
        onVehicleSelected,
        onYearSelected,
        onModelSelected,
        onMakeSelected,
        onTrimSelected,
        onRevertToYear,
        onRevertToMake,
        onRevertToModel,
        buttonLabel,
        confirmMessageText,
        preselectVehicle,
        preselectVehicleData,
        preselectStatus,
        setPreselectStatus,
        clearSelectedYear,
        styleName,
        isValidZip,
        zipCode,
        isPinnacle
    } = props;

    const disableButton = isPinnacle
        ? selectedYear && selectedMake && selectedModel && selectedTrim && isValidZip
            ? false
            : true
        : selectedYear && selectedMake && selectedModel && selectedTrim
          ? false
          : true;

    const [confirmMessage, setConfirmMessage] = useState('');
    const headingEl = document.getElementById('sa-heading');

    if (isPinnacle && headingEl) {
        headingEl.innerText = 'WHAT VEHICLE NEEDS SERVICE?';
    }

    useEffect(() => {
        if (selectedTrim && confirmMessageText) {
            setConfirmMessage(
                `${confirmMessageText} ${selectedYear} ${selectedMake} ${selectedModel} ${selectedTrim}.`
            );
        } else {
            setConfirmMessage('');
        }
    }, [selectedTrim, selectedYear, selectedMake, selectedModel, confirmMessageText]);

    useEffect(() => {
        const vehicleData = preselectVehicleData || {};
        if (Object.keys(vehicleData).length > 0) {
            preselectVehicle(preselectVehicleData);
            setPreselectStatus(true);
        }
    }, [preselectVehicleData]);

    const setSiteProfileVehicle = (vehicle, type) => {
        const { year, make, model, trim, tpms } = vehicle;
        window.siteProfile.vehicles[type] = {
            ymm: `${year}-${make}-${model}`,
            year,
            make,
            model,
            trim,
            tpms
        };
    };

    const zipCodeHandler = async () => {
        var myZip = window?.siteProfile?.location?.myZip;
        if (myZip && myZip !== '' && myZip !== zipCode) {
            const { storeNumber } = await setStoreByZip(zipCode);
            await setZipStoreHtml(zipCode, storeNumber, true);
        }
    };

    const setVehicleCookie = async (vehicleData) => {
        console.log('vehicle Data ' + vehicleData);
        // set vehicle cookie value
        const { year, make, model, trim, tpms } = { ...vehicleData };
        const response = await setVehicle(year, make, model, trim, tpms);
        if (response.success === 'false') {
            // probably don't need to alert the user if this fails
        } else {
            vehicleData.tpms = response?.data?.tpms;
            setSiteProfileVehicle(vehicleData, 'tce');
            if (preselectVehicleData?.origin !== 'vehicle-picker-modal') {
                pubsub.publish('vehicle-data-cookie');
            }
        }
    };

    return (
        <>
            <div className={`vehicle-selector-column ${isPinnacle ? 'cmp-pinnacle' : ''}`}>
                <Formik
                    initialValues={{ year: '', make: '', model: '', trim: '' }}
                    onSubmit={async (values, actions) => {
                        onVehicleSelected({
                            year: selectedYear,
                            make: selectedMake,
                            model: selectedModel,
                            trim: selectedTrim,
                            tpms: values.tpms,
                            vehicleId: selectedVehicleId
                        });
                        actions.setSubmitting(false);
                    }}
                    enableReinitialize
                >
                    {({ setFieldValue }) => (
                        <Form id="vehicleSelectForm">
                            <VehicleSelectorForm
                                years={years}
                                models={models}
                                makes={makes}
                                trims={trims}
                                selectedYear={selectedYear}
                                selectedMake={selectedMake}
                                selectedModel={selectedModel}
                                selectedTrim={selectedTrim}
                                onYearSelected={onYearSelected}
                                onMakeSelected={onMakeSelected}
                                onModelSelected={onModelSelected}
                                onTrimSelected={onTrimSelected}
                                onRevertToYear={onRevertToYear}
                                onRevertToMake={onRevertToMake}
                                onRevertToModel={onRevertToModel}
                                setFieldValue={setFieldValue}
                                preselectStatus={preselectStatus}
                                clearSelectedYear={clearSelectedYear}
                            />

                            <div className="next">
                                {confirmMessage && <div className="confirm-message">{confirmMessage}</div>}
                                <Button
                                    disabled={disableButton}
                                    clickAction={() => {
                                        setVehicleCookie({
                                            year: selectedYear,
                                            make: selectedMake,
                                            model: selectedModel,
                                            trim: selectedTrim,
                                            vehicleId: selectedVehicleId
                                        });

                                        if (isPinnacle && zipCode && isValidZip) {
                                            zipCodeHandler();
                                        }
                                    }}
                                    type="submit"
                                    styleName={styleName}
                                >
                                    {buttonLabel || 'Submit'}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};

VehicleSelectorColumn.propTypes = {
    years: PropTypes.array.isRequired,
    makes: PropTypes.array.isRequired,
    models: PropTypes.array.isRequired,
    trims: PropTypes.array.isRequired,
    selectedYear: PropTypes.string.isRequired,
    selectedMake: PropTypes.string.isRequired,
    selectedModel: PropTypes.string.isRequired,
    selectedTrim: PropTypes.string.isRequired,
    selectedVehicleId: PropTypes.string.isRequired,
    onVehicleSelected: PropTypes.func.isRequired,
    onYearSelected: PropTypes.func.isRequired,
    onModelSelected: PropTypes.func.isRequired,
    onMakeSelected: PropTypes.func.isRequired,
    onTrimSelected: PropTypes.func.isRequired,
    onRevertToYear: PropTypes.func.isRequired,
    onRevertToMake: PropTypes.func.isRequired,
    onRevertToModel: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string,
    confirmMessageText: PropTypes.string,
    preselectVehicle: PropTypes.func,
    preselectVehicleData: PropTypes.object,
    preselectStatus: PropTypes.bool,
    setPreselectStatus: PropTypes.func,
    clearSelectedYear: PropTypes.func,
    styleName: PropTypes.string.isRequired,
    isValidZip: PropTypes.bool,
    zipCode: PropTypes.string,
    isPinnacle: PropTypes.bool
};

export { VehicleSelectorColumn };
