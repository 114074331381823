/**
 * Primary Subnavs
 * @param {HTMLElement} container
 *
 * This module is responsible for handling the Tires, Auto Repair, and Auto Maintenance subnavs.
 * It will handle the keyboard navigation for the Tires, Auto Repair, and Auto Maintenance subnavs.
 *
 * When the user presses the ENTER key on the menu item, it will open the submenu.
 * When the user presses the ESC key, it will close the submenu.
 * When the user tabs off of the last element in the submenu (Auto Maintenance), it will close the submenu.
 */

export const PrimarySubnavs = (container) => {
    // The focusable elements that can be tabbed through.
    const FOCUSABLE_ELEMENTS = 'a, button';
    // The class name for the open submenu.
    const IS_OPEN = 'is-open';

    // Get all the menu items that have submenus.
    // '#nav-coupons > a' does not have a submenu but is included to close the Auto Maintenance submenu when focused.
    const menus = container?.querySelectorAll(
        '#nav-tires > a, #nav-auto_repair > a, #nav-auto_maintenance > a, #nav-coupons > a'
    );

    // Event handler for when a menu item is focused.
    // When a menu item is focused, it will close all other menus and listen for blur and keydown events.
    const onMenuFocus = (evt) => {
        const target = evt.target;

        // Close all open menus.
        closeOpenSubMenus();

        target.addEventListener('blur', onMenuBlur);
        target.addEventListener('keydown', onMenuKeyPress);
    };

    // Event handler for when a menu item is blurred.
    // When a menu item is blurred, it will close the submenu unless the related target is a submenu link.
    const onMenuBlur = (evt) => {
        const target = evt.target;

        // Don't do anything if it's submenu open.
        if (isSubNavLink(evt)) return;

        target.removeEventListener('blur', onMenuBlur);
        target.removeEventListener('keydown', onMenuKeyPress);

        // Close all open menus.
        closeOpenSubMenus();
    };

    // Check if the related target is a submenu link.
    // Used to prevent closing the submenu when tabbing to a submenu link.
    const isSubNavLink = (evt) => {
        const subnavSelectors = ['.sub-nav', '.my-garage-content', '.my-store-container'];
        return evt.relatedTarget && evt.relatedTarget.closest(subnavSelectors.join(', '));
    };

    // Event handler for when a key is pressed on a menu item.
    // This will handle the enter, escape, and tab keys on the last submenu item.
    // It will also OPEN the submenu when the enter key is pressed.
    // It will also CLOSE the submenu when the escape key is pressed.
    const onMenuKeyPress = (evt) => {
        // will close the submenu and set focus back on the menu if the user presses the escape key
        if (evt.key === 'Escape') {
            evt.preventDefault();
            // Passing true to set focus back to the menu item when closing the submenu with the escape key.
            closeOpenSubMenus(true);
        }

        // If the user presses the enter key, open the submenu.
        if (evt.key === 'Enter') {
            onMenuEnterKey(evt);
        }
    };

    // Event handler for when the enter key is pressed on a menu item.
    // This will open the submenu and prevent the page from navigating.  It will also add tabbing to the submenu elements.
    // It will also close the my store submenu when tabbing off of the last element.
    const onMenuEnterKey = (evt) => {
        const target = evt.target;
        const hasSubnav = target.nextElementSibling && target.nextElementSibling.matches('.sub-nav');

        // If the menu item has a submenu and is not open
        // Then open it and prevent the page from navigating
        if (hasSubnav && !target.classList.contains(IS_OPEN)) {
            evt.preventDefault();

            target.classList.add(IS_OPEN);

            addSubnavTabbing(target);

            onSubMenuOpen(target);
        }
    };

    // Event handler for when a menu item is opened.
    const onSubMenuOpen = (target) => {
        target.setAttribute('aria-expanded', 'true');
    };

    // Event handler for when a menu item is closed.
    const onSubMenuClose = (target) => {
        target.setAttribute('aria-expanded', 'false');
    };

    // Add tabbing to the submenu elements.
    // This will set the tabindex to 0 which will allow the user to tab through the submenu elements and add event listeners for keydown events.
    const addSubnavTabbing = (target) => {
        const subNav = target.parentElement.querySelector('.sub-nav');

        if (subNav) {
            subNav.querySelectorAll(FOCUSABLE_ELEMENTS).forEach((element) => {
                element.tabIndex = 0;
                element.addEventListener('keydown', onMenuKeyPress);
            });
        }
    };

    // Remove tabbing from the submenu elements.
    // This will set the tabindex to -1 which will prevent the user from tabbing through the submenu elements
    // and remove the event listeners for keydown events.
    const removeSubnavTabbing = (target) => {
        const subNav = target.parentElement.querySelector('.sub-nav');

        if (subNav) {
            subNav.querySelectorAll(FOCUSABLE_ELEMENTS).forEach((element) => {
                element.tabIndex = -1;
                element.removeEventListener('keydown', onMenuKeyPress);
            });
        }
    };

    // Close all open menus.
    // This will remove the tabbing from the submenu elements.  It will also close the my garage and my store when submenus.
    // @param {boolean} focusBack - If true, it will set focus back to the menu item when closing the submenu with the escape key.
    const closeOpenSubMenus = (focusBack = false) => {
        menus.forEach((el) => {
            if (el.classList.contains(IS_OPEN)) {
                removeSubnavTabbing(el);

                el.removeEventListener('blur', onMenuBlur);
                el.classList.remove(IS_OPEN);

                onSubMenuClose(el);

                // Set focus back to the menu item when closing the submenu with the escape key.
                if (focusBack) {
                    el.focus();
                }
            }
        });
    };

    // Initialize the primary subnavs.
    menus?.forEach((element) => {
        element.addEventListener('focus', onMenuFocus);
    });
};
