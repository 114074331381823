/**
 * pubsub module
 *
 * @exports pubsub
 */
let pubsub = {};
window.cache = {}; // the topic/subscription hash

/**
 * Publish some data on a named topic.
 * @example
 * Publish stuff on '/some/topic'. Anything subscribed will be called with
 * a function signature like: function(a,b,c){ ... }
 *      publish("/some/topic", ["a","b","c"]);
 *
 * @param  {String} topic The channel to publish on.
 * @param {Array} [args] The data to publish. Each array item is converted
 *                       into an ordered arguments on the subscribed functions.
 * @return {void}
 */
pubsub.publish = function (topic, args) {
    let subs = window.cache[topic];
    let len = subs ? subs.length : 0;

    //can change loop or reverse array if the order matters
    while (len--) {
        subs[len].apply(window, args || []);
    }
};

/**
 * Register a callback on a named topic.
 *
 * @example
 * subscribe("/some/topic", function(a, b, c){});
 *
 * @param  {String}   topic The channel to subscribe to.
 * @param  {Function} callback The handler event. Anytime something is
 *                             published on a subscribed channel, the callback
 *                             will be called with the published array as
 *                             ordered arguments.
 * @return {Array} A handle which can be used to unsubscribe this particular subscription.
 */
pubsub.subscribe = function (topic, callback) {
    if (!window.cache[topic]) {
        window.cache[topic] = [];
    }

    window.cache[topic].push(callback);

    return [topic, callback]; // Array
};

/**
 * Disconnect a subscribed function for a topic.
 *
 * @example
 * var handle = subscribe("/some/topic", function(){});
 * unsubscribe(handle);
 *
 * @param  {Array} handle The return value from a subscribe call.
 * @param  {Function} [callback] Optional callback.
 * @return {[type]}            [description]
 */
pubsub.unsubscribe = function (handle, callback) {
    let subs = window.cache[callback ? handle : handle[0]];
    let len = subs ? subs.length : 0;
    callback = callback || handle[1];

    while (len--) {
        if (subs[len] === callback) {
            subs.splice(len, 1);
        }
    }
};

export { pubsub };
