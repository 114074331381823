import { Provider } from 'react-redux';
import { ReviewsApp } from './ReviewsApp';
import { render } from 'react-dom';
import React from 'react';

const reviewsReactAppLoader = async (store) => {
    const rootDiv = document.createElement('div');

    return render(
        <Provider store={store}>
            <ReviewsApp />
        </Provider>,
        rootDiv
    );
};

export { reviewsReactAppLoader };
