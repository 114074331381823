import { getAvailabilityForMultipleStores } from './getAvailabilityForMultipleStores';
import { showHours } from './showHours';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const nextDates = (evt, rootEl, props) => {
    const {
        previousDatesArrows,
        nextDatesArrow,
        numDays,
        getDateSelectorIndex,
        setDateSelectorIndex,
        datesArr,
        getStoresInfo,
        setStoresInfo,
        addedServicesListArr,
        dateStoreStep,
        completeDateTimeSelection
    } = props;

    const idx = getDateSelectorIndex();
    let currentTarget = evt ? evt.currentTarget : nextDatesArrow;
    const start = numDays * idx;
    const end = numDays * (idx + 1);
    let allDateItems = rootEl.querySelectorAll('.date-items');
    let calledOnce = false;
    allDateItems.forEach((allDateItem) => {
        let dateItems = allDateItem.querySelectorAll('li');
        let serviceDate = null;

        if (idx > 0) {
            previousDatesArrows.forEach((previousDatesArrow) => {
                previousDatesArrow.classList.remove('disable');
            });
        }

        dateItems.forEach((item) => {
            item.classList.add('hide');
        });

        if (start != datesArr.length) {
            Array.from(dateItems)
                .slice(start, end)
                .forEach((item) => {
                    item.classList.remove('hide');
                });

            setDateSelectorIndex(idx + 1);

            if (end == datesArr.length) {
                currentTarget.classList.add('disable');
            }

            dateItems = rootEl.querySelectorAll('.date-items li:not(.hide)');
            const firstWeekDay = Array.from(dateItems).find((li) => getComputedStyle(li).display !== 'none');
            const serviceFetch = firstWeekDay.dataset.serviceFetch || false;

            serviceDate = firstWeekDay.dataset.serviceDate;

            if (!serviceFetch && !calledOnce) {
                [...rootEl.querySelectorAll('.store-hours .wait')].forEach((waitEl) => {
                    waitEl.style.display = '';
                });
                getAvailabilityForMultipleStores(rootEl, {
                    addedServicesListArr,
                    serviceDate,
                    setDateSelectorIndex,
                    numDays,
                    getStoresInfo,
                    setStoresInfo,
                    dateStoreStep,
                    completeDateTimeSelection
                });
                calledOnce = true;
            }
        }

        showHours(rootEl, { numDays });

        setAdobeDataLayer({ event: 'sa.v2.calendarinteraction' });
    });
};

export { nextDates };
