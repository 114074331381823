import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const goToStep = (evt) => {
    evt.preventDefault();

    const currentTarget = evt.currentTarget;
    const stepTo = currentTarget.dataset.stepTo;
    const tracking = currentTarget.dataset.tracking;

    if (tracking) {
        const sdata = { event: tracking?.eventName || 'na', ...tracking?.data };

        setAdobeDataLayer(sdata);
    }

    window.stepsList.goTo = stepTo;
};

export { goToStep };
