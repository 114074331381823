import unionWith from 'lodash-es/unionWith';
import isEqual from 'lodash/isEqual';
import {
    REVIEWS_MERGE_PRODUCTS,
    REVIEWS_MERGE_REVIEWS,
    REVIEWS_SET_REVIEWS,
    SET_REVIEWS_V2,
    SET_SORTBY_REVIEWS_V2,
    UPDATE_REVIEWS_V2,
    SET_RATING_REVIEWS_V2,
    MERGE_REVIEWS_V2,
    REVIEWS_MERGE_PRODUCTS_V2
} from '../actions';

const initialState = {
    nextPageKey: '',
    products: [], // array of both stores and tires. append reviews to each object when reviews are requested.
    PRv2: {
        data: {
            results: [
                {
                    rollup: {
                        average_rating: 0,
                        review_count: 0,
                        rating_histogram: [0, 0, 0, 0, 0],
                        faceoff_negative: undefined,
                        faceoff_positive: undefined
                    },
                    reviews: [],
                    paging: undefined
                }
            ]
        },
        sortBy: '',
        rating: 0
    }
};

const doesIdMatch = (obj, other) => {
    return obj.product_id === other.product_id;
};

const doesIdMatchV2 = (obj, other) => {
    return obj.page_id === other.page_id;
};

const doesReviewIdMatch = (obj, other) => {
    let validate;
    if (obj?.id && other?.id) {
        // power reviews version 1
        validate = obj.id === other.id;
    } else {
        // power reviews version 2
        validate = obj.review_id === other.review_id;
    }
    return validate;
};

const getProductIndex = (state, id) => {
    let index = undefined;

    for (let i = 0; i < state.products.length; i++) {
        if (state.products[i].product_id === id) {
            index = i;
            break;
        }
    }

    return index;
};

function reviewsReducer(state = initialState, action) {
    const { type, payload, id, nextPageKey } = action;
    let newArr, index, product;

    switch (type) {
        case REVIEWS_MERGE_PRODUCTS:
            // overwrite products with matching ids
            newArr = unionWith(payload, state.products, doesIdMatch);
            // console.log('!!! - reviews_merge_products:', {
            //     newArr,
            //     oldArr: state.products
            // });

            return Object.assign({}, state, { products: newArr });

        case REVIEWS_MERGE_PRODUCTS_V2:
            // overwrite products with matching ids
            newArr = unionWith(payload, state.products, doesIdMatchV2);
            // console.log('!!! - reviews_merge_products:', {
            //     newArr,
            //     oldArr: state.products
            // });

            return Object.assign({}, state, { products: newArr });

        case REVIEWS_SET_REVIEWS:
            index = getProductIndex(state, id);

            if (Number.isInteger(index)) {
                product = state.products[index];
                product.reviews = payload;
            } else {
                // product id wasn't found, make a new product
                product = { product_id: id, reviews: payload };
            }

            newArr = unionWith([product], state.products, doesIdMatch);

            // console.log('!!! - reviews_set_reviews:', {
            //     index,
            //     indexIsInt: Number.isInteger(index),
            //     product,
            //     stateProducts: state.products,
            //     newArr
            // });

            return Object.assign({}, state, { products: newArr, nextPageKey });

        case REVIEWS_MERGE_REVIEWS:
            index = getProductIndex(state, id);

            if (Number.isInteger(index)) {
                product = state.products[index];

                product.reviews = unionWith(product.reviews, payload, doesReviewIdMatch);
                // product.reviews = product.reviews.concat(payload);

                newArr = unionWith([product], state.products, doesIdMatch);

                return Object.assign({}, state, {
                    products: newArr,
                    nextPageKey
                });
            }

            return state;

        case SET_REVIEWS_V2:
            return Object.assign({}, state, { ...state, PRv2: { ...payload } });

        case SET_SORTBY_REVIEWS_V2:
            return Object.assign({}, state, { PRv2: { ...state.PRv2, sortBy: payload } });

        case SET_RATING_REVIEWS_V2:
            return Object.assign({}, state, { PRv2: { ...state.PRv2, rating: payload } });

        case UPDATE_REVIEWS_V2:
            return Object.assign({}, state, {
                PRv2: {
                    ...state.PRv2,
                    data: {
                        ...state.PRv2.data,
                        results: [{ ...state.PRv2.data.results[0], reviews: payload.results[0].reviews }],
                        paging: payload.paging
                    }
                }
            });

        case MERGE_REVIEWS_V2:
            newArr = unionWith(state.PRv2.data.results[0].reviews, payload.results[0].reviews, isEqual);
            return Object.assign({}, state, {
                PRv2: {
                    ...state.PRv2,
                    data: {
                        ...state.PRv2.data,
                        results: [{ ...state.PRv2.data.results[0], reviews: newArr }],
                        paging: payload.paging
                    }
                }
            });

        default:
            return state;
    }
}

export default reviewsReducer;
