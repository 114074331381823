import { resetHours } from './resetHours';

const showHours = (rootEl, props) => {
    const { reset, numDays } = props;
    const dateItems = Array.from(rootEl.querySelectorAll('.date-items li'));
    const selector = reset ? ':nth-child(1)' : ':not(.hide):nth-child(1)';
    const startingWeek = dateItems.filter((el) => !el.matches('.hide'))[0] || dateItems[0];
    const hours = Array.from(rootEl.querySelectorAll('.hours'));
    const startIndex = Array.from(startingWeek.parentNode.children).indexOf(startingWeek);

    dateItems.forEach((item) => {
        item.classList.remove('active');
    });
    startingWeek.classList.add('active');

    hours.forEach((item) => {
        let hoursItem = Array.from(item.children).slice(startIndex, startIndex + numDays);
        let noSlots = item.querySelectorAll('.no-slots');

        Array.from(item.children).forEach((el) => {
            el.classList.remove('active');
            el.classList.add('hide');
        });

        hoursItem.forEach((el, idx) => {
            el.classList.remove('hide');
            if (idx === 0) {
                el.classList.add('active');
            }
        });

        noSlots.forEach((el) => {
            let currentSlot = el;
            let nextAvailableSlot = el.nextElementSibling;

            while (nextAvailableSlot && nextAvailableSlot.classList.contains('no-slots')) {
                nextAvailableSlot = nextAvailableSlot.nextElementSibling;
            }

            if (nextAvailableSlot) {
                let dateService = rootEl.querySelector(
                    `[data-service-date="${nextAvailableSlot.dataset.availableDate}"]`
                );
                let nextAvailableTime = nextAvailableSlot.querySelector('li.available').textContent;

                if (dateService) {
                    let nextAvailableDay = dateService.querySelector('.day').textContent;
                    let nextAvailableDate = dateService.querySelector('.date').textContent;

                    currentSlot.querySelector(
                        '.no-slots-message'
                    ).textContent = `Next Available: ${nextAvailableDay}, ${nextAvailableDate} at ${nextAvailableTime}`;
                }
            }
        });
    });

    resetHours(rootEl);
};

export { showHours };
