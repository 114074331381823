import { formatTimeToHrsMins } from 'common/js/util/date-time/formatTime';

// Matcher to compare service names from UI and API
function matchServiceNames(uiText, serviceDesc) {
    return uiText === serviceDesc || (serviceDesc === 'Other' && uiText === "I'm not sure");
}

/**
 * Inserts service duration into the accordion item text.
 * @param {HTMLElement} item The accordion item from list of services
 * @param {object} serviceData The data from API call to /bsro/services/store/services
 * @param {*} isPinnacleStore True if the store is a Pinnacle store
 */
const addServiceDuration = (item, serviceData = { data: [] }, isPinnacleStore) => {
    // adds service duration to each accordion item, moves details copy after service copy

    const durationCopy = item.querySelector('.service-duration');
    if (durationCopy) {
        // already added service duration do nothing.
        return;
    }
    const serviceCopy = item?.querySelector('span');
    if (!serviceCopy) {
        // HTML structure has changed, so we can't find the service copy
        console.error('UNEXPECTED: Service copy not found:', item);
        return;
    }
    const detailsCopy = serviceCopy.querySelector('.details');
    if (detailsCopy) {
        // move the see details link outside of the after the service name
        serviceCopy.after(detailsCopy);
    }

    // grabs service name from accordion and uses the index to get the item in the api response
    // NOTE: if service ID becomes available, that would be a better way to match
    let serviceCopyRoot = serviceCopy.cloneNode(true);
    // remove any child nodes that are not text so that textContent is just the service name
    serviceCopyRoot.childNodes.forEach((node) => {
        if (node.tagName) {
            serviceCopyRoot.removeChild(node);
        }
    });
    const serviceName = serviceCopyRoot.textContent.trim();
    if (Array.isArray(serviceData?.data)) {
        const serviceIndex = serviceData.data.findIndex((obj) => matchServiceNames(serviceName, obj.serviceDesc));
        if (serviceIndex === -1) {
            console.warn('Service not found in service data:', serviceName);
            return;
        }
        const serviceDuration = document.createElement('span');
        const serviceDurationTime = serviceData.data[serviceIndex]?.duration || 0;
        if (serviceDurationTime && isPinnacleStore) {
            serviceDuration.dataset.duration = serviceDurationTime;
            serviceDuration.textContent = '(est. ' + formatTimeToHrsMins(serviceDurationTime) + ')';
        }
        serviceDuration.classList.add('service-duration');
        serviceCopy.dataset.serviceId = serviceData.data[serviceIndex]?.serviceId;
        serviceCopy.appendChild(serviceDuration);
    }
};

export { addServiceDuration };
