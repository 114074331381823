import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
// Radio input
const RadioInput = ({
    field: { name, value, onChange, onBlur },
    form,
    id,
    defaultChecked,
    label,
    labelSVG,
    className,
    setFieldValue,
    onChangeHandler,
    ...props
}) => {
    const handleEnterKeyDown = (e) => {
        if (e.key === 'Enter') {
            document.getElementById(id)?.click();
        }
    };

    return (
        <div tabIndex={0} onKeyDown={handleEnterKeyDown}>
            <input
                name={name}
                id={id}
                type="radio"
                value={value} // could be something else for output?
                defaultChecked={defaultChecked}
                onChange={(e) => {
                    if (onChangeHandler) {
                        onChangeHandler(e, setFieldValue);
                        console.log('radio input - on change handler:', {
                            e,
                            eventTarget: e.target,
                            checked: e.target.checked,
                            setFieldValue
                        });
                    } else if (onChange) {
                        onChange(e);
                        console.log('radio input - on change:', {
                            e
                        });
                    }
                }}
                onBlur={onBlur}
                className={classNames('radio-button', className)}
                {...props}
            />
            <label htmlFor={id}>{labelSVG ? <SVG src={labelSVG} /> : <>{label}</>}</label>
        </div>
    );
};

RadioInput.propTypes = {
    field: PropTypes.object,
    form: PropTypes.object,
    id: PropTypes.string,
    defaultChecked: PropTypes.bool,
    label: PropTypes.string,
    labelSVG: PropTypes.string,
    className: PropTypes.string,
    setFieldValue: PropTypes.string,
    onChangeHandler: PropTypes.func
};

export default RadioInput;
