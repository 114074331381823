import merge from 'lodash-es/merge';
import {
    TSR_ADD_APPLIED_FILTER,
    TSR_DISABLE_FILTER_SORT,
    TSR_MERGE_TIRE_DATA,
    TSR_REMOVE_ALL_APPLIED_FILTERS,
    TSR_REMOVE_APPLIED_FILTER,
    TSR_SET_COMPARE_GROUP,
    TSR_SET_DATA_ATTRIBUTES,
    TSR_SET_FILTERS,
    TSR_SET_INVENTORY,
    TSR_SET_INVENTORY_PRICING,
    TSR_SET_PRICING,
    // TSR_SET_SEARCH_BY_VEHICLE,
    TSR_SET_RESULT_TYPE,
    TSR_SET_SEARCH_TYPE,
    TSR_SET_SORT,
    TSR_SET_TAB,
    TSR_SET_VIEW,
    TSR_SET_LOGGED_IN
} from '../actions';
import { deleteLocalStorageItem, setLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { removeDuplicatesInArray } from 'common/js/util/removeDuplicatesInArray';
import { stringSanitizer } from 'common/js/util/stringSanitizer';
import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';
// import { createArticleKey } from 'common/js/util/articles/createArticleKey';

const initialState = {
    hasInitialized: false, // let's us know when page data-attr have been loaded in
    hasInventoryPricing: false, // let's us know when inventory_pricing has been loaded. can't count results because sometimes we get zero back.
    mobileFilterFlag: false, // has mobile filter been triggered yet?
    tireResults: {},
    vehicle: {},
    tireSize: {},
    articleIds: [],
    articleKey: [],
    matchedSets: [],
    storeNumber: '',
    storePhone: '',
    pricing: [],
    inventory: [],
    tires: [], // this is a selection of used values from tireResults, pricing, and inventory merged together for easier access/usage.
    currentTab: 'all',
    searchType: '',
    resultType: '',
    bestSellerIds: [],
    comparedGroup: [],
    tireComparison: [],
    tabs: {
        all: {
            label: 'All Tires',
            heading: 'Available at a Store Near You',
            displayByStock: true, // split into separate groups by stock if true, else display as a single group
            filters: {
                // may need to add an order applied to filters for mobile
                open: false,
                size: '',
                brands: [],
                price: 999999999,
                rating: 0,
                mileage: [],
                type: []
            },
            sort: '',
            view: 'list',
            appliedFilters: []
        },
        bestSellers: {
            label: 'Best Sellers',
            heading: 'Best Sellers',
            view: 'list'
        },
        highestRated: {
            label: 'Highest Rated',
            heading: 'Highest Rated',
            view: 'list'
        },
        specialOffers: {
            label: 'Special Offers',
            heading: 'Special Offers',
            filters: {
                open: false,
                size: '',
                brands: [],
                price: 999999999,
                rating: -1,
                mileage: [],
                type: []
            },
            sort: '',
            view: 'list',
            appliedFilters: []
        }
    }
    // searchByVehicle: {}
};

let firstLoad = true;

/**
 * TIRES object structure (object with general data, plus array of tires in the set):
 * isMatchSet
 * isLowStock (< 4 for both or < 2 for front/rear each)
 * avgPrice (avg of front and rear tire prices - int)
 * tires[]
 *
 * FRONT/REAR/BOTH object structure
 * tire
 *     article
 *     brand
 *     description
 *     frb
 *     loadIndex
 *     loadIndexPounds
 *     mileage
 *     ratings (from power reviews)
 *         averageRating
 *         reviewCount
 *     speedRating
 *     speedRatingMPH
 *     standardOptional
 *     tireBrandImage
 *     tireBrandName
 *     tireGroupName
 *     tireName
 *     tireSize
 *     tireType
 *     tireVehType
 *     vehType
 *     inventory
 *         q_local
 *         q_sister
 *         quantity
 *     price
 *         currencyIso: "USD"
 *         formattedValue: "$129.99"
 *         value: 129.99
 *     promos
 *     rebates
 *     reviews
 * tireSurveyDetails
 *     dryTraction
 *     noiseLevel
 *     rideComfort
 *     tireStability
 *     tireWear
 *     tractionInSnowIce
 *     wetTraction
 */

/**
 * TIRE RESULTS object structure:
 * pwsMarketing: Array(0)
 * tire
 *     article: "12445"
 *     bestInClass: "false"
 *     brand: "Firestone"
 *     description: "FIREHAWK INDY 500 255/35R18 XL 94W BL"
 *     discontinued: "N"
 *     frb: "R" (front, rear, or both. comes back as 'F', 'R', or 'B')
 *     generateCatalogPage: "Y"
 *     loadIndex: "94"
 *     loadIndexPounds: "1477"
 *     mileage: "N/A"
 *     oemFlag: "N"
 *     sidewallDescription: "Black Letter/Black Wall"
 *     speedRating: "W"
 *     speedRatingMPH: "168"
 *     standardOptional: "S" (is this considered the default "standard" tire size, or is it optional? "S" or "O")
 *     technology: "NONE"
 *     tireBrandImage: "https://bsro.tireconnect.ca/images/brands/fcac-logo-Firehawk.png"
 *     tireBrandName: "Firehawk"
 *     tireClassName: "Ultra High Performance Summer"
 *     tireGroupName: "Performance"
 *     tireName: "Firehawk Indy 500"
 *     tireSegmentName: "Performance Tires"
 *     tireSize: "255/35R18 XL"
 *     tireType: "Summer"
 *     tireVehType: "Performance"
 *     vehType: "PASS"
 *     warrantyName: "Gold Pledge Limited Warranty"
 * tireSurveyDetails:
 *     dryTraction: "9"
 *     noiseLevel: "8.2"
 *     rideComfort: "8.7"
 *     tireStability: "8.5"
 *     tireWear: "8.6"
 *     tractionInSnowIce: "7.5"
 *     wetTraction: "8.5"
 */

/**
 * TIRE SIZE object structure:
 * aspectRatio: "45"
 * crossSection: "245"
 * rimsize: "20"
 */

/**
 * VEHICLE object structure:
 * acesVehicleId: "70847"
 * make: "BMW"
 * model: "328i"
 * submodel: "Base"
 * tpms: "1"
 * tpmsInd: "true"
 * year: "2008"
 */

/**
 * INVENTORY object structure:
 * part_number: "9444" (the article id)
 * q_local: "4" (the quantity at the store)
 * q_sister: "8" (the quantity at near by stores)
 * quantity: "12" (the quantity of both local and sister stores)
 */

/**
 * PRICING object structure:
 * availableForPickup: false
 * baseOptions: Array(0)
 * brgtInventoryAssurance: false
 * brgtInventoryQuantity: 0
 * categories: Array(2)
 *     0: {code: "YZ12D1", url: "/JDA---FT140/c/YZ12D1"}
 *     1: {code: "HANA_Pattern_FT140", url: "/FIRESTONE/FR710/FT140/c/HANA_Pattern_FT140"}
 * code: "6518" (article id)
 * description: ""
 * name: "FT140 205/55R16 TLBLPS91H"
 * numberOfReviews: 0
 * potentialPromotions: Array(0)
 *   brgtEmailOnlyPromotion,
 *   clearanceOnly, (important)
 *   code, (important)
 *   description,
 *   detailsURL,
 *   displayName, (important)
 *   enabled,
 *   endDate,
 *   hidePrice, (important)
 *   onlineOnly,
 *   priority,
 *   promotionAction,
 *   promotionGroup,
 *   promotionType,
 *   promotionValue
 *     currencyIso,
 *     formattedValue,
 *     priceType,
 *     value
 *   restrictions,
 *   salePrice (important)
 *     currencyIso,
 *     formattedValue,
 *     priceType,
 *     value, (important)
 *   startDate,
 *   title
 * price (may not exist. if not, must direct Boss to call store)
 *     currencyIso: "USD"
 *     formattedValue: "$129.99"
 *     priceType: "BUY"
 *     value: 129.99
 * priceRange: {}
 * productReferences: Array(0)
 * purchasable: true
 * rebates: Array(0)
 *   active, (imporatnt)
 *   label, (important)
 *   code, (important)
 *   URL,
 *   endDate, (use active)
 *   startDate, (use active)
 *   minimumQuantity,
 *   priority
 * reviews: Array(0)
 * stock:
 *     stockLevel: 0
 *     stockLevelStatus: "inStock"
 * summary: ""
 * url: "/fcac/products/6518"
 */

const setTireComparisionArray = (tireObject, articleId, tireComparisonArray) => {
    if (Object.entries(tireObject).length > 0) {
        Object.entries(tireObject).forEach((allTireData) => {
            const [key, value] = allTireData;
            if (key === 'tires') {
                const tires = value;
                tires.forEach((tireData) => {
                    if (articleId === tireData.tire.article) {
                        tireData.tire.tireSurveyDetails = tireData.tireSurveyDetails;
                        tireComparisonArray.push(tireData.tire);
                    }
                });
            }
        });
    }

    return tireComparisonArray;
};

function tireSearchResultsReducer(state = initialState, action) {
    const { type, payload, target, callback } = action;

    switch (type) {
        case TSR_SET_COMPARE_GROUP: {
            let tireComparison = [];
            const tiresArray = state.tires;
            //tiresArray array = state.tires -> tireEl Object -> tires Array -> tireData Object -> tire.article
            if (tiresArray.length > 0) {
                tiresArray.forEach((tireEl) => {
                    if (payload.length > 0) {
                        payload.forEach((item) => {
                            const splitedItem = item.split('_'); // if spit-fit tires splited the articleIds by _
                            if (splitedItem.length > 1) {
                                //split-fit tires set will have more than 1 id
                                let compareElements = [];
                                splitedItem.forEach((splitedItemEl) => {
                                    compareElements = setTireComparisionArray(tireEl, splitedItemEl, compareElements);
                                });

                                if (compareElements.length > 1) {
                                    tireComparison.push(compareElements);
                                }
                            } else {
                                // one set of tires will have only one id
                                tireComparison = setTireComparisionArray(tireEl, item, tireComparison);
                            }
                        });
                    }
                });
            }

            tireComparison = removeDuplicatesInArray(tireComparison);

            const day = 24 * 60 * 60 * 1000;
            const expiration = new Date(Date.now() + day);

            // Avoid overwrite with an empty array when the app is loaded
            // data should be saved just when a tire is selected

            if (!firstLoad && tireComparison.length > 0) {
                deleteLocalStorageItem('tireComparison');
                // setLocalStorageItem('tireComparison', tireComparison, expiration);
            }

            firstLoad = false;

            if (tireComparison.length > 0) {
                setLocalStorageItem('tireComparison', tireComparison, expiration);
            }

            return Object.assign({}, state, { comparedGroup: payload });
        }
        case TSR_SET_DATA_ATTRIBUTES: {
            payload.hasInitialized = true;
            return Object.assign({}, state, payload);
        }
        case TSR_SET_INVENTORY_PRICING: {
            let inventory = [];
            let pricing = [];
            let articleIds = [];
            // iterate through articles in payload
            // to generate pricing and inventory arrays
            // also update articles to what is available in store
            for (const [key, value] of Object.entries(payload)) {
                // can't rely on api to provide inventory prop in all cases
                const tireInventory = value?.inventory || {
                    quantity: '0',
                    part_number: key,
                    q_sister: '0',
                    q_local: '0'
                };
                // there may be cases where tire pricing isn't provided
                const tirePrice = value?.pricing || {
                    availableForPickup: false,
                    baseOptions: [],
                    brgtInventoryAssurance: false,
                    brgtInventoryQuantity: 0,
                    categories: [],
                    code: key,
                    description: '',
                    name: '',
                    numberOfReviews: 0,
                    potentialPromotions: [],
                    // price: {
                    //     currencyIso: 'USD',
                    //     formattedValue: 'No Price Available',
                    //     priceType: 'BUY',
                    //     value: 0
                    // },
                    priceRange: {},
                    productReferences: [],
                    purchasable: true,
                    rebates: [],
                    reviews: [],
                    stock: {
                        stockLevel: 0,
                        stockLevelStatus: 'inStock'
                    },
                    summary: '',
                    url: ''
                };

                if (tireInventory) inventory.push(tireInventory);
                if (tirePrice) pricing.push(tirePrice);
                if (key) articleIds.push(key);
            }

            // check if all articles in matched sets are still available. otherwise remove.
            let matchedSets = state.matchedSets.filter((item) => {
                let pass = true;
                const matchedArticles = item.split('_');
                matchedArticles.map((article) => {
                    if (!articleIds.includes(article)) {
                        pass = false;
                    }
                });
                return pass;
            });

            let articleKey = matchedSets.concat(articleIds);

            console.log('TSR_SET_DATA_ATTRIBUTES', {
                payload,
                inventory,
                pricing,
                articleIds,
                matchedSets,
                articleKey
            });

            callback(articleKey.length || '');

            return Object.assign({}, state, {
                hasInventoryPricing: true,
                inventory,
                pricing,
                articleIds,
                matchedSets,
                articleKey
            });
        }
        case TSR_SET_PRICING:
            // legacy: only using as a backup option
            console.log('TSR_SET_PRICING');
            return Object.assign({}, state, { pricing: payload });
        case TSR_SET_INVENTORY:
            // legacy: only using as a backup option
            console.log('TSR_SET_INVENTORY');
            return Object.assign({}, state, { inventory: payload });
        // case TSR_SET_SEARCH_BY_VEHICLE: {
        // console.log('TSR_SET_SEARCH_BY_VEHICLE', {
        //     payload,
        //     filters: payload?.filters,
        //     bestSellers: payload?.filters?.bestSellers
        // });
        // convert matched sets to article key
        // const allArticles = payload?.allArticles || [];
        // const matchedSetArticles = payload?.matchedSetArticles || [];
        // const articleKey = createArticleKey(allArticles, matchedSetArticles);
        // const bestSellerIds = payload?.filters?.bestSellers || [];
        // return Object.assign({}, state, { articleKey, bestSellerIds, searchByVehicle: payload });
        // }
        case TSR_SET_TAB:
            return Object.assign({}, state, { currentTab: payload });
        case TSR_SET_SEARCH_TYPE:
            return Object.assign({}, state, { searchType: payload });
        case TSR_SET_RESULT_TYPE:
            return Object.assign({}, state, { resultType: payload });
        case TSR_SET_FILTERS:
            if (target === 'all' || target === 'specialOffers') {
                let tabs = Object.assign({}, state.tabs);
                tabs[target].filters = Object.assign({}, tabs[target].filters, payload);
                return Object.assign({}, state, { tabs, mobileFilterFlag: true });
            } else return state;
        case TSR_SET_SORT:
            if (target === 'all' || target === 'specialOffers') {
                let tabs = {};
                tabs[target] = { sort: payload };
                return merge({}, state, { tabs });
            } else return state;
        case TSR_SET_VIEW:
            if (target && (payload === 'list' || payload === 'grid')) {
                let tabs = {};
                tabs[target] = { view: payload };
                return merge({}, state, { tabs });
            } else return state;
        case TSR_SET_LOGGED_IN:
            return merge({}, state, { isLoggedIn: payload });
        case TSR_MERGE_TIRE_DATA: {
            console.log('TSR_MERGE_TIRE_DATA');
            // merges pricing and inventory data into the tireResults object
            // then converts it into an array of matched sets
            // based on the article key
            let reviewsProducts = payload || [];
            let tr = Object.assign({}, state.tireResults);
            let pricing = state?.pricing ? state.pricing.concat() : [];
            let inventory = state.inventory.concat();

            // merge ratings into tires
            // note: not all articles will return rating

            reviewsProducts.forEach((r) => {
                if (!r) return;
                for (const [key, value] of Object.entries(tr)) {
                    const brand = value.tire?.brand || '';
                    const tireName = value.tire?.tireName || '';
                    const id = stringSanitizer(`${brand}${tireName}`, 'upper');

                    if (id === (tireDetailPageSwitch() ? r.page_id : r.product_id)) {
                        const ratingsData = !tireDetailPageSwitch() ? r : r.rollup;
                        if (ratingsData?.average_rating) {
                            tr[key].tire.ratings = {
                                averageRating: Number(ratingsData.average_rating),
                                reviewCount: ratingsData.review_count || 0
                            };
                        }
                    }
                }
            });

            // merge pricing into tires
            // note: not all articles will return pricing
            pricing.map((p) => {
                const { code, price = null, potentialPromotions = null, rebates = null } = p;
                if (tr[code]?.tire) {
                    if (price) {
                        tr[code].tire.price = price;
                    }
                    tr[code].tire.promos = potentialPromotions || [];
                    tr[code].tire.rebates = rebates || [];
                }
            });

            // merge inventory into tires
            // note: not all articles will return inventory
            inventory.map((i) => {
                const { part_number, q_local = null, q_sister = null, quantity = null } = i;
                if (tr[part_number]?.tire) {
                    tr[part_number].tire.inventory = { q_local, q_sister, quantity };
                }
            });

            // Map the tire data based on the article key if it's populated, else article ids
            const arr = state.articleKey.length > 0 ? state.articleKey.concat() : state.articleIds.concat();

            const tires = arr
                .map((item) => {
                    const articles = item.split('_');
                    let data = {};

                    if (articles.length === 1) {
                        const both = tr[articles[0]];
                        data = {
                            isMatchSet: true,
                            isLowStock: !(
                                both?.tire?.inventory?.quantity && parseInt(both.tire.inventory.quantity) >= 4
                            ),
                            avgPrice: both?.tire?.price?.value || null,
                            tires: [both]
                        };
                    } else if (articles.length === 2) {
                        const front = tr[articles[0]];
                        const rear = tr[articles[1]];
                        data = {
                            isMatchSet: false,
                            isLowStock: !(
                                front?.tire?.inventory?.quantity &&
                                rear?.tire?.inventory?.quantity &&
                                parseInt(front.tire.inventory.quantity) >= 2 &&
                                parseInt(rear.tire.inventory.quantity) >= 2
                            ),
                            avgPrice:
                                (front?.tire?.price?.value &&
                                    rear?.tire?.price?.value &&
                                    Math.round((front.tire.price.value + rear.tire.price.value) * 100) / 100) ||
                                null,
                            tires: [front, rear]
                        };
                    }

                    return data;
                })
                .filter((item) => {
                    if (!item.tires || !item.tires[0] || !item.tires[0].tire) {
                        console.error(
                            'Null value encountered in tire array. Data consistency issue between inventory and tire catalog'
                        );
                        return false;
                    } else {
                        return true;
                    }
                });

            // convert object to array
            // const tires = Object.values(tr);

            return Object.assign({}, state, { tires });
        }
        case TSR_ADD_APPLIED_FILTER: {
            if (target === 'all' || target === 'specialOffers') {
                let tabs = Object.assign({}, state.tabs);
                // If is input type radio, remove exisiting options before add new one
                if (payload.type === 'rating' || payload.type === 'price') {
                    tabs[target].appliedFilters = tabs[target].appliedFilters.filter(
                        (item) => item.type !== payload.type
                    );
                }
                tabs[target] = { appliedFilters: [...state.tabs[target].appliedFilters, payload] };
                return merge({}, state, { tabs });
            } else return state;
        }
        case TSR_REMOVE_APPLIED_FILTER: {
            if (target === 'all' || target === 'specialOffers') {
                let tabs = Object.assign({}, state.tabs);
                tabs[target].appliedFilters = tabs[target].appliedFilters.filter((item) => item.id !== payload.id);
                return Object.assign({}, state, { tabs });
            } else return state;
        }
        case TSR_DISABLE_FILTER_SORT: {
            let tabs = Object.assign({}, state.tabs);
            tabs[target].filters = null;
            tabs[target].sort = null;
            tabs[target].appliedFilters = null;
            return Object.assign({}, state, { tabs });
        }
        // This reducer is only for remove all the applied filters at the same time.
        // Doesn't receive payload
        case TSR_REMOVE_ALL_APPLIED_FILTERS: {
            if (target === 'all' || target === 'specialOffers') {
                let tabs = Object.assign({}, state.tabs);
                tabs[target].appliedFilters = [];
                return Object.assign({}, state, { tabs });
            } else return state;
        }
        default:
            return state;
    }
}

export default tireSearchResultsReducer;
