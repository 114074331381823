import '../scss/topnav.scss';
import { component } from 'common/js/library/component';
import {
    getLocalStorageItem,
    setLocalStorageItem,
    deleteLocalStorageItem
} from 'common/js/data/localstorage/localStorageService';
import { isUserLoggedIn } from 'common/js/data/services/account/user';
import { setZipStoreHtml } from 'common/js/util/geolocation/setZipStoreHtml';
import { setStoreByStoreNumber, saveStoreService } from 'common/js/data/services/store-location';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';

const headerAnimationInDelay = 1500;

const instanceInit = (rootEl) => {
    if (!rootEl) return;

    if (!rootEl.classList.contains('nav-ready')) {
        setTimeout(() => {
            rootEl.classList.add('nav-ready');
        }, headerAnimationInDelay);
    }

    window.onload = () => {
        const storeData = getLocalStorageItem('storeData');
        const ecomCartIcon = document.querySelector('.ecomm-cart-icon');
        if (storeData?.eCommActiveFlag === '1') {
            ecomCartIcon ? (ecomCartIcon.style.display = 'flex') : 'none';
        }
    };

    setLocalStorePage();
};

const setLocalStorePage = async () => {
    const isStoreSpecific =
        document?.body?.classList?.contains('local-seo-page') && document?.body?.classList?.contains('store');
    const isLoggedIn = await isUserLoggedIn();
    if (isStoreSpecific && !isLoggedIn) {
        //If it's a Store Page gets storeNumber from Store Page specific
        deleteLocalStorageItem('storeData');
        const storeDetailsElement = document.getElementById('local-pages-store-details');
        const storePageNumber = storeDetailsElement?.dataset.storeNumber;
        if (storePageNumber) {
            setStoreByStoreNumber(storePageNumber).then(async (store) => {
                if (store.status === '200' && store.success === 'true') {
                    const savedStore = await saveStoreService(store.data?.zip?.substr(0, 5), store.data?.storeNumber);
                    if (savedStore?.status === '200' && savedStore?.success === 'true') {
                        setLocalStorageItem('storeData', savedStore);
                        setLocalStorageItem('hasPreferredStore', true);
                        window.siteProfile.location.storeNumber = store.data?.storeNumber;
                        window.siteProfile.location.myZip = store?.data?.zip?.substr(0, 5);
                        pubsub.publish(topics.MY_ZIP_SET, [store?.data?.zip?.substr(0, 5)]);
                        await setZipStoreHtml(store?.data?.zip?.substr(0, 5), store.data?.storeNumber, true);
                    }
                }
            });
        }
    }
};

component({
    selector: 'nav#top-nav.top-nav',
    instanceInit,
    name: 'TopNavV1'
});
