import { pubsub } from 'common/js/library/pubsub';

/**
 *
 * @param {object} parent - parent reference
 * @param {element} interrupt - the interrupt element
 * @param {element} container - custom module containing element
 * @param {element} button - submit button
 * @param {function} closeCallback - triggers close interrupt
 */
let emailSignupCoupons = (parent, interrupt, container, buttons, closeCallback) => {
    let ref = {
        idleId: 'idleId',
        parent,
        interrupt,
        container,
        buttons,
        closeCallback,
        hasShown: false
    };
    // receive what pages to include or exclude from event listeners

    const el = container?.querySelector('.interrupt-email-signup');
    const emailInput = document.getElementById('email-signup-coupons-email-input');

    // Annex the CTA button and use as a submit button
    // using mouseup instead of click because other scripts are stopping propogation before it can be triggered here
    buttons.map((btn) => {
        btn.addEventListener('mouseup', (e) => {
            redirect(ref, e, emailInput.value);
        });
        btn.addEventListener('touchend', (e) => {
            redirect(ref, e, emailInput.value);
        });
    });

    ref.eventHandler = () => {
        if (!ref.hasShown) {
            removeIdleTimeout(ref);
            setIdleTimeout(ref);
        }
    };

    ref.printHandler = () => {
        showInterrupt(ref);
    };

    ref.smsHandler = () => {
        showInterrupt(ref);
    };

    // hide the interrupt, but set the customModule display to show for when interrupt is shown
    hideInterrupt(ref);
    el.classList.remove('hide');

    setIdleTimeout(ref);
    addEventListeners(ref);
};

const showInterrupt = (ref) => {
    console.log('interrupt - email signup', ref);
    ref.interrupt && ref.interrupt.classList.remove('hide');
    removeEventListeners(ref);
};

const hideInterrupt = (ref) => {
    ref.interrupt && ref.interrupt.classList.add('hide');
};

const addEventListeners = (ref) => {
    ref.hasShown = false;
    window.addEventListener('scroll', ref.eventHandler);
    window.addEventListener('click', ref.eventHandler);
    window.addEventListener('mousemove', ref.eventHandler);
    window.addEventListener('afterprint', ref.printHandler);
    pubsub.subscribe('sms-message-sent', ref.smsHandler);
};

const removeEventListeners = (ref) => {
    ref.hasShown = true;
    window.removeEventListener('scroll', ref.eventHandler);
    window.removeEventListener('click', ref.eventHandler);
    window.removeEventListener('mousemove', ref.eventHandler);
    window.removeEventListener('afterprint', ref.printHandler);
    pubsub.unsubscribe('sms-message-sent', ref.smsHandler);
};

const setIdleTimeout = (ref) => {
    ref.idleId = window.setTimeout(showInterrupt, 60 * 1000, ref);
};

const removeIdleTimeout = (ref) => {
    window.clearTimeout(ref.idleId);
};

const redirect = (ref, e, value) => {
    e.preventDefault();
    hideInterrupt(ref);
    ref.closeCallback(ref.parent); // also triggers cookie
    window.location.href = '/email-signup/?email=' + value;
};

export { emailSignupCoupons };
