/**
 * removes all special characters and spaces
 * @param {string} str - string to modify
 * @param {string} strCase - case desired in output
 * @param {string} replacement
 */

const stringSanitizer = (str, strCase = null, replacement = '') => {
    if (!str) return str;

    let sanitizedStr = str.replace(/[\W_]+/g, replacement); //str.replace(/ +/g, '');

    switch (strCase) {
        case 'upper':
            sanitizedStr = sanitizedStr.toUpperCase();
            break;
        case 'lower':
            sanitizedStr = sanitizedStr.toLowerCase();
            break;
        default:
            sanitizedStr = sanitizedStr.toLowerCase();
            break;
    }
    return sanitizedStr;
};

export { stringSanitizer };
