import { getStoreApi, sendDataApi } from 'common/js/data/services/contact-form/contactFormData';
import { component } from 'common/js/library/component';
import { serializeForm, validateElement } from 'common/js/library/util';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { getEcid } from 'common/js/library/get-ecid';

let phoneNumberEl;
let contactFormEl;
let chatEls;
let chatContactEl;
let storeNumberEl;
let feedbackIdEl;
let rebateInquiryEl;
let emailErrorEl;
let zipErrorEl;
let formViewEl;
let confirmationViewEl;
let chatSessionEl;
let signupOptinEl;
let cityOtherEl;
let stateOtherEl;
let zipOtherEl;
const init = (config) => {
    phoneNumberEl = document.querySelectorAll('.phonenumber');
    contactFormEl = document.querySelector('#contact-form');
    chatEls = document.querySelectorAll('.chat');
    storeNumberEl = document.querySelector('input[name=storeNumber]');
    feedbackIdEl = document.querySelector('[name=feedbackId]');
    rebateInquiryEl = document.querySelector('.rebate-inquiry');
    emailErrorEl = document.querySelector('.emailError');
    zipErrorEl = document.querySelector('.zipError');
    formViewEl = document.querySelector('.form-view');
    confirmationViewEl = document.querySelector('.confirmation-view');
    chatSessionEl = document.querySelector('.chat-session');
    chatContactEl = document.querySelector('#chat-contact');
    signupOptinEl = document.querySelector('#signup-optin');
    cityOtherEl = document.querySelector('.city-other');
    stateOtherEl = document.querySelector('.state-other');
    zipOtherEl = document.querySelector('.zip-other');
};

const instanceInit = (rootEl) => {
    let selCategory = rootEl.querySelector('select[name=category]');
    let selInquiry = rootEl.querySelector('select[name=inquiry]');
    let submitBtn = rootEl.querySelector('.contact-btn');

    let category = null;
    let currentClass = rootEl.getAttribute('class');

    const setCurrentClass = (value) => {
        currentClass = value;
    };

    const getCurrentClass = () => {
        return currentClass;
    };

    phoneNumberEl.forEach((phoneNumber) => {
        phoneNumber.setAttribute('maxlength', 12);

        phoneNumber.addEventListener('keyup', (evt) => {
            let _val = evt.currentTarget.value;
            _val = _val.replace(/\D/g, '');

            if (_val.length > 10) _val = _val.slice(0, 10);
            const phoneRegExp = /^(\d{3})(\d{3})(\d{4})$/;
            if (phoneRegExp.test(_val)) {
                const match = _val.match(phoneRegExp);
                evt.currentTarget.value = `${match[1]}-${match[2]}-${match[3]}`;
            } else {
                evt.currentTarget.value = _val;
            }
        });
    });

    selCategory.addEventListener('change', (evt) => {
        let option = [...selCategory.children].filter((child) => {
            return child.selected;
        });

        option = option.length ? option[0] : option;

        category = option.innerText;

        let inquiryAttr = selInquiry.getAttribute('data-inquiry');
        let matchAttr = selInquiry.getAttribute('data-match');

        let inquiries = JSON.parse(inquiryAttr);
        let matches = JSON.parse(matchAttr);

        let _id = option.value;

        removeLastClass(rootEl);
        addClass(rootEl, option.dataset.formFields, setCurrentClass);

        if (option.index > 0) {
            [...selInquiry.children].forEach((child) => {
                child.index > 0 && child.remove();
            });

            var _obj = matches[_id].toString();
            var subs = {};

            subs = _obj.split(',');

            var _olen = subs.length;

            for (var k = 0; k < _olen; k++) {
                selInquiry.insertAdjacentHTML(
                    'beforeend',
                    '<option value=' + subs[k] + '>' + inquiries[subs[k]] + '</option>'
                );

                if (_olen == 1) {
                    selInquiry.selectedIndex = 1;
                    feedbackIdEl.value = selInquiry.value;
                }
            }
        }

        while (emailErrorEl.firstChild) {
            emailErrorEl.removeChild(emailErrorEl.firstChild);
        }

        while (zipErrorEl.firstChild) {
            zipErrorEl.removeChild(zipErrorEl.firstChild);
        }
    });

    selInquiry.addEventListener('change', (evt) => {
        let option = [...selInquiry.children].filter((child) => {
            return child.selected;
        });

        option = option.length ? option[0] : option;
        let inquiry = option.innerText;
        feedbackIdEl.value = option.value;

        if (category == 'Other Comments and Questions') {
            if (inquiry.toLowerCase().indexOf('rebate') != -1) {
                removeLastClass(rootEl);
                addClass(rootEl, 'rebate-inquiry', setCurrentClass);
            } else {
                if (rebateInquiryEl) {
                    rebateInquiryEl.classList.value = '';
                    rebateInquiryEl.classList.add('contact-fields-simple');
                }
                let lastClass = removeLastClass(rootEl);
                addClass(rootEl, lastClass, setCurrentClass);
            }
        }
        if (category == 'Fleet Care / National Accounts') {
            if (inquiry == 'Customer Service Comments') {
                removeLastClass(rootEl);
                contactFormEl.classList.add('contact-fields-fleet-care-services');
            } else {
                removeLastClass(rootEl);
                contactFormEl.classList.add('contact-fields-fleet-care');
            }
        }
    });

    const props = { selInquiry, selCategory, getCurrentClass, submitBtn };
    [...rootEl.querySelectorAll('input, select, textarea')].forEach((field) => {
        field.addEventListener('change', (evt) => handleFieldChange(evt, rootEl, props, category));
        field.addEventListener('blur', (evt) => handleFieldChange(evt, rootEl, props, category));
    });

    [...rootEl.querySelectorAll('input, textarea')].forEach((field) => {
        field.addEventListener('keyup', (evt) => handleFieldChange(evt, rootEl, props, category));
    });

    submitBtn.addEventListener('click', (evt) => handleSubmit(evt, rootEl, props, category));

    if (window.siteProfile) {
        storeNumberEl.value = window.siteProfile.location.storeNumber;
    }

    window.addEventListener('load', (evt) => componentInit(evt, selCategory));

    [...chatEls].forEach((chatEl) => {
        chatEl.addEventListener('click', () => {
            window.adobeDataLayer.push({
                event: 'cta_click',
                cta_type: 'chat',
                cta_text: 'Chat Now',
                cta_location: 'Contact Us'
            });
            chatSessionEl.innerHTML =
                '<iframe src="//home-c14.incontact.com/inContact/ChatClient/ChatClient.aspx?poc=64f24f4a-6a69-4d26-8736-02c5bd2b425c&bu=4597628&P0=FirstName&P1=Last%20Name&P2=first.last%40company.com&P3=555-555-5555"></iframe>';
        });
    });

    document.addEventListener('click', (evt) => {
        if (window.getComputedStyle(chatContactEl).display === 'none') {
            let chatSessionIframeEl = document.querySelector('.chat-session iframe');
            chatSessionIframeEl?.remove();
        }
    });
};

const componentInit = (evt, selCategory) => {
    console.log('inside contact-form.js');
    let categoryAttr = selCategory.getAttribute('data-category');
    let categories = JSON.parse(categoryAttr);
    let formFieldClass = 'contact-fields-simple';
    let paramHash = window.location.search.substr(1) || window.location.hash;

    for (var key in categories) {
        if (key == '1') {
            formFieldClass = 'contact-fields-customer-service';
        } else if (key == '4') {
            formFieldClass = 'contact-fields-maintenance-repair';
        } else if (key == '5') {
            formFieldClass = 'contact-fields-vehicle-lookup';
        } else if (key == '9') {
            formFieldClass = 'contact-fields-fleet-care';
        } else {
            formFieldClass = 'contact-fields-simple';
        }
        if (Object.prototype.hasOwnProperty.call(categories, key)) {
            selCategory.insertAdjacentHTML(
                'beforeend',
                '<option value=' + key + ' data-form-fields=' + formFieldClass + '>' + categories[key] + '</option>'
            );
        }
    }

    if (/\bchat\b/.test(paramHash)) {
        chatEls[0] && chatEls[0].click();
    }
};

const handleFieldChange = (evt, rootEl, props, category) => {
    const { getCurrentClass, submitBtn } = props;
    let data = getFormValues(rootEl, props, category);
    let disabled = true;

    if (validate(data, getCurrentClass)) {
        disabled = false;
    }

    submitBtn.disabled = disabled;
};

const handleSubmit = async (evt, rootEl, props, category) => {
    const { getCurrentClass, submitBtn } = props;
    let data = getFormValues(rootEl, props, category);
    let _email = data.email.trim();

    let emailValidRes = validateElement(_email, 'email');
    let zipValidRes = validateElement(data.zip, 'zipcode');
    if (validate(data, getCurrentClass) && emailValidRes && zipValidRes) {
        /**
         * if store not set by user (default store)
         * get store using contact form zip input
         */
        if (data.zip?.trim()) {
            // If user entered a zip code, prioritize it
            const storeNumber = await getStore(data.zip.trim());
            data.storeNumber = storeNumber;
        } else if (window.siteProfile.location.locationLvl < 2 && data['store-list'].length == 0) {
            // Fallback to window.siteProfile zip if no user input
            const storeNumber = await getStore(window.siteProfile.location.zip);
            data.storeNumber = storeNumber;
        }
        sendData(submitBtn, data);
    } else {
        if (!emailValidRes) {
            setAdobeDataLayer({
                event: 'forms.user_generated_error',
                form_name: 'contact us',
                step_name: 'Contact Us',
                field_name: 'Email ID',
                error_message: 'Invalid Email ID'
            });
        }

        if (!zipValidRes) {
            setAdobeDataLayer({
                event: 'forms.user_generated_error',
                form_name: 'contact us',
                step_name: 'Contact Us',
                field_name: 'Zip Code',
                error_message: 'Invalid Zip Code'
            });
        }
        console.log('ContactForm: invalid data');
    }
};

const getFormValues = (rootEl, props, category) => {
    return serializeToObject(rootEl, props, category);
};

const validate = (data, getCurrentClass) => {
    let currentSet = getCurrentClass();

    if (currentSet === 'contact-fields-customer-service') {
        if (
            data.category !== '' &&
            data.inquiry.length !== 0 &&
            data.firstName !== '' &&
            data.lastName !== '' &&
            data.email !== '' &&
            data.state.length !== 0 &&
            data.city.length !== 0 &&
            data['store-list'].length !== 0 &&
            data.message !== ''
        ) {
            return true;
        }
    } else if (currentSet === 'contact-fields-simple') {
        if (
            data.category !== '' &&
            data.inquiry.length !== 0 &&
            data.firstName !== '' &&
            data.lastName !== '' &&
            data.email !== '' &&
            data.zip !== '' &&
            data.message !== ''
        ) {
            return true;
        }
    } else if (currentSet === 'contact-fields-maintenance-repair') {
        if (
            data.category !== '' &&
            data.inquiry.length !== 0 &&
            data.firstName !== '' &&
            data.lastName !== '' &&
            data.email !== '' &&
            data.zip !== '' &&
            data.message !== ''
        ) {
            return true;
        }
    } else if (currentSet === 'contact-fields-vehicle-lookup') {
        if (
            data.category !== '' &&
            data.inquiry.length !== 0 &&
            data.firstName !== '' &&
            data.lastName !== '' &&
            data.email !== '' &&
            data.year.length !== 0 &&
            data.make.length !== 0 &&
            data.model.length !== 0 &&
            data.submodel.length !== 0 &&
            data.zip !== '' &&
            data.message !== ''
        ) {
            return true;
        }
    } else if (currentSet === 'contact-fields-fleet-care') {
        if (
            data.category !== '' &&
            data.inquiry.length !== 0 &&
            data.firstName !== '' &&
            data.lastName !== '' &&
            data.email !== '' &&
            data.address !== '' &&
            data.zip !== '' &&
            data.state !== '' &&
            data.message !== '' &&
            data.city !== ''
        ) {
            return true;
        }
    } else if (currentSet === 'rebate-inquiry') {
        if (
            data.category !== '' &&
            data.inquiry.length !== 0 &&
            data.firstName !== '' &&
            data.lastName !== '' &&
            data.email !== '' &&
            data.zip !== '' &&
            data.state.length !== '' &&
            data.zip !== '' &&
            data.address !== '' &&
            data.city !== '' &&
            data.message !== ''
        ) {
            return true;
        }
    }

    return false;
};

const getStore = async (zip) => {
    try {
        const response = await getStoreApi({ zipCode: zip });

        // ensure result is valid before proceeding
        if (response.success !== 'true' || parseInt(response.status) !== 200) {
            return;
        }

        const data = response.data;
        if (parseInt(response.status) === 200) {
            return data.stores[0].storeNumber;
        }
    } catch (err) {
        console.log('No stores found for zip: ' + zip);
    }
};

const sendData = async (submitBtn, dataPackage) => {
    submitBtn.disabled = true;
    dataPackage.visitorID = getEcid();

    try {
        const response = await sendDataApi(dataPackage);

        // ensure result is valid before proceeding
        if (response.success !== 'true' || parseInt(response.status) !== 200) {
            return;
        }

        const data = response.data;
        formViewEl.style.display = 'none';
        confirmationViewEl.style.display = 'block';

        const adobeDataLayer = {
            event: 'contact.form_submit',
            category: dataPackage.category,
            email: dataPackage.email
        };
        const phone = dataPackage.mobilePhone || dataPackage.dayPhone || dataPackage.eveningPhone;
        if (phone) {
            adobeDataLayer.phone = phone;
        }
        setAdobeDataLayer(adobeDataLayer);
    } catch (err) {
        submitBtn.disabled = false;
    }
};

const removeLastClass = (rootEl) => {
    let lastClass = rootEl.getAttribute('class').split(' ').pop();

    if (lastClass.length > 0) {
        rootEl.classList.remove(lastClass);
    }
    return lastClass;
};

const addClass = (rootEl, val, setCurrentClass) => {
    rootEl.classList && rootEl.classList.add(val);
    setCurrentClass(val);
};

const serializeToObject = (rootEl, props, category) => {
    const { selInquiry, selCategory } = props;
    let _s_obj = serializeForm(rootEl);

    let selectedOption = [...selInquiry.children].filter((child) => {
        return child.selected;
    });

    let inquiry = selectedOption.length ? selectedOption[0].innerText : selectedOption.innerText;

    if (
        selCategory.value == '6' &&
        (inquiry.toLowerCase().indexOf('service') != -1 || inquiry.toLowerCase().indexOf('tire') != -1)
    ) {
        _s_obj[cityOtherEl.getAttribute('name')] = cityOtherEl.value;
        _s_obj[stateOtherEl.getAttribute('name')] = stateOtherEl.value;
        _s_obj[zipOtherEl.getAttribute('name')] = zipOtherEl.value;
    }

    if (selCategory.value == '4') {
        _s_obj[zipOtherEl.getAttribute('name')] = zipOtherEl.value;
    }

    if (selCategory.value == '9') {
        _s_obj[zipOtherEl.getAttribute('name')] = zipOtherEl.value;
        _s_obj['userAgent'] = navigator.userAgent;

        if (selInquiry.value != '44') {
            _s_obj[cityOtherEl.getAttribute('name')] = cityOtherEl.value;
            _s_obj[stateOtherEl.getAttribute('name')] = stateOtherEl.value;
        }
    }
    delete _s_obj['signup-optin'];
    _s_obj['optin'] = signupOptinEl.checked ? 'Yes' : 'No';
    _s_obj['category'] = category;

    return _s_obj;
};

component({
    selector: '[data-contact-form]',
    init,
    instanceInit,
    name: 'ContactForm'
});
