import { getCache, setCache } from 'common/js/util/sessionCache';
import { queryStringify, HTTPHeaders } from 'common/js/data/services/serviceUtilities';

export const fetchGetOptions = () => {
    return {
        cache: 'no-cache',
        credentials: 'include',
        headers: HTTPHeaders(),
        method: 'GET'
    };
};

export const setStoreByZip = async (zip, basePath = '') => {
    try {
        const url = `${basePath}/bsro/services/store/set-store-by-zip?zipCode=${zip}`;

        const response = await fetch(url, fetchGetOptions());
        const responseJson = await response.json();

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const setStoreByStoreNumber = async (storeNumber, basePath = '') => {
    try {
        const url = `${basePath}/bsro/services/store/set-store-by-store-number?storeNumber=${storeNumber}`;

        const response = await fetch(url, fetchGetOptions());
        const responseJson = await response.json();

        return responseJson;
    } catch (err) {
        console.log("there's been an error in the code: ", { err });
        return { error: err, success: 'false' };
    }
};

export const saveStoreService = async (zip, storeId, basePath = '') => {
    try {
        const saveStoreObjParam = { zipCode: zip, storeNumber: storeId };

        const url = `${basePath}/bsro/services/store/save-mystore${queryStringify(saveStoreObjParam)}`;

        const response = await fetch(url, fetchGetOptions());
        const responseJson = await response.json();
        return responseJson;
    } catch (err) {
        console.log("there's been an error in the code: ", { err });
        return { error: err, success: 'false' };
    }
};

/**
 *
 * @param {string} lat
 * @param {string} long
 * @param {number} storeCount
 * @param {string} siteName - if a brand agnostic value is needed, use 'BSRO'
 * @returns
 */
export const getStoreLocationListByLatLong = async (lat, long, storeCount = 1, siteName = null, basePath = '') => {
    if (!siteName && window.bsroOmniStoreFinder) {
        siteName = 'BSRO';
    }
    try {
        let url = `${basePath}/bsro/services/store/location/get-list-by-lat-lng?latitude=${lat}&longitude=${long}&storeCount=${storeCount}`;

        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        if (siteName) {
            url += `&siteName=${siteName}`;
        }
        const response = await fetch(url, fetchGetOptions());
        const responseJson = await response.json();

        setCache(url, responseJson && responseJson.data && responseJson.data.stores);

        return (responseJson && responseJson.data && responseJson.data.stores) || null;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getStoreLocationsListByZip = async (zip, options = {}, basePath = '') => {
    let { radius = null, siteName = null } = options;
    if (!siteName && window.bsroOmniStoreFinder) {
        siteName = 'BSRO';
    }
    if (zip !== '') {
        try {
            let url = `${basePath}/bsro/services/store/location/get-list-by-zip?zipCode=${zip}`;
            if (siteName) {
                url += `&siteName=${siteName}`;
            }
            if (radius) {
                url += `&radius=${radius}`;
            }

            const cachedData = getCache(url);

            if (cachedData) return cachedData;

            const response = await fetch(url, fetchGetOptions());
            const responseJson = await response.json();

            setCache(url, responseJson);

            return responseJson;
        } catch (err) {
            return { error: err, success: 'false' };
        }
    }
};

export const getStoreLocationsByCityState = async ({ city, state, siteName = null }) => {
    if (!siteName && window.bsroOmniStoreFinder) {
        siteName = 'BSRO';
    }
    try {
        let url = `/bsro/services/store/location/get-list-state-city?city=${city}&state=${state}`;

        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        if (siteName) {
            url += `&siteName=${siteName}`;
        }
        const response = await fetch(url, fetchGetOptions());
        const responseJson = await response.json();

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getStoreByStoreNumber = async (storeNumber) => {
    try {
        const url = `/bsro/services/store/details/${storeNumber}`;

        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        const response = await fetch(url, fetchGetOptions());
        const responseJson = await response.json();

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        console.log("there's been an error in getStoreByStoreNumber(storeNumber);: ", { err });
        return { error: err, success: 'false' };
    }
};

export const getStates = async () => {
    try {
        const url = '/bsro/services/store/get-state';

        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        const response = await fetch(url, fetchGetOptions());
        const responseJson = await response.json();

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        console.log("there's been an error in getStates: ", { err });
        return { error: err, success: 'false' };
    }
};

export const getUserIP = async () => {
    try {
        const url = `/bsro/services/store/location/geo-location`;
        const response = await fetch(url, fetchGetOptions());
        const responseJson = await response.json();

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};
