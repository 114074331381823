import '../scss/good-better-best-list.scss';
import Swiper from 'swiper/bundle';
import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';

const instanceInit = (rootEl) => {
    console.log('Good Better Best List Called');

    window.addEventListener('load', () => {
        initSwiper(rootEl);
        adjustHeight(rootEl);
    });

    pubsub.subscribe('viewportquerychange', (evt, currentQuery) => handleResize(currentQuery, rootEl));
};

const initSwiper = (rootEl) => {
    const carousel = rootEl.querySelector('.swiper');
    let slidesDesktop = Number(carousel.getAttribute('data-slides-desktop')) || 3;
    let slidesTablet = Number(carousel.getAttribute('data-slides-tablet')) || 2;
    let slidesPhone = Number(carousel.getAttribute('data-slides-phone')) || 1;
    let swiperSlides = rootEl.querySelectorAll('.children.carousel .swiper-slide');

    [...swiperSlides].forEach((el) => {
        rootEl.querySelector('.swiper-wrapper').insertAdjacentElement('beforeend', el);
    });

    rootEl.querySelector('.children.carousel').remove();

    new Swiper(carousel, {
        loop: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        breakpoints: {
            300: {
                slidesPerView: slidesPhone,
                slidesPerGroup: 1,
                spaceBetween: 10
            },
            768: {
                slidesPerView: slidesTablet,
                slidesPerGroup: slidesTablet,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: slidesDesktop,
                slidesPerGroup: slidesDesktop
            }
        }
    });
};

const adjustHeight = (rootEl) => {
    let maxHeight = 0;

    let cardsInfo = rootEl.querySelectorAll('.good-better-best-card .info');

    [...cardsInfo].forEach((el) => {
        el.innerHeight = 'auto';
    });

    [...cardsInfo].forEach((el) => {
        let elHeight = el.clientHeight;

        if (elHeight > maxHeight) {
            maxHeight = elHeight;
        }
    });

    [...rootEl.querySelectorAll('.good-better-best-card .info')].forEach((infoEl) => {
        infoEl.style.height = maxHeight + 'px';
    });
};

const handleResize = (currentQuery, rootEl) => {
    console.log('GBBL handleResize:', currentQuery);
    adjustHeight(rootEl);
};

component({
    selector: '.good-better-best-carousel',
    instanceInit,
    name: 'GoodBetterBestList'
});
