import { isStoreOpen } from './isStoreOpen';
import { transform24hto12hTime } from './transform24hto12hTime';

/**
 * Generates HTML for a store hours table based on the provided store data.
 *
 * @param {Object} storeData - The data for the store.
 * @param {string} storeData.state - The state where the store is located.
 * @param {Array<Object>} storeData.hours - The regular hours of the store. Each object should contain `openTime` and `closeTime` properties.
 * @param {Array<Object>} [storeData.holidays] - The holidays when the store may have different hours. Each object should contain `holidayId` and `description` properties.
 * @param {Array<Object>} [storeData.holidayHours] - The hours for the store during holidays. Each object should contain `holidayId`, `openTime`, and `closeTime` properties.
 *
 * @returns {string} - The HTML string for the store hours table.
 */

const getStoreHoursTableHtml = (storeData) => {
    if (storeData.temporarilyClosed === 'Y') {
        return `
            <table class="wrap-local-pages__table">
            <tr>
                <td colspan="2">
                    <span class="wrap-local-pages__closed wrap-local-pages__closed-holiday"></span>
                    Closed Temporarily <br><br>
                    This store is temporarily closed. <a href="#finder">Click Here</a> to see alternative stores.
                </td>
            </tr>
            </table>
        `;
    }

    const hours = storeData.hours;
    const holidays = storeData.holidays;
    const holidayHours = storeData.holidayHours;
    const isOpen = isStoreOpen(hours, holidays, holidayHours);
    const isOpenClass = isOpen ? 'wrap-local-pages__open' : 'wrap-local-pages__closed';
    const isOpenText = isOpen ? 'Open Now' : 'Closed';

    const getFormattedTimeRange = (dayIndex) => {
        if (hours[dayIndex]) {
            return `${transform24hto12hTime(hours[dayIndex].openTime).toLowerCase()} - ${transform24hto12hTime(hours[dayIndex].closeTime).toLowerCase()}`;
        } else {
            return 'Closed';
        }
    };

    const formattedHours = `
        <table class="wrap-local-pages__table">
            <tr>
                <td colspan="2" class="wrap-local-pages__is-open"><span class="${isOpenClass}"></span> ${isOpenText}</td>
            </tr>
            <tr>
                <td>Mon- Fri</td>
                <td>${getFormattedTimeRange(0)}</td>
            </tr>
            <tr>
                <td>Sat</td>
                <td>${getFormattedTimeRange(5)}</td>
            </tr>
            <tr>
                <td>Sun</td>
                <td>${getFormattedTimeRange(6)}</td>
            </tr>
            ${
                holidays
                    ? holidays
                          .map((holiday) => {
                              const hh = holidayHours
                                  ? holidayHours.find((hh) => hh.holidayId === holiday.holidayId)
                                  : undefined;
                              const holidayName = holiday.description || 'Holiday';
                              if (hh) {
                                  return `
                                <tr><td colspan="2">Holiday Hours</td></tr>
                                <tr>
                                    <td>${holidayName}</td>
                                    <td>${transform24hto12hTime(hh.openTime).toLowerCase()} - ${transform24hto12hTime(hh.closeTime).toLowerCase()}</td>
                                </tr>
                            `;
                              } else {
                                  const holidayDate = new Date(holiday.year, holiday.month - 1, holiday.day);
                                  const options = { weekday: 'long', month: 'long', day: 'numeric' };
                                  const formattedDate = holidayDate.toLocaleDateString('en-US', options);
                                  return `
                                <tr>
                                    <td colspan="2"><br><span class="wrap-local-pages__closed wrap-local-pages__closed-holiday"></span><strong>Closed</strong> ${formattedDate}</td>
                                </tr>
                            `;
                              }
                          })
                          .join('')
                    : ''
            }                
        </table>
    `;
    return formattedHours;
};

export { getStoreHoursTableHtml };
