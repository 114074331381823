import '../scss/footer.scss';
import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
import { viewport } from 'common/js/library/viewport';

const instanceInit = (rootEl) => {
    activateAccordion(viewport.currentQuery, rootEl);
    pubsub.subscribe('viewportquerychange', (evt, currentQuery) => activateAccordion(currentQuery, rootEl));
};

const activateAccordion = (currentQuery, rootEl) => {
    if (currentQuery === 'phone') {
        rootEl.addEventListener('click', accordionHandler);
    } else {
        rootEl.removeEventListener('click', accordionHandler);
        let wrapEl = rootEl.nextElementSibling;
        if (window.getComputedStyle(wrapEl).display === 'none') {
            wrapEl.style.display = '';
        }
    }
};

const accordionHandler = (e) => {
    e.preventDefault();
    let el = e.currentTarget || e.target;
    let wrapEl = el.nextElementSibling;
    let toggleEl = el?.querySelector('.toggle a');
    if (window.getComputedStyle(wrapEl).display === 'none') {
        wrapEl.style.display = 'block';
        toggleEl.innerText = '-';
    } else {
        wrapEl.style.display = 'none';
        toggleEl.innerText = '+';
    }
};

component({
    selector: '.footerlinks .footer-heading',
    instanceInit,
    name: 'Footer'
});
