import { queryStringify, responseError, HTTPHeaders } from 'common/js/data/services/serviceUtilities';
import { component } from 'common/js/library/component';
import { validateElement } from 'common/js/library/util';

const instanceInit = (rootEl) => {
    console.log('inside email-unsubscribe.js');

    let submitBtn = rootEl.querySelector('.email-unsubscribe-btn');
    let coralWaitContainer = rootEl.querySelector('.coral-wait-container');

    coralWaitContainer && (coralWaitContainer.style.display = 'none');

    submitBtn.addEventListener('click', (evt) => handleSubmit(evt, submitBtn, rootEl, coralWaitContainer));

    let inputEl = rootEl.querySelectorAll('input');
    [...inputEl].forEach((inputEl) => {
        inputEl.addEventListener('change', (evt) => handleFieldChange(evt, submitBtn, rootEl));
        inputEl.addEventListener('blur', (evt) => handleFieldChange(evt, submitBtn, rootEl));
        inputEl.addEventListener('keyup', (evt) => handleFieldChange(evt, submitBtn, rootEl));
        inputEl.addEventListener('input', (evt) => handleFieldChange(evt, submitBtn, rootEl));
    });
};

const handleFieldChange = (evt, submitBtn, rootEl) => {
    let data = getFormValues(rootEl);
    let disabled = true;

    if (validate(data)) {
        disabled = false;
    }

    submitBtn.disabled = disabled;
};

const handleSubmit = (evt, submitBtn, rootEl, coralWaitContainer) => {
    let data = getFormValues(rootEl);

    if (validate(data)) {
        let emailValidRes = validateElement(data.email, 'email');
        if (emailValidRes) {
            sendData(data, submitBtn, rootEl, coralWaitContainer);
        }
    } else {
        console.log('invalid data');
    }
};

const getFormValues = (rootEl) => {
    let _email = rootEl.querySelector('#email').value.trim();
    let _confirmEmail = rootEl.querySelector('#confirm-email').value.trim();
    let _data = {
        email: _email,
        confirmEmail: _confirmEmail
    };

    return _data;
};

const validate = (data) => {
    if (data.email !== '' && data.confirmEmail !== '' && data.email === data.confirmEmail) {
        return true;
    }

    return false;
};

const sendData = async (data, submitBtn, rootEl, coralWaitContainer) => {
    let sendData = {
        email: data.email
    };

    submitBtn.disabled = true;

    try {
        const url = `/bsro/services/contact/unsubscribe${queryStringify(sendData)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        let unSubscribeEl = rootEl.querySelector('.unsubscribe');
        let emailUnSubscribeConfirmationEl = rootEl.querySelector('.email-unsubscribe-confirmation');

        unSubscribeEl && (unSubscribeEl.style.display = 'none');
        emailUnSubscribeConfirmationEl && (emailUnSubscribeConfirmationEl.style.display = 'block');
        window._satellite.track('email.unsubscribed');
    } catch (err) {
        submitBtn.disabled = false;
    } finally {
        coralWaitContainer && (coralWaitContainer.style.display = 'none');
    }
};

component({
    selector: '.email-unsubscribe-component',
    instanceInit,
    name: 'EmailUnsubscribe'
});
