import { featureService } from './feature-service';
import { updateAppointmentDetails } from './updateAppointmentDetails';
import { pubsub } from 'common/js/library/pubsub';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const onVehicleSubmit = (rootEl, props) => {
    const {
        addedServicesListArr,
        dateStoreStep,
        getAppointmentDetails,
        getStoresInfo,
        serviceStep,
        setAppointmentDetails,
        getVehicleData
    } = props;
    getVehicleData();
    const { year, make, model, trim } = getAppointmentDetails().vehicle;

    const vehicleProfile = {
        year,
        make,
        model,
        submodel: trim
    };

    let sdata = {
        vehicle: vehicleProfile,
        vehicle_search_type: 'dropdown',
        event: 'sa.v2.vehicle'
    };

    setAppointmentDetails(sdata);

    if (dateStoreStep.dataset.date) {
        const dateStoreData = JSON.parse(dateStoreStep.dataset.date);
        const storeDetails = getStoresInfo().storesAsDataObj[dateStoreData.storeNumber];

        sdata.store = {
            store_position: storeDetails.count,
            city: storeDetails.city,
            state: storeDetails.state,
            zip: storeDetails.zip,
            storeNumber: dateStoreData.storeNumber
        };
        sdata.services = addedServicesListArr;
        sdata.date = dateStoreData.availableDate;
        sdata.time = dateStoreData.selectedTime;
        sdata.preference_selected = rootEl.querySelector('.pickup-preference option:checked')?.value || 'N/A';

        updateAppointmentDetails(rootEl, {
            dateStoreData,
            getAppointmentDetails,
            setAppointmentDetails,
            getStoresInfo,
            serviceStep
        });
    }

    if (!year || !make || !model || !trim) {
        console.error(
            `vehicle selection completion error: vehicle information missing or incomplete, {vehicleProfile: {year: ${year}, make: ${make}, model: ${model}, submodel: ${trim}}}`
        );
    }

    // if vehicle older than 5 years, elevate vehicle inspection
    let thisYear = new Date().getFullYear();
    let vehicleYear = parseInt(year);

    if (thisYear - vehicleYear >= 5) {
        featureService(
            'accordion-item-vehicle-inspection-base',
            'Find any potential issues before they become big problems.'
        );
        featureService(
            'accordion-item-vehicle-inspection-plus',
            'Our Complete Vehicle Inspection PLUS in depth starting, charging and coolant pressure testing.'
        );
    }

    setAdobeDataLayer(sdata);
    pubsub.publish('steps-next');
};

export { onVehicleSubmit };
