import { resetHours } from './resetHours';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const showServiceDateHours = (evt, rootEl) => {
    const currentTarget = evt.currentTarget;
    const serviceDate = currentTarget.dataset.serviceDate;
    const hoursItem = Array.from(rootEl.querySelectorAll('.hours-item'));
    const dateItems = rootEl.querySelectorAll('.date-items li');
    const serviceDateHours = hoursItem.filter((item) => item.getAttribute('data-available-date') === serviceDate);

    dateItems.forEach((item) => {
        item.classList.remove('active');
    });

    hoursItem.forEach((item) => {
        item.classList.remove('active');
    });

    currentTarget.classList.add('active');
    serviceDateHours.forEach((item) => {
        item.classList.add('active');
    });

    setAdobeDataLayer({ event: 'sa.v2.calendarinteraction' });

    resetHours(rootEl);
};

export { showServiceDateHours };
