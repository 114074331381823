import { setStore } from './set-store';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';

const tabStoreClientSideReinitialization = () => {
    // Select the target node
    const targetNode = document.querySelector('#tab-my-store-container');

    // Function to be called when top nav tab store jsi is added
    const onTabStoreJSIAdded = () => {
        const images = targetNode.querySelectorAll('img[data-src]');
        images.forEach((img) => {
            img.src = img.dataset.src;
        });
    };

    // Options for the observer (which mutations to observe)
    const config = { childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationsList) => {
        for (let mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.target.id?.includes('dynamic_include_filter_div')) {
                onTabStoreJSIAdded();
                window.initModalPopover();
                setStore();

                const reviewsSummary = Array.from(targetNode.querySelectorAll('.reviews-app'));
                pubsub.publish(topics.CREATE_REVIEWS, [reviewsSummary]);

                const reviewsSummaryV2 = Array.from(targetNode.querySelectorAll('.reviews-app-v2'));
                pubsub.publish(topics.CREATE_REVIEWS_V2, [reviewsSummaryV2]);

                //Disconnect the observer after the first mutation
                observer.disconnect();
                break;
            }
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    targetNode && observer.observe(targetNode, config);
};

export { tabStoreClientSideReinitialization };
