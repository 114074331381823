import '../scss/topnav.scss';
import '../scss/tabcontent.scss';
import { KeyboardNavigation } from './keyboard-navigation/keyboard-navigation';
import { pubsub } from 'common/js/library/pubsub';

const hoverHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    pubsub.publish('VEHICLE-SELECTOR-APP-closeMyGarageApp');
    pubsub.publish('close-my-store-globally');
    pubsub.publish('on-subnav-open');
};

const init = () => {
    const topNav = document.querySelector('#top-nav-v2');
    const nav = document.querySelector('.primary-nav-v2');

    if (nav) {
        const links = nav.querySelectorAll('#nav-tires > a, #nav-auto_repair > a, #nav-auto_maintenance > a');

        if (links && links.length > 0) {
            links.forEach((link) => {
                link.addEventListener('mouseover', hoverHandler, false);
            });
        }
    }

    if (topNav) {
        KeyboardNavigation(topNav);
    }
};

window.addEventListener('load', init);
