import { store as ReduxStore } from 'common/js/data/redux/store/exportedStore';
import { setCookie } from 'common/js/library/util';

const getAccountState = (props) => {
    const { setIsLoggedIn, setPreferredStoreNumber } = props;
    const currentState = ReduxStore.getState();

    if (currentState.account.isLoggedIn === 'true') {
        setIsLoggedIn(true);

        const profile = currentState.account.profile;
        const accountDriver = profile.DRIVER[0];
        const accountStore = profile.STORE;
        const accountPreferredStoreId = accountDriver && accountDriver.PREFERRED_STORE;

        for (let i = 0; i < accountStore.length; i++) {
            const myStore = accountStore[i];

            if (myStore.ID == accountPreferredStoreId) {
                setPreferredStoreNumber(myStore.STORE_NUMBER);
                setCookie('hasSetPreferredStore', true);

                break;
            }
        }
    } else {
        setCookie('hasSetPreferredStore', false);
    }
};

export { getAccountState };
