/**
 *
 * @param {string} id - checkbox selector for service (can be for duplicate elements)
 * @param {string} copy - optional additional feature copy
 * @returns {Array} array of accordion items inserted
 */
const featureService = (id, copy) => {
    // Handle potential duplicate IDs so a pre-selected cvi gets the feature service class as well
    const checkboxes = document.querySelectorAll(`[id="${id}"]`);
    if (!checkboxes.length) return [];

    const insertedItems = [];

    // Process each matching checkbox
    checkboxes.forEach((checkbox) => {
        // Check if the service is already part of added services
        if (checkbox.closest('.service-items-group')) return;

        const accordionItem = checkbox.closest('.accordion-item');
        if (!accordionItem) return;

        const section = checkbox.closest('section');
        const accordion = section?.querySelector('.accordion'); // recommended services accordion
        const accordionFirstItem = accordion?.querySelector('.accordion-item');
        let item = accordion?.insertBefore(accordionItem, accordionFirstItem);
        const hasCopyEl = accordionItem?.querySelector('.feature-copy');

        if (copy && !hasCopyEl) {
            let detailsBtn = accordionItem?.querySelector('.details');
            let copyParent = accordionItem?.querySelector('span');
            let copyEl = document.createElement('small');
            copyEl.innerText = copy;
            copyEl.classList.add('feature-copy');
            copyParent.append(detailsBtn);
        }

        if (item) {
            item.classList.add('feature-service');
            insertedItems.push(item);
        }
    });

    // For backward compatibility, return the first item if only one is found
    return insertedItems.length === 1 ? insertedItems[0] : insertedItems;
};

export { featureService };
