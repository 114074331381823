import { dateToDD } from './dateToDD';
import { dateToMM } from './dateToMM';
/**
 * Extracts the year, month, and day as separate components from a given Date object.
 * Returns an object with properties for year, month, and day, where month and day
 * are formatted as two-digit strings.
 *
 * @param {Date} date - The Date object from which to extract the date components.
 * @returns {{year: string, month: string, day: string}} An object containing the year as a four-digit string,
 *          and the month and day as two-digit strings.
 */
export const dateToYearMonthDay = (date) => {
    // const year = date
    //     .toLocaleString('en-US', { year: 'numeric' })
    //     .replace(/[^ -~]/g, '');
    // const month = date
    //     .toLocaleString('en-US', { month: '2-digit' })
    //     .replace(/[^ -~]/g, '');
    // const day = date
    //     .toLocaleString('en-US', { day: '2-digit' })
    //     .replace(/[^ -~]/g, '');
    const year = String(date.getFullYear());
    const month = dateToMM(date);
    const day = dateToDD(date);

    return { year, month, day };
};
