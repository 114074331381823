import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';

/**
 * Initializes the Product Results Filter
 *
 * @param {function} callback - actions needed to be handled after filter is complete
 * @return {void}
 */
const filter = (callback) => {
    const $group = $('[data-product-results-grid]');
    const $categoryInputs = $group.find('.category input');
    const $brandInputs = $group.find('.brand-category input');
    const $typeInputs = $group.find('.type-category input');
    const $saleInputs = $group.find('.sale-only input');
    const $matchedsetInputs = $group.find('.matchedSet-category input');
    const $sizeInputs = $group.find('.size-category input');
    const $tireRatingInputs = $group.find('.rating-category input');
    const $mileageInputs = $group.find('.mileage-category input');
    const $stdoptInputs = $group.find('.stdOpt-category input');

    $categoryInputs.change(function () {
        let selected = {
            brands: [],
            types: [],
            size: [],
            price: [],
            sale: [],
            matchedSet: [],
            tireRating: [],
            mileage: [],
            stdOpt: [],
            priceValue: 0,
            track: []
        };
        const $input = $(this);
        const $priceLabel = $input.parent().prevAll('.price-label').find('span.price');

        if ($input.hasClass('input-range')) {
            $input.addClass('data-price-range');
            $priceLabel.text('$' + this.value);
        }

        if ($('.input-range').hasClass('data-price-range')) {
            selected.priceValue = $('.data-price-range').val();

            if (selected.priceValue == 0) {
                selected.price = [];
            } else if (selected.priceValue == 100) {
                selected.price = ['price-0'];
            } else if (selected.priceValue == 200) {
                selected.price = ['price-0', 'price-1'];
            } else if (selected.priceValue == 300) {
                selected.price = ['price-0', 'price-1', 'price-2'];
            } else if (selected.priceValue == 400) {
                $priceLabel.text('$300+');
                selected.price = ['price-0', 'price-1', 'price-2', 'price-3'];
            }
        }

        addChecked($brandInputs, selected.brands);
        addChecked($typeInputs, selected.types);
        addChecked($saleInputs, selected.sale);
        addChecked($matchedsetInputs, selected.matchedSet);
        addChecked($sizeInputs, selected.size);
        addChecked($tireRatingInputs, selected.tireRating);
        addChecked($mileageInputs, selected.mileage);
        addChecked($stdoptInputs, selected.stdOpt);

        setFilterBG('.brand-category', selected.brands);
        setFilterBG('.type-category', selected.types);
        setFilterBG('.size-category', selected.size);
        setFilterBG('.matchedSet-category', selected.matchedSet);
        setFilterBG('.rating-category', selected.tireRating);
        setFilterBG('.mileage-category', selected.mileage);
        setFilterBG('.stdOpt-category', selected.stdOpt);
        setFilterBG('.pricing-category', selected.price, selected.sale);

        // using setTimeout to delay these changes because max price filter doesn't get updated otherwise.
        setTimeout(() => {
            $('.results-container')
                .find('div.product.result-card')
                .each(function (index) {
                    const $card = $(this);

                    if (
                        getCardFilterStatus($card, selected.brands) &&
                        getCardFilterStatus($card, selected.types) &&
                        getCardFilterStatus($card, selected.size) &&
                        getCardFilterStatus($card, selected.price) &&
                        getCardFilterStatus($card, selected.sale) &&
                        getCardFilterStatus($card, selected.matchedSet) &&
                        getCardFilterStatus($card, selected.tireRating) &&
                        getCardFilterStatus($card, selected.mileage) &&
                        getCardFilterStatus($card, selected.stdOpt)
                    ) {
                        $card.show();
                    } else {
                        $card.hide().find('input').attr('checked', false);

                        $card.hide(); // TODO: do we need to call hide a second time?
                    }
                });

            var resultCount = $('.results-container').find('div.product.result-card:visible').length;

            var inStockCount = $('.results-container').find('div.product.result-card.in-stock-card:visible').length;

            var outStockCount = $('.results-container').find('div.product.result-card.call-card:visible').length;

            pubsub.publish(topics.VALIDATE_SEARCH_FILTERS, [inStockCount, outStockCount]);

            $('.number-of-results span.result-count').html(resultCount);

            if (resultCount == 0) {
                $('.no-result-error').show();
            } else {
                $('.no-result-error').hide();
            }
        }, 1);

        let $customSelected = $input.parents('.custom-selected');
        let $customSelectedFirstChild = $customSelected && $customSelected.children(':first-child');
        let $inputParentUl = $input.parents('ul');
        let $inputParentUlClass = $inputParentUl && $inputParentUl.attr('class');
        let _customSelect =
            ($customSelectedFirstChild && $customSelectedFirstChild.text().toLowerCase()) ||
            ($inputParentUlClass && $inputParentUlClass.split(' ')[0]) ||
            'max price'; // BSROAEM-4034: no ul parent exists in mobile for max price selector. defaulting to 'max price' because it's the only case currently where there wouldn't be a value
        let $inputLabel = $input.parent().children('label');
        let _customCheckbox = ($inputLabel && $inputLabel.text().toLowerCase()) || $input.val().toLowerCase();

        _customSelect = _customSelect.replace(/-/g, ' ').toLowerCase();

        if (_customSelect == 'max price') {
            _customCheckbox = selected.priceValue;
        }

        // either adds array values or single number value to the tracker
        const addToTracker = (label, arr, value, isMileage = false) => {
            if (Array.isArray(arr) && arr.length > 0) {
                arr.map((item) => {
                    if (isMileage) {
                        item = item === 'mileage-2' ? 'over 50,000 miles' : '';
                    }
                    selected.track.push(label + item);
                });
            } else if (value && value > 0) {
                selected.track.push(label + value);
            }
        };

        addToTracker('max price:', null, selected.priceValue);
        addToTracker('brand:', selected.brands);
        addToTracker('tire type:', selected.types);
        addToTracker('tire size:', selected.size);
        addToTracker('mileage warranty:', selected.mileage, null, true);

        // remove duplicates from both mobile/desktop selections:
        selected.track = selected.track.filter((value, index, arr) => {
            return arr.indexOf(value) == index;
        });

        var _sortData = {
            filter: _customSelect + ':' + _customCheckbox,
            allFilters: selected.track
                .toString()
                .replace(/([\d]+),([\d]+)/g, '$1+$2')
                .replace(/,/g, '|')
                .replace(/\+/g, ',')
                .replace(/--/g, ' ')
                .toLowerCase()
        };

        window._satellite.setVar('eventData', _sortData);
        window._satellite.track('tire.filter');

        callback();
    });
};

/**
 * Add checked inputs into the provided array
 *
 * @param {jQuery} $inputs - the inputs to see if they're checked
 * @param {Array} arr - the array to add the input value to
 * @return {void}
 */
const addChecked = ($inputs, arr) => {
    $inputs.filter(':checked').each(function () {
        arr.push(this.value);
    });
};

//
/**
 * Sets the UI state for the filter element
 *
 * @param {string} selector - selector that defines element(s) to target
 * @param {Array} arr - verifies that array has contents for class to be added
 * @param {Array} arr2 - a second array to verify against
 * @return {void}
 */
const setFilterBG = (selector, arr, arr2) => {
    if ((Array.isArray(arr) && arr.length > 0) || (Array.isArray(arr2) && arr2.length > 0)) {
        $(selector).addClass('filterBg');
    } else {
        $(selector).removeClass('filterBg');
    }
};

/**
 * Returns if a card contains a class
 *
 * @param {jQuery} $card - card to be checked against
 * @param {Array} classArr - array of classes to check against
 * @return {boolean}
 */
const getCardFilterStatus = ($card, classArr) => {
    let status = false;

    if (Array.isArray(classArr)) {
        if (classArr.length == 0) {
            status = true;
        } else {
            classArr.map((item) => {
                if ($card.hasClass(item)) {
                    status = true;
                }
            });
        }
    }

    return status;
};

export { filter };
