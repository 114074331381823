import { allOffers } from 'common/js/data/services/ecomm/quote';

export const getDiscounts = async (pricingDetailsStorageItem) => {
    const promotion = pricingDetailsStorageItem[0]?.potentialPromotions[0];
    const discountPromoType = () => {
        const instantOnly =
            (promotion?.promotionValue !== undefined || promotion?.promotionValue !== null) &&
            pricingDetailsStorageItem[0]?.rebates?.length === 0;
        const rebateOnly =
            pricingDetailsStorageItem[0]?.potentialPromotions?.length === 0 &&
            pricingDetailsStorageItem[0]?.rebates?.length > 0;
        const rebateAndInstant =
            pricingDetailsStorageItem[0]?.rebates?.length > 0 &&
            pricingDetailsStorageItem[0]?.potentialPromotions?.length > 0;
        if (instantOnly) {
            return 'instant';
        } else if (rebateOnly) {
            return 'rebate';
        } else if (rebateAndInstant) {
            return 'instant + rebate';
        } else {
            return null;
        }
    };
    const discountType = discountPromoType();
    const response = await allOffers(promotion?.title || pricingDetailsStorageItem[0]?.rebates[0]?.code);
    const thumbTitle = response?.data?.tirePromotions?.[0]?.thumbTitle || response?.data?.coupons?.title;
    const promoCategory = promotion?.loggedInOnlyPromotion === true ? 'logged in' : 'everybody';
    let discounts = [];
    if (discountType !== null) {
        if (discountType === 'instant') {
            discounts.push({
                category: promoCategory,
                type: discountType,
                offerID: promotion?.code,
                offerName: thumbTitle
            });
        } else if (discountType === 'rebate') {
            discounts.push({
                category: promoCategory,
                type: discountType,
                offerID: pricingDetailsStorageItem[0]?.rebates[0]?.code,
                offerName: thumbTitle
            });
        } else if (discountType === 'instant + rebate') {
            discounts.push(
                {
                    category: promoCategory,
                    type: 'instant',
                    offerID: promotion?.code,
                    offerName: thumbTitle
                },
                {
                    category: promoCategory,
                    type: 'rebate',
                    offerID: pricingDetailsStorageItem[0]?.rebates[0]?.code,
                    offerName: thumbTitle
                }
            );
        }
    }
    return discounts;
};
