import { addNullData } from './addNullData';

const dtmTag = (evt) => {
    const dataLayer = window.digitalData || {};
    const currentTarget = evt.currentTarget;
    let tag = currentTarget.dataset.tag || null;
    const tagClass = currentTarget.dataset.tagClass || '';
    const tagAttr = currentTarget.dataset.tagAttr || null;
    const tagAttrVal = currentTarget.dataset.tagAttrVal || null;

    // needs to be consistent with data-tag-* like above
    const setVar = currentTarget.dataset.setVar || null;
    const setVarVal = currentTarget.dataset.setVarVal || null;
    const actionName = currentTarget.dataset.actionName || null;
    const track = currentTarget.dataset.track;
    const dataLayerAttr = dataLayer.event && dataLayer.event.length > 0 ? dataLayer.event[0].attributes : null;
    //

    if (typeof tag === 'string') {
        tag = { eventName: tag };
    }

    if (tag.eventName == null && tag.eventName == undefined && actionName != null) {
        tag.eventName = actionName;
    }

    if (tag.attributes) {
        addNullData(tag, true);
    }

    if (dataLayerAttr) {
        if (!tag.attributes) {
            tag.attributes = {};
        }

        tag.attributes = Object.assign({}, tag.attributes, dataLayerAttr);
    }

    tagClass && currentTarget.classList.add(tagClass);

    if (tagAttr && tagAttrVal) {
        currentTarget.dataset[tagAttr] = tagAttrVal === 'eventName' ? tag.eventName : tagAttrVal;
    }

    if (dataLayer) {
        dataLayer.event = [tag];
    }

    if (setVar) {
        if (setVarVal) {
            window._satellite.setVar(setVar, setVarVal);
        } else {
            window._satellite.setVar(setVar, tag.attributes);
        }
    }

    if (track) {
        switch (typeof track) {
            case 'boolean':
                window._satellite.track(tag.eventName);
                break;
            case 'string':
                window._satellite.track(track);
                break;
        }
    }
};

export { dtmTag };
