import '../scss/articles.scss';
import Swiper from 'swiper/bundle';
import { component } from 'common/js/library/component';

const instanceInit = (rootEl) => {
    console.log('inside articles-carousel.js');
    initSwiper(rootEl);
};

const initSwiper = (rootEl) => {
    const carousel = rootEl.querySelector('.swiper');
    const slidesDesktop = Number(carousel.getAttribute('data-slides-desktop')) || 3;
    const slidesTablet = Number(carousel.getAttribute('data-slides-tablet')) || 2;
    const slidesPhone = Number(carousel.getAttribute('data-slides-phone')) || 1;
    const carouselScrollBar = rootEl.querySelector('.swiper-scrollbar');

    const swiperSlides = rootEl.querySelectorAll('.carousel .swiper-slide');

    [...swiperSlides].forEach((el) => {
        rootEl.querySelector('.swiper-wrapper').insertAdjacentElement('beforeend', el);
    });

    rootEl.querySelector('.carousel')?.remove();

    const checkArrowAndDots = (rootEl) => {
        const slides = rootEl.querySelectorAll('.swiper-wrapper .swiper-slide');
        const swiperPrev = rootEl.querySelector('.swiper-button-prev');
        const swiperNext = rootEl.querySelector('.swiper-button-next');
        const swiperPagination = rootEl.querySelector('.swiper-pagination');
        
        if (window.innerWidth > 1024 && slides.length > 3) {
            swiperPrev.style.display = 'block';
            swiperNext.style.display = 'block';
            swiperPagination.style.display = 'none';
        } else {
            swiperPrev.style.display = 'none';
            swiperNext.style.display = 'none';
            swiperPagination.style.display = 'block';
        }
    };

    new Swiper(carousel, {
        loop: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        spaceBetween: 20,
        scrollbar: {
            el: carouselScrollBar,
            draggable: true,
            hide: true
        },
        breakpoints: {
            320: {
                slidesPerView: slidesPhone,
                slidesPerGroup: slidesPhone
            },
            768: {
                slidesPerView: slidesTablet,
                slidesPerGroup: slidesTablet
            },
            1024: {
                slidesPerView: slidesDesktop,
                slidesPerGroup: slidesDesktop
            }
        },
        on: {
            init: () => {
                checkArrowAndDots(rootEl);
            },
            resize: () => {
                checkArrowAndDots(rootEl);
            }
        }
    });
};

component({
    instanceInit,
    selector: '.articles-carousel',
    name: 'ArticlesCarousel'
});
