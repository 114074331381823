import { getProductSummaries, getProductSummariesV2 } from 'common/js/data/services/reviews/summary';

/**
 * helper function for making and responding to review data request
 * @param {function} callback - callback function to run after successful response
 * @param {array} idArray - array of stores and products to get values for
 * @param {string} groupId - PowerReviews groupId?
 * @param {string} locale - PowerReviews locale
 * @returns
 */
const updateProductSummaries = async (callback, idArray, groupId, locale = 'en-US') => {
    if (idArray.length < 1) {
        return false;
    }
    // split the idArray into store and tire arrays and make separate requests for each to improve cache response times
    let storeIds = [];
    let tireIds = [];
    let responseData = [];
    idArray.forEach((item) => {
        if (Number.isInteger(Number.parseInt(item))) {
            storeIds.push(item);
        } else {
            tireIds.push(item);
        }
    });

    if (storeIds.length > 0) {
        const storeResponse = await productSummariesRequest(storeIds.join(), groupId);
        if (storeResponse) {
            responseData = responseData.concat(storeResponse);
        }
    }

    if (tireIds.length > 0) {
        // temporary fix: chunk the request into two requests if it's larger than 50
        if (tireIds.length > 50) {
            const count = tireIds.length;
            const midPoint = Math.floor(count / 2);
            const group1 = tireIds.slice(0, midPoint - 1);
            const group2 = tireIds.slice(midPoint, tireIds.length);

            const g1Response = await productSummariesRequest(group1.join(), groupId);
            if (g1Response) {
                responseData = responseData.concat(g1Response);
            }

            const g2Response = await productSummariesRequest(group2.join(), groupId);
            if (g2Response) {
                responseData = responseData.concat(g2Response);
            }
        } else {
            const tireResponse = await productSummariesRequest(tireIds.join(), groupId);
            if (tireResponse) {
                responseData = responseData.concat(tireResponse);
            }
        }
    }

    if (responseData.length > 0) {
        callback(responseData);
        return true;
    } else {
        return false;
    }
};

const updateProductSummariesV2 = async (callback, idArray, groupId, locale = 'en-US') => {
    if (idArray.length < 1) {
        return false;
    }
    // split the idArray into store and tire arrays and make separate requests for each to improve cache response times
    let storeIds = [];
    let tireIds = [];
    let responseData = [];
    idArray.forEach((item) => {
        if (Number.isInteger(Number.parseInt(item))) {
            storeIds.push(item);
        } else {
            tireIds.push(item);
        }
    });

    if (storeIds.length > 0) {
        const storeResponse = await productSummariesRequestV2(storeIds.join(), groupId);
        if (storeResponse) {
            responseData = responseData.concat(storeResponse);
        }
    }

    if (tireIds.length > 0) {
        const tireResponse = await productSummariesRequestV2(tireIds.join(), groupId);
        if (tireResponse) {
            responseData = responseData.concat(tireResponse.results);
        }
    }

    callback(responseData);
    return responseData.length > 0;
};

/**
 *
 * @param {string} id
 * @param {string} groupId
 * @returns false or array
 */
const productSummariesRequest = async (id, groupId) => {
    try {
        const response = await getProductSummaries({
            id,
            groupId
            // locale
        });
        if (response.success === 'false') {
            return false;
        } else {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return false;
    }
};

const productSummariesRequestV2 = async (id, groupId) => {
    try {
        const response = await getProductSummariesV2({
            id,
            groupId
            // locale
        });
        if (response.success === 'false') {
            return false;
        } else {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return false;
    }
};

export { updateProductSummaries, updateProductSummariesV2 };
