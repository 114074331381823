import {
    COMPARE_ADD_TIRE,
    COMPARE_REMOVE_TIRE,
    COMPARE_SET_I18N,
    COMPARE_SET_MODAL_STATE,
    COMPARE_SET_TIRES,
    COMPARE_SET_LOGGED_IN
} from '../actions';
// import merge from 'lodash-es/merge';

import { deleteLocalStorageItem, setLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

const initialState = {
    isModalOpen: false,
    searchType: '',
    vehicle: { year: '', make: '', model: '', trim: '' },
    size: { crossSection: '', aspectRatio: '', rimSize: '', tireSize: '' },
    results: [],
    i18n: {}
};

function tireComparisionReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case COMPARE_SET_MODAL_STATE: {
            console.log('COMPARE_SET_MODAL_STATE', { payload });
            return Object.assign({}, state, { isModalOpen: Boolean(payload) });
        }
        case COMPARE_SET_TIRES: {
            console.log('COMPARE_SET_TIRES', { payload });
            return Object.assign({}, state, { results: payload });
        }
        case COMPARE_ADD_TIRE: {
            console.log('COMPARE_ADD_TIRE', { payload });
            let arr = state.results.concat();
            arr.push(payload);

            const day = 24 * 60 * 60 * 1000;
            const expiration = new Date(Date.now() + day);

            deleteLocalStorageItem('tireComparison');
            setLocalStorageItem('tireComparison', arr, expiration);

            return Object.assign({}, state, { results: arr });
        }
        case COMPARE_REMOVE_TIRE: {
            // payload is expected to be the tire article(s) of the result to be removed, comma deliminated if it's staggered-fit
            console.log('COMPARE_REMOVE_TIRE', { payload });
            let articles = payload.split('_');
            let arr = state.results.concat();
            // filter out results that match all article(s) in the payload.
            // let filteredResults = arr.filter((item) => {
            //     let isKeep = true;

            //     // make sure the tire array and article array are the same length
            //     if (item.length !== articles.length) return isKeep;

            //     let articleMatches = articles.filter((article, idx) => {
            //         return item[idx].article === article;
            //     });

            //     if (articleMatches.length === articles.length) isKeep = false;

            //     return isKeep;
            // });
            let filteredResults = [];
            if (articles.length > 1) {
                //split fit
                arr.forEach((result) => {
                    if (Array.isArray(result)) {
                        if (result[0].article !== articles[0] && result[1].article !== articles[1]) {
                            filteredResults.push(result);
                        }
                    }
                });
            } else {
                filteredResults = arr.filter((result) => result.article != payload);
            }

            const day = 24 * 60 * 60 * 1000;
            const expiration = new Date(Date.now() + day);

            deleteLocalStorageItem('tireComparison');
            setLocalStorageItem('tireComparison', filteredResults, expiration);

            return Object.assign({}, state, { results: filteredResults });
        }
        case COMPARE_SET_I18N: {
            console.log('COMPARE_SET_I18N', { payload });
            return Object.assign({}, state, { i18n: payload });
        }

        case COMPARE_SET_LOGGED_IN:
            return Object.assign({}, state, { isLoggedIn: payload });
            
        default:
            return state;
    }
}

export default tireComparisionReducer;
