export const satelliteSetVar = (eventKey, data) => {
    if (typeof window._satellite === 'object') {
        console.log('_satellite is available');
        if (typeof window._satellite.setVar === 'function') {
            console.log('setVar is a function');
            window._satellite.setVar(eventKey, data);
        } else {
            return false;
        }
    } else {
        // here is where we can detect an ad blocker if need be
        return false;
    }
};

export const satelliteTrack = (eventKey, data) => {
    if (typeof window._satellite === 'object') {
        if (typeof window._satellite.track === 'function') {
            data ? window._satellite.track(eventKey, data) : window._satellite.track(eventKey);
        } else {
            // here we can capture a failure condition
            return false;
        }
    } else {
        // here is where we can detect an ad blocker if need be
        console.log('no satellite available');
        return false;
    }
};
