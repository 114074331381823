import { META_SET_CLOSEST_STORE_LOCATION, META_SET_CURRENT_ZIP, META_SET_WINDOW_SIZE } from '../actions';
import merge from 'lodash-es/merge';

const initialState = {
    closestStoreLocation: {
        activeFlag: '',
        address: '',
        city: '',
        distance: '',
        eCommActiveFlag: '',
        fax: '',
        hours: [],
        latitude: '',
        localPageURL: '',
        longitude: '',
        managerEmailAddress: '',
        managerName: '',
        onlineAppointmentActiveFlag: '',
        phone: '',
        state: '',
        status: '',
        storeName: '',
        storeNumber: '',
        storeType: '',
        success: '',
        tirePricingActiveFlag: '',
        trackingPhone: '',
        zip: ''
    },
    currentZip: null,
    windowDimensions: {
        width: null,
        height: null
    }
};

function metaReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case META_SET_CLOSEST_STORE_LOCATION:
            return merge({}, state, {
                closestStoreLocation: payload.closestStoreLocation
            });
        case META_SET_CURRENT_ZIP:
            return merge({}, state, {
                currentZip: payload.zip
            });
        case META_SET_WINDOW_SIZE:
            return merge({}, state, {
                windowDimensions: payload.windowDimensions
            });
        default:
            return state;
    }
}

export default metaReducer;
