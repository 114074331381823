import { initModalPopover } from 'common/js/util/coral-modal/initModalPopover';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';
import { getCookie } from 'common/js/library/util';

const fetchGetOptions = {
    cache: 'no-cache',
    headers: {
        'Content-Type': 'text/html'
    },
    method: 'GET'
};

const setStoreContent = async ({ rootDiv, url, imgSelector }) => {
    try {
        if (rootDiv) {
            const response = await fetch(url, fetchGetOptions);
            if (response.ok) {
                const content = await response.text();
                if (rootDiv.classList.contains('seo-page')) {
                    rootDiv.classList.remove('seo-page');
                }
                rootDiv.innerHTML = content;
                const storeImg = rootDiv.querySelector(imgSelector);
                storeImg.src = storeImg.dataset.src;

                return true;
            }
        } else {
            console.log('NO ROOT DIV: ', rootDiv);
        }
    } catch (error) {
        console.log("there's been an error in setStoreContent: ", error);
    }
};

export const setZipStoreHtml = async (zip, storeNumber, force = false) => {
    try {
        if (!storeNumber) {
            const cookieData = getCookie('bsro.cp-fcac');
            storeNumber = cookieData?.location?.storeNumber;
        }

        console.log(`inside setZipStoreHtml '${zip}' '${storeNumber}'`);
        const siteName = window.AEM.siteName;

        // 5634: added any store with location level less than 2 to allow geolocation with no stores to show default store.
        if (
            force ||
            storeNumber != window?.siteProfile?.location?.storeNumber ||
            window?.siteProfile?.location?.locationLvl < 2
        ) {
            console.log('setZipStoreHTML() -> storeNumber != siteProfile.location.storeNumber');

            // set oil change map content
            const oil = {
                rootDiv: document.querySelector('.reskin [data-widget-oil-tab-content] .widget-tab-content'),
                url: `/content/bsro/${siteName}/static/widget/widget-oil-tab-content.${storeNumber}.html`,
                imgSelector: '.reskin [data-widget-oil-tab-content] .widget-tab-content .store-directions img'
            };

            // set brakes map content
            const brakes = {
                rootDiv: document.querySelector('.reskin [data-widget-brakes-tab-content] .widget-tab-content'),
                url: `/content/bsro/${siteName}/static/widget/widget-brakes-tab-content.${storeNumber}.html`,
                imgSelector: '.reskin [data-widget-brakes-tab-content] .widget-tab-content .store-directions img'
            };

            // set local store location map content
            const localStoreRootDiv = document.querySelector('.local-store.reskin');
            const localStore = {
                rootDiv: localStoreRootDiv ? localStoreRootDiv.parentNode : null,
                url: `/content/bsro/${siteName}/static/local-store.${storeNumber}.html`,
                imgSelector: '.local-store-map .map img'
            };

            // set myStore details
            const myStore = {
                rootDiv: document.querySelector('.primary-nav #tab-my-store-container'),
                url: `/content/bsro/${siteName}/static/top-nav/tab-store.${storeNumber}.html`,
                imgSelector: '.primary-nav #tab-my-store-container .col.map img'
            };
            console.log('SET-STORE before promise.all');
            // set all content maps in parallel
            await Promise.all([
                setStoreContent(oil),
                setStoreContent(brakes),
                setStoreContent(localStore),
                setStoreContent(myStore)
            ]);
            console.log('SET-STORE after promise.all');
            const zipInputs = document.querySelectorAll('input[name*=zip]');

            const reviewsSummary = Array.from(
                document.querySelectorAll('.primary-nav #tab-my-store-container .reviews-app')
            );
            console.log('setZipStoreHtml - pubsub:', {
                topic: topics.CREATE_REVIEWS,
                reviewsSummary
            });
            reviewsSummary.length > 0 && pubsub.publish(topics.CREATE_REVIEWS, [reviewsSummary]);

            const reviewsSummaryV2 = Array.from(
                document.querySelectorAll('.primary-nav #tab-my-store-container .reviews-app-v2')
            );
            console.log('setZipStoreHtml - pubsub:', {
                topic: topics.CREATE_REVIEWS_V2,
                reviewsSummaryV2
            });
            reviewsSummaryV2.length > 0 && pubsub.publish(topics.CREATE_REVIEWS_V2, [reviewsSummaryV2]);

            [].forEach.call(zipInputs, (input) => {
                if (!input.classList.contains('seo-input')) {
                    input.value = zip;

                    // Trigger input event to update form validation.
                    input.dispatchEvent(
                        new Event('input', {
                            bubbles: true
                        })
                    );
                }
            });

            const $body = document.querySelector('body');
            console.log('$body: ', $body);
            $body.classList.remove('no-local-store');
            $body.classList.add('has-local-store');

            window.siteProfile.location.myZip = zip;
            window.siteProfile.location.storeNumber = storeNumber;

            pubsub.publish(topics.MY_ZIP_SET, [zip]);

            // TODO: determine if this is necessary and why
            // found in common.js
            window.setTimeout(initModalPopover, 500);

            return true;
        } else {
            //window.debug_log('setZipStoreHTML() -> storeNumber == siteProfile.location.storeNumber');
        }
    } catch (error) {
        console.log("there's been an error in setZipStoreHtml: ", { error });
    }
};
