/**
 * Utility for checking if a vehicle's YMMT is in the vehicle repository.
 * This is needed to prevent "automatic" adding of vehicles to the repository.
 */
import { isUserLoggedIn } from 'common/js/data/services/account/user';
import { VehicleRepository } from 'common/js/util/vehicle-repository/vehicle-repository';

export const isYMMTInList = async (vehicle) => {
    const loggedState = await isUserLoggedIn();
    if (loggedState) {
        const vehicleRepository = new VehicleRepository();

        await vehicleRepository.load(true);

        const repositoryVehicles = [...vehicleRepository.vehicles()];

        return repositoryVehicles.find((repositoryVehicle) => {
            const repoVehicle = repositoryVehicle[1];
            const normalize = (str) => String(str).toLowerCase();
            const hasYMMT =
                normalize(repoVehicle.year) === normalize(vehicle.year) &&
                normalize(repoVehicle.make) === normalize(vehicle.make) &&
                normalize(repoVehicle.model) === normalize(vehicle.model) &&
                normalize(repoVehicle.trim) === normalize(vehicle.trim);
            return hasYMMT;
        });
    }

    return false;
};
