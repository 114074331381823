import { dateToDD } from './dateToDD';
import { dateToMM } from './dateToMM';

const dateToYYYYMMDD = (date) => {
    const yyyy = String(date.getFullYear());
    // const mm = date.toLocaleString('en-us', { month: '2-digit' }).replace(/[^ -~]/g, '');
    // const dd = date.toLocaleString('en-us', { day: '2-digit' }).replace(/[^ -~]/g, '');
    const mm = dateToMM(date);
    const dd = dateToDD(date);

    return `${yyyy}${mm}${dd}`;
};

export { dateToYYYYMMDD };
