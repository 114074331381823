const config = {
    data: {
        byVehicle: {
            tce: {
                year: '',
                make: '',
                model: '',
                trim: '',
                tpms: '',
                engine: '',
                vehicleType: ''
            },
            main: {
                year: '',
                make: '',
                model: '',
                trim: '',
                tpms: '',
                engine: '',
                vehicleType: ''
            },
            aux: {
                year: '',
                make: '',
                model: '',
                trim: '',
                tpms: '',
                engine: '',
                vehicleType: ''
            }
        },
        byTire: {
            crossSection: '',
            aspectRatio: '',
            rimSize: ''
        }
    },
    serviceUrl: {
        years: '/bsro/services/vehicle/get-years',
        makes: '/bsro/services/vehicle/get-makes',
        models: '/bsro/services/vehicle/get-models',
        submodels: '/bsro/services/vehicle/get-trims',
        engines: '/bsro/services/vehicle/get-engine',
        crossSection: '/bsro/services/tires/get-cross-sections',
        aspectRatio: '/bsro/services/tires/get-aspect-ratios',
        rimSize: '/bsro/services/tires/get-rim-sizes'
    }
};

export { config };
