const checkIfStoreHoliday = (storeInfo, day, month, year) => {
    let isHoliday = false;
    const holidays = storeInfo.holidays;
    if (!holidays) {
        return isHoliday;
    }

    for (let holidayIndex in holidays) {
        const holidayDay = holidays[holidayIndex].day;
        const holidayMonth = holidays[holidayIndex].month;
        const holidayYear = holidays[holidayIndex].year;

        if (holidayDay == day && holidayMonth == month && holidayYear == year) {
            isHoliday = true;
            break;
        }
    }

    return isHoliday;
};

export { checkIfStoreHoliday };
