import React from 'react';

const Button = (props) => {
    const { clickAction, styleName, type, disabled = false } = props;

    const buttonType = type ? type : 'button';

    return (
        <button className={`btn ${styleName || ''}`} onClick={clickAction} type={buttonType} disabled={disabled}>
            {props.children}
        </button>
    );
};

export default Button;
