/**
 * Converts a time string from 24-hour format to 12-hour format with AM/PM.
 * If the input is not in the correct format, the function returns the original input.
 *
 * @param {string} time - A string representing time in 24-hour format ("HH:MM:SS" or "HH:MM").
 * @returns {string} A string representing the time in 12-hour format with AM/PM suffix.
 */
export const transform24hto12hTime = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
};
