import { queryStringify, responseError, HTTPHeaders } from '../serviceUtilities';

export const getProductReviews = async (dataPackage) => {
    // const { id, locale, groupId, page, pageSize, sortBy, rating } = dataPackage;
    // might want to verify that dataPackage has correct query strings
    // sortBy = ratings|latest|oldest
    // rating=<1..5>|highestFirst|lowestFirst
    try {
        const url = `/bsro/services/reviews/get-reviews-for-product-id${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }
        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getProductReviewsV2 = async (dataPackage) => {
    try {
        const url = `/bsro/services/reviews/get-reviews-for-product-id${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders(['powerreviews'])
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }
        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

// {
//     "merchant_group_id": 68056,
//     "merchant_id": 42530,
//     "product_page_id": "abc1",
//     "headline": "This is a great product",
//     "rating": 4,
//     "comments": "great quality...",
//     "nickname": "John T",
//     "location": "San Fransisco",
//     "locale": "en_US",
//     "email": "test@test.com",
//     "user_ip_address": "0.0.0.0.0",
//     "review_source": "web",
//     "browser": "firefox",
//     "bottom_line": "Yes",
//     "pros": [
//       "Durable"
//     ],
//     "cons": [
//       "Wide"
//     ]
//   }
export const setProductReview = async (queryStringData, dataPackage) => {
    const url = `https://writeservices.powerreviews.com/api/b2b/writereview/submit_review${queryStringify(
        queryStringData
    )}`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataPackage),
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const voteUGC = async (dataPackage) => {
    /**
     * ugc_id integer *required
     * Unique ID of review that helpful/unhelpful vote is being applied to.
     * 
     * created_date string
     * Date-time the vote was submitted. Example: 2023-06-20T15:39:14.034Z
     * 
     * vote_type string *required
     * Accepted values: helpful, unhelpful
     * 
     * merchant_id string *required
     * PowerReviews unique identifier for a merchant.
     * 
     * cookie string
     * 
     * ip_address string
     * IP of end user submitting vote.
     * 
     */
    const url = 'https://writeservices.powerreviews.com/voteugc'

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataPackage),
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }
        return responseJson;

    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const reportUGC = async (dataPackage) => {
    /**
     * ugcId string *required
     * 
     * createdDate string
     * 
     * merchantId string *required
     * 
     * merchantUserId string
     * 
     * flagType string *required
     * profane, copyright, wrong_product, not_review, spam, customer_image, duplicate, other
     * 
     * flagComment string *required
     * 
     * contactEmail string *required
     * 
     * cookies string
     * 
     * ipAddress string
     * 
     */
    const url = 'https://writeservices.powerreviews.com/flagugc';

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataPackage),
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }
        return responseJson;

    } catch (err) {
        return { error: err, success: 'false' };
    }
};

// export const helpful = async (dataPackage) => {};

// export const recommend = async (dataPackage) => {};
