/**
 * Fetches nearest round time to half hour or full hour.
 *
 * @param {timeSlot} time slot - The time string to format.
 * @returns {string} round time nearest to half hour or full hour.
 */
const roundTimeToNearestHalfHourOrFullHour = (timeSlot) => {
    // Remove the "am" or "pm" part from the string
    let period = timeSlot.slice(-2).toLowerCase();
    let time = timeSlot.slice(0, -2);
    // Parse the time string to a Date object
    let [hours, minutes] = time.split(':').map(Number);

    if (minutes == 0 || minutes == 30) {
        return timeSlot;
    }

    // Round minutes to the nearest half hour or full hour
    if (minutes < 30) {
        minutes = 0;
    } else if (minutes <= 59) {
        minutes = 30;
    }

    // Format the rounded hours and minutes as a string
    let formattedHours = hours.toString();
    let formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}${period}`;
};

export { roundTimeToNearestHalfHourOrFullHour };
