import { getCookie, getUrlParameter, setCookie } from 'common/js/library/util';

const TDP_NAMESPACE = 'bsro-ui-tdp';

const checkQueryStrings = () => {
    const queryString = getUrlParameter(TDP_NAMESPACE) || '';

    if (queryString === 'v2' || queryString === '2') {
        setCookie(TDP_NAMESPACE, 'v2');
    } else if (queryString === 'v1' || queryString === '1') {
        setCookie(TDP_NAMESPACE, 'v1');
    }
};

export const tireDetailPageSwitch = () => {
    // enable a way to trigger switch, even if it's turned off.
    checkQueryStrings();

    // If no "bsro-ui-tdp" cookie, then default to v1.
    const version = getCookie(TDP_NAMESPACE) || 'v2';

    // Switch if v2.
    if (version === 'v1') {
        return false;
    }
    
    // Don't switch by default.
    return true;
};
    