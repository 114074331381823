import { responseError, HTTPHeaders } from './serviceUtilities.js';

export const emailSignUp = async (dataPackage, basePath = '') => {
    try {
        const url = `${basePath}/bsro/services/contact/emailsignup/`;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataPackage),
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};
