import '../scss/fifty-fifty.scss';
import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
import { viewport } from 'common/js/library/viewport';

const instanceInit = (rootEl) => {
    console.log('inside fifty-fifty-js');

    pubsub.subscribe(viewport.topics.RESIZE, (evt) => handleEvent(evt, rootEl));
    rootEl.querySelector('img').addEventListener('load', (evt) => handleEvent(evt, rootEl));
    // image load isn't always reliable, so also using window as a backup.
    window.addEventListener('load', (evt) => handleEvent(evt, rootEl));
};

/**
 * Event handler for all event listeners.
 * @memberof FiftyFifty
 * @private
 * @evt {event} event to be handled
 * @return {void}
 */
const handleEvent = (evt, rootEl) => {
    switch (evt.type) {
        case 'resize':
            console.log('on resize event');
            //update(rootEl);
            break;
        case 'load':
            console.log('on load event');
            // update(rootEl);
            break;
        default:
            throw TypeError('FiftyFifty.handleEvent: Event type not recognized.');
    }
};

/**
 * Updates the height of the container divs to allow contents to be vertically aligned
 * @memberof FiftyFifty
 * @public
 * @clearHeight {boolean} Removes height styles on container divs before measuring the module's height
 * @return {void}
 */
const update = (rootEl, clearHeight) => {
    let fiftyFiftyContainers = rootEl.querySelector('div');

    if (!clearHeight) {
        fiftyFiftyContainers.innerHeight = 'auto';
    }

    fiftyFiftyContainers.innerHeight = rootEl.innerHeight;
    rootEl.style.visibility = 'visible';
    rootEl.querySelector('.copy').classList.add('center-block');
};

component({
    selector: '.fifty-fifty',
    instanceInit,
    name: 'FiftyFifty'
});
