const getDaysLeft = (endDate) => {
    const todayDate = new Date();
    const endOfDate = new Date(endDate);
    let diffTime = endOfDate - todayDate;
    diffTime = diffTime + 1000 * 60 * 60 * 24;

    const daysLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return daysLeft;
};

export { getDaysLeft };
