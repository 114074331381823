import { VS_SET_VEHICLE, VS_SET_IS_ACCOUNT, VS_SET_ACTION } from '../actions';

const initialState = {
    vehicle: window.siteProfile.vehicles.tce || {},
    isAccount: false,
    action: ''
};

function vehicleSelectorReducer(state = initialState, action) {
    const { type, payload } = action;

    console.log('vehicleData vehicleSelectReducer:', payload);

    switch (type) {
        case VS_SET_VEHICLE:
            return Object.assign({}, state, { vehicle: payload });
        case VS_SET_IS_ACCOUNT:
            return Object.assign({}, state, { isAccount: payload });
        case VS_SET_ACTION:
            return Object.assign({}, state, { action: payload });
        default:
            return state;
    }
}

export default vehicleSelectorReducer;
