import '../scss/product-results-grid.scss';
import { setCardClickTracking, setGroupTracking } from './tracking';
import { createQuoteComplete } from './create-quote-complete';
import { filter } from './filter';
import { getTireResults } from './get-tire-results';
import { goodBetterBest } from './good-better-best';
import { chunkArray, getCookie, setCookie } from 'common/js/library/util';
import { QuoteServiceFactory } from 'common/js/library/quote-service-factory';
import { component } from 'common/js/library/component';
import { getArticleId } from 'common/components/content/react-shared/utils/getArticleId';
import { getInitSubsort } from 'common/js/util/search/getInitSubsort';
import { notifications } from 'common/js/util/notifications';
import { pubsub } from 'common/js/library/pubsub';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { topics } from 'common/js/library/topics';
import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';
import { isUserLoggedIn } from 'common/js/data/services/account/user';

const isTDPV2 = tireDetailPageSwitch();
let qService = new QuoteServiceFactory();
let sortBy = {};
let tireResultsData;
let $tireResults;
const init = (config) => {
    // arrays of product cards, which can be sorted and then redrawn
    sortBy = {
        brand: [],
        mileage: [],
        price: [],
        noPrice: []
    };
    tireResultsData = $('[data-tire-results]').data('tire-results');
};

const instanceInit = (rootEl) => {
    const psp = window.productSortPriority;
    const initSortSettingRegex = new RegExp('lowtohigh|hightolow|none');

    let $el = $(rootEl);
    let $filterBtn = $el.find('.filter-btn');
    let $closeBtn = $el.find('.close-btn');
    let $aside = $el.find('aside');
    let $tireResultContainer = $el.find('[data-tire-results]');
    $tireResults = $($tireResultContainer).attr('data-tire-results');
    console.log('tire results on init:', $tireResults);

    // create default values for global variables if they aren't defined
    window.productSortPriority = {
        active: (psp && Boolean(psp.active)) || false,
        subsort: initSortSettingRegex.test(psp && psp.subsort) ? psp.subsort : getInitSubsort(),
        criteria: (psp && psp.criteria) || {},
        priority: (psp && psp.priority) || {}
    };

    // check if a notification should be displayed
    const notification = notifications();
    if (notification) {
        const { message } = notification;
        // update the error message area and show it
        const messageEl = document.querySelector('.no-catalog-match');
        messageEl.innerText = message;
        messageEl.style.display = 'block';
    }

    $(document).on('click', '.tire-quote-btn', (evt) => {
        evt.preventDefault();

        const $target = $(evt.currentTarget);
        const $parent = $target.parents('.product');

        if (!$parent.hasClass('inactive')) {
            qService.setCreateQuoteDataByEvt(evt).createQuote();
            $parent.addClass('get-quote');
            $target.blur();
        }
    });

    $filterBtn.on('click', (evt) => {
        evt.preventDefault();
        $aside.toggleClass('active');
    });

    $closeBtn.on('click', (evt) => {
        evt.preventDefault();
        $aside.removeClass('active');
    });

    $(document).on('change', '.compare-checkbox input[type="checkbox"]', function () {
        const $nextLabel = $(this).next('label');
        // get all copies of the label
        const labelFor = $nextLabel.attr('for');
        const $labels = $el.find(`[for="${labelFor}"]`);
        $labels.toggleClass('active');

        if (this.checked == true) {
            $labels.find('.compare-uncheck').hide();
            $labels.find('.compare-checked').show();
        } else {
            $labels.find('.compare-uncheck').show();
            $labels.find('.compare-checked').hide();
        }
    });

    $(document).on('click', '.compare-btn', function (evt) {
        evt.preventDefault();

        var compare_data = [];
        var $compare_checkbox = $('.compare-checkbox input');
        var $productCompareModal = $('#product-compare-modal');
        $compare_checkbox.filter(':checked').each(() => {
            compare_data.push(this.value);
        });

        if (compare_data.length < 2) {
            $productCompareModal.addClass('error');
        } else {
            getTireResults(tireResultsData);
            $productCompareModal.removeClass('error');
            $productCompareModal
                .find('.tire-quote-btn')
                .attr('data-type', $(this).parent().closest('div').data('type'));

            var _tag = { eventName: 'tires.comparison' };
            window._satellite.setVar('searchData', _tag);
            window._satellite.track(_tag.eventName);
        }

        var elem = $(evt.currentTarget);
        var _type = elem.data().toggle;
        var $target = $(elem.data().target || elem.attr('href'));
        if (_type == 'modal') {
            $('body').addClass('coral-Modal.is-open');
            $target.before('<div class="coral-Modal-backdrop" aria-hidden="true"></div>');
            $target.show();
        }
        // create reviews
        const reviewsSummary = Array.from(document.querySelectorAll('#product-compare-modal .reviews-app'));
        pubsub.publish(topics.CREATE_REVIEWS, [reviewsSummary]);
    });

    $('input[type="range"]').change(function () {
        const $this = $(this);
        const $min = $this.attr('min');
        var val = ($this.val() - $min) / ($this.attr('max') - $min);

        updatePriceRangeBar(val, $this);
    });

    $('.filter-btn').click((evt) => {
        evt.preventDefault();
        $('.filter-container').toggle();
        $('.mobile-only-sorting').hide();
    });

    $('.sorting-btn').click((evt) => {
        evt.preventDefault();
        $('.mobile-only-sorting').toggle();
        $('.filter-btn').toggle();
        $('.filter-container').hide();
    });

    $('.custom-select-result').click(function (evt) {
        evt.stopPropagation();
        const $this = $(this);

        if ($this.hasClass('custom-selected')) {
            $('.filter-details').hide();
            $('.custom-select-result').removeClass('custom-bg custom-selected');
        } else {
            $('.data-filter .filter-details').hide();
            $('.custom-select-result').removeClass('custom-bg custom-selected');
            if ($this.hasClass('custom-select-sort')) {
                $this.addClass('custom-bg data-filter');
            } else {
                $this.addClass('custom-bg custom-selected data-filter');
            }
            $this.children().show();
        }
        if (window.navigator.userAgent.match(/iPad/i)) {
            $('body').addClass('results-grid-filter-active');
        }
    });

    $('body').click((evt) => {
        $('.filter-details').hide();
        $('.custom-select-result').removeClass('custom-bg custom-selected');

        if (window.navigator.userAgent.match(/iPad/i)) {
            $('body').removeClass('results-grid-filter-active');
        }
    });

    $('.filter-content input').on('click', function () {
        const $prev = $(this).prev();
        if ($prev.hasClass('active-filter')) {
            $prev.removeClass('active-filter');
        } else {
            $prev.addClass('active-filter');
        }
    });

    $('.clear-btn').on('click', () => {
        $('.filter-content input').attr('checked', false);
        $('.filter-content a').removeClass('active-filter');
        $('.results .product').show();
        var resultCount = $('.product:not(.gbb-card):visible').length;
        $('.number-of-results span.result-count').html(resultCount);
        resetPriceRange();
    });

    $('.done-btn').click((evt) => {
        evt.preventDefault();
        $('.filter-container').hide();
    });

    //Filter item count

    $('.brand-category li').each(function () {
        const $this = $(this);
        var curClass = $this.children('input').val();
        var brand_length = $('.' + curClass).length;
        $this.children('a').append(' <span>(' + brand_length + ')</span>');
    });

    $('.type-category li').each(function () {
        const $this = $(this);
        var curClass = $this.children('input').val();
        var brand_length = $('.' + curClass).length;
        $this.children('a').append(' <span>(' + brand_length + ')</span>');
    });

    $('.size-category .filter-details ul li').each(function () {
        const $this = $(this);
        if ($this.children('div').hasClass('custom-checkbox')) {
            var curClass = $this.find('input').val().replace('/', '-');
            $this.find('input').val(curClass);
        }
    });

    $('.mobile-only-filter .size-category li').each(function () {
        const $this = $(this);
        const $input = $this.find('input');
        const val = ($input && $input.val()) || '';
        const curClass = val.replace('/', '-');
        $this.children('input').val(curClass);
        const brand_length = $('.' + curClass).length;
        $this.children('a').append(' <span>(' + brand_length + ')</span>');
    });

    $('.stdOpt-category li').each(function () {
        const $this = $(this);
        var curClass = $this.children('input').val();
        var brand_length = $('.' + curClass).length;
        $this.children('a').append(' <span>(' + brand_length + ')</span>');
    });

    $('.matchedSet-category li').each(function () {
        const $this = $(this);
        var curClass = $this.children('input').val();
        var brand_length = $('.' + curClass).length;
        $this.children('a').append(' <span>(' + brand_length + ')</span>');
    });

    $('.mileage-category li').each(function () {
        const $this = $(this);
        var curClass = $this.children('input').val();
        var brand_length = $('.' + curClass).length;
        $this.children('a').append(' <span>(' + brand_length + ')</span>');
    });

    $(window).on('load', (evt) => setVehicleAndPricing($el));
    $el.find('a[data-article-id]').on('click', setPricingByArticleId);

    //
    pubsub.subscribe(qService.CREATE_QUOTE_COMPLETE, (data = {}) => {
        createQuoteComplete(data.tireQuoteId);
    });

    $('.tire-sizing-options .view-cta a').on('click', (evt) => {
        evt.preventDefault();

        $(evt.currentTarget).parent().toggleClass('active');
        $('.tire-sizing-options').toggleClass('stack');
    });

    $('.tire-sizing-options button').on('click', (evt) => {
        evt.preventDefault();

        const $target = $(evt.currentTarget);
        const $btns = $('.tire-sizing-options button');
        var _nfilter = $target.data().filter;

        $('input[name=radio-matchedSet-options]:checked').click();

        $btns.prop('disabled', false);
        $btns.removeClass('active');

        $target.addClass('active');
        $target.prop('disabled', 'disabled');
        $('#' + _nfilter).click();
    });

    // NOTE: items below this comment previously were at the global level.
    $('.filter-tab ul li a').click(function (evt) {
        evt.preventDefault();
        const $this = $(this);
        var filterData = $this.attr('data-filter');

        $('.filter-tab ul li a.active').removeClass('active');
        $this.addClass('active');
        $('.filter-content ul').css('display', 'none');
        $('.filter-content ul' + '.' + filterData).css('display', 'block');
    });

    $('.sort-select-1,#sort-select-2').change(function () {
        const val = $(this).val();
        $('.sort-select-1,#sort-select-2').val(val);
        sort(val);
    });

    $('.sort-select-1 li a').click(function (evt) {
        evt.preventDefault();
        const $this = $(this);

        $('.sort-select-1 li a.active').removeClass('active');
        $this.addClass('active');

        $('.mobile-only-sorting').toggle();
        $('.filter-btn').show();

        sort($this.attr('value'));
    });

    filter(() => {
        showHideLowInventoryGridHeading(); // update if separator should be visible
    });

    [...document.querySelectorAll('.tsr-card-deck .tsr-card')].forEach((card) => {
        card.addEventListener('click', (evt) => {
            const productID = [
                {
                    productID: evt.currentTarget?.dataset?.articleId,
                    brand: evt.currentTarget.querySelector('.tsr-card__brand').innerHTML
                }
            ];
            const staggeredFitment = !!evt.currentTarget?.dataset?.rearArticleId;

            if (staggeredFitment) {
                productID.push({
                    productID: evt.currentTarget?.dataset?.rearArticleId,
                    brand: evt.currentTarget.querySelector('.tsr-card__brand').innerHTML
                });
            }
            setAdobeDataLayer({ event: 'tire-detail-interaction', tdInteractionType: 'tires for you - card clicked' });

            setAdobeDataLayer({
                event: 'product.click',
                products: productID,
                staggered_fitment: staggeredFitment
            });
        });
    });
};

$('body').keydown((e) => {
    if (e.which == 27) {
        $('.error-display').hide();
    }
});

const sortInit = () => {
    $('.results .product:not(.gbb-card)').each(function () {
        const $this = $(this);

        let productName = $this.attr('data-product-name') || ''; // MB: Setting default for those elements that don't have the data attributes on them.
        productName = productName.toLowerCase();
        sortBy.brand.push({ data: productName, $el: $this });

        let priceData = $this.data('price') || $('.price span', this).attr('data-price') || '';
        if (priceData) {
            sortBy.price.push({ data: priceData, $el: $this });
        } else {
            sortBy.noPrice.push({ data: '', $el: $this });
        }

        // .mileage and this aren't where the mileage data is kept as far as I can tell, but if it's like this on another page will try to include it.
        let mileage =
            parseInt($this.find('.product-specs').attr('data-mileage')) ||
            $('.mileage', this).text().replace(',', '') ||
            0;
        sortBy.mileage.push({ data: mileage, $el: $this });
    });

    numericSort(sortBy.price, 'lowtohigh');
    numericSort(sortBy.mileage, 'hightolow');
};

/**
 * handles tire survey data sorts
 *
 * @param {string} dataAttribute - name of data attribute to search for
 * @return {void}
 */
const tireSurveySort = (dataAttribute) => {
    let noMatch = [];
    let matched = [];

    const cards = [...document.querySelectorAll('.results .product:not(.gbb-card)')];

    cards.map((card, index, arr) => {
        const attribute = card.querySelector('.product-specs').getAttribute(dataAttribute);

        console.log('tire survey sort', {
            card,
            child: card.querySelector('.product-specs'),
            dataAttribute,
            attribute
        });

        if (attribute) {
            matched.push({ data: attribute, $el: $(card) });
        } else {
            noMatch.push({ data: '', $el: $(card) });
        }
    });

    numericSort(matched, 'hightolow');
    const allCards = matched.concat(noMatch);
    sortByInventory(allCards);
};

/**
 * ???
 *
 * @return {void}
 */
const sortByPriority = () => {
    if (window.productSortPriority.subsort === 'none') {
        sortByInventory(undefined, true);
    } else {
        sortByPrice(window.productSortPriority.subsort, true);
    }
    updateSortSelect('bestfit');
};

/**
 * ???
 *
 * @return {void}
 */
const lowToHigh = () => {
    sortByPrice('lowtohigh');
};

/**
 * ???
 *
 * @return {void}
 */
const highToLow = () => {
    sortByPrice('hightolow');
};

/**
 * ???
 *
 * @return {void}
 */
const updateSortSelect = (type) => {
    let $sortSelect = $(`.sort-select-1 option[value=${type}],#sort-select-2 option[value=${type}]`);
    if ($sortSelect.length) {
        $('.sort-select-1,#sort-select-2').val(type);
    }
    // mobile
    $('.sort-select-1 li a.active').removeClass('active');
    $(`.sort-select-1 li a[value='${type}']`).addClass('active');
};

/**
 *
 * @param {string} [type] - ???
 * @param {boolean} [useSortPriority] - ???
 * @return {void}
 */
const sortByPrice = (type = 'lowtohigh', useSortPriority = false) => {
    if (!useSortPriority) {
        updateSortSelect(type);
    }
    numericSort(sortBy.price, type);
    const byPrice = sortBy.price.concat(sortBy.noPrice);
    sortByInventory(byPrice, useSortPriority);
};

/**
 * ???
 *
 * @param {Array} arr - ???
 * @param {string} [type] - ???
 * @return {void}
 */
const numericSort = (arr, type = 'lowtohigh') => {
    arr = arr.sort((a, b) => (type === 'lowtohigh' ? a.data - b.data : b.data - a.data));
};

/**
 * ???
 *
 * @param {Array} arr - ???
 * @param {string} [type] - ???
 * @return {void}
 */
const alphaSort = (arr, type = 'ascending') => {
    arr = arr.sort((a, b) =>
        type === 'ascending' ? (a.data || '').localeCompare(b.data || '') : (b.data || '').localeCompare(a.data || '')
    );
};

/**
 * ???
 *
 * @param {string} sortValue - ???
 * @return {void}
 */
const sort = (sortValue) => {
    var _sortData = { sortBy: sortValue.toLowerCase() };

    window._satellite.setVar('eventData', _sortData);
    window._satellite.track('tire.sort');

    switch (sortValue) {
        case 'bestfit':
            sortByPriority();
            break;
        case 'lowtohigh':
            lowToHigh();
            break;
        case 'hightolow':
            highToLow();
            break;
        case 'brand':
            alphaSort(sortBy.brand, 'ascending');
            sortByInventory(sortBy.brand);
            break;
        case 'mileage':
            numericSort(sortBy.mileage, 'hightolow');
            sortByInventory(sortBy.mileage);
            break;
        case 'wet':
            tireSurveySort('data-wet');
            break;
        case 'dry':
            tireSurveySort('data-dry');
            break;
        case 'comfort':
            tireSurveySort('data-comfort');
            break;
        case 'winter':
            tireSurveySort('data-snow');
            break;
        case 'tread':
            tireSurveySort('data-treadwear');
            break;
    }
};

/**
 * ???
 *
 * @return {void}
 */
const setVehicleAndPricing = ($el) => {
    setVehicle($el);

    if (window.isEcom && !$el.hasClass('product-catalog')) {
        setPricing($el);
    }

    let allCards = document.querySelectorAll('.tsr-card-deck .tsr-card');
    let showHideCards = document.querySelector('.tires-for-you .results-container > button');
    const hasSmallScreen = window.matchMedia('(max-width: 767px)').matches;

    if (allCards && allCards.length > 4) {
        if (!hasSmallScreen) {
            // Hide all cards except first 4
            allCards.forEach((card, i) => {
                if (i > 3) {
                    card.style.display = 'none';
                } else {
                    card.style.display = 'block';
                }
            });
        }

        if (showHideCards) {
            showHideCards.addEventListener('click', (e) => {
                const btn = e.currentTarget;
                //show
                if (!btn.classList.contains('open')) {
                    btn.classList.add('open');
                    btn.innerHTML = 'HIDE ALL TIRES THAT FIT';
                    allCards.forEach((card) => {
                        card.style.display = 'block';
                    });

                    setAdobeDataLayer({
                        event: 'tire-detail-cta-clicked',
                        cta_detail: 'tires for you - see all tires that fit'
                    });
                } else {
                    btn.classList.remove('open');
                    btn.innerHTML = 'SEE ALL TIRES THAT FIT';
                    allCards.forEach((card, i) => {
                        if (i > 3) {
                            card.style.display = 'none';
                        } else {
                            card.style.display = 'block';
                        }
                    });
                }
            });
        }
    } else {
        if (showHideCards) {
            showHideCards.style.display = 'none';
        }
    }
};

/**
 * ???
 *
 * @return {void}
 */
const setVehicle = ($el) => {
    var _fitment = $el.data('vehicle') || null;
    var _sizing = $el.data('sizing') || null;
    var _vehicleType = window.isEcom ? 'tce' : 'main';
    var cookieData = getCookie(`bsro.cp-${window.siteId}`);

    var cookieFitment = cookieData && cookieData.vehicles && cookieData.vehicles[_vehicleType];

    if (_fitment) {
        // only set the vehicle if it doesn't match what's in the cookie already
        if (
            !cookieFitment ||
            cookieFitment.year !== _fitment.year ||
            cookieFitment.make !== _fitment.make ||
            cookieFitment.model !== _fitment.model ||
            cookieFitment.trim !== _fitment.submodel
        ) {
            $.getJSON(
                '/bsro/services/profile/set-vehicle?vehicleType=' +
                    _vehicleType +
                    '&year=' +
                    _fitment.year +
                    '&make=' +
                    _fitment.make +
                    '&model=' +
                    _fitment.model +
                    '&trim=' +
                    _fitment.submodel
            );

            if (window.siteProfile) {
                var _sPV = window.siteProfile.vehicles[_vehicleType];

                _sPV.ymm = _fitment.year + '-' + _fitment.make + '-' + _fitment.model;
                _sPV.year = _fitment.year;
                _sPV.make = _fitment.make;
                _sPV.model = _fitment.model;
                _sPV.trim = _fitment.submodel;
                _sPV.tpms = _fitment.tpms;
            }
        }
    }

    if (_sizing) {
        $.getJSON(
            '/bsro/services/tires/set-tire-size?crossSection=' +
                _sizing.crossSection +
                '&aspectRatio=' +
                _sizing.aspectRatio +
                '&rimSize=' +
                _sizing.rimSize
        );
    }
};

/**
 * Only run once
 *
 * @return {void}
 */
const setPricing = function ($el) {
    var _articleIds = $el.data().productArticleIds;
    var _data = {
        storeNumber: window.siteProfile.location.storeNumber,
        articleIDs: _articleIds
    };

    if (_articleIds) {
        $.getJSON('/bsro/services/store/pricing-details', _data)
            .done((data) => {
                var _pricing = data.data.products;
                var _pricingObj = {};

                if (_pricing) {
                    _pricing.map((product, i) => {
                        _pricingObj[product.code] = product;
                    });

                    var $products = $('.results .product:not(.gbb-card), .tsr-card');

                    $products.each(function () {
                        var $this = $(this);
                        const pricingRoot = $this.find('.tsr-card__pricing');

                        var _elData = $this.data();
                        var _articleId = _elData.articleId;
                        var _rearArticleId = _elData.rearArticleId;

                        var _pData = _pricingObj[_articleId];
                        var _rData = _pricingObj[_rearArticleId];

                        if (_pData) {
                            // console.log('_pData', _pData);
                            // console.log('_rData', _rData);

                            const price = _pData?.price?.value || 0;
                            const priceFormatted = _pData?.price?.formattedValue || 'N/A';
                            const rearPrice = _rData?.price?.value || 0;
                            const rearPriceFormatted = _rData?.price?.formattedValue || 'N/A';
                            const promo = _pData?.potentialPromotions || [];
                            const rearPromo = _rData?.potentialPromotions || [];
                            const rebate = _pData?.rebates || [];
                            const rearRebate = _rData?.rebates || [];

                            const root = pricingRoot[0];
                            if (root) {
                                const data = {
                                    price,
                                    priceFormatted,
                                    rearPrice,
                                    rearPriceFormatted,
                                    promo: JSON.stringify(promo),
                                    rearPromo: JSON.stringify(rearPromo),
                                    rebate: JSON.stringify(rebate),
                                    rearRebate: JSON.stringify(rearRebate)
                                };
                                Object.assign(root.dataset, data);
                            }

                            if (!price) {
                                $this.find('.no-price button.store-phone').on('click', (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const $this = e.currentTarget;
                                    const href = $this.dataset.href;
                                    window.open(href);
                                });
                                $this.find('.no-price').show();
                            }
                        } else {
                            $this.addClass('without-price');
                            $this.find('.app-global.quote-annex-cta .btn').addClass('disabled').disbled = true;
                        }
                    });
                    isUserLoggedIn().then((isLoggedIn) => {
                        const cardPrices = Array.from(document.querySelectorAll('.tsr-card__pricing'));
                        if (cardPrices.length > 0) {
                            pubsub.publish(topics.CREATE_PRODUCTPRICE, [cardPrices, isLoggedIn]);
                        }
                    });

                    if ($el.hasClass('catitem-match')) {
                        if (window._catItem) {
                            var quoteBtn = $el.find("[data-product-name='" + window._catItem + "'] .tire-quote-btn")[0];

                            if (quoteBtn) {
                                quoteBtn.click();
                            }
                        }
                    }
                } else {
                    //console.log("+++ PRICING DATA RETURNED NULL +++")
                }

                // set the inventory for all chunks, then run the callback function
                setInventoryByChunks($el, 10, (invCount) => {
                    const gbbTarget = $el.get(0).querySelector('.container');
                    const gbbProductPool = [...$el.get(0).querySelectorAll('.results .product')];
                    goodBetterBest(gbbTarget, gbbProductPool);

                    // display cards
                    $('.product').removeClass('inactive');

                    sortInit();
                    setGroupTracking($el.get(0));
                    setCardClickTracking($el.get(0));

                    if (window.productSortPriority.active) {
                        sortByPriority();
                    } else {
                        // remove best fit from select options
                        let type = 'bestfit';
                        let $sortSelect = $(
                            `.sort-select-1 option[value=${type}],#sort-select-2 option[value=${type}]`
                        );
                        $sortSelect.remove();
                        $(`.sort-select-1 li a[value='${type}']`).remove();

                        if (getInitSubsort() == 'hightolow') {
                            highToLow();
                        } else {
                            lowToHigh();
                        }
                    }
                });

                window.setTimeout(function () {
                    document.querySelectorAll('#more_products_carous .product-name a').forEach((anchor) => {
                        anchor.addEventListener('click', (evt) => {
                            let recommendedProductId = evt.currentTarget.dataset.articleId,
                                vehicleData = window?.siteProfile?.vehicles?.tce || {},
                                vehicle = {
                                    year: vehicleData.year,
                                    make: vehicleData.make,
                                    model: vehicleData.model,
                                    subModel: vehicleData.trim
                                };

                            setAdobeDataLayer({
                                event: 'tires.recommendations-click',
                                recommended_location: 'tire_details',
                                products: getArticleId(),
                                clicked_products: [{ productID: recommendedProductId }],
                                vehicle
                            });
                        });
                    });

                    document.querySelectorAll('#more_products_carous .quote-annex-cta button').forEach((anchor) => {
                        anchor.addEventListener('click', (evt) => {
                            let recommendedProductId = evt.currentTarget.closest('.result-card').dataset.articleId;
                            setAdobeDataLayer({
                                event: 'tires.quote.start',
                                quote_location: 'tire_details',
                                products: [{ productID: recommendedProductId }]
                            });
                        });
                    });
                }, 1000);
            })
            .fail((data) => {
                //console.log("++++++++ PRICING CALL FAILED +++++++")
            });
    }
};

/**
 * ???
 *
 * @param {number} size - number of items to process
 * @param {function} [callback] - function to run when all chunks are complete
 * @return {void}
 */
const setInventoryByChunks = ($el, size, callback) => {
    var _articleIds = $el.data().productArticleIds.toString().split(',');
    var _chunkArr = chunkArray(_articleIds, size || 3);
    let invCount = 0; // total number of inventory results found
    let chunksComplete = 0; // total number of inventory responses received.
    let hasCalledBack = false; // make sure callback is only called once.

    _chunkArr.map((chunk, i, arr) => {
        setInventory($el, chunk.toString(), (success, count) => {
            invCount += count;
            chunksComplete++;
            if (arr.length <= chunksComplete && !hasCalledBack) {
                if (callback) callback(invCount);
                hasCalledBack = true;
            } //supplier = IWS
        });
    });
};

/**
 * ???
 *
 * @param {Array} articleIds - ???
 * @param {function} chunkLoadComplete - callback. provide if response was success and the number of inventory results found
 * @return {void}
 */
const setInventory = ($el, articleIds, chunkLoadComplete) => {
    var _articleIds = articleIds || $el.data().productArticleIds;
    var _data = {
        storeNumber: window.siteProfile.location.storeNumber,
        articleIDs: _articleIds
    };

    const onSuccess = (data) => {
        // data.data.tires is old structure, if it's there then use
        var _inventory = (data.data && data.data.tires) || data.data;

        console.log('inventory data response', { _inventory });

        if (_inventory) {
            var _i = 0;
            var _ilen = _inventory.length;
            for (_i; _i < _ilen; _i++) {
                var _iData = _inventory[_i];
                var _quantity = parseInt(_iData.quantity);
                var _articleId = parseInt(_iData.part_number);
                var _storeQuantity = parseInt(_iData.q_local);
                var _sisterStoreQuantity = parseInt(_iData.q_sister);
                var $productContainer = $('div[data-article-id=' + _articleId + ']');
                var $quoteBtn = $productContainer.find('.tire-quote-btn');

                $quoteBtn.attr('data-inventory-id', _iData.id);
                $quoteBtn.attr('data-inventory-quantity', _quantity);

                if (_quantity >= 2) {
                    if (_storeQuantity >= 4) {
                        $productContainer
                            .find('.availability .icon-phone')
                            .removeClass('icon-phone')
                            .addClass('icon-thumb-up');
                        $productContainer.find('.availability .text').text('In Stock');
                    } else if (_quantity >= 4) {
                        $productContainer
                            .find('.availability .icon-phone')
                            .removeClass('icon-phone')
                            .addClass('icon-thumb-up');
                        $productContainer.find('.availability .text').text('Usually Available Same Day');
                    }

                    tireResultsData[_articleId].tire['tireInventoryId'] = _iData.id;
                    tireResultsData[_articleId].tire['tireInventoryQuantity'] = _quantity;
                }

                var _pathname = window.location.href;

                if (_pathname.indexOf('#inventory') != -1) {
                    if (_sisterStoreQuantity > 0) {
                        var sisterStoreOnHand = 'Sister store on-hand: ' + _sisterStoreQuantity;
                        $productContainer.find('.cta .sister_store_on_hand').text(sisterStoreOnHand);
                    }
                    if (_storeQuantity > 0) {
                        var storeOnHand = 'Store on-hand: ' + _storeQuantity;
                        $productContainer.find('.cta .store_on_hand').text(storeOnHand);
                    }

                    $productContainer.find('.store-on-hand').show();
                }
            }
        } else {
            //console.log("+++ INVENTORY DATA RETURNED NULL +++", _data)
        }
        chunkLoadComplete(true, _inventory.length || 0);
    };

    $.ajax({
        url: '/bsro/services/store/inventory',
        dataType: 'json',
        data: _data,
        success: onSuccess,
        error: () => chunkLoadComplete(false, 0),
        timeout: 10000
    });
};

const setPricingByArticleId = (evt) => {
    evt.preventDefault();

    const $target = $(evt.currentTarget);
    const $copyContainer = $target.parents('.copy-container');

    var _href = $target.attr('href');
    var _price = $copyContainer.find('.price span:eq(0)').text().substr(1);
    var _articleId = $target.data().articleId;

    if (window.isEcom) {
        var _tmpPrice = $copyContainer.find('span.discounted-price').text();

        if (_tmpPrice != '00.00') {
            _price = _tmpPrice;
        } else {
            _price = $copyContainer.find('span.retail-price').text();
        }
    }

    _price = _price.replace(/(#)+/g, '');

    if (_price == '') {
        _price = -1;
    }

    setCookie(_articleId, _price, 1);
    window.location.href = _href;
};

/**
 * To filter Products by price.
 *
 * @param {string} [sortBy] - expected values are 'lowtohigh' or 'hightolow'
 * @return {Array}
 */
const getProductsByPrice = (sortBy = 'lowtohigh') => {
    let $products = $('.results .product[data-price]:not(.gbb-card)');
    let products = $products.toArray();

    products.sort((a, b) => parseFloat(a.dataset.price) - parseFloat(b.dataset.price));

    if (sortBy == 'hightolow') {
        products.reverse();
    }

    return products;
};

/**
 * get Priority Array
 *
 * @return {Array}
 */
const getPriorityArray = () => {
    const {
        lessAspectRatio = undefined,
        lessAge = undefined,
        make = undefined,
        matrix = undefined
    } = window.productSortPriority.criteria;

    const priority = window.productSortPriority.priority;
    let arr = (Array.isArray(priority.default) && priority.default) || [];

    // if we don't have any of the parameters to run the matrix, go with default
    if (isNaN(lessAspectRatio) || isNaN(lessAge) || !(make instanceof RegExp) || !Array.isArray(matrix)) {
        console.log('getPriorityArray - parameters missing, defaulting to empty array:', {
            aspectRatio: isNaN(lessAspectRatio),
            age: isNaN(lessAge),
            make: !(make instanceof RegExp),
            matrix: !Array.isArray(matrix)
        });
        return arr;
    }

    const tireSizeRegex = /tireSize(.*?)\/(.*?)R/gm;
    const aspectRatioRegex = /\/(.*?)R/;
    const tireSizes = $tireResults.match(tireSizeRegex);
    let isLessAspectRatio = 1; // default greater than 50. index 1 is larger aspect ratio
    let aspectRatios = [];
    tireSizes.map((tireSize) => {
        aspectRatios.push(aspectRatioRegex.exec(tireSize)[1]);
    });
    console.log('aspect ratios:', aspectRatios);
    aspectRatios.map((aspectRatio) => {
        if (aspectRatio <= lessAspectRatio) {
            isLessAspectRatio = 0; // index 0 is smaller aspect ratio
        }
    });

    const tce = (window.siteProfile && window.siteProfile.vehicles && window.siteProfile.vehicles.tce) || {};
    const currentYear = new Date().getFullYear();
    const vehicleYear = tce.year || currentYear - 12; // default to average age of car
    const vehicleMake = tce.make || 'no make found'; // default to non-lux make
    const isLessAge = currentYear - vehicleYear < lessAge ? 0 : 1; // 0 index = newer car, 1 index = older car
    const isLux = make.test(vehicleMake) ? 0 : 1; // 0 index = lux car, 1 index = non-lux car
    const priorityName = matrix[isLessAspectRatio][isLessAge][isLux];

    console.log('sort settings:', {
        isLessAspectRatio,
        isLessAge,
        isLux,
        priorityName
    });

    arr = priority[priorityName] || arr;

    return arr;
};

/**
 * ???
 *
 * @param {jQuery} $products - ???
 * @param {boolean} [reversePriority] - ???
 * @return {jQuery}
 */
const getProductsSortedByPriority = ($products, reversePriority = true) => {
    if ($products) {
        let products = $products.toArray();
        let priorities = getPriorityArray().concat();
        if (reversePriority) priorities.reverse();

        priorities.map((regex) => {
            if (regex instanceof RegExp) {
                let foundProducts = [];
                let otherProducts = [];

                products.map((el, index) => {
                    let productName = el && el.dataset && el.dataset.productName;
                    let hasMatch = regex.test(productName);

                    if (hasMatch) {
                        foundProducts.push(el);
                    } else {
                        otherProducts.push(el);
                    }
                });

                products = foundProducts.concat(otherProducts);
            }
        });

        $products = $(products);
    }

    return $products;
};

/**
 * ???
 *
 * @param {boolean} useSortPriority - ???
 * @return {Object}
 */
const getProductsGroupedByInventory = (useSortPriority = false) => {
    let $products = $('.results .product:not(.gbb-card)');
    let $productsWithInventory = $();
    let $productsLowInventory = $();

    if (useSortPriority) {
        $products = getProductsSortedByPriority($products);
    }

    $products.each((index) => {
        const $product = $($products[index]);
        const thumbsUp = $product.find('.icon-thumb-up');

        if (thumbsUp && thumbsUp.length > 0) {
            $productsWithInventory = $productsWithInventory.add($products[index]);
        } else {
            $productsLowInventory = $productsLowInventory.add($products[index]);
        }
    });

    return {
        $products,
        $productsWithInventory,
        $productsLowInventory
    };
};

/**
 * used on page load and in combination with "sort by"
 *
 * @param {Array} customSort - ???
 * @param {boolean} useSortPriority - ???
 * @return {jQuery}
 */
const sortByInventory = (customSort, useSortPriority = false) => {
    const { $products, $productsWithInventory, $productsLowInventory } = getProductsGroupedByInventory();
    let withInventory = [];
    let lowInventory = [];
    let $withInventory = $();
    let $lowInventory = $();

    console.log('sort by inventory', { customSort });

    // merge the inventory sort with the custom sort
    if (customSort) {
        customSort.map((item) => {
            const $el = item.$el || $();
            const article = $el.data('article-id');
            $productsWithInventory.map((index, itemB) => {
                const articleB = $(itemB).data('article-id');
                if (article === articleB) {
                    withInventory.push($el[0]);
                }
            });

            $productsLowInventory.map((index, itemB) => {
                const articleB = $(itemB).data('article-id');
                if (article === articleB) {
                    lowInventory.push($el[0]);
                }
            });
        });

        $withInventory = $(withInventory);
        $lowInventory = $(lowInventory);
    } else {
        $withInventory = $productsWithInventory;
        $lowInventory = $productsLowInventory;
    }

    // apply sort priority if needed
    if (useSortPriority) {
        $withInventory = getProductsSortedByPriority($withInventory);
        $lowInventory = getProductsSortedByPriority($lowInventory);
    }

    clearResults();

    if ($withInventory.length > 0) {
        $('.results').append('<div class="in-stock-header"></div>');
        $withInventory.addClass('in-stock-card');
        $('.results').append($withInventory);
    }
    if ($lowInventory.length > 0) {
        $('.results').append('<div class="out-stock-header"></div>');
        $lowInventory.addClass('call-card');
        $('.results').append($lowInventory);
    }

    let inStockCount = $withInventory.filter(':visible');
    let outStockCount = $lowInventory.filter(':visible');

    setGridHeading(inStockCount.length, outStockCount.length);
    pubsub.subscribe(topics.VALIDATE_SEARCH_FILTERS, setGridHeading);

    return $products;
};

/**
 * ???
 *
 * @param {int} inStockInventory - ???
 * @param {int} outStockInventory - ???
 * @return {void}
 */
const setGridHeading = (inStockInventory, outStockInventory) => {
    removeInventoryGridHeading();
    removeLowInventoryGridHeading();

    if (inStockInventory > 0) addInventoryGridHeading();
    if (outStockInventory >= 1 && inStockInventory >= 1) addLowInventoryGridHeading();
    if (outStockInventory >= 1 && inStockInventory < 1) addJustLowInventoryGridHeading();
};

/**
 * ???
 *
 * @return {void}
 */
const clearResults = () => {
    $('.in-stock-header').remove();
    $('.out-stock-header').remove();
    removeInventoryGridHeading();
    removeLowInventoryGridHeading(); // sortByInventory may be called multiple times. the separator must be removed and reappended each time.
    $('.results .product:not(.gbb-card)').remove();
};

/**
 * ???
 *
 * @return {void}
 */
const showHideLowInventoryGridHeading = () => {
    const { $productsLowInventory } = getProductsGroupedByInventory();
    const hasVisibleCard = Boolean($productsLowInventory.find(':visible').length > 0);
    let $invSep = $('.results').find('.low-inventory-grid-heading');

    $invSep && $invSep.length > 0 && $invSep.toggle(hasVisibleCard);
};

/**
 * ???
 *
 * @return {void}
 */
const addInventoryGridHeading = () => {
    const txt = window.inventoryGridHeading || 'These tires fit your vehicle and are in stock at a store near you:';
    $('.in-stock-header').append('<div class="inventory-grid-heading"><h4>' + txt + '</h4></div>');
};

/**
 * ???
 *
 * @return {void}
 */
const addLowInventoryGridHeading = () => {
    const txt =
        window.lowInventoryGridHeading ||
        'These tires also fit your vehicle, but call your local store to check on availability:';
    $('.out-stock-header').append('<div class="low-inventory-grid-heading"><h4>' + txt + '</h4></div>');
};

/**
 * ???
 *
 * @return {void}
 */
const addJustLowInventoryGridHeading = () => {
    const txt =
        window.lowInventoryGridHeading ||
        'These tires fit your vehicle, but call your local store to check on availability:';
    $('.out-stock-header').append('<div class="low-inventory-grid-heading"><h4>' + txt + '</h4></div>');
};

/**
 * ???
 *
 * @return {void}
 */
const removeInventoryGridHeading = () => {
    $('.results').find('.inventory-grid-heading').remove();
};

/**
 * ???
 *
 * @return {void}
 */
const removeLowInventoryGridHeading = function () {
    $('.results').find('.low-inventory-grid-heading').remove();
};

/**
 * ???
 *
 * @param {number} val - ???
 * @param {jQuery} [$target] - ???
 * @return {void}
 */
const updatePriceRangeBar = (val, $target = $('.input-range.data-price-range')) => {
    $target.css(
        'background-image',
        `-webkit-gradient(linear, left top, right top, color-stop(${val}, #d81e05), color-stop(${val}, #666666))`
    );
};

/**
 * ???
 *
 * @return {void}
 */
const resetPriceRange = () => {
    let $priceRangeInput = $('.input-range.data-price-range');
    let $price = $('.price-range .price');

    if ($priceRangeInput.length > 0) {
        $priceRangeInput.val(0);
        updatePriceRangeBar(0);
        $price.text('$0');
    }
};

component({
    selector: '[data-product-results-grid]:not(.product-results-grid--v2)',
    init,
    instanceInit,
    name: 'product-results-grid'
});
