export const getSiteProfile = () => {
    return window.siteProfile;
};

export const getSiteProfileVehicle = () => {
    const siteProfile = getSiteProfile();
    const vehicle = (siteProfile && siteProfile.vehicles) || {};
    const tce = vehicle.tce || false;
    const main = vehicle.main || false;

    return tce || main || false;
};

export const getSiteProfileTires = () => {
    const siteProfile = getSiteProfile();
    const tires = (siteProfile && siteProfile.tires) || {};
    const tce = tires.tce || false;
    const main = tires.main || false;

    return tce || main || false;
};

export const setSiteProfileVehicle = (vehicle, type) => {
    const { year, make, model, trim, tpms } = vehicle;
    window.siteProfile.vehicles[type] = {
        ymm: `${year}-${make}-${model}`,
        year,
        make,
        model,
        trim,
        tpms
    };
};

export const hasSiteProfileVehicle = () => {
    const vehicle = getSiteProfileVehicle();

    return Boolean(vehicle && vehicle.ymm && vehicle.ymm != '');
};

export const hasSiteProfileTires = () => {
    const tires = getSiteProfileTires();

    return Boolean(tires && tires.tireSize);
};
