import { isString } from 'common/js/library/util';
import { satelliteSetVar, satelliteTrack } from 'common/js/data/services/analytics/satellite';
import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
import { getEcid } from 'common/js/library/get-ecid';

let _scroll;
let _scrollTo;
let _currentStep;
let stepsProgress;
let pathName;
let queryParam;
let _stepsLen;
let EVENTS;
const init = (_config) => {
    _scroll = true;
    EVENTS = {
        STEPS_PREVIOUS: 'steps-previous',
        STEPS_NEXT: 'steps-next',
        STEPS_GO_TO: 'steps-go-to'
    };
};

const instanceInit = (rootEl) => {
    console.log('inside steps-list.js');
    const steps = rootEl.querySelectorAll('.step');
    stepsProgress = rootEl.querySelectorAll('.steps.list ul li:not(.line)');
    _stepsLen = steps.length;
    _currentStep = (rootEl.dataset && rootEl.dataset.stepsListStart) || 0;
    _scrollTo = rootEl.querySelector('.steps.list')?.getBoundingClientRect()?.top + window.pageYOffset;

    pathName = rootEl.getAttribute('data-base-url');
    queryParam = window.location.search;

    pubsub.subscribe(EVENTS.STEPS_PREVIOUS, (evt) => previous(evt, rootEl, steps));
    pubsub.subscribe(EVENTS.STEPS_NEXT, (evt) => next(evt, rootEl, steps));
    pubsub.subscribe(EVENTS.STEPS_GO_TO, (evt) => goTo(evt, rootEl, steps));

    steps.forEach(function (step, idx) {
        step.dataset.stepIndex = idx;
    });

    window.addEventListener('load', () => componentInit(rootEl, { EVENTS, _currentStep, steps }));

    window.onpopstate = (e) => (e.state !== null ? goTo(e.state, rootEl, steps) : goTo(0, rootEl, steps));
};

const componentInit = (rootEl, props) => {
    const { EVENTS, _currentStep, steps } = props;

    // global step list method apis
    window.stepsList = {
        enableHistory: false,
        set goTo(step) {
            pubsub.publish(EVENTS.STEPS_GO_TO, [step]);
        },
        get next() {
            pubsub.publish(EVENTS.STEPS_NEXT);
            return _currentStep;
        },
        get previous() {
            pubsub.publish(EVENTS.STEPS_PREVIOUS);
            return _currentStep;
        },
        get start() {
            // start at the first step item
            window.stepsList.goTo = 0;
            return _currentStep;
        },
        get finish() {
            // end at the last step item
            window.stepsList.goTo = _stepsLen;
            return _currentStep;
        },
        get currentStep() {
            if (console.info) {
                console.info('current step element index within step collection');
            }
            console.log('currentStep:', _currentStep);
            return _currentStep;
        },
        set scroll(scroll) {
            _scroll = scroll;
        },
        set scrollTo(position) {
            _scrollTo = position;
        }
    };

    steps.forEach((step) => (step.style.display = 'none'));
    goTo(_currentStep, rootEl, steps);

    rootEl.classList.remove('hide-steps');

    if (rootEl.dataset.enableHistory === 'true') {
        window.stepsList.enableHistory = true;
    }

    const nextBtns = rootEl.querySelectorAll('[data-next]');
    const prevBtns = rootEl.querySelectorAll('[data-previous]');
    const stepBtns = rootEl.querySelectorAll('[data-step-to]');

    nextBtns.forEach((btn) => {
        btn.addEventListener('click', (evt) => {
            next(evt, rootEl, steps);
        });
    });

    prevBtns.forEach((btn) => {
        btn.addEventListener('click', (evt) => {
            previous(evt, rootEl, steps);
        });
    });

    stepBtns.forEach((btn) => {
        btn.addEventListener('click', (evt) => {
            stepHandler(evt, steps);
        });
    });
};

const stepHandler = (evt, steps) => {
    const el = evt.currentTarget;
    const stepTo = el.dataset.stepTo;

    const data = el.dataset;

    console.log('Steps List stepHandler evt:', evt);
    console.log('Steps List stepHandler el:', el, 'dataset stepTo:', el.dataset);
    console.log('Steps List stepHandler stepTo:', stepTo);
    console.log('Steps List stepHandler data:', data);

    if (data && data.tracking && data.tracking.eventName) {
        if (data.tracking.data) {
            satelliteSetVar('event_data', data.tracking.data);
        }

        satelliteTrack(data.tracking.eventName);

        console.log('tracking:', data.tracking);
    }

    goTo(stepTo, el, steps, true);
};

const previous = (evt, rootEl, gSteps) => {
    if (evt) {
        evt.preventDefault();
    }

    var steps = gSteps;
    var evtEl = evt ? evt.currentTarget : null;
    var data = evtEl ? evtEl.dataset : null;

    steps.forEach((step) => {
        step.classList.remove('active');
        step.style.display = 'none';
    });

    _currentStep--;

    if (_currentStep <= 0) {
        _currentStep = 0;
    }

    var el = steps[_currentStep];
    var prevSteps = [];
    var nextSteps = [];

    for (var i = 0; i < steps.length; i++) {
        if (i < _currentStep) {
            prevSteps.push(steps[i]);
        } else if (i > _currentStep) {
            nextSteps.push(steps[i]);
        }
    }

    prevSteps.forEach((step) => {
        step.classList.remove('active');
        step.classList.add('activated');
    });

    nextSteps.forEach((step) => {
        step.classList.remove('active');
        step.classList.remove('activated');
    });

    el.classList.add('active');
    el.classList.remove('activated');
    el.style.display = '';

    stepsListProgress(rootEl, steps);
    scrollTo();

    updateHistory(el.dataset.urlKey);

    console.log('Steps previous el:', el);
    console.log('Steps NEXT prevSteps:', prevSteps);

    if (data && data.tracking && data.tracking.eventName) {
        if (data.tracking.data) {
            satelliteSetVar('event_data', data.tracking.data);
        }

        satelliteTrack(data.tracking.eventName);

        console.log('tracking:', data.tracking);
    }
};

const next = (evt, rootEl, gSteps) => {
    if (evt) {
        evt.preventDefault();
    }

    const steps = gSteps;
    const evtEl = evt ? evt.currentTarget : null;
    const data = evtEl ? evtEl.dataset : null;
    const ecid = getEcid();

    if (ecid) {
        window.siteProfile.vid = ecid;
    }

    for (let i = 0; i < steps.length; i++) {
        steps[i].classList.remove('active');
        steps[i].style.display = 'none';
    }

    _currentStep++;

    if (_currentStep >= steps.length - 1) {
        _currentStep = steps.length - 1;
    }

    const el = steps[_currentStep];
    const prevSteps = [];
    const nextSteps = [];

    let prev = el.previousElementSibling;
    while (prev && prev.classList.contains('step')) {
        prevSteps.push(prev);
        prev.classList.remove('active');
        prev.classList.add('activated');
        prev = prev.previousElementSibling;
    }

    let next = el.nextElementSibling;
    while (next && next.classList.contains('step')) {
        nextSteps.push(next);
        next.classList.remove('active');
        next.classList.remove('activated');
        next = next.nextElementSibling;
    }

    el.classList.add('active');
    el.classList.remove('activated');
    el.style.display = '';

    updateHistory(el.dataset.urlKey);

    stepsListProgress(rootEl, steps);
    scrollTo();

    console.log('Steps NEXT el:', el);
    console.log('Steps NEXT prevSteps:', prevSteps);

    if (data && data.tracking && data.tracking.eventName) {
        if (data.tracking.data) {
            satelliteSetVar('event_data', data.tracking.data);
        }

        satelliteTrack(data.tracking.eventName);

        console.log('tracking:', data.tracking);
    }
};

const goTo = (step, rootEl, gSteps, isClick = false) => {
    const steps = gSteps;
    let _step = parseInt(step) || step;
    let el = null;

    if (typeof _step === 'string') {
        _step = _step === 'time' ? 'date-store' : _step;
        el = Array.from(steps).filter(function (step) {
            return (
                (step.classList.contains(_step.split('.')[0]) && step.classList.contains(_step.split('.')[1])) ||
                step.classList.contains(_step)
            );
        });
        el = el[0];

        if (el) {
            if (isClick) {
                const historyStep = el.dataset.stepIndex;
                updateHistory(historyStep);
            }

            _currentStep = el.dataset.stepIndex;
            console.log('goTo() isString currentStep:', _step, 'el:', el);
        }
    }

    if (typeof _step === 'number') {
        _currentStep = _step - 1;

        if (_currentStep <= 0) {
            _currentStep = 0;
        }

        if (_currentStep >= _stepsLen) {
            _currentStep = _stepsLen - 1;
        }

        el = steps[_currentStep];

        console.log('goTo() isNumber _step:', _step, 'el:', el);
    }

    if (el) {
        for (let i = 0; i < steps.length; i++) {
            steps[i].classList.remove('active');
            steps[i].style.display = 'none';
        }

        el.classList.add('active');
        el.classList.remove('activated');
        el.style.display = '';

        for (
            let prev = el.previousElementSibling;
            prev && prev.classList.contains('step');
            prev = prev.previousElementSibling
        ) {
            prev.classList.remove('active');
            prev.classList.add('activated');
        }

        for (
            let next = el.nextElementSibling;
            next && next.classList.contains('step');
            next = next.nextElementSibling
        ) {
            next.classList.remove('active');
            next.classList.remove('activated');
        }

        stepsListProgress(rootEl, steps);
        scrollTo();

        console.log('Step GOTO el:', el);
        console.log('Step GOTO currentStep:', _currentStep);
    }
};

const stepsListProgress = (rootEl, steps) => {
    const progressStep = stepsProgress[_currentStep];

    if (progressStep) {
        progressStep.classList.add('active');
        progressStep.classList.remove('activated');
        const prevSteps = prevAll(progressStep);
        const nextSteps = nextAll(progressStep);

        prevSteps.forEach((prevStep) => {
            prevStep.classList.remove('active');
            prevStep.classList.add('activated');
        });

        nextSteps.forEach((nextStep) => {
            nextStep.classList.remove('active');
            nextStep.classList.remove('activated');
        });
    }

    if (_currentStep === steps.length - 1) {
        rootEl.classList.add('finish');
    }
};

const prevAll = (element) => {
    var siblings = [];
    var prevSibling = element.previousSibling;
    while (prevSibling) {
        if (prevSibling.nodeType === 1) {
            siblings.push(prevSibling);
        }
        prevSibling = prevSibling.previousSibling;
    }
    return siblings;
};

const nextAll = (element) => {
    var siblings = [];
    var nextSibling = element.nextSibling;
    while (nextSibling) {
        if (nextSibling.nodeType === 1) {
            siblings.push(nextSibling);
        }
        nextSibling = nextSibling.nextSibling;
    }
    return siblings;
};

const scrollTo = (position) => {
    var _position = position || _scrollTo;

    if (_scroll) {
        window.scrollTo({
            top: _position,
            left: 0,
            behavior: 'smooth'
        });
    }
};

const updateHistory = (urlKey) => {
    if (window.stepsList.enableHistory) {
        if (isString(urlKey)) {
            const newUrl = `${pathName}${urlKey}${queryParam}`;
            history.pushState(urlKey, null, newUrl);
            return;
        }

        history.go(-urlKey);
    }
};

component({
    init,
    instanceInit,
    name: 'StepsList',
    selector: '[data-steps-list]'
});
