/**
 *
 * @param {string} timestamp - in the format 2020-12-03T15:00:00-06:00
 * @returns {string} returns reformated time in this format 2020-12-03 15:00
 */

const timestampToCustomDateTime = (timestamp) => {
    if (!timestamp) return null;
    // split at T and second colon
    const timestampSplit = timestamp.split('T');
    const date = timestampSplit[0];
    const timeSplit = timestampSplit[1].split(':');
    const time = timeSplit[0] + ':' + timeSplit[1];
    const datetime = date + ' ' + time;

    return datetime;
};

export { timestampToCustomDateTime };
