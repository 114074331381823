// power reviews config
export const prConfig = () => {
    const isDev = window.location.hostname.includes('ix-dev') || false;
    let config = null;

    if (window.prConfig) {
        config = isDev ? { isProd: false, ...window.prConfig.test } : window.prConfig;
    }

    return config;
};
