// META ACTIONS
export const META_SET_CLOSEST_STORE_LOCATION = 'META_SET_CLOSEST_STORE_LOCATION';
export const META_SET_CURRENT_ZIP = 'META_SET_CURRENT_ZIP';
export const META_SET_WINDOW_SIZE = 'META_SET_WINDOW_SIZE';

// ACCOUNT ACTIONS
export const ACCOUNT_SET_FULL_PROFILE = 'ACCOUNT_SET_FULL_PROFILE';
export const ACCOUNT_LOGIN_LOADING = 'ACCOUNT_LOGIN_LOADING';
export const ACCOUNT_LOGIN_USER = 'ACCOUNT_LOGIN_USER';
export const ACCOUNT_LOGOUT_USER = 'ACCOUNT_LOGOUT_USER';
export const ACCOUNT_REMOVE_VEHICLE = 'ACCOUNT_REMOVE_VEHICLE';
export const ACCOUNT_SET_VEHICLES = 'ACCOUNT_SET_VEHICLES';

// CART ACTIONS
export const ECOMM_CART_EXPIRED = 'ECOMM_CART_EXPIRED';
export const ECOMM_REMOVE_CART = 'ECOMM_REMOVE_CART';
export const ECOMM_MERGE_CART = 'ECOMM_MERGE_CART';
export const ECOMM_SET_QUOTE = 'ECOMM_SET_QUOTE';
export const ECOMM_SET_FORCE_NEW_QUOTE = 'ECOMM_SET_FORCE_NEW_QUOTE';
export const ECOMM_SET_CART = 'ECOMM_SET_CART';
export const ECOMM_SET_CART_STATE = 'ECOMM_SET_CART_STATE';
export const ECOMM_SET_CART_RESET_COUNTER = 'ECOMM_SET_CART_RESET_COUNTER';
export const ECOMM_SET_ADD_ONS = 'ECOMM_SET_ADD_ONS';
export const ECOMM_SET_ORDER = 'ECOMM_SET_ORDER';
export const ECOMM_SET_SERVICE_NAMES_LIST = 'ECOMM_SET_SERVICE_NAMES_LIST';

// TIRE DETAIL ACTIONS
export const ECOMM_SET_TIRE_DETAIL_VIEW_STATE = 'ECOMM_SET_TIRE_DETAIL_VIEW_STATE';
export const ECOMM_SET_TIRE_DETAIL_QUOTE_VIEW_STATE = 'ECOMM_SET_TIRE_DETAIL_QUOTE_VIEW_STATE';
export const ECOMM_SET_TIRE_DETAIL_BUTTON_STATE = 'ECOMM_SET_TIRE_DETAIL_BUTTON_STATE';
export const ECOMM_SET_TIRE_DETAIL_BUTTON_LABEL = 'ECOMM_SET_TIRE_DETAIL_BUTTON_LABEL';
export const ECOMM_SET_TIRE_DETAIL_IS_IN_CART = 'ECOMM_SET_TIRE_DETAIL_IS_IN_CART';
export const ECOMM_SET_TIRE_DETAIL_IS_SCROLLED_PAST = 'ECOMM_SET_TIRE_DETAIL_IS_SCROLLED_PAST';
export const ECOMM_SET_SORTED_DATA = 'ECOMM_SET_SORTED_DATA';
export const ECOMM_SET_PRICING_DETAILS_TIMESTAMP = 'ECOMM_SET_PRICING_DETAILS_TIMESTAMP';
// CHECKOUT ACTIONS
export const ECOMM_SET_CHECKOUT_SUBMITTING = 'ECOMM_SET_CHECKOUT_SUBMITTING';
export const ECOMM_SET_CHECKOUT_APPOINTMENT = 'ECOMM_SET_CHECKOUT_APPOINTMENT';
export const ECOMM_SET_CHECKOUT_APPOINTMENT_CONFIRM = 'ECOMM_SET_CHECKOUT_APPOINTMENT_CONFIRM';
export const ECOMM_SET_CHECKOUT_BILLING = 'ECOMM_SET_CHECKOUT_BILLING';
export const ECOMM_SET_CHECKOUT_PAYMENT = 'ECOMM_SET_CHECKOUT_PAYMENT';
export const ECOMM_SET_CHECKOUT_STEP = 'ECOMM_SET_CHECKOUT_STEP';
export const ECOMM_REPLACE_CHECKOUT_STORE_AVAILABILITY = 'ECOMM_REPLACE_CHECKOUT_STORE_AVAILABILITY';
export const ECOMM_SET_CHECKOUT_STORE_AVAILABILITY = 'ECOMM_SET_CHECKOUT_STORE_AVAILABILITY';
export const ECOMM_SET_ECOMM_STORE = 'ECOMM_SET_ECOMM_STORE';

// REVIEWS ACTIONS
export const REVIEWS_MERGE_PRODUCTS = 'REVIEWS_MERGE_PRODUCTS';
export const REVIEWS_MERGE_PRODUCTS_V2 = 'REVIEWS_MERGE_PRODUCTS_V2';
export const REVIEWS_SET_REVIEWS = 'REVIEWS_SET_REVIEWS';
export const REVIEWS_MERGE_REVIEWS = 'REVIEWS_MERGE_REVIEWS';
export const SET_REVIEWS_V2 = 'SET_REVIEWS_V2';
export const SET_SORTBY_REVIEWS_V2 = 'SET_SORTBY_REVIEWS_V2';
export const SET_RATING_REVIEWS_V2 = 'SET_RATING_REVIEWS_V2';
export const UPDATE_REVIEWS_V2 = 'UPDATE_REVIEWS_V2';
export const MERGE_REVIEWS_V2 = 'MERGE_REVIEWS_V2';

// TIRE DECISION GUIDE ACTIONS
export const TIRE_DECISION_GUIDE_SET_TDG = 'TIRE_DECISION_GUIDE_SET_TDG';
export const TIRE_DECISION_GUIDE_SET_STEP = 'TIRE_DECISION_GUIDE_SET_STEP';
export const TIRE_DECISION_GUIDE_SET_VEHICLE_SELECTED = 'TIRE_DECISION_GUIDE_SET_VEHICLE_SELECTED';
export const TIRE_DECISION_GUIDE_SET_STORE = 'TIRE_DECISION_GUIDE_SET_STORE';
export const TIRE_DECISION_GUIDE_SET_ZIPCODE = 'TIRE_DECISION_GUIDE_SET_ZIPCODE';
export const TIRE_DECISION_GUIDE_SET_TIRE_TYPE = 'TIRE_DECISION_GUIDE_SET_TIRE_TYPE';
export const TIRE_DECISION_GUIDE_SET_PRIORITIES = 'TIRE_DECISION_GUIDE_SET_PRIORITIES';
export const TIRE_DECISION_GUIDE_SET_SELECTED_PRIORITIES = 'TIRE_DECISION_GUIDE_SET_SELECTED_PRIORITIES';
export const TIRE_DECISION_GUIDE_SET_TIRES = 'TIRE_DECISION_GUIDE_SET_TIRES';
export const TIRE_DECISION_GUIDE_SET_SELECTED_TIRES = 'TIRE_DECISION_GUIDE_SET_SELECTED_TIRES';
export const TIRE_DECISION_GUIDE_SET_LOGGED_IN = 'TIRE_DECISION_GUIDE_SET_LOGGED_IN';

// TIRE SEARCH RESULTS
export const TSR_SET_DATA_ATTRIBUTES = 'TSR_SET_DATA_ATTRIBUTES';
export const TSR_SET_PRICING = 'TSR_SET_PRICING';
export const TSR_SET_INVENTORY = 'TSR_SET_INVENTORY';
// export const TSR_SET_SEARCH_BY_VEHICLE = 'TSR_SET_SEARCH_BY_VEHICLE';
export const TSR_SET_INVENTORY_PRICING = 'TSR_SET_INVENTORY_PRICING';
export const TSR_MERGE_TIRE_DATA = 'TSR_MERGE_TIRE_DATA';
export const TSR_SET_TAB = 'TSR_SET_TAB';
export const TSR_SET_SEARCH_TYPE = 'TSR_SET_SEARCH_TYPE';
export const TSR_SET_RESULT_TYPE = 'TSR_SET_RESULT_TYPE';
export const TSR_SET_FILTERS = 'TSR_SET_FILTERS';
export const TSR_SET_SORT = 'TSR_SET_SORT';
export const TSR_SET_VIEW = 'TSR_SET_VIEW';
export const TSR_SET_LOGGED_IN = 'TSR_SET_LOGGED_IN';
export const TSR_ADD_APPLIED_FILTER = 'TSR_ADD_APPLIED_FILTER';
export const TSR_REMOVE_APPLIED_FILTER = 'TSR_REMOVE_APPLIED_FILTER';
export const TSR_DISABLE_FILTER_SORT = 'TSR_DISABLE_FILTER_SORT';
export const TSR_REMOVE_ALL_APPLIED_FILTERS = 'TSR_REMOVE_ALL_APPLIED_FILTERS';
export const TSR_SET_COMPARE_GROUP = 'TSR_SET_COMPARE_GROUP';

// TIRE COMPARISON
export const COMPARE_SET_MODAL_STATE = 'COMPARE_SET_MODAL_STATE';
export const COMPARE_SET_TIRES = 'COMPARE_SET_TIRES';
export const COMPARE_ADD_TIRE = 'COMPARE_ADD_TIRE';
export const COMPARE_REMOVE_TIRE = 'COMPARE_REMOVE_TIRE';
export const COMPARE_SET_I18N = 'COMPARE_SET_I18N';
export const COMPARE_SET_LOGGED_IN = 'COMPARE_SET_LOGGED_IN';

// EXPEDITED BOOKING
export const XB_SET_VIEW = 'XB_SET_VIEW';
export const XB_SET_ZIPCODE = 'XB_SET_ZIPCODE';
export const XB_SET_STORE = 'XB_SET_STORE';
export const XB_SET_STORE_SERVICE = 'XB_SET_STORE_SERVICE';
export const XB_SET_VEHICLE_SELECTED = 'XB_SET_VEHICLE_SELECTED';
export const XB_SET_CONTACT_INFO = 'XB_SET_CONTACT_INFO';
export const XB_SET_DATETIME_SELECTED = 'XB_SET_DATETIME_SELECTED';
export const XB_SET_IS_EDITING = 'XB_SET_IS_EDITING';
export const XB_SET_SELECTEDTAB = 'XB_SET_SELECTEDTAB';
export const XB_SET_TIME_SLOT_ID = 'XB_SET_TIME_SLOT_ID';

//VEHICLE SELECTOR
export const VS_SET_VEHICLE = 'VS_SET_VEHICLE';
export const VS_SET_IS_ACCOUNT = 'VS_SET_IS_ACCOUNT';
export const VS_SET_ACTION = 'VS_SET_ACTION';

//SCROLL POSITION
export const XB_SET_IS_SCROLLED_PAST = 'XB_SET_IS_SCROLLED_PAST';
