/**
 * normalizes the data that will go into the option value attribute
 * @param {string} val - form input option
 */

export const normalizeWidgetValue = (val) => {
    let str = String(val).toLowerCase();

    if (str.length !== 1) {
        str = str
            .replace(/[@#$%^&*.]/g, '')
            .replace(/\s{2,}/g, ' ')
            .replace(/[/+:\s]/g, '-');
    }

    return str;
};

/**
 * Converts an array of values to string of option tags
 * @param {array} arr - array of data points to create a list of option tags with
 * @param {string} datatype - select datatype. may allow for specialized output
 */
export const getOptions = (arr, datatype = '') => {
    let options = arr.map((item) => {
        let val = datatype === 'trim' ? item.trim : item;
        let tpms = datatype === 'trim' ? `data-tpms="${item.tpms}"` : '';
        let normy = normalizeWidgetValue(val);
        return `<option value="${val}" data-optval="${normy}"${tpms}>${val}</option>`;
    });

    return options.join('');
};

/**
 * Provides Boolean for if the profile value is found in array of values
 * verify that there is an option that matches profile data since they may not match if backup vehicle datatype is being used
 * @param {array} arr - array of items to check against
 * @param {string} val - value we're checking for a match
 * @param {string} datatype - select datatype. for conditional cases
 */
export const isProfileMatch = (arr, val, datatype = '') => {
    let match = false;

    if (val) {
        arr.map((item) => {
            let test = datatype === 'trim' ? item.trim : item;
            if (test === val) {
                match = true;
            }
        });
    }

    return match;
};

export const isTiresProfileMatch = (arr, val) => {
    let match = false;

    val &&
        arr.map((item) => {
            if (item.value === val) {
                match = true;
            }
        });

    return match;
};
