/**
 * @module lidTiresNearYouInInventory
 * @description
 * This module is used to get the tires near you in inventory.  It is used on the LID page.
 * It gets the tires for the selected vehicle, then filters out the tires that are not in stock.
 * It returns the final mapped tires near you in inventory list.
 * 
 * @param {object} lidData - The lid data object.
 * @returns {array} - The list of mapped tires near you in inventory.
 */
import { searchByVehicle } from '../data/services/tire';
import { getInventoryPricing } from '../data/services/ecomm/quote';
import tireSearchResultsReducer from '../data/redux/reducers/tire-search-results';
import {
    TSR_MERGE_TIRE_DATA,
    TSR_SET_INVENTORY_PRICING
} from '../data/redux/actions';
import { lowToHigh } from './sort/lowToHigh';

// The POJO React state.
let state = {};

/**
 * @description Fetches the vehicle tires for the selected vehicle.
 * @param {object} lidData 
 * @returns {object} - The vehicle tires.
 */
const getVehicleTires = async (lidData) => {
    let dataPackage = {
        ymm: `${lidData.year}-${lidData.make}-${lidData.model}`,
        trim: lidData.trim
    };

    const response = await searchByVehicle(dataPackage);

    if (!response.success) {
        throw new Error(response?.message?.error);
    } else {
        return response.data;
    }
};

/**
 * @description Fetches the inventory and pricing for the selected vehicle.
 * @param {object} lidData 
 * @returns {object} - The inventory and pricing.
 */
const getInventoryAndPricing = async (lidData) => {
    let vehicle = {
        year: lidData.year,
        make: lidData.make,
        model: lidData.model,
        trim: lidData.trim
    };

    let store = lidData.storeNumber,
        searchCriteria = vehicle;

    const response = await getInventoryPricing(store, searchCriteria);
    if (response.success === 'false') {
        throw new Error(response?.message?.error);
    } else {
        return response.data;
    }
};

/**
 * @description Aggrogates the vehicle tires and inventory and pricing data for the selected vehicle.
 * @param {object} lidData 
 * @returns {object} - The vehicle tires and inventory and pricing.
 */
const getVehicleTiresAndInventoryAndPricing = async (lidData) => {
    const vehicleTires = await getVehicleTires(lidData);
    const inventoryAndPricing = await getInventoryAndPricing(lidData);
    return { vehicleTires, inventoryAndPricing };
};

/**
 * This method was ported over from the tire search results app. (TireSearchResultsApp.js)
 * The standard tire size is the tire size that is most common for the selected vehicle.
 * 
 * @param {Object} data Tires data
 * @returns 
 */
const getStandardTireSize = (data) => {
    let tireSize = null;
    const standardOptionalItems = [];
    const tiresArray = data?.tires || [];
    tiresArray.forEach((item) => {
        if (item.tires.length === 1 && item?.tires[0]?.tire?.standardOptional?.toUpperCase() === 'S') {
            const tireSaved = standardOptionalItems.find((e) => e.value === item?.tires[0]?.tire?.tireSize);
            if (tireSaved) {
                tireSaved.count += 1;
            } else {
                standardOptionalItems.push({ value: item?.tires[0]?.tire?.tireSize, count: 1 });
            }
        }
    });

    let maxCount = 0;
    standardOptionalItems.forEach((item) => {
        if (item.count > maxCount) {
            maxCount = item.count;
            tireSize = item.value;
        }
    });
    
    return tireSize;
};

/**
 * @description Parses the tires and inventory and pricing for the selected vehicle.
 * It removes duplicate tires and filters out tires that are not in stock. 
 * @param {array} vehicleTires
 * @param {array} inventoryAndPricing 
 * @returns {array} - The filtered tires.
 */
const filterInventoryAndPricing = async (vehicleTires, inventoryAndPricing) => {
    // Get the tires ids that are in stock.
    if (!vehicleTires || !vehicleTires?.tires || !inventoryAndPricing) return {'filteredTires': []};

    // Merge the tireResults (tireData) and matchedSets
    // into the inital state.
    Object.assign(state, {
        tireResults: vehicleTires.tires,
        matchedSets: []
    });

    // Get the inventory and pricing from the TSR reducer.
    const reducedInventoryAndPricing = tireSearchResultsReducer(state, {
        type: TSR_SET_INVENTORY_PRICING,
        payload: inventoryAndPricing?.products,
        target: null,
        callback: ((arg) => console.log(`---* arg:`, arg))
    });

    // Merge the inventory and pricing into the state.
    state = Object.assign(state, reducedInventoryAndPricing);

    // Get the tire data from the TSR reducer.
    const reducedTireData =  tireSearchResultsReducer(state, {
        type: TSR_MERGE_TIRE_DATA,
        payload: null,
        target: null,
        callback: null
    });

    // Merge the tire data into the state.
    state = Object.assign(state, reducedTireData);

    // Get the standard tire size. (ported method from TSR app)
    const standardTireSize = getStandardTireSize(state);

    // The final list.
    const filteredTires = state.tires
                                // Pluck the tire objects.
                                .map((item) => {
                                    return item.tires[0].tire;
                                })
                                // Sort tires low-to-high
                                // Default to 0 for price-less tires, ordering them first.
                                .sort((a, b) => {
                                    const aPrice = a?.price?.value || 0;
                                    const bPrice = b?.price?.value || 0;

                                    return lowToHigh(aPrice, bPrice);
                                });

    return { filteredTires };
};

/**
 * @description Maps tire and ratings data to the tires.
 * @param {array} filteredTires 
 * @returns {array} - The mapped tires.
 */
const getMappedTires = (filteredTires) => {
    return filteredTires.map((tire) => {
        return {
            article: tire.article,
            brand: tire.brand,
            tireName: tire.tireName,
            tireTitle: getTireTitle(tire),
            id: getTireNameId(tire),
            vehicleType: `${tire.tireVehType} | ${tire.tireType}`,
            description: tire.description
        };
    });
};

/**
 * @description Gets the tire title to be rendered on the tire cards.
 * @param {string} tire - The tire object.
 * @returns 
 */
const getTireTitle = (tire) => {
    return `${tire.tireName}${(tire.oemFlag === 'Y' && '<sup>†</sup>') || ''}${(tire.discontinued === 'Y' && '<sup>*</sup>') || ''}`
};

/**
 * @description Gets the tire name id.  This is used to get the tire rating.
 * The tire name id is the brand and tire name in all caps.
 * @param {string} tire - The tire name id.
 * @returns 
 */
const getTireNameId = (tire) => {
    return `${tire.brand.toUpperCase()}${tire.tireFriendlyName.toUpperCase()}`;
};

/**
 * @description Gets the tires near you in inventory. It is used on the LID page.
 * It gets the tires for the selected vehicle, then filters out the tires that are not in stock.
 * @param {object} lidData 
 * @returns {array} - The final mapped tires near you in inventory list.
 */
export const tiresNearYouInInventory = async (lidData) => {
    const { vehicleTires, inventoryAndPricing } = await getVehicleTiresAndInventoryAndPricing(lidData);
    const { filteredTires } = await filterInventoryAndPricing(vehicleTires, inventoryAndPricing);
    const mappedTires = getMappedTires(filteredTires);

    return mappedTires;
};