import { component } from 'common/js/library/component';
import { getDaysLeft } from 'common/js/util/daysLeft';

const instanceInit = (rootEl) => {
    setupDaysLeftOffer(rootEl);
};

const setupDaysLeftOffer = (rootEl) => {
    const offerEndDate = rootEl.querySelector('.offer-card-refresh__expiry-wrap')?.dataset?.offerEndDate || '';
    if (offerEndDate) {
        const daysLeft = getDaysLeft(offerEndDate);

        const daysLeftText = rootEl.querySelector('.offer-card-refresh__expiry-date');
        if (daysLeftText) {
            daysLeftText.innerHTML = 'Exp. ' + offerEndDate + ' <span>(' + daysLeft + ' days left!)</span>';
        }

        const validUntilInput = rootEl.querySelector('.offer-card-refresh__valid-date-days');
        if (validUntilInput) {
            validUntilInput.value = 'Valid until ' + offerEndDate + ' (' + daysLeft + ' days left!)';
        }
    }
};

component({
    selector: '.offer-card-refresh',
    instanceInit,
    name: 'OffersCard',
    canMutate: true,
    canReinit: true
});
