import '../scss/content-carousel.scss';
import Swiper from 'swiper/bundle';
import { component } from 'common/js/library/component';

const instanceInit = (rootEl) => {
    console.log('inside content-carousel.js');

    initSwiper(rootEl);
};

const initSwiper = (rootEl) => {
    const carousel = rootEl.querySelector('.swiper');
    if (carousel) {
        const slidesDesktop = Number(carousel.getAttribute('data-slides-desktop')) || 3;
        const slidesTablet = Number(carousel.getAttribute('data-slides-tablet')) || 2;
        const slidesPhone = Number(carousel.getAttribute('data-slides-phone')) || 1;

        const swiperSlides = rootEl.querySelectorAll('.carousel .swiper-slide');

        [...swiperSlides].forEach((el) => {
            rootEl.querySelector('.swiper-wrapper')?.insertAdjacentElement('beforeend', el);
        });

        rootEl.querySelector('.carousel')?.remove();

        new Swiper(carousel, {
            loop: false,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            spaceBetween: 20,
            breakpoints: {
                320: {
                    slidesPerView: slidesPhone,
                    slidesPerGroup: slidesPhone
                },
                768: {
                    slidesPerView: slidesTablet,
                    slidesPerGroup: slidesTablet
                },
                1024: {
                    slidesPerView: slidesDesktop,
                    slidesPerGroup: slidesDesktop
                }
            }
        });
    }
};

component({
    selector: '.content-carousel',
    instanceInit,
    name: 'ContentCarousel'
});
