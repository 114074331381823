/**
 * Converts the day of the month from a Date object into a two-digit string.
 * 
 * @param {Date} date - The Date object from which the day will be extracted.
 * @returns {string} A two-digit string representing the day of the month.
 */
const dateToDD = (date) => {
    let dd = date.getDate().toString();
    dd = dd.length === 1 ? `0${dd}` : dd;

    return dd;
};

export { dateToDD };
