export const reviewsSwitch = () => {
    //////////////////
    // REVIEWS ON
    //////////////////
    if (navigator.cookieEnabled) {

        if (localStorage.getItem('reviewsAreActive') === 'false') {
            return false;
        }
    }
    
    return true;

    ///////////////
    // REVIEWS OFF
    ///////////////
    // if (localStorage.getItem('reviewsAreActive') === 'true') {
    //     return true;
    // }

    // return false;
};
