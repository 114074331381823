import '../scss/overview.scss';
import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
import { viewport } from 'common/js/library/viewport';
import Swiper from 'swiper/bundle';

const init = (config) => {};

const instanceInit = (rootEl) => {
    console.log('inside overview-carousel.js');
    const swiperEl = rootEl.querySelector('.swiper');
    const carousel = rootEl.querySelector('.carousel');
    let active = false;

    const isActive = () => {
        return active;
    };

    const setActive = (status) => {
        active = status;
    };

    pubsub.subscribe('viewportquerychange', (evt, currentQuery) =>
        handleResize(evt, rootEl, { currentQuery, swiperEl, isActive, setActive, carousel })
    );

    if (viewport.currentQuery === 'phone') {
        createSwipercontainer(rootEl);
        initSwiper(rootEl, swiperEl, carousel);
        setActive(true);
    }
};

 const createSwipercontainer = (rootEl) => {
    const swiperSlides = rootEl.querySelectorAll('.carousel .swiper-slide');

    [...swiperSlides].forEach((el) => {
        rootEl.querySelector('.swiper-wrapper').insertAdjacentElement('beforeend', el.cloneNode(true));
    });
 }

const handleResize = (evt, rootEl, props) => {
    const { currentQuery, swiperEl, isActive, setActive, carousel } = props;
    if (!isActive() && currentQuery === 'phone') {
        initSwiper(rootEl, swiperEl, carousel);
        setActive(true);
    } else if (isActive() && (currentQuery === 'tablet' || currentQuery === 'desktop')) {
        const swiperControl = swiperEl.swiper;
        swiperControl.destroy(true, true);
        toggleSwiper(swiperEl, carousel, false);
        setActive(false);
    }
};

const toggleSwiper = (swiper, carousel, showSwiper) => {
    if (showSwiper) {
        swiper && (swiper.style.display = 'block');
        carousel && (carousel.style.display = 'none');
    } else {
        swiper && (swiper.style.display = 'none');
        carousel && (carousel.style.display = '');
    }
};

const initSwiper = (rootEl, swiper, carousel) => {
    const swiperScrollBar = rootEl.querySelector('.swiper-scrollbar');
    toggleSwiper(swiper, carousel, true);

    new Swiper(swiper, {
        loop: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        spaceBetween: 20,
        scrollbar: {
            el: swiperScrollBar,
            draggable: true,
            hide: true
        },
        slidesPerView: 1,
        slidesPerGroup: 1
    });
};

component({
    init,
    instanceInit,
    selector: '.overview-carousel',
    name: 'OverviewCarousel'
});
