const formatTime = (time, minutes) => {
    // pre es2015/ES6 optional minutes parameter:
    minutes = typeof minutes !== 'undefined' ? minutes : true;

    if (!time || time === '') {
        return '';
    }

    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? 'am' : 'pm'; // Set am/pm
        time[0] = +time[0] % 12 || 12; // Adjust hours

        if (!minutes) {
            time = [time[0], time[5]];
        }
    }

    return time.join(''); // return adjusted time or original string
}

export { formatTime };
