import { formatTime } from "./formatTime";

const openUntil = (hours, holidays, holidayHours) => {
    var output = 'Open until ',
        today = new Date(),
        isHoliday = false,
        todayWeekday,
        hoursWeekday;

    today.setHours(0, 0, 0, 0); //simplify for date compare
    //short day name, e.g., TUE
    todayWeekday = today.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();

    if (holidays != undefined) {
        for (var x = 0; x < holidays.length; x += 1) {
            // create date object
            var holiday = new Date(holidays[x].month + '/' + holidays[x].day + '/' + holidays[x].year);
            holiday.setHours(0, 0, 0, 0); //simplify for date compare
            if (holiday.getTime() === today.getTime()) {
                isHoliday = true;
                if (!holidayHours) {
                    output = '';
                } else {
                    //TODO see cleanHolidays
                }
            }
        }
    }
    if (!isHoliday) {
        if (hours != undefined) {
            for (var i = 0; i < hours.length; i += 1) {
                hoursWeekday = hours[i].weekDay.toUpperCase();

                if (hours[i].closeTime.length > 0 && hoursWeekday.length > 0) {
                    switch (hoursWeekday) {
                        case todayWeekday:
                            output = output + formatTime(hours[i].closeTime, false);
                            break;
                        case 'MON-FRI':
                            if ('MONTUEWEDTHUFRI'.indexOf(todayWeekday) !== -1) {
                                output = output + formatTime(hours[i].closeTime, false);
                            }
                            break;
                        case 'MON-SAT':
                            if ('MONTUEWEDTHUFRISAT'.indexOf(todayWeekday) !== -1) {
                                output = output + formatTime(hours[i].closeTime, false);
                            }
                            break;
                        case 'SAT-SUN':
                            if ('SATSUN'.indexOf(todayWeekday) !== -1) {
                                output = output + formatTime(hours[i].closeTime, false);
                            }
                            break;
                    }
                }
            }
        } else {
            output = '';
        }
    }

    return output;
};

export { openUntil };
