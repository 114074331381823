import { getCookie } from 'common/js/library/util';

export const loginNameCookie = '90210_L0G1N_N4M3_8675309';

// this model is used to trim the full state down to
// just the top-level keys API accepts on update
export const userApiModel = {
    DRIVER: null,
    FILL_UP: null,
    MAINTENANCE_PERFORMED_SERVICE: null,
    MAINTENANCE_TASK: null,
    SERVICE_HISTORY_INVOICE: null,
    SERVICE_HISTORY_JOB: null,
    SERVICE_HISTORY_JOB_DETAIL: null,
    SERVICE_HISTORY_VEHICLE: null,
    STORE: null,
    VEHICLE: null
};

export const postOptions = (dataPackage, tokenId) => {
    const headers = HTTPHeaders();
    const tokenCookie = tokenId || getCookie('tokenId');

    // Add tokenId to headers at this point because it needs to be parameterized to support
    // a temporary tokenId passed during the ChangePasswordForm and EmailConfirmationRequiredPrompt.
    if (tokenCookie) {
        headers.tokenId = tokenCookie;
    }

    return {
        method: 'POST',
        body: JSON.stringify(dataPackage),
        headers
    };
};

export const formatDateForApi = (date) => {
    // Format day/month/year to two digits
    console.log('DATE in serviceUtilities: ', date);
    const formattedDate = ('0' + date.getDate()).slice(-2);
    const formattedMonth = ('0' + (date.getMonth() + 1)).slice(-2);
    const formattedYear = date.getFullYear().toString().substr(2, 2);

    // Combine and format date string
    return formattedMonth + '/' + formattedDate + '/' + formattedYear;
};

export const getMap = (lat, lon, width, height) => {
    if (!lat || !lon) return '';

    const server = '//dev.virtualearth.net/';
    const path = 'REST/v1/Imagery/Map/Road/';
    const zoom = '13'; // I think this is the zoom level
    const ms = `${width},${height}`; // map dimensions.
    const ml = 'TrafficFlow'; // map layers
    const fmt = 'jpeg'; // format
    const mmd = '0'; // ????
    const key = 'AlGvGS0ffMJjgLqnheFD2z6MiUGHw9_QzsErZT9qJT21H_FW-mmSVjNh4Vq7MFwV';

    return `${server}${path}${lat},${lon}/${zoom}?ms=${ms}&pp=${lat},${lon};7&ml=${ml}&fmt=${fmt}&mmd=${mmd}&key=${key}`;
};

/**
 * Creates a query string from the given dataPackage object.
 * Unlike the raw URLSearchParams, this method will not include null or undefined values.
 * @param {Object} dataPackage An object to be converted to a query string.
 * @returns Query string with leading question mark, or an empty string if the dataPackage is empty.
 */
export const queryStringify = (dataPackage) => {
    // delete members that are null or undefined
    const filteredPackage = Object.entries(dataPackage || {}).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
            acc[key] = value;
        }
        return acc;
    }, {});
    const params = new URLSearchParams(filteredPackage).toString();
    return params ? `?${params.replaceAll('+', '%20')}` : '';
};

export const responseError = (responseJson) => {
    console.log('ERROR DATA: ', responseJson);
    const status = responseJson.status;
    const error = responseJson.data.error;
    return `authentication error: Response code ${status}.\n\nError: ${error}`;
};

// Utility method to get the headers for the HTTP request.
export const HTTPHeaders = (headerTypes = []) => {
    // Default headers with content-type as application/json.
    const headers = {
        'Content-Type': 'application/json'
    };

    // Appends the pinnacle feature flag in the headers, if the feature is enabled.
    if (headerTypes.includes('pinnacle') && window?.featureSwitches?.isTrue('bsro-ui-pinnacle')) {
        headers['bsro-ui-pinnacle'] = true;
    }

    // Appends the tokenId in the headers from the cookie.
    if (headerTypes.includes('token')) {
        headers.tokenId = getCookie('tokenId') || '';
    }

    // Appends the reviews version v2 in the headers for PowerReviews API.
    if (headerTypes.includes('powerreviews')) {
        headers.version = 'v2';
    }

    return headers;
};
