/**
 * Base component behaviors.
 * It will take a selector and apply the component init to each instance in the DOM once the page is loaded.
 * Optionally, it will add a mutation observer in case additional instances are added to the page later.
 * It will mark each instance to prevent it from being instantiated again.
 * @param {object} config - configuration params
 * @param {string} config.selector - The DOM selector to attach to. Preferably a data attribute '[data-cmp-componentname]'
 * @param {function} config.init - Function to run before instanceInit for any shared behaviors or data among all instances.
 * @param {function} config.instanceInit = Function to run for each DOM instance found
 * @param {string} [config.name] - Identifier for the component
 * @param {boolean} [config.canMutate] - Flag for if a mutation observer should be added to instantiate components added to the page after page load.
 * @param {boolean} [config.canReinit] - Flag for reinitializing the component. This is useful for site clientlibs that may need there own init step.
 */
const component = ({
    selector,
    init = () => {},
    instanceInit = () => {},
    name = 'Unnamed Component',
    canMutate = false,
    canReinit = false
}) => {
    const config = { selector, init, instanceInit, name, canMutate, canReinit };

    if (!selector) {
        console.log(`No selector provided for ${name}. It will not be instantiated.`);
        return;
    }

    if (document.readyState !== 'loading') {
        onDocumentReady(config);
    } else {
        document.addEventListener(
            'DOMContentLoaded',
            () => {
                onDocumentReady(config);
            },
            { once: true }
        );
    }
};

/**
 * Initialization chain to kick off once the document is ready.
 * @param {object} config
 */
const onDocumentReady = (config) => {
    const { selector, name, init, canMutate } = config;
    const elements = document.querySelectorAll(selector);

    console.log(`initializing: ${name}`);

    init(config);

    console.log(`initialize ${elements.length} ${name} components`);

    elements.forEach((el, index) => {
        console.log(`initializing ${name} instance #${index}: ${el}`);
        createComponentInstance(config, el);
    });

    if (canMutate) {
        createMutationObserver(config, selector);
    }
};

/**
 * identifies if the component instance has already been initialized and if not runs the init.
 * @param {object} config -
 * @param {element} el - the DOM element to instantiate the component on
 */
const createComponentInstance = (config, el) => {
    if (!el || (el.hasAttribute('data-has-init') && !config.canReinit)) return;

    el.setAttribute('data-has-init', '');
    config.instanceInit(el);
};

/**
 * creates a mutation observer that will run the component creation function when a matching element is added to the DOM.
 * @param {string} selector
 * @param {function} init
 * @param {boolean} [canReinit]
 */
const createMutationObserver = (config, selector) => {
    const MutationObserver = window.MutationObserver;
    const body = document.querySelector('body');
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((addedNode) => {
                if (addedNode.querySelectorAll) {
                    let addedEls = addedNode.querySelectorAll(selector);
                    addedEls.forEach((el) => {
                        createComponentInstance(config, el);
                    });
                }
            });
        });
    });

    observer.observe(body, {
        subtree: true,
        childList: true,
        characterData: true
    });
};

export { component };
