export const setModalHandler = (setModal) => {
    const handleSetModal = (component, closeModalOverrideFunction, classes) => {
        let popHandler = () => {
            setModal({
                isOpen: false,
                content: null,
                closeModalFunction: null,
                containerClass: ''
            });
            window.history.forward();
        };
        if (component) {
            document.body.classList.add('no-scroll');
            if (closeModalOverrideFunction && typeof closeModalOverrideFunction === 'function') {
                setModal({
                    isOpen: true,
                    content: component,
                    closeModalFunction: () => closeModalOverrideFunction(),
                    containerClass: classes
                });
            } else {
                setModal({
                    isOpen: true,
                    content: component,
                    closeModalFunction: () => handleSetModal(),
                    containerClass: classes
                });
            }

            window.addEventListener('popstate', popHandler, { once: true });
        } else {
            setModal({
                isOpen: false,
                content: null,
                closeModalFunction: null,
                containerClass: ''
            });
            document.body.classList.remove('no-scroll');
            window.removeEventListener('popstate', popHandler, { once: true });
        }
    };

    return handleSetModal;
};
