import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import { coralWaitDisplay } from './coralWaitDisplay';
import { findStores } from './findStores';
import { getAccountState } from './getAccountState';
import { selectStore } from './selectStore';
import { validateSearchInput } from './validateSearchInput';
import { isEmpty } from 'common/js/library/util';
import { component } from 'common/js/library/component';
import { debounce } from 'common/js/util/debounce';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';
import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { getStoreLocationListByLatLong, getStoreLocationsByCityState } from 'common/js/data/services/store-location';
import { GeolocationPermission } from 'common/js/util/geolocationPermission';

const init = (config) => {};

const instanceInit = (rootEl) => {
    let selectedStoreNum = null;
    let stepDisable;
    let isLoggedIn = false;
    let stores = [];
    let preferredStoreNumber = null;
    let zip;
    let selectedStore = null;
    let city = null;
    let state = null;
    let searchZipInput = rootEl.querySelector('.search-block input');
    let searchZipBtn = rootEl.querySelector('.search-block button:not(.geo-locate-me)');
    let steps = rootEl.querySelector('.appt-step');
    let queryParams = {};
    let widemapView = steps.classList.contains('widemap');
    let queryStoreNumber = null;
    var geolocationContainer = document.querySelector('.local-seo-theme .container');
    var geolocationFormHeader = document.querySelector('.local-seo-theme .geolocation-header');
    const isSeoPage = rootEl.querySelector('.local-seo-theme') || document.body.classList.contains('local-seo-page');
    const seoDefaultZipCode = document.querySelector('.local-seo-theme')?.dataset?.defaultZipcode;
    let wrap = document.querySelector('.local-seo-theme .container .wrap');
    let wrapInner = document.querySelector('.local-seo-theme .container .wrap .wrap-inner');
    const firstTitleTextCTA = document.querySelector('.local-seo-page .map-title');
    const geoLocateButton = rootEl.querySelector('.local-seo-theme .search-block form .geo-locate-me');
    const resultsWrapper = document.querySelector('.appt-step-1-results');

    const getSelectedStoreNum = () => {
        return selectedStoreNum;
    };

    const setSelectedStoreNum = (value) => {
        selectedStoreNum = value;
    };

    const getStepDisable = () => {
        return stepDisable;
    };

    const setStepDisable = (value) => {
        stepDisable = value;
    };

    const getIsLoggedIn = () => {
        return isLoggedIn;
    };

    const setIsLoggedIn = (value) => {
        isLoggedIn = value;
    };

    const getStores = () => {
        return stores;
    };

    const setStores = (value) => {
        stores = value;
    };

    const getPreferredStoreNumber = () => {
        return preferredStoreNumber;
    };

    const setPreferredStoreNumber = (value) => {
        preferredStoreNumber = value;
    };

    const getZip = () => {
        return zip;
    };

    const setZip = (value) => {
        zip = value;
    };

    const getCity = () => {
        return city;
    };

    const setCity = (value) => {
        city = value;
    };

    const getState = () => {
        return state;
    };

    const setState = (value) => {
        state = value;
    };

    const getSelectedStore = () => {
        return selectedStore;
    };

    const setSelectedStore = (value) => {
        selectedStore = value;
    };

    const getQueryParams = () => {
        return queryParams;
    };

    const setQueryParams = (value) => {
        queryParams = value;
    };

    const getQueryStoreNumber = () => {
        return queryStoreNumber;
    };

    const setQueryStoreNumber = (value) => {
        queryStoreNumber = value;
    };

    const geoLocateSuccessful = () => {
        if (geolocationContainer) {
            geolocationContainer.classList.contains('local-geolocation-search')
                ? geolocationContainer.classList.remove('local-geolocation-search')
                : '';

            getStores().length >= 1
                ? wrapInner.classList.add('has-results')
                : wrapInner.classList.remove('has-results');
        }

        if (geolocationFormHeader) {
            geolocationFormHeader.classList.contains('show-form')
                ? geolocationFormHeader.classList.replace('show-form', 'hide-form')
                : '';
        }

        if (firstTitleTextCTA && firstTitleTextCTA.classList.contains('hidden')) {
            firstTitleTextCTA.classList.remove('hidden');
        }
    };

    coralWaitDisplay(false);

    setStepDisable(rootEl.dataset.stepDisable || false);

    const props = {
        getSelectedStoreNum,
        getStepDisable,
        setSelectedStoreNum,
        setStepDisable,
        getIsLoggedIn,
        setIsLoggedIn,
        getStores,
        setStores,
        setPreferredStoreNumber,
        getPreferredStoreNumber,
        getZip,
        setZip,
        getSelectedStore,
        setSelectedStore,
        searchZipInput,
        searchZipBtn,
        steps,
        getCity,
        setCity,
        getState,
        setState,
        getQueryParams,
        setQueryParams,
        getQueryStoreNumber,
        setQueryStoreNumber,
        isSeoPage,
        seoDefaultZipCode,
        geoLocateSuccessful
    };

    if (geolocationContainer) {
        if (wrapInner?.classList?.contains('city')) {
            firstTitleTextCTA?.classList?.remove('hidden');
        }
    }

    rootEl.querySelector('.search-block form').addEventListener('submit', (evt) => {
        evt.preventDefault();
        findStores(rootEl, props, evt);
    });

    GeolocationPermission().then((permission) => {
        if (/granted|prompt/.test(permission)) {
            enableGeolocationCTA();
        }
    });

    const enableGeolocationCTA = () => {
        if (geoLocateButton) {
            geoLocateButton.disabled = false;
            geoLocateButton.addEventListener('click', (evt) => {
                evt.preventDefault();
                rootEl.querySelector('.search-block .zipcodeError').innerHTML = '';
                geoLocateStores();
            });
        }
    };

    const getStoresByLatLong = async (data) => {
        const lat = data.coords.latitude;
        const long = data.coords.longitude;
        const storeList = await getStoreLocationListByLatLong(lat, long, 1, null, '');
        const storeData = (Array.isArray(storeList) && storeList[0]) || {};

        let closestStoreZip;
        let values;

        if (storeData) {
            closestStoreZip = storeData.zip?.substr(0, 5);
            values = { zip: closestStoreZip };
            if (closestStoreZip !== undefined) {
                setZip(closestStoreZip);
                findStores(rootEl, props, null, { zipCode: closestStoreZip });
                geoLocateSuccessful();
            } else {
                rootEl.querySelector('.search-block .zipcodeError').innerHTML = 'No Stores Within Range';
            }
        }
    };

    const getStoresByLatLongError = (err) => {};

    const geoLocateStores = () => {
        if (window.navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getStoresByLatLong, getStoresByLatLongError);
        }
    };

    document.querySelector('.signupBtn')?.addEventListener('click', () => {
        var email = document.querySelector('.form input').value;
        window.localStorage.setItem('emailID', email);
        if (window.AEM.siteName === 'hibdontire') {
            window.location.href = '/content/bsro/' + window.AEM.siteName + '/en/sign-up-for-email-offers.html';
        } else {
            window.location.href = '/content/bsro/' + window.AEM.siteName + '/en/email-signup.html';
        }
    });

    const see_all_cta = document.querySelectorAll('[data-see-all]');
    const _maintenance_container = document.querySelector('.maintenance-services');

    see_all_cta.forEach((element) => {
        element.addEventListener('click', (evt) => {
            evt.preventDefault();
            var maintenanceItems = _maintenance_container.querySelectorAll('li:nth-child(n+4)');
            maintenanceItems.forEach(function (item) {
                item.style.display = item.style.display === 'none' ? '' : 'none';
            });
            this.style.display = 'none';
        });
    });

    window.addEventListener('load', (evt) => componentInit(rootEl, widemapView, props));

    // Automatically Populate after Geolocation.
    pubsub.subscribe(
        topics.CREATE_REVIEWS,
        debounce(() => {
            let zipInput = document.querySelector('.find-store input[name*=zip]');
            // If the results are NOT populated and a zip HAS been geolcoated.
            if (
                document.querySelector('.appt-step-1-results').childElementCount === 1 &&
                zipInput &&
                zipInput.value !== ''
            ) {
                // Submit the form.
                let submitButton = document.querySelector('.find-store button');
                submitButton && (submitButton.disabled = false);
                findStores(rootEl, props);
            }
        }, 1)
    );

    pubsub.subscribe(topics.CREATE_REVIEWS_READY, (el) => {
        setAppointmentCtaTracking(el);
    });
};

const componentInit = async (rootEl, widemapView, props) => {
    const {
        setIsLoggedIn,
        setPreferredStoreNumber,
        searchZipInput,
        setQueryParams,
        getQueryParams,
        steps,
        searchZipBtn,
        getQueryStoreNumber,
        setQueryStoreNumber,
        isSeoPage,
        seoDefaultZipCode
    } = props;
    getAccountState(setIsLoggedIn, setPreferredStoreNumber);

    setQueryParams(getQueryStrings());

    const isCoralModal = rootEl.parentElement.closest('.coral-Modal');
    const inModal = isCoralModal && [...rootEl.parentElement.querySelectorAll('.coral-Modal')].length > 0;
    const modalShow = isCoralModal && getComputedStyle(rootEl.parentElement.closest('.coral-Modal')).display !== 'none';

    let _startZip = null;

    if (window.siteProfile) {
        _startZip = window.siteProfile.location.myZip || '';
    }

    if (widemapView) {
        if (searchZipInput.value.length > 0) {
            _startZip = searchZipInput.value;
        }
        const resultsZip = document.querySelector('.results-zip');
        resultsZip && (resultsZip.innerText = _startZip);
    }

    setQueryStoreNumber(getQueryParams().storeNumber || getQueryParams().store || null);

    if (inModal) {
        setQueryStoreNumber(null);
    }

    var _fromTireShop = window.document.referrer.toString();
    var _nmsCityState = getLocalStorageItem('nmsCityState');

    if (_fromTireShop.indexOf('tire-shop') != -1) {
        if (_nmsCityState) {
            _startZip = _nmsCityState;
        }
    }

    const _vehMainForm = window.isEcom
        ? document.querySelector('[data-service-type=tce]')
        : document.querySelector('[data-service-type=main]');

    const _vehMainCookie = window.siteProfile.vehicles.main;

    searchZipInput.addEventListener('change', (evt) => handleSearchInputChange(evt, rootEl, widemapView));
    searchZipInput.addEventListener('blur', (evt) => handleSearchInputChange(evt, rootEl, widemapView));
    searchZipInput.addEventListener('keyup', (evt) => handleSearchInputChange(evt, rootEl, widemapView));
    searchZipInput.addEventListener('click', (evt) => handleSearchInputChange(evt, rootEl, widemapView));

    if (_startZip === '' && isSeoPage && parseInt(seoDefaultZipCode)) {
        _startZip = seoDefaultZipCode;
    }

    if (getQueryStoreNumber() == null) {
        if (_startZip) {
            if (_nmsCityState) {
                window.localStorage.removeItem('nmsCityState');
            }

            /* FALL BACK FOR ZIP CODE */
            if (document.body.classList.contains('city') || document.body.classList.contains('store')) {
                if (seoDefaultZipCode) {
                    _startZip = seoDefaultZipCode;
                } else {
                    // if data-default-zip not set in the markup, then we need to make a call to get the first store in the city/state
                    const stateValue = document.querySelector('meta[name="state"]')?.getAttribute('content');
                    const cityValue = document.querySelector('meta[name="city"]')?.getAttribute('content');
                    const searchPackage = { city: cityValue, state: stateValue };
                    const response = await getStoreLocationsByCityState(searchPackage);
                    if (response?.data?.stores?.length > 0) {
                        // Filter the stores to include only those that are not temporarily closed
                        const openStores = response.data.stores.filter((store) => store.temporarilyClosed === 'N');
                        const firstZip = openStores[0].zip.replace(/-.*/, '');
                        _startZip = firstZip;
                    }
                }
            }

            searchZipInput.value = _startZip;

            validateSearchInput(rootEl, _startZip);

            if ((modalShow && inModal) || !inModal) {
                findStores(rootEl, props);

                if (_vehMainCookie.make != '') {
                    const makeOption = document.createElement('option');
                    makeOption.text = _vehMainCookie.make;
                    makeOption.value = _vehMainCookie.make;

                    if (_vehMainForm) {
                        _vehMainForm.querySelector('[name=make]').appendChild(makeOption).selected = true;

                        const modelOption = document.createElement('option');
                        modelOption.text = _vehMainCookie.model;
                        modelOption.value = _vehMainCookie.model;
                        _vehMainForm.querySelector('[name=model]').appendChild(modelOption).selected = true;

                        const submodelOption = document.createElement('option');
                        submodelOption.text = _vehMainCookie.trim;
                        submodelOption.value = _vehMainCookie.trim;
                        _vehMainForm.querySelector('[name=submodel]').appendChild(submodelOption).selected = true;
                    }
                }
            }
        }
    } else {
        steps.classList.remove('active');

        if (window.digitalData) {
            var _tag = {
                eventName: 'appointment.step2',
                attributes: {
                    storeNumber: getQueryStoreNumber()
                }
            };

            window.digitalData.event = [_tag];
            window._satellite.track(_tag.eventName);
        }

        selectStore(null, getQueryStoreNumber(), rootEl, props);
    }

    const observer = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                // Handle the event when the element is about to become visible
                if (inModal) {
                    if (isEmpty(_startZip)) {
                        searchZipInput.value = window.siteProfile.location.myZip || '';
                    }

                    findStores(rootEl, props);
                }

                // Unobserve the element to stop observing further visibility changes
                observer.unobserve(entry.target);
            }
        });
    });

    const findStoreModal = document.querySelector('#find-store-modal');
    observer.observe(findStoreModal);

    var _step_one_container = rootEl.querySelector('.appt-step-1 .container');

    coralWaitDisplay(true, _step_one_container);

    if (getQueryParams().zipCode) {
        searchZipInput.value = getQueryParams().zipCode;
        searchZipBtn.dispatchEvent(new Event('click'));
    }
};

const handleSearchInputChange = (evt, rootEl, widemapView) => {
    const currentTarget = evt.currentTarget;
    const val = currentTarget.value;
    currentTarget?.removeAttribute('readonly');
    validateSearchInput(rootEl, val);

    if (widemapView) {
        document.querySelector('.results-zip').textContent = val;
    }
};

const getQueryStrings = () => {
    let queries = window.location.search.substr(1).split('&');
    let query = {};

    for (let i = 0; i < queries.length; i++) {
        var kv = queries[i].split('=');
        query[kv[0]] = kv[1];
    }
    return query;
};

const setAppointmentCtaTracking = (arr) => {
    arr.forEach((el) => {
        let cta = el.closest('.result').querySelectorAll('.dynamic-tracking');
        cta.forEach((ctaElement) => {
            let data = {
                link_type: ctaElement.getAttribute('data-link-type'),
                scoreNumber: el.querySelector('.average-rating')?.textContent,
                numberOfReviews: el.querySelector('.review-count')?.textContent?.replace(/[^0-9]/g, ''),
                storeNumber: ctaElement.getAttribute('data-store-number')
            };
            ctaElement.addEventListener('click', function () {
                window._satellite.track('store.detail.click', data);
            });
        });
    });
};

component({
    init,
    instanceInit,
    name: 'ScheduleAppointment',
    selector: '[data-schedule-appointment]'
});
