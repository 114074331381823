import { component } from 'common/js/library/component';

const instanceInit = (rootEl) => {
    console.log('inside tool.js');

    let footerHeading = rootEl.querySelector('.footer-heading');
    footerHeading &&
        footerHeading.addEventListener('click', (evt) => {
            handleEvent(evt, rootEl);
        });
};

const handleEvent = (evt, rootEl) => {
    let ct = evt.currentTarget;

    if (ct && ct.classList.contains('footer-heading')) {
        toggle(rootEl);
        evt.preventDefault();
    }
};

const toggle = (rootEl) => {
    let activeClass = 'active';
    let toggleText = rootEl.querySelector('.toggle a');

    if (rootEl.classList.contains(activeClass)) {
        rootEl.classList.remove(activeClass);
        toggleText.innerText = '+';
    } else {
        rootEl.classList.add(activeClass);
        toggleText.innerText = '-';
    }
};

/**
 * This component is used on the footer of each page with selector having footer-tool.
 */

component({
    selector: '.footer-tool',
    instanceInit,
    name: 'FooterTool'
});
