import '../scss/product-detail.scss';
import Handlebars from 'common/js/library/handlebar';
import { getCookie, isArray, isEmpty, setCookie, serializeForm, getUrlParameter } from 'common/js/library/util';
import { testEmail } from 'common/components/content/react-shared/utils/validation';
import { QuoteServiceFactory } from 'common/js/library/quote-service-factory';
import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
import { tirePath } from 'common/js/util/dynamic-media/tirePath';
import { stringSanitizer } from 'common/js/util/stringSanitizer';
import { getLocalStorageItem, setLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { getCatalogAllTires } from 'common/components/content/react-shared/requests/getCatalogAllTires';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { responseError, queryStringify, HTTPHeaders } from 'common/js/data/services/serviceUtilities';

var quoteService = new QuoteServiceFactory();
let emailModal;
let gHidePrice;
let $carousel;

//Analytics handle Nav coupon Click
const couponButtons = document.querySelectorAll('.coupons');
const handleCouponClick = (event) => {
    console.log('coupon clicked');
    setAdobeDataLayer({
        event: 'tire-detail-interaction',
        tdInteractionType: 'coupons link clicked'
    });
};

couponButtons.forEach((button) => {
    button.addEventListener('click', handleCouponClick);
});

const init = (config) => {
    emailModal = document.querySelector('#email-quote-modal');
    gHidePrice = false;
    $carousel = $('.carousel');
};

const instanceInit = (rootEl) => {
    let $el = $(rootEl);

    function carouselSlide() {
        if ($(window).width() <= 767) {
            let slidesPhone = Number($carousel.attr('data-slides-phone')) || 1;
            let carouselSettings = {
                arrows: true,
                dots: true,
                infinite: false,
                centerMode: true,
                centerPadding: '26px',
                slidesToShow: slidesPhone
            };
            $carousel.not('.slick-initialized').slick(carouselSettings);
        } else {
            if ($carousel.hasClass('slick-initialized')) {
                $carousel.slick('unslick');
            }
        }
    }

    $('.tire-quote-btn').on('click', function (evt) {
        evt.preventDefault();

        var elem = $(this);
        var tire_brand = elem.data('catitem-tirebrand');
        var tire_name = elem.data('catitem-tirename');
        var product_name = tire_brand + ' ' + tire_name;

        var elParent = elem.parents('.product-detail-price-block-v2');

        setCookie('bsro.ui.catitem.full', product_name);
        setCookie('bsro.ui.catitem.brand', tire_brand);
        setCookie('bsro.ui.catitem.name', tire_name);

        var _articleNumber = elem.attr('data-article');

        if (!_articleNumber) {
            $('#get-vehicle-modal').show();
            return;
        }

        quoteService.setCreateQuoteDataByEvt(evt).createQuote();
        elParent.addClass('get-quote');
    });

    function popupPosition() {
        var device_size = $(window).width();
        var pos_container_width;
        if (device_size <= 1100) {
            pos_container_width =
                $('.product-detail-tread-info .rating-image').width() +
                $('.product-detail-tread-info .wrap').width() +
                10;
            $('.product-detail-tread-info .accordion .folder').css({
                left: pos_container_width + 'px'
            });
        } else {
            pos_container_width = $('.product-detail-tread-info .wrap').width() + 60;
            $('.product-detail-tread-info .accordion .folder').css({
                left: pos_container_width + 'px'
            });
        }
    }

    $(window).resize(function () {
        carouselSlide();
        popupPosition();
    });

    $('.thumbs .thumb img').on('click', changeImage);

    $(window).on('load', (evt) => componentInit(evt, $el));
    carouselSlide();
    popupPosition();

    pubsub.subscribe(quoteService.CREATE_QUOTE_COMPLETE, createQuoteComplete);

    if (window.siteProfile.vehicles.tce.ymm === '') {
        document.querySelector('.wrapper-more-tires').style.display = 'none';
    }

    if (window.siteProfile.location.myZip === '') {
        document.querySelector('.wrapper-more-tires').style.display = 'none';
    }
};

const changeImage = (evt, data) => {
    var $sel = $(evt.currentTarget);

    var $img = $sel.attr('data-image');
    if ($img && !$img.startsWith('https://')) {
        $img = 'https://s7d1.scene7.com/is/image/' + $img.replace(/^\//, '');
    }
    var $opt = $sel.closest('.thumbs');
    var $tireLarge = $opt.parent().find('div .photo.active img');
    $tireLarge.attr('src', $img);
};

const componentInit = (evt, $el) => {
    console.log('inside product-detail-v2.js');

    var _articleId = $el.data('article-id') || null;
    var _price = null;
    var _referrerArr = window.document.referrer.split('/');
    var _referrer = _referrerArr.length ? _referrerArr.pop() : null;

    let gArticleId = _articleId;
    let gTireSize = $('.tire-size').text().trim() || 'NA';
    let searchOrigin = 'size';
    let hidePrice = false;

    // Features and Benefits show more onClickHandler
    let featuresBenefits = $('.product-detail-features-benefits-v2__container');
    let featuresBenefitsBtn = $('#productDetailFeaturesBenefitsShowMore');

    featuresBenefitsBtn.on('click', function () {
        setAdobeDataLayer({
            event: 'tire-detail-interaction',
            tdInteractionType: 'see more-features and benefits'
        });

        featuresBenefits.toggleClass('display');
        featuresBenefitsBtn.toggleClass('display');

        if (featuresBenefits.hasClass('display')) {
            featuresBenefitsBtn.html('SEE LESS');
        } else {
            featuresBenefitsBtn.html('SEE MORE');
        }
    });

    if (!isEmpty(_referrer)) {
        var _referStr = _referrer.toString().replace(/(.html)/g, '');
        if (_referStr === 'search-results') {
            $el.find('.tire-quote-btn').attr('data-type', 'vehicle');
            searchOrigin = 'vehicle';
        }
    }

    if (_articleId) {
        _price = parseFloat(getCookie(_articleId.toString()));

        if (_price == 0 || _price.toString() == 'NaN') {
            _price = null;
        }
    }

    if (window.isEcom) {
        const isEcomPricing = window.siteProfile.vehicles.tce.ymm !== '';

        if (isEcomPricing) {
            // get just pricing
            getPricing(_articleId, gArticleId, gTireSize);

            // get just inventory
            getInventory(_articleId, gArticleId);
        }
    } else if (_price == -1) {
        $('.product-detail-price-block-v2').html('<h3>Please call your local store for pricing</h3>');
    } else if (_articleId && _price) {
        setTirePricing(_price);
    }

    initProductModalEmail();

    var AvgRatingInterval = setInterval(function () {
        var avgReviewEl = $('.product-detail-v2 .average-rating');
        var ratingEl = $('.product-detail-v2 .review-count>a');
        setSeoStructuredData(avgReviewEl, ratingEl);
        clearInterval(AvgRatingInterval);
    }, 500);

    /******************* REDESIGN CODE *******************/

    //tdr modal mod.
    var modalquest = document.querySelector('.icon-checkmark.modalquestion');
    const modal = document.querySelector('.modal');
    const overlay = document.querySelector('.overlay');
    const openModalBtn = document.querySelector('.btn-open');
    const closeModalBtn = document.querySelector('.btn-close');

    const closeModal = function () {
        modal.classList.add('hidden');
        overlay.classList.add('hidden');
    };
    closeModalBtn.addEventListener('click', closeModal);
    overlay.addEventListener('click', closeModal);
    document.addEventListener('keydown', function (e) {
        if (e.key === 'Escape' && !modal.classList.contains('hidden')) {
            closeModal();
        }
    });

    const openModal = function () {
        modal.classList.remove('hidden');
        overlay.classList.remove('hidden');
    };

    openModalBtn.addEventListener('click', openModal);

    //tdr image gallery.
    const image_modal = document.querySelector('.modal.image');
    const image_overlay = document.querySelector('.overlay.image');
    const imageOpenModalExpand = document.querySelector('.btn-open-expand');
    const imageCloseModalBtn = document.querySelector('.btn-close-image');

    const imageCloseModal = function () {
        image_modal.classList.add('hidden');
        image_overlay.classList.add('hidden');
    };
    imageCloseModalBtn.addEventListener('click', imageCloseModal);
    image_overlay.addEventListener('click', imageCloseModal);
    document.addEventListener('keydown', function (e) {
        if (e.key === 'Escape' && !image_modal.classList.contains('hidden')) {
            imageCloseModal();
        }
    });

    const imageOpenModal = function () {
        image_modal.classList.remove('hidden');
        image_overlay.classList.remove('hidden');
    };

    imageOpenModalExpand.addEventListener('click', imageOpenModal);

    //tdr carousel.
    let carousel_gallery = document.querySelector('#carousel_gallery');
    let carousel_gallery_image = document.querySelector('#carousel_gallery_image');
    const nextButton = carousel_gallery.querySelector('.btn.next');
    const prevButton = carousel_gallery.querySelector('.btn.prev');
    const nextButtonImage = carousel_gallery_image.querySelector('.btn.next.image');
    const prevButtonImage = carousel_gallery_image.querySelector('.btn.prev.image');
    var thumbnailIndex = 0;
    var thumbnailIndexImage = 0;
    const thumbnailsHandlers = document.querySelectorAll('.thumbnails-image');
    const mobileTrack = document.querySelector('.gallery-scroll');
    const thumb = mobileTrack?.querySelector('.gallery-scroll__thumb');
    const thumbnailsHandlersImage = document.querySelectorAll('.thumbnails-image-modal');
    const mobileTrackImage = document.querySelector('.gallery-scroll-image');
    const thumbImage = mobileTrackImage?.querySelector('.gallery-scroll-image__thumb');
    let loadedImageCount = 0;
    let loadedModalImageCount = 0;
    let thumbWidth = 100;
    let thumbWidthImage = 100;
    if (thumbnailsHandlers.length > 0) {
        thumbWidth = 100 / thumbnailsHandlers.length;
    }
    if (thumbnailsHandlersImage.length > 0) {
        thumbWidthImage = 100 / thumbnailsHandlersImage.length;
    }
    const imagesForTirePath = document.querySelectorAll('.tirePath');
    const imagesForTirePathImage = document.querySelectorAll('.tirePathImage');
    imagesForTirePath.forEach((image) => {
        const { brand, tire, angle, zoom, full } = image.dataset;
        const imageOptions = {
            brand: stringSanitizer(brand),
            pattern: tire.toLowerCase(),
            angle: angle,
            full: full ? true : false,
            zoom: zoom ? true : false,
            web: true,
            buScope: 'global',
            buOwnership: 'bsro'
        };
        const imageParams = {
            scale: 1
        };
        const imageSrc = tirePath(imageOptions, imageParams);

        fetch(imageSrc).then((response) => {
            if (!response.ok) {
                console.info('Broken Image Error v2', imageSrc);
                image.parentNode.parentNode.remove();
            }

            if (response.status === 200) {
                image.setAttribute('src', imageSrc);
                loadedImageCount++;
                if (image.parentElement.classList.contains('zoom')) {
                    let timer; // Declare a variable to hold the timeout
                    let hasZoomed = false; // Track if the zoom has already occurred

                    image.parentElement.addEventListener('mouseenter', (e) => {
                        const zoomer = e.currentTarget;

                        // Clear the previous timeout (if any)
                        clearTimeout(timer);

                        // Set a new timeout to delay the zoom by 1 second
                        timer = setTimeout(() => {
                            if (!hasZoomed) {
                                zoomer.style.backgroundImage = `url(${imageSrc})`;
                                zoomer.style.backgroundSize = 'auto 300%';
                                image.style.opacity = '0';
                                hasZoomed = true;

                                // Activate mousemove event on the zoomed image
                                zoomer.addEventListener('mousemove', (e) => {
                                    let offsetX, offsetY, x, y;
                                    e.offsetX ? (offsetX = e.offsetX) : (offsetX = e.touches?.[0]?.pageX);
                                    e.offsetY ? (offsetY = e.offsetY) : (offsetX = e.touches?.[0]?.pageX);
                                    x = (offsetX / zoomer.offsetWidth) * 100;
                                    y = (offsetY / zoomer.offsetHeight) * 100;
                                    zoomer.style.backgroundPosition = x + '% ' + y + '%';
                                });
                            }
                        }, 400); // 400ms delay
                    });

                    image.parentElement.addEventListener('mouseleave', (e) => {
                        const zoomer = e.currentTarget;
                        clearTimeout(timer); // Clear the timeout if the user leaves before the delay
                        zoomer.style.backgroundImage = '';
                        image.style.opacity = '1';
                        hasZoomed = false; // Reset the flag for the next mouseenter
                        zoomer.removeEventListener('mousemove', (e) => {}); // Remove the mousemove event
                    });
                }
            } else {
                const thumb = image.closest('.thumbnails-image');
                const zoom = image.closest('.zoom-img');
                if (thumb) thumb.style.display = 'none';
                if (zoom) zoom.style.display = 'none';
            }
            // Checking the count of Loaded Images in Detail page with 2 because the thumbnails and profile images have same class
            // So if Product has only image in response so one is loaded in thumbnails and other in Profile.
            if (loadedImageCount === 2) {
                nextButton.classList.add('inactive');
                nextButton.removeEventListener('click', scrollToNextPage);
                prevButton.removeEventListener('click', scrollToPrevPage);
            } else {
                nextButton.classList.remove('inactive');
                nextButton.addEventListener('click', scrollToNextPage);
                prevButton.addEventListener('click', scrollToPrevPage);
            }
        });
    });
    imagesForTirePathImage.forEach((image) => {
        const { brand, tire, angle, zoom, full } = image.dataset;
        const imageOptions = {
            brand: stringSanitizer(brand),
            pattern: tire.toLowerCase(),
            angle: angle,
            full: full ? true : false,
            zoom: zoom ? true : false,
            web: true,
            buScope: 'global',
            buOwnership: 'bsro'
        };
        const imageParams = {
            scl: 1
        };
        const imageSrc = tirePath(imageOptions, imageParams);
        fetch(imageSrc).then((response) => {
            if (response.status === 200) {
                image.setAttribute('src', imageSrc);
                loadedModalImageCount++;

                if (image.parentElement.classList.contains('zoom')) {
                    let timer; // Declare a variable to hold the timeout
                    let hasZoomed = false; // Track if the zoom has already occurred

                    image.parentElement.addEventListener('mouseenter', (e) => {
                        const zoomer = e.currentTarget;

                        // Clear the previous timeout (if any)
                        clearTimeout(timer);

                        // Set a new timeout to delay the zoom by 1 second
                        timer = setTimeout(() => {
                            if (!hasZoomed) {
                                zoomer.style.backgroundImage = `url(${imageSrc})`;
                                zoomer.style.backgroundSize = 'auto 300%';
                                image.style.opacity = '0';
                                hasZoomed = true;

                                // Activate mousemove event on the zoomed image
                                zoomer.addEventListener('mousemove', (e) => {
                                    let offsetX, offsetY, x, y;
                                    e.offsetX ? (offsetX = e.offsetX) : (offsetX = e.touches?.[0]?.pageX);
                                    e.offsetY ? (offsetY = e.offsetY) : (offsetX = e.touches?.[0]?.pageX);
                                    x = (offsetX / zoomer.offsetWidth) * 100;
                                    y = (offsetY / zoomer.offsetHeight) * 100;
                                    zoomer.style.backgroundPosition = x + '% ' + y + '%';
                                });
                            }
                        }, 400); // 400ms delay
                    });

                    image.parentElement.addEventListener('mouseleave', (e) => {
                        const zoomer = e.currentTarget;
                        clearTimeout(timer); // Clear the timeout if the user leaves before the delay
                        zoomer.style.backgroundImage = '';
                        image.style.opacity = '1';
                        hasZoomed = false; // Reset the flag for the next mouseenter
                        zoomer.removeEventListener('mousemove', (e) => {}); // Remove the mousemove event
                    });
                }
            } else {
                const thumb = image.closest('.thumbnails-image-modal');
                const zoom = image.closest('.zoom-img');
                if (thumb) thumb.style.display = 'none';
                if (zoom) zoom.style.display = 'none';
            }
            // Checking the count of Loaded Images in Modal with 2 because the thumbnails and profile images have same class
            // So if Product has only image in response so one is loaded in thumbnails and other in Profile.
            if (loadedModalImageCount === 2) {
                nextButtonImage.classList.add('inactive');
                nextButtonImage.removeEventListener('click', scrollToNextPageImage);
                prevButtonImage.removeEventListener('click', scrollToPrevPageImage);
            } else {
                nextButtonImage.classList.remove('inactive');
                nextButtonImage.addEventListener('click', scrollToNextPageImage);
                prevButtonImage.addEventListener('click', scrollToPrevPageImage);
            }
        });
    });
    thumbnailsHandlers.forEach((thumbnailsHandler, index) => {
        thumbnailsHandler.querySelector('a').addEventListener('click', (e) => {
            e.preventDefault();
            const href = e.currentTarget.getAttribute('href');
            setZoomImage(index, href.replace('#', ''));
        });
    });
    thumbnailsHandlersImage.forEach((thumbnailsHandlerImage, index) => {
        thumbnailsHandlerImage.querySelector('a').addEventListener('click', (e) => {
            e.preventDefault();
            const href = e.currentTarget.getAttribute('href');
            setZoomImageModal(index, href.replace('#', ''));
        });
    });
    nextButton.addEventListener('click', scrollToNextPage);
    prevButton.addEventListener('click', scrollToPrevPage);
    nextButtonImage.addEventListener('click', scrollToNextPageImage);
    prevButtonImage.addEventListener('click', scrollToPrevPageImage);

    const setThumbPositionByIndex = (index) => {
        thumb.style.left = `${index * thumbWidth}%`;
        thumb.style.width = `${thumbWidth}%`;
    };
    const setThumbPositionByIndexImage = (index) => {
        thumbImage.style.left = `${index * thumbWidthImage}%`;
        thumbImage.style.width = `${thumbWidthImage}%`;
    };
    const arrowStatusByIndex = (thumbnailIndex) => {
        if (thumbnailIndex === thumbnailsHandlers.length - 1) {
            nextButton.classList.add('inactive');
        } else {
            nextButton.classList.remove('inactive');
        }

        if (thumbnailIndex === 0) {
            prevButton.classList.add('inactive');
        } else {
            prevButton.classList.remove('inactive');
        }
    };
    const arrowStatusByIndexImage = (thumbnailIndexImage) => {
        if (thumbnailIndexImage === thumbnailsHandlersImage.length - 1) {
            nextButtonImage.classList.add('inactive');
        } else {
            nextButtonImage.classList.remove('inactive');
        }
        if (thumbnailIndexImage === 0) {
            prevButtonImage.classList.add('inactive');
        } else {
            prevButtonImage.classList.remove('inactive');
        }
    };
    const goToImageByIndex = (thumbnailIndex) => {
        thumbnailsHandlers.forEach((thumbnailsHandler) => {
            thumbnailsHandler.classList.remove('active');
        });
        const thumbnailElement = thumbnailsHandlers.item(thumbnailIndex);
        thumbnailElement.classList.add('active');
        const href = thumbnailElement?.querySelector('a').getAttribute('href').replace('#', '');
        const elementToScroll = document.getElementById(href);
        elementToScroll.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        setThumbPositionByIndex(thumbnailIndex);
    };

    const goToImageByIndexImage = (thumbnailIndexImage) => {
        thumbnailsHandlersImage.forEach((thumbnailsHandlerImage) => {
            thumbnailsHandlerImage.classList.remove('active');
        });
        const thumbnailElement = thumbnailsHandlersImage.item(thumbnailIndexImage);
        thumbnailElement.classList.add('active');
        const href = thumbnailElement?.querySelector('a').getAttribute('href').replace('#', '');
        const elementToScroll = document.getElementById(href);
        elementToScroll.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        setThumbPositionByIndexImage(thumbnailIndexImage);
    };

    setThumbPositionByIndex(thumbnailIndex);
    setThumbPositionByIndexImage(thumbnailIndexImage);

    function scrollToNextPage() {
        const nextIndex = thumbnailIndex + 1;

        if (nextIndex >= thumbnailsHandlers.length) {
            // nextButton.classList.add('inactive');
        } else {
            thumbnailIndex = nextIndex;
            goToImageByIndex(thumbnailIndex);
        }

        arrowStatusByIndex(thumbnailIndex);
    }
    function scrollToPrevPage() {
        const prevIndex = thumbnailIndex - 1;
        if (prevIndex < 0) {
            // Empty block
        } else {
            thumbnailIndex = prevIndex;
            goToImageByIndex(thumbnailIndex);
        }

        arrowStatusByIndex(thumbnailIndex);
    }

    function scrollToNextPageImage() {
        const nextIndex = thumbnailIndexImage + 1;

        if (nextIndex >= thumbnailsHandlersImage.length) {
            // nextButton.classList.add('inactive');
        } else {
            thumbnailIndexImage = nextIndex;

            goToImageByIndexImage(thumbnailIndexImage);
        }

        arrowStatusByIndexImage(thumbnailIndexImage);
    }
    function scrollToPrevPageImage() {
        const prevIndex = thumbnailIndexImage - 1;
        if (prevIndex < 0) {
            // Empty block
        } else {
            thumbnailIndexImage = prevIndex;
            goToImageByIndexImage(thumbnailIndexImage);
        }

        arrowStatusByIndexImage(thumbnailIndexImage);
    }

    document.addEventListener('keydown', (e) => {
        if (e.key === 'ArrowLeft') {
            if (!prevButtonImage.classList.contains('inactive') || !prevButton.classList.contains('inactive')) {
                thumbnailIndexImage =
                    (thumbnailIndexImage - 1 + thumbnailsHandlersImage.length) % thumbnailsHandlersImage.length;
                thumbnailIndex = (thumbnailIndex - 1 + thumbnailsHandlers.length) % thumbnailsHandlers.length;
                goToImageByIndex(thumbnailIndex);
                arrowStatusByIndex(thumbnailIndex);
                goToImageByIndexImage(thumbnailIndexImage);
                arrowStatusByIndexImage(thumbnailIndexImage);
            }
        } else if (e.key === 'ArrowRight') {
            if (!nextButtonImage.classList.contains('inactive') || !nextButton.classList.contains('inactive')) {
                thumbnailIndexImage = (thumbnailIndexImage + 1) % thumbnailsHandlersImage.length;
                thumbnailIndex = (thumbnailIndex + 1) % thumbnailsHandlers.length;
                goToImageByIndex(thumbnailIndex);
                arrowStatusByIndex(thumbnailIndex);
                goToImageByIndexImage(thumbnailIndexImage);
                arrowStatusByIndexImage(thumbnailIndexImage);
            }
        }
    });

    const setZoomImage = (index, href) => {
        thumbnailIndex = index;
        const elementToScroll = document.getElementById(href);
        elementToScroll.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        thumbnailsHandlers.forEach((thumbnailsHandler) => {
            thumbnailsHandler.classList.remove('active');
        });
        thumbnailsHandlers.item(thumbnailIndex).classList.add('active');
        setThumbPositionByIndex(thumbnailIndex);
        arrowStatusByIndex(thumbnailIndex);
    };

    const setZoomImageModal = (index, href) => {
        thumbnailIndexImage = index;
        const elementToScroll = document.getElementById(href);
        elementToScroll.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        thumbnailsHandlersImage.forEach((thumbnailsHandlerImage) => {
            thumbnailsHandlerImage.classList.remove('active');
        });
        thumbnailsHandlersImage.item(thumbnailIndexImage).classList.add('active');
        setThumbPositionByIndexImage(thumbnailIndexImage);

        arrowStatusByIndexImage(thumbnailIndexImage);
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((elem) => {
            if (elem.isIntersecting) {
                const index = parseInt(elem.target.dataset.index);
                console.log('Ping! Visible: ', index);
                thumbnailIndex = index;
                thumbnailsHandlers.forEach((thumbnailsHandler) => thumbnailsHandler.classList.remove('active'));
                thumbnailsHandlers.item(thumbnailIndex).classList.add('active');
                setThumbPositionByIndex(thumbnailIndex);

                arrowStatusByIndex(thumbnailIndex);
            }
        });
    });

    const observerImage = new IntersectionObserver((entries) => {
        entries.forEach((elem) => {
            if (elem.isIntersecting) {
                const index = parseInt(elem.target.dataset.index);
                console.log('Ping! Visible: ', index);
                thumbnailIndexImage = index;
                thumbnailsHandlersImage.forEach((thumbnailsHandlerImage) =>
                    thumbnailsHandlerImage.classList.remove('active')
                );
                thumbnailsHandlersImage.item(thumbnailIndexImage).classList.add('active');
                setThumbPositionByIndexImage(thumbnailIndexImage);

                arrowStatusByIndexImage(thumbnailIndexImage);
            }
        });
    });

    const mediaQuery = '(max-width: 1024px)';
    const mediaQueryList = window.matchMedia(mediaQuery);

    const addObserver = (event) => {
        if (event.matches) {
            document.querySelectorAll('.zoom-img').forEach((elem) => observer.observe(elem));
        } else {
            document.querySelectorAll('.zoom-img').forEach((elem) => observer.unobserve(elem));
        }
    };

    const addObserverImage = (event) => {
        if (event.matches) {
            document.querySelectorAll('.zoom-img').forEach((elem) => observerImage.observe(elem));
        } else {
            document.querySelectorAll('.zoom-img').forEach((elem) => observerImage.unobserve(elem));
        }
    };

    addObserver(mediaQueryList);
    addObserverImage(mediaQueryList);

    mediaQueryList.addEventListener('change', (event) => {
        addObserver(event);
        addObserverImage(event);
    });

    //Rear tire size code
    const rearData = getLocalStorageItem('quoteResponseTempData').rearTire;
    if (rearData) {
        const frontText = document.querySelector('.tire-size:not(.rear)');
        const frontSize = frontText.dataset.tireSize || 'NA';
        const rearText = document.querySelector('.tire-size.rear');
        frontText.innerHTML = `FRONT SIZE: ${frontSize}`;
        rearText.innerHTML = `REAR SIZE: ${rearData.tireSize || 'NA'}`;
        rearText.style.display = 'block';
    }

    //See more specs code
    const handler = document.querySelector('.product-detail-tire-specs-v2 .action-handler button');
    const handlerText = handler?.querySelector('span');
    const specsContent = document.querySelector('.product-detail-tire-specs-v2 .specs-content-display');
    handler.addEventListener('click', () => {
        setAdobeDataLayer({
            event: 'tire-detail-interaction',
            tdInteractionType: 'see more - specifications'
        });

        if (specsContent.classList.contains('active')) {
            handlerText.innerHTML = 'See More Specs';
        } else {
            handlerText.innerHTML = 'See Less Specs';
        }
        specsContent.classList.toggle('active');
        handler.classList.toggle('active');
    });

    //Performance code
    const element = document.querySelector('.performance-graph');

    const fetchPerformanceData = async () => {
        const catalogData = await getCatalogAllTires();
        const allTires = await catalogData?.data?.displays;
        const articleSection = document.querySelector('[data-tire-model]');
        let model = '';
        if (articleSection) {
            model = articleSection.dataset.tireModel;
        }
        let tireData = {
            dryTraction: '0',
            rideComfort: '0',
            tireWear: '0',
            wetTraction: '0',
            noiseLevel: '0',
            tireStability: '0',
            tractionInSnowIce: '0'
        };

        allTires.forEach((tire) => {
            if (tire.model === model) {
                const {
                    dryTraction,
                    rideComfort,
                    tireWear,
                    wetTraction,
                    noiseLevel,
                    tireStability,
                    tractionInSnowIce
                } = tire;
                tireData = {
                    dryTraction,
                    rideComfort,
                    tireWear,
                    wetTraction,
                    noiseLevel,
                    tireStability,
                    tractionInSnowIce
                };
            }
        });

        return tireData;
    };

    const getPercentage = (strValue) => {
        const value = parseFloat(strValue);
        const percentage = value * 10;
        return percentage;
    };

    const statRender = (stat, name) => {
        return `<li>
                    <h5 class="tsr-card__stat-name">${name} </h5>
                    <div class="tsr-card__stat-bar">
                        <div class="tsr-card__stat-graph" style="width: ${getPercentage(stat)}%"></div>
                    </div>
                    <h5> ${stat === 0 ? 'N/A' : stat}</h5>
                </li>`;
    };

    const graphsRender = async () => {
        console.log('GraphsRender start');
        const fetchedData = await fetchPerformanceData();
        let renderString = '';
        const orderArray = ['dryTraction', 'wetTraction', 'tractionInSnowIce', 'rideComfort', 'noiseLevel', 'tireWear'];
        const namesArray = ['Dry', 'Wet', 'Snow', 'Comfort', 'Quiet Ride', 'Tread Life'];

        orderArray.forEach((perfItem, i) => {
            const stat = fetchedData[perfItem];
            const name = namesArray[i];
            renderString += statRender(stat, name);
        });

        element.innerHTML = `<ul> ${renderString}</ul>`;
    };

    graphsRender();
};

const createQuoteComplete = (data) => {
    var _quoteId = data.tireQuoteId;

    if (_quoteId != undefined && _quoteId != null) {
        var _path = '/tires/tire-quote/' + _quoteId;

        if (window.location.port) {
            _path = '/content/bsro/' + window.AEM.siteName + '/en/tires/tire-quote.html?quoteId=' + _quoteId;
        }

        window.location.href = _path;
    }
};

const setTirePricing = (gArticleId, gTireSize, price, allArticleIds, high) => {
    var _price = null;
    var $priceDetail = $('.product-detail-price-block-v2');

    if (allArticleIds) {
        price = high ? price.high : price.low;
        _price = price.price ? price.price.toString() : '0';

        if (window.isEcom) {
            window._satellite.setVar('ecomData', {
                event: {
                    eventName: 'product.view'
                },
                product: {
                    item: [
                        {
                            productID: price.articleId ? price.articleId.toString() : null,
                            size: price.size ? price.size.toString() : null
                        }
                    ]
                }
            });
        }
    } else {
        _price = price ? price.toString() : '0';

        window._satellite.setVar('ecomData', {
            event: {
                eventName: 'product.view'
            },
            product: {
                item: [
                    {
                        productID: gArticleId ? gArticleId.toString() : null,
                        size: gTireSize ? gTireSize.toString() : null
                    }
                ]
            }
        });
    }

    window._satellite.track('ecommerce');

    var _priceNumArray, _dollars, _cents;

    if (parseInt(_price) != 0) {
        _priceNumArray = _price.split('.');
        _dollars = _priceNumArray[0];
        _cents = _priceNumArray[1];

        $('.dollars').text(_dollars);
        $('.cents').text(_cents);

        var _totalPrice = parseFloat(_price) * 4;
        _totalPrice = _totalPrice.toFixed(2);
        var _totalPriceNumArray = _totalPrice.toString().split('.');
        var _totalPriceDollars = _totalPriceNumArray[0];
        var _totalPriceCents = _totalPriceNumArray[1];

        $('.totalPriceDollars').text(_totalPriceDollars);
        $('.totalPriceCents').html('<sup>' + _totalPriceCents + '</sup>');

        if ($priceDetail.hasClass('ecom')) {
            $priceDetail.addClass('with-price');
            if (allArticleIds && !high) {
                $priceDetail.addClass('low');
            }

            if (gHidePrice) {
                $priceDetail.find('.price,.total,.coupon-cta').remove();
                $priceDetail.addClass('with-hidden-price');
            }
        }
    } else {
        if ($priceDetail.hasClass('ecom')) {
            $priceDetail.addClass('without-price');
        }
    }
};

const setSeoStructuredData = (avgReviewEl, ratingEl) => {
    window.SeoStructuredData = window.SeoStructuredData || {};

    window.SeoStructuredData.productSchema = Object.assign(window.SeoStructuredData.productSchema || {}, {
        tirePrice: '',
        url: '',
        ratingValue: '',
        ratingCount: '',
        init: function () {
            this.tirePrice = this.getTirePrice();
            this.url = window.location.href;
            this.ratingValue = this.getAvgRatingValue();
            this.ratingCount = this.getAvgRatingCount();
            // this.getRatingCount(5);
            // this.getRatingCount(1);
        },
        getTirePrice: function () {
            var price = '';
            var priceContainer = $(".quote-content .price>div:contains('$')");
            if (!priceContainer.length) {
                priceContainer = $(".quote-order-summary .heading-row>div:contains('$')");
                if (!priceContainer.length) {
                    priceContainer = $(".quote-content>div>div:contains('$')");
                }
            }

            if (priceContainer.length > 0) {
                price = priceContainer.get(0).innerText;
                price = price.replace(/(\$|\/|\b(?:ea|tire)\b)/g, '');
            } else {
                const currentTirePriceData = getLocalStorageItem('currentTirePrice');
                price = currentTirePriceData.rawPrice.toString();
            }

            return price;
        },
        // getRatingCount: function (rating) {
        //     var ratingEl = $('.number-of-stars:contains(' + rating + ')');
        //     var ratingStr = ratingEl.length > 0 && ratingEl.siblings('.count').get(0).innerText;
        //     return ratingStr ? ratingStr.replace(/\D/g, '') : '';
        // },
        getAvgRatingValue: function () {
            avgReviewEl = avgReviewEl.length ? avgReviewEl : $('.ratings-and-reviews .average-rating');
            var avgReviewCount = avgReviewEl.length > 0 && avgReviewEl.get(0).innerText;
            return avgReviewCount ? avgReviewCount.replace(/[^0-9.]/g, '') : '';
        },
        getAvgRatingCount: function () {
            ratingEl = ratingEl.length ? ratingEl : $('.ratings-and-reviews .ratings-summary .title');
            var ratingStr = ratingEl.length > 0 && ratingEl.get(0).innerText;
            return ratingStr ? ratingStr.replace(/\D/g, '') : '';
        },
        addSeoScript: function () {
            this.init();
            var structuredDataText = {
                '@context': 'https://schema.org/',
                '@type': 'Product',
                name: this.name,
                image: this.image,
                description: this.description,
                brand: this.brand,
                sku: '',
                offers: {
                    '@type': 'Offer',
                    url: this.url,
                    priceCurrency: 'USD',
                    price: this.tirePrice,
                    availability: '',
                    itemCondition: ''
                }
            };

            if (this.ratingValue && this.ratingCount) {
                structuredDataText.aggregateRating = {
                    '@type': 'AggregateRating',
                    ratingValue: this.ratingValue,
                    bestRating: '5',
                    worstRating: '1',
                    ratingCount: this.ratingCount
                };
            }
            const script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.textContent = JSON.stringify(structuredDataText, null, 2);
            document.head.appendChild(script);
        }
    });

    if (window.SeoStructuredData.productSchema.addSeoScript) {
        try {
            window?.SeoStructuredData?.productSchema?.addSeoScript();
        } catch (error) {
            console.error('Error executing addSeoScript:', error);
        }
    } else {
        console.error('SeoStructuredData or productSchema is not initialized properly.');
    }
};

const getPricing = (articleId, gArticleId, gTireSize) => {
    var _data = {
        storeNumber: window.siteProfile.location.storeNumber,
        articleIDs: articleId.toString()
    };

    $.getJSON('/bsro/services/store/pricing-details', _data).done(function (data) {
        var _pData = data.data.products;
        var _pricing = _pData && _pData[0];
        var _highlow = null;
        var _promotions = _pricing && _pricing.potentialPromotions && _pricing.potentialPromotions[0];
        var _rebates = _pricing && _pricing.rebates && _pricing.rebates[0];

        gHidePrice = (_promotions && _promotions.hidePrice) || false;

        if (_pData.length) {
            if (_pData.length > 1) {
                _highlow = getPricingHighLow(_pData);
            }

            if (_pricing && _highlow == null) {
                var _price = _pricing.price && _pricing.price.value;
                var _salePrice = (_promotions && _promotions.salePrice.value) || 0;

                if (_salePrice) {
                    _price = _salePrice;
                }

                setTirePricing(gArticleId, gTireSize, _price);
            } else {
                setTirePricing(gArticleId, gTireSize, _highlow, true);
            }

            if (_promotions || _rebates) {
                var _isRebate = _rebates ? true : false;

                setTirePromotion(_promotions || _rebates, _isRebate);
            }
        }
    });
};

const getInventoryPricing = (articleId) => {
    var _data = {
        storeNumber: window.siteProfile.location.storeNumber,
        articleIDs: articleId
    };

    $.getJSON('/bsro/services/store/inventory-pricing-details', _data).done(function (data) {
        var _pData = data.data.pricingdata;
        var _pricing = _pData && _pData[0];
        var _highlow = null;

        if (_pData.length) {
            if (_pData.length > 1) {
                _highlow = getPricingHighLow(_pData);
            }

            if (_pricing && _highlow == null) {
                var _price = _pricing.price && _pricing.price.value;
                var _promotions = _pricing.potentialPromotions && _pricing.potentialPromotions[0];
                var _salePrice = (_promotions && _promotions.salePrice.value) || 0;

                if (_salePrice) {
                    _price = _salePrice;
                }

                setTirePricing(_price);
            } else {
                setTirePricing(_highlow, true);
            }
        }
    });
};

/* taken from ProductResults but it looks like
getInventoryPricing below might suffice */

const getInventory = (articleIds, gArticleId) => {
    var _articleIds = articleIds || gArticleId;
    var _data = {
        storeNumber: window.siteProfile.location.storeNumber,
        articleIDs: _articleIds.toString()
    };

    $.getJSON('/bsro/services/store/inventory', _data)
        .done(function (data) {
            // console.log("setInventory() inventory response:", data);
            var _inventory = (data.data && data.data.tires) || data.data;
            var hasZip = Boolean(window.siteProfile.location.myZip);

            // console.log("Inventory: ", _inventory);
            if (_inventory && hasZip) {
                var _i = 0;
                var _ilen = _inventory.length;
                var $quoteBtn = $('.tire-quote-btn');

                for (_i; _i < _ilen; _i++) {
                    var _iData = _inventory[_i];
                    var _quantity = parseInt(_iData.quantity);
                    var _articleId = parseInt(_iData.part_number);
                    var _storeQuantity = parseInt(_iData.q_local);
                    // var _sisterStoreQuantity = parseInt(_iData.q_sister);
                    document.querySelector('span.in-stock').style.display = 'none';

                    if (!isArray(_articleIds) && _articleIds == _articleId) {
                        $quoteBtn.attr('data-inventory-id', _iData.id);
                        $quoteBtn.attr('data-inventory-quantity', _quantity);

                        var $storeInfo = $('a.store-number').clone();
                        var _store = $('.store-info span strong').text().trim();

                        $storeInfo.text(_store);

                        // If there is a query string with the store number, we need a different inventory experience
                        const hasStoreQueryString = Boolean(getUrlParameter('store') || '');

                        if (hasStoreQueryString) {
                            if (_quantity >= 3) {
                                $('span.in-stock').append('In stock at&nbsp;');
                                $('.product-detail-stock').addClass('in-stock');
                            } else if (_quantity > 0) {
                                $('span.in-stock').append('Limited availability at&nbsp; ');
                                $('.product-detail-stock').addClass('in-stock');
                            } else {
                                $('span.in-stock').append('Out of stock at&nbsp; ');
                                $('.in-stock > .icon-green-check').hide();
                            }
                        } else {
                            if (_storeQuantity >= 2) {
                                $('span.in-stock').append('In stock at&nbsp;');
                                $('.product-detail-stock').addClass('in-stock');
                            } else if (_quantity >= 2) {
                                $('span.in-stock').append('Usually available same day at&nbsp; ');
                                $('.product-detail-stock').addClass('in-stock');
                            } else {
                                $('span.in-stock').append('Call for availability at&nbsp; ');
                            }
                        }

                        $('span.in-stock').append($storeInfo);
                        $('.product-detail-stock').css({ display: 'flex' });
                        // $('.product-detail-stock').show();
                        document.querySelector('span.in-stock').style.display = 'block';

                        break;
                    } else {
                        document.querySelector('span.in-stock').style.display = 'none';
                    }
                }
            } else {
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("+++ INVENTORY DATA RETURNED NULL +++", _data)
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("++++++++++++++++++++++++++++++++++++")
            }
        })
        .fail(function (data) {
            //console.log("++++++++++++++++++++++++++++++++++++")
            //console.log("++++++++++++++++++++++++++++++++++++")
            //console.log("+++++++ INVENTORY CALL FAILED ++++++", _data)
            //console.log("++++++++++++++++++++++++++++++++++++")
            //console.log("++++++++++++++++++++++++++++++++++++")
        });

    if (
        window.siteProfile &&
        window.siteProfile.vehicles.tce.ymm != '' &&
        window.siteProfile.vehicles.tce.ymm != undefined &&
        window.siteProfile.vehicles.tce.trim != undefined
    ) {
        $.getJSON(
            '/bsro/services/tires/search-by-vehicle?ymm=' +
                window.siteProfile.vehicles.tce.ymm +
                '&trim=' +
                window.siteProfile.vehicles.tce.trim
        )
            .done(function (data) {
                //console.log("vehicle fitment data:", data);
                const _allArticles = data.data && data.data.allArticles;
                const _fitment = data.data && data.data.vehicleFitment;

                if (_fitment) {
                    let _veh = _fitment.year + ' ' + _fitment.make + ' ' + _fitment.model + ' ' + _fitment.submodel;

                    if (_allArticles.indexOf(gArticleId.toString()) != -1) {
                        //console.log("tire fits vehicle fitment");
                        _veh = 'This tire fits your vehicle';
                        $('.stock-vehicle-fitment').append(_veh);
                        $('.stock-vehicle-fitment').show();
                    } else {
                        if (gArticleId.length > 1) {
                            $('.stock-vehicle-fitment').hide();
                        }
                    }
                }
            })
            .fail(function (data) {
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("++  VECHILE FITMENT CHECK FAILED  ++", _data)
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("++++++++++++++++++++++++++++++++++++")
            });
    }
};

const getPricingHighLow = (pData, highlow) => {
    var _articlePrices = [];
    var _highlow = { high: null, low: null };

    for (var i = 0; i < pData.length; i++) {
        var p = pData[i];
        var _price = p.price && p.price.value;
        var _articleId = p.code;
        var _nameItems = p['name'] ? p.name.split(' ') : [];
        var _articlePriceObj = { articleId: null, price: null, size: null };

        if (p.potentialPromotions && p.potentialPromotions.length) {
            _price = p.potentialPromotions[0].salePrice.value;
        }

        _nameItems.sort(function (a, b) {
            if (a.indexOf('/') != -1) {
                return -1;
            }

            if (b.indexOf('/') != -1) {
                return 1;
            }

            return 0;
        });

        _articlePriceObj.articleId = _articleId;
        _articlePriceObj.size = _nameItems[0];
        _articlePriceObj.price = _price ? _price.toFixed(2) : null;

        _articlePrices.push(_articlePriceObj);
    }

    _articlePrices.sort(function (a, b) {
        return a.price - b.price;
    });

    var _high = _articlePrices.pop();
    var _low = _articlePrices[0];

    _highlow.high = _high;
    _highlow.low = _low;

    return _highlow;
};

const setTirePromotion = (promotion, isRebate) => {
    if (promotion) {
        var $couponRebate = $('.coupon-rebate');
        var $couponRebateLink = $('.coupon-rebate-link');

        var _couponRebate = promotion;
        var _couponRebateLink = $couponRebateLink.attr('href') + '/' + _couponRebate.code + '/';

        $couponRebate.find('span').prepend(_couponRebate.displayName || _couponRebate.label);
        $couponRebateLink.attr('href', _couponRebateLink);

        if (isRebate) {
            $couponRebateLink.text('Rebate');
        }

        $couponRebate.parent().show();
    }
};

const initProductModalEmail = () => {
    const emailModalContentWrapper = emailModal?.querySelector('.email-wrapper');
    const emailModalThankYouWrapper = emailModal?.querySelector('.thank-you-wrapper');

    const emailModalContentHandlebarsTmpl = document
        .querySelector('[data-email-quote-template]')
        .cloneNode(true).innerHTML;
    const emailModalTemplate = Handlebars.compile(emailModalContentHandlebarsTmpl);

    const props = { emailModalContentWrapper, emailModalThankYouWrapper, emailModalTemplate };

    $(emailModal).on('show hide', (evt) => onProductModalEmailShowHide(evt, props));

    if (window.quoteData) {
        setProductModalEmailQuoteDataContent(props);
    }
};

const initProductModalEmailForm = () => {
    const emailModalForm = emailModal?.querySelector('form');

    emailModalForm.addEventListener('change', (evt) => handleProductModalEmailFormFieldChange(evt, emailModalForm));
    emailModalForm.addEventListener('blur', (evt) => handleProductModalEmailFormFieldChange(evt, emailModalForm));
    emailModalForm.addEventListener('keyup', (evt) => handleProductModalEmailFormFieldChange(evt, emailModalForm));

    emailModalForm.addEventListener('submit', (evt) => submitProductModalEmailForm(evt, emailModalForm));

    const submitErrorElements = emailModalForm.querySelectorAll('.submitError, .emailError');
    submitErrorElements.forEach((element) => {
        element.textContent = '';
    });

    console.log('initProductModalEmailForm emailModalForm', emailModalForm);
};

const handleProductModalEmailFormFieldChange = (evt, emailModalForm) => {
    const firstName = emailModal?.querySelector('[name=firstName]').value;
    const lastName = emailModal?.querySelector('[name=lastName]').value;
    const email = emailModal?.querySelector('[name=emailAddress]').value;
    const submitError = emailModalForm?.querySelector('.submitError');

    submitError.textContent = '';

    let disabled = true;
    if (firstName !== '' && lastName !== '' && email !== '') {
        disabled = false;
    }

    const sendEmailQuote = emailModal?.querySelector('.send-email-quote');
    if (sendEmailQuote) {
        sendEmailQuote.disabled = disabled;
    }

    console.log('handleProductModalEmailFormFieldChange', 'evt:', evt);
};

const onProductModalEmailShowHide = (evt, props) => {
    emailModal.classList.remove('thank-you');

    if (evt.type == 'show') {
        setProductModalEmailQuoteDataContent(props);
    }
};

const onSuccessfulEmailQuote = (emailModalForm) => {
    const yesButton = emailModalForm.querySelector('.schedule-btn');
    if (!yesButton) return;

    yesButton.addEventListener('click', () => {
        setApptNotes();
    });
};

const submitProductModalEmailForm = async (evt, emailModalForm) => {
    evt.preventDefault();

    if (!emailModalForm) {
        console.error('UNEXPECTED: Missing emailModalForm');
        return;
    }
    const _data = serializeForm(emailModalForm);
    _data.optin = _data.optin ? 'Yes' : 'No';

    const emailValidRes = testEmail(_data.emailAddress);

    if (emailValidRes) {
        console.log('submitProductModalEmailForm _data:', _data);

        const emailQuoteResponse = await fetchEmailQuoteData(_data);

        if (emailQuoteResponse && emailQuoteResponse.success === 'true') {
            emailModal.classList.add('thank-you');
            emailModalForm.querySelector('input').value = '';
            onSuccessfulEmailQuote(emailModal);
        } else {
            emailModalForm.querySelector('.submitError').innerText =
                'Looks like we ran into an issue sending your quote. Please try again later.';
        }
    }
};

const fetchEmailQuoteData = async (paramData) => {
    const url = `/bsro/services/contact/emailquote${queryStringify(paramData)}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const setProductModalEmailQuoteDataContent = async (props) => {
    const { emailModalContentWrapper, emailModalThankYouWrapper, emailModalTemplate } = props;
    if (window.quoteData) {
        emailModalContentWrapper.innerHTML = emailModalTemplate(window.quoteData);

        initProductModalEmailForm(emailModalContentWrapper);

        const storeDetails = await fetchStoreDetails();

        if (storeDetails && storeDetails.data) {
            setProductModalEmailThankYouContent(storeDetails, emailModalThankYouWrapper);
        }
    }
};

const fetchStoreDetails = async () => {
    const url = `/bsro/services/store/details/${window.quoteData.storeNumber}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const setProductModalEmailThankYouContent = (data, emailModalThankYouWrapper) => {
    const _store = data && data.data;
    const _appointmentHREF = `/appointment/schedule-appointment/?storeNumber=${_store.storeNumber}&src=tq`;
    const _backResultsPath = document.querySelector('.ecomm-back-to-results').getAttribute('href');
    let _storeStatus =
        _store.activeFlag == '1' && _store.onlineAppointmentActiveFlag == '1' ? 'activeStore' : 'callStore';

    if (emailModalThankYouWrapper) {
        emailModalThankYouWrapper.querySelector('.phoneNumber').textContent = _store?.phone.replace(/[()-]/g, '');
        emailModalThankYouWrapper.querySelector('.back-btn').setAttribute('href', _backResultsPath);
        emailModalThankYouWrapper.querySelector('.schedule-btn').setAttribute('href', _appointmentHREF);
    }

    if (_store.activeFlag == '0' && _store.storeType != 'FCAC') {
        _storeStatus = 'closedStore';
    }

    if (_store.storeType == 'TPL') {
        _storeStatus = 'callStore';
    }

    emailModalThankYouWrapper.classList.add(_storeStatus);

    console.log('setProductModalEmailThankYouContent');
};

const setApptNotes = () => {
    const quote = getLocalStorageItem('quoteResponseTempData');
    if (!quote) return;

    const frontTire = quote.tire;
    const rearTire = quote.rearTire;
    let apptNotes = `tire quote id:  ${quote.tireQuoteId}`;

    if (
        frontTire &&
        frontTire.article &&
        frontTire.tireName &&
        frontTire.retailPrice &&
        frontTire.tireSize &&
        quote.quantity
    ) {
        apptNotes += `,  tire article number: ${frontTire.article},  tire description: ${frontTire.tireName},  tire size: ${frontTire.tireSize},  tire quantity: ${quote.quantity},  tire price: ${frontTire.retailPrice}`;
    }

    if (
        rearTire &&
        rearTire.article &&
        frontTire.tireName &&
        rearTire.retailPrice &&
        rearTire.tireSize &&
        quote.rearQuantity
    ) {
        apptNotes += `,  rear tire article number: ${rearTire.article},  rear tire description: ${frontTire.tireName},  rear tire size: ${rearTire.tireSize},  rear tire quantity: ${quote.rearQuantity},  rear tire price: ${rearTire.retailPrice}`;
    }

    apptNotes += ',  tire quote End;';

    setLocalStorageItem('apptNotes', apptNotes);
};

component({
    init,
    instanceInit,
    name: 'ProductDetailV2',
    selector: '[data-product-detail-v2]'
});
