import { timeOfDayToHours } from 'common/js/util/date-time/timeOfDayToHours';

const blockOutTime = (timeSlots, blockUntilHours) => {
    if (!timeSlots) {
        return;
    }

    var timeSlotsIndex = timeSlots.length;
    while (timeSlotsIndex--) {
        const timeSlotData = timeSlots[timeSlotsIndex];
        const timeSlotHours = timeOfDayToHours(timeSlotData);
        if (timeSlotHours < blockUntilHours) {
            timeSlots.splice(timeSlotsIndex, 1);
        }
    }
};

export { blockOutTime };
