import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { setVehicle } from 'common/js/data/services/vehicle/vehicleData';
import Modal from 'common/components/content/react-shared/elements/modal/v1/Modal';
import { VehicleSelectorWrapper } from 'common/components/content/react-shared/components/vehicle-selector/VehicleSelectorWrapper';
import { VehicleSelectorColumn } from 'common/components/content/react-shared/components/vehicle-selector/column/VehicleSelectorColumn';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import '../../scss/vehicle-lookup.scss';

const VehicleButton = (props) => {
    const {
        preselectedVehicle,
        vehicleSelected,
        updateVehicleSelected,
        vehicleSelectedError,
        basePath,
        onSubmitFunc,
        lidData,
        type
    } = props;

    const isVehicleSelected = Object.keys(vehicleSelected).length !== 0;

    const [preselectVehicleData, setPreselectVehicleData] = useState(preselectedVehicle);
    const [modalIsOpen, setIsOpen] = useState(false);

    const ButtonLabel = () => {
        if (isVehicleSelected) {
            return (
                <>
                    <span className="highlight">
                        {vehicleSelected.year} {vehicleSelected.make}
                    </span>
                    <span>
                        {vehicleSelected.model} {vehicleSelected.trim}
                    </span>
                </>
            );
        } else {
            return <>Add Vehicle</>;
        }
    };

    const setVehicleCookie = async (vehicleData) => {
        const { year, make, model, trim, tpms } = { ...vehicleData };
        const response = await setVehicle(year, make, model, trim, tpms, basePath);

        // once the response returns, trigger the function that grabs data from it.
        onSubmitFunc();

        if (response.success === 'false') {
            // probably don't need to alert the user if this fails
        }
    };

    useEffect(() => {
        if (preselectedVehicle && preselectedVehicle.year && preselectedVehicle.year !== '') {
            updateVehicleSelected({
                year: preselectedVehicle.year,
                make: preselectedVehicle.make,
                model: preselectedVehicle.model,
                trim: preselectedVehicle.trim
            });
        }
    }, [preselectedVehicle]);

    useEffect(() => {
        console.log('vehicleSelected: ', vehicleSelected);
    }, [vehicleSelected]);

    const handleVehicleSelected = (vehicleData) => {
        updateVehicleSelected(vehicleData);
        setPreselectVehicleData(vehicleData);
        setVehicleCookie(vehicleData);
        setIsOpen(false);
    };

    const initVehicleSelector = () => {
        if (preselectedVehicle) {
            setPreselectVehicleData(preselectedVehicle);
        }
    };

    const openVehicleSelectorModal = () => {
        setAdobeDataLayer({
            event: 'pt_widget',
            event_type: 'interaction',
            interaction: 'change vehicle'
        });
        setIsOpen(true);
    };

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className="xb-vehicle-v2">
            {type === 'back' && (
                <button
                    type="button"
                    className={'lid-content__back'}
                    onClick={() => {
                        openVehicleSelectorModal();
                    }}
                >
                    Change Vehicle
                </button>
            )}
            {type === 'button' && (
                <button
                    type="button"
                    className="lid-content__no-tire__action"
                    onClick={() => {
                        openVehicleSelectorModal();
                    }}
                >
                    Change Vehicle
                </button>
            )}
            <Modal
                isModalOpen={modalIsOpen}
                closeModal={closeModal}
                containerClass="vehicle-selector__modal"
                modalContent={
                    <VehicleSelectorWrapper
                        onVehicleSelected={(vehicleData) => {
                            handleVehicleSelected(vehicleData);
                        }}
                        initVehicleSelector={initVehicleSelector}
                        basePath={basePath}
                    >
                        <VehicleSelectorColumn
                            buttonLabel="YES, THIS IS MY VEHICLE"
                            confirmMessageText="Confirming your vehicle is "
                            preselectVehicleData={preselectVehicleData}
                            onSubmitFunc={onSubmitFunc}
                        />
                    </VehicleSelectorWrapper>
                }
            ></Modal>
        </div>
    );
};

VehicleButton.propTypes = {
    preselectedVehicle: PropTypes.object.isRequired,
    vehicleSelected: PropTypes.object.isRequired,
    updateVehicleSelected: PropTypes.func.isRequired,
    vehicleSelectedError: PropTypes.bool.isRequired
};

export { VehicleButton };
