import { testPhone, testEmail } from '../../components/content/react-shared/utils/validation';
const debug = window?.BSRO?.debug ? true : false;
const cookiePath = '/';

const formatTime = (time, minutes) => {
    // pre es2015/ES6 optional minutes parameter:
    minutes = typeof minutes !== 'undefined' ? minutes : true;

    if (!time || time === '') {
        return '';
    }

    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? 'am' : 'pm'; // Set am/pm
        time[0] = +time[0] % 12 || 12; // Adjust hours

        if (!minutes) {
            time = [time[0], time[5]];
        }
    }

    return time.join(''); // return adjusted time or original string
};

const ensureSevenDays = (arr) => {
    const days = {
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6,
        sun: 7
    };

    for (let key in days) {
        if (!Object.prototype.hasOwnProperty.call(days, key)) continue;

        let found = false,
            item;

        for (let i = 0, len = arr.length; i < len; i += 1) {
            item = arr[i];

            if (item.weekDay.toLowerCase() === key) {
                found = true;
            }
        }

        if (found === false) {
            arr.push({
                weekDay: key.toUpperCase(),
                openTime: '',
                closeTime: ''
            });
        }
    }
};

const sortDays = (arr) => {
    const sorter = {
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6,
        sun: 7
    };

    arr.sort(function sortByDay(a, b) {
        const day1 = a.weekDay.toLowerCase();
        const day2 = b.weekDay.toLowerCase();

        return sorter[day1] > sorter[day2];
    });

    return arr;
};

const serviceIcons = () => {
    const serviceIcons = {
        'Air Conditioning Service': 'ac-service-icon-web-global-bsro',
        'Air Filter and Cabin Air Filter': 'air-cabin-icon-web-global-bsro',
        'Brake Fluid Exchange': 'fluid-replacement-icon-web-global-bsro',
        Brakes: 'brakes-icon-web-global-bsro',
        'Complete Vehicle Inspection': 'vehicle-inspection-icon-web-global-bsro',
        'Coolant Fluid Exchange': 'fluid-icon-web-global-bsro',
        'Engine Tune-Up': 'engine-tune-up-icon-web-global-bsro',
        'Flat Repair': 'flat-repair-icon-web-global-bsro',
        'Install Geotab': 'geotab-icon-web-global-bsro',
        'Lighting and Bulbs': 'lighting-bulb-icon-web-global-bsro',
        'Manufacturer Scheduled Maintenance': 'smooth-ride-icon-web-global-bsro',
        'Oil and Filter Change': 'oil-change-icon-web-global-bsro',
        'Oil & Filter Change': 'oil-change-icon-web-global-bsro',
        'Starting and Charging/Battery': 'battery-icon-web-global-bsro',
        'State Inspection': 'state-inspection-icon-web-global-bsro',
        'Tire Replacement': 'tire-replacement-icon-web-global-bsro',
        'Tire Rotation': 'tire-rotating-icon-web-global-bsro',
        'Transmission Fluid Service': 'transmission-service-icon-web-global-bsro',
        'Wheel Alignment': 'alignment-icon-web-global-bsro',
        'Wheel Balance': 'wheel-balance-icon-web-global-bsro',
        'Wiper Blades': 'wiper-blades-icon-web-global-bsro',
        Other: 'other-icon-web-global-bsro'
    };
    return serviceIcons;
};

export function getServiceIcon(apptService) {
    const hostName = 'https://s7d1.scene7.com/is/content/bridgestone/';
    const icons = serviceIcons();
    const foundValue = icons[apptService];
    return hostName + foundValue;
}

export function log(message) {
    if (debug) {
        let args = [];

        if (arguments.length >= 1) {
            for (let prop in arguments) {
                let val = arguments[prop];

                if (typeof val === 'object') {
                    let protoStr = Object.prototype.toString.call(val);
                    args.push(protoStr);

                    let valStr = null;

                    try {
                        valStr = JSON.stringify(val);
                    } catch (err) {
                        //valStr = "Cannot Convert To JSON String : " + err;
                        valStr = '';
                        console.log(val);
                    }

                    args.push(valStr);
                } else {
                    args.push(val);
                }
            }
            message = args.join(' -> ');
        }

        console.log(message);
    }
}

export function setCookie(name, val, exp = 360) {
    const d = new Date();
    d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${name}=${val};${expires};path=${cookiePath}`;
}

export function getCookie(name) {
    name = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            const sarr = c.split('=');
            const dcdc = decodeURIComponent(sarr[1]);
            try {
                return JSON.parse(dcdc);
            } catch (e) {
                return dcdc;
            }
        }
    }
    return '';
}

export function removeCookie(name) {
    if (Util.isArray(name)) {
        for (let i = 0; i < name.length; i++) {
            document.cookie = `${name[i]}=; path=${cookiePath}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        }
    } else {
        document.cookie = `${name}=; path=${cookiePath}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
}

/* DO NOT USE. Currently still in use, but prefer getUrlParameter(name) below */
export function getUrlParam(name) {
    if (window.location.search.length > 1) {
        return decodeURI(
            window.location.search.replace(
                new RegExp('^(?:.*[&\\?]' + encodeURI(name).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'),
                '$1'
            )
        );
    }

    return null;
}

/* UPDATED version of getUrlParam function above */
// Adding allowPlus for emails or other values that don't require spaces, but need + symbol
export function getUrlParameter(name, allowPlus = false) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null
        ? ''
        : allowPlus
          ? decodeURIComponent(results[1])
          : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function validateElement(data, ele, regex) {
    const zipRe = /^\d{5}(-\d{4})?$/;
    let invalidPattern = '0000000000'; //Not Allowing all Zeros
    let phoneNumReg = /^[\+]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    let validStatus = true;

    if (ele == 'email') {
        let emailErrorEl = document.querySelector('.emailError');
        if (data != '' && !testEmail(data)) {
            emailErrorEl && (emailErrorEl.innerHTML = 'Enter valid email address');
            validStatus = false;
        } else {
            emailErrorEl?.replaceChildren();
        }
    }
    if (ele == 'zipcode') {
        let zipErrorEl = document.querySelector('.zipError');
        if (data != '' && !zipRe.test(data)) {
            zipErrorEl && (zipErrorEl.innerHTML = 'Enter valid Zipcode');
            validStatus = false;
        } else {
            zipErrorEl?.replaceChildren();
        }
    }
    if (ele == 'phoneNum') {
        if (regex) {
            phoneNumReg = regex;
        }
        let phoneErrorEl = document.querySelector('.phoneError');
        if (
            data != '' &&
            (!testPhone(data) || !phoneNumReg.test(data) || invalidPattern == data.replace(/\s-(,)/g, ''))
        ) {
            phoneErrorEl && (phoneErrorEl.innerHTML = 'Enter valid Phone Number');
            validStatus = false;
        } else {
            phoneErrorEl?.replaceChildren();
        }
    }
    if (ele == 'required') {
        if (data == '') {
            validStatus = false;
        }
    }
    return validStatus;
}

export function chunkArray(arr, chunkSize) {
    let newArr = [].concat(arr); //create copy
    let chunked = [];
    const len = Math.ceil(newArr.length / chunkSize);

    for (let i = 0; i < len; i++) {
        let temp = [];
        for (let c = 0; c < chunkSize; c++) {
            const item = newArr.shift();

            if (item) {
                temp.push(item);
            }

            if (c == chunkSize - 1) {
                chunked.push(temp);
                temp = [];
            }
        }
    }

    return chunked;
}

export function type(obj) {
    if (obj == null) {
        return obj + '';
    }

    const classType = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regexp',
        '[object Object]': 'object',
        '[object Symbol]': 'symbol',
        '[object Null]': 'null',
        '[object Undefined]': 'undefined',
        '[object Error]': 'error'
    };

    const toString = Object.prototype.toString; // For IE issue(BSROAEM-2040) while generation of tire quote

    return classType[toString.call(obj)];
}

export function isFunction(obj) {
    return type(obj) === 'function' ? true : false;
}

export function isObject(obj) {
    return type(obj) === 'object' ? true : false;
}

export function isArray(obj) {
    return type(obj) === 'array' ? true : false;
}

export function isNumber(obj) {
    return type(obj) === 'number' ? true : false;
}

export function isString(obj) {
    return type(obj) === 'string' ? true : false;
}

export function isEmpty(obj) {
    if (isObject(obj)) {
        if (Object.keys(obj).length == 0) {
            return true;
        }

        for (let o in obj) {
            let _o = obj[o];

            if (isObject(_o)) {
                isEmpty(_o);
            }

            if (isEmpty(_o)) {
                return true;
            }
        }

        return false;
    }

    return obj == '' || obj == undefined || obj == null ? true : false;
}

export function cleanObject(obj) {
    let data = obj;

    for (let o in data) {
        let _o = data[o];

        if (isObject(_o)) {
            cleanObject(_o);
        }

        if (isArray(_o)) {
            let arr = _o;
            let i = 0;
            let len = arr.length;
            for (; i < len; i++) {
                cleanObject(arr[i]);
            }
        }

        if (isEmpty(_o)) {
            delete data[o];
        }
    }

    return data;
}

export function toCapitalCase(str) {
    return str.replace(/(^|\s)[a-z]/g, function (chr) {
        return chr.toUpperCase();
    });
}

export function buildQuery(data) {
    if (typeof data === 'string') return data;
    let query = [];
    for (let key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
        }
    }
    return query.join('&');
}

export const cleanPhoneNumber = (phone) => {
    const newPhone = phone.replace('(', '').replace(') ', '.').replace('-', '.');
    return newPhone;
};

export const cleanPhoneNumberDashed = (phone) => {
    const newPhone = phone.replace('(', '').replace(') ', '-');
    return newPhone;
};

/**
 * Cleans the store hours and formats them for display.
 * It has some side effects because the hours array is modified in place:
 * - hours array is sorted by day
 * - hours array is ensured to have all 7 days of the week
 * - a times property is added to each day with the formatted hours or 'Closed'
 * - MON weekday is changed to MON-FRI if the hours are the same for all weekdays
 * @param {Array} hours Store hours as returned from get-list-by-zip API.
 * @returns An array with hours formatted for display.
 */
export function cleanHours(hours) {
    let output, len, current, next, item, last, i;

    if (!hours || hours.length === 0) {
        return [];
    }

    const alreadyClean = hours.filter((day) => day.times);
    if (alreadyClean.length) {
        return alreadyClean;
    }

    ensureSevenDays(hours);
    sortDays(hours);

    output = [];
    len = hours.length;

    for (i = 0; i < len; i += 1) {
        current = hours[i];
        next = hours[i + 1];

        // add the first day in the list
        if (i === 0) {
            output.push(current);
        } else if (i === len - 1) {
            // last item in list
            last = output[output.length - 1];

            if (current.openTime === last.openTime && current.closeTime === last.closeTime) {
                last.weekDay += '-' + current.weekDay;
            } else {
                output.push(current);
            }
        } else {
            last = output[output.length - 1];

            if (current.openTime !== next.openTime || current.closeTime !== next.closeTime) {
                if (current.openTime === last.openTime && current.closeTime === last.closeTime) {
                    last.weekDay += '-' + current.weekDay;
                } else {
                    output.push(current);
                }
            } else {
                if (current.openTime !== last.openTime || current.closeTime !== last.closeTime) {
                    output.push(current);
                }
            }
        }
    }

    // format the times to 12-hour or Closed
    for (i = 0, len = output.length; i < len; i += 1) {
        item = output[i];

        if (item.openTime === '' || item.closeTime === '') {
            item.times = 'Closed';
        } else {
            item.times = formatTime(item.openTime) + '-' + formatTime(item.closeTime);
        }
    }

    // check and slice for duplicated weekdays
    for (i = 0; i < output.length; i++) {
        if (output[i].weekDay.length > 7) {
            output[i].weekDay = output[i].weekDay.slice(0, 7);
        }
    }

    return output;
}

export function cleanHolidays(holidays, holidayHours) {
    let output, len, current, item, i;

    if (!holidays || holidays.length === 0) {
        return [];
    }

    output = [];
    item = {};
    len = holidays.length;

    for (i = 0; i < len; i += 1) {
        current = holidays[i];

        item = current;

        if (!holidayHours) {
            item.openTime = '';
            item.closeTime = '';
        } else {
            // loop through and get the hours
            let hoursFound = false;

            for (let j = 0, len2 = holidayHours.length; j < len2; j += 1) {
                let item2 = holidayHours[j];

                if (current.holidayId === item2.holidayId) {
                    item.openTime = item2.openTime;
                    item.closeTime = item2.closeTime;
                    hoursFound = true;
                }
            }

            if (hoursFound === false) {
                item.openTime = '';
                item.closeTime = '';
            }
        }

        output.push(item);
    }

    // format the times to 12-hour or Closed
    for (i = 0, len = output.length; i < len; i += 1) {
        item = output[i];

        if (item.openTime === '' || item.closeTime === '') {
            item.times = 'Closed';
        } else {
            item.times = formatTime(item.openTime) + '-' + formatTime(item.closeTime);
        }
    }

    return output;
}

/**
 * Use current date/time to determine if the store is open or closed.
 * Assumes user is in the same timezone as the store.
 * TODO: Should this be aware of Holiday hours?
 * @param {Array} hours An array of objects containing store hours like { weekDay: 'MON', openTime: '07:00', closeTime: '19:00' }
 * @returns true if the store is open, false if the store is closed
 */
export const getOpenCloseStoreStatus = (hours) => {
    if (hours?.length > 0) {
        const now = new Date();
        const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const currentDayOfWeek = daysOfWeek[now.getDay()];

        const todayStoreHours = hours.find((day) => day.weekDay?.substring(0, 3) === currentDayOfWeek);

        const timeParser = /(\d+):(\d+)/;
        if (todayStoreHours) {
            const open = timeParser.exec(todayStoreHours.openTime);
            const close = timeParser.exec(todayStoreHours.closeTime);
            if (!open || !close) {
                console.warn(
                    `Invalid store hours for ${currentDayOfWeek} { open: "${todayStoreHours.openTime}", close: "${todayStoreHours.closeTime}" }`
                );
                return false;
            }
            const nowHour = now.getHours();
            const nowMinutes = now.getMinutes();
            const openHour = parseInt(open[1], 10);
            const closeHour = parseInt(close[1], 10);

            if (nowHour > openHour && nowHour < closeHour) {
                return true;
            }
            if (nowHour === openHour) {
                return nowMinutes >= parseInt(open[2], 10);
            }
            if (nowHour === closeHour) {
                return nowMinutes < parseInt(close[2], 10);
            }
            return false;
        }
    }

    return false;
};

/**
 * @param {Element} el - element we want siblings of
 * @return {NodeList}
 */
export const siblings = (el) => {
    return [...el.parentNode.children].filter((child) => child !== el);
};

/**
 * Takes a form and converts the names and values into an object.
 * @param {string} selector The CSS Selector of the form element you wish to serialize.
 * @returns
 */
export const serializeForm = (formEl) => {
    const output = {};
    [...formEl.querySelectorAll('input, textarea, select')].map((el) => {
        if (!el.disabled) {
            const name = el.getAttribute('name');
            const type = el.getAttribute('type');
            switch (el.localName) {
                case 'input':
                    if (type === 'checkbox') {
                        output[name] = el.checked;
                    } else if (type === 'radio') {
                        if (!Object.hasOwn(output, name)) {
                            output[name] = '';
                        }
                        if (el.checked) {
                            output[name] = el.value;
                        }
                    } else {
                        output[name] = el.value;
                    }
                    break;
                case 'textarea':
                    output[name] = el.value;
                    break;
                case 'select':
                    output[name] = [...el.querySelectorAll('option')]
                        .map((option) => {
                            if (option.selected) {
                                return option.value;
                            }
                        })
                        .filter((v) => {
                            if (v) {
                                return v;
                            }
                        });
                    break;
            }
        }
    });
    return output;
};

/**
 * Takes inpout which is phone number in arbitrary format and returns a formatted phone number:
 *  nnn-nnn-nnnn
 * @param {String} phoneNum The user input phone number
 * @returns Formatted phone number
 */
export function formatPhoneNumber(phoneNum) {
    let phoneNumber = phoneNum ? phoneNum.toString() : '';
    /* The regular expression below will:
        - discard any number of leading 0s, 1s or non digits
        - capture up to 3 digits in group 1
        - discard any number of non digits
        - capture up to 3 digits in group 2
        - discard any number of non-digits
        - capture up to 4 digits in group 3
        - discard the rest of the string
       Replace that with the 3 groups separated by hyphens
       If the either of the last two groups is empty there will be trailing hyphens that need to be removed
    */
    let inputValue = phoneNumber
        .replace(/^[01\D]*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4}).*$/, '$1-$2-$3') // Extract the digits
        .replace(/-+$/, ''); // trim trailing hyphens
    return inputValue;
}

export const passwordValidationCheck = (pwValue) => {
    let vObj = {
        minChar: false,
        lowercase: false,
        uppercase: false,
        aNumber: false,
        specialChar: false
    };

    if (pwValue.length > 7) {
        vObj.minChar = true;
    }

    if (pwValue.match(/[a-z]/g)) {
        vObj.lowercase = true;
    }

    if (pwValue.match(/[A-Z]/g)) {
        vObj.uppercase = true;
    }

    if (pwValue.match(/\d/)) {
        vObj.aNumber = true;
    }

    if (pwValue.match(/[-!$%^&*()\/_+|~=`{}[\]:;?,.@#]/g)) {
        vObj.specialChar = true;
    }
    return vObj;
};

export const isProductTypeTire = (productType) => {
    return productType?.toLowerCase() === 'tire';
};

const Util = {
    log,
    setCookie,
    getCookie,
    removeCookie,
    getUrlParam,
    validateElement,
    chunkArray,
    type,
    isFunction,
    isObject,
    isArray,
    isNumber,
    isString,
    isEmpty,
    cleanObject,
    toCapitalCase,
    cleanHours,
    cleanHolidays,
    cleanPhoneNumber,
    siblings,
    serializeForm,
    getServiceIcon,
    formatPhoneNumber,
    passwordValidationCheck,
    isProductTypeTire
};

export default Util;
