import { getStoreLocationsListByZip } from 'common/js/data/services/store-location';
import { getStoresData } from './getStoresData';

const getStoresByZip = async (rootEl, props) => {
    const {
        zip,
        getRadius,
        queryStoreNumber,
        isLoggedIn,
        preferredStoreNumber,
        setDateSelectorIndex,
        dateStoreStep,
        numDays,
        datesArr,
        addedServicesListArr,
        getStoresInfo,
        setStoresInfo,
        completeDateTimeSelection
    } = props;
    const radius = getRadius();
    const response = await getStoreLocationsListByZip(zip.toString(), { radius });
    getStoresData(rootEl, {
        response,
        getRadius,
        queryStoreNumber,
        isLoggedIn,
        preferredStoreNumber,
        setDateSelectorIndex,
        dateStoreStep,
        numDays,
        datesArr,
        addedServicesListArr,
        getStoresInfo,
        setStoresInfo,
        completeDateTimeSelection
    });
};

export { getStoresByZip };
