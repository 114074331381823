import '../scss/product-catalog.scss';
import { component } from 'common/js/library/component';
import { setCookie } from 'common/js/library/util';
import { setAdobeDataLayer, getAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

let tabsAnchors;
let checkboxesCatalog;
const init = (config) => {
    tabsAnchors = document.querySelectorAll('.tab-catalog > ul > li > a');
    checkboxesCatalog = document.querySelectorAll('.product-catalog .brand-filter input');
};

const instanceInit = (rootEl) => {
    console.log('inside product-catalog.js');
    let _urlParams = {};
    let isCookieClicked = false;
    const filterBtn = rootEl.querySelector('.filter-btn');
    const closeBtns = rootEl.querySelectorAll('.close-btn');
    const aside = rootEl.querySelector('aside');

    const setIsCookieClicked = (value) => {
        isCookieClicked = value;
    };

    const getIsCookieClicked = () => {
        return isCookieClicked;
    };

    checkboxesCatalog.forEach((checkbox) => {
        checkbox.addEventListener('change', handleFilterChange);
    });

    // Navigate the url, based on tire type
    [...tabsAnchors].forEach((tabsAnchor) => {
        tabsAnchor.addEventListener('click', (event) => {
            event.preventDefault();
            const tire_type = event.currentTarget.getAttribute('href');
            setAdobeDataLayer({
                event: 'tires.results.filter',
                filter_name: 'tabbed navigation',
                filter_value: tire_type,
                isSearchResult: 'false'
            });
            window.location.href = tire_type;
        });
    });

    if (window.location.search.substring(1)) {
        _urlParams = window.JSON.parse(
            '{"' +
                window.decodeURIComponent(
                    window.decodeURIComponent(
                        window.location.search
                            .substring(1)
                            .replace(/&/g, '","')
                            .replace(/=/g, '":"')
                            .replace(/\+/g, ' ')
                    )
                ) +
                '"}'
        );
    }

    const _tireBrands = _urlParams['brands'] ? _urlParams['brands'].toString().split(',') : [];
    const _tireTypes = _urlParams['types'] ? _urlParams['types'].toString().split(',') : [];
    const _tireFilters = _urlParams['filters'] ? _urlParams['filters'].toString().split(',') : [];
    const _tireFiltersBy = _tireFilters.length > 0 ? _tireFilters : _tireBrands.concat(_tireTypes);
    const _hash = window.location.hash.substr(1) ? window.location.hash.substr(1).toString().split(',') : [];
    const _filters = _hash.length > 0 ? _hash : _tireFiltersBy;

    if (!getIsCookieClicked()) {
        rootEl.addEventListener('click', (evt) => {
            const elem = evt.target.closest('.btn[data-catitem-tirename]');
            if (!elem) return;
            const tire_brand = elem.getAttribute('data-catitem-tirebrand');
            const tire_name = elem.getAttribute('data-catitem-tirename');
            const product_name = tire_brand + ' ' + tire_name;

            setCookie('bsro.ui.catitem.full', product_name);
            setCookie('bsro.ui.catitem.brand', tire_brand);
            setCookie('bsro.ui.catitem.name', tire_name);

            setAdobeDataLayer({
                event: 'tires.results.get-quote',
                productID: tire_name,
                isSearchResult: 'false'
            });
        });

        setIsCookieClicked(true);
    }

    filterBtn.addEventListener('click', (evt) => {
        evt.preventDefault();
        if (aside.classList.contains('active')) {
            aside.classList.remove('active');
        } else {
            aside.classList.add('active');
        }
    });

    [...closeBtns].forEach((closeBtn) => {
        closeBtn.addEventListener('click', (evt) => {
            evt.preventDefault();
            aside.classList.remove('active');
        });
    });

    if (_filters.length > 0) {
        let checkboxesFiltered = checkboxesCatalog;

        if (_tireBrands.length > 0 && _tireTypes.length == 0) {
            checkboxesFiltered = document.querySelectorAll('[class*=filter-by-brand]');
        }

        if (_tireTypes.length > 0 && _tireBrands.length == 0) {
            checkboxesFiltered = document.querySelectorAll('[class*=filter-by-type]');
        }

        checkboxesFiltered.forEach(function (el) {
            const _val = el.value.toString().toLowerCase();

            for (let i = 0; i < _filters.length; i++) {
                const _fstr = _filters[i].toString().toLowerCase();

                if (_val.indexOf(_fstr) != -1) {
                    el.checked = true;
                    el.dispatchEvent(new Event('change'));
                    break;
                }
            }
        });

        checkboxesCatalog.dispatchEvent(new Event('change'));
    }
};

const handleFilterChange = (evt) => {
    const typesValue = [];
    const brandsValue = [];
    const typeList = [];
    const brandList = [];
    const products = document.querySelectorAll('div.product');
    let filteredProducts = null;

    checkboxesCatalog.forEach((checkbox) => {
        if (checkbox.checked && checkbox.className.includes('filter-by-brand')) {
            console.log('filter-by-brand checked filter:', checkbox.value, 'this:', checkbox);
            brandsValue.push(checkbox.value);
            const filterExists = getAdobeDataLayer().find((data) => data.filter_value === checkbox.value);
            if (!filterExists) {
                setAdobeDataLayer({
                    event: 'tires.results.filter',
                    filter_name: 'brands',
                    filter_value: checkbox.value,
                    isSearchResult: 'false'
                });
            }
        } else if (checkbox.checked && checkbox.className.includes('filter-by-type')) {
            console.log('filter-by-type checked filter:', checkbox.value, 'this:', checkbox);
            typesValue.push(checkbox.value);
            const filterExists = getAdobeDataLayer().find((data) => data.filter_value === checkbox.value);
            if (!filterExists) {
                setAdobeDataLayer({
                    event: 'tires.results.filter',
                    filter_name: 'types',
                    filter_value: checkbox.value,
                    isSearchResult: 'false'
                });
            }
        }
    });

    if (typesValue.length) {
        typesValue.forEach((type) => {
            typeList.push(`[class*='${type}']`);
        });

        filteredProducts = Array.from(products).filter((product) => {
            return typeList.some((type) => product.matches(type));
        });
    }

    if (brandsValue.length) {
        brandsValue.forEach((brand) => {
            brandList.push(`[class*='${brand}']`);
        });

        if (filteredProducts != null) {
            filteredProducts = Array.from(filteredProducts).filter((product) => {
                return brandList.some((brand) => product.matches(brand));
            });
        } else {
            filteredProducts = Array.from(products).filter((product) => {
                return brandList.some((brand) => product.matches(brand));
            });
        }
    }

    if (filteredProducts) {
        products.forEach((product) => {
            product.style.display = 'none';
        });
        filteredProducts.forEach((product) => {
            product.style.display = '';
        });
    } else {
        products.forEach((product) => {
            product.style.display = '';
        });
    }
};

component({
    init,
    instanceInit,
    name: 'ProductCatalog',
    selector: '.product-catalog.product-results'
});
