// takes a list of service Ids and returns the total duration
export const fetchTotalServiceDuration = async (storeNumber, serviceIdList) => {
    const url = `/bsro/services/store/services/duration?storeNumber=${storeNumber}&serviceIds=${serviceIdList.join(',')}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            console.error('Error fetching total service duration');
            return 0;
        }
        const responseJson = await response.json();

        const totalDuration = responseJson?.data?.totalDuration;

        return totalDuration;
    } catch (err) {
        console.error('Error fetching total service duration:', err);
        return 0;
    }
};
