const createToolTip = (rootEl) => {
    let _TOOLTIP_HTML =
        '<div class="coral-Tooltip" role="tooltip" style="display:block;position:absolute;top:-9999px;left:-9999px"></div>';
    let _ARROW_WIDTH = 8;
    let _DISTANCE = 6;

    let _ARROWS = {
        left: 'coral-Tooltip--positionRight',
        right: 'coral-Tooltip--positionLeft',
        top: 'coral-Tooltip--positionBelow',
        bottom: 'coral-Tooltip--positionAbove'
    };

    let _STYLES_TYPES = {
        info: 'coral-Tooltip--info',
        error: 'coral-Tooltip--error',
        notice: 'coral-Tooltip--notice',
        success: 'coral-Tooltip--success',
        inspect: 'coral-Tooltip--inspect'
    };

    if (!rootEl) {
        return;
    }
    
    let $parent = $(rootEl).parent();

    $parent.append(_TOOLTIP_HTML);
    let $tooltip = $parent.find('.coral-Tooltip');
    let $quicktip = $parent.find('.tooltip');
    $tooltip.html(rootEl.dataset.quicktipContent);
    if (rootEl.dataset.quicktipStyle) {
        $tooltip.attr('style', $tooltip.attr('style') + '; ' + rootEl.dataset.quicktipStyle);
    }
    $quicktip.attr('href', 'javascript:void(0)');

    if (rootEl.dataset.quicktipArrow == 'right') rootEl.dataset.arrow = 'right';
    if (rootEl.dataset.quicktipArrow == 'left') rootEl.dataset.arrow = 'left';
    if (rootEl.dataset.quicktipArrow == 'bottom') rootEl.dataset.arrow = 'bottom';
    if (rootEl.dataset.quicktipArrow == 'top') rootEl.dataset.arrow = 'top';

    if (rootEl.dataset.quicktipType == 'info') rootEl.dataset.type = 'info';
    if (rootEl.dataset.quicktipType == 'error') rootEl.dataset.type = 'error';
    if (rootEl.dataset.quicktipType == 'success') rootEl.dataset.type = 'success';
    if (rootEl.dataset.quicktipType == 'notice') rootEl.dataset.type = 'notice';
    if (rootEl.dataset.quicktipType == 'inspect') rootEl.dataset.type = 'inspect';

    $tooltip.addClass(_STYLES_TYPES[rootEl.dataset.type]);
    $tooltip.addClass(_ARROWS[rootEl.dataset.arrow]);

    /**
     * accept `data-style` attribute for tooltip customization
     * for example `data-style="max-height:40em;max-width:20em"`
     */
    if (typeof rootEl.dataset.style !== 'undefined') {
        rootEl.dataset.style = rootEl.dataset.style.trim();
        if (rootEl.dataset.style.substr(rootEl.dataset.style.length - 1) != ';') {
            rootEl.dataset.style = rootEl.dataset.style + ';';
        }
        let style = $tooltip.attr('style');
        $tooltip.attr('style', rootEl.dataset.style + style);
    }
    const props = { $tooltip, _ARROW_WIDTH, _DISTANCE };

    $(rootEl).on('touchstart mouseover focusin', (evt) => handleEvent(evt, rootEl, props));
};

const hide = ($tooltip) => {
    $tooltip.hide();
};

const show = (rootEl, props) => {
    const { $tooltip, _ARROW_WIDTH, _DISTANCE } = props;
    let _ntop, _nleft;

    if (rootEl.dataset.arrow == 'right') {
        _ntop = $(rootEl).position().top - $tooltip.height() / 2 - 3;
        _nleft = $(rootEl).position().left - $tooltip.outerWidth() - _ARROW_WIDTH - _DISTANCE;
    }

    if (rootEl.dataset.arrow == 'left') {
        _ntop = $(rootEl).position().top - $tooltip.height() / 2 - 3;
        _nleft = $(rootEl).position().left + $(rootEl).outerWidth();
    }

    if (rootEl.dataset.arrow == 'top') {
        _ntop = $(rootEl).position().top + $(rootEl).outerHeight() + _ARROW_WIDTH - 2;
        _nleft = $(rootEl).position().left + $(rootEl).outerWidth() / 2 - $tooltip.outerWidth() / 2 - 5;
    }

    if (rootEl.dataset.arrow == 'bottom') {
        _ntop = $(rootEl).position().top - $tooltip.outerHeight() - _ARROW_WIDTH - 2;
        _nleft = $(rootEl).position().left + $(rootEl).outerWidth() / 2 - $tooltip.outerWidth() / 2 - 5;
    }

    _ntop = Math.ceil(_ntop);
    _nleft = Math.ceil(_nleft);

    $tooltip.css({ top: _ntop, left: _nleft });

    $tooltip.show();
};

const handleEvent = (evt, rootEl, props) => {
    const { $tooltip } = props;
    let type = evt.type;

    switch (type) {
        case 'mouseover':
        case 'focusin':
            show(rootEl, props);
            $(rootEl).on('mouseout focusout', (evt) => handleEvent(evt, rootEl, props));
            break;
        case 'mouseout':
        case 'focusout':
            hide($tooltip);
        default:
            setTimeout(() => hide($tooltip), 10000);
    }
};

export { createToolTip };
