const coralWaitDisplay = (show, container) => {
    let coralWait = document.querySelector('.coral-wait-container');
    if (coralWait) {
        if (show) {
            if (container) {
                container.prepend(coralWait);
            }

            coralWait.style.display = 'block';
        } else {
            if (getComputedStyle(coralWait).display === 'block') {
                coralWait.style.display = 'none';
            }
        }
    }
};

export { coralWaitDisplay };
