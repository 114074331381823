/**
 * Keyboard Navigation
 * @param {HTMLElement} container
 *
 * This module is responsible for handling keyboard navigation for the topnav.
 * It will handle the Primary and Secondary subnavs differently since they are used in different ways
 * compared to My Garage and My Store.
 *
 */

import { PrimarySubnavs } from './primary-subnavs';
import { SecondarySubnavs } from './secondary-subnavs';

export const KeyboardNavigation = (container) => {
    // Primary subnav items include Tires, Auto Repair and Auto Maintenance.
    PrimarySubnavs(container);
    // Secondary subnav items include My Garage and My Store.
    SecondarySubnavs(container);
};
