import { queryStringify, responseError, HTTPHeaders } from '../serviceUtilities';
import { getCache, setCache } from 'common/js/util/sessionCache';

export const getVehicleYears = async (vehicleType, basePath = '') => {
    try {
        const vType = vehicleType || 'tce';
        const url = `${basePath}/bsro/services/vehicle/get-years?vehicleType=${encodeURIComponent(vType)}`;
        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getVehicleMakes = async (vehicleType, year, basePath = '') => {
    try {
        const vType = vehicleType || 'tce';
        const url = `${basePath}/bsro/services/vehicle/get-makes?vehicleType=${encodeURIComponent(
            vType
        )}&year=${encodeURIComponent(year)}`;

        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getVehicleModels = async (vehicleType, year, make, basePath = '') => {
    try {
        const vType = vehicleType || 'tce';
        const url = `${basePath}/bsro/services/vehicle/get-models?vehicleType=${encodeURIComponent(
            vType
        )}&year=${encodeURIComponent(year)}&make=${encodeURIComponent(make)}`;

        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getVehicleTrim = async (vehicleType, year, make, model, basePath = '') => {
    try {
        const url = `${basePath}/bsro/services/vehicle/get-trims?year=${encodeURIComponent(year)}&make=${encodeURIComponent(
            make
        )}&model=${encodeURIComponent(model)}`;

        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getVehicleDrivingConditions = async (year, make, model, trim) => {
    try {
        // should remove when moving API endpoint
        const dataPackage = {
            ymm: `${year}-${make}-${model}`,
            trim
        };
        const url = `/bsro/services/account/get-driving-conditions${queryStringify(dataPackage)}`;
        const options = {
            method: 'GET',
            credentials: 'include',
            headers: HTTPHeaders(['token'])
        };

        const response = await fetch(url, options);

        if (response.ok) {
            const data = await response.json();

            return data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const setVehicle = async (year, make, model, trim, tpms, basePath = '') => {
    try {
        const dataPackage = {
            year,
            make,
            model,
            trim,
            tpms, // If TPMS is undefined, queryStringify removes it from the query string.
            vehicleType: window.isEcom ? 'tce' : 'main'
        };
        const url = `${basePath}/bsro/services/profile/set-vehicle${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getVehicleByPlate = async (licensePlate, stateCode, basePath = '') => {
    try {
        const url = `${basePath}/bsro/services/vehicle/get-vehicle-by-plate?licensePlate=${encodeURIComponent(
            licensePlate
        )}&state=${encodeURIComponent(stateCode)}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};
