import { blockOutTime } from './blockOutTime';
import { checkIfStoreHoliday } from './checkIfStoreHoliday';
import { findStoreDayAvailableTimes } from './findStoreDayAvailableTimes';
import { findStoreNextBusinessDaySchedule } from './findStoreNextBusinessDaySchedule';
import { getCookie } from 'common/js/library/util';
import { timeOfDayToHours } from 'common/js/util/date-time/timeOfDayToHours';
import { hasInventory } from 'common/components/content/react-shared/utils/hasInventory';

const HOURS_BUFFER = 3;
const HOURS_BUFFER_PINNACLE = 4;
const DAYS_BUFFER = 3; // used for tire replacement

const getEnoughInventory = () => {
    const tireQuantity = getCookie('bsro.ui.cartQuantity') || 0;

    if (tireQuantity > 0) {
        return hasInventory(tireQuantity);
    } else {
        return true;
    }
};

const formatDoubleDigit = (value) => {
    return `0${value}`.slice(-2);
};

const findAndBlockOutNextDay = (dataObj, storeIndex, storeInfo, weekday) => {
    const storeBusinessDaySchedule = findStoreNextBusinessDaySchedule(storeInfo, weekday);
    if (storeBusinessDaySchedule) {
        const storeNextDayAvailableTimes = findStoreDayAvailableTimes(
            dataObj?.[storeIndex]?.daytimes,
            storeBusinessDaySchedule.day,
            storeBusinessDaySchedule.month,
            storeBusinessDaySchedule.year
        );
        const storeOpenTime = storeBusinessDaySchedule.storeHoursSchedule.openTime;
        if (storeOpenTime) {
            const storeOpenTimeHours = timeOfDayToHours(storeOpenTime);
            blockOutTime(storeNextDayAvailableTimes, storeOpenTimeHours + HOURS_BUFFER);
        }
    }
};

/**
 *
 * @param {Array} dateTimeData // List of dates and times for each store from the availability API.
 * @param {Array} storesInfoList // List of store objects.
 * @param {Boolean} hasTireReplacement // Flag to determine if the user has tire replacement in their cart.
 * @param {Boolean} isCartFlow // Flag to determine if the user is in the cart flow.
 * @returns {Object} // An object with the store number as the key and the filtered times as the value.
 */
export const filterDateTime = (dateTimeData, storesInfoList = [], hasTireReplacement = false, isCartFlow = false) => {
    const needsThreeDaysGracePeriod = isCartFlow && hasTireReplacement && !getEnoughInventory();

    // Convert the dateTimeData array to an object with the store number as the key.
    let dataObj = dateTimeData.reduce((acc, day) => {
        const storeNumber = Object.keys(day)[0];
        acc[storeNumber] = day[storeNumber];
        return acc;
    }, {});

    const now = new Date();

    for (let storeIndex in dataObj) {
        const appointmentStoreNumber = storeIndex;
        const storeInfo = storesInfoList.find((store) => store?.storeNumber === appointmentStoreNumber);
        const isPinnacle = window?.featureSwitches?.isTrue('bsro-ui-pinnacle');
        const isPinnacleStore = storeInfo?.onlineExperience === 'pinnacle';
        if (storeInfo) {
            const weekday = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
            const currentWeekDay = weekday[now.getDay()];

            const isHoliday = checkIfStoreHoliday(storeInfo, now.getDate(), now.getMonth() + 1, now.getFullYear());
            const currentDayStoreHoursSchedule = storeInfo.hours.find(
                (hour) => hour.weekDay === currentWeekDay && !isHoliday
            );
            if (currentDayStoreHoursSchedule) {
                const storeCurrentDayCloseHours = timeOfDayToHours(currentDayStoreHoursSchedule.closeTime);
                const storeCurrentDayStartHours = timeOfDayToHours(currentDayStoreHoursSchedule.openTime);
                const currentTimeHours = now.getHours();
                const currentDayAvailableTimes = findStoreDayAvailableTimes(
                    dataObj?.[storeIndex]?.daytimes,
                    now.getDate(),
                    now.getMonth() + 1,
                    now.getFullYear()
                );
                blockOutTime(
                    currentDayAvailableTimes,
                    currentTimeHours +
                        (isPinnacle && isPinnacleStore && now.getMinutes() >= 30 ? HOURS_BUFFER_PINNACLE : HOURS_BUFFER)
                );
                if (currentTimeHours >= storeCurrentDayCloseHours) {
                    findAndBlockOutNextDay(dataObj, storeIndex, storeInfo, weekday);
                } else if (currentTimeHours < storeCurrentDayStartHours) {
                    blockOutTime(currentDayAvailableTimes, storeCurrentDayStartHours + HOURS_BUFFER);
                }
            } else {
                findAndBlockOutNextDay(dataObj, storeIndex, storeInfo, weekday);
            }
        }
    }

    // Tire replacement logic to block out the first three days.
    if (needsThreeDaysGracePeriod) {
        for (let store in dataObj) {
            // Block out the first days.
            let threeDaysOut = new Date();
            threeDaysOut.setDate(now.getDate() + 2);
            threeDaysOut.setHours('6', '0', '0');
            for (let day = 0; day < DAYS_BUFFER; day++) {
                let availableDateObject = dataObj?.[store]?.daytimes?.[day];
                if (availableDateObject) {
                    let availableDate = new Date(
                        `${availableDateObject.availableYear}/${availableDateObject.availableMonth}/${availableDateObject.availableDay} 6:00:00 am`
                    );
                    let isWithinThreeDays = availableDate.getTime() < threeDaysOut.getTime();

                    if (isWithinThreeDays) {
                        dataObj[store].daytimes[day].times = [];
                    }
                }
            }
        }
    }

    // Final pass to remove times for days in the past and holidays anywhere in the list.
    for (let storeNumber in dataObj) {
        for (let dayIndex in dataObj[storeNumber].daytimes) {
            const availableDateObject = dataObj[storeNumber].daytimes[dayIndex];
            const availableDate = new Date(
                `${availableDateObject.availableYear}-${formatDoubleDigit(availableDateObject.availableMonth)}-${formatDoubleDigit(availableDateObject.availableDay)}T00:00:00`
            );
            const todaysDate = new Date(
                `${now.getFullYear()}-${formatDoubleDigit(now.getMonth() + 1)}-${formatDoubleDigit(now.getDate())}T00:00:00`
            );
            const storeData = storesInfoList.find((store) => store?.storeNumber === storeNumber);
            const isHoliday = checkIfStoreHoliday(
                storeData,
                availableDateObject.availableDay,
                availableDateObject.availableMonth,
                availableDateObject.availableYear
            );
            const isPastDate = availableDate.getTime() < todaysDate.getTime();

            if (isPastDate || isHoliday) {
                dataObj[storeNumber].daytimes[dayIndex].times = [];
            }
        }
    }

    return dataObj;
};

/**
 * Mimics the multi-store response from the API for a single-store response.
 * Returns the single-store dateTime data by the store number.
 */
export const filterDateTimeStore = (dateTimeData, storeInfo, hasTireReplacement = false, isCartFlow = false) => {
    const storeNumber = storeInfo?.storeNumber;

    return filterDateTime([{ [storeNumber]: dateTimeData }], [storeInfo], hasTireReplacement, isCartFlow)[storeNumber];
};
