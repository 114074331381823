import { serializeForm, validateElement } from 'common/js/library/util';
import { component } from 'common/js/library/component';

let military = false;
let offroad = false;
let currentClass = '';
const instanceInit = (rootEl) => {
    const submitBtn = rootEl.querySelector('.cv-btn');
    currentClass = rootEl.getAttribute('class');

    const getCurrentClass = () => {
        switch (currentClass) {
            case 'contact-fields-military':
                military = true;
                break;
            case 'contact-fields-offroad':
                offroad = true;
                break;
        }
        return currentClass;
    };

    const formatPhoneNumber = (evt) => {
        let _val = evt.currentTarget.value;
        _val = _val.replace(/[\s\.]/g, '-').replace(/[^0-9\-]/g, '');
        if (evt.currentTarget.value !== _val) {
            evt.currentTarget.value = _val;
        }
    };

    rootEl.querySelector('.phonenumber').addEventListener('keyup', formatPhoneNumber);

    const handleChange = () => handleFieldChange(rootEl, submitBtn, getCurrentClass);

    $('input, select, textarea').on('change blur', handleChange);
    $('input, textarea').on('keyup', handleChange);
    $(submitBtn).on('click', () => handleSubmit(rootEl, submitBtn, getCurrentClass));
    $(window).on('load', init);
};
const init = () => {
    console.log('inside cv-generation-form.js');
};
const handleFieldChange = (rootEl, submitBtn, getCurrentClass) => {
    let selector = '';
    if (military) {
        selector = 'input, textarea, select, date';
    } else if (offroad) {
        selector = 'input, textarea';
    }

    const inputs = selector ? rootEl.querySelectorAll(selector) : null;
    const data = getFormValues(rootEl);
    const isValid = validate(data, getCurrentClass);
    let allFieldsFilled = true;
    const elNameError = rootEl.querySelector('.nameError');

    if (elNameError.classList.contains('showError')) {
        elNameError.classList.remove('showError');
    }

    if (inputs) {
        inputs.forEach((input) => {
            if (input.required && !input.value) {
                allFieldsFilled = false;
            }
        });
        submitBtn.disabled = !allFieldsFilled || !isValid;
    } else {
        submitBtn.disabled = !isValid;
    }
};

const handleSubmit = (rootEl, submitBtn, getCurrentClass) => {
    const data = getFormValues(rootEl);
    const _email = data.email.trim();
    const _phone = data.phoneNumber.trim();
    const _name = data.fullName.trim();
    const emailValidRes = validateElement(_email, 'email');
    const phoneValidRes = validateElement(_phone, 'phoneNum');
    const nameValidRes = _name.length > 3;
    const elNameError = rootEl.querySelector('.nameError');

    if (!emailValidRes) {
        rootEl.querySelector('.emailError').innerHTML = 'invalid email address';
    }
    if (!phoneValidRes) {
        rootEl.querySelector('.phoneError').innerHTML = 'invalid phone number';
    }
    if (!nameValidRes) {
        elNameError.innerHTML = 'minimum 3 letters required';
        elNameError.classList.add('showError');
    }

    if ((offroad || military) && emailValidRes && phoneValidRes) {
        let combinedData = {
            firstName: data.fullName,
            lastName: '-',
            email: data.email,
            zip: '-',
            phone: data.phoneNumber,
            feedbackId: data.feedbackId,
            message: ''
        };

        if (offroad) {
            combinedData.message =
                '<br/>Estimated Budget = ' +
                data.estimatedBudget +
                '<br/>Accessories = ' +
                data.accessories +
                '<br/>Contact Preference = ' +
                data.contactPreference;
        }

        if (military) {
            combinedData.message +=
                '<br/>Military Branch = ' +
                data.militaryBranch +
                '<br/>Current Rank/Paygrade = ' +
                data.rankPaygrade +
                '<br/>Best Time To Call = ' +
                data.timeToCall +
                '<br/>SkillBridge or Career Skills Program Start Date = ' +
                data.startDate +
                '<br/>Expected EAS/End of Service Date or Terminal Leave = ' +
                data.endOfService +
                '<br/>Geographical Interest For Full Time Employment Once You Complete SkillBridge Program = ' +
                data.geographical +
                '</br>Stay Near Current Duty Location = ' +
                data.currentLocation;
        }

        if (nameValidRes && validate(combinedData, getCurrentClass)) {
            sendData(combinedData, submitBtn);
        }
    } else {
        if (!offroad && !military && validate(data, getCurrentClass) && emailValidRes && phoneValidRes) {
            sendData(data, submitBtn);
        } else {
            console.log('CVGenerationForm: invalid data');
        }
    }
};

const getFormValues = (rootEl) => {
    const formValues = serializeForm(rootEl);
    formValues.message = formValues.message && formValues.message[0];
    return formValues;
};
const validate = (data, getCurrentClass) => {
    const currentSet = getCurrentClass();
    const fields = {
        'contact-fields-simple': ['phone', 'firstName', 'lastName', 'email', 'zip', 'message'],
        'contact-fields-military': [
            'phoneNumber',
            'fullName',
            'militaryBranch',
            'rankPaygrade',
            'email',
            'timeToCall',
            'startDate',
            'endOfService',
            'geographical',
            'currentLocation'
        ],
        'contact-fields-offroad': ['fullName', 'email', 'phoneNumber', 'accessories', 'contactPreference']
    };

    return fields[currentSet].every((field) => data[field] !== '');
};
const sendData = (_data, submitBtn) => {
    submitBtn.disabled = true;
    $.ajax({
        url: '/bsro/services/contact/jobs',
        contentType: 'application/json',
        data: JSON.stringify(_data),
        method: 'POST'
    })
        .done(function (data) {
            let response = data;
            if (response && response.message && response.message.error) {
                submitBtn.disabled = false;
            }
            if (response.status == '200') {
                document.querySelector('.form-view').style.display = 'none';
                document.querySelector('.confirmation-view').style.display = 'block';
                window.adobeDataLayer = window.adobeDataLayer || [];
                window.adobeDataLayer.push({
                    event: 'JoinTheTeamFormSubmitSuccess'
                });
            } else {
                console.error('Job Data error', response);
                submitBtn.disabled = false;
            }
        })
        .fail(function () {
            submitBtn.disabled = false;
        });
};

component({
    selector: '[data-cv-generation-form]',
    instanceInit,
    name: 'CVGenerationForm'
});
