import { resetHours } from './resetHours';
import Handlebars from 'common/js/library/handlebar';
import { initModalPopover } from 'common/js/util/coral-modal/initModalPopover';
import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { formatTimeToHrsMins } from 'common/js/util/date-time/formatTime';

const addStores = (rootEl, props) => {
    const {
        hasAvailability = true,
        numDays,
        stores,
        setDateSelectorIndex,
        datesArr,
        preferredStoreNumber,
        getStoresInfo
    } = props;

    const storeItemTemplate = rootEl.querySelector('[data-store-item-template]');
    const storeItemTemplateOAAF = rootEl.querySelector('[data-store-item-template-oaaf]');
    const container = rootEl.querySelector('.step.date-store .date-store-container .stores');
    const otherStoreContainer = rootEl.querySelector('.step.date-store .date-store-container .otherStoresList');
    const allDateItems = Array.from(rootEl.querySelectorAll('.date-items'));

    const timeDurationTextEl = rootEl.querySelector('.step.date-store .date-store-container .timeDurationText');
    const totalTime = getLocalStorageItem('totalServiceTime');
    const timeDurationEl = document.body.querySelector('.timeDuration');
    const setStore = getLocalStorageItem('storeData');
    const setStoreNumber = setStore.storeNumber;
    const filteredStores = stores.filter((item) => item.storeNumber === setStoreNumber);
    const otherStores = stores.filter((item) => item.storeNumber !== setStoreNumber);
    const isPinnacle = window?.featureSwitches?.isTrue('bsro-ui-pinnacle') && setStore.onlineExperience === 'pinnacle';
    const storesToUse = isPinnacle ? filteredStores : stores;
    const otherStoresEl = document.querySelector('.otherStoresContainer');

    isPinnacle ? (timeDurationEl.style.display = 'flex') : '';

    timeDurationTextEl.innerHTML = 'Total Service Time: est. ' + formatTimeToHrsMins(totalTime);
    container.innerHTML = '';
    otherStoreContainer.innerHTML = '';

    allDateItems.forEach((allDateItem) => {
        let dateItems = Array.from(allDateItem?.querySelectorAll('li'));
        dateItems.forEach((dateItem) => {
            dateItem.classList.toggle('hide', !dateItem.classList.contains('active'));
            dateItem.dataset.serviceFetch && (dateItem.dataset.serviceFetch = false);
        });
        dateItems.slice(0, numDays).forEach((dateItem) => dateItem.classList.remove('hide'));
    });
    setDateSelectorIndex(1);
    resetHours(rootEl);

    // Ensure only the correct number of days are displayed
    const dateItems = rootEl.querySelectorAll('.date-items li');
    dateItems.forEach((item, index) => {
        if (index < numDays) {
            item.classList.remove('hide');
        } else {
            item.classList.add('hide');
        }
    });
    // Disable the previous arrow when coming back to this step
    const prevArrow = rootEl.querySelector('.nav-control.prev');
    if (prevArrow) {
        prevArrow.classList.add('disable');
    }

    const renderStores = (stores, container, startFromSecond = false) => {
        const startIndex = startFromSecond ? 2 : 1;
        stores.forEach((item, idx) => {
            const storesObj = { ...getStoresInfo().storesAsDataObj };
            const storeObj = storesObj[item.storeNumber] || {};
            const newHtml =
                item.onlineAppointmentActiveFlag === '0' || !hasAvailability
                    ? storeItemTemplateOAAF.cloneNode(true).innerHTML
                    : storeItemTemplate.cloneNode(true).innerHTML;
            const template = Handlebars.compile(newHtml);

            Object.assign(storeObj, {
                count: idx + startIndex,
                datesArr,
                myPreferredStore: item.storeNumber == preferredStoreNumber,
                activeFlag: storeObj.activeFlag == '1',
                hasAvailability: hasAvailability == true
            });

            storesObj[item.storeNumber] = storeObj;
            container.insertAdjacentHTML('beforeend', template(storeObj));
        });
    };

    renderStores(storesToUse, container);

    if (isPinnacle) {
        otherStoresEl.style.display = 'flex';
        const arrowEl = rootEl.querySelector('.otherStoresContainer .arrow-down img');
        const availabilityButton = rootEl.querySelector('.pinnacle-availability');
        const pinnacleContainer = rootEl.querySelector('.pinnacle-container');
        const datesRows = (type) => {
            return rootEl.querySelectorAll('.dates.pinnacle')[type];
        };
        const otherStoresList = () => rootEl.querySelector('.otherStoresList');
        const storeHoursEl = () => rootEl.querySelector('.store-hours');
        arrowEl.classList.remove('rotate');

        const toggleClasses = (arrowPosition) => {
            pinnacleContainer.style.display = 'none';
            resetHours(rootEl);
            if (arrowPosition && arrowPosition == 'down') {
                datesRows(0).classList.add('hide');
                datesRows(1).classList.remove('hide');
                timeDurationEl.classList.add('hide');
                availabilityButton.classList.remove('hide');
                storeHoursEl(0).classList.add('hide');
                otherStoresList().classList.remove('hide');
            } else {
                datesRows(0).classList.remove('hide');
                datesRows(1).classList.add('hide');
                availabilityButton.classList.add('hide');
                timeDurationEl.classList.remove('hide');
                storeHoursEl().classList.remove('hide');
                otherStoresList().classList.add('hide');
            }
        };

        toggleClasses();

        if (!window.toggleEventListenersAdded) {
            arrowEl.addEventListener('click', (evt) => {
                let arrowPosition = '';
                if (evt.currentTarget.classList.contains('rotate')) {
                    evt.currentTarget.classList.remove('rotate');
                } else {
                    evt.currentTarget.classList.add('rotate');
                    arrowPosition = 'down';
                }

                toggleClasses(arrowPosition);
            });

            availabilityButton.addEventListener('click', () => {
                toggleClasses();
                arrowEl.classList.remove('rotate');
            });
            window.toggleEventListenersAdded = true;
        }

        renderStores(otherStores, otherStoreContainer, true);
    }

    [
        ...container.querySelectorAll('.store-item > .wait'),
        ...otherStoreContainer.querySelectorAll('.store-item > .wait')
    ].forEach((waitEl) => (waitEl.style.display = 'none'));

    if (window.scheduleAppointmentMap) {
        window.scheduleAppointmentMap.buildMap(stores);
    }

    window.setTimeout(initModalPopover, 500);
};

export { addStores };
