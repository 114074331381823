import { batteryLife } from 'common/js/data/services/battery/batteryLife';
import { component } from 'common/js/library/component';

let vehicleLookupPanel;
let zipcodeError;
let vehicleLookupBtn;
const init = (config) => {
    vehicleLookupPanel = document.querySelector('.vehicle-lookup');
    zipcodeError = document.querySelector('.zipcodeError');
    vehicleLookupBtn = document.querySelector('.vehicle-lookup .btn');
};

const instanceInit = (rootEl) => {
    console.log('inside virtual-battery-tester.js');

    let subEls = {
        year: vehicleLookupPanel?.querySelector('select[name=year]'),
        make: vehicleLookupPanel?.querySelector('select[name=make]'),
        model: vehicleLookupPanel?.querySelector('select[name=model]'),
        zip: vehicleLookupPanel?.querySelector('input[name=zip]'),
        resultSuccess: rootEl.querySelector('.success'),
        resultError: rootEl.querySelector('.error')
    };

    vehicleLookupPanel.querySelector('.btn').addEventListener('click', (evt) => handleSubmit(evt, rootEl, subEls));

    subEls.zip.addEventListener('change', validateSearchZip);
    subEls.zip.addEventListener('blur', validateSearchZip);
    subEls.zip.addEventListener('keyup', validateSearchZip);
    subEls.zip.addEventListener('click', validateSearchZip);
    subEls.zip.addEventListener('input', validateSearchZip);

    componentInit(rootEl, subEls);
};

const componentInit = (rootEl, subEls) => {
    let zip;
    let vehicleInfo;

    if (window?.siteProfile?.location) {
        zip = window.siteProfile.location.myZip || window.siteProfile.location.autoZip;
        vehicleInfo = getVehicleInfo(true, subEls);

        if (zip && zip !== '' && vehicleInfo.year !== '') {
            getResult(rootEl, zip, vehicleInfo, subEls);
        }
    }
};

const handleSubmit = (evt, rootEl, subEls) => {
    let zip = subEls.zip.value;
    let vehicleInfo = getVehicleInfo(false, subEls);

    getResult(rootEl, zip, vehicleInfo, subEls);
};

const getVehicleInfo = (useProfile, subEls) => {
    const { year, make, model } = subEls;
    let info = {};

    if (useProfile) {
        info.year = window.siteProfile.vehicles.aux.year;
        info.make = window.siteProfile.vehicles.aux.make.replace('+', ' ');
        info.model = window.siteProfile.vehicles.aux.model.replace('+', ' ');
    } else {
        // use the dropdown values
        info.year = year.value;
        info.make = make.value;
        info.model = model.value;
    }

    return info;
};

const validateSearchZip = (evt) => {
    let val = evt.currentTarget.value;
    let zipRe = /^\d{5}(-\d{4})?$/;

    if (val !== '') {
        if (zipRe.test(val)) {
            zipcodeError.innerText = '';
            vehicleLookupBtn?.classList.remove('disable');
        } else {
            zipcodeError.innerText = 'Please enter a valid zip code';
            vehicleLookupBtn?.classList.add('disable');
        }
    }
};

const getResult = async (rootEl, zip, vehicleInfo, subEls) => {
    const { resultSuccess, resultError } = subEls;
    let sendData = {
        zipCode: zip
    };
    let data;

    resultSuccess.style.display = 'none';
    resultError.style.display = 'none';

    try {
        const response = await batteryLife(sendData);

        if (response.success === 'true' && parseInt(response.status) === 200) {
            data = response.data;
            rootEl.style.display = 'block';
            rootEl.querySelector('.vehicle').innerText = `${vehicleInfo.year} ${vehicleInfo.make} ${vehicleInfo.model}`;
            rootEl.querySelector('.life-year').innerText = data.lifeYear;
            rootEl.querySelector('.life-months').innerText = data.lifeMonth;
            rootEl.querySelector('.life-total-months').innerText = data.lifeTotMonth;

            resultSuccess.style.display = 'block';
        } else {
            resultError.style.display = 'none';
        }
    } catch (err) {
        resultError.style.display = 'none';
    }
};

component({
    selector: '.battery-life-result',
    init,
    instanceInit,
    name: 'VirtualBatteryTester'
});
