export const testZip = (value) => /^\d{5}(-\d{4})?$/.test(value);

export const testPhone = (value) => {
    const isValidPhoneNumber = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
    const startsWithZero = /^0[0-9].*$/.test(value);
    let isConsecutiveNumbers = false;

    if (isValidPhoneNumber && !startsWithZero) {
        const phoneNumberWithoutSpecialCharacters = String(value).replace(/[^0-9]/g, '');

        // Check for consecutive numbers
        for (let num = 0; num < 10; num++) {
            const consecutiveNumberRegExp = new RegExp(`^[${num}]*$`, 'g');

            // Check if the phone number contains consecutive numbers
            isConsecutiveNumbers = consecutiveNumberRegExp.test(phoneNumberWithoutSpecialCharacters);

            // If consecutive numbers are found, break out of the loop
            if (isConsecutiveNumbers) {
                break;
            }
        }
    }

    // Return true if the phone number is valid and does not contain consecutive numbers
    return isValidPhoneNumber && !isConsecutiveNumbers && !startsWithZero;
};

export const testEmail = (value) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);

export const testState = (value) =>
    /^(A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])$/i.test(
        value
    );
