/**
 * Determines if GBB should be shown and if so renders it to the page.
 *
 * @param {element} target - DOM element to inject GBB into
 * @param {Array} cards - Array of product cards to use as pool of options
 * @return {boolean} Did GBB get rendered?
 */
const goodBetterBest = (target, cards) => {
    initDataSource();

    // test that:
    // 1: GBB is active
    // 2: Number of results meets minimum number of products requirement
    if (!window.goodBetterBest.active || cards.length < window.goodBetterBest.minProducts) {
        return false;
    }

    const gbbCards = getGBBCards(cards);

    // Test that:
    // 1: There is at least one card to display
    // 2: If required, there is a card for each GBB category
    let positives = 0;
    const toPass = window.goodBetterBest.requireAll ? 3 : 1;

    gbbCards.map((card) => {
        if (card) {
            positives++;
        }
    });

    if (positives < toPass) {
        return false;
    }

    // if we've made it through the gauntlet, display GBB
    renderGBB(target, gbbCards);

    return true;
};

/**
 * Initializes the Good, Better, Best global data object
 *
 * @return {void}
 */
const initDataSource = () => {
    const gbb = window.goodBetterBest;

    window.goodBetterBest = {
        active: (gbb && gbb.active) || false, // determines if gbb will be displayed
        minProducts: (gbb && gbb.minProducts) || 10000, // 6, // min number of products required to display GBB
        requireInventory: (gbb && Boolean(gbb.requireInventory)) || true, // must meet min inventory requirements for each product in GBB
        minInventory: (gbb && gbb.minInventory) || 4, // if requireInventory, number that must be in inventory to display in GBB
        requireAll: (gbb && Boolean(gbb.requireAll)) || true, // must have at least one product from each grouping of GBB
        share: (gbb && Boolean(gbb.share)) || true, // if a GBB category has no card can it borrow a card from another category?
        order: (gbb && gbb.order) || {
            desktop: [1, 2, 3],
            mobile: [3, 2, 1]
        }, // changes order to best, better, good
        copy: (gbb && gbb.copy) || {
            heading: 'We suggest these tires for you, hand picked by our experts.',
            postHeading: 'View all other tires for your vehicle below',
            cardHeadings: ['Good', 'Better', 'Best']
        },
        priority: (gbb && gbb.priority) || {
            good: [/^firestone(.*?)all season/i],
            better: [/ecopia/i, /firehawk/i, /champion/i],
            best: [/potenza/i, /transforce/i, /dueler/i, /destination/i, /turanza/i, /weathergrip/i, /driveguard/i]
        } // defines GBB product groupings
    };
};
/**
 * Uses GBB priority arrays, and if required inventory levels, to determine cards used in GBB
 *
 * @param {Array} cards - Array of product cards to use as pool of options
 * @return {Array}
 */
const getGBBCards = (cards) => {
    let good, better, best;
    let goodArr = window.goodBetterBest.priority.good.concat();
    let betterArr = window.goodBetterBest.priority.better.concat();
    let bestArr = window.goodBetterBest.priority.best.concat();
    let goodCards = [];
    let betterCards = [];
    let bestCards = [];

    cards.map((card) => {
        let lowInventory = false;
        let price = card.dataset.price || '0';
        const isSplitfit = Boolean(card.dataset.rearArticleId) || false;
        let productName = card.dataset.productName || ''; // MB: Setting default for those elements that don't have the data attributes on them.
        productName = productName.toLowerCase();

        const btn = card.querySelector('.tire-quote-btn');
        const inventory = btn ? btn.dataset.inventoryQuantity : 0;

        // we only want to test cards that have enough inventory, if that is a requirement
        if (window.goodBetterBest.requireInventory) {
            // console.log('gbb inventory check:', {
            //     productName,
            //     minInventory: window.goodBetterBest.minInventory,
            //     inventory,
            //     lowInventory:
            //         !inventory || inventory < window.goodBetterBest.minInventory
            // });
            lowInventory = !inventory || inventory < window.goodBetterBest.minInventory;
        }

        // block if split-fit or has low inventory flag
        if (!lowInventory && !isSplitfit) {
            const goodRank = rankByRegex(goodArr, productName);
            const betterRank = rankByRegex(betterArr, productName);
            const bestRank = rankByRegex(bestArr, productName);

            if (goodRank !== -1) {
                goodCards.push({
                    card,
                    rank: goodRank,
                    productName,
                    inventory,
                    price
                });
            }

            if (betterRank !== -1) {
                betterCards.push({
                    card,
                    rank: betterRank,
                    productName,
                    inventory,
                    price
                });
            }

            if (bestRank !== -1) {
                bestCards.push({
                    card,
                    rank: bestRank,
                    productName,
                    inventory,
                    price
                });
            }
        }
    });

    // sort matches by rank, then by price
    goodCards = sortCategory(goodCards);
    betterCards = sortCategory(betterCards);
    bestCards = sortCategory(bestCards);

    // if share, allow categories to be filled by other categories
    if (window.goodBetterBest.share) {
        // capture best card
        if (bestCards.length > 0) {
            // best exists, so use it
            best = bestCards.shift();
        } else {
            if (betterCards.length > 0) {
                // no best cards exist, assign best and better from that pool
                best = betterCards.shift();
                better = betterCards.shift(); // we don't care if this is undefined
            } else if (goodCards.length > 0) {
                // only good cards exist. assign all cards from that pool
                best = goodCards.shift();
                better = goodCards.shift(); // we don't care if this is undefined
                good = goodCards.shift(); // we don't care if this is undefined
            }
        }

        // capture better card, if not already captured
        if (!better) {
            if (betterCards.length === 1 && goodCards.length === 0) {
                // in this specific case we need to make sure better grabs from best and let good take the only better result
                better = bestCards.pop();
                good = betterCards.pop();
            } else if (betterCards.length > 0) {
                // better exists so use it
                better = betterCards.shift();
            } else if (bestCards.length > 0) {
                // when best cards, make sure good pops first if no good cards exist
                if (goodCards.length === 0) {
                    good = bestCards.pop();
                }
                // no better, take lowest best
                better = bestCards.pop();
            } else if (goodCards.length > 0) {
                // only good cards exist. assign remaining cards from that pool
                better = goodCards.shift();
                good = goodCards.shift();
            }
        }

        // capture good card, if not already captured
        if (!good) {
            if (goodCards.length > 0) {
                // good exists, so use it.
                good = goodCards.shift();
            } else {
                if (betterCards.length > 0) {
                    // better exists, so use it
                    good = betterCards.pop();
                }
            }
        }
    } else {
        best = bestCards.shift();
        better = betterCards.shift();
        good = goodCards.shift();
    }

    return [(good && good.card) || undefined, (better && better.card) || undefined, (best && best.card) || undefined];
};

/**
 * sorts an array by the rank and price of the objects contained in it
 *
 * @param {Array} arr - array of card object data
 * @return {Array} sorted array
 */
const sortCategory = (arr = []) => {
    const arrCopy = arr.concat();

    arrCopy.sort((a, b) => {
        let val = a.rank - b.rank;
        if (val === 0) {
            val = Number(b.price) - Number(a.price);
        }
        return val;
    });

    return arrCopy;
};

/**
 * checks if regex matches product name and returns index of match
 *
 * @param {Array} regexArr - array of regex values to test
 * @param {string} productName - string to test regex values against
 * @return {number} returns -1 if no match found. otherwise, returns the index of the first match
 */
const rankByRegex = (regexArr, productName) => {
    let i;

    for (i = 0; i < regexArr.length; i++) {
        if (regexArr[i] instanceof RegExp) {
            if (regexArr[i].test(productName)) {
                return i;
            }
        }
    }

    return -1;
};

/**
 * checks if regex matches product name
 *
 * @param {Array} regexArr - array of regex values to test
 * @param {string} productName - string to test regex values against
 * @return {boolean}
 */
// const testRegexs = (regexArr, productName) => {
//     let i;

//     for (i = 0; i < regexArr.length; i++) {
//         if (regexArr[i] instanceof RegExp) {
//             if (regexArr[i].test(productName)) {
//                 return true;
//             }
//         }
//     }

//     return false;
// };

/**
 * Renders the GBB cards to the page
 *
 * @param {element} target - Element to append the GBB cards to
 * @param {Array} gbbCards - Array of cards to be used for GBB
 */
const renderGBB = (target, gbbCards) => {
    const clonesWithCloned = gbbCards.map((card) => {
        if (card) {
            return { clone: card.cloneNode(true), original: card };
        }
    });
    const gbbRoot = document.createElement('div');
    const cardContainer = document.createElement('div');
    const heading = document.createElement('h3');
    const postHeading = document.createElement('h3');

    gbbRoot.className = 'gbb';
    cardContainer.className = 'gbb-card-container';
    heading.className = 'heading';
    postHeading.className = 'post-heading';

    if (window.goodBetterBest.order) {
        if (window.goodBetterBest.order.mobile) {
            cardContainer.classList.add('order-mobile');
        }
        if (window.goodBetterBest.order.desktop) {
            cardContainer.classList.add('order-desktop');
        }
    }

    heading.innerHTML = window.goodBetterBest.copy.heading || '';
    postHeading.innerHTML = window.goodBetterBest.copy.postHeading || '';

    clonesWithCloned.map((data, i) => {
        if (data && data.clone && data.original) {
            const { clone, original } = data;
            const btnSelector = '.quote-annex-cta button';
            const oldBtn = clone?.querySelector('.tire-quote-btn');
            const cloneSpinner = clone?.querySelector('.wait') || null;

            // remove cloned old button so that it doesn't cause conflicts when opening compare modal.
            oldBtn.remove();

            if (i === 0) {
                clone.classList.add('gbb-good');
            } else if (i === 1) {
                clone.classList.add('gbb-better');
            } else if (i === 2) {
                clone.classList.add('gbb-best');
            }
            clone?.classList.add('gbb-card');
            clone?.classList.remove('in-stock-card');
            clone?.classList.remove('call-card');
            const btn = clone.querySelector(btnSelector);
            btn &&
                btn.addEventListener('click', (e) => {
                    original?.querySelector(btnSelector).dispatchEvent(new window.CustomEvent('cloneclick'));
                    if (cloneSpinner) {
                        cloneSpinner.style.display = 'block';
                    }
                    e.target.classList.add('disabled');
                });

            if (Array.isArray(window.goodBetterBest.copy.cardHeadings)) {
                clone.setAttribute('data-heading', window.goodBetterBest.copy.cardHeadings[i] || '');
            }
            if (window.goodBetterBest.order) {
                if (Array.isArray(window.goodBetterBest.order.mobile)) {
                    clone.classList.add(`gbb-m-order-${window.goodBetterBest.order.mobile[i]}`);
                }
                if (Array.isArray(window.goodBetterBest.order.desktop)) {
                    clone.classList.add(`gbb-d-order-${window.goodBetterBest.order.desktop[i]}`);
                }
            }
            cardContainer.appendChild(clone);
        }
    });

    gbbRoot.appendChild(heading);
    gbbRoot.appendChild(cardContainer);
    gbbRoot.appendChild(postHeading);
    target.insertBefore(gbbRoot, target.firstChild);
};

export { goodBetterBest };
