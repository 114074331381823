/**
 * Prevents entering anything but numbers in textbox.
 * @param {string} event - The event object .
 * @param {string} isDecimal - Passing isDecimal as true will accept one decimal point. Default is false
 */
export const isKeypressNumber = (event, isDecimal = false) => {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 13 || (isDecimal && event.keyCode === 46)) {
        return true;
    } else if (key < 48 || key > 57) {
        return false;
    } else {
        return true;
    }
};
