import { Vehicle } from 'common/js/util/vehicle-repository/vehicle';
import { setCookie, getCookie } from 'common/js/library/util';
import { profileCookieName } from 'common/js/util/profileCookieName';

/**
 * Gets the current date/time in the format MM/DD/YYYY HH:MM:SS GMT.
 * This format is used in the bsro.cp cookie.
 * @returns {string} timestamp
 */
function currentTimestamp() {
    const now = new Date();

    const day = now.getUTCDate().toString().padStart(2, '0');
    const month = (now.getUTCMonth() + 1).toString().padStart(2, '0'); // getUTCMonth() returns 0-11
    const year = now.getUTCFullYear();

    const hours = now.getUTCHours().toString().padStart(2, '0');
    const minutes = now.getUTCMinutes().toString().padStart(2, '0');
    const seconds = now.getUTCSeconds().toString().padStart(2, '0');

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} GMT`;
}

/**
 * A simple class that stores the current vehicle for the user. This
 * interacts with the underlying persistence layer to store the vehicle.
 */
export class CurrentVehicleStore {
    static setVehicle(vehicle) {
        const { year, make, model, trim, engine, tpms } = vehicle;
        // read the vehicle cookie
        // We can assume the cookie is set. There's logic at the server side (in Varnish) to ensure this.
        // Since we are updating the cookie, we will read it directly instead of using the siteProfile.
        const bsroCp = getCookie(profileCookieName());
        if (year && make && model && (trim || engine)) {
            if (!bsroCp.vehicles) {
                throw new Error('bsro.cp cookie not found');
            }
            if (engine) {
                bsroCp.vehicles.aux = {
                    lvl: 0,
                    ymm: vehicle.toYMMString(),
                    year,
                    make,
                    model,
                    engine,
                    dt: currentTimestamp()
                };
            }
            if (year >= 1983) {
                bsroCp.vehicles.tce = {
                    lvl: 0,
                    ymm: vehicle.toYMMString(),
                    year,
                    make,
                    model,
                    trim,
                    dt: currentTimestamp(),
                    tpms
                };
                bsroCp.vehicles.main = bsroCp.vehicles.tce;
            }
        } else {
            throw new Error('Incomplete vehicle data');
        }
        // Update cookie. it should expire in 1 year from now.
        setCookie(profileCookieName(), encodeURIComponent(JSON.stringify(bsroCp)), 365);
        // Update siteProfile too.
        if (window.siteProfile?.vehicles) {
            window.siteProfile.vehicles = bsroCp.vehicles;
        }
    }

    static getVehicle(withEngine = false) {
        // Get current vehicles from siteProfile or the cookie.
        const bsroCp = window.siteProfile?.vehicles ? window.siteProfile : getCookie(`bsro.cp-${window.AEM.siteId}`);
        if (!bsroCp.vehicles) {
            throw new Error('bsro.cp cookie not found');
        }
        const cookieVehicle = withEngine ? bsroCp.vehicles.aux : bsroCp.vehicles.tce;
        const vehicle = new Vehicle(cookieVehicle);
        return vehicle;
    }
}
