export const setAdobeDataLayer = (data) => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push(data);

    return window.adobeDataLayer;
};

export const getAdobeDataLayer = () => {
    return window.adobeDataLayer || [];
};
