const findStoreDayAvailableTimes = (dayTimes, day, month, year) => {
    let storeDayAvailableTimes = null;
    if (dayTimes) {
        for (let dayTimesIndex in dayTimes) {
            const availableDay = dayTimes[dayTimesIndex].availableDay;
            const availableMonth = dayTimes[dayTimesIndex].availableMonth;
            const availableYear = dayTimes[dayTimesIndex].availableYear;
            if (availableDay == day && availableMonth == month && availableYear == year) {
                storeDayAvailableTimes = dayTimes[dayTimesIndex]?.times;
                break;
            }
        }
    }

    return storeDayAvailableTimes;
};

export { findStoreDayAvailableTimes };
