const topics = {
    CREATE_PRODUCTPRICE: 'createproductprice',
    /**
     * Trigger creation of new React reviews apps
     * @property {array} els - array of root elements to create new React reviews apps for
     */
    CREATE_REVIEWS: 'createreviews',
    /**
     * Trigger creation of new React reviews apps v2
     * @property {array} els - array of root elements to create new React reviews apps for
     */
    CREATE_REVIEWS_V2: 'createreviewsv2',
    /**
     * Trigger creation of new React interrupt annex app
     * @property {node} el - element to create new React app for
     */
    CREATE_INTERRUPT: 'createinterrupt',
    /**
     * Trigger Wating for React reviews apps ready
     */
    CREATE_REVIEWS_READY: 'createreviewsready',
    /**
     * Trigger to set headers on tire results seach
     * @property {int} inStockInventory - in stock results length
     * @property {int} outStockInventory - out stock results length
     */
    VALIDATE_SEARCH_FILTERS: 'validatesearchfilters',

    /**
     * Trigger when zip is set
     */
    MY_ZIP_SET: 'myzipset',

    /**
     * Trigger creation of sign in button
     * @property {array} els - array of root elements to create sign in button for
     */
    OFFERS_SIGN_IN_ONLY: 'offerssigninonly'
};

export { topics };
