import '../scss/hero.scss';
import { component } from 'common/js/library/component';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import Modal from 'common/js/util/coral-modal/modal';
import ShareOfferModal from 'common/components/content/offers-refresh/v1/js/offers/offer-share';
import offerDetailsTemplate from 'common/components/content/offers-refresh/v1/js/offers/tpl/offer-detail.template';
import offerShareTemplate from 'common/components/content/offers-refresh/v1/js/offers/tpl/offer-share.template';
import { getDaysLeft } from 'common/js/util/daysLeft';

// Selectors
const offerCardClass = 'offer-card-refresh';

const init = (config) => {
    document.body.classList.remove('xf-web-container');
};

const instanceInit = (rootEl) => {
    [...rootEl.getElementsByClassName(offerCardClass)].forEach((elem) => {
        if (elem.dataset.listenerAttached) return;
        elem.dataset.listenerAttached = true;

        const offerEndDate = elem.dataset?.offerEndDate || '';
        if (offerEndDate) {
            const daysLeftBeforeTheEnd = getDaysLeft(offerEndDate);
            const disclaimerElm = document.querySelector('.offer-details-disclaimer');
            if (daysLeftBeforeTheEnd > 0) {
                elem?.classList.remove('hidden');
                disclaimerElm?.classList.remove('hidden');
            }
        }
        elem.addEventListener('click', (evt) => {
            let title = elem.querySelector('.offer-card-refresh__title')?.dataset?.modalTitle || '';
            let price = elem.querySelector('.offer-card-refresh__discount-copy')?.dataset?.modalPrice || '';
            let subText = elem.querySelector('.offer-card-refresh__subtitle')?.dataset?.modalSubText || '';
            let subText1 = elem.querySelector('.offer-card-refresh__subtitle1')?.dataset?.modalSubText1 || '';
            let subText2 = elem.querySelector('.offer-card-refresh__subtitle2')?.dataset?.modalSubText2 || '';
            let shareTitleValue = '';
            if (title) {
                shareTitleValue += title;
            }
            if (price) {
                shareTitleValue += ' ' + price;
            }
            if (subText) {
                shareTitleValue += ' ' + subText;
            }
            if (subText1) {
                shareTitleValue += ' ' + subText1;
            }
            if (subText2) {
                shareTitleValue += ' ' + subText2;
            }

            if (evt.target.className.includes('offer-card-refresh__phone-cta')) {
                evt.stopPropagation();

                const data = {
                    friendlyId: elem.dataset.friendlyId,
                    title: shareTitleValue
                };

                openShareOfferModal(data);

                setAdobeDataLayer({
                    event: 'offer.modal_click',
                    modal_action: 'send_to_phone',
                    offerID: data.friendlyId,
                    offerName: data.title
                });
            } else if (evt.target.className.includes('offer-card-refresh__details-cta')) {
                return true;
            } else {
                const data = {
                    isFeatured: false,
                    friendlyId: elem.dataset.friendlyId,
                    title: title,
                    subText: subText,
                    modalTitle: elem.querySelector('.offer-card-refresh__title')?.dataset?.modalTitle || '',
                    modalSubText: elem.querySelector('.offer-card-refresh__subtitle')?.dataset?.modalSubText || '',
                    modalSubText1: elem.querySelector('.offer-card-refresh__subtitle1')?.dataset?.modalSubText1 || '',
                    modalSubText2: elem.querySelector('.offer-card-refresh__subtitle2')?.dataset?.modalSubText2 || '',
                    modalOfferDetails:
                        elem.querySelector('.offer-card-refresh__summary')?.dataset?.modalOfferDetails || '',
                    price: price,
                    shareModalTitle: shareTitleValue,
                    modalPrice: elem.querySelector('.offer-card-refresh__discount-copy')?.dataset?.modalPrice || '',
                    modalOfferDescription:
                        elem.querySelector('.offer-card-refresh__body-copy')?.dataset?.modalOfferDescription || '',
                    rebateText: elem.querySelector('.offer-card-refresh__body-copy')?.dataset?.rebateText || '',
                    landingUrl: elem.querySelector('.offer-card-refresh__body-copy')?.dataset?.landingUrl || '',
                    summary: elem.querySelector('.offer-card-refresh__summary').innerHTML,
                    validDateDays: elem.querySelector('.offer-card-refresh__valid-date-days')?.value,
                    validDateRange: elem.querySelector('.offer-card-refresh__valid-date-range')?.value,
                    disclaimer: elem.querySelector('.offer-card-refresh__disclaimer')?.value,
                    printUrl: elem.querySelector('.offer-card-refresh__print-cta')?.getAttribute('href'),
                    defaultOfferImage: elem.querySelector('.offer-card-refresh__offer-image img')?.src || ''
                };

                const modal = new Modal({
                    modalId: data.friendlyId,
                    classNames: 'offer-modal offer-modal--details',
                    content: offerDetailsTemplate(data)
                });
                const offerOverlay = modal.modalElem;

                offerOverlay?.querySelector('.offer-details__cta-phone').addEventListener('click', (ctaEvt) => {
                    ctaEvt.preventDefault();
                    const modalData = {
                        friendlyId: offerOverlay?.querySelector('.offer-details__offer')?.dataset?.friendlyId || '',
                        title:
                            offerOverlay?.querySelector('.offer-details__offer-header')?.dataset?.shareModalTitle || ''
                    };
                    openShareOfferModal(modalData);
                    setAdobeDataLayer({
                        event: 'offer.modal_click',
                        modal_action: 'send_to_phone',
                        offerID: modalData.friendlyId,
                        offerName: modalData.title
                    });
                });
            }
        });
    });
};

const openShareOfferModal = (data) => {
    new Modal({
        modalId: data.friendlyId,
        classNames: 'offer-modal offer-modal__share-text-email',
        content: offerShareTemplate(data)
    });
    ShareOfferModal('[data-share-offer-modal]');
};

component({
    selector: '.hero',
    init,
    instanceInit,
    name: 'Hero'
});
