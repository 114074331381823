export default function (data) {
    const siteName = window.AEM.siteName;

    let emailDisclaimer = '';
    let textDisclaimer = '';

    if (siteName === 'fcac') {
        emailDisclaimer = `
            <div class="offer-modal__disclaimer offer-modal__disclaimer--email hide">
                By opting in to receive text messages from Bridgestone Americas, Inc. and/or Firestone Complete Auto Care (hereinafter, “We,” “Us,” “Our”) (the “Service”), 
                you agree to these Text Messaging Terms and Conditions (the “Agreement”). Message and Data Rates may apply. Your participation in the Service is also subject
                to our Privacy Policy <a href="/privacy-statement/">(Privacy Policy | Firestone Complete Auto Care)</a> Terms of Use <a href="/legal-notice/">(Read Our Site 
                Terms of Use | Firestone Complete Auto Care)</a> and any other applicable terms and agreements related to your use of Our services. This Agreement is not 
                intended to modify other terms or the Privacy Policy that may govern the relationship between you and Us in other contexts.
            </div>
        `;
        textDisclaimer = `
            <div class="offer-modal__disclaimer offer-modal__disclaimer--text">
                By opting in to receive text messages from Bridgestone Americas, Inc. and/or Firestone Complete Auto Care (hereinafter, “We,” “Us,” “Our”) (the “Service”), 
                you agree to these Text Messaging Terms and Conditions (the “Agreement”). Message and Data Rates may apply. Your participation in the Service is also subject
                to our Privacy Policy <a href="/privacy-statement/">(Privacy Policy | Firestone Complete Auto Care)</a> Terms of Use <a href="/legal-notice/">(Read Our Site 
                Terms of Use | Firestone Complete Auto Care)</a> and any other applicable terms and agreements related to your use of Our services. This Agreement is not 
                intended to modify other terms or the Privacy Policy that may govern the relationship between you and Us in other contexts.
            </div>
        `;
    } else if (siteName === 'tiresplus') {
        emailDisclaimer = `
            <div class="offer-modal__disclaimer offer-modal__disclaimer--email hide">
                By opting in to receive text messages from Bridgestone Americas, Inc. and/or Tire Plus (hereinafter, “We,” “Us,” “Our”) (the “Service”), you agree
                to these Text Messaging Terms and Conditions (the “Agreement”). Message and Data Rates may apply. Your participation in the Service is also subject
                to our Privacy Policy  <a href="/privacy-policy/">(Read the Tires Plus Website Privacy Policy | Tires Plus)</a>, Terms of Use  <a href="/legal-notice/">(See Legal Terms of Use for the Tires Plus Site | Tires Plus)</a> 
                and any other applicable terms and agreements related to your use of Our services.This Agreement is not intended to modify other terms or the Privacy Policy
                that may govern the relationship between you and Us in other contexts.
            </div>
        `;
        textDisclaimer = `
            <div class="offer-modal__disclaimer offer-modal__disclaimer--text">
                By opting in to receive text messages from Bridgestone Americas, Inc. and/or Tire Plus (hereinafter, “We,” “Us,” “Our”) (the “Service”), you agree
                to these Text Messaging Terms and Conditions (the “Agreement”). Message and Data Rates may apply. Your participation in the Service is also subject
                to our Privacy Policy <a href="/privacy-policy/">(Read the Tires Plus Website Privacy Policy | Tires Plus)</a>, Terms of Use <a href="/legal-notice/">(See Legal Terms of Use for the Tires Plus Site | Tires Plus)</a> 
                and any other applicable terms and agreements related to your use of Our services.This Agreement is not intended to modify other terms or the Privacy Policy
                that may govern the relationship between you and Us in other contexts.
            </div>
        `;
    } else if (siteName === 'hibdontire') {
        emailDisclaimer = `
            <div class="offer-modal__disclaimer offer-modal__disclaimer--email hide">
                By opting in to receive text messages from Bridgestone Americas, Inc. and/or Hibdon Tires Plus (hereinafter, “We,” “Us,” “Our”) (the “Service”),
                you agree to these Text Messaging Terms and Conditions (the “Agreement”). Message and Data Rates may apply. Your participation in the Service is 
                also subject to our Privacy Policy <a href="/privacy/">(Privacy Policy | Hibdon Tires Plus)</a>, Terms of Use <a href="/legal/">(Read Hibdon Tires Plus Terms of Use | Hibdon Tires Plus)</a> 
                and any other applicable terms and agreements related to your use of Our services. This Agreement is not intended to modify other terms or the
                Privacy Policy that may govern the relationship between you and Us in other contexts.
            </div>
        `;
        textDisclaimer = `
            <div class="offer-modal__disclaimer offer-modal__disclaimer--text">
                By opting in to receive text messages from Bridgestone Americas, Inc. and/or Hibdon Tires Plus (hereinafter, “We,” “Us,” “Our”) (the “Service”),
                you agree to these Text Messaging Terms and Conditions (the “Agreement”). Message and Data Rates may apply. Your participation in the Service is 
                also subject to our Privacy Policy <a href="/privacy/">(Privacy Policy | Hibdon Tires Plus)</a>, Terms of Use <a href="/legal/">(Read Hibdon Tires Plus Terms of Use | Hibdon Tires Plus)</a> 
                and any other applicable terms and agreements related to your use of Our services. This Agreement is not intended to modify other terms or the
                Privacy Policy that may govern the relationship between you and Us in other contexts.
            </div>
        `;
    } else if (siteName === 'wheelworks') {
        emailDisclaimer = `
            <div class="offer-modal__disclaimer offer-modal__disclaimer--email hide">
                By opting in to receive text messages from Bridgestone Americas, Inc. and/or Wheel Works (hereinafter, “We,” “Us,” “Our”) (the “Service”),
                you agree to these Text Messaging Terms and Conditions (the “Agreement”). Message and Data Rates may apply. Your participation in the Service
                is also subject to our Privacy Policy <a href="/privacy/">(Read the Wheel Works Website Privacy Policy | Wheel Works)</a>, Terms of Use <a href="/legal/">(Read the Wheel Works Website
                Terms of Use | Wheel Works)</a> and any other applicable terms and agreements related to your use of Our services. This Agreement is not intended
                to modify other terms or the Privacy Policy that may govern the relationship between you and Us in other contexts.
            </div>
        `;
        textDisclaimer = `
            <div class="offer-modal__disclaimer offer-modal__disclaimer--text">
                By opting in to receive text messages from Bridgestone Americas, Inc. and/or Wheel Works (hereinafter, “We,” “Us,” “Our”) (the “Service”),
                you agree to these Text Messaging Terms and Conditions (the “Agreement”). Message and Data Rates may apply. Your participation in the Service
                is also subject to our Privacy Policy <a href="/privacy/">(Read the Wheel Works Website Privacy Policy | Wheel Works)</a>, Terms of Use <a href="/legal/">(Read the Wheel Works Website
                Terms of Use | Wheel Works)</a> and any other applicable terms and agreements related to your use of Our services. This Agreement is not intended
                to modify other terms or the Privacy Policy that may govern the relationship between you and Us in other contexts.
            </div>
        `;
    }
    return `
        <div class="offer-modal__body" data-share-offer-modal data-friendly-id="${data.friendlyId}" data-offer-title="${data.title}">

            <div class="offer-modal__body--description">
                <h1 class="offer-modal__title">Share Offer</h1>
                <h2 class="offer-modal__subtitle">${data.title}</h2>

               <div class="offer-modal__share-option">
                    <input type="radio" name="share_option" id="shareOptionText" value="text" checked/>
                    <label for="shareOptionText">Send by Text</label>
                    <input type="radio" name="share_option" id="shareOptionEmail" value="email" />
                    <label for="shareOptionEmail">Send by Email</label>
                </div>

                <div class="offer-modal__share-input form-global">
                    <div class="offer-modal__share-input-email hide">
                        <input type="text" name="email" class="offer-modal__textfield" value="" placeholder="Email Address">
                        <span class="offer-modal__input-error offer-modal__input-error--email hide">Please enter valid email address</span>
                        <div class="offer-modal__share-input-info">
                            <div class="custom-checkbox">
                                <input type="checkbox" name="email_sign_up" id="emailSignUp" />
                                <label for="emailSignUp">Sign me up to receive offers to my inbox</label>
                            </div>
                        </div>
                    </div>
                    <div class="offer-modal__share-input-text">
                        <input type="tel" minlength="11" name="text" class="offer-modal__textfield" value="" placeholder="Mobile Number">
                        <span class="offer-modal__input-error offer-modal__input-error--phone hide">Please enter valid phone number</span>
                        <div class="offer-modal__share-input-info">
                            Carrier charges may apply
                        </div>
                    </div>

                    <button type="button" id="btnShareOffer" class="btn btn--v2 offer-modal__btn-send">
                        Send Offer Now
                    </button>

                    <span class="offer-modal__input-error offer-modal__input-error--phone--response hide">Text could not be sent. Please verify phone number.</span>
                    <span class="offer-modal__input-error offer-modal__input-error--email--response hide">Text could not be sent. Please verify email.</span>
                    <span class="offer-modal__input-error offer-modal__input-error--generic--response hide">An error occurred. We apologize for the inconvenience. Please try again later.</span>
                    ${emailDisclaimer}
                    ${textDisclaimer}
                </div>
            </div>
            <div class="coral-Wait--container wait hide">
                <div class="coral-Wait coral-Wait--large coral-Wait--center"></div>
            </div>
        </div>
    `;
}
