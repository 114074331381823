/**
 * Converts a date string in YYYYMMDD format to a JavaScript Date object.
 * 
 * @param {string} yyyymmdd - The date string in YYYYMMDD format.
 * @returns {Date} A Date object representing the specified date.
 */
const dateFromYYYYMMDD = (yyyymmdd) => {
    const year = yyyymmdd.substring(0, 4);
    const month = yyyymmdd.substring(4, 6);
    const day = yyyymmdd.substring(6, 8);

    const date = new Date(year, month - 1, day);

    // Check if the date is invalid
    if (isNaN(date.getTime())) {
        return "Invalid date";
    }

    return date;
};

export { dateFromYYYYMMDD };
