import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';

import { ReviewsLoaderV2 } from 'common/components/content/reviews/v2/js/ui/reviewsLoader';
import Modal from 'common/components/content/react-shared/elements/modal/v1/Modal';
import { ModalContext } from 'common/components/content/react-shared/elements/modal/v1/ModalContext';
import { setModalHandler } from 'common/components/content/react-app-loader/v1/js/setModalHandler';

const RootReact = (props) => {
    const [modal, setModal] = useState({
        isOpen: false,
        content: null,
        closeModalFunction: null,
        containerClass: ''
    });

    const handleSetModal = setModalHandler(setModal);

    return (
        <>
            <ModalContext.Provider value={{ setGlobalModal: handleSetModal }}>
                <ReviewsLoaderV2/>
            </ModalContext.Provider>
            <Modal
                closeModal={modal.closeModalFunction}
                isModalOpen={modal.isOpen}
                isRoute={false}
                modalContent={modal.content}
                containerClass={modal.containerClass}
            />
        </>
    );
};

const ReviewsApp = hot(RootReact);

export { ReviewsApp };
