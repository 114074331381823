import { formatTime } from "./formatTime";

const cleanHours = (hours) => {
    var output, len, current, next, item, last, i;

    if (!hours || hours.length === 0) {
        return [];
    }

    ensureSevenDays(hours);
    sortDays(hours);

    output = [];
    len = hours.length;

    for (i = 0; i < len; i += 1) {
        current = hours[i];
        next = hours[i + 1];

        // add the first day in the list
        if (i === 0) {
            output.push(current);
        } else if (i === len - 1) {
            // last item in list
            last = output[output.length - 1];

            if (current.openTime === last.openTime && current.closeTime === last.closeTime) {
                last.weekDay += '-' + current.weekDay;
            } else {
                output.push(current);
            }
        } else {
            last = output[output.length - 1];

            if (current.openTime !== next.openTime || current.closeTime !== next.closeTime) {
                if (current.openTime === last.openTime && current.closeTime === last.closeTime) {
                    last.weekDay += '-' + current.weekDay;
                } else {
                    output.push(current);
                }
            } else {
                if (current.openTime !== last.openTime || current.closeTime !== last.closeTime) {
                    output.push(current);
                }
            }
        }
    }

    // format the times to 12-hour or Closed
    for (i = 0, len = output.length; i < len; i += 1) {
        item = output[i];

        if (item.openTime === '' || item.closeTime === '') {
            item.times = 'Closed';
        } else {
            item.times = formatTime(item.openTime) + '-' + formatTime(item.closeTime);
        }
    }

    return output;
};

const ensureSevenDays = (arr) => {
    var days = {
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6,
        sun: 7
    };

    for (var key in days) {
        if (!days.hasOwnProperty(key)) continue;

        var found = false,
            item;

        for (var i = 0, len = arr.length; i < len; i += 1) {
            item = arr[i];

            if (item.weekDay.toLowerCase() === key) {
                found = true;
            }
        }

        if (found === false) {
            arr.push({
                weekDay: key.toUpperCase(),
                openTime: '',
                closeTime: ''
            });
        }
    }
};

const sortDays = (arr) => {
    var sorter = {
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6,
        sun: 7
    };

    arr.sort(function sortByDay(a, b) {
        var day1 = a.weekDay.toLowerCase();
        var day2 = b.weekDay.toLowerCase();

        return sorter[day1] > sorter[day2];
    });

    return arr;
};

export { cleanHours };
