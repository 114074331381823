import '../scss/product-results-columns.scss';
import { freshenPrices } from './freshen-prices';
import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
import { setCookie } from 'common/js/library/util';
import { viewport } from 'common/js/library/viewport';
import { getLocalStorageItem, setLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

const init = (config) => {};

const instanceInit = (rootEl) => {
    console.log('inside product-results-columns.js');

    let currentQuery = viewport.currentQuery;
    let positionFlag = false;
    let $disclaimerControls = $(rootEl).find('.disclaimer-controls a');
    let $disclaimerShow = $disclaimerControls.filter('.show-disclaimer');
    let $disclaimerHide = $disclaimerControls.filter('.hide-disclaimer');
    let $disclaimerContent = $(rootEl).find('> .disclaimer .disclaimer-content');
    let $errorPhone = $(rootEl).find('[data-error-phone]');

    $disclaimerShow.addClass('active');
    $disclaimerControls.on('click', (evt) => {
        evt.preventDefault();
        changeDisclaimerState(props);
    });

    const setCurrentQuery = (value) => {
        currentQuery = value;
    };

    const getCurrentQuery = () => {
        return currentQuery;
    };

    const setPositionFlag = (value) => {
        positionFlag = value;
    };

    const getPositionFlag = () => {
        return positionFlag;
    };

    const props = {
        $disclaimerHide,
        $disclaimerShow,
        $disclaimerContent,
        getCurrentQuery,
        getPositionFlag,
        setCurrentQuery,
        setPositionFlag
    };

    pubsub.subscribe(viewport.topics.RESIZE, (evt) => handleEvent(evt, rootEl, props));
    $(rootEl)
        .find('img')
        .on('load', (evt) => handleEvent(evt, rootEl, props));
    // image load isn't always reliable, so also using window as a backup.
    $(window).on('load', (evt) => handleEvent(evt, rootEl, props));

    $(rootEl)
        .find('.battery-schedule-appointment-button')
        .on('click', (e) => {
            const { productName, productPrice, article } = e.currentTarget.dataset;
            const apptNotes = `Purchase and install this battery: ${productName}, ${article}, ${productPrice}. **`;
            setLocalStorageItem('apptNotes', apptNotes);
            window.location.href = '/appointment/schedule-appointment/';
        });

    $(rootEl)
        .find('.batteryQuoteBtn')
        .on('click', (evt) => {
            console.log('Battery Product Code :' + $(evt.currentTarget).attr('data-product-code'));
            var _data = {};
            _data['productCode'] = $(evt.currentTarget).attr('data-product-code');

            setCookie('bsro.ui.productID', _data['productCode']);

            $.getJSON('/bsro/services/battery/create-quote', _data).done(function (data) {
                if (data.success === 'false') {
                    return false;
                } else {
                    console.log('Battey Quote Id:' + data.data.batteryQuoteId);
                    var quoteId = data.data.batteryQuoteId;
                    if (quoteId != undefined && quoteId != null) {
                        setCookie('bsro.ui.quoteID', quoteId);

                        // *** TODO: this path need to change per site structure more than is reflected below
                        var url = '';
                        if (window.AEM.siteName == 'fcac') {
                            url =
                                '/content/bsro/' +
                                window.AEM.siteName +
                                '/en/maintain/batteries/battery-quote.html?quoteId=' +
                                quoteId;
                        } else if (window.AEM.siteName == 'tiresplus') {
                            url =
                                '/content/bsro/' +
                                window.AEM.siteName +
                                '/en/auto-repair-services/auto-battery-service/battery-quote.html?quoteId=' +
                                quoteId;
                        } else if (window.AEM.siteName == 'wheelworks') {
                            // *** TODO: verify/insert correct paths for wheelworks
                            url =
                                '/content/bsro/wheelworks/en/auto-repair/repair-service/battery-and-electrical/battery-quote.html?quoteId=' +
                                quoteId;
                        } else if (window.AEM.siteName == 'hibdontire') {
                            // *** TODO: verify/insert correct paths for hibdontire
                            url = '/content/bsro/hibdontire/en/repair/batteries/battery-quote.html?quoteId=' + quoteId;
                        }
                        $(location).attr('href', url);
                    }
                }
            });
        });

    $(rootEl)
        .find('.alignmentQuoteBtn')
        .on('click', (evt) => {
            console.log('Alignment Article :' + $(evt.currentTarget).attr('data-article'));
            var _data = {};
            _data['storeNumber'] = window.siteProfile.location.storeNumber;
            _data['articleNumber'] = $(evt.currentTarget).attr('data-article');
            _data['altype'] = $(evt.currentTarget).attr('data-type');
            _data['alpricingId'] = $(evt.currentTarget).attr('data-pricing-id');
            _data['ymm'] = window.siteProfile.vehicles.main.ymm;
            _data['trim'] = window.siteProfile.vehicles.main.trim;

            setCookie('bsro.ui.productID', _data['articleNumber']);

            $.getJSON('/bsro/services/alignment/create-quote', _data)
                .done(function (data) {
                    if (data.success === 'false') {
                        return false;
                    } else {
                        console.log('Alignment Quote Id:' + data.data.alignmentQuoteId);
                        var quoteId = data.data.alignmentQuoteId;
                        if (quoteId != undefined && quoteId != null) {
                            setCookie('bsro.ui.quoteID', quoteId);

                            // *** TODO: this path need to change per site structure more than is reflected below
                            var url = '';
                            if (window.AEM.siteName == 'fcac') {
                                url = '/repair/alignment/alignment-quote.html?quoteId=' + quoteId;
                            } else if (window.AEM.siteName == 'tiresplus') {
                                url =
                                    '/auto-repair-services/wheel-alignment-service/alignment-quote.html?quoteId=' +
                                    quoteId;
                            } else if (window.AEM.siteName == 'wheelworks') {
                                // *** TODO: verify/insert correct paths for wheelworks
                                url =
                                    '/auto-repair/maintenance-service/wheel-alignment/alignment-quote.html?quoteId=' +
                                    quoteId;
                            } else if (window.AEM.siteName == 'hibdontire') {
                                // *** TODO: verify/insert correct paths for wheelworks
                                url = '/maintenance/alignment/alignment-quote.html?quoteId=' + quoteId;
                            }
                            $(location).attr('href', url);
                        }
                    }
                })
                .fail(() => {
                    return false;
                });
        });

    const storeData = getLocalStorageItem('storeData');
    console.log('storeData:' + JSON.stringify(storeData));

    if (rootEl.classList.contains('batteries')) {
        const ymm = window.siteProfile.vehicles.aux.ymm;
        const engine = window.siteProfile.vehicles.aux.engine;
        freshenPrices(rootEl, { ymm, engine, storeNumber: storeData.storeNumber });
    }

    // update error phone to match topnav
    const errorPhone = storeData.phone;
    $errorPhone.text(errorPhone);
    //console.log('errorPhone:'+errorPhone);
    $('.product-results-columns.batteries .error-message a').attr('href', 'tel:' + errorPhone);

    checkPositions(rootEl, props);
};

/**
 * Event handler for all event listeners.
 * @memberof ProductResultsColumns
 * @private
 * @evt {event} event to be handled
 * @return {void}
 */
const handleEvent = (evt, rootEl, props) => {
    switch (evt.type) {
        case 'resize':
            // console.log('on resize event');
            update(rootEl, props);
            break;
        case 'load':
            // console.log('on load event');
            update(rootEl, props);
            break;
        default:
            throw TypeError('ProductResultsColumns._handleEvent: Event type not recognized.');
    }
};

const changeDisclaimerState = (props) => {
    const { $disclaimerContent, $disclaimerHide, $disclaimerShow } = props;
    $disclaimerShow.toggleClass('active');
    $disclaimerHide.toggleClass('active');
    $disclaimerContent.toggleClass('active');
};

const checkPositions = (rootEl, props) => {
    const { getCurrentQuery, getPositionFlag, setPositionFlag } = props;
    let queryA = getCurrentQuery();
    let queryB = viewport.getCurrentQuery();
    let $priceContainers;
    let $copyContainers;

    if ((!getPositionFlag && queryB === 'phone') || (queryA !== queryB && (queryA === 'phone' || queryB === 'phone'))) {
        $priceContainers = $(rootEl).find('.price-container');
        $copyContainers = $(rootEl).find('.copy-container');

        $priceContainers.each(function (index) {
            if (queryB === 'phone') {
                $(this).before($($copyContainers[index]));
            } else {
                $($copyContainers[index]).before($(this));
            }
        });
    }

    setPositionFlag(true);
};

/**
 * Updates the height of the container divs to allow contents to be vertically aligned
 * @memberof ProductResultsColumns
 * @public
 * @return {void}
 */
const update = (rootEl, props) => {
    const { getCurrentQuery, setCurrentQuery } = props;
    let heights = [];

    checkPositions(rootEl, props);
    setCurrentQuery(viewport.getCurrentQuery());

    // this works fine as long as disclaimer is only optional child element

    // reset heights and collect largest heights
    $(rootEl)
        .find('.column')
        .each(function (i) {
            var $column = $(this);

            $column.children().each(function (ii) {
                var $child = $(this);
                $child.height('auto');
                var childHeight = $child.height();

                heights[ii] = heights[ii] > childHeight ? heights[ii] : childHeight;
            });
        });

    // set largest heights
    if (getCurrentQuery() !== 'phone') {
        $(rootEl)
            .find('.column')
            .each(function (i) {
                var $column = $(this);

                $column.children().each(function (ii) {
                    var $child = $(this);

                    $child.height(heights[ii]);
                });
            });
    }
};

component({
    selector: '.product-results-columns',
    init,
    instanceInit,
    name: 'ProductResultsColumns'
});
