export const convertVehicleToVehicleFromAccount = (vehicle) => {
    return {
        ID: vehicle.id,
        YEAR: `${vehicle.year}`,
        MAKE: vehicle.make,
        MODEL: vehicle.model,
        SUBMODEL: vehicle.trim,
        ENGINE: vehicle.engine,
        BASE_VEHICLE_ID: vehicle.acesid,
        NAME: vehicle.nickname,
        ODOMETER: vehicle.odometer,
        DRIVING_CONDITION: vehicle.drivingCondition,
        IMAGE_URL: vehicle.imageUrl
    };
};
