import '../scss/fleetcare-registration.scss';
import { queryStringify, responseError } from 'common/js/data/services/serviceUtilities';
import { serializeForm, validateElement } from 'common/js/library/util';
import { component } from 'common/js/library/component';

let passengerVehicle;
let truckVehicle;
let otherVehicle;
let descIntro;
let fieldsSection;
let formSubmit;
let confirmationView;
const init = (config) => {
    passengerVehicle = document.querySelector('#noOfPassengerVehicle');
    truckVehicle = document.querySelector('#noOfLightTruckVehicle');
    otherVehicle = document.querySelector('#noOfOtherVehicle');
    descIntro = document.querySelector('.desc-intro');
    fieldsSection = document.querySelectorAll('.contact-fields-simple .fields-section');
    formSubmit = document.querySelector('.contact-fields-simple .form-submit');
    confirmationView = document.querySelector('.contact-fields-simple .confirmation-view');
};
const instanceInit = (rootEl) => {
    let submitBtn = rootEl.querySelector('.form-submit .register-btn');
    let numericVal = rootEl.querySelectorAll('.numericVal');
    let phoneVal = rootEl.querySelectorAll('.phoneNumber');
    let regForm = document.querySelector('#registration-form');
    const phoneRegExp = /^(\d{3})(\d{3})(\d{4})$/;

    const formatPhoneNumber = input => {
        const numericValue = input.replace(/\D/g, '');
        const match = numericValue.match(phoneRegExp);

        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }

        return numericValue;
    };

    phoneVal.forEach(inputElement => {
        inputElement.addEventListener('input', event => {
            let inputValue = event.target.value;

            if (event.inputType === 'insertFromPaste' && inputValue.length > 10) {
            inputValue = inputValue.slice(0, 10);
            }

            if (event.inputType !== 'insertFromPaste' && inputValue.length > 12) {
            inputValue = inputValue.slice(0, 12);
            }

            const formattedInput = formatPhoneNumber(inputValue);
            event.target.value = formattedInput;
        });
    });

    numericVal.forEach(numericVal => {
        numericVal.addEventListener('keyup', (evt) => {
            let currentTarget = evt.currentTarget;
            let _val = currentTarget && currentTarget.value;
            let _initVal = _val;

            _val = _val.replace(/[^0-9.]/g, '');

            if (_initVal != _val) {
                currentTarget && (currentTarget.value = _val);
            }
        });
    });

    let htmlElements = rootEl.querySelectorAll('input, select, textarea');
    [...htmlElements].forEach((el) => {
        el && el.addEventListener('change', (evt) => handleFieldChange(evt, submitBtn, rootEl));
    });
    [...htmlElements].forEach((el) => {
        el && el.addEventListener('blur', (evt) => handleFieldChange(evt, submitBtn, rootEl));
    });
    [...htmlElements].forEach((el) => {
        el && el.addEventListener('keyup', (evt) => handleFieldChange(evt, submitBtn, rootEl));
    });
    regForm.addEventListener('submit', (evt) => handleSubmit(evt, submitBtn, rootEl));
};

const handleFieldChange = (evt, submitBtn, rootEl) => {
    let data = getFormValues(rootEl);
    let disabled = true;
    let field = evt.currentTarget;
    if (validate(data)) {
        disabled = false;
    }
    submitBtn.disabled = disabled;
};

const handleSubmit = (evt, submitBtn, rootEl) => {
    let data = getFormValues(rootEl);
    let emailValidRes = validateElement(data.email, 'email');
    let zipValidRes = validateElement(data.zip, 'zipcode');
    if (!validate(data) || !emailValidRes || !zipValidRes) {
        submitBtn.disabled = true;
        evt.preventDefault();
    } else {
        return;
    }
};

const getFormValues = (formEl) => {
    return serializeToObject(formEl);
};

const validate = (data) => {
    if (
        data.company !== '' &&
        data.zip !== '' &&
        data.first_name !== '' &&
        data.last_name !== '' &&
        data.email !== '' &&
        data.phone !== '' &&
        passengerVehicle.value !== '' &&
        truckVehicle.value !== '' &&
        otherVehicle.value !== ''
    ) {
        return true;
    }

    return false;
};

const serializeToObject = (formEl) => {
    let _s_obj = serializeForm(formEl);
    if (passengerVehicle.value !== '') {
        _s_obj[passengerVehicle.getAttribute('name')] = passengerVehicle.value;
    } else {
        _s_obj[passengerVehicle.getAttribute('name')] = '0';
    }

    if (truckVehicle.value !== '') {
        _s_obj[truckVehicle.getAttribute('name')] = truckVehicle.value;
    } else {
        _s_obj[truckVehicle.getAttribute('name')] = '0';
    }

    if (otherVehicle.value !== '') {
        _s_obj[otherVehicle.getAttribute('name')] = otherVehicle.value;
    } else {
        _s_obj[otherVehicle.getAttribute('name')] = '0';
    }
    return _s_obj;
};

component({
    selector: '[data-registration-form]',
    init,
    instanceInit,
    name: 'FleetcareRegistration'
})
