import { stringSanitizer } from 'common/js/util/stringSanitizer';

const getVehicleSearchURL = (year, make, model, trim) => {
    let url = '/tires/vehicle/';
    if (year && make && model && trim) {
        const makeFriendlyName = stringSanitizer(make, 'lower', '-');
        const modelFriendlyName = stringSanitizer(plusSanitizer(model), 'lower', '-');

        //replace edge case where trim ends '.' ex. "Warner Bros."
        trim = trim.replace(/\.$/, '');

        const trimFriendlyName = trim === '+' || trim === '!' ? trim : stringSanitizer(trim, 'lower', '-');

        url = `${url}${makeFriendlyName}/${modelFriendlyName}/${year}/${trimFriendlyName}/`;
    }

    return url;
};

const plusSanitizer = (str, replacement = 'plus') => {
    if (!str) return str;
    let sanitizedStr = str.replace(/[+]+/g, replacement);
    return sanitizedStr;
};
export { getVehicleSearchURL };
