import { dateFromYYYYMMDD } from './dateFromYYYYMMDD';
import { dateToTimezone } from './dateToTimezone';
import { dateToYearMonthDay } from './dateToYearMonthDay';
import { timeToHourMinutes } from './timeToHourMinutes';
/**
 * Converts a date in YYYYMMDD format and a time in 'HH:MMam/pm' format into a complete timestamp string
 * with an optional timezone. If the timezone is not provided, it calculates the timezone based on the given date.
 * 
 * converts these ('20190502', '4:39pm'), to this timestamp format:
 * 2019-05-02T16:39:00-06:00
 *
 * @param {string} yyyymmdd - The date in YYYYMMDD format.
 * @param {string} time - The time in 'HH:MMam/pm' format.
 * @param {string} [timezone] - The timezone offset as a string (e.g., '-06:00'). If not provided, it is calculated.
 * @returns {string} A timestamp in the format 'YYYY-MM-DDTHH:MM:00ZZ:00', where ZZ is the timezone offset.
 */
export const timestampFromYYYYMMDDTime = (yyyymmdd, time, timezone) => {
    const { hour, minutes } = timeToHourMinutes(time);
    const { year, month, day } = dateToYearMonthDay(dateFromYYYYMMDD(yyyymmdd));

    if (!timezone) {
        const dateForTimezone = new Date(`${year}-${month}-${day}T${hour}:${minutes}:00+00:00`);
        timezone = dateToTimezone(dateForTimezone);
    }

    return `${year}-${month}-${day}T${hour}:${minutes}:00${timezone}`;
};
