const addNullData = (data, trim) => {
    let newData = data;

    for (let o in newData) {
        let zip = undefined;
        let selectedServices = undefined;
        let services = [];

        if (typeof newData[o] === 'object') {
            addNullData(newData[o], trim);
        }

        switch (o.toString()) {
            case 'year':
                newData[o] = newData[o] || document.querySelector('[name=year]')?.value || '';
                break;
            case 'make':
                newData[o] = newData[o] || document.querySelector('[name=make]')?.value || '';
                break;
            case 'model':
                newData[o] = newData[o] || document.querySelector('[name=model]')?.value || '';
                break;
            case 'subModel':
                newData[o] = newData[o] || document.querySelector('[name=submodel]')?.value || '';
                break;
            case 'city':
                newData[o] = newData[o] || document.querySelector('[name=city]')?.value || '';
                break;
            case 'state':
                newData[o] = newData[o] || document.querySelector('[name=state]')?.value || '';
                break;
            case 'zip':
                zip = document.querySelector('[name=zip]')?.value || '';

                if (parseInt(zip)) {
                    zip = parseInt(zip);
                } else {
                    zip = null;
                }

                newData[o] = newData[o] || zip;
                break;
            case 'storeNumber':
                newData[o] = newData[o] || window.siteProfile.location.storeNumber;
                break;
            case 'firstName':
                newData[o] = newData[o] || document.querySelector('[name=firstName]')?.value || '';
                break;
            case 'lastName':
                newData[o] = newData[o] || document.querySelector('[name=lastName]')?.value || '';
                break;
            case 'email':
                newData[o] = newData[o] || document.querySelector('[name=email]')?.value || '';
                break;
            case 'telephone':
                newData[o] = newData[o] || document.querySelector('[name=phoneNumber]')?.value || '';
                break;
            case 'service':
                selectedServices = document.querySelectorAll('input[type=checkbox]:checked');
                selectedServices.forEach((item) => {
                    services.push(item.value);
                });
                newData[o] = newData[o] || services.join('|');
                break;
            case 'time':
                newData[o] = newData[o] || document.querySelector('[name=timeSelect]')?.value || '';
        }

        if (trim) {
            if (newData[o] == '' || newData[o] == undefined || newData[o] == null) {
                delete newData[o];
            }
        }
    }

    return newData;
};

export { addNullData };
