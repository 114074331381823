class Modal {
    constructor(options) {
        console.group('Modal');

        const modalMarkup = this.getModalMarkup(options);

        this.handleModalClose();

        const elem = document.createElement('div');
        elem.innerHTML = modalMarkup;
        document.body.appendChild(elem);

        setTimeout(() => {
            document.querySelector('.refresh-modal').classList.add('modal--show');
        }, 0);

        elem?.querySelector('.refresh-modal__close').addEventListener('click', this.destroy.bind(this));
        elem?.querySelector('.refresh-modal__overlay').addEventListener('click', this.destroy.bind(this));
        elem?.querySelector('[data-dismiss="modal"]') &&
            elem?.querySelector('[data-dismiss="modal"]').addEventListener('click', this.destroy.bind(this));
        document.body.classList.add('refresh-modal--visible');
        this.modalElem = elem;

        console.groupEnd();
    }

    getModalMarkup(options) {
        return `
            <div id="modal-${options.modalId}" class="modal refresh-modal ${
                options.classNames
            }" aria-modal="true" role="dialog">
                <div class="refresh-modal__overlay"></div>
                <div class="refresh-modal__wrapper">
                    <button type="button" class="refresh-modal__close btn--close" title="Close">x</button>
                    <div class="refresh-modal__content">
                        ${options.content || ''}
                    </div>
                </div>
            </div>
        `;
    }

    handleModalClose() {
        if (document.querySelector('.refresh-modal')) {
            document.body.classList.remove('refresh-modal--visible');
            document.body.classList.remove('coral-Modal.is-open');
            document.querySelector('.refresh-modal').parentNode.remove();
        }
    }

    destroy() {
        const self = this;
        document.body.classList.remove('refresh-modal--visible');
        document.body.classList.remove('coral-Modal.is-open');
        self.modalElem.remove();
    }
}

export default Modal;
