import { cleanHolidays } from './cleanHolidays';
import { cleanHours } from './cleanHours';
import { findStores } from './findStores';
import { updateStoreResultsContainer } from './updateStoreResultsContainer';
import { getAccountState } from './getAccountState';
import { cleanObject, setCookie } from 'common/js/library/util';
import { queryStringify, HTTPHeaders } from 'common/js/data/services/serviceUtilities';
import { addStoreToProfile } from 'common/js/data/redux/actionCreators/account';
import { deleteLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { initModalPopover } from 'common/js/util/coral-modal/initModalPopover';
import { pubsub } from 'common/js/library/pubsub';
import { store as ReduxStore } from 'common/js/data/redux/store/exportedStore';

const selectStore = (zip, store, rootEl, props) => {
    const {
        getSelectedStoreNum,
        setSelectedStoreNum,
        getStepDisable,
        getIsLoggedIn,
        getPreferredStoreNumber,
        getSelectedStore,
        setSelectedStore,
        searchZipInput,
        setIsLoggedIn,
        setPreferredStoreNumber,
        setZip
    } = props;
    setSelectedStoreNum(store);

    if (zip && getSelectedStoreNum()) {
        updateStoreResultsContainer(getSelectedStoreNum(), rootEl, props);
    }

    var saveStoreService =
        zip != null ? '/bsro/services/store/save-mystore' : '/bsro/services/store/set-store-by-store-number';
    var saveStoreObjParam = zip != null ? { zipCode: zip, storeNumber: store } : { storeNumber: store };
    var _defer = $.Deferred();

    if (getPreferredStoreNumber() != null && getPreferredStoreNumber() != getSelectedStoreNum() && getIsLoggedIn()) {
        const preferredStoreModalToggle = document.querySelector('.preferredStoreModalToggle');
        preferredStoreModalToggle.dispatchEvent(new Event('click'));

        const setPreferredStoreBtns = document.querySelectorAll('.preferredStoreSubModal [data-set-preferred-store]');
        setPreferredStoreBtns.forEach((btn) => {
            btn.addEventListener('click', async () => {
                const currentState = ReduxStore.getState();
                if (currentState.account.isLoggedIn) {
                    const dispatched = await ReduxStore.dispatch(addStoreToProfile(store));
                    console.log('DISPATCHED RESULT: ', dispatched);

                    setCookie('hasSetPreferredStore', true);

                    const coralModalBackdrop = document.querySelector('.coral-Modal-backdrop');
                    coralModalBackdrop.dispatchEvent(new Event('click'));

                    _defer.resolve();
                }
            });
        });

        const setStoreBtns = document.querySelectorAll('.preferredStoreSubModal [data-set-store]');
        setStoreBtns.forEach((btn) => {
            btn.addEventListener('click', () => {
                const coralModalBackdrop = document.querySelector('.coral-Modal-backdrop');
                coralModalBackdrop.dispatchEvent(new Event('click'));

                _defer.resolve();
            });
        });
    } else {
        _defer.resolve();
    }

    Promise.resolve(_defer).then(function () {
        return fetch(`${saveStoreService}${queryStringify(saveStoreObjParam)}`, {
            method: 'GET',
            headers: HTTPHeaders()
        })
            .then(function (response) {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Error: ' + response.status);
                }
            })
            .then(async function (data) {
                await getAccountState(setIsLoggedIn, setPreferredStoreNumber);
                return data;
            })
            .then(function (data) {
                data = data.data || data;

                if (data) {
                    setSelectedStore(data);
                    const selectedStore = getSelectedStore();
                    setZip(selectedStore.zip.substr(0, 5));

                    var _hours = selectedStore.hours;
                    var _holidays = selectedStore.holidays;
                    var _holidayHours = selectedStore.holidayHours;

                    var storeInfo = rootEl.querySelector('span.storeInfo');
                    if (storeInfo) {
                        storeInfo.innerHTML = '';
                        storeInfo.innerHTML += '<strong>Store: #' + selectedStore.storeNumber + '</strong><br/>';
                        storeInfo.innerHTML += selectedStore.address + '<br/>';
                        storeInfo.innerHTML += selectedStore.city + ', ' + selectedStore.state;
                    }
                    var callcta = 'tel:' + selectedStore.phone.slice(1, 15).replace(/\)\s/g, '-');
                    rootEl.querySelector('.appt-step-2 .call-cta')?.setAttribute('href', callcta);

                    if (_hours) {
                        selectedStore.hours = cleanHours(_hours);
                    }

                    if (_holidays) {
                        selectedStore.holidays = cleanHolidays(_holidays, _holidayHours);
                    }

                    if (window.digitalData) {
                        var _attributes = {
                            zip: zip,
                            city: selectedStore.city,
                            state: selectedStore.state,
                            storeNumber: selectedStore.storeNumber
                        };

                        if (window.digitalData.event) {
                            if (window.digitalData.event.length == 0) {
                                window.digitalData.event[0] = {
                                    attributes: _attributes
                                };
                            } else {
                                window.digitalData.event[0].attributes = _attributes;
                            }
                        }

                        if (
                            window.digitalData &&
                            Array.isArray(window.digitalData.user) &&
                            window.digitalData.user.length > 0
                        ) {
                            const user = window.digitalData.user[0];

                            if (Array.isArray(user.profile) && user.profile.length > 0) {
                                const profile = user.profile[0];

                                profile.attributes = {
                                    storeNumber: selectedStore.storeNumber
                                };
                                profile.postalCode = zip;
                                profile.city = selectedStore.city;
                                profile.stateProvince = selectedStore.state;
                            }
                        }
                        cleanObject(window.digitalData);
                    }

                    if (zip == null) {
                        searchZipInput.value = zip;
                        findStores(rootEl, props);
                    }
                }

                const tabContainer = document.querySelector('.primary-nav #tab-my-store-container');
                const storeUrl = `/content/bsro/${window.AEM.siteName}/static/top-nav/tab-store.${store}.html`;

                fetch(storeUrl)
                    .then((response) => response.text())
                    .then((html) => {
                        tabContainer.innerHTML = html;

                        const mapImg = document.querySelector('.primary-nav #tab-my-store-container .col.map img');
                        mapImg.src = mapImg.dataset.src;

                        document.body.classList.remove('no-local-store');
                        document.body.classList.add('has-local-store');

                        setTimeout(initModalPopover, 500); // from common
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

                if (getStepDisable()) {
                    if (window.location.href.indexOf('get-directions') !== -1) {
                        if (window.location.hostname === 'localhost') {
                            window.location.href =
                                '/content/bsro/' + window.AEM.siteName + '/en/locate/get-directions.' + store + '.html';
                        } else {
                            window.location.href = '/locate/get-directions/' + store;
                        }
                    } else {
                        const selectedStore = getSelectedStore();
                        if (window.location.search.indexOf('storeNumber') !== -1) {
                            var searchStoreNum = window.location.search.split('=')[1];
                            if (selectedStore.storeNumber !== searchStoreNum) {
                                window.location.search = '?storeNumber=' + selectedStore.storeNumber;
                            } else {
                                document.querySelector('#find-store-modal').style.display = 'none';
                            }
                        } else if (window.location.href.indexOf('tire-quote') !== -1) {
                            pubsub.publish('store_change_complete', [selectedStore]);
                            document.querySelector('#find-store-modal').style.display = 'none';
                        } else if (window.location.search.indexOf('store') !== -1) {
                            let _cleanUrl = window.location.href.split(/[?#]/)[0];
                            window.location.replace(_cleanUrl);
                        } else {
                            //if preferred store changed from TDP
                            if (document.querySelector('.product-detail-v2')) {
                                if (ReduxStore.getState().ecomm.checkout.step === 0) {
                                    if (ReduxStore.getState().ecomm.store.storeNumber !== selectedStore.storeNumber) {
                                        deleteLocalStorageItem('pricingDetails');
                                    }
                                }
                            }
                            window.location.reload();
                        }
                    }
                }
            })
            .catch(function (error) {
                console.error('Error:', error);
            });
    });
};

export { selectStore };
