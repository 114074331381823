// fecthes a store's services based on store number
export const fetchServiceData = async (storeNumber) => {
    const url = `/bsro/services/store/services?storeNumber=${storeNumber}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
        const responseJson = await response.json();

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};
