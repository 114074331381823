import { initModalPopover } from 'common/js/util/coral-modal/initModalPopover';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { setCookie, getCookie } from 'common/js/library/util';

const editPopOverAnalytics = () => {
    let searchType;
    let siteProfile = window?.siteProfile;
    const pathname = document.location.pathname.split('/');
    const zip = siteProfile.location.myZip || siteProfile.location.autoZip;
    // vehical data
    const year = siteProfile.vehicles.tce.year
    const make = siteProfile.vehicles.tce.make
    const model =  siteProfile.vehicles.tce.model
    const subModel = siteProfile.vehicles.tce.trim
    // tire data
    const xSection = siteProfile.tires.tce.cs;
    const aspect = siteProfile.tires.tce.ar;
    const rimSize = siteProfile.tires.tce.rs;
    const tire = {};
    const vehicle = {}
    const matchedSizes = [];

    if (pathname[1] === 'tires' && pathname[2] === 'vehicle') {
        searchType = 'vehicle';
        vehicle.year = year ? year : '';
        vehicle.make = make ? make : '';
        vehicle.model = model ? model  : '';
        vehicle.subModel = subModel ? subModel : '';
    } else if (pathname[1] === 'tires' && pathname[2] === 'size') {
        searchType = 'tire size'
        if (xSection) tire['x-section'] = xSection;
        if (aspect) tire.aspect = aspect;
        if (rimSize) tire['rim-size'] = rimSize;

        matchedSizes.push(`${tire['x-section']}:${tire.aspectRatio}:${tire['rim-size']}`);
    }

    const view = document.querySelector(".tsr-view-toggle .active");
    setAdobeDataLayer({
        event: 'tires.results',
        link_type: 'tire_detail',
        list_view: view?.classList.contains("list") ? 'list' : 'grid',
        'matched-sizes':matchedSizes,
        'search-type': searchType,
        tire:tire,
        vehicle: vehicle,
        zip: zip,
    });
}

const initialSetup = () => {
    console.group('initial setup');
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

    // TODO: This should be a part of local-store component
    const ctaStoreBar = document.querySelectorAll('.cta-store-bar');
    const ctaStoreBarHandler = (evt) => {
        console.log('.cta-store-bar', evt);
        evt.stopPropagation();
    };
    ctaStoreBar.forEach((item) => {
        item.addEventListener('touchstart', ctaStoreBarHandler);
        item.addEventListener('touchend', ctaStoreBarHandler);
    });

    // modify back button behavior if data attribute of "back" found
    // TODO: Needs to be removed from product-quote component
    const dataBack = document.querySelectorAll('[data-back]');
    const dataBackHandler = (evt) => {
        evt.preventDefault();
        window.history.back();
    };
    dataBack.forEach((item) => {
        item.addEventListener('click', dataBackHandler);
        item.addEventListener('touchstart', dataBackHandler);
    });

    // modify print button behavior it data attribute of "print" found
    const dataPrint = document.querySelectorAll('[data-print]');
    const dataPrintHandler = (evt) => {
        evt.preventDefault();
        window.print();
    };
    dataPrint.forEach((item) => {
        item.addEventListener('click', dataPrintHandler);
    });

    // TODO: This should be moved to the vehicle selection tool related to this
    const changeVehiclePopover = document.querySelectorAll('a.change-vehicle-popover');
    changeVehiclePopover.forEach((item) => {
        item.addEventListener('click', (evt) => {
            editPopOverAnalytics();
            evt.preventDefault();
            evt.currentTarget.closest('.vehicle-info-bar').classList.toggle('popOver');
        });
    });

    // add print hash to all print coupon buttons
    // TODO: verify if this is still needed
    /*const couponBtns = document.querySelectorAll('.dL_couponClick');
    couponBtns.forEach((item) => {
        item.href = `${item.href}#print`;
    });*/

    // on init of offer details page
    // this assumes that only offers would ever need a hash of print in the url
    // TODO: this should be moved to offer details component
    if (document.querySelector('.component .offer-details')) {
        if (window.location.hash.substring(1) === 'print') {
            const pathname = window.location.pathname.replace(/\/\s*$/, '');
            const pathnameSplit = pathname.split('/');
            const offerID = pathnameSplit[pathnameSplit.length - 1];
            const printButtonEl = document.querySelector('[data-print]');
            const offerName = printButtonEl.dataset['couponName'] || printButtonEl.dataset['coupon-name'];

            setAdobeDataLayer({
                event: 'offer.print',
                offerID,
                offerName
            });

            window.setTimeout(function () {
                window.print();
            }, 1000);
        }
    }

    // TODO: this should be moved to promotion detail component
    const promotionDetail = document.querySelector('.promotion-detail');
    if (promotionDetail) {
        const promoStartEndDate = promotionDetail.querySelector('.offerStartEndDate');

        if (!promoStartEndDate.classList.contains('hasDate')) {
            const validDays = promoStartEndDate.getAttribute('data-expiry-length')
                ? parseInt(promoStartEndDate.getAttribute('data-expiry-length'))
                : 20;
            let validDate = new Date();
            const todayDate = `${validDate.getMonth() + 1}/${validDate.getDate()}/${validDate.getFullYear()}`;

            //set future expiration date
            validDate.setDate(validDate.getDate() + validDays);

            //get date when promotion expires
            const expireDate = `${validDate.getMonth() + 1}/${validDate.getDate()}/${validDate.getFullYear()}`;
            const validThru = `${todayDate} - ${expireDate}`;

            promoStartEndDate.querySelector('.offer-dates').textContent = validThru;
            promoStartEndDate.classList.add('hasDate');
        }
    }

    if (window.isEcom) {
        console.log('has ecomm');
        // cart guid needed for minicartcount
        // const guid = getCookie('bsro.ui.cartGUID');

        // kill an invalid cookie if profile is non-ecomm format but site mode is ecomm
        if (window?.siteProfile?.vehicles && !window.siteProfile.vehicles.tce) {
            setCookie(`bsro.cp-${window.AEM.siteId}`, null);
            window.location.reload(true);
        }
    } else {
        console.log('no ecomm');
    }

    window.initModalPopover = initModalPopover;
    initModalPopover();

    // open a modal by default
    if (window.location.hash.indexOf('.open') != -1) {
        const hash = window.location.hash.split('.open')[0];
        const modal = `[href="${hash}"]`;
        document.querySelector(modal).classList.trigger('click');
    }

    if (userAgent.match(/(iPad)|(iPhone)/i)) {
        const coralModals = document.querySelectorAll('.coral-Modal');
        coralModals.forEach((item) => {
            item.classList.add('coral-Modal-ios');
        });
    }

    window.testIfWorking = 'test';

    window.BSRO = Object.assign({}, window.BSRO, {
        core: {
            setVisitorId: (vid) => {
                const siteVidId = window?.siteProfile?.vid ? window.siteProfile.vid : '';
                const visitor = window.adobeInstanceId ? window?.Visitor?.getInstance(window.adobeInstanceId) : null;
                let mcID = visitor ? visitor.getMarketingCloudVisitorID() : '';

                if (vid) {
                    mcID = vid;
                }

                if (mcID && mcID != siteVidId) {
                    const req = new XMLHttpRequest();
                    const reqURL = '/bsro/services/profile/update-vid?vid=';

                    req.open('GET', reqURL + mcID);
                    req.send();

                    window.BSRO.visitor && (window.BSRO.visitor.id = mcID);
                }
            }
        }
        // visitor: {
        //     set id(id) {
        //         this._id = id;
        //     },
        //     get id() {
        //         return this._id || window?.siteProfile?.vid;
        //     }
        // }
    });
    window.BSRO.core.setVisitorId();

    const rwg = 'rwg_token';
    const rwgCookie = getCookie(rwg);
    const queries = window.location.search;
    const numberDays = 30;
    const searchParams = new URLSearchParams(queries);
    
    if(searchParams.has(rwg)) {
        const value = encodeURIComponent(searchParams.get(rwg));
       if(value !== '' && value !== rwgCookie) setCookie(rwg, value, numberDays);
    }
    console.groupEnd;
};

export { initialSetup };
