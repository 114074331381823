/**
 * returns Tire Detail Page Path
 * removes all special characters and spaces
 * @param {string} brand - Brand name string to modify
 * @param {string} tireName - Tire name string to modify
 * @param {string} articleId - Article id to modify
 * @param {string} quoteId 
 */
import { stringSanitizer } from './stringSanitizer';

const getTDPPath = (brand, tireName, articleId, quoteId) => {
    if (!brand) return brand;
    if (!tireName) return tireName;

    const sanitizedBrand = stringSanitizer(brand);

    const sanitizedTireName = stringSanitizer(tireName?.replaceAll('+','plus'));

    const sanitizedArticleId = articleId && stringSanitizer(articleId);

    const sanitizedQuoteId = quoteId && stringSanitizer(quoteId);

    let hrefTDP = `/tires/brands/${sanitizedBrand}/${sanitizedTireName}`;

    // If articleId is present, append it to the path
    if (articleId) {
        hrefTDP += `/${sanitizedArticleId}`;
    }

    // Final trailing slash
    hrefTDP += '/';

    // If quoteId is present, append it to the path
    if (quoteId) {
        hrefTDP += `?${sanitizedQuoteId}`;
    }

    let tdpPath = hrefTDP.toLocaleLowerCase();

    return tdpPath;
};


export { getTDPPath };