import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

export const hasInventory = (cartQuantity) => {
    const qrd = getLocalStorageItem('quoteResponseData');
    if (!qrd) return false;

    const { quantity, inventoryFront, inventoryRear } = qrd;
    let frontPass = false;
    let rearPass = false;
    cartQuantity = Number(cartQuantity) || Number(quantity);
    if (inventoryRear) cartQuantity = cartQuantity / 2;

    if (inventoryFront && cartQuantity) {
        frontPass = Number(inventoryFront) >= cartQuantity * 2;
    }

    // if split-fit, check rear inventory too
    if (inventoryRear) {
        rearPass = Number(inventoryRear) >= cartQuantity * 2;
    } else {
        rearPass = true;
    }

    console.log(
        'hasInventory:',
        cartQuantity,
        inventoryFront,
        inventoryRear,
        frontPass,
        rearPass,
        frontPass && rearPass
    );

    return frontPass && rearPass;
};
