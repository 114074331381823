import { getCookie } from 'common/js/library/util';
import { timestampFromYYYYMMDDTime } from 'common/js/util/date-time/timestampFromYYYYMMDDTime';
import { timestampToCustomDateTime } from 'common/js/util/date-time/timestampToCustomDateTime';
import { pubsub } from 'common/js/library/pubsub';
import { getEcid } from 'common/js/library/get-ecid';

const submitAppointment = (evt, rootEl, props) => {
    const {
        appointmentDetails,
        addedServicesListArr,
        getApptDataPackage,
        setApptDataPackage,
        confirmPost,
        submitButton,
        getAppointmentDetails,
        dateStoreStep,
        getStoresInfo,
        getEventsAdded,
        setEventsAdded
    } = props;
    const isValid = evt.target.checkValidity();
    const dateTime = appointmentDetails.date;
    const serviceIds = appointmentDetails.store.service;
    const selectedServicesIds = [serviceIds.serviceId];
    const storeNote = rootEl.querySelector('[name=store-notes]');
    const dropOffEl = rootEl.querySelector('[name=wait-or-drop-off]:checked');
    const dropOffVal = dropOffEl.value.toString() == '0' ? 'wait' : 'drop';
    const pickupPrefEl = rootEl.querySelector('[name=pickupPreference]');
    const pickupPrefVal = pickupPrefEl.value;
    const hasOtherServices = addedServicesListArr.toString().toLowerCase().indexOf('other') != -1 ? true : false;
    const otherServiceComments = rootEl.querySelector('[name=other-service]')?.value || null;
    const datetime = timestampToCustomDateTime(
        timestampFromYYYYMMDDTime(String(dateTime.availableDate), String(dateTime.selectedTime))
    );
    const currentApptDataPackage = getApptDataPackage();
    let comments = rootEl.querySelector('[name=comments]').value || '';
    let storeNoteVal = storeNote.value;

    evt.preventDefault();

    function formatPhoneNumber(phoneNumber) {
        // Use a regular expression to remove the parentheses
        return phoneNumber.replace(/\(|\)/g, '').replace(' ', '-');
    }

    if (serviceIds.additionalServices != '') {
        selectedServicesIds.push(serviceIds.additionalServices);
    }

    if (hasOtherServices) {
        let desc = otherServiceComments ? otherServiceComments : 'none';
        if (comments) {
            comments = `${comments}, `;
        }
        comments = `${comments}service request: other, description: ${desc}`;
    }

    if (dropOffVal == 'drop' && pickupPrefVal != '') {
        if (comments) {
            comments = `${comments}, `;
        }
        comments = `${comments}pickup preference: ${pickupPrefVal}`;
    }

    if (storeNoteVal.charAt(storeNoteVal.length - 1) !== ';' && storeNoteVal !== '') {
        storeNoteVal = storeNoteVal + ';';
    }
    comments = comments === '' ? storeNoteVal : `${storeNoteVal} ${comments}`;

    const dataPackage = Object.assign({}, currentApptDataPackage, {
        storeNumber: appointmentDetails.store.storeNumber,
        locationId: serviceIds.locationId,
        employeeId: serviceIds.employeeId,
        appointmentStatusId: serviceIds.statusId,
        appointmentStatusDesc: 'Confirmed',
        vehicleYear: appointmentDetails.vehicle.year,
        vehicleMake: appointmentDetails.vehicle.make,
        vehicleModel: appointmentDetails.vehicle.model,
        vehicleSubmodel: appointmentDetails.vehicle.trim,
        mileage: rootEl.querySelector('.vehicle-mileage').value || '',
        customerNotes: '',
        comments: comments || 'none',
        customerFirstName: rootEl.querySelector('input[name=first-name]').value,
        customerLastName: rootEl.querySelector('input[name=last-name]').value,
        customerCity: 'na',
        customerDayTimePhone: formatPhoneNumber(rootEl.querySelector('input[name=phone]').value),
        customerEmailAddress: rootEl.querySelector('input[name=email]').value,
        customerCommunicationPreferences:
            rootEl.querySelector('input[name="communication-preference"]:checked')?.value || '',
        websiteName: window.siteProfile.site || 'FCAC',
        appointmentType: 'New',
        quoteId: getCookie('bsro.ui.quoteID'),
        choice: {
            appointmentChoiceId: '1',
            choice: '1',
            datetime,
            dropWaitOption: dropOffVal,
            appointmentId: '0',
            pickUpTime: dateTime.selectedTime,
            dropOffTime: dateTime.selectedTime
        },
        serviceNamesList: addedServicesListArr.toString().replace('+', ' Plus').replace(/\s+/g, '+'),
        selectedServices: selectedServicesIds.toString(),
        visitorID: getEcid(),
        optin: rootEl.querySelector('[name=email-optin]').checked ? 'YES' : 'NO',
        verify: currentApptDataPackage.verify || 'true',
        salt: Date.now(),
        timeSlotId: dateTime.timeSlotId
    });

    switch (dataPackage.customerCommunicationPreferences) {
        case 'email':
            dataPackage.customerCommunicationPreferences = ['email'];
            break;
        case 'sms':
            dataPackage.customerCommunicationPreferences = ['sms'];
            break;
        case 'both':
            dataPackage.customerCommunicationPreferences = ['email', 'sms'];
            break;
        default:
            delete dataPackage.customerCommunicationPreferences;
    }

    setApptDataPackage(dataPackage);

    if (
        !dataPackage.vehicleYear ||
        !dataPackage.vehicleMake ||
        !dataPackage.vehicleModel ||
        !dataPackage.vehicleSubmodel
    ) {
        console.error(
            `appointment submission error: vehicle information missing or incomplete, {vehicleProfile: {year: ${dataPackage.vehicleYear}, make: ${dataPackage.vehicleMake}, model: ${dataPackage.vehicleModel}, submodel: ${dataPackage.vehicleSubmodel}}}`
        );
    }

    if (isValid) {
        confirmPost(rootEl, {
            dataPackage,
            submitButton,
            getAppointmentDetails,
            dateStoreStep,
            addedServicesListArr,
            getStoresInfo,
            getEventsAdded,
            setEventsAdded,
            getApptDataPackage
        });
    }
};

const contactDetail = (evt, rootEl) => {
    pubsub.publish('steps-next');
};

export { submitAppointment, contactDetail };
