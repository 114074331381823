/* global adobeDataLayer */
import Swiper from 'swiper/bundle';
import { component } from 'common/js/library/component';
import { isUserLoggedIn } from 'common/js/data/services/account/user';
import { pricingDetails } from 'common/js/data/services/ecomm/quote';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';

import '../scss/tires-near-you.scss';

let isLoggedIn = false;
/**
 * Initializes the Tires carousel component.
 * @param {HTMLElement} rootEl - The root element where the Tires carousel is initialized.
 */
const instanceInit = async (rootEl) => {
    console.log('Tires carousel init');
    isLoggedIn = await isUserLoggedIn();
    initSwiper(rootEl);
    observeVisibilityTiresNearYou(rootEl);
    initAnalytics(rootEl);
};

/**
 * Initializes the Swiper carousel with custom settings.
 * @param {HTMLElement} rootEl - The root element containing the Swiper carousel.
 */
const initSwiper = (rootEl) => {
    const carousel = rootEl.querySelector('.swiper');
    const carouselscrollBar = rootEl.querySelector('.swiper-scrollbar');
    const slidesDesktop = 4;
    const slidesTablet = 3;
    const slidesPhone = 1;

    new Swiper(carousel, {
        loop: false,
        spaceBetween: 20,
        breakpoints: {
            320: {
                slidesPerView: slidesPhone,
                slidesPerGroup: slidesPhone
            },
            768: {
                slidesPerView: slidesTablet,
                slidesPerGroup: slidesTablet
            },
            1024: {
                slidesPerView: slidesDesktop,
                slidesPerGroup: slidesDesktop
            }
        },
        scrollbar: {
            el: carouselscrollBar,
            draggable: true,
            hide: false
        }
    });
};

/**
 * Observes the visibility of the Tires carousel in the viewport and lazily initializes prices when visible.
 * @param {HTMLElement} rootEl - The root element containing the Tires carousel.
 */
const observeVisibilityTiresNearYou = (rootEl) => {
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                initPrices(rootEl);
                observer.unobserve(entry.target);
            }
        });
    });

    observer.observe(rootEl);
};

/**
 * Fetches and initializes product prices for the Tires carousel.
 * This function is executed lazily when the Tires carousel is visible in the viewport.
 * @param {HTMLElement} rootEl - The root element containing the pricing elements.
 */
const initPrices = async (rootEl) => {
    const pricingElements = rootEl.querySelectorAll('.tsr .tsr-card__pricing');

    if (document.querySelectorAll('.wrap-local-pages.store')[0] !== undefined) {
        let dataPackage = {};
        const storeNumber = document.querySelectorAll('.wrap-local-pages.store')[0].getAttribute('data-store-id');
        await Promise.all(
            Array.from(pricingElements).map(async (pricingElement) => {
                const tireBrand = pricingElement.getAttribute('data-brand');
                const tireName = pricingElement.getAttribute('data-tire-name');
                dataPackage.storeNumber = storeNumber;
                dataPackage.tireBrand = tireBrand;
                dataPackage.tireName = tireName;
                const data = await pricingDetails(dataPackage);
                let productPriceInfo = data?.data?.summary?.minPrice;

                // Guard clause to prevent null exception.
                if (!productPriceInfo || !productPriceInfo.price) return [];

                // Create the pricing data object.  Standard-fitment.
                const pricingData = {
                    price: productPriceInfo.price.value,
                    priceFormatted: productPriceInfo.price.formattedValue,
                    promo: JSON.stringify(productPriceInfo.potentialPromotions),
                    rebate: JSON.stringify(productPriceInfo.rebates),
                    showPrice: false,
                    isAsLowAs: true
                };

                // Set the pricing data attributes on the individual pricing element.
                Object.assign(pricingElement.dataset, pricingData);

                // Set the article id, on each .tsr-card element
                const tsrCard = pricingElement.closest('.tsr-card');
                tsrCard.setAttribute('data-article', productPriceInfo.code);
            })
        );

        // Publish the ProductPrice initialization event.
        pubsub.publish(topics.CREATE_PRODUCTPRICE, [pricingElements, isLoggedIn]);
    }
};

const initAnalytics = (rootEl) => {
    const buttons = rootEl.querySelectorAll('.tsr-card-content .tsr-card__cta');
    buttons.forEach((button) => {
        button.addEventListener('click', function () {
            const tsrCard = button.closest('.tsr-card');
            const linkText = button.textContent.trim();
            const articleId = tsrCard.dataset.article;
            const dataLayerEvent1 = {
                event: 'cta_click',
                cta_type: 'tire_fit',
                cta_text: linkText
            };
            if (articleId) {
                dataLayerEvent1.products = [{ productID: articleId }];
            }

            adobeDataLayer.push(dataLayerEvent1);

            // For the second event to the datalayer
            const averageRatingElement = tsrCard?.querySelector('.average-rating')
                ? tsrCard?.querySelector('.average-rating').textContent.trim()
                : null;
            const reviewCountElement = tsrCard?.querySelector('.review-count a')
                ? tsrCard?.querySelector('.review-count a').textContent.trim().replace(/[()]/g, '')
                : null;

            const dataLayerEvent = {
                event: 'tires.detail.click'
            };

            if (articleId) {
                dataLayerEvent.products = [{ productID: articleId }];
            }

            if (averageRatingElement) {
                dataLayerEvent.scoreNumber = averageRatingElement;
            }

            if (reviewCountElement) {
                dataLayerEvent.numberOfReviews = reviewCountElement;
            }
            adobeDataLayer.push(dataLayerEvent);
        });
    });

    // For the events of the review count click:
    document.addEventListener('click', function (event) {
        const reviewsButton = event.target.closest(
            '.tires-near-you .tsr-card-content .tsr-card__reviews .reviews-v2 .reviews-inline-rating .review-count a'
        );

        if (reviewsButton) {
            const tsrCard = reviewsButton.closest('.tsr-card');
            const articleId = tsrCard ? tsrCard.dataset.article : null;
            const averageRatingElement = tsrCard ? tsrCard.querySelector('.average-rating')?.textContent.trim() : null;
            const reviewCountElement = tsrCard
                ? tsrCard.querySelector('.review-count a')?.textContent.trim().replace(/[()]/g, '')
                : null;

            const dataLayerEvent = {
                event: 'tires.detail.click'
            };

            if (articleId) {
                dataLayerEvent.products = [{ productID: articleId }];
            }

            if (averageRatingElement) {
                dataLayerEvent.scoreNumber = averageRatingElement;
            }

            if (reviewCountElement) {
                dataLayerEvent.numberOfReviews = reviewCountElement;
            }
            adobeDataLayer.push(dataLayerEvent);
        }
    });

    const seeMoreTiresCta = rootEl.querySelector('.tsr__see-more-tires-cta');
    seeMoreTiresCta.addEventListener('click', function () {
        adobeDataLayer.push({
            event: 'cta_click',
            cta_type: 'tire_results',
            cta_text: 'see more tires'
        });
    });
};

// Registers the TiresCarousel component
component({
    selector: '.tsr',
    instanceInit,
    name: 'TiresCarousel'
});
