/**
 * Generates a Scene7 query string based on the provided options.
 *
 * This function maps the keys of the input `options` object to Scene7-specific query parameters
 * and constructs a query string. If no options are provided, an empty string is returned.
 *
 * @param {Object} [options] - The options for constructing the Scene7 query string.
 * @param {number} [options.width] - The width of the image (mapped to 'wid').
 * @param {number} [options.height] - The height of the image (mapped to 'hei').
 * @param {string} [options.format] - The format of the image (mapped to 'fmt').
 * @param {string} [options.backgroundColor] - The background color (mapped to 'bgColor').
 * @param {number} [options.scale] - The scale factor (mapped to 'scl').
 * @param {string} [options.crop] - The crop coordinates (mapped to 'cropN').
 * @returns {string} The generated Scene7 query string.
 *
 * @example
 * // Basic usage
 * const queryString = scene7Params({ width: 100, height: 200, format: 'jpeg' });
 * console.log(queryString); // Output: '?wid=100&hei=200&fmt=jpeg'
 */

const scene7Params = (options) => {
    if (!options) {
        return '';
    }

    const scene7Properites = ['wid', 'hei', 'fmt', 'bgColor', 'scl', 'cropN'];
    const scene7ProperitesMap = {
        width: 'wid',
        height: 'hei',
        format: 'fmt',
        backgroundColor: 'bgColor',
        scale: 'scl',
        crop: 'cropN'
    };
    let final = '';

    Object.entries(options).forEach(([key, value], index) => {
        let property = scene7ProperitesMap[key];
        if (property) {
            final += `${index === 0 ? '?' : '&'}${property}=${options[key]}`;
        }
    });

    return final;
};

export { scene7Params };
