import '../scss/tabs.scss';
import { component } from 'common/js/library/component';

const instanceInit = (rootEl) => {
    console.log('inside tabs.js');

    const rolloverAnchors = rootEl.querySelectorAll(
        ':not([data-tabfunc=false])[data-rollover=true] .tab-nav li a:first-child'
    );
    const tabLinks = rootEl.querySelectorAll('a.tab-link');
    const anchors = rootEl.querySelectorAll('.tab-nav > ul > li > a:first-child');
    const mTabs = rootEl.querySelectorAll('.tab-content .m-tab');
    const toolbars = rootEl.querySelectorAll('[data-type="toolbar"]');
    const relatedServicesOptionAnchors = rootEl.querySelectorAll('.related-services .service-option a');

    componentInit({ rolloverAnchors, tabLinks, anchors, mTabs, toolbars, relatedServicesOptionAnchors });
};

const componentInit = (props) => {
    const { rolloverAnchors, tabLinks, anchors, mTabs, toolbars, relatedServicesOptionAnchors } = props;

    [...rolloverAnchors].forEach((rolloverAnchor) => {
        rolloverAnchor.addEventListener('mouseover', (event) => {
            event.preventDefault();

            let tabParent = event.currentTarget.closest('.tabs');
            let tabNav = tabParent.querySelector('.tab-nav');
            let tabContent = tabParent.querySelector('.tab-content');
            const tabSelect = event.currentTarget.parentElement;
            let idx = 0;
            [...tabSelect.closest('ul').querySelectorAll('li')].forEach((liEl, index) => {
                if (tabSelect == liEl) {
                    idx = index;
                }
            });

            if (tabParent.dataset.type == 'toolbar') {
                tabNav.classList.add('active');
            }

            [...event.currentTarget.parentElement.children].forEach((children) => {
                children.classList.remove('active');
            });

            event.currentTarget.classList.add('active');

            if (tabContent.length > 1) {
                tabContent.classList.remove('active');
                tabContent[idx].classList.add('active');
            }

            if (tabContent.classList.contains('hide')) {
                tabContent.classList.add('active');
            }

            [...tabContent.children].forEach((children, index) => {
                if (index == idx) {
                    children.classList.add('active');
                } else {
                    children.classList.remove('active');
                }
            });
        });
    });

    // Opening tabs on anchor
    [...tabLinks].forEach((tabLink) => {
        tabLink.addEventListner('click', (event) => {
            event.preventDefault();

            let hrefVal = event.currentTarget.href;
            let hrefArr = hrefVal.split('#');
            let tabNo = parseInt(hrefArr[1]) >= 0 ? parseInt(hrefArr[1]) : 0;
            let tabParent = event.currentTarget.closest('.tabs');
            let tabNav = tabParent?.querySelector('.tab-nav');
            let tabNavChildren = tabNav.children;
            let tabContent = tabParent?.querySelector('.tab-content').children;
            let tabLength = tabNavChildren.length;

            // Validating with tab length
            if (tabLength > tabNo) {
                [...tabNavChildren].forEach((children) => {
                    children.classList.remove('active');
                });

                if (tabContent.length > 1) {
                    tabContent.classList.remove('active');
                    tabContent[tabNo].classList.add('active');
                }

                if (tabContent.classList.contains('hide')) {
                    tabContent.classList.add('active');
                }

                [...tabNavChildren].forEach((children, index) => {
                    if (index == tabNo) {
                        children.classList.add('active');
                    } else {
                        children.classList.remove('active');
                    }
                });
            }
        });
    });

    [...anchors].forEach((anchor) => {
        anchor.addEventListener('click', (event) => {
            let tabParent = event.currentTarget.closest('.tabs');

            event.stopPropagation();

            if (tabParent.dataset.tabfunc) {
                event.preventDefault();
            }

            const tabSelect = event.currentTarget.parentElement;
            let idx = 0;
            [...tabSelect.closest('ul').querySelectorAll('li')].forEach((liEl, index) => {
                if (tabSelect == liEl) {
                    idx = index;
                }
            });
            const tabNav = tabParent?.querySelector('.tab-nav');
            const tabContent = tabParent?.querySelector('.tab-content');

            let tabContentH3s;
            let tabContentSections;
            let tabContentSectionCurrent;

            if (tabContent) {
                tabContentH3s = [...tabContent.children].filter((child) => {
                    return child.nodeName == 'H3';
                });

                tabContentSections = [...tabContent.children].filter((child) => {
                    return child.nodeName == 'SECTION';
                });
                tabContentSectionCurrent = tabContentSections[idx];
            }
            const mobileNav = tabParent?.querySelector('.mobile-tab-nav');

            if (!tabSelect.classList.contains('active')) {
                [...tabSelect.parentNode.children].forEach((child) => {
                    child.classList.remove('active');
                });

                tabSelect.classList.add('active');
            }

            if (tabParent.dataset.type == 'toolbar') {
                tabNav.classList.add('active');
            }

            if (tabContent) {
                [...tabContentSections].forEach((tabContentSection) => {
                    tabContentSection.classList.remove('active');
                });

                tabContentSectionCurrent.classList.add('active');

                if (tabContentH3s.length) {
                    [...tabContentH3s].forEach((tabContentH3) => {
                        tabContentH3.classList.remove('active');
                    });

                    tabContentSectionCurrent.classList.add('active');
                    tabContentSectionCurrent.previousElement &&
                        tabContentSectionCurrent.previousElement.classList.add('active');
                }
            }

            if (mobileNav && mobileNav.style.display == 'block') {
                tabNav.toggle();
            }
        });
    });

    [...mTabs].forEach((mTab) => {
        mTab.addEventListener('click', (event) => {
            event.preventDefault();
            const target = event.currentTarget;
            const tabParent = target.closest('.tabs');
            const tabNavTabs = tabParent.querySelectorAll('.tab-nav ul li');
            const tabSelect = event.currentTarget;
            let idx = 0;
            [...target.parentElement.children].forEach((liEl, index) => {
                if (tabSelect == liEl) {
                    idx = index / 2;
                }
            });

            if (target.classList.contains('active')) {
                target.nextElement.classList.remove('active');
                target.classList.remove('active');
            } else {
                [...tabNavTabs].forEach((tabNavTab) => {
                    tabNavTab.classList.remove('active');
                });

                tabNavTabs[idx].classList.add('active');

                [...target.parentElement.children].forEach((child) => {
                    child.classList.remove('active');
                });

                target.classList.add('active');
                target.nextElement && target.nextElement.classList.add('active');
            }
        });
    });

    [...toolbars].forEach((toolbar) => {
        toolbar.addEventListener('mouseleave', (event) => {
            let tabNav = event.currentTarget.querySelector('.tab-nav');
            let tabContent = event.currentTarget.querySelector('.tab-content');

            if (tabNav.classList.contains('active')) {
                tabNav.classList.remove('active');
                tabNav.querySelector('li.active').classList.remove('active');
            }

            if (tabContent.classList.contains('active')) {
                tabContent.classList.remove('active');
            }
        });
    });

    //
    // For related services icons in tire catalog tab-content
    // borrowed from services-carousel.js
    //

    [...relatedServicesOptionAnchors].forEach((relatedServicesOptionAnchor) => {
        relatedServicesOptionAnchor.addEventListener('mouseover', (evt) => {
            const img = evt.currentTarget.querySelector('img');
            const src = img.getAttribute('src').replace('.png', '_hover.png');
            img.getAttribute('src', src);
        });

        relatedServicesOptionAnchor.addEventListener('mouseout', (evt) => {
            const img = evt.currentTarget.querySelector('img');
            const src = img.getAttribute('src').replace('_hover.png', '.png');
            img.getAttribute('src', src);
        });
    });
};

component({
    selector: '.tabs',
    instanceInit,
    name: 'Tabs'
});
