import { getCookie, removeCookie } from 'common/js/library/util';
import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { responseError, HTTPHeaders } from 'common/js/data/services/serviceUtilities';
import { store } from 'common/js/data/redux/store/exportedStore';

export const addToCart = async (dataPackage, guid) => {
    try {
        const cartGuid = guid ? guid : store.getState().ecomm.quote.cartGuid;
        const url = `/bsro/services/cart/create/${cartGuid}`;
        const headers = HTTPHeaders(['token']);

        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataPackage),
            headers
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }
        // This is being handled in cart.jsx useEffect for cart guid now
        // else if (
        //     responseJson.status === '200' &&
        //     responseJson.data &&
        //     responseJson.data.cart &&
        //     responseJson.data.cart.guid
        // ) {
        //     localStorage.setItem('guid', responseJson.data.cart.guid);
        // }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getCart = async (dataPackage) => {
    try {
        const lsCart = getLocalStorageItem('cart');
        const cartGuid = dataPackage || (lsCart && lsCart.guid) || '';
        const headers = HTTPHeaders(['token']);

        if (!cartGuid) return;

        const url = `/bsro/services/cart/get/${cartGuid}`;
        const response = await fetch(url, {
            method: 'GET',
            headers
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const removeCart = async (guid = null) => {
    try {
        if (!guid) {
            const cookieCart = getCookie('fcac-AEM-cart') || null;
            const lsCart = getLocalStorageItem('cart');
            guid = cookieCart || lsCart?.guid || '';
            if (!guid) return;
        }

        const url = `/bsro/services/cart/remove/${guid}`;

        const headers = HTTPHeaders(['token']);

        const response = await fetch(url, {
            method: 'POST',
            headers
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        window.localStorage.removeItem('cart');
        removeCookie('apAppointmentId');
        removeCookie('fcac-AEM-cart');

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const setQuantity = async (dataPackage) => {
    try {
        const lsCart = getLocalStorageItem('cart');
        const cartGuid = (lsCart && lsCart.guid) || '';
        const headers = HTTPHeaders(['token']);
        if (!cartGuid) return;

        const url = `/bsro/services/cart/item/update/${cartGuid}`;
        const response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(dataPackage),
            headers
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        removeCookie('apAppointmentId');
        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getAddOns = async (dataPackage) => {
    try {
        const lsCart = getLocalStorageItem('cart');
        const cartGuid = dataPackage || (lsCart && lsCart.guid) || '';
        const headers = HTTPHeaders(['token']);
        if (!cartGuid) return;

        const url = `/bsro/services/cart/addons/get/${cartGuid}`;
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const addAddOn = async (dataPackage) => {
    try {
        const lsCart = getLocalStorageItem('cart');
        const cartGuid = (lsCart && lsCart.guid) || '';
        const headers = HTTPHeaders(['token']);
        if (!cartGuid) return;

        const url = `/bsro/services/cart/addons/add/${cartGuid}`;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataPackage),
            headers
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        removeCookie('apAppointmentId');
        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const removeAddOn = async (dataPackage) => {
    try {
        const lsCart = getLocalStorageItem('cart');
        const cartGuid = (lsCart && lsCart.guid) || '';
        const headers = HTTPHeaders(['token']);
        if (!cartGuid) return;

        const url = `/bsro/services/cart/addons/remove/${cartGuid}`;
        const response = await fetch(url, {
            method: 'DELETE',
            body: JSON.stringify(dataPackage),
            headers
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        removeCookie('apAppointmentId');
        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};
