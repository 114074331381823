import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const chatBot = (adobeDataLayer) => {
    // Chatbot variables from chatbot.html
    const chatbotAppId = window.sprChatbotVars?.chatbotAppId;
    const chatbotVars = window.sprChatbotVars;
    const siteName = window.siteName;

    let chatbotContext = {};
    // initialize chatbot context with empty values
    Object.entries(chatbotVars)
        .filter(([, value]) => /^_c_[\da-f]{24}$/.test(value)) // only the custom variables
        .forEach(([, value]) => {
            chatbotContext[value] = '';
        });

    // fetches store data from local storage as default, updates chatbot context with any overrides via newChatbotContext
    const getChatbotContext = (chatbotContext, chatbotVars, newChatbotContext = {}) => {
        let storeDataLS = localStorage.getItem('storeData');
        if (storeDataLS) {
            const storeData = JSON.parse(storeDataLS)?.data;
            chatbotContext = Object.keys(chatbotVars).reduce((context, key) => {
                // This is where we map data like 'storeNumber' to the chatbot variable '_c_669a516537389d34c871bbe1'
                context[chatbotVars[key]] = storeData?.[key] || '';
                return context;
            }, {});
        }
        if (newChatbotContext) {
            chatbotContext = {
                ...chatbotContext,
                ...newChatbotContext
            };
        }

        return chatbotContext;
    };
    const updateChatbotContext = (newChatbotContext) => {
        if (newChatbotContext) {
            chatbotContext = {
                ...chatbotContext,
                ...newChatbotContext
            };
        }

        // updates the current conversation context
        window.sprChat('updateConversationContext', {
            context: chatbotContext
        });

        // updates all future conversations
        window.sprChat('updateClientContext', chatbotContext);
        window.sprChatSettings.clientContext = chatbotContext;

        return chatbotContext;
    };

    // initialize sprinklr chatbot code
    const loadChatBot = () => {
        chatbotContext = getChatbotContext(chatbotContext, chatbotVars);

        window.sprChatSettings = window.sprChatSettings || {};
        window.sprChatSettings = {
            appId: chatbotAppId,
            skin: 'MODERN',
            scope: 'CONVERSATION',
            landingScreen: 'LAST_CONVERSATION',
            clientContext: chatbotContext
        };

        (function () {
            var t = window,
                e = t.sprChat,
                a = e && !!e.loaded,
                n = document,
                r = function () {
                    r.m(arguments);
                };
            (r.q = []),
                (r.m = function (t) {
                    r.q.push(t);
                }),
                (t.sprChat = a ? e : r);
            var o = function () {
                var e = n.createElement('script');
                (e.type = 'text/javascript'),
                    (e.async = !0),
                    (e.src =
                        'https://prod-live-chat.sprinklr.com/api/livechat/handshake/widget/' + t.sprChatSettings.appId);

                (e.onerror = function () {
                    t.sprChat.loaded = !1;
                }),
                    (e.onload = function () {
                        t.sprChat.loaded = !0;
                    });
                var a = n.getElementsByTagName('script')[0];
                a.parentNode.insertBefore(e, a);
            };
            'function' == typeof e
                ? a
                    ? e('update', t.sprChatSettings)
                    : o()
                : 'loading' !== n.readyState
                  ? o()
                  : n.addEventListener('DOMContentLoaded', o);
        })();

        // open chatbot if an existing conversation is found
        const IS_CONVERSATION_OPEN_FILTER_ID = siteName + '-conversation-status-filter';
        const IS_CONVERSATION_OPEN_ID = siteName + '-conversation-status-variable';
        const IS_CONVERSATION_OPEN_FILTER = {
            id: IS_CONVERSATION_OPEN_FILTER_ID, //unique and mandatory string
            filters: {
                filterType: 'OR',
                filters: [
                    {
                        field: 'IS_CONVERSATION_OPEN',
                        filterType: 'IN',
                        values: [true],
                        id: IS_CONVERSATION_OPEN_ID //mandatory unique id that can be used to identify the filter, can be anything as long as it 's unique across all the given filters.
                    }
                ]
            }
        };

        const handleFilterResults = ({ data, error }) => {
            const {
                id,
                filters,
                results: { passed, values }
            } = data;
            // true only when open conversation exists
            if (passed) {
                updateChatbotContext(chatbotContext);
                window.sprChat('open');
            } else {
                window.sprChat('removeFilterToEvaluate', {
                    id: IS_CONVERSATION_OPEN_FILTER_ID
                });
            }
        };

        window.sprChat('addFilterToEvaluate', IS_CONVERSATION_OPEN_FILTER, handleFilterResults);
    };

    const addToV1Header = () => {
        // Add chatbot link to the header
        const chatbotHeaderLink = document.createElement('a');
        const chatbotLinkIcon = document.createElement('img');
        const phoneIcon = document.createElement('img');

        chatbotHeaderLink.setAttribute('href', '#');
        chatbotHeaderLink.classList.add('chatbot-header-link');
        chatbotHeaderLink.innerText = 'Book an appointment via chat';

        document
            .getElementById('tab-my-store-container')
            ?.getElementsByTagName('H3')[0]
            ?.classList.add('phone-with-chatbot');

        chatbotHeaderLink.addEventListener('click', function (event) {
            event.preventDefault();
            setAdobeDataLayer({
                event: 'cta_click',
                cta_type: 'chat',
                cta_text: 'book an appointment',
                cta_location: 'primary navigation'
            });
            chatbotContext = getChatbotContext(chatbotContext, chatbotVars);
            updateChatbotContext(chatbotContext);
            window.sprChat('open');
        });
        document
            .getElementById('tab-my-store-container')
            ?.getElementsByClassName('sub-nav-content')[0]
            ?.classList.add('has-chatbot-link');
        document
            .getElementById('tab-my-store-container')
            ?.getElementsByClassName('details')[0]
            ?.prepend(chatbotHeaderLink);
    };

    const addToMyStore = () => {
        // Add to My Store on Header V2 chatbot entry
        const chatbotHeaderV2Tag = document.getElementsByClassName('headerV2-chatbot__link')[0];
        if (chatbotHeaderV2Tag) {
            chatbotHeaderV2Tag.onclick = function (event) {
                event.preventDefault();
                setAdobeDataLayer({
                    event: 'cta_click',
                    cta_type: 'chat',
                    cta_text: 'book an appointment',
                    cta_location: 'primary navigation'
                });
                chatbotContext = getChatbotContext(chatbotContext, chatbotVars);
                updateChatbotContext(chatbotContext);
                window.sprChat('open');
            };
            document
                .getElementById('my-store-app')
                ?.getElementsByClassName('search-block')[0]
                ?.appendChild(chatbotHeaderV2Tag);
        }
    };

    // create chatbot entry points
    const loadChatBotTriggers = () => {
        if (document.body?.classList?.contains('has-local-store')) {
            addToV1Header();
        } else {
            // Add mutation observer to add chatbot link to the header after header changes
            const headerV1Container = document.getElementById('tab-my-store-container');

            const HeaderObserver = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    if (mutation.addedNodes.length) {
                        addToV1Header();
                    }
                });
                HeaderObserver.disconnect();
            });
            HeaderObserver.observe(headerV1Container, {
                childList: true
            });
        }

        // remove existing contact Us chatbot link event listeners
        const contactUsChatbotLink = document.getElementById('spr-contact-chat');
        const newContactUsChatbotLink = contactUsChatbotLink?.cloneNode(true);
        contactUsChatbotLink?.parentNode?.replaceChild(newContactUsChatbotLink, contactUsChatbotLink);

        // Local & contact pages chatbot link functionality
        const chatbotPageLinks = document.getElementsByClassName('spr-open-chat');
        if (chatbotPageLinks.length) {
            for (let i = 0; i < chatbotPageLinks.length; i++) {
                chatbotPageLinks[i]?.addEventListener('click', function (event) {
                    event.preventDefault();
                    const pathname = window.location.pathname;
                    const pathLocation = pathname.split('/')?.[1];
                    const ctaLocation = pathLocation === 'contact' ? 'primary body' : 'hero';

                    let localChatbotContext = {};
                    if (this.dataset.storeNumber) {
                        localChatbotContext = {
                            [chatbotVars.storeNumber]: this?.dataset?.storeNumber || '',
                            [chatbotVars.city]: this?.dataset?.city || '',
                            [chatbotVars.state]: this?.dataset?.state || '',
                            [chatbotVars.address]: this?.dataset?.address || '',
                            [chatbotVars.zip]: this?.dataset?.zip || ''
                        };
                    }
                    chatbotContext = updateChatbotContext(localChatbotContext);

                    setAdobeDataLayer({
                        event: 'cta_click',
                        cta_type: 'chat',
                        cta_text: this.innerText,
                        cta_location: ctaLocation
                    });

                    window.sprChat('open');
                });
            }
        }

        if (window.featureSwitches?.is('bsro-ui-global-nav', 'v2')) {
            const chatbotHeaderV2Tag = document.getElementsByClassName('headerV2-chatbot__link')[0];
            if (chatbotHeaderV2Tag) {
                addToMyStore();
            } else {
                const myStoreContainer = document.getElementById('my-store-app');
                const myStoreObserver = new MutationObserver(function (mutations) {
                    mutations.forEach(function (mutation) {
                        if (mutation.addedNodes.length) {
                            addToMyStore();
                        }
                    });
                    myStoreObserver.disconnect();
                });
                myStoreObserver.observe(myStoreContainer, {
                    childList: true
                });
            }
        } else {
            return;
        }
    };

    // load chatbot on page load, if feature switch is enabled
    window.addEventListener('DOMContentLoaded', function () {
        if (window.featureSwitches?.is('bsro-ui-chatbot', 'v1')) {
            loadChatBot();
            loadChatBotTriggers();
        }
    });
};

export { chatBot };
