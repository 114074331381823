import '../scss/product-detail.scss';
import Handlebars from 'common/js/library/handlebar';
import {
    getCookie,
    setCookie,
    isArray,
    isEmpty,
    serializeForm,
    validateElement,
    getUrlParameter
} from 'common/js/library/util';
import { QuoteServiceFactory } from 'common/js/library/quote-service-factory';
import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
import { responseError, queryStringify, HTTPHeaders } from 'common/js/data/services/serviceUtilities';

var quoteService = new QuoteServiceFactory();
let emailModal;
let gHidePrice;
let $carousel;
let onErrorImage = document.querySelectorAll('.product-detail-photos img');

const init = (config) => {
    emailModal = document.querySelector('#email-quote-modal');
    gHidePrice = false;
    $carousel = $('.carousel');
};

const instanceInit = (rootEl) => {
    let $el = $(rootEl);

    function carouselSlide() {
        if ($(window).width() <= 767) {
            let slidesPhone = Number($carousel.attr('data-slides-phone')) || 1;
            let carouselSettings = {
                arrows: true,
                dots: true,
                infinite: false,
                slidesToShow: slidesPhone,
                slidesToScroll: slidesPhone
            };
            $carousel.not('.slick-initialized').slick(carouselSettings);
        } else {
            if ($carousel) {
                //  $carousel.slick('unslick');
            }
        }
    }

    $('.tire-quote-btn').on('click', function (evt) {
        evt.preventDefault();

        var elem = $(this);
        var tire_brand = elem.data('catitem-tirebrand');
        var tire_name = elem.data('catitem-tirename');
        var product_name = tire_brand + ' ' + tire_name;

        var elParent = elem.parents('.product-detail-price-block');

        setCookie('bsro.ui.catitem.full', product_name);
        setCookie('bsro.ui.catitem.brand', tire_brand);
        setCookie('bsro.ui.catitem.name', tire_name);

        var _articleNumber = elem.attr('data-article');

        if (!_articleNumber) {
            $('#get-vehicle-modal').show();
            return;
        }

        quoteService.setCreateQuoteDataByEvt(evt).createQuote();
        elParent.addClass('get-quote');
    });

    function popupPosition() {
        var device_size = $(window).width();
        var pos_container_width;
        if (device_size <= 1100) {
            pos_container_width =
                $('.product-detail-tread-info .rating-image').width() +
                $('.product-detail-tread-info .wrap').width() +
                10;
            $('.product-detail-tread-info .accordion .folder').css({
                left: pos_container_width + 'px'
            });
        } else {
            pos_container_width = $('.product-detail-tread-info .wrap').width() + 60;
            $('.product-detail-tread-info .accordion .folder').css({
                left: pos_container_width + 'px'
            });
        }
    }

    $(window).resize(function () {
        carouselSlide();
        popupPosition();
    });

    $('.thumbs .thumb img').on('click', changeImage);

    $(window).on('load', (evt) => componentInit(evt, $el));
    carouselSlide();
    popupPosition();

    pubsub.subscribe(quoteService.CREATE_QUOTE_COMPLETE, createQuoteComplete);
};

const changeImage = (evt, data) => {
    var $sel = $(evt.currentTarget);

    var $img = $sel.attr('data-image');
    if ($img && !$img.startsWith('https://')) {
        $img = 'https://s7d1.scene7.com/is/image/' + $img.replace(/^\//, '');
    }
    var $opt = $sel.closest('.thumbs');
    var $tireLarge = $opt.parent().find('div .photo.active img');
    $tireLarge.attr('src', $img);
};

const componentInit = (evt, $el) => {
    var _articleId = $el.data('article-id') || null;
    var _price = null;
    var _referrerArr = window.document.referrer.split('/');
    var _referrer = _referrerArr.length ? _referrerArr.pop() : null;

    let gArticleId = _articleId;
    let gTireSize = $('.tire-size').text().trim() || 'NA';

    if (!isEmpty(_referrer)) {
        var _referStr = _referrer.toString().replace(/(.html)/g, '');
        if (_referStr === 'search-results') {
            $el.find('.tire-quote-btn').attr('data-type', 'vehicle');
        }
    }

    if (_articleId) {
        _price = parseFloat(getCookie(_articleId.toString()));

        if (_price == 0 || _price.toString() == 'NaN') {
            _price = null;
        }
    }

    if (window.isEcom) {
        const isEcomPricing = window.siteProfile.vehicles.tce.ymm !== '';
        if (isEcomPricing) {
            // get just pricing
            getPricing(_articleId, gArticleId, gTireSize);

            // get just inventory
            getInventory(_articleId, gArticleId);
        }
    } else if (_price == -1) {
        $('.product-detail-price-block').html('<h3>Please call your local store for pricing</h3>');
    } else if (_articleId && _price) {
        setTirePricing(_price);
    }

    initProductModalEmail();

    var AvgRatingInterval = setInterval(function () {
        var avgReviewEl = $('.product-detail-v2 .average-rating');
        var ratingEl = $('.product-detail-v2 .review-count>a');
        setSeoStructuredData(avgReviewEl, ratingEl);
        clearInterval(AvgRatingInterval);
    }, 500);
};

const createQuoteComplete = (data) => {
    var _quoteId = data.tireQuoteId;

    if (_quoteId != undefined && _quoteId != null) {
        var _path = '/tires/tire-quote/' + _quoteId;

        if (window.location.port) {
            _path = '/content/bsro/' + window.AEM.siteName + '/en/tires/tire-quote.html?quoteId=' + _quoteId;
        }

        window.location.href = _path;
    }
};

const setTirePricing = (gArticleId, gTireSize, price, allArticleIds, high) => {
    var _price = null;
    var $priceDetail = $('.product-detail-price-block');

    if (allArticleIds) {
        price = high ? price.high : price.low;
        _price = price.price ? price.price.toString() : '0';

        if (window.isEcom) {
            window._satellite.setVar('ecomData', {
                event: {
                    eventName: 'product.view'
                },
                product: {
                    item: [
                        {
                            productID: price.articleId ? price.articleId.toString() : null,
                            size: price.size ? price.size.toString() : null
                        }
                    ]
                }
            });
        }
    } else {
        _price = price ? price.toString() : '0';

        window._satellite.setVar('ecomData', {
            event: {
                eventName: 'product.view'
            },
            product: {
                item: [
                    {
                        productID: gArticleId ? gArticleId.toString() : null,
                        size: gTireSize ? gTireSize.toString() : null
                    }
                ]
            }
        });
    }

    window._satellite.track('ecommerce');

    var _priceNumArray, _dollars, _cents;

    if (parseInt(_price) != 0) {
        _priceNumArray = _price.split('.');
        _dollars = _priceNumArray[0];
        _cents = _priceNumArray[1];

        $('.dollars').text(_dollars);
        $('.cents').text(_cents);

        var _totalPrice = parseFloat(_price) * 4;
        _totalPrice = _totalPrice.toFixed(2);
        var _totalPriceNumArray = _totalPrice.toString().split('.');
        var _totalPriceDollars = _totalPriceNumArray[0];
        var _totalPriceCents = _totalPriceNumArray[1];

        $('.totalPriceDollars').text(_totalPriceDollars);
        $('.totalPriceCents').html('<sup>' + _totalPriceCents + '</sup>');

        if ($priceDetail.hasClass('ecom')) {
            $priceDetail.addClass('with-price');
            if (allArticleIds && !high) {
                $priceDetail.addClass('low');
            }

            if (gHidePrice) {
                $priceDetail.find('.price,.total,.coupon-cta').remove();
                $priceDetail.addClass('with-hidden-price');
            }
        }
    } else {
        if ($priceDetail.hasClass('ecom')) {
            $priceDetail.addClass('without-price');
        }
    }
};

const setSeoStructuredData = (avgReviewEl, ratingEl) => {
    window.SeoStructuredData = window.SeoStructuredData || {};

    window.SeoStructuredData.productSchema = Object.assign(window.SeoStructuredData.productSchema || {}, {
        tirePrice: '',
        url: '',
        ratingValue: '',
        ratingCount: '',
        init: function () {
            this.tirePrice = this.getTirePrice();
            this.url = window.location.href;
            this.ratingValue = this.getAvgRatingValue();
            this.ratingCount = this.getAvgRatingCount();
        },
        getTirePrice: function () {
            var priceContainer = $(".quote-content .price>div:contains('$')");
            if (!priceContainer.length) {
                priceContainer = $(".quote-order-summary .heading-row>div:contains('$')");
                if (!priceContainer.length) {
                    priceContainer = $(".quote-content>div>div:contains('$')");
                }
            }
            if (priceContainer.length > 0) {
                var price = priceContainer.get(0).innerText;
                price = price.replace(/(\$|\/|\b(?:ea|tire)\b)/g, '');
                return price;
            } else {
                return false;
            }
        },
        getAvgRatingValue: function () {
            avgReviewEl = avgReviewEl.length ? avgReviewEl : $('.ratings-and-reviews .average-rating');
            var avgReviewCount = avgReviewEl.length > 0 && avgReviewEl.get(0).innerText;
            return avgReviewCount ? avgReviewCount.replace(/[^0-9.]/g, '') : '';
        },
        getAvgRatingCount: function () {
            ratingEl = ratingEl.length ? ratingEl : $('.ratings-and-reviews .ratings-summary .title');
            var ratingStr = ratingEl.length > 0 && ratingEl.get(0).innerText;
            return ratingStr ? ratingStr.replace(/\D/g, '') : '';
        },
        addSeoScript: function () {
            this.init();
            var structuredDataText = {
                '@context': 'https://schema.org/',
                '@type': 'Product',
                name: this.name,
                image: this.image,
                description: this.description,
                brand: this.brand,
                sku: '',
                offers: {
                    '@type': 'Offer',
                    url: this.url,
                    priceCurrency: 'USD',
                    price: this.tirePrice,
                    availability: '',
                    itemCondition: ''
                }
            };

            if (this.ratingValue && this.ratingCount) {
                structuredDataText.aggregateRating = {
                    '@type': 'AggregateRating',
                    ratingValue: this.ratingValue,
                    bestRating: '5',
                    worstRating: '1',
                    ratingCount: this.ratingCount
                };
            }
            const script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.textContent = JSON.stringify(structuredDataText, null, 2);
            document.head.appendChild(script);
        }
    });

    if (window.SeoStructuredData.productSchema.addSeoScript) {
        try {
            window?.SeoStructuredData?.productSchema?.addSeoScript();
        } catch (error) {
            console.error('Error executing addSeoScript:', error);
        }
    } else {
        console.error('SeoStructuredData or productSchema is not initialized properly.');
    }
};

const getPricing = (articleId, gArticleId, gTireSize) => {
    var _data = {
        storeNumber: window.siteProfile.location.storeNumber,
        articleIDs: articleId.toString()
    };

    $.getJSON('/bsro/services/store/pricing-details', _data).done(function (data) {
        var _pData = data.data.products;
        var _pricing = _pData && _pData[0];
        var _highlow = null;
        var _promotions = _pricing && _pricing.potentialPromotions && _pricing.potentialPromotions[0];
        var _rebates = _pricing && _pricing.rebates && _pricing.rebates[0];

        gHidePrice = (_promotions && _promotions.hidePrice) || false;

        if (_pData.length) {
            if (_pData.length > 1) {
                _highlow = getPricingHighLow(_pData);
            }

            if (_pricing && _highlow == null) {
                var _price = _pricing.price && _pricing.price.value;
                var _salePrice = (_promotions && _promotions.salePrice.value) || 0;

                if (_salePrice) {
                    _price = _salePrice;
                }

                setTirePricing(gArticleId, gTireSize, _price);
            } else {
                setTirePricing(gArticleId, gTireSize, _highlow, true);
            }

            if (_promotions || _rebates) {
                var _isRebate = _rebates ? true : false;

                setTirePromotion(_promotions || _rebates, _isRebate);
            }
        }
    });
};

const getInventoryPricing = (articleId) => {
    var _data = {
        storeNumber: window.siteProfile.location.storeNumber,
        articleIDs: articleId
    };

    $.getJSON('/bsro/services/store/inventory-pricing-details', _data).done(function (data) {
        var _pData = data.data.pricingdata;
        var _pricing = _pData && _pData[0];
        var _highlow = null;

        if (_pData.length) {
            if (_pData.length > 1) {
                _highlow = getPricingHighLow(_pData);
            }

            if (_pricing && _highlow == null) {
                var _price = _pricing.price && _pricing.price.value;
                var _promotions = _pricing.potentialPromotions && _pricing.potentialPromotions[0];
                var _salePrice = (_promotions && _promotions.salePrice.value) || 0;

                //console.log("_price:", _price);
                //console.log("_salePrice:", _salePrice);

                if (_salePrice) {
                    _price = _salePrice;
                }

                setTirePricing(_price);
            } else {
                setTirePricing(_highlow, true);
            }
        }
    });
};

/* taken from ProductResults but it looks like
getInventoryPricing below might suffice */

const getInventory = (articleIds, gArticleId) => {
    var _articleIds = articleIds || gArticleId;
    var _data = {
        storeNumber: window.siteProfile.location.storeNumber,
        articleIDs: _articleIds.toString()
    };

    $.getJSON('/bsro/services/store/inventory', _data)
        .done(function (data) {
            // console.log("setInventory() inventory response:", data);
            var _inventory = (data.data && data.data.tires) || data.data;
            var hasZip = Boolean(window.siteProfile.location.myZip);

            // console.log("Inventory: ", _inventory);
            if (_inventory && hasZip) {
                var _i = 0;
                var _ilen = _inventory.length;
                var $quoteBtn = $('.tire-quote-btn');

                for (_i; _i < _ilen; _i++) {
                    var _iData = _inventory[_i];
                    var _quantity = parseInt(_iData.quantity);
                    var _articleId = parseInt(_iData.part_number);
                    var _storeQuantity = parseInt(_iData.q_local);
                    // var _sisterStoreQuantity = parseInt(_iData.q_sister);

                    if (!isArray(_articleIds) && _articleIds == _articleId) {
                        $quoteBtn.attr('data-inventory-id', _iData.id);
                        $quoteBtn.attr('data-inventory-quantity', _quantity);

                        var $storeInfo = $('a.store-number').clone();
                        var _store = $('.store-info span strong').text().trim();

                        $storeInfo.text(_store);

                        // If there is a query string with the store number, we need a different inventory experience
                        const hasStoreQueryString = Boolean(getUrlParameter('store') || '');

                        if (hasStoreQueryString) {
                            if (_quantity >= 3) {
                                $('span.in-stock').append('In stock at ');
                                $('.product-detail-stock').addClass('in-stock');
                            } else if (_quantity > 0) {
                                $('span.in-stock').append('Limited availability at ');
                                $('.product-detail-stock').addClass('in-stock');
                            } else {
                                $('span.in-stock').append('Out of stock at ');
                            }
                        } else {
                            if (_storeQuantity >= 2) {
                                $('span.in-stock').append('In stock at ');
                                $('.product-detail-stock').addClass('in-stock');
                            } else if (_quantity >= 2) {
                                $('span.in-stock').append('Usually available same day at ');
                                $('.product-detail-stock').addClass('in-stock');
                            } else {
                                $('span.in-stock').append('Call for availability at ');
                            }
                        }

                        $('span.in-stock').append($storeInfo);
                        $('.product-detail-stock').show();

                        break;
                    }
                }
            } else {
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("+++ INVENTORY DATA RETURNED NULL +++", _data)
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("++++++++++++++++++++++++++++++++++++")
            }
        })
        .fail(function (data) {
            //console.log("++++++++++++++++++++++++++++++++++++")
            //console.log("++++++++++++++++++++++++++++++++++++")
            //console.log("+++++++ INVENTORY CALL FAILED ++++++", _data)
            //console.log("++++++++++++++++++++++++++++++++++++")
            //console.log("++++++++++++++++++++++++++++++++++++")
        });

    if (
        window.siteProfile &&
        window.siteProfile.vehicles.tce.ymm != '' &&
        window.siteProfile.vehicles.tce.ymm != undefined &&
        window.siteProfile.vehicles.tce.trim != undefined
    ) {
        $.getJSON(
            '/bsro/services/tires/search-by-vehicle?ymm=' +
                window.siteProfile.vehicles.tce.ymm +
                '&trim=' +
                window.siteProfile.vehicles.tce.trim
        )
            .done(function (data) {
                //console.log("vehicle fitment data:", data);
                const _allArticles = data.data && data.data.allArticles;
                const _fitment = data.data && data.data.vehicleFitment;

                if (_fitment) {
                    let _veh = _fitment.year + ' ' + _fitment.make + ' ' + _fitment.model + ' ' + _fitment.submodel;

                    if (_allArticles.indexOf(gArticleId.toString()) != -1) {
                        //console.log("tire fits vehicle fitment");
                        _veh = 'This tire fits your ' + _veh;
                        $('.stock-vehicle-fitment').append(_veh);
                    } else {
                        if (gArticleId.length > 1) {
                            $('.stock-vehicle-fitment').hide();
                        } else {
                            _veh = "This tire doesn't fit your " + _veh;
                            $('.stock-vehicle-fitment').append(_veh);
                        }
                    }
                }
            })
            .fail(function (_data) {
                console.error('VECHILE FITMENT CHECK FAILED', _data);
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("++  VECHILE FITMENT CHECK FAILED  ++", _data)
                //console.log("++++++++++++++++++++++++++++++++++++")
                //console.log("++++++++++++++++++++++++++++++++++++")
            });
    }
};

const getPricingHighLow = (pData, highlow) => {
    var _articlePrices = [];
    var _highlow = { high: null, low: null };

    for (var i = 0; i < pData.length; i++) {
        var p = pData[i];
        var _price = p.price && p.price.value;
        var _articleId = p.code;
        var _nameItems = p['name'] ? p.name.split(' ') : [];
        var _articlePriceObj = { articleId: null, price: null, size: null };

        if (p.potentialPromotions && p.potentialPromotions.length) {
            _price = p.potentialPromotions[0].salePrice.value;
        }

        _nameItems.sort(function (a, b) {
            if (a.indexOf('/') != -1) {
                return -1;
            }

            if (b.indexOf('/') != -1) {
                return 1;
            }

            return 0;
        });

        _articlePriceObj.articleId = _articleId;
        _articlePriceObj.size = _nameItems[0];
        _articlePriceObj.price = _price ? _price.toFixed(2) : null;

        _articlePrices.push(_articlePriceObj);
    }

    _articlePrices.sort(function (a, b) {
        return a.price - b.price;
    });

    var _high = _articlePrices.pop();
    var _low = _articlePrices[0];

    _highlow.high = _high;
    _highlow.low = _low;

    return _highlow;
};

const setTirePromotion = (promotion, isRebate) => {
    if (promotion) {
        var $couponRebate = $('.coupon-rebate');
        var $couponRebateLink = $('.coupon-rebate-link');

        var _couponRebate = promotion;
        var _couponRebateLink = $couponRebateLink.attr('href') + '/' + _couponRebate.code + '/';

        $couponRebate.find('span').prepend(_couponRebate.displayName || _couponRebate.label);
        $couponRebateLink.attr('href', _couponRebateLink);

        if (isRebate) {
            $couponRebateLink.text('Rebate');
        }

        $couponRebate.parent().show();
    }
};

const initProductModalEmail = () => {
    const emailModalContentWrapper = emailModal?.querySelector('.email-wrapper');
    const emailModalThankYouWrapper = emailModal?.querySelector('.thank-you-wrapper');

    const emailModalContentHandlebarsTmpl = document
        .querySelector('[data-email-quote-template]')
        .cloneNode(true).innerHTML;
    const emailModalTemplate = Handlebars.compile(emailModalContentHandlebarsTmpl);

    const props = { emailModalContentWrapper, emailModalThankYouWrapper, emailModalTemplate };

    $(emailModal).on('show hide', (evt) => onProductModalEmailShowHide(evt, props));

    if (window.quoteData) {
        setProductModalEmailQuoteDataContent(props);
    }
};

const initProductModalEmailForm = () => {
    const emailModalForm = emailModal?.querySelector('form');

    emailModalForm?.addEventListener('change', (evt) => handleProductModalEmailFormFieldChange(evt, emailModalForm));
    emailModalForm?.addEventListener('blur', (evt) => handleProductModalEmailFormFieldChange(evt, emailModalForm));
    emailModalForm?.addEventListener('keyup', (evt) => handleProductModalEmailFormFieldChange(evt, emailModalForm));

    emailModalForm?.addEventListener('submit', (evt) => submitProductModalEmailForm(evt, emailModalForm));

    const submitErrorElements = emailModalForm.querySelectorAll('.submitError, .emailError');
    submitErrorElements.forEach((element) => {
        element.textContent = '';
    });

    console.log('initProductModalEmailForm emailModalForm', emailModalForm);
};

const handleProductModalEmailFormFieldChange = (evt, emailModalForm) => {
    const firstName = emailModal?.querySelector('[name=firstName]').value;
    const lastName = emailModal?.querySelector('[name=lastName]').value;
    const email = emailModal?.querySelector('[name=emailAddress]').value;
    const submitError = emailModalForm.querySelector('.submitError');

    submitError.textContent = '';

    let disabled = true;
    if (firstName !== '' && lastName !== '' && email !== '') {
        disabled = false;
    }

    const sendEmailQuote = emailModal?.querySelector('.send-email-quote');
    if (sendEmailQuote) {
        sendEmailQuote.disabled = disabled;
    }

    console.log('handleProductModalEmailFormFieldChange', 'evt:', evt);
};

const onProductModalEmailShowHide = (evt, props) => {
    emailModal?.classList.remove('thank-you');

    if (evt.type == 'show') {
        setProductModalEmailQuoteDataContent(props);
    }
};

const submitProductModalEmailForm = async (evt, emailModalForm) => {
    evt.preventDefault();

    const _data = serializeForm(emailModalForm);
    _data.optin = _data.optin ? 'Yes' : 'No';

    const emailValidRes = validateElement(_data.emailAddress, 'email');

    if (emailValidRes) {
        console.log('submitProductModalEmailForm _data:', _data);

        const emailQuoteResponse = await fetchEmailQuoteData(_data);

        if (emailQuoteResponse && emailQuoteResponse.success === 'true') {
            emailModal.classList.add('thank-you');
            emailModalForm.querySelector('input').value = '';
        } else {
            emailModalForm.querySelector('.submitError').innerText =
                'Looks like we ran into an issue sending your quote. Please try again later.';
        }
    }
};

const fetchEmailQuoteData = async (paramData) => {
    const url = `/bsro/services/contact/emailquote${queryStringify(paramData)}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const setProductModalEmailQuoteDataContent = async (props) => {
    const { emailModalContentWrapper, emailModalThankYouWrapper, emailModalTemplate } = props;
    if (window.quoteData) {
        emailModalContentWrapper.innerHTML = emailModalTemplate(window.quoteData);

        initProductModalEmailForm(emailModalContentWrapper);

        const storeDetails = await fetchStoreDetails();

        if (storeDetails && storeDetails.data) {
            setProductModalEmailThankYouContent(storeDetails, emailModalThankYouWrapper);
        }
    }
};

const fetchStoreDetails = async () => {
    const url = `/bsro/services/store/details/${window.quoteData.storeNumber}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const setProductModalEmailThankYouContent = (data, emailModalThankYouWrapper) => {
    const _store = data && data.data;
    const _appointmentHREF =
        '/content/bsro/' +
        window.AEM.siteName +
        '/en/appointment/schedule-appointment.html?storeNumber=' +
        _store.storeNumber +
        '&src=tq';
    const _backResultsPath = document.querySelector('.ecomm-back-to-results')?.getAttribute('href') ?? null;
    let _storeStatus =
        _store.activeFlag == '1' && _store.onlineAppointmentActiveFlag == '1' ? 'activeStore' : 'callStore';

    emailModalThankYouWrapper.querySelector('.phoneNumber').textContent = _store?.phone
        .replace('(', '')
        .replace(') ', '.')
        .replace('-', '.');
    emailModalThankYouWrapper.querySelector('.back-btn').setAttribute('href', _backResultsPath);
    emailModalThankYouWrapper.querySelector('.schedule-btn').setAttribute('href', _appointmentHREF);

    if (_store.activeFlag == '0' && _store.storeType != 'FCAC') {
        _storeStatus = 'closedStore';
    }

    if (_store.storeType == 'TPL') {
        _storeStatus = 'callStore';
    }

    emailModalThankYouWrapper.classList.add(_storeStatus);

    console.log('setProductModalEmailThankYouContent');
};

const onErrorImages = (images) => {
    images.forEach((image) => {
        image.addEventListener('error', () => {
            image.parentNode.remove();
            console.info('Broken Image Error v1 - New', image.src);
        });
    });
};
onErrorImages(onErrorImage);

component({
    selector: '[data-product-detail]',
    init,
    instanceInit,
    name: 'ProductDetail'
});
