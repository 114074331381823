import '../scss/offers-signup.scss';
import { component } from 'common/js/library/component';
import { validateElement } from 'common/js/library/util';
import { emailSignUp } from 'common/js/data/services/emailSignUp';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const instanceInit = (el) => {
    // console.error('inside offers-signup.js');
    let offerSignupEl = el;
    let emailSignup = offerSignupEl?.querySelector('.offer-signup-btn, .offers-signup__btn');

    emailSignup.addEventListener('click', (evt) => {
        evt.preventDefault();
        let email = offerSignupEl.querySelector('input').value;
        window.localStorage.setItem('emailID', email);

        if (offerSignupEl.classList.contains('indy-signup')) {
            handleSubmit(evt, emailSignup, offerSignupEl);
        } else {
            if (window.AEM.siteName == 'hibdontire') {
                location.href = '/sign-up-for-email-offers';
            } else {
                location.href = '/email-signup';
            }
            let offerSignupForm = offerSignupEl?.querySelector('form');

            offerSignupForm &&
                offerSignupForm.addEventListener('submit', (evt) => {
                    evt.preventDefault();
                    emailSignup.click();
                });
        }
    });
};

const handleSubmit = (evt, signupBtn, rootEl) => {
    let data = getFormValues(rootEl);

    if (validate(data)) {
        let emailValidRes = validateElement(data.email, 'email');

        if (emailValidRes) {
            sendData(data, signupBtn, rootEl);
        }
    } else {
        console.error('invalid data');
    }
};

const getFormValues = (rootEl) => {
    return {
        firstName: 'indy500',
        lastName: 'indy500',
        email: window.localStorage.getItem('emailID'),
        confirmEmail: window.localStorage.getItem('emailID'),
        address: 'indy500',
        city: 'indy500',
        state: 'indy500',
        zip: 'indy500',
        optin: 'Yes'
    };
};

const validate = (data) => {
    if (
        data.firstName !== '' &&
        data.lastName !== '' &&
        data.email !== '' &&
        data.confirmEmail !== '' &&
        data.email === data.confirmEmail
    ) {
        return true;
    }

    return false;
};

const sendData = async (data, signupBtn, rootEl) => {
    signupBtn.disabled = true;

    try {
        const response = await emailSignUp(data);
        if (response.success === 'false') {
            signupBtn.disabled = false;
        } else {
            // console.error('Email Subscribed', data);
            let emailSignupEl = rootEl;
            let emailConfirmationEl = document.querySelector('.indy-confirmation');
            emailSignupEl.style.display = 'none';
            emailConfirmationEl.style.display = 'block';
            let edata = {
                event: 'email.signup',
                email: response.data.emailAddress,
                form_name: 'newsletter signup: indy car'
            };
            setAdobeDataLayer(edata);
        }
    } catch (err) {
        console.error(err);
    }
};

component({
    selector: '[data-offers-signup]',
    instanceInit,
    name: 'OffersSignup'
});
