import '../scss/find-store.scss';
import { queryStringify, responseError, HTTPHeaders } from 'common/js/data/services/serviceUtilities';
import { component } from 'common/js/library/component';

let storeNumberInputCtl;
const init = (config) => {
    storeNumberInputCtl = document.querySelector('input[name=storeNumber]')
};

const instanceInit = (rootEl) => {
    console.log('inside find-store.js');

    const selState = rootEl.querySelector('select[name=state]');
    const selCity = rootEl.querySelector('select[name=city]');
    const selStore = rootEl.querySelector('select[name=store-list]');

    selState.addEventListener('change', async (evt) => {
        selCity.querySelectorAll(':scope > option:not(:first-child)').forEach((e) => e.remove());
        selStore.querySelectorAll(':scope > option:not(:first-child)').forEach((e) => e.remove());

        const cityData = await getCity(selState.value);
        if (cityData && cityData.data) {
            [...cityData.data].forEach((val) => {
                const opt = document.createElement('option');
                opt.value = val;
                opt.textContent = val;
                selCity.appendChild(opt);
            });
        }

        selCity && (selCity.parentNode.parentNode.className = '');
    });

    selCity.addEventListener('change', async (evt) => {
        selStore.querySelectorAll(':scope > option:not(:first-child)').forEach((e) => e.remove());

        const storesData = await getStores(selCity.value, selState.value);
        if (storesData && storesData.data && storesData.data.stores) {
            [...storesData.data.stores].forEach((val) => {
                if (val.city == selCity.value) {
                    const opt = document.createElement('option');
                    opt.value = val.storeNumber;
                    opt.textContent = val.address;
                    selStore.appendChild(opt);
                }
            });
        }
    });

    selStore.addEventListener('change', (evt) => {
        const el = evt.currentTarget;
        storeNumberInputCtl && (storeNumberInputCtl.value = el.value);
    });
};

const getCity = async (state) => {
    const paramData = {
        state: state
    };

    const cityByStateServiceUrl = `/bsro/services/store/get-city${queryStringify(paramData)}`;

    try {
        const response = await fetch(cityByStateServiceUrl, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const getStores = async (city, state) => {
    const paramData = {
        state: state,
        city: city
    };

    const cityByStateServiceUrl = `/bsro/services/store/location/get-list-state-city${queryStringify(paramData)}`;

    try {
        const response = await fetch(cityByStateServiceUrl, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

component({
    init,
    instanceInit,
    name: 'FindStore',
    selector: '[data-find-store]'
});
