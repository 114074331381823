/**
 * Secondary Subnavs
 * @param {HTMLElement} container
 *
 * This module is responsible for handling the My Garage and My Store subnavs.
 * It will handle the keyboard navigation for the My Garage and My Store subnavs.
 *
 * When the My Garage is focused and the My Store is open, it will close the My Store.
 * When the My Store is focused and the My Garage is open, it will close the My Garage.
 * When the user presses the ESC key, it will close the My Garage or My Store.
 * When the user presses the ENTER key on the My Store, it will listen for the last element to tab off.
 * When the user tabs off of the last element in the My Store submenu, it will close the My Store.
 */

import { pubsub } from 'common/js/library/pubsub';

export const SecondarySubnavs = (container) => {
    const MY_GARAGE = '.my-garage__opener';
    const MY_STORE = '.my-store__opener';

    const myGarageAndMyStoreMenus = container?.querySelectorAll([MY_GARAGE, MY_STORE].join(', '));

    // Event handler for when the my garage or my store is focused.
    const onMyGarageOrMyStoreFocus = (evt) => {
        const target = evt.target;
        // If the my garage is focused and the my store is open, close the my store.
        if (target.matches(MY_GARAGE) && isMyStoreOpen()) {
            closeMyStore();
        }

        // If the my store is focused and the my garage is open, close the my garage.
        if (target.matches(MY_STORE) && isMyGarageOpen()) {
            closeMyGarage();
        }

        target.addEventListener('keydown', onMyGarageOrMyAStoreKeyPress);
        target.addEventListener('blur', onMyGarageOrMyStoreBlur);

        // Listen for clicks on the my store to close it when tabbing off of the last element.
        if (target.matches(MY_STORE)) {
            listenForLastMyStoreItemTabOff();
        }
    };

    // Event handler for when the my garage or my store is blurred.
    const onMyGarageOrMyStoreBlur = (evt) => {
        const target = evt.target;

        target.removeEventListener('keydown', onMyGarageOrMyAStoreKeyPress);
        target.removeEventListener('blur', onMyGarageOrMyStoreBlur);
    };

    // Event handler for when a key is pressed on the my garage or my store.
    // This will handle the ESC key to close the my garage or my store.
    // This will handle the tab key to close the my garage or my store when tabbing off of the last element.
    const onMyGarageOrMyAStoreKeyPress = (evt) => {
        const target = evt.target;

        // If the user presses the ESC key, close the my garage or my store.
        if (evt.key === 'Escape') {
            if (target.matches(MY_GARAGE) && isMyGarageOpen()) {
                closeMyGarage();
            }

            if (target.matches(MY_STORE) && isMyStoreOpen()) {
                closeMyStore();
            }
        }

        // If the user shift tags off of the my garage or my store, close it.
        if (evt.key === 'Tab' && evt.shiftKey) {
            if (target.matches(MY_GARAGE)) {
                closeMyGarage();
            }

            if (target.matches(MY_STORE)) {
                closeMyStore();
            }
        }

        // If the user presses the ENTER key on the my store to listen for the last element.
        if (evt.key === 'Enter' && target.matches(MY_STORE)) {
            listenForLastMyStoreItemTabOff();
        }
    };

    // Close the my garage.
    const closeMyGarage = () => {
        pubsub.publish('on-my-store-open');
    };

    // Close the my store.
    const closeMyStore = () => {
        pubsub.publish('on-my-garage-open');
    };

    // Check if the my garage is open.
    const isMyGarageOpen = () => {
        return Boolean(document.querySelector('.my-garage-content'));
    };

    // Check if the my store is open.
    const isMyStoreOpen = () => {
        return Boolean(document.querySelector('#tab-my-store-container-v2.is-active'));
    };

    // Listen for the last element in the my store submenu to tab off.
    const listenForLastMyStoreItemTabOff = () => {
        const myStoreLastLink = document.querySelector('.results-container .swiper-slide:last-child .cta button');
        if (myStoreLastLink) {
            myStoreLastLink.addEventListener('keydown', onMyStoreLastItemTabOff);
        }
    };

    // Event handler for when the user tabs off of the last element in the my store submenu.
    const onMyStoreLastItemTabOff = (evt) => {
        const target = evt.target;

        // If the user tabs off of the last element in the my store submenu, close the my store.
        if (evt.key === 'Tab' && !evt.shiftKey) {
            closeMyStore();
            target.removeEventListener('keydown', onMyStoreLastItemTabOff); // Stop listening for the tab key on the last element in the my-store submenu.
        }
    };

    // Initialize the secondary subnavs.
    myGarageAndMyStoreMenus?.forEach((element) => {
        element.addEventListener('focus', onMyGarageOrMyStoreFocus);
    });
};
