import { findStores } from './findStores';
import { toggleStoreSelection } from './toggleStoreSelection';
import { pubsub } from 'common/js/library/pubsub';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const onServicesSubmit = (evt, rootEl, props) => {
    const {
        getAppointmentDetails,
        addedServicesListArr,
        queryStoreData,
        findStoresFormZip,
        getRadius,
        queryStoreNumber,
        isLoggedIn,
        preferredStoreNumber,
        setDateSelectorIndex,
        dateStoreStep,
        numDays,
        datesArr,
        getStoresInfo,
        setStoresInfo,
        setAppointmentDetails,
        completeDateTimeSelection
    } = props;
    const { year, make, model, trim } = getAppointmentDetails().vehicle;
    const searchType = getAppointmentDetails().vehicle_search_type;
    evt.preventDefault();

    rootEl.querySelector('.accordion input[type=checkbox]').checked = false;

    // don't use autoZip because we don't want the default store zip used to show available stores.
    // the user should have to enter a zip or use geo-loc
    let zip = queryStoreData?.zip || window.siteProfile.location.myZip;

    if (zip) {
        rootEl.querySelector('input[name=zip]').value = zip;
        findStores(null, rootEl, {
            findStoresFormZip,
            getRadius,
            queryStoreNumber,
            isLoggedIn,
            preferredStoreNumber,
            setDateSelectorIndex,
            dateStoreStep,
            numDays,
            datesArr,
            addedServicesListArr,
            getStoresInfo,
            setStoresInfo,
            getAppointmentDetails,
            setAppointmentDetails,
            completeDateTimeSelection
        });
        toggleStoreSelection(true);
    } else {
        toggleStoreSelection(false);
    }

    let sdata = {};
    if (searchType) {
        sdata.vehicle = {
            year,
            make,
            model,
            trim
        };

        sdata.vehicle_search_type = searchType;
    }

    sdata.services = addedServicesListArr;
    sdata.event = 'sa.v2.service';

    if (searchType && (!year || !make || !model || !trim)) {
        console.error(
            `service selection completion error: vehicle information missing or incomplete, {vehicleProfile: {year: ${year}, make: ${make}, model: ${model}, trim: ${trim}}}`
        );
    }

    setAdobeDataLayer(sdata);

    // reset stores & dates page incase the page has already been visited. (i.e. back button)
    const pinnacleAvailabilityBtn = dateStoreStep.querySelector('.pinnacle-availability');
    const otherStoreContainer = dateStoreStep.querySelector('.otherStoresContainer');
    const otherStoresDates = dateStoreStep.querySelector('.other-stores.dates');
    const timeDuration = dateStoreStep.querySelector('.timeDuration');
    const storeDates = dateStoreStep.querySelector('.pinnacle.dates:not(.other-stores)');

    if (!pinnacleAvailabilityBtn?.classList?.contains('hide')) {
        pinnacleAvailabilityBtn.classList.add('hide');
    }
    if (!otherStoresDates?.classList?.contains('hide')) {
        otherStoresDates.classList.add('hide');
    }
    if (storeDates?.classList?.contains('hide')) {
        storeDates.classList.remove('hide');
    }
    otherStoreContainer.style.display = 'none';
    timeDuration.style.display = 'none';

    // end reset

    pubsub.publish('steps-next');
};

export { onServicesSubmit };
