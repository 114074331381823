import { checkIfStoreHoliday } from './checkIfStoreHoliday';

const findStoreNextBusinessDaySchedule = (storeInfo, weekday) => {
    const storeWorkHours = storeInfo.hours;
    if (!storeWorkHours) {
        return null;
    }

    let storeBusinessDaySchedule = null;
    const now = new Date();
    let storeHoursSchedule = null;
    let dayCount = now.getDate();
    let currentMonth = now.getMonth() + 1;
    let currentYear = now.getFullYear();
    let nextDay = new Date(); // meant to increment in the for loop.

    for (let weekDayIndex = 0; weekDayIndex < 7; weekDayIndex++) {
        nextDay.setDate(nextDay.getDate() + 1);
        dayCount = nextDay.getDate();
        currentMonth = nextDay.getMonth() + 1;
        currentYear = nextDay.getFullYear();

        const isNextDayHoliday = checkIfStoreHoliday(storeInfo, dayCount, currentMonth, currentYear);
        if (isNextDayHoliday) {
            continue;
        }
        const nextWeekDay = weekday[(now.getDay() + 1 + weekDayIndex) % 7];
        for (let storeWorkHoursIndex in storeWorkHours) {
            const storeWorkDay = storeWorkHours[storeWorkHoursIndex].weekDay;
            if (storeWorkDay == nextWeekDay) {
                storeHoursSchedule = storeWorkHours[storeWorkHoursIndex];
                break;
            }
        }

        if (storeHoursSchedule) {
            break;
        }
    }

    if (storeHoursSchedule) {
        storeBusinessDaySchedule = {
            day: dayCount,
            month: currentMonth,
            year: currentYear,
            storeHoursSchedule: storeHoursSchedule
        };
    }

    return storeBusinessDaySchedule;
};

export { findStoreNextBusinessDaySchedule };
