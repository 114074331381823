import { getCookie, isEmpty } from 'common/js/library/util';
import { getStoreByStoreNumber } from 'common/js/data/services/store-location';
import { pubsub } from 'common/js/library/pubsub';
import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

/**
 * Observes the URL for service seletions, store numbers, etc and makes auto selections
 * Also sets local storage values
 * @param {Element} rootEl - the instance root element
 * @returns
 */
const initUrlParamsReferrer = async (rootEl) => {
    let urlParams = {};
    let search = window.location.search.substring(1);

    if (search) {
        urlParams = window.JSON.parse(
            `{"${window.decodeURIComponent(
                window.decodeURIComponent(search.replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' '))
            )}"}`
        );
    }

    let param = {
        service: urlParams['maintenanceChoices'] || null,
        comment: urlParams['appointment.comments'] || null,
        quoteId: urlParams['appointment.tireQuoteId'] || null,
        fitment: {
            year: urlParams['vehicle.year'],
            make: urlParams['vehicle.make'],
            model: urlParams['vehicle.model'],
            submodel: urlParams['vehicle.submodel']
        }
    };
    let storedNotes = getLocalStorageItem('apptNotes');
    let service = null;
    let quoteId = null;
    let comments = rootEl.querySelector('[name=comments]');
    let storeNote = rootEl.querySelector('[name=store-notes]');
    let queryStoreNumber = urlParams['storeNumber'];
    let queryStoreData = {};

    const autoSelectService = (condition, selector, addNote = false, addQuoteId = false) => {
        if (condition) {
            rootEl.querySelector(selector).dispatchEvent(new Event('click'));

            if (addNote) {
                storeNote.value = storedNotes;
            }
            if (addQuoteId) {
                quoteId = getCookie('bsro.ui.quoteID');
            }
        }
    };

    const referrer = window.document.referrer.toString() + window.location.href.toString();
    const isBrake = referrer.indexOf('brake') != -1 || (service && !param.service && urlParams['src']) == 'brake';
    const isBattery = referrer.indexOf('battery') != -1 || referrer.indexOf('batteries') != -1;
    const isBatteryQuote = referrer.indexOf('battery-quote') != -1;
    const isAlignment = referrer.indexOf('alignment') != -1 && referrer.indexOf('alignment-quote') == -1;
    const isAlignmentQuote = referrer.indexOf('alignment-quote') != -1;
    const isTireRepair = referrer.indexOf('tire-repair') != -1;
    const isOil = referrer.indexOf('oil') != -1 || (service && !param.service && urlParams['src'] == 'oil');
    const isRotation = referrer.indexOf('rotation') != -1;
    const isTireQuote = referrer.indexOf('tire-quote') != -1 || referrer.indexOf('tq') != -1;
    const isTuneUp = referrer.indexOf('tune-up') != -1;
    const isAC = referrer.indexOf('air-conditioning') != -1 || referrer.indexOf('ac-service') != -1;
    const isWiperBlades = referrer.indexOf('wiper-blades') != -1;
    const isVehicleInspection =
        referrer.indexOf('vehicle-inspection') != -1 || window.location.href.indexOf('inspection') != -1;
    const isOther = referrer.indexOf('other') && urlParams['services'];

    autoSelectService(isBrake, "[data-add-service='Brakes']");
    autoSelectService(isBattery, "[data-add-service='Starting & Charging/Battery']", true);
    autoSelectService(isBatteryQuote, "[data-add-service='Starting & Charging/Battery']", true, true);
    autoSelectService(isAlignment, "[data-add-service='Wheel Alignment']");
    autoSelectService(isAlignmentQuote, "[data-add-service='Wheel Alignment']", true, true);
    autoSelectService(isTireRepair, "[data-add-service='Flat Repair']");
    autoSelectService(isOil, "[data-add-service='Oil & Filter Change']");
    autoSelectService(isRotation, "[data-add-service='Tire Rotation']");
    autoSelectService(isTireQuote, "[data-add-service='Tire Replacement']", true, true);
    autoSelectService(isTuneUp, "[data-add-service='Engine Tune-Up']");
    autoSelectService(isAC, "[data-add-service='Air Conditioning Service']");
    autoSelectService(isWiperBlades, "[data-add-service='Wiper Blades']");
    autoSelectService(isVehicleInspection, "[data-add-service='Complete Vehicle Inspection']");
    autoSelectService(isOther, "[data-add-service='Other']");

    if (isOther) {
        // using setTimeout to give enough time for input to be rendered so it can modified.
        setTimeout(() => {
            let otherInput = document.getElementById('other-service');
            let serviceArray = urlParams['services'].split(',');
            let otherDescriptions = '';
            // go through services, which should be in format ?services=service|description,service|description
            // and print "other" service descriptions to other input field.
            serviceArray.map((obj) => {
                if (obj.includes('other')) {
                    let objSplit = obj.split('|');
                    if (objSplit.length > 1) {
                        if (otherDescriptions) otherDescriptions += ', ';
                        otherDescriptions += objSplit[1];
                    }
                }
            });
            if (otherDescriptions) {
                otherInput.value = otherDescriptions;
            }
        }, 200);
    }

    if (param.comment) {
        param.comment = window.decodeURIComponent(param.comment).replace(/\+/g, ' ');
        comments.value = param.comment;
    }

    if (param.quoteId) {
        quoteId = param.quoteId;
    }

    if (!isEmpty(param.fitment)) {
        let tmpSiteProfile = window.isEcom ? window.siteProfile.vehicles.tce : window.siteProfile.vehicles.main;

        tmpSiteProfile.year = param.fitment.year;
        tmpSiteProfile.make = param.fitment.make;
        tmpSiteProfile.model = param.fitment.model;
        tmpSiteProfile.trim = param.fitment.submodel;
        tmpSiteProfile.ymm = `${param.fitment.year}-${param.fitment.make}-${param.fitment.model}`;

        // TODO: Set vehicle fitment on step one

        pubsub.publish('schedule_appointment_widget_reset', []);
    }

    // if store number found, get the store data to auto populate
    if (queryStoreNumber) {
        const response = await getStoreByStoreNumber(queryStoreNumber);
        queryStoreData = response?.data?.data || {};
    }

    // comments, storeNote were assigned back to the elements
    return {
        queryStoreNumber,
        queryStoreData,
        quoteId
    };
};

export { initUrlParamsReferrer };
