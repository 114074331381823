import offerSuccessTemplate from './tpl/offer-success.template';
import Modal from 'common/js/util/coral-modal/modal';
import { responseError, HTTPHeaders } from 'common/js/data/services/serviceUtilities';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { validateElement, getCookie } from 'common/js/library/util';
import { getEcid } from 'common/js/library/get-ecid';

const ShareOfferModal = (el) => {
    let rootEl = document.querySelector(el);
    let subEls = {
        shareOptions: rootEl.querySelectorAll('.offer-modal__share-option input[type="radio"]'),
        shareInputEmail: rootEl.querySelector('.offer-modal__share-input-email'),
        shareInputText: rootEl.querySelector('.offer-modal__share-input-text'),
        disclaimerEmail: rootEl.querySelector('.offer-modal__disclaimer--email'),
        disclaimerText: rootEl.querySelector('.offer-modal__disclaimer--text'),
        btnSend: rootEl.querySelector('.offer-modal__btn-send'),
        offerDesc: rootEl.querySelector('.offer-modal__body--description'),
        offerSuccess: rootEl.querySelector('.offer-modal__body--success'),
        phone: rootEl.querySelector('[name="text"]'),
        email: rootEl.querySelector('[name="email"]'),
        selectedOption: 'phone',
        phoneErrorEl: rootEl.querySelector('.offer-modal__input-error--phone'),
        phoneResponsError: rootEl.querySelector('.offer-modal__input-error--phone--response'),
        emailErrorEl: rootEl.querySelector('.offer-modal__input-error--email'),
        emailResponseError: rootEl.querySelector('.offer-modal__input-error--email--response'),
        friendlyId: rootEl.dataset.friendlyId,
        offerTitle: rootEl.dataset.offerTitle,
        waitContainer: rootEl.querySelector('.coral-Wait--container'),
        emailSignUp: rootEl.querySelector('#emailSignUp'),
        genericError: rootEl.querySelector('.offer-modal__input-error--generic--response')
    };

    subEls.shareOptions.forEach((elem) => {
        elem.addEventListener('click', (event) => {
            const target = event.target;
            subEls.selectedOption = toggleInputOption(target, subEls);
        });
    });

    offerShareInit(subEls);
};

const toggleInputOption = (target, props) => {
    const { shareInputEmail, disclaimerEmail, shareInputText, disclaimerText } = props;
    let selectedOption;
    if (target.id === 'shareOptionEmail') {
        shareInputEmail.classList.remove('hide');
        disclaimerEmail.classList.remove('hide');
        shareInputText.classList.add('hide');
        disclaimerText.classList.add('hide');
        selectedOption = 'email';
    } else if (target.id === 'shareOptionText') {
        shareInputText.classList.remove('hide');
        disclaimerText.classList.remove('hide');
        shareInputEmail.classList.add('hide');
        disclaimerEmail.classList.add('hide');
        selectedOption = 'phone';
    }
    return selectedOption;
};

const offerShareInit = (subEls) => {
    console.log('inside share-offer-modal.js');

    subEls.phone.addEventListener('input', (evt) => {
        let inputValue = '';
        let regex = /(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/;
        let targetValue = evt.target.value;
        let val = '';

        if (targetValue[0] == 1) {
            val = targetValue.replace(/\D/g, '').match(regex);
            inputValue = !val[2] ? val[1] : `${val[1]} ${val[2]}-${val[3]}` + (val[4] ? `-${val[4]}` : '');
        } else {
            val = evt.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            inputValue = !val[2] ? val[1] : `${val[1]}-${val[2]}` + (val[3] ? `-${val[3]}` : '');
        }

        evt.target.value = inputValue;
    });

    subEls.btnSend.addEventListener('click', (evt1) => {
        handleSubmit(evt1, subEls);
    });
};

const handleSubmit = async (evt, props) => {
    const vehicleCookie = getCookie('bsro.cp-fcac');
    const {
        email,
        phone,
        phoneResponsError,
        selectedOption,
        phoneErrorEl,
        waitContainer,
        friendlyId,
        offerTitle,
        emailErrorEl,
        emailSignUp,
        genericError
    } = props;
    let validEmail = validateElement(email.value, 'email');
    let emailValue = email.value;
    let phoneValue = phone.value.replace(/\D/g, '');
    let validPhone = validateElement(phoneValue, 'phoneNum');
    phoneResponsError.classList.add('hide');
    phoneResponsError.classList.remove('show');
    genericError.classList.remove('show');
    genericError.classList.add('hide');

    if (selectedOption === 'phone') {
        phoneErrorEl.classList.remove('show');
        phoneErrorEl.classList.add('hide');
        if (phoneValue !== '' && validPhone) {
            const postData = {
                phone: phoneValue,
                friendlyId: friendlyId
            };
            waitContainer.classList.add('show');
            waitContainer.classList.remove('hide');

            try {
                const url = '/bsro/services/sms/';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: HTTPHeaders(),
                    body: JSON.stringify(postData)
                });
                const responseJson = await response.json();

                if (!response.ok) {
                    throw Error(responseError(responseJson));
                }

                if (parseInt(response.status) === 200) {
                    new Modal({
                        modalId: friendlyId,
                        classNames: 'offer-modal offer-modal--success',
                        content: offerSuccessTemplate({ type: 'send', source: 'phone' })
                    });
                    phoneErrorEl.classList.add('hide');
                    phoneErrorEl.classList.remove('show');
                    setAdobeDataLayer({
                        event: 'offer.share_by_text',
                        offerID: friendlyId,
                        offerName: window.analytics_utils.getCouponData(friendlyId).thumbTitle,
                        telephone: phoneValue
                    });
                } else {
                    genericError.classList.remove('hide');
                    genericError.classList.add('show');
                }

                window.setTimeout(function () {
                    waitContainer.classList.remove('show');
                    waitContainer.classList.add('hide');
                }, 500);
            } catch (err) {
                genericError.classList.remove('hide');
                genericError.classList.add('show');

                window.setTimeout(function () {
                    waitContainer.classList.remove('show');
                    waitContainer.classList.add('hide');
                }, 500);
            }
        } else {
            phoneErrorEl.classList.add('show');
            phoneErrorEl.classList.remove('hide');
        }
    } else {
        if (validEmail) {
            const postData = {
                email: emailValue,
                friendlyId: friendlyId,
                optin: emailSignUp.checked == true ? 'Yes' : 'No',
                visitorId: getEcid()
            };
            waitContainer.classList.add('show');
            waitContainer.classList.remove('hide');

            try {
                const url = '/bsro/services/contact/email-coupons';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: HTTPHeaders(),
                    body: JSON.stringify(postData)
                });
                const responseJson = await response.json();

                if (!response.ok) {
                    throw Error(responseError(responseJson));
                }

                if (parseInt(response.status) === 201) {
                    new Modal({
                        modalId: friendlyId,
                        classNames: 'offer-modal offer-modal--success',
                        content: offerSuccessTemplate({ type: 'send', source: 'email' })
                    });
                    emailErrorEl.classList.add('hide');
                    emailErrorEl.classList.remove('show');
                    setAdobeDataLayer({
                        event: 'offer.share_by_email',
                        offerID: friendlyId,
                        offerName: window.analytics_utils.getCouponData(friendlyId).thumbTitle,
                        email: emailValue
                    });
                } else {
                    genericError.classList.remove('hide');
                    genericError.classList.add('show');
                }

                window.setTimeout(function () {
                    waitContainer.classList.remove('show');
                    waitContainer.classList.add('hide');
                }, 500);
            } catch (err) {
                genericError.classList.remove('hide');
                genericError.classList.add('show');

                window.setTimeout(function () {
                    waitContainer.classList.remove('show');
                    waitContainer.classList.add('hide');
                }, 500);
            }
        } else {
            emailErrorEl.classList.add('show');
            emailErrorEl.classList.remove('hide');
        }
    }
};

export default ShareOfferModal;
