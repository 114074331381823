import { getStoresByCityState } from './getStoresByCityState';
import { getStoresByZip } from './getStoresByZip';
import { getStoresData } from './getStoresData';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { toggleStoreSelection } from './toggleStoreSelection';

/**
 * Store Location form submit handler. Can also be called without the event when switching sections.
 * @param {Event} evt
 * @param {element} rootEl
 * @param {object} props
 */
const findStores = (evt, rootEl, props) => {
    const {
        findStoresFormZip,
        getRadius,
        queryStoreNumber,
        isLoggedIn,
        preferredStoreNumber,
        setDateSelectorIndex,
        dateStoreStep,
        numDays,
        datesArr,
        addedServicesListArr,
        getStoresInfo,
        setStoresInfo,
        getAppointmentDetails,
        setAppointmentDetails,
        completeDateTimeSelection
    } = props;
    let inputVal = findStoresFormZip.value;

    // For cases in which the Store Location form was submitted and triggered this function
    if (evt) {
        evt.preventDefault();
    }

    const radius = getRadius();
    let zip = getAppointmentDetails().zip;

    // is there an input value from the form? if not, don't process
    if (inputVal) {
        // is the input value a number? if so, process as a zip
        if (parseInt(inputVal)) {
            // if the existing zip doesn't match the input value and they're both not empty,
            // get stores by that zip
            if (zip != inputVal && inputVal != '') {
                zip = inputVal;
                setAppointmentDetails({ zip: inputVal });
                getStoresByZip(rootEl, {
                    zip,
                    getRadius,
                    queryStoreNumber,
                    isLoggedIn,
                    preferredStoreNumber,
                    setDateSelectorIndex,
                    dateStoreStep,
                    numDays,
                    datesArr,
                    addedServicesListArr,
                    getStoresInfo,
                    setStoresInfo,
                    completeDateTimeSelection
                });

                let sdata = {
                    zip,
                    radius,
                    event: 'sa.v2.findstores'
                };

                setAdobeDataLayer(sdata);
            } else {
                if (getStoresInfo().storesData?.length) {
                    getStoresData(rootEl, {
                        getRadius,
                        queryStoreNumber,
                        isLoggedIn,
                        preferredStoreNumber,
                        setDateSelectorIndex,
                        dateStoreStep,
                        numDays,
                        datesArr,
                        addedServicesListArr,
                        getStoresInfo,
                        setStoresInfo,
                        completeDateTimeSelection
                    });
                }
            }
        } else {
            // process as a city, state
            let tmp = inputVal.split(',');
            let city = '';
            let state = '';

            if (typeof tmp[1] != 'undefined') {
                city = tmp[0];
                state = tmp[1];
            } else {
                state = inputVal.match(/((new|north|south|west|rhode)? ?[a-z]*)$/gi)[0].trim();
                let rx = new RegExp(',? ' + state); // _data.state
                city = inputVal.replace(rx, '');
            }

            setAppointmentDetails({ city, state });

            getStoresByCityState(rootEl, {
                city,
                state,
                getRadius,
                queryStoreNumber,
                isLoggedIn,
                preferredStoreNumber,
                setDateSelectorIndex,
                dateStoreStep,
                numDays,
                datesArr,
                addedServicesListArr,
                getStoresInfo,
                setStoresInfo
            });
        }
    }

    // only show if we have a zip or city,state value in the form
    const { city, state } = getAppointmentDetails();
    toggleStoreSelection(Boolean(zip || (city && state)));
};

export { findStores };
