import '../scss/accordion.scss';
import { getStoreHoursTableHtml } from 'common/js/util/date-time/getStoreHoursTableHtml';
import { storeToMapUrl } from 'common/js/util/storeToMapURL';
import { component } from 'common/js/library/component';

const instanceInit = (rootEl) => {
    [...rootEl.querySelectorAll('.folder')].forEach((folder) => {
        folder.addEventListener('click', handleEvent);
    });
    [...rootEl.querySelectorAll('.accordion-locations__see-more')].forEach((more) => {
        more.addEventListener('click', handleEvent);
    });
    // Initialize the observer for the .local-seo-page .seo-accordion element
    initViewportObserver(rootEl);
    updatePhoneNumber();
};

/**
 * Event handler for all event listeners.
 * @memberof Accordion
 * @private
 * @evt {event} event to be handled
 * @return {void}
 */
const handleEvent = (evt) => {
    switch (evt.type) {
        case 'click':
            if (evt.currentTarget.classList.contains('accordion-locations__see-more')) {
                evt.preventDefault();
                onMoreClick(evt.currentTarget);
            } else {
                onFolderClick(evt.currentTarget);
            }
            break;
        default:
            throw TypeError('Accordion._handleEvent: Event type not recognized.');
    }
};

/**
 * Handles folder click
 * @memberof Accordion
 * @private
 * @param {vanilla} folder
 * @return {void}
 */

const onFolderClick = (folder) => {
    const item = folder.nextElementSibling;
    const siblings = Array.from(folder.parentNode.children).filter((el) => el !== folder && el !== item);

    siblings.forEach((sibling) => {
        sibling.classList.remove('active');
    });

    folder.classList.toggle('active');
    item.scrollIntoView({ top: item.offsetTop, behavior: 'smooth' });
    item.classList.toggle('active');
    const itemSecond = document.querySelector('.accordion.second .item');
    itemSecond?.classList?.toggle('active');
};

/**
 * Handles See More Locations click
 * @memberof Accordion
 * @private
 * @param {vanilla} more
 * @return {void}
 */

let currentVisibleCount = 4;
const onMoreClick = (more) => {
    const locations = document.querySelectorAll('.local-seo-page .accordion .item .accordion-locations__location');
    const nextVisibleCount = currentVisibleCount + 4;

    for (let i = currentVisibleCount; i < nextVisibleCount && i < locations.length; i++) {
        locations[i].style.display = 'block';
    }

    currentVisibleCount += 4;

    // Optionally, you can hide the "more" button if all locations are visible
    if (currentVisibleCount >= locations.length) {
        more.style.display = 'none';
    }
};

/**
 * Initializes the Intersection Observer for .local-seo-page .seo-accordion
 * @memberof Accordion
 * @private
 * @param {vanilla} rootEl
 * @return {void}
 */
const initViewportObserver = (rootEl) => {
    const seoAccordion = rootEl.querySelector('.accordion-locations');
    if (seoAccordion) {
        const city = seoAccordion.getAttribute('data-city');
        const state = seoAccordion.getAttribute('data-state');
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Check if data-stores-info is already set
                    if (!seoAccordion.getAttribute('data-stores-info')) {
                        fetchStoreLocations(city, state)
                            .then((response) => {
                                seoAccordion.setAttribute('data-stores-info', true);
                                response.data.stores.forEach((store) => {
                                    const storeElement = rootEl.querySelector(
                                        `.accordion-locations__location[data-store="${store.storeNumber}"]`
                                    );
                                    if (storeElement) {
                                        let formattedHours = getStoreHoursTableHtml(store);
                                        const hoursElement = storeElement.querySelector('.accordion-locations__hours');
                                        hoursElement.innerHTML = formattedHours;
                                    }
                                });
                                renderAddressUrl();
                            })
                            .catch((error) => {
                                console.error('Error fetching store locations:', error);
                            });
                    }
                    // Stop observing after the request check
                    observer.unobserve(seoAccordion);
                }
            });
        });

        observer.observe(seoAccordion);
    }
};

const fetchStoreLocations = async (city, state) => {
    const url = `/bsro/services/store/location/get-list-state-city?state=${state}&city=${encodeURIComponent(city)}`;
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
    }
    return response.json();
};

function updatePhoneNumber() {
    let phoneInfos = document.querySelectorAll('.accordion-locations__phone'); // Use querySelectorAll to select all phone elements

    phoneInfos.forEach((phoneInfo) => {
        let phoneNumber = phoneInfo.textContent;
        let cleanedPhoneNumber = phoneNumber.replace(/[()\s-]/g, '');
        phoneInfo.setAttribute('href', `tel:${cleanedPhoneNumber}`);
    });
}

function renderAddressUrl() {
    let addressInfos = document.querySelectorAll('.accordion-locations__address');

    addressInfos.forEach((addressInfo) => {
        let store = {
            address: addressInfo.getAttribute('data-address'),
            state: addressInfo.getAttribute('data-state'),
            city: addressInfo.getAttribute('data-city')
        };
        let addressUrl = storeToMapUrl(store);
        addressInfo.setAttribute('href', addressUrl);
    });
}

component({
    selector: '.accordion',
    instanceInit,
    name: 'Accordion'
});
