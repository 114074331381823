import { queryStringify, responseError, HTTPHeaders } from '../serviceUtilities';
import { store } from '../../redux/store/exportedStore';
import { getCache, setCache } from 'common/js/util/sessionCache';
import { getCookie, setCookie } from 'common/js/library/util';
import { timestampToCustomDateTime } from 'common/js/util/date-time/timestampToCustomDateTime';
import { getEcid } from 'common/js/library/get-ecid';

export const getServiceAssignments = async (dataPackage) => {
    try {
        const url = `/bsro/services/appointment/get-service-assignments${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getAvailableDays = async (dataPackage) => {
    try {
        const url = `/bsro/services/appointment/get-available-days${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

/**
 * Function to check and reserve appointments by sending a POST request to the specified endpoint.
 *
 * @param {Object} payload - The payload containing data for the appointment request.
 * @param {string} basePath - Optional. The base path for the API endpoint. Default is an empty string.
 * @returns {Promise<Object>} A promise that resolves with the response JSON or rejects with an error object.
 */

export const checkAndReserve = async (payload, basePath = '') => {
    try {
        payload.date = payload.date.replace(/[^ -~]/g, '');
        const url = `${basePath}/bsro/services/appointment/check-and-reserve`;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: HTTPHeaders(['pinnacle'])
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

/**
 * Returns the service time duration for the specified data package.
 * Sample dataPackage
 *  {
 *     storeNumber: 123,
 *     serviceIds: [123, 456]
 *  }
 */
export const getServiceTimeDuration = async (dataPackage, basePath = '') => {
    try {
        const url = `${basePath}/bsro/services/store/services/duration?${queryStringify(dataPackage)}`;

        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders(['pinnacle'])
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getAvailableTimes = async (dataPackage) => {
    try {
        const url = `/bsro/services/appointment/get-available-times${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getAvailabilityForStore = async (dataPackage, basePath = '') => {
    try {
        dataPackage.startDate = dataPackage.startDate.replace(/[^ -~]/g, '');
        console.log('DATA PACKAGE: ', dataPackage);
        const url = `${basePath}/bsro/services/appointment/get-availability-for-store${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders(['pinnacle'])
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const getAvailabilityForMultistores = async (dataPackage) => {
    try {
        const url = `/bsro/services/appointment/get-availability-for-multistores${queryStringify(dataPackage)}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders(['pinnacle'])
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const confirm = async (dataPackage, basePath = '') => {
    const rwg = 'rwg_token';
    const rwgCookie = getCookie(rwg);
    if (rwgCookie) dataPackage[rwg] = rwgCookie;

    try {
        const url = `${basePath}/bsro/services/appointment/confirm`;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataPackage),
            headers: HTTPHeaders(['pinnacle'])
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

// export const confirm = async (dataPackage) => {
//     try {
//         const url = `/bsro/services/appointment/confirm${queryStringify(dataPackage)}`;
//         const response = await fetch(url, {
//             method: 'GET',
//             headers: { 'Content-Type': 'application/json' }
//         });
//         const responseJson = await response.json();

//         if (!response.ok) {
//             throw Error(responseError(responseJson));
//         }

//         return responseJson;
//     } catch (err) {
//         return { error: err, success: 'false' };
//     }
// };

export const eConfirm = async (dataPackage) => {
    const state = store.getState().ecomm;
    const { cart, checkout, quote, vehicleFitment, addOns } = { ...state };
    const entries = cart.entries || [];
    const tiresSrc = entries.filter((entry) => (entry.product && entry.product.brgtProductType) === 'TIRE');

    let comments = '';

    function formatPhoneNumber(phoneNumber) {
        // Use a regular expression to remove the parentheses
        return phoneNumber.replace(/\(|\)/g, '').replace(' ', '-');
    }

    if (checkout.appointment.dropWaitOption === 'curbside') {
        comments += 'CURBSIDE SERVICE REQUESTED. ';
    }

    comments += `tire quote id:  ${cart.brgtQuoteId}`;

    if (tiresSrc[0] && tiresSrc[0].product && tiresSrc[0].basePrice) {
        comments += `,  tire article number: ${tiresSrc[0].product.code},  tire description: ${tiresSrc[0].product.name},  tire size: ${tiresSrc[0].product.brgtTireSizeLabel},  tire quantity: ${tiresSrc[0].quantity},  tire price: ${tiresSrc[0].basePrice.formattedValue}`;
    }

    if (tiresSrc[1] && tiresSrc[1].product && tiresSrc[1].basePrice) {
        comments += `,  rear tire article number: ${tiresSrc[1].product.code},  rear tire description: ${tiresSrc[1].product.name},  rear tire size: ${tiresSrc[1].product.brgtTireSizeLabel},  rear tire quantity: ${tiresSrc[1].quantity},  rear tire price: ${tiresSrc[1].basePrice.formattedValue}`;
    }

    comments += ',  tire quote End;';

    let preCounter = 0;

    entries.map((item, i) => {
        const product = item.product || {};
        const code = product.code || '';
        const name = product.name.toLowerCase() || '';
        const price = (item.totalPrice && item.totalPrice.formattedValue.toLowerCase()) || '';

        console.log('addOns', addOns);

        if (addOns && Array.isArray(addOns.products)) {
            addOns.products.map((addOn, ii) => {
                if (addOn.code === code) {
                    const pre = preCounter === 0 ? '  ' : ',  ';
                    preCounter++;
                    comments += `${pre}${name}: ${price}`;
                }
            });
        }
    });

    if (checkout.appointment && checkout.appointment.pickupPreference) {
        const pre = preCounter === 0 ? '  ' : ',  ';
        comments += `${pre}pickup preference: ${checkout.appointment.pickupPreference}`;
        preCounter++;
    }

    // verify that, if apAppointment cookie exists, that the current appointment matches
    const storeNumber = cart.brgtStore.lwsStoreId;
    const datetime = timestampToCustomDateTime(checkout.appointment.appointmentDate); //checkout.appointment.dateTime;
    const apptCookieData = getCookie('apAppointmentId');
    const apptCookie = apptCookieData ? apptCookieData : {};
    let verify = 'false';

    if (apptCookie.storeNumber === storeNumber && apptCookie.datetime === datetime && apptCookie.appointmentId) {
        verify = 'true';
    }

    dataPackage = dataPackage
        ? dataPackage
        : {
              storeNumber,
              locationId: checkout.service.locationId,
              employeeId: checkout.service.employeeId,
              appointmentStatusId: '4111',
              appointmentStatusDesc: 'eCommerce Scheduled',
              vehicleYear: vehicleFitment.year || quote.vehicleFitment.year || window.siteProfile.vehicles.tce.year,
              vehicleMake: vehicleFitment.make || quote.vehicleFitment.make || window.siteProfile.vehicles.tce.make,
              vehicleModel: vehicleFitment.model || quote.vehicleFitment.model || window.siteProfile.vehicles.tce.model,
              vehicleSubmodel:
                  vehicleFitment.submodel || quote.vehicleFitment.submodel || window.siteProfile.vehicles.tce.trim,
              mileage: '',
              customerNotes: '',
              comments,
              customerFirstName: checkout.billing.firstName,
              customerLastName: checkout.billing.lastName,
              customerCity: checkout.billing.city,
              customerDayTimePhone: formatPhoneNumber(checkout.billing.mobile),
              customerEmailAddress: checkout.billing.email,
              websiteName: window.siteProfile.site || cart.site || 'FCAC',
              appointmentType: 'New',
              quoteId: cart.brgtQuoteId || '',
              choice: {
                  appointmentChoiceId: '1',
                  choice: '1',
                  datetime,
                  dropWaitOption: checkout.appointment.dropWaitOption,
                  appointmentId: '0',
                  pickUpTime: checkout.appointment.appointmentTime,
                  dropOffTime: checkout.appointment.appointmentTime
              },
              selectedServices: checkout.service.serviceId,
              visitorID: getEcid(),
              optin: 'No',
              verify,
              salt: Date.now()
          };
    const url = '/bsro/services/appointment/e-confirm';
    const eCommRefNumber = cart.code;
    const payload = { ...dataPackage, eCommRefNumber };
    console.log('eConfirm payload:', payload);

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: HTTPHeaders()
        });
        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        // check if we have a cookied appt id and if duplicate returned
        // if so, use the cookied appt id rather than the appt id returned by eConfirm
        // else we want to set the cookie appt id for future use
        if (responseJson.success !== 'false' && responseJson.data) {
            if (
                verify === 'true' &&
                responseJson.data.duplicate &&
                responseJson.data.duplicate.toLowerCase() === 'true'
            ) {
                responseJson.data.appointmentId = apptCookie.appointmentId;
            } else {
                setCookie(
                    'apAppointmentId',
                    JSON.stringify({
                        appointmentId: responseJson.data.appointmentId,
                        storeNumber,
                        datetime
                    }),
                    1 / 24
                );
            }
        }
        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};
