import '../scss/article-list-infinite.scss';
import { component } from 'common/js/library/component';
import { viewport } from 'common/js/library/viewport';

const instanceInit = (rootEl) => {
    console.log('ArticleListInfinite init');
    let data = {};
    let filterSection = rootEl.querySelector('.article-list__filter-section');

    if (filterSection) {
        data.mobileThreshold = filterSection.getAttribute('data-mobile-threshold');
        data.mobileInitialVisible = filterSection.getAttribute('data-mobile-initial-visible');
        data.desktopThreshold = filterSection.getAttribute('data-desktop-threshold');
        data.desktopInitialVisible = filterSection.getAttribute('data-desktop-initial-visible');
    }

    componentInit(rootEl, data);
};

const componentInit = (rootEl, data) => {
    let componentData = {
        cta: rootEl.querySelector('.load-more-cta'),
        loadMoreSection: rootEl.querySelector('.load-more-section'),
        pagination: rootEl.querySelector('.content-pagination'),
        filterCtaGroup: rootEl.querySelector('.article-list__filter-group'),
        articleCards: rootEl.querySelectorAll('.article-card'),
        filterCount: rootEl.querySelector('.article-list__filter-count'),
        filterSelectedClass: 'article-list__filter-cta--selected'
    };

    componentData.filteredCards = componentData.articleCards;
    generateFilterCtas(componentData.articleCards, componentData.filterCtaGroup);
    componentData.filterCtas = rootEl.querySelectorAll('.article-list__filter-cta');
    if (viewport.currentQuery === 'phone') {
        componentData.threshold = data.mobileThreshold;
        componentData.initialVisible = data.mobileInitialVisible;
    } else {
        componentData.initialVisible = data.desktopInitialVisible;
        componentData.threshold = data.desktopThreshold;
    }

    [...componentData.filteredCards].forEach((filterCard, index) => {
        if (index >= componentData.initialVisible) {
            filterCard.style.display = 'none';
        }
    });

    updateLoadMoreSection(componentData);

    componentData.cta.addEventListener('click', (evt) => handleEvent(evt, componentData));

    [...componentData.filterCtas].forEach((filterCta) => {
        filterCta.addEventListener('click', (evt) => handleEvent(evt, componentData));
    });
};

const generateFilterCtas = (articleCards, filterCtaGroup) => {
    let tagsArray = [];

    [...articleCards].forEach((articleCard) => {
        if (articleCard.getAttribute('data-tag')) {
            let tags = articleCard.getAttribute('data-tag');
            tags.split(',').map((tag) => {
                tagsArray.push(tag.trim());
            });
        }
    });

    let ctaTemplate = (label) => {
        return ` <button type="button" class="article-list__filter-cta" data-tag-key="${label}">
            ${label} <span class="article-list__filter-cta__close"></span>
        </button>
    `;
    };

    tagsArray = tagsArray.filter((item, index, arr) => {
        return arr.indexOf(item) == index;
    });

    filterCtaGroup.innerHTML = tagsArray.map((tag) => ctaTemplate(tag)).join('');
};

const handleEvent = (evt, componentData) => {
    let currentTarget = evt.currentTarget;
    let trackData = '';
    evt.preventDefault();

    switch (evt.type) {
        case 'click':
            if (currentTarget.classList.contains('load-more-cta')) {
                let hiddenFilteredCards = [];
                [...componentData.filteredCards].forEach((filteredCard) => {
                    if (window.getComputedStyle(filteredCard).display == 'none') {
                        hiddenFilteredCards.push(filteredCard);
                    }
                });

                [...hiddenFilteredCards].forEach((hiddenFilterCard, index) => {
                    if (index < componentData.threshold) {
                        hiddenFilterCard.style.display = 'block';
                    }
                });

                updateLoadMoreSection(componentData);

                trackData = {
                    articleCategory: window?.digitalData?.page?.category?.subCategory2 || ''
                };
                window._satellite?.track('blog.load.more', trackData);
            } else if (currentTarget.classList.contains('article-list__filter-cta')) {
                let tagKey = currentTarget.getAttribute('data-tag-key');

                if (currentTarget.classList.contains(componentData.filterSelectedClass)) {
                    currentTarget.classList.remove(componentData.filterSelectedClass);
                    currentTarget.blur();
                } else {
                    currentTarget.classList.add(componentData.filterSelectedClass);

                    trackData = { filterCategory: tagKey };
                    window._satellite?.track('blog.filter', trackData);
                }

                componentData.filteredCards = updateFilteredCards(componentData);
                updateLoadMoreSection(componentData);
                updateFilterCtas(componentData);
            }
            break;
        default:
            throw TypeError('LoadMore._handleEvent: Event type not recognized.');
    }
};

const updateLoadMoreSection = (componentData) => {
    let visibleFilteredCardsLength = 0;
    [...componentData.filteredCards].forEach((filteredCard) => {
        if (window.getComputedStyle(filteredCard).display == 'block') {
            visibleFilteredCardsLength++;
        }
    });

    componentData.pagination.innerHTML = `SHOWING ${visibleFilteredCardsLength} of ${componentData.filteredCards.length}`;

    componentData.filterCount &&
        (componentData.filterCount.innerHTML = `SHOWING ${componentData.filteredCards.length} ARTICLES`);

    let hiddenFilteredCardsLength = 0;
    [...componentData.filteredCards].forEach((filteredCard) => {
        if (window.getComputedStyle(filteredCard).display == 'none') {
            hiddenFilteredCardsLength++;
        }
    });

    const ctaText =
        hiddenFilteredCardsLength <= componentData.threshold ? hiddenFilteredCardsLength : componentData.threshold;
    componentData.cta.innerHTML = `LOAD ${ctaText} MORE`;

    const displayStyle = hiddenFilteredCardsLength === 0 ? 'none' : 'block';
    componentData.loadMoreSection.style.display = displayStyle;
};

const updateFilterCtas = ({ filterCtas, filteredCards, filterSelectedClass }) => {
    [...filterCtas].forEach((filterCta) => {
        const tag = filterCta.getAttribute('data-tag-key');
        let count = 0;

        [...filteredCards].forEach((filteredCard) => {
            const filterCardDataTag = filteredCard.getAttribute('data-tag');
            if (filterCardDataTag) {
                filterCardDataTag.split(',').indexOf(tag) > -1 ? count++ : count;
            }
        });

        if (count === 0) {
            filterCta.disabled = true;
            filterCta.classList.remove(filterSelectedClass);
        } else {
            filterCta.disabled = false;
        }
    });
};

const updateFilteredCards = ({ filterCtas, articleCards, initialVisible, filterSelectedClass, filteredCards }) => {
    [...filterCtas].forEach((filterCta) => {
        if (filterCta.classList.contains(filterSelectedClass)) {
            const tagKey = filterCta.getAttribute('data-tag-key');

            filteredCards = [...articleCards].filter((articleCard) => {
                const tags = articleCard.getAttribute('data-tag');
                if (tags) {
                    if (tags.split(',').indexOf(tagKey) > -1) {
                        return true;
                    }
                }
            });
        }
    });

    let filterCtasSelectedLength = 0;
    [...filterCtas].forEach((filterCta) => {
        if (filterCta.classList.contains(filterSelectedClass)) {
            filterCtasSelectedLength++;
        }
    });

    if (filterCtasSelectedLength === 0) {
        filteredCards = articleCards;
        [...filteredCards].forEach((filteredCard, index) => {
            const displayStyle = index < initialVisible ? 'block' : 'none';
            filteredCard.style.display = displayStyle;
        });
    } else {
        [...articleCards].forEach((articleCard) => {
            articleCard.style.display = 'none';
        });
        [...filteredCards].forEach((filteredCard, index) => {
            if (index < initialVisible) {
                filteredCard.style.display = 'block';
            }
        });
    }
    return filteredCards;
};

component({
    selector: '.blog__article-list--infinite, .blog__article-list--filter-infinite',
    instanceInit,
    name: 'ArticleListInfinite'
});
