const validateSearchInput = (rootEl, val) => {
    const searchErrorzip = rootEl.querySelector('.search-block .zipcodeError');
    const searchZipBtn = rootEl.querySelector('.search-block .btn');
    var wrapInner = document.querySelector('.local-seo-theme .container .wrap .wrap-inner');
    let disabled = true;
    let zipRe = /^\d{5}(-\d{4})?$/;
    let cityStateRe =
        /^(?:[A-Za-z ]+?(:^|[, ]))+[,]?[ ]?([Aa][LKSZRAEPlkszraep]|[Cc][AOTaot]|[Dd][ECec]|[Ff][LMlm]|[Gg][AUau]|[Hh][Ii]|[Ii][ADLNadln]|[Kk][SYsy]|[Ll][Aa]|[Mm][ADEHINOPSTadehinopst]|[Nn][CDEHJMVYcdehjmvy]|[Oo][HKRhkr]|[Pp][ARWarw]|[Rr][Ii]|[Ss][CDcd]|[Tt][NXnx]|[Uu][Tt]|[Vv][AITait]|[Ww][AIVYaivy])$/;

    if (val !== '') {
        if (zipRe.test(val)) {
            disabled = false;
            searchErrorzip.innerText = '';
            if (wrapInner) {
                wrapInner.classList.remove('has-errors');
            }
            if (parseInt(val) === 0) {
                searchErrorzip.innerText = 'No Stores Within Range';
                if (wrapInner) {
                    wrapInner.classList.add('has-errors');
                }
            }
        } else if (cityStateRe.test(val)) {
            disabled = false;
            searchErrorzip.innerText = '';
            if (wrapInner) {
                wrapInner.classList.remove('has-errors');
            }
        } else {
            searchErrorzip.innerText = 'Please enter a valid zip code or city, state';
            if (wrapInner) {
                wrapInner.classList.add('has-errors');
            }
        }
    }

    searchZipBtn.disabled = disabled;
};

export { validateSearchInput };
