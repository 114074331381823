import { hasSmallViewPort } from './hasSmallViewPort';
import { resetWaitDropoff } from './resetWaitDropoff';
import { scrollTo } from './scrollTo';
import { showHours } from './showHours';
import { roundTimeToNearestHalfHourOrFullHour } from 'common/js/util/date-time/roundTimeToNearestHalfHourOrFullHour';
import { dateToYYYYMMDD } from 'common/js/util/date-time/dateToYYYYMMDD';
import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { daySuffix } from 'common/js/util/date-time/daySuffix';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

let eventListenersAdded = false;

const onSlotClick = (evt, rootEl, props) => {
    const { dateStoreStep, completeDateTimeSelection, getStoresInfo } = props;
    evt.preventDefault();

    const currentTarget = evt.currentTarget;
    const col = currentTarget.closest('.hours-item');
    const dropOffContainer = rootEl.querySelector('.drop-off-mileage');
    const storesContainer = rootEl.querySelector('.stores');
    const datesEl = rootEl.querySelector('.dates');
    const availableEl = rootEl.querySelectorAll('li.available');
    availableEl.forEach((item) => {
        item.classList.remove('selected');
    });
    currentTarget.classList.add('selected');
    col.dataset.selectedTime = currentTarget.dataset.timeSlot;
    dateStoreStep.dataset.date = JSON.stringify(col.dataset);

    // analytics for time table interaction
    setAdobeDataLayer({
        event: 'sa.v2.calendarinteraction'
    });

    // Logic for Pinnacle store feature
    const stores = getStoresInfo().stores;
    const selectedStore = currentTarget.closest('.store-item').dataset.storeNumber;
    const selectedStoreData = stores.find((store) => store.storeNumber === selectedStore);
    currentTarget
        .closest('.schedule-appointment-v2')
        .classList.toggle('at-pinnacle-store', selectedStoreData?.onlineExperience === 'pinnacle');

    currentTarget.closest('.stores')?.classList.add('storeSelected');
    currentTarget.closest('.otherStoresList')?.classList.add('storeSelected');

    rootEl.querySelector('.time-slot-error-message').classList.add('hide');

    if (completeDateTimeSelection) {
        completeDateTimeSelection.forEach((completeDateTimeSelectionCta) => {
            completeDateTimeSelectionCta.disabled = false;
        });
    }

    resetWaitDropoff(rootEl);

    // Handle scroll if in small viewports
    if (hasSmallViewPort()) {
        scrollTo(rootEl, dropOffContainer.offsetTop);
    }

    // Find the selected time slot
    const selectedTime = rootEl.querySelector('[data-time-slot].selected');
    if (selectedTime) {
        const storeItem = selectedTime.offsetParent?.offsetParent?.offsetParent;
        if (storeItem) {
            const storeItemTop = storeItem.offsetTop;
            const selectedTimeTop = selectedTime.offsetTop + storeItemTop;

            // Scroll to the selected time slot within the store
            $(storesContainer).animate({ scrollTop: selectedTimeTop }, 500);
            // Should be able to write an alternative to JQuery here to animate the scroll
            // But I could not get the animate function below to work.
            // animate(storesContainer, 'scrollTop', storesContainer.scrollTop, selectedTimeTop, 500);
            const locationBar = rootEl.querySelector('.date-store-container .find');
            // For Pinnacle store, the locationBar is display: none
            // Check with locationBar.offsetHeight === 0, or window.getComputedStyle(locationBar).display === 'none'
            if (locationBar && locationBar.offsetHeight) {
                const scrollTop =
                    locationBar.offsetTop + locationBar.offsetHeight + locationBar.offsetParent?.offsetTop;
                window.scrollTo({ left: 0, top: scrollTop, behavior: 'smooth' });
            }
        }
    }
};

const animate = (element, property, start, end, duration) => {
    let startTime = null;
    function step(timestamp) {
        if (!startTime) {
            startTime = timestamp;
        }

        const progress = Math.min((timestamp - startTime) / duration, 1);
        const value = start + (end - start) * progress;

        element.style[property] = value + 'px';

        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    }

    window.requestAnimationFrame(step);
};

const onShowMoreClick = (evt) => {
    evt.preventDefault();

    const currentTarget = evt.currentTarget;
    const col = currentTarget.closest('.hours');

    col.classList.toggle('preview-hours');
    [...col.querySelectorAll('li.show-more')].forEach((showMoreLi) => {
        showMoreLi.classList.toggle('less');
    });
};

const addStoresHours = (rootEl, props) => {
    const { data, dateStoreStep, completeDateTimeSelection, getStoresInfo, setStoresInfo, numDays } = props;
    const now = new Date();
    const currentYYYYMMDD = dateToYYYYMMDD(now);
    const stores = Object.assign([], getStoresInfo().stores);

    const setStore = getLocalStorageItem('storeData');
    const setStoreNumber = setStore.storeNumber;
    const filteredStores = [...stores].filter((item) => item.storeNumber === setStoreNumber);
    const otherStores = [...stores].filter((item) => item.storeNumber !== setStoreNumber);
    const isPinnacleStore = setStore.onlineExperience === 'pinnacle';
    const isPinnacle = window?.featureSwitches?.isTrue('bsro-ui-pinnacle');
    const dropOffContainer = document.body.querySelector('.drop-off-mileage');
    const pinnacleContainer = document.body.querySelector('.pinnacle-container');
    let pinnacle = isPinnacle && isPinnacleStore;
    let storeDataToUse = isPinnacle && isPinnacleStore ? filteredStores : stores;

    const updateStoreData = (items) => {
        items.forEach((item) => {
            const storeObjects = getStoresInfo().storesAsDataObj;
            const storeObj = storeObjects[item.storeNumber];
            const dataObj = data[item.storeNumber];
            const container = rootEl.querySelector(`.store-item[data-store-number="${item.storeNumber}"]`);
            const hours = container.querySelectorAll('.hours');

            let daytimes = dataObj?.daytimes || null;
            let errorMessage = dataObj?.messages?.error || dataObj?.message?.error || null;
            let hideHalfHours = true;

            if (daytimes) {
                hours.forEach((hour) => (hour.style.display = ''));
                container.querySelectorAll('.noStoresFound').forEach((item) => item.remove());

                daytimes.forEach((date) => {
                    const times = date.times;
                    const hoursItem = container.querySelector(`[data-available-date="${date.availableDate}"]`);
                    hoursItem?.setAttribute('data-available-year', parseInt(date.availableYear));
                    hoursItem?.setAttribute('data-available-month', parseInt(date.availableMonth));
                    hoursItem?.setAttribute('data-available-day', parseInt(date.availableDay));
                    hoursItem?.setAttribute('data-day-name', date.dayName);

                    // clear out old times for when we need to refresh the time-slots
                    const oldTimes = hoursItem?.querySelectorAll('[data-time-slot]');
                    oldTimes.forEach((oldTime) => {
                        const oldEvenTime = roundTimeToNearestHalfHourOrFullHour(oldTime?.dataset?.timeSlot);
                        oldTime.innerHTML = '-';
                        oldTime.classList.remove('available');
                        oldTime.classList.remove('selected');
                        oldTime.setAttribute('data-time-slot', oldEvenTime);
                    });

                    times.forEach((slot) => {
                        const evenSlot = roundTimeToNearestHalfHourOrFullHour(slot);
                        const slotEl = hoursItem?.querySelector(`[data-time-slot="${evenSlot}"]`);
                        if (slotEl) {
                            slotEl.innerHTML = slot;
                            slotEl.classList.add('available');
                            slotEl.setAttribute('data-time-slot', slot);
                        }
                    });

                    if (times.includes(':30')) {
                        hideHalfHours = false;
                    }

                    if (times.length > 0) {
                        hoursItem?.classList.remove('no-slots');
                    }
                });
            } else if (errorMessage) {
                const noStoresFound = document.createElement('p');
                if (!noStoresFound) {
                    noStoresFound.className = 'noStoresFound';
                    noStoresFound.innerHTML = errorMessage;
                    hours[0].parentNode.insertBefore(noStoresFound, hours[0]);
                    hours.forEach((hour) => (hour.style.display = 'none'));
                }
            }

            storeObjects[item.storeNumber] = { ...storeObj, ...dataObj };
            setStoresInfo({ storesAsDataObj: storeObjects });

            const storeHoursLoader = container.querySelector('.store-hours .wait');
            if (storeHoursLoader) {
                storeHoursLoader.style.display = 'none';
            }

            if (hideHalfHours) {
                container
                    .querySelectorAll('.store-hours')
                    .forEach((storeHour) => storeHour.classList.add('hide-half-hours'));
            }
        });
    };

    updateStoreData(storeDataToUse);
    if (pinnacle) {
        updateStoreData(otherStores);
    }

    showHours(rootEl, { numDays });

    const getTimeInfo = async () => {
        let totalTime = getLocalStorageItem('totalServiceTime');
        totalTime = parseInt(totalTime, 10); // Convert total time to integer
        const dateTextEl = await document.querySelector('.appointmentText');
        const dateEl = document.querySelector('[data-date]')?.dataset.date;
        const dateData = dateEl && JSON.parse(dateEl);
        const dropOffWrapperTitle = dropOffContainer?.querySelector('h3');
        const dropOffEl = dropOffContainer?.querySelector('.drop-off');
        const timeDurationEl = document.body.querySelector('.timeDuration');
        let isTimeSelectedStorePinnacle = stores.some(
            (s) => s.storeNumber.toString() === dateData.storeNumber.toString() && s.onlineExperience === 'pinnacle'
        );
        const nextBtn = dropOffContainer.querySelector('.next-btn-wrapper');

        if (dateData) {
            const date = new Date(dateData.availableYear, dateData.availableMonth - 1, dateData.availableDay);
            const monthName = date.toLocaleString('en-US', { month: 'long' });
            const day = parseInt(dateData.availableDay, 10);
            const dayWithSuffix = `${day}${daySuffix(day)}`;
            const dayName = dateData.dayName;
            const selectedTime = dateData.selectedTime;
            // Parse selected time
            const parseTime = (timeStr) => {
                const [time, period] = timeStr.match(/(\d+:\d+)(am|pm)/).slice(1);
                let [hours, minutes] = time.split(':').map(Number);
                if (period === 'pm' && hours !== 12) hours += 12;
                if (period === 'am' && hours === 12) hours = 0;
                return { hours, minutes };
            };
            const { hours: startHours, minutes: startMinutes } = parseTime(selectedTime);
            // Calculate end time
            let endHours = startHours + Math.floor(totalTime / 60);
            let endMinutes = startMinutes + (totalTime % 60);
            if (endMinutes >= 60) {
                endMinutes -= 60;
                endHours += 1;
            }
            const formatTime = (hours, minutes) => {
                const period = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12 || 12; // Convert to 12-hour format
                return `${hours}:${minutes.toString().padStart(2, '0')}${period}`;
            };
            const endTime = formatTime(endHours, endMinutes);
            dateTextEl.innerText = `${monthName} ${dayWithSuffix}, ${dayName} - ${selectedTime} - ${endTime}`;

            if (pinnacle && isTimeSelectedStorePinnacle) {
                pinnacleContainer.style.display = 'block';
                nextBtn.classList.add('pinnacle');
            } else {
                pinnacleContainer.style.display = 'none';
                nextBtn.classList.remove('pinnacle');
            }
        }
    };

    // this seems very broad in scope and concerning we would be creating far too many event listeners
    // to partially address this, added a check to see if the event listeners have already been added
    rootEl.querySelectorAll('[data-time-slot].available').forEach((item) => {
        if (!item.dataset.hasListener) {
            item.addEventListener('click', (evt) => {
                getTimeInfo();
                onSlotClick(evt, rootEl, { dateStoreStep, completeDateTimeSelection, getStoresInfo });
            });
            item.dataset.hasListener = true;
        }
    });

    rootEl.querySelectorAll('[data-show-more]').forEach((item) => {
        item.removeEventListener('click', onShowMoreClick);
    });
    rootEl.querySelectorAll('[data-show-more]').forEach((item) => {
        item.addEventListener('click', onShowMoreClick);
    });
};

export { addStoresHours };
