import '../scss/battery-life-result.scss';
import { component } from 'common/js/library/component';

const instanceInit = (rootEl) => {
    let batteryCodeBtn = rootEl.querySelector('.battery-date-code .btn.submit-code');

    batteryCodeBtn.addEventListener('click', (evt) => onClickHandler(evt, rootEl));
};

const onClickHandler = (evt, rootEl) => {
    console.log('inside battery-life-result.js');

    let batteryResultsContainer = rootEl.querySelector('.battery-date-code .battery-code-results');
    let batteryLifeTable = rootEl.querySelector('.battery-code-results .battery-life-table');
    let batteryCodeResult = rootEl.querySelector('.battery-code-results .result-msg');

    batteryResultsContainer.style.display = 'block';
    batteryLifeTable.style.display = 'none';

    const columnCodes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L/M'];
    const errorMsg =
        'Your battery code is invalid.  Please make sure it has a valid letter and number as described above.';

    let dateCodeStr = rootEl.querySelector('.battery-date-code .date-code').value;

    let selectedCodeMonth = null;
    let selectedMonth = 0;
    let selectedCodeYear = null;
    let dateCode = dateCodeStr.trim();

    //Validate the date code.  Do we have one letter and one number?
    if (dateCode != '' && dateCode.length == 2) {
        let regexAlpha = /^[a-zA-Z]/;
        let regexNum = /^[0-9]/;
        for (let i = 0; i < dateCode.length; i++) {
            if (regexAlpha.test(dateCode.charAt(i))) {
                selectedCodeMonth = dateCode.charAt(i).toUpperCase();
            } else if (regexNum.test(dateCode.charAt(i))) {
                selectedCodeYear = dateCode.charAt(i);
            }
        }
        if (selectedCodeYear != null && selectedCodeMonth != null) {
            for (let i = 0; i < columnCodes.length; i++) {
                if (columnCodes[i].indexOf(selectedCodeMonth) > -1) {
                    selectedMonth = i + 1;
                    break;
                }
            }
            if (selectedMonth == 0) {
                batteryCodeResult.innerHTML = errorMsg;
            } else {
                getBatteryResults(
                    rootEl,
                    batteryCodeResult,
                    batteryLifeTable,
                    selectedCodeMonth,
                    selectedCodeYear,
                    selectedMonth,
                    columnCodes
                );
            }
        } else {
            batteryCodeResult.innerHTML = errorMsg;
        }
    } else {
        batteryCodeResult.innerHTML = errorMsg;
    }
};

const getBatteryResults = (rootEl, props) => {
    const { batteryCodeResult, batteryLifeTable, selectedCodeMonth, selectedCodeYear, selectedMonth, columnCodes } =
        props;
    let tableHeadDate = rootEl.querySelector('.battery-life-table .heading-dates');
    let tableBodyContent = rootEl.querySelector('.battery-life-table .result-table');

    tableHeadDate.innerHTML = '';
    let tableBody = rootEl.querySelector('.battery-life-table .result-table tbody');
    tableBody && tableBody.remove();

    let date = new Date();
    let currentYear = date.getFullYear();
    let currentMonth = date.getMonth();
    let currentYearValue = parseInt(currentYear.toString().substring(3));
    let codeYearValue = parseInt(selectedCodeYear);
    let selectedYear = 2000;

    if (codeYearValue <= currentYearValue + 1) selectedYear = currentYear - currentYearValue + codeYearValue;
    else selectedYear = currentYear - currentYearValue - 10 + codeYearValue;

    let yearDiff = currentYear - 1 - selectedYear;
    let monthDiff = 13 - selectedMonth + currentMonth;

    if (monthDiff >= 12) {
        yearDiff++;
        monthDiff -= 12;
    }

    let headingDates = new Date();
    headingDates.setMonth(headingDates.getMonth() - 12);

    let columnDates = new Date();
    columnDates.setMonth(columnDates.getMonth() - 72);

    let highlight = true;

    let years = 'year';
    if (yearDiff > 1) years = 'years';

    let months = 'month';
    if (monthDiff > 1) months = 'months';

    let months2 = 'months';
    if (12 * yearDiff + monthDiff == 1) months2 = 'month';

    let timeStatement = '';
    if (yearDiff > 0) {
        if (monthDiff > 0)
            timeStatement =
                yearDiff +
                ' ' +
                years +
                ' and ' +
                monthDiff +
                ' ' +
                months +
                ' (' +
                (12 * yearDiff + monthDiff) +
                ' ' +
                months2 +
                ')';
        else timeStatement = yearDiff + ' ' + years + ' (' + (12 * yearDiff + monthDiff) + ' ' + months2 + ')';
    } else {
        timeStatement = 12 * yearDiff + monthDiff + ' ' + months2;
    }

    let condStatement = '';
    if (12 * yearDiff + monthDiff <= 24) condStatement = ' old and should not need replacement for at least 12 months.';
    else if (12 * yearDiff + monthDiff > 71) condStatement = ' old and is due for replacement.';
    else {
        condStatement = ' old.';

        batteryLifeTable.style.display = 'block';

        tableHeadDate && tableHeadDate.insertAdjacentHTML('beforeend', '<td></td>');

        for (let i = 0; i < 24; i++) {
            headingDates.setMonth(headingDates.getMonth() + 1);
            tableHeadDate &&
                tableHeadDate.insertAdjacentHTML(
                    'beforeend',
                    '<td>' + headingDates.toDateString().substring(4, 7) + ' ' + headingDates.getFullYear() + '</td>'
                );
        }

        for (let i = 0; i < 48; i++) {
            let rowMonth = 60 - i;
            let highlightColumn = false;
            columnDates.setMonth(columnDates.getMonth() + 1);
            let codeMonth = columnCodes[columnDates.getMonth()];
            let codeYear = columnDates.getFullYear().toString().substring(3);
            if (codeMonth.indexOf(selectedCodeMonth) > -1 && codeYear == selectedCodeYear) {
                highlightColumn = true;
                highlight = false;
            }

            let batteryAge = '';
            for (let j = 0; j < 24; j++) {
                let tdColor = highlightColumn && j == 11 ? '#fff' : '#000';
                let tdBgColor =
                    (highlight && j == 11) || (highlightColumn && j < 12)
                        ? '#EC5765'
                        : rowMonth + j > 60
                        ? '#ffff99'
                        : rowMonth + j > 35
                        ? '#ccffcc'
                        : '#ffffff';
                batteryAge +=
                    "<td style='color:" + tdColor + ';background-color:' + tdBgColor + ";'>" + (rowMonth + j) + '</td>';
            }

            let rowHead = "<tbody><tr><td class='bold'>" + codeMonth + ' ' + codeYear + '</td>';
            let tableRows = rowHead + batteryAge + '</tr></tbody>';
            tableBodyContent.insertAdjacentHTML('beforeend', tableRows);
        }
    }

    batteryCodeResult.innerHTML =
        'Your battery code results show that your battery is ' + timeStatement + condStatement;
};

component({
    selector: '[data-battery-life-result]',
    instanceInit,
    name: 'BatteryLifeResult'
});
