/**
 *
 * @param {string} type - vehicle service type ('tce', 'main', 'aux')
 * @param {boolean} backup - tries to get another service type's data if the original has empty data.
 */
export const getProfile = (type, backup = true) => {
    let data = {
        vehicle: {},
        type,
        zip: ''
    };

    if (window?.siteProfile?.location) {
        data.zip = window.siteProfile.location.myZip || window.siteProfile.location.autoZip;
    }

    if (window?.siteProfile?.vehicles[type]) {
        data.vehicle = window.siteProfile.vehicles[type];
    }

    if (backup && !data.vehicle.ymm && (type === 'tce' || type === 'main')) {
        data = getBackupVehicleProfile(type) || data;
    }

    return data;
};

/**
 *
 * @param {string} type - vehicle service type that was empty and needs a potential different backup service type
 */
export const getBackupVehicleProfile = (type) => {
    if (type === 'tce') {
        return getProfile('main', false);
    } else if (type === 'main') {
        return getProfile('tce', false);
    }

    return null;
};
