import { responseError, HTTPHeaders } from 'common/js/data/services/serviceUtilities';
import { getCache, setCache } from 'common/js/util/sessionCache';

/**
 * Get Catalog for all tires
 * @returns object
 */
const getCatalogAllTires = async () => {
    try {
        const siteName = window.AEM.siteName;
        const url = `/bsro/services/tires/get-catalog?site=${siteName}&tireType=all-tires`;
        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        console.log(err);
    }
};

export { getCatalogAllTires };
