import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import RadioInput from '../../../elements/radio/v1/RadioInput';

const VehicleSelectorForm = (props) => {
    const {
        years,
        selectedYear,
        makes,
        selectedMake,
        models,
        selectedModel,
        trims,
        selectedTrim,
        clearSelectedYear,
        onYearSelected,
        onMakeSelected,
        onModelSelected,
        onTrimSelected,
        onRevertToYear,
        onRevertToMake,
        onRevertToModel,
        setFieldValue,
        preselectStatus
    } = props;

    /**
     *  necesary variables to scroll behavior on preselect vehicle
     *  panelOptionHeight is a fired height, of each option in the panel,
     *  this one can change in future, currently is the easy way to this behavior
     */
    const panelOptionHeight = 44;
    const yearPanelRefs = useRef(null);
    const makePanelRefs = useRef(null);
    const modelPanelRefs = useRef(null);
    const trimPanelRefs = useRef(null);

    const isYearSelected = selectedYear !== '' && selectedYear !== undefined;
    const isMakeSelected = selectedMake !== '' && selectedMake !== undefined;
    const isModelSelected = selectedModel !== '' && selectedModel !== undefined;
    const isTrimSelected = selectedTrim !== '' && selectedTrim !== undefined;

    const [yearScrollPosition, setYearScrollPosition] = useState(0);
    const [makeScrollPosition, setMakeScrollPosition] = useState(0);
    const [modelScrollPosition, setModelScrollPosition] = useState(0);

    useEffect(() => {
        if (selectedYear) {
            console.log('selectedYear change:', { selectedYear });
        }
    }, [selectedYear]);

    useEffect(() => {
        if (selectedMake) {
            console.log('selectedMake change:', { selectedMake });
        }
    }, [selectedMake]);

    useEffect(() => {
        if (selectedModel) {
            console.log('selectedModel change:', { selectedModel });
        }
    }, [selectedModel]);

    useEffect(() => {
        if (selectedTrim) {
            console.log('selectedTrim change:', { selectedTrim });
        }
    }, [selectedTrim]);

    useEffect(() => {
        if (selectedYear && preselectStatus) {
            const scrollPosition = getItemScrollPosition(selectedYear, years);
            setYearScrollPosition(scrollPosition);
            scrollBackUp(scrollPosition, yearPanelRefs);
        }

        if (selectedMake && preselectStatus) {
            const scrollPosition = getItemScrollPosition(selectedMake, makes);
            setMakeScrollPosition(scrollPosition);
            scrollBackUp(scrollPosition, makePanelRefs);
        }

        if (selectedModel && preselectStatus) {
            const scrollPosition = getItemScrollPosition(selectedModel, models);
            setModelScrollPosition(scrollPosition);
            scrollBackUp(scrollPosition, modelPanelRefs);
        }

        if (selectedTrim && preselectStatus) {
            const scrollPosition = getItemScrollPosition(selectedTrim, trims);
            scrollBackUp(scrollPosition, trimPanelRefs);
        }
    }, [years, makes, models, trims]);

    useEffect(() => {
        if (trims.length === 1 && !selectedTrim) {
            const singleTrim = trims[0];
            const value = JSON.stringify({
                trim: singleTrim.trim,
                tpms: singleTrim.tpms,
                vehicleId: singleTrim.acesVehicleId
            });

            onTrimSelected(
                {
                    target: { value }
                },
                setFieldValue
            );
        }
    }, [trims, selectedTrim, setFieldValue, onTrimSelected]);

    const setScrollPositions = () => {
        yearPanelRefs.current.scrollTo(0, yearScrollPosition);
        makePanelRefs.current.scrollTo(0, makeScrollPosition);
        modelPanelRefs.current.scrollTo(0, modelScrollPosition);
    };

    useLayoutEffect(() => {
        if ((selectedYear || selectedMake || selectedModel) && !selectedTrim) {
            setScrollPositions();
        }

        if (selectedYear && selectedMake && selectedModel && selectedTrim) {
            setTimeout(() => {
                setScrollPositions();
            }, 100);
        }
    }, [selectedYear, selectedMake, selectedModel, selectedTrim]);

    const getItemScrollPosition = (selectedItem, list) => {
        const selectedItemIndex = list.indexOf(selectedItem);
        const selectedItemOffSetTop = panelOptionHeight * selectedItemIndex;
        return selectedItemOffSetTop;
    };

    const scrollBackUp = (scrollPosition, panelRef) => {
        setTimeout(() => {
            panelRef.current.scrollTo(0, scrollPosition);
        }, 100);
    };

    const onYearChange = (e, setFieldValue) => {
        setYearScrollPosition(yearPanelRefs.current.scrollTop);
        onYearSelected(e, setFieldValue);
    };

    const onMakeChange = (e, setFieldValue) => {
        setMakeScrollPosition(makePanelRefs.current.scrollTop);
        onMakeSelected(e, setFieldValue);
    };

    const onModelChange = (e, setFieldValue) => {
        setModelScrollPosition(modelPanelRefs.current.scrollTop);
        onModelSelected(e, setFieldValue);
    };

    const YearsList = () => {
        return (
            <div className="list" ref={yearPanelRefs}>
                {years.map((year, idx) => {
                    return (
                        <Field
                            component={RadioInput}
                            key={'year' + idx}
                            name="selectYear"
                            id={'mblYr' + idx}
                            value={year}
                            label={year}
                            defaultChecked={year === selectedYear}
                            onChangeHandler={(e, setFieldValue) => onYearChange(e, setFieldValue)}
                            setFieldValue={setFieldValue}
                        />
                    );
                })}
            </div>
        );
    };

    const YearSelector = () => {
        const active = true;
        const selected = isYearSelected;

        return (
            <div
                className={classNames('column', 'year', {
                    active,
                    selected
                })}
            >
                <div className="column-content">
                    <div className="column-title">Year</div>
                    <YearsList />
                </div>
                <div className="selected-option" onClick={clearSelectedYear}>
                    {selectedYear}
                </div>
            </div>
        );
    };

    const MakeList = () => {
        return (
            <div className="list" ref={makePanelRefs}>
                {makes.map((make, idx) => {
                    return (
                        <Field
                            component={RadioInput}
                            key={'make' + idx}
                            name="selectMake"
                            id={'mblMake' + idx}
                            value={make}
                            label={make}
                            defaultChecked={make === selectedMake}
                            onChangeHandler={(e, setFieldValue) => onMakeChange(e, setFieldValue)}
                            setFieldValue={setFieldValue}
                        />
                    );
                })}
            </div>
        );
    };

    const MakeSelector = () => {
        const active = isYearSelected;
        const selected = isMakeSelected;

        return (
            <div
                className={classNames('column', 'make', {
                    active,
                    selected
                })}
            >
                <div className="column-content">
                    <div className="column-title">Make</div>
                    <MakeList />
                </div>
                <div className="selected-option" onClick={(e) => onRevertToYear(setFieldValue, selectedYear)}>
                    {selectedMake}
                </div>
            </div>
        );
    };

    const ModelList = () => {
        return (
            <div className="list" ref={modelPanelRefs}>
                {models.map((model, idx) => {
                    return (
                        <Field
                            component={RadioInput}
                            key={'model' + idx}
                            name="selectModel"
                            id={'mblModel' + idx}
                            value={model}
                            label={model}
                            defaultChecked={model === selectedModel || models.length === 1}
                            onChangeHandler={(e, setFieldValue) => onModelChange(e, setFieldValue)}
                            setFieldValue={setFieldValue}
                        />
                    );
                })}
            </div>
        );
    };

    const ModelSelector = () => {
        const active = isMakeSelected;
        const selected = isModelSelected;

        return (
            <div
                className={classNames('column', 'model', {
                    active,
                    selected
                })}
            >
                <div className="column-content">
                    <div className="column-title">Model</div>
                    <ModelList />
                </div>
                <div className="selected-option" onClick={(e) => onRevertToMake(setFieldValue, selectedMake)}>
                    {selectedModel}
                </div>
            </div>
        );
    };

    const TrimList = () => {
        return (
            <div className="list" ref={trimPanelRefs}>
                {console.log('my arry -->', trims)}
                {trims
                    .sort((a, b) => (a.trim > b.trim ? 1 : -1))
                    .map((data, idx) => {
                        const trim = data['trim'];
                        const value = JSON.stringify({
                            trim: data['trim'],
                            tpms: data['tpms'],
                            vehicleId: data['acesVehicleId']
                        });

                        return (
                            <Field
                                component={RadioInput}
                                key={'make' + idx}
                                name="selectTrim"
                                id={'mblTrim' + idx}
                                value={value}
                                label={trim}
                                defaultChecked={trim === selectedTrim || trims.length === 1}
                                onChangeHandler={onTrimSelected}
                                setFieldValue={setFieldValue}
                            />
                        );
                    })}
            </div>
        );
    };

    const TrimSelector = () => {
        const active = isModelSelected;
        const selected = isTrimSelected || trims.length === 1;

        return (
            <div
                className={classNames('column', 'trim', {
                    active,
                    selected
                })}
            >
                <div className="column-content">
                    <div className="column-title">Submodel</div>
                    <TrimList />
                </div>
                <div className="selected-option" onClick={(e) => onRevertToModel(setFieldValue, selectedModel)}>
                    {selectedTrim}
                </div>
            </div>
        );
    };

    return (
        <div className="columns">
            <YearSelector />
            <MakeSelector />
            <ModelSelector />
            <TrimSelector />
        </div>
    );
};

VehicleSelectorForm.propTypes = {
    years: PropTypes.array.isRequired,
    makes: PropTypes.array.isRequired,
    models: PropTypes.array.isRequired,
    trims: PropTypes.array.isRequired,
    selectedYear: PropTypes.string.isRequired,
    selectedMake: PropTypes.string.isRequired,
    selectedModel: PropTypes.string.isRequired,
    selectedTrim: PropTypes.string.isRequired,
    clearSelectedYear: PropTypes.func.isRequired,
    onYearSelected: PropTypes.func.isRequired,
    onModelSelected: PropTypes.func.isRequired,
    onMakeSelected: PropTypes.func.isRequired,
    onTrimSelected: PropTypes.func.isRequired,
    onRevertToYear: PropTypes.func.isRequired,
    onRevertToMake: PropTypes.func.isRequired,
    onRevertToModel: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    preselectStatus: PropTypes.bool
};

export { VehicleSelectorForm };
