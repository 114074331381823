import React from 'react';
import { render } from 'react-dom';

import { isLIDEnabled, lidData, getTiresNearYouInInventory } from '../../../../../js/util/lidUserStates';
import { setLocalStorageItem } from '../../../../../js/data/localstorage/localStorageService';
import {
    getOptions,
    isProfileMatch,
    isTiresProfileMatch,
    normalizeWidgetValue,
    tireCardTemplate,
    seeAllTiresCardTemplate
} from './util';
import { config } from './config';
import { getProfile } from './profile';
import { reviewsReactAppLoader } from './reviews/reviews-react-app-loader';
import { VehicleButton } from './vehicle/VehicleButton';
import { getVehicleTrim, setVehicle } from 'common/js/data/services/vehicle/vehicleData';
import { serializeForm, setCookie } from 'common/js/library/util';
import { component } from 'common/js/library/component';
import { getSiteProfileTires } from 'common/components/content/react-shared/utils/siteProfile';
import { getVehicleLookupApi } from 'common/js/data/services/vehicle-lookup/vehicleLookupServices';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';
import { createToolTip } from 'common/js/library/tooltip';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { getVehicleSearchURL } from 'common/components/content/react-shared/utils/getVehicleSearchURL.js';
import { store } from 'common/js/data/redux/store/exportedStore';
import { reviewsReduxLoaderV2 } from 'common/components/content/reviews/v2/js/reviewsReduxLoader';
import { setStoreByZip } from 'common/js/data/services/store-location';
import { testZip } from 'common/components/content/react-shared/utils/validation';
// import { publishZipCodeInterceptPrompt } from 'common/js/util/pinnacle/zipCodeInterept';
// import { getPinnacleStoreDetails } from 'common/js/util/pinnacle/pinnacleStatus';
// import { VehicleRepository } from 'common/js/util/vehicle-repository/vehicle-repository';
// import { VehicleForRepository as Vehicle } from 'common/js/util/vehicle-repository/vehicle';

import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';

import { VehicleRepository } from 'common/js/util/vehicle-repository/vehicle-repository';
import { Vehicle } from 'common/js/util/vehicle-repository/vehicle';

import { isYMMTInList } from 'common/js/util/vehicle-repository/isYMMTInList';

let mobileTabNav;
let widgetTabNav;
let topNav;
let settings;
let zipCodeErrorEl;
let widgetBySizeZipErrorEl;
let sectionAlignmentZipErrorEl;
let widgetByVehicleZipErrorEl;
let searchBatteryZipErrorEl;
let hVehicleFilterZipErrorEl;
let widgetZipEl;
let tabNavReskinEl;
let vehicleSearchURLs;
let _redirect;
let tiresNearYou;
const maxCards = 4;

const widget = document.querySelector('.tire-service-widget-v3');
const vehicleLookupElement = document.querySelector('.vehicle-lookup-v3');
let tabTopWidget;
let tabNavWidget;
let tabContentWidget;
let lidContent;
let backButton;
let changeVehicleButton;
let vehicleText;
let vehicleNickText;
let appointmentButton;
let tiresButton;
let cardsContainer;
let thumbnailsHandlers;
let mobileTrack;
let thumb;
let track;
let thumbWidth = 100;
let nextButton;
let prevButton;
let thumbnailIndex = 0;
let initialX = null;
const vehicleRepository = new VehicleRepository();
if (widget) {
    tabTopWidget = widget.querySelector('.top-nav.tab-top');
    tabNavWidget = widget.querySelector('.tab-nav.tab-left.reskin');
    tabContentWidget = widget.querySelector('.tab-content');
    lidContent = widget.querySelector('.lid-content');
    backButton = lidContent.querySelector('.lid-content__back');
    changeVehicleButton = lidContent.querySelector('.lid-content__no-tire__action');
    vehicleText = lidContent.querySelector('.lid-content__vehicle');
    vehicleNickText = lidContent.querySelector('.lid-content__vehicle-nickname');
    appointmentButton = lidContent.querySelector('.lid-content__appointment');
    tiresButton = lidContent.querySelector('.lid-content__see-tires');
    cardsContainer = lidContent.querySelector('.lid-content__cards');
    thumbnailsHandlers = lidContent.querySelectorAll('.lid-content__card');

    mobileTrack = lidContent.querySelector('.gallery-scroll');
    thumb = mobileTrack.querySelector('.gallery-scroll__thumb');
    track = mobileTrack.querySelector('.gallery-scroll__track');
    nextButton = lidContent.querySelector('.btn.next');
    prevButton = lidContent.querySelector('.btn.prev');
}

const init = (cfg) => {
    mobileTabNav = document.querySelector('.tire-service-widget-v3 .mobile-tab-nav');
    widgetTabNav = document.querySelector('.tire-service-widget-v3 .mobile-tab-nav+.tab-nav');
    topNav = document.querySelector('.tire-service-widget-v3 .top-nav');
    settings = config;
    zipCodeErrorEl = document.querySelector('.zipcodeError');
    widgetBySizeZipErrorEl = document.querySelector('.tire-search-widget .search-by-size .zipcodeError');
    sectionAlignmentZipErrorEl = document.querySelector('section.active .search-alignment .zipcodeError');
    widgetByVehicleZipErrorEl = document.querySelector('.tire-search-widget .search-by-vehicle .zipcodeError');
    searchBatteryZipErrorEl = document.querySelector('.search-battery .zipcodeError');
    hVehicleFilterZipErrorEl = document.querySelector('.horizontal-vehicle-filter .zipcodeError');
    widgetZipEl = document.querySelector('[name=widget-zip]');
    tabNavReskinEl = document.querySelector('.tab-nav.reskin');

    document.querySelector('.wait.hide')?.classList.remove('hide');

    // workaround for adding a default class to the tire service widget.
    if (widget) widget.classList.add('shop-tires');
};

const initLID = async () => {
    widget && widget.querySelector('.wait.hide')?.classList?.remove('hide');
    const isEnabled = await isLIDEnabled();
    const storePhone = window.storePhoneNumber;
    tiresNearYou = await getTiresNearYouInInventory();

    console.group('LID');
    console.log(`isEnabled: ${isEnabled}`);
    console.log('lidData:', lidData);
    console.log('tiresNearYou:', tiresNearYou);
    console.groupEnd();
    if (isEnabled && widget) {
        widget.classList.add('lid-enabled');

        displayVehicleData(lidData);
        if (Array.isArray(tiresNearYou) && tiresNearYou.length > 0) {
            displayTiresNearYouData(tiresNearYou);
        } else {
            displayNoTires(storePhone);
        }

        hideNoLIDContent();
        showLIDContent();
        removeEvents(lidData); // Remove events before setting them again.
        addEvents(lidData);
        scrollBar();
    }

    widget && widget.querySelector('.wait')?.classList.add('hide');
};

const updateVehicleSelected = async (vehicle) => {
    vehicle.tpms = vehicle.tpms ? vehicle.tpms : 1;

    const newVehicle = new Vehicle(vehicle);
    const isInList = await isYMMTInList(newVehicle);
    if (!isInList) {
        pubsub.publish('PHW-vehicle-selected', [vehicle]);
    }
};

const scrollPercentage = () => {
    return (cardsContainer.scrollLeft / (cardsContainer.scrollWidth - cardsContainer.clientWidth)) * 100;
};

const scrollBar = () => {
    function updateThumbPosition() {
        const percentage = scrollPercentage();
        const thumbPosition = (percentage * (track.clientWidth - thumb.clientWidth)) / 100;

        thumb.style.left = thumbPosition + 'px';
    }
    function scrollContent() {
        const thumbPosition = parseFloat(thumb.style.left);
        const thumbScrollPercentage = thumbPosition / (track.clientWidth - thumb.clientWidth);

        const lastThumbnail = thumbnailsHandlers.length - 1;
        let currentThumbnail =
            thumbScrollPercentage === 0 ? 0 : thumbScrollPercentage === 1 ? lastThumbnail : thumbScrollPercentage;

        const thumbIndex = Math.round((thumbnailsHandlers.length - 1) * thumbScrollPercentage);
        goToImageByIndex(thumbIndex);
        cardsContainer.scrollLeft = thumbScrollPercentage * (cardsContainer.scrollWidth - cardsContainer.clientWidth);
        arrowStatusByIndex(currentThumbnail);
    }
    cardsContainer.addEventListener('scroll', updateThumbPosition);

    thumb.addEventListener('mousedown', function (event) {
        event.preventDefault();
        const startX = event.clientX;
        const thumbStartLeft = thumb.offsetLeft;
        function onMouseMove(event) {
            const deltaX = event.clientX - startX;
            const newThumbLeft = Math.min(track.offsetWidth - thumb.offsetWidth, Math.max(0, thumbStartLeft + deltaX));
            thumb.style.left = newThumbLeft + 'px';
            scrollContent();
        }
        function onMouseUp() {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        }
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    });
};

const displayVehicleChangeModal = () => {
    const rootDiv = document.getElementsByClassName('lid-content__back-wrapper')[0];

    // Vehicle component requires these props to be present, even if empty.
    const preselectedVehicle = lidData;
    const vehicleSelectedError = {};
    const vehicleSelected = {};

    render(
        <VehicleButton
            preselectedVehicle={preselectedVehicle}
            vehicleSelectedError={vehicleSelectedError}
            vehicleSelected={vehicleSelected}
            updateVehicleSelected={updateVehicleSelected}
            basePath=""
            onSubmitFunc={initLID}
            lidData={lidData}
            type="back"
        />,
        rootDiv
    );
};

const displayVehicleChangeModalButton = () => {
    const rootDiv = document.getElementsByClassName('lid-content__no-tire__action')[0];

    // Vehicle component requires these props to be present, even if empty.
    const preselectedVehicle = lidData;
    const vehicleSelectedError = {};
    const vehicleSelected = {};
    render(
        <VehicleButton
            preselectedVehicle={preselectedVehicle}
            vehicleSelectedError={vehicleSelectedError}
            vehicleSelected={vehicleSelected}
            updateVehicleSelected={updateVehicleSelected}
            basePath=""
            onSubmitFunc={initLID}
            lidData={lidData}
            type="button"
        />,
        rootDiv
    );
};

const hideNoLIDContent = () => {
    tabTopWidget.style.display = 'none';
    tabNavWidget.classList.add('hide');
    tabContentWidget.style.display = 'none';
};

const showNoLIDContent = () => {
    tabTopWidget.style.display = 'inline-block';
    tabNavWidget.classList.remove('hide');
    tabContentWidget.style.display = 'inline-block';
};

const showLIDContent = () => {
    lidContent.style.display = 'block';
};

const hideLIDContent = () => {
    lidContent.style.display = 'none';
};

const onAppointmentButtonClick = () => {
    setAdobeDataLayer({
        event: 'pt_widget',
        event_type: 'cta_click',
        cta: 'schedule appointment'
    });
    window.location.href = '/appointment/schedule-appointment/?tire-quote=true';
};

const onTiresButtonClick = () => {
    setAdobeDataLayer({
        event: 'pt_widget',
        event_type: 'cta_click',
        cta: 'see tires that fit'
    });

    window.location.href = getVehicleSearchURL(lidData.year, lidData.make, lidData.model, lidData.trim);
};

const onNextButtonClick = (evt) => {
    scrollToNextPage();
    setAdobeDataLayer({
        event: 'pt_widget',
        event_type: 'interaction',
        interaction: 'carousel navigation'
    });
};

const onPrevButtonClick = (evt) => {
    scrollToPrevPage();
    setAdobeDataLayer({
        event: 'pt_widget',
        event_type: 'interaction',
        interaction: 'carousel navigation'
    });
};

const onCardClick = (card, index) => {
    const averageRating = card.querySelector('.average-rating')?.textContent;
    const reviewCountElement = card.querySelector('.review-count')?.textContent;
    const reviewCount = reviewCountElement ? reviewCountElement.replace(/[()]/g, '') : '';
    if (tiresNearYou && Array.isArray(tiresNearYou) && tiresNearYou.length > index) {
        const productID = tiresNearYou[index].article;
        setAdobeDataLayer({
            event: 'pt_widget',
            event_type: 'cta_click',
            cta: 'tire details',
            scoreNumber: averageRating,
            numberOfReviews: reviewCount,
            productID: productID
        });
    }
};

const onContainerWheel = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    return false;
};

const addEvents = (data) => {
    backButton.addEventListener('click', resetSelects);

    changeVehicleButton.addEventListener('click', resetSelects);

    tiresButton.addEventListener('click', onTiresButtonClick);

    appointmentButton.addEventListener('click', onAppointmentButtonClick);

    nextButton.addEventListener('click', onNextButtonClick);
    prevButton.addEventListener('click', onPrevButtonClick);

    let cardClickHandler = lidContent.querySelectorAll('.lid-content__card');

    cardClickHandler.forEach((card, index) => {
        card.addEventListener('click', () => onCardClick(card, index));
    });

    cardsContainer.addEventListener('wheel', onContainerWheel);

    cardsContainer.addEventListener('touchstart', startTouch, false);

    cardsContainer.addEventListener('touchmove', moveTouch, false);
};

const removeEvents = () => {
    backButton.removeEventListener('click', resetSelects);

    changeVehicleButton.removeEventListener('click', resetSelects);

    tiresButton.removeEventListener('click', onTiresButtonClick);

    appointmentButton.removeEventListener('click', onAppointmentButtonClick);

    nextButton.removeEventListener('click', onNextButtonClick);
    prevButton.removeEventListener('click', onPrevButtonClick);

    let cardClickHandler = lidContent.querySelectorAll('.lid-content__card');

    cardClickHandler.forEach((card, index) => {
        card.removeEventListener('click', () => onCardClick(card, index));
    });

    cardsContainer.removeEventListener('wheel', onContainerWheel);

    cardsContainer.removeEventListener('touchstart', startTouch, false);

    cardsContainer.removeEventListener('touchmove', moveTouch, false);
};

const resetSelects = () => {
    let zipcode = widget.querySelectorAll('#zip');
    setAdobeDataLayer({
        event: 'pt_widget',
        event_type: 'interaction',
        interaction: 'change vehicle'
    });
    showNoLIDContent();
    hideLIDContent();
    instanceInit(vehicleLookupElement);
    document.getElementById('year').value = '';
    document.getElementById('make').value = '';
    document.getElementById('model').value = '';
    document.getElementById('submodel').value = '';
    document.getElementById('make').setAttribute('disabled', 'true');
    document.getElementById('make').parentElement.classList.remove('enable');
    document.getElementById('model').setAttribute('disabled', 'true');
    document.getElementById('model').parentElement.classList.remove('enable');
    document.getElementById('submodel').setAttribute('disabled', 'true');
    document.getElementById('submodel').parentElement.classList.remove('enable');
    zipcode.length > 0 &&
        zipcode.forEach((zip) => {
            zip.value = '';
            zip.classList.remove('enable');
        });
};

const startTouch = (e) => {
    initialX = e.touches[0].clientX;
};

const moveTouch = (e) => {
    if (initialX === null) {
        return;
    }

    let currentX = e.touches[0].clientX;
    if (initialX - currentX > 0) {
        //swipe left
        // console.log("swipe left on cards");
        scrollToNextPage();
    } else {
        //swipe right
        // console.log("swipe right on cards");
        scrollToPrevPage();
    }

    initialX = null;
    initialX = null;

    e.preventDefault();
};

const setThumbPositionByIndex = (index) => {
    thumb.style.left = `${index * thumbWidth}%`;
    thumb.style.width = `${thumbWidth}%`;
};
const arrowStatusByIndex = (thumbnailIndex) => {
    if (thumbnailIndex === thumbnailsHandlers.length - 1) {
        nextButton.classList.add('inactive');
    } else {
        nextButton.classList.remove('inactive');
    }
    if (thumbnailIndex === 0) {
        prevButton.classList.add('inactive');
    } else {
        prevButton.classList.remove('inactive');
    }
};

const goToImageByIndex = (thumbnailIndex) => {
    thumbnailsHandlers.forEach((thumbnailsHandler) => {
        thumbnailsHandler.classList.remove('active');
    });
    const thumbnailElement = thumbnailsHandlers.item(thumbnailIndex);
    thumbnailElement.classList.add('active');
    const href = thumbnailElement.getAttribute('id');
    const elementToScroll = document.getElementById(href);
    elementToScroll.scrollIntoView({ block: 'nearest', behavior: 'smooth', inline: 'start' });
    setThumbPositionByIndex(thumbnailIndex);
};

function scrollToNextPage() {
    const percentage = scrollPercentage();
    const nextIndex = Math.round((thumbnailsHandlers.length - 1) * (percentage / 100)) + 1;

    if (nextIndex >= thumbnailsHandlers.length) {
        // Do nothing
    } else {
        thumbnailIndex = nextIndex;
        goToImageByIndex(thumbnailIndex);
    }
    arrowStatusByIndex(thumbnailIndex);
}
function scrollToPrevPage() {
    const percentage = scrollPercentage();
    const prevIndex = Math.round((thumbnailsHandlers.length - 1) * (percentage / 100)) - 1;

    if (prevIndex < 0) {
        // Do nothing
    } else {
        thumbnailIndex = prevIndex;
        goToImageByIndex(thumbnailIndex);
    }

    arrowStatusByIndex(thumbnailIndex);
}

const displayVehicleData = (data) => {
    vehicleText.innerHTML = `${data.year} ${data.make} ${data.model} ${data.trim}`;
    if (data.username && data.vehicleNickName) {
        vehicleNickText.innerHTML = `${data.vehicleNickName}`;
    }
};

const displayTiresNearYouData = (data) => {
    lidContent.querySelector('.lid-content__tires-near-you').style.display = 'block';
    lidContent.querySelector('.lid-content__no-tire').style.display = 'none';
    tiresButton.removeAttribute('disabled');
    let displayString = '';

    if (Array.isArray(data) && data.length > 0) {
        // If the data is greater than the max cards, we need to slice the data to only show the max cards.
        const tireList = data.length > maxCards ? data.slice(0, maxCards) : data;

        tireList.forEach((tireData, index) => {
            displayString += tireCardTemplate(tireData, index);
        });

        setAdobeDataLayer({
            event: 'pt_widget',
            event_type: 'impression',
            results: 'true'
        });
    }

    if (data.length === 1) {
        cardsContainer.classList.add('no-scroll');
        cardsContainer.parentElement.classList.add('no-scroll');
    } else {
        cardsContainer.classList.remove('no-scroll');
        cardsContainer.parentElement.classList.remove('no-scroll');
    }

    if (data.length > maxCards) {
        displayString += seeAllTiresCardTemplate(lidData, maxCards + 1);
    }

    cardsContainer.innerHTML = displayString;

    reviewsReactAppLoader(store);

    if (tireDetailPageSwitch()) {
        reviewsReduxLoaderV2(store);
    } else {
        pubsub.publish(topics.CREATE_REVIEWS, [
            Array.from(document.querySelectorAll('.tire-service-widget-v3 .reviews-app'))
        ]);
    }

    thumbnailsHandlers = lidContent.querySelectorAll('.lid-content__card');

    if (thumbnailsHandlers.length > 0) {
        thumbWidth = 100 / thumbnailsHandlers.length;
    }

    setThumbPositionByIndex(thumbnailIndex);
};

const formatPhoneNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.toString();
    // Remove any non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    // Check if the number of digits is valid
    if (digitsOnly.length < 10) {
        return 'Invalid phone number';
    }
    // Extract the area code, prefix, and line number using capture groups in a regular expression
    const formattedNumber = digitsOnly.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1)$2-$3');
    return formattedNumber;
};

const displayNoTires = (storeNumber) => {
    const noTireCotent = lidContent.querySelector('.lid-content__no-tire');
    tiresButton.setAttribute('disabled', true);
    if (!lidContent || !noTireCotent) {
        console.error('UNEXPECTED: missing elements in displayNoTires');
        return;
    }
    lidContent.querySelector('.lid-content__tires-near-you').style.display = 'none';
    noTireCotent.style.display = 'block';
    if (storeNumber) {
        noTireCotent.querySelector('.lid-content__no-tire__store span').innerHTML =
            '<button onClick=window.location.href="tel:' +
            storeNumber.split('.').join('') +
            '">' +
            formatPhoneNumber(storeNumber) +
            '</button>';
    }
    setAdobeDataLayer({
        event: 'pt_widget',
        event_type: 'impression',
        results: 'false'
    });
};

const instanceInit = async (rootEl) => {
    /*
        Three vehicleTypes query string options:
                &vehicleType=tce
                    -- for ecom vehicle lookups for year,make,model
                &vehicleType=main
                    -- for vehicle lookups for year,make,model
                &vehicleType=aux
                    -- for vehicle engine look up
                    -- for reference. not currently used
    */
    const instanceProps = {
        isWidget: rootEl.parentElement.classList.contains('tire-service-widget-v3'),
        isDataInit: rootEl.dataset.dataInit == false ? false : true,
        isBySize: rootEl.classList.contains('size-bar'),
        isByVehicle: rootEl.classList.contains('vehicle-bar'),
        defaultVehicleType: window.isEcom && window?.siteProfile?.vehicles?.tce ? 'tce' : 'main',
        closeTabContentEl: rootEl.querySelector('.close-tab-content')
    };

    window.addEventListener('load', (evt) => componentInit(rootEl, instanceProps));

    pubsub.subscribe('schedule_appointment_widget_reset', (evt, currentQuery) => widgetReset(rootEl, instanceProps));
    //set vehicle from my-garage
    pubsub.subscribe('My-GARAGE.setCurrentVehicle', async () => {
        await initLID();
    });

    pubsub.subscribe('My-Store.setZip', (zip) => {
        const zipEl = rootEl.querySelector('input[name=zip]');
        if (zipEl) {
            zipEl.value = zip;
        }
    });

    window.localStorage.removeItem('willThisFitSubmitted');

    const tooltipEls = rootEl.querySelectorAll('.tooltip');
    [...tooltipEls].forEach((tooltip) => {
        createToolTip(tooltip);
    });

    await initLID();

    displayVehicleChangeModal();
    displayVehicleChangeModalButton();
};

const nextAll = (element) => {
    const nextElements = [];
    let nextElement = element;

    while (nextElement.nextElementSibling) {
        nextElements.push(nextElement.nextElementSibling);
        nextElement = nextElement.nextElementSibling;
    }

    return nextElements;
};

const enableSelect = (select) => {
    select.removeAttribute('disabled');
    select.parentElement.classList.add('enable');
};

const disableSelect = (select) => {
    select.setAttribute('disabled', 'true');
    select.parentElement.classList.remove('enable');
    select.selectedIndex = null;
};

const servicesHandler = (evt, rootEl, props) => {
    const { isWidget, defaultVehicleType, selects } = props;
    let sel = evt.currentTarget;
    let form = sel.parentNode.closest('form');
    let _name = sel.getAttribute('name');
    let _service_type = sel.closest('[data-service-type]').dataset.serviceType || defaultVehicleType;

    if (!form) {
        console.error('UNEXPECTED: missing form in servicesHandler');
        return;
    }
    const elCache = {
        make: rootEl.querySelector('[name=make]'),
        model: rootEl.querySelector('[name=model]'),
        submodel: rootEl.querySelector('[name=submodel]'),
        aspectRatio: rootEl.querySelector('[name=aspectRatio]'),
        rimSize: rootEl.querySelector('[name=rimSize]')
    };

    if (_name != 'tpms') {
        // this introduced conflicts between tires and alignment forms in the widget
        // and would disable enabled fields and submit button in tires as the code would
        // execute for the alignment form

        let nextAllEls = nextAll(sel.closest('.col'));

        [...nextAllEls].forEach((nextEl) => {
            let selectEl = nextEl.querySelector('select');
            let zipEls = nextEl.querySelectorAll('input[name=zip]');
            let submitEl = nextEl.querySelector('button[type=submit]');
            let enabledEl = nextEl.querySelector('[class*=enable]');

            if (selectEl) {
                selectEl.selectedIndex = null;
                selectEl.disabled = true;
            }

            [...zipEls].forEach((zipEl) => {
                zipEl.disabled = true;
            });
        });
    }

    //selected Option
    let option = [...sel.children].filter((child) => {
        return child.selected;
    });
    option = option.length ? option[0] : option;

    switch (_name) {
        case 'year':
            disableSelect(elCache.make);
            disableSelect(elCache.model);
            disableSelect(elCache.submodel);

            if (!isWidget) {
                getMakes(evt, rootEl, _service_type);
            } else {
                if (defaultVehicleType != 'main') {
                    getMakes(evt, rootEl, defaultVehicleType);
                    disableSelect(elCache.model);
                    disableSelect(elCache.submodel);
                } else {
                    getMakes(evt, rootEl, 'main');
                }

                disableSelect(elCache.make);
                disableSelect(elCache.model);
                disableSelect(elCache.submodel);
            }
            break;
        case 'make':
            if (!isWidget) {
                getModels(evt, rootEl, _service_type);
            } else {
                if (defaultVehicleType != 'main') {
                    getModels(evt, rootEl, defaultVehicleType);
                    enableSelect(elCache.model);
                    disableSelect(elCache.submodel);
                } else {
                    getModels(evt, rootEl, 'main');
                }

                disableSelect(elCache.model);
                disableSelect(elCache.submodel);
            }
            break;
        case 'model':
            if (!isWidget) {
                if (_service_type == 'main') {
                    getSubmodels(evt, rootEl, _service_type);
                }

                if (_service_type == 'tce') {
                    getSubmodels(evt, rootEl, _service_type);
                }

                if (_service_type == 'aux') {
                    getEngines(evt, rootEl, _service_type);
                }
            } else {
                if (defaultVehicleType != 'main') {
                    getSubmodels(evt, rootEl, defaultVehicleType);
                    enableSelect(elCache.submodel);
                } else {
                    getSubmodels(evt, rootEl, 'main');
                }

                disableSelect(elCache.submodel);
            }
            break;
        case 'submodel': {
            settings.data.byVehicle[_service_type].trim = option.value;

            setTPMS(rootEl, option?.dataset?.tpms, defaultVehicleType);

            const formZipEls = document.querySelectorAll('input[name=zip]');
            [...formZipEls].forEach((formZipEl) => {
                let displayType = window.getComputedStyle(formZipEl).display;
                if (displayType == 'inline-block' || displayType == 'block') {
                    formZipEl.disabled = false;
                    formZipEl.classList.add('enable');
                }
            });
            break;
        }
        case 'engine': {
            settings.data.byVehicle[_service_type].engine = option.value;

            // BLANK ENGINE
            if (option && option.value == '') {
                break;
            }

            const formZipDisabledEl = form.querySelector('input[name=zip]');
            formZipDisabledEl.classList.add('enable');
            formZipDisabledEl.disabled = false;
            break;
        }
        case 'crossSection':
            getAspectRatios(evt, rootEl);
            disableSelect(elCache.aspectRatio);
            disableSelect(elCache.rimSize);
            break;
        case 'aspectRatio':
            getRimSizes(evt, rootEl);
            disableSelect(elCache.rimSize);
            break;
        case 'rimSize': {
            settings.data.byTire.rimSize = sel.value;

            // BLANK RIMSIZE
            if (option.value == '') {
                break;
            }

            const zipDisabledEl = form.querySelector('input[name=zip]');
            if (zipDisabledEl && form) {
                zipDisabledEl.classList.add('enable');
                zipDisabledEl.disabled = false;
                form.querySelector('button[type=submit]').disabled = false;
            }
            break;
        }
    }

    // _service_type filter ensures we only change the select boxes of the same service type otherwise it breaks.
    selects.forEach((select) => {
        let expectedSelect = select.closest(`[data-service-type='${_service_type}'] [name='${_name}']`);
        expectedSelect && (expectedSelect.value = sel.value);
    });

    // Final submit button enable/disable
    form.querySelector('button[type=submit]').disabled = !isFormValid(form);
};

const isFormValid = (form) => {
    let isValid = true;
    const selects = form.querySelectorAll('select');
    const zips = form.querySelectorAll('input[name*=zip]');
    const validate = (value) => {
        return value !== '' && value !== '--Select--';
    };

    [...selects].forEach((select) => {
        if (!validate(select.value)) {
            isValid = false;
        }
    });

    [...zips].forEach((zip) => {
        if (!testZip(zip.value)) {
            isValid = false;
        }
    });

    return isValid;
};
const componentInit = (rootEl, props) => {
    const { isDataInit, isBySize, isByVehicle, isWidget, defaultVehicleType, closeTabContentEl } = props;

    const selects = rootEl.querySelectorAll('select');
    const zips = rootEl.querySelectorAll('input[name*=zip]');

    let profile = getProfile(defaultVehicleType);

    if (!isDataInit) {
        // test of year value being blank prevented vehicle modal on quote page from populating
        // if the BOSS has a vehicle in profile but a quote based on tire size
        //if (siteProfile && siteProfile.vehicles[defaultVehicleType].year == '') {
        if (window.siteProfile) {
            if (isByVehicle || (!isByVehicle && !isBySize)) {
                getYears(rootEl, rootEl.querySelector('[data-service-type]').dataset.serviceType);
            }
        }
    } else {
        if (!isWidget) {
            if (isByVehicle || (!isByVehicle && !isBySize)) {
                getYears(rootEl, rootEl.querySelector('[data-service-type]').dataset.serviceType);
            }
        } else {
            if (isByVehicle || (!isByVehicle && !isBySize)) {
                getYears(rootEl, 'main');
                getYears(rootEl, 'aux');
                if (defaultVehicleType != 'main') {
                    getYears(rootEl, defaultVehicleType);
                }
            }
        }

        if (profile.vehicle.ymm) {
            setTPMS(rootEl, profile.vehicle.tpms, defaultVehicleType);
        }

        //isWidget = false;

        if ((!isByVehicle && !isBySize) || isBySize == true) {
            getCrossSections(rootEl, true);
        }
    }

    [...selects].forEach((selectEl) => {
        selectEl.disabled = true;
    });
    [...zips].forEach((zip) => {
        zip.disabled = true;
    });

    [...selects].forEach((selectEl) => {
        selectEl.addEventListener('change', (evt) =>
            servicesHandler(evt, rootEl, { isWidget, defaultVehicleType, selects })
        );
    });

    [...rootEl.querySelectorAll('form')].forEach((formEl) => {
        formEl.addEventListener('submit', async (evt) => {
            evt.preventDefault();

            let _el = evt.currentTarget;
            let _cta = _el?.querySelector('[data-cta]');
            let _serviceType = _el.dataset.serviceType || defaultVehicleType;
            let _veh = settings.data.byVehicle[_serviceType];

            let _isAjax = _el.dataset.ajax == true ? true : false;
            let _zip = _el?.querySelector('[name*=zip]').value.trim();
            let _mileage = _el?.querySelector('[name*=mileage]')?.value;

            if (_mileage) {
                setCookie('bsro.ui.mileage', parseInt(_mileage));
            }

            _veh.vehicleType = _serviceType;

            if (!_isAjax) {
                _redirect = _el.getAttribute('action') || window.location.pathname;
            }

            let _tmp_obj = serializeToObject(_el);
            let _obj;

            setAdobeDataLayer({
                event: 'service_schedule_info_entered',
                vehicle: {
                    year: _tmp_obj.year,
                    make: _tmp_obj.make,
                    model: _tmp_obj.model,
                    submodel: _tmp_obj.submodel,
                    mileage: _mileage
                }
            });

            if (_serviceType == 'aux') {
                _obj = {
                    year: _tmp_obj.year,
                    make: _tmp_obj.make,
                    model: _tmp_obj.model,
                    engine: _tmp_obj.engine,
                    vehicleType: 'aux'
                };
            } else {
                if (_cta.dataset.model == 'byVehicle') {
                    _obj = {
                        year: _tmp_obj.year,
                        make: _tmp_obj.make,
                        model: _tmp_obj.model,
                        trim: _tmp_obj.submodel,
                        tpms: _el.querySelector('[name=tpms]')?.value,
                        vehicleType: _serviceType
                    };
                    const vehicle = Object.assign({}, _obj, {
                        year: Array.isArray(_obj.year) ? _obj.year[0] : _obj.year,
                        make: _obj.make[0],
                        model: _obj.model[0],
                        trim: _obj.trim ? _obj.trim[0] : '',
                        engine: _obj.engine ? _obj.engine[0] : '',
                        odometer: '0',
                        nickname: '',
                        drivingCondition: ''
                    });
                    const newVehicle = new Vehicle(vehicle);
                    await vehicleRepository.setCurrentVehicle(newVehicle);
                    // 6988 - update vehicle url to direct to SEO url
                    if (_redirect === `/tires/search-results/` || _redirect.includes('/tires/vehicle/')) {
                        vehicleSearchURLs.map((item) => {
                            let oTrim = _obj.trim.length ? _obj.trim[0] : _obj.trim;
                            if (item.trim === oTrim) {
                                _redirect = item.url;
                            }
                        });
                        console.log('vehicleSearchURLs', {
                            vehicleSearchURLs: vehicleSearchURLs,
                            trim: _obj.trim,
                            _redirect
                        });
                    }
                } else {
                    _obj = {
                        crossSection: _tmp_obj.crossSection,
                        aspectRatio: _tmp_obj.aspectRatio,
                        rimSize: _tmp_obj.rimSize,
                        vehicleType: _serviceType
                    };
                    // 6988 - update tire size url to direct to SEO url
                    if (_redirect === `/tires/search-results-by-size/` || _redirect.includes('/tires/size/')) {
                        _redirect = `/tires/size/${_obj.rimSize}-inch/${_obj.crossSection}-${_obj.aspectRatio}-${_obj.rimSize}/`;
                        console.log('vehicleSearchURLs', {
                            _obj,
                            _redirect
                        });
                    }
                }
            }

            let _batteryTag = {
                eventName: 'battery.willthisfit.submit',
                attributes: {
                    engine: _obj.engine,
                    make: _obj.make,
                    model: _obj.model,
                    productID: window.location.hash.substr(1),
                    year: _obj.year,
                    zip: _zip
                }
            };

            let _willThisFitSubmitted = window.localStorage.getItem('willThisFitSubmitted') || false;

            if (!_willThisFitSubmitted && _cta.dataset.cta == 'battery.willthisfit.submit') {
                window.localStorage.setItem('willThisFitSubmitted', true);

                window._satellite.setVar('searchData', _batteryTag.attributes);
                window._satellite.track(_batteryTag.eventName);
            }

            // if the service type is main, update the tce value too if it matches.
            if (_serviceType === 'main') {
                await syncVehicleData('main', 'tce', _obj.year, _obj.make, _obj.model, _obj.trim);
            }

            // if the service type is tce, update the main value too if it matches.
            if (_serviceType === 'tce') {
                await syncVehicleData('tce', 'main', _obj.year, _obj.make, _obj.model, _obj.trim);
            }

            try {
                await getVehicleLookupApi(_cta.dataset.seturl, _obj, true);
                const myZip = window?.siteProfile?.location?.myZip.substr(0, 5);
                if (myZip != _zip) {
                    try {
                        const response = await setStoreByZip(_zip);
                        if (response.success === 'true') {
                            setLocalStorageItem('storeData', response);
                        }

                        while (zipCodeErrorEl.firstChild) {
                            zipCodeErrorEl.removeChild(zipCodeErrorEl.firstChild); // to empty the element.
                        }
                        if (_cta.dataset.cta == 'getvehiclemodal_addtocart') {
                            _obj['zip'] = _zip;
                            pubsub.publish('getvehiclemodal_addtocart', [evt, _obj]);
                        } else if (_cta.dataset.cta == 'battery.willthisfit.submit') {
                            if (_redirect) {
                                setTimeout(() => {
                                    window.location.href = _redirect;
                                }, 500);
                            }
                        } else if (_cta.dataset.cta == 'getvehiclemodal_scheduleappt') {
                            pubsub.publish('getvehiclemodal_scheduleappt', [evt, _obj]);
                        } else {
                            if (_redirect) {
                                if (response.success === 'true') {
                                    let storeDetails = false; // await getPinnacleStoreDetails(_zip, response);
                                    if (storeDetails) {
                                        /*
                                        publishZipCodeInterceptPrompt({
                                            redirect: _redirect,
                                            storeDetails: storeDetails
                                        });
                                        */
                                    } else {
                                        window.location.href = _redirect;
                                    }
                                } else {
                                    window.location.href = _redirect;
                                }
                            }
                        }
                    } catch (err) {
                        console.log('err', err);
                        let zipcodeErorMessage = 'Enter valid Zipcode';
                        if (zipCodeErrorEl.length) {
                            if (
                                Object.prototype.hasOwnProperty.call(_obj, 'aspectRatio') &&
                                Object.prototype.hasOwnProperty.call(_obj, 'crossSection') &&
                                Object.prototype.hasOwnProperty.call(_obj, 'rimSize')
                            ) {
                                widgetBySizeZipErrorEl.innerHTML = zipcodeErorMessage;
                            } else if (
                                Object.prototype.hasOwnProperty.call(_obj, 'year') &&
                                Object.prototype.hasOwnProperty.call(_obj, 'make') &&
                                Object.prototype.hasOwnProperty.call(_obj, 'model') &&
                                Object.prototype.hasOwnProperty.call(_obj, 'trim') &&
                                Object.prototype.hasOwnProperty.call(_obj, 'tpms')
                            ) {
                                if (typeof _obj.tpms == 'undefined') {
                                    sectionAlignmentZipErrorEl.innerHTML = zipcodeErorMessage;
                                } else {
                                    widgetByVehicleZipErrorEl.innerHTML = zipcodeErorMessage;
                                }
                            } else if (
                                Object.prototype.hasOwnProperty.call(_obj, 'year') &&
                                Object.prototype.hasOwnProperty.call(_obj, 'make') &&
                                Object.prototype.hasOwnProperty.call(_obj, 'model') &&
                                Object.prototype.hasOwnProperty.call(_obj, 'engine')
                            ) {
                                searchBatteryZipErrorEl.innerHTML = zipcodeErorMessage;
                            }
                        }
                        if (hVehicleFilterZipErrorEl.length) {
                            hVehicleFilterZipErrorEl.innerHTML = zipcodeErorMessage;
                        }
                    }
                } else {
                    while (zipCodeErrorEl.firstChild) {
                        zipCodeErrorEl.removeChild(zipCodeErrorEl.firstChild); // to empty the element.
                    }
                    if (_cta.dataset.cta == 'getvehiclemodal_addtocart') {
                        _obj['zip'] = profile.zip;
                        pubsub.publish('getvehiclemodal_addtocart', [evt, _obj]);
                    } else if (_cta.dataset.cta == 'battery.willthisfit.submit') {
                        if (_redirect) {
                            setTimeout(function () {
                                window.location.href = _redirect;
                            }, 500);
                        }
                    } else if (_cta.dataset.cta == 'getvehiclemodal_scheduleappt') {
                        pubsub.publish('getvehiclemodal_scheduleappt', [evt, _obj]);
                    } else {
                        if (_redirect) {
                            console.log('redirect:', { _redirect });
                            window.location.href = _redirect;
                        }
                    }
                }
            } catch (err) {
                console.log('err', err);
            }
        });
    });

    mobileTabNav?.addEventListener('click', (evt) => {
        evt.preventDefault();
        widgetTabNav.slideToggle(400);
    });

    rootEl.querySelector('input[name*=mileage]')?.addEventListener('keyup', (evt) => {
        let el = evt.currentTarget;

        let _val = el.value;
        let _initVal = _val;

        _val = _val.replace(/[^0-9]/g, '');

        if (_initVal != _val) {
            el.value = _val;
        }
    });
    [...zips].forEach((zip) => {
        let _startZip;
        if (window.siteProfile.location.myZip) {
            _startZip = window.siteProfile.location.myZip;
        } else if (
            window.siteProfile.location.autoZip &&
            window.siteProfile.location.autoZip != window.AEM.defaultZip
        ) {
            _startZip = window.siteProfile.location.autoZip;
        }

        if (_startZip) {
            zip && (zip.value = _startZip);
        }
        zip.addEventListener('input', (evt) => {
            const el = evt.currentTarget;
            const form = el.parentNode.closest('form');
            form.querySelector('button[type=submit]').disabled = !isFormValid(form);
        });
    });

    widgetZipEl && (widgetZipEl.disabled = false);

    const tireServiceWidget = rootEl.closest('.tire-service-widget-v3');
    const tabNavReskinLinks = tireServiceWidget?.querySelectorAll('.tab-nav.reskin a') || [];
    const topNavTabs = topNav?.querySelectorAll('a') || [];

    [...topNavTabs].forEach((topNavTab) => {
        topNavTab.addEventListener('click', (evt) => {
            evt.preventDefault();

            let target = evt.currentTarget;
            let tabcontents = [...tireServiceWidget.children].filter((child) => {
                return child.classList.contains('tab-content');
            });

            [...tabcontents].forEach((tabcontent) => {
                [...tabcontent.children].forEach((child) => {
                    if (child.nodeName == 'SECTION') {
                        child.classList.remove('active');
                    }
                });
            });

            target.parentNode.closest('.tire-service-widget-v3').classList.remove('show-tab-content');

            // if services
            if (tireServiceWidget.classList.contains('get-services')) {
                [...tabcontents].forEach((tabcontent) => {
                    tabcontent.querySelector('.tire-search-widget').classList.add('active');
                });
            } else {
                [...tabcontents].forEach((tabcontent) => {
                    tabcontent.querySelector('section').classList.add('active');
                });
            }

            [...topNav.querySelectorAll('li')].forEach((topNavLi) => {
                topNavLi.classList.remove('active');
            });

            tireServiceWidget.classList.remove('shop-tires', 'get-services');

            tireServiceWidget.classList.add(target.parentNode.getAttribute('class')?.trim().split(' '));

            target.parentNode.classList.add('active');
        });
    });

    [...tabNavReskinLinks].forEach((tabNavReskinLink) => {
        tabNavReskinLink.addEventListener('click', (evt) => {
            evt.preventDefault();

            tireServiceWidget.classList.add('show-tab-content');
        });
    });

    closeTabContentEl?.addEventListener('click', (evt) => {
        evt.preventDefault();

        let el = evt.currentTarget;

        el.parentNode.closest('.tire-service-widget-v3').classList.remove('show-tab-content');

        tabNavReskinEl?.querySelector('li').classList.remove('active');
    });
};

const getYears = async (rootEl, vehicleType) => {
    let profile = getProfile(vehicleType);
    let _obj = { vehicleType };

    settings.data.byVehicle[vehicleType].vehicleType = vehicleType;
    try {
        const response = await getVehicleLookupApi(config.serviceUrl.years, _obj);
        // ensure result is valid before proceeding
        if (response.success !== 'true' || parseInt(response.status) !== 200) {
            return;
        }

        const data = response.data;
        let years = data.years || data.year;
        let options = getOptions(years);
        let _find = `[data-service-type=${vehicleType}] select[name=year]`;
        let selects = rootEl.querySelectorAll(_find);

        // add options to page
        [...selects].forEach((select) => {
            select.insertAdjacentHTML('beforeend', options);
            enableFormItem(select, true);

            // see if we can get makes.
            if (isProfileMatch(years, `${profile.vehicle.year}`)) {
                select.value = profile.vehicle.year;
                settings.data.byVehicle[vehicleType].year = profile.vehicle.year;
                getMakes(null, rootEl, vehicleType);
            }
        });
    } catch (err) {
        console.log(err);
    }
};

const getMakes = async (evt, rootEl, vehicleType) => {
    let profile = getProfile(vehicleType);
    let el;
    let _obj;

    settings.data.byVehicle[vehicleType].vehicleType = vehicleType;

    if (evt != null) {
        el = evt.currentTarget;
        settings.data.byVehicle[vehicleType].year = el.value;
    }

    _obj = {
        year: settings.data.byVehicle[vehicleType].year,
        vehicleType: settings.data.byVehicle[vehicleType].vehicleType
    };

    try {
        const response = await getVehicleLookupApi(config.serviceUrl.makes, _obj);
        // ensure result is valid before proceeding
        if (response.success !== 'true' || parseInt(response.status) !== 200) {
            return;
        }
        const data = response.data;
        let makes = data.makes;
        let options = getOptions(makes);
        let _find = '[data-service-type=' + vehicleType + '] select[name=make]';
        let selects = rootEl.querySelectorAll(_find);

        [...selects].forEach((select) => {
            //Remove all otions except default
            [...select.children].forEach((child) => {
                child.value != '' && child.remove();
            });

            select.insertAdjacentHTML('beforeend', options);
            enableFormItem(select, true);

            // see if we can get models.
            if (evt == null && isProfileMatch(makes, profile.vehicle.make)) {
                let normVal = normalizeWidgetValue(profile.vehicle.make);
                let selectedMake = select.querySelector(`option[data-optval='${normVal}']`).value;

                select.value = selectedMake;

                settings.data.byVehicle[vehicleType].make = selectedMake;

                getModels(null, rootEl, vehicleType);
            }
        });
    } catch (err) {
        console.log('err', err);
    }
};

const getModels = async (evt, rootEl, vehicleType) => {
    let profile = getProfile(vehicleType);
    let el;
    let _obj;

    settings.data.byVehicle[vehicleType].vehicleType = vehicleType;

    if (evt != null) {
        el = evt.currentTarget;
        settings.data.byVehicle[vehicleType].make = el.value;
    }

    _obj = {
        year: settings.data.byVehicle[vehicleType].year,
        make: settings.data.byVehicle[vehicleType].make,
        vehicleType: settings.data.byVehicle[vehicleType].vehicleType
    };

    try {
        const response = await getVehicleLookupApi(config.serviceUrl.models, _obj);
        // ensure result is valid before proceeding
        if ((response.success !== 'true' && response.success !== true) || parseInt(response.status) !== 200) {
            return;
        }
        const data = response.data;
        let models = data.models;
        let mlen = models.length;
        let options = getOptions(models);
        let selects = rootEl.querySelectorAll(`[data-service-type=${vehicleType}] select[name=model]`);

        [...selects].forEach((select) => {
            //Remove all otions except default
            [...select.children].forEach((child) => {
                child.value != '' && child.remove();
            });

            select.insertAdjacentHTML('beforeend', options);
            enableFormItem(select, true);

            // Auto-select if only one element
            if (evt && mlen == 1) {
                window.theselect = select;
                let singleEl = [...select.children].find((child) => {
                    return child.value != '';
                });
                select.value = singleEl.value;
                select.dispatchEvent(new Event('change'));
            }

            // see if we can get trims or engines.
            if (evt == null && isProfileMatch(models, profile.vehicle.model)) {
                let normVal = normalizeWidgetValue(profile.vehicle.model);

                let selectedModel = select.querySelector(`option[data-optval='${normVal}']`).value;

                select.value = selectedModel;

                settings.data.byVehicle[vehicleType].model = selectedModel;

                if (vehicleType == 'aux') {
                    getEngines(null, rootEl, vehicleType);
                } else {
                    getSubmodels(null, rootEl, vehicleType);
                }
            }
        });
    } catch (err) {
        console.log('err', err);
    }
};

const getSubmodels = async (evt, rootEl, vehicleType) => {
    let profile = getProfile(vehicleType);
    let el;
    let _obj;

    settings.data.byVehicle[vehicleType].vehicleType = vehicleType;

    if (evt != null) {
        el = evt.currentTarget;
        settings.data.byVehicle[vehicleType].model = el.value;
    }

    // Since we are chaining the service calls, we know we can rely on the lookup data as clean data for the next service call.
    _obj = {
        year: settings.data.byVehicle[vehicleType].year,
        make: settings.data.byVehicle[vehicleType].make,
        model: settings.data.byVehicle[vehicleType].model,
        vehicleType: settings.data.byVehicle[vehicleType].vehicleType
    };

    try {
        const response = await getVehicleLookupApi(config.serviceUrl.submodels, _obj);
        // ensure result is valid before proceeding
        if (response.success !== 'true' || parseInt(response.status) !== 200) {
            return;
        }

        const data = response.data;
        let submodels = data.trims;
        let slen = submodels.length;
        let options = getOptions(submodels, 'trim');
        let selects = rootEl.querySelectorAll('[data-service-type=' + vehicleType + '] select[name=submodel]');

        // 6988 - Generate list of friendly urls from trim results for SEO friendly search url /tires/vehicle/make/model/year/trim/
        const { makeFriendlyName = '', modelFriendlyName = '', year } = data;
        const vehicleSearchYMM = `/tires/vehicle/${makeFriendlyName}/${modelFriendlyName}/${year}/`;
        vehicleSearchURLs = submodels.map((item) => {
            const url = item.friendlyName ? `${vehicleSearchYMM}${item.friendlyName}/` : '/tires/search-results/';
            return { trim: item.trim, url };
        });

        [...selects].forEach((select) => {
            //Remove all otions except default
            [...select.children].forEach((child) => {
                child.value != '' && child.remove();
            });

            select.insertAdjacentHTML('beforeend', options);
            enableFormItem(select, true);

            // Auto-select if only one element
            if (evt && slen == 1) {
                window.theselect = select;
                let singleEl = [...select.children].find((child) => {
                    return child.value != '';
                });
                select.value = singleEl.value;
                select.dispatchEvent(new Event('change'));
            }

            if (evt == null && isProfileMatch(submodels, profile.vehicle.trim, 'trim')) {
                let normVal = normalizeWidgetValue(profile.vehicle.trim);

                let selectedTrim = select.querySelector(`option[data-optval='${normVal}']`).value;

                let disabledZipEl = select.parentNode.closest('form').querySelector('input[name=zip]:disabled');
                if (disabledZipEl) {
                    disabledZipEl.classList.add('enable');
                    disabledZipEl.disabled = false;
                }
                select.value = selectedTrim;
            }
        });
    } catch (err) {
        console.log('err', err);
    }
};

const getEngines = async (evt, rootEl, vehicleType) => {
    let el;
    let _obj;

    settings.data.byVehicle[vehicleType].vehicleType = vehicleType;

    if (evt != null) {
        el = evt.currentTarget;
        settings.data.byVehicle[vehicleType].model = el.value;
    }

    _obj = {
        year: settings.data.byVehicle[vehicleType].year,
        make: settings.data.byVehicle[vehicleType].make,
        model: settings.data.byVehicle[vehicleType].model,
        vehicleType: settings.data.byVehicle[vehicleType].vehicleType
    };

    try {
        const response = await getVehicleLookupApi(config.serviceUrl.engines, _obj);
        // ensure result is valid before proceeding
        if (response.success !== 'true' || parseInt(response.status) !== 200) {
            return;
        }

        const data = response.data;
        let engines = data.engines;
        let elen = engines.length;
        let options = getOptions(engines);
        let selects = rootEl.querySelectorAll(`[data-service-type=${vehicleType}] select[name=engine]`);

        [...selects].forEach((select) => {
            //Remove all otions except default
            [...select.children].forEach((child) => {
                child.value != '' && child.remove();
            });

            select.insertAdjacentHTML('beforeend', options);
            enableFormItem(select, true);

            // Auto-select if only one element
            if (evt && elen == 1) {
                window.theselect = select;

                let singleEl = [...select.children].find((child) => {
                    return child.value != '';
                });
                select.value = singleEl.value;
                select.dispatchEvent(new Event('change'));
            }

            if (evt == null && window.siteProfile && window.siteProfile.vehicles[vehicleType].engine) {
                select.value = window.siteProfile.vehicles[vehicleType].engine.replace(/[+]/g, ' ');
                let formEl = select.parentNode.closest('form').querySelector('button[type=submit]:disabled');
                formEl && (formEl.disabled = false);
                let disabledZipEl = select.parentNode.closest('form').querySelector('input[name=zip]:disabled');
                if (disabledZipEl) {
                    disabledZipEl.classList.add('enable');
                    disabledZipEl.disabled = false;
                }
            }
        });
    } catch (err) {
        console.log('err', err);
    }
};

const setTPMS = (rootEl, tpms, defaultVehicleType) => {
    let select = rootEl.querySelector('select[name=tpms]');

    settings.data.byVehicle[defaultVehicleType].tpms = tpms;
    if (select) {
        select.value = tpms;
        enableFormItem(select, true);
        select.disabled = false;
    }
};

const getCrossSections = async (rootEl, inInit = false) => {
    let profile = getSiteProfileTires();

    try {
        const response = await getVehicleLookupApi(config.serviceUrl.crossSection, {});

        // ensure result is valid before proceeding
        if (response.success !== 'true' || parseInt(response.status) !== 200) {
            return;
        }

        const data = response.data;
        let cross = data.cross;
        let clen = cross.length;
        let options = '';

        for (let i = 0; i < clen; i++) {
            options = `${options}<option value="${cross[i].value}">${cross[i].text}</option>`;
        }

        let select = rootEl.querySelector('select[name=crossSection]');
        if (select) {
            //Remove all otions except default
            [...select.children].forEach((child) => {
                child.value != '' && child.remove();
            });
            select.insertAdjacentHTML('beforeend', options);
            enableFormItem(select, true);

            // During initialization, select the cross section that matches the profile
            if (inInit && isTiresProfileMatch(cross, profile.cs)) {
                select.value = profile.cs;
                settings.data.byTire.crossSection = profile.cs;
                getAspectRatios(null, rootEl, inInit);
            }
        }
    } catch (err) {
        console.log('err', err);
    }
};

const getAspectRatios = async (evt, rootEl, inInit = false) => {
    let profile = getSiteProfileTires();

    if (evt != null) {
        let el = evt.currentTarget;
        settings.data.byTire.crossSection = el.value;
    }

    try {
        const response = await getVehicleLookupApi(config.serviceUrl.aspectRatio, settings.data.byTire);
        // ensure result is valid before proceeding
        if (response.success !== 'true' || parseInt(response.status) !== 200) {
            return;
        }

        const data = response.data;
        let aspect = data.aspect;
        let alen = aspect.length;
        let options = '';

        for (let i = 0; i < alen; i++) {
            options = `${options}<option value="${aspect[i].value}">${aspect[i].text}</option>`;
        }

        let select = rootEl.querySelector('select[name=aspectRatio]');
        //Remove all otions except default
        [...select.children].forEach((child) => {
            child.value != '' && child.remove();
        });
        select.insertAdjacentHTML('beforeend', options);
        enableFormItem(select, true);

        if (evt && alen == 1) {
            let singleEl = [...select.children].find((child) => {
                return child.value != '';
            });
            select.value = singleEl.value;
            select.dispatchEvent(new Event('change'));
        }

        // During initialization, select the aspect ratio that matches the profile
        if (inInit && isTiresProfileMatch(aspect, profile.ar)) {
            select.value = profile.ar;
            settings.data.byTire.aspectRatio = profile.ar;
            getRimSizes(null, rootEl, inInit);
        }
    } catch (err) {
        console.log('err', err);
    }
};

const getRimSizes = async (evt, rootEl, inInit = false) => {
    let profile = getSiteProfileTires();
    if (evt != null) {
        let el = evt.currentTarget;
        settings.data.byTire.aspectRatio = el.value;
    }
    try {
        const response = await getVehicleLookupApi(config.serviceUrl.rimSize, settings.data.byTire);
        // ensure result is valid before proceeding
        if (response.success !== 'true' || parseInt(response.status) !== 200) {
            return;
        }
        const data = response.data;
        let rim = data.rim;
        let rlen = rim.length;
        let options = '';

        for (let i = 0; i < rlen; i++) {
            options = `${options}<option value="${rim[i].value}">${rim[i].text}</option>`;
        }

        let select = rootEl.querySelector('select[name=rimSize]');
        //Remove all otions except default
        [...select.children].forEach((child) => {
            child.value != '' && child.remove();
        });
        select.insertAdjacentHTML('beforeend', options);
        enableFormItem(select, true);

        if (evt && rlen == 1) {
            let singleEl = [...select.children].find((child) => {
                return child.value != '';
            });
            select.value = singleEl.value;
            select.dispatchEvent(new Event('change'));
        }

        // During initialization, select the value that matches the profile
        if (inInit && isTiresProfileMatch(rim, profile.rs)) {
            select.value = profile.rs;
            settings.data.byTire.rimSize = profile.rs;
        }

        if (evt == null && profile && profile.rs) {
            let formEl = select.parentNode.closest('form').querySelector('button[type=submit]:disabled');
            formEl && (formEl.disabled = false);
            let disabledZipEl = select.parentNode.closest('form').querySelector('input[name=zip]:disabled');
            if (disabledZipEl) {
                disabledZipEl.classList.add('enable');
                disabledZipEl.disabled = false;
            }
        }
    } catch (err) {
        console.log('err', err);
    }
};

const enableFormItem = (item, enable) => {
    if (enable) {
        item.disabled = false;
        item.parentNode.classList.add('enable');
    } else {
        item.disabled = true;
        item.parentNode.classList.remove('enable');
    }
};

const serializeToObject = (el) => {
    return serializeForm(el);
};

const widgetReset = (rootEl, props) => {
    const { isWidget, isByVehicle, isBySize, defaultVehicleType } = props;
    if (!isWidget) {
        if (isByVehicle || (!isByVehicle && !isBySize)) {
            getYears(rootEl, rootEl.querySelector('[data-service-type]').dataset.serviceType);
        }
    } else {
        if (isByVehicle || (!isByVehicle && !isBySize)) {
            getYears(rootEl, 'main');
            getYears(rootEl, 'aux');

            if (defaultVehicleType != 'main') {
                getYears(rootEl, defaultVehicleType);
            }
        }
    }
};

/**
 * Syncs vehicle data between TCE to Main or vice versa
 * @param {string} fromType - the service type to copy from
 * @param {string} toType - the service type to merge into
 * @param {string} year - fromType vehicle year
 * @param {string} make - fromType vehicle make
 * @param {string} model - fromType vehicle model
 * @param {string} trim - fromType vehicle trim
 */

// TODO: move this to a shared location when refactoring this component.

const syncVehicleData = async (fromType, toType, year, make, model, trim) => {
    // Assume that year, make, model all match and just try to get a response on trim
    // then verify if trim matches.
    // If it does, sync the vehicle data.

    const requestVehicleTrims = async (year, make, model) => {
        let response = {};

        try {
            if (typeof year === 'undefined' || typeof make === 'undefined' || typeof model === 'undefined') {
                throw new Error('One or more required parameters (year, make, model) are undefined in Vehicle Lookup');
            }
            response = await getVehicleTrim(toType, year, make, model);
        } catch (err) {
            console.error(err);
        }

        return response.data || {};
    };

    const requestSetVehicle = async () => {
        try {
            await setVehicle(year, make, model, trim, null, toType);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const trimData = await requestVehicleTrims(year, make, model);
    let submodels = trimData?.trims || [];

    if (isProfileMatch(submodels, trim, 'trim')) {
        const hasVehicleSet = await requestSetVehicle();
        return hasVehicleSet;
    }

    return;
};

component({
    selector: '.vehicle-lookup-v3',
    init,
    instanceInit,
    name: 'VehicleLookupV3',
    canMutate: true,
    canReinit: false
});
