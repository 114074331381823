import { getAvailabilityForMultipleStores } from './getAvailabilityForMultipleStores';
import { showHours } from './showHours';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const previousDates = (evt, rootEl, props) => {
    const {
        previousDatesArrow,
        numDays,
        getDateSelectorIndex,
        setDateSelectorIndex,
        nextDatesArrows,
        addedServicesListArr,
        getStoresInfo,
        setStoresInfo,
        dateStoreStep,
        completeDateTimeSelection
    } = props;
    const idx = getDateSelectorIndex();
    const currentTarget = evt ? evt.currentTarget : previousDatesArrow;
    const start = numDays * (idx - 2);
    const end = numDays * (idx - 1);

    let allDateItems = rootEl.querySelectorAll('.date-items');
    let calledOnce = false;
    allDateItems.forEach((allDateItem) => {
        let dateItems = allDateItem?.querySelectorAll('li');
        let serviceDate = null;
        dateItems.forEach((item) => {
            item.classList.add('hide');
        });

        if (end != 0) {
            Array.from(dateItems)
                .slice(start, end)
                .forEach((item) => {
                    item.classList.remove('hide');
                });
            setDateSelectorIndex(idx - 1);
            nextDatesArrows.forEach((nextDatesArrow) => {
                nextDatesArrow.classList.remove('disable');
            });

            if (idx === 2) {
                currentTarget.classList.add('disable');
            }

            dateItems = rootEl.querySelectorAll('.date-items li:not(.hide)');
            const firstWeekDay = Array.from(dateItems).find((li) => getComputedStyle(li).display !== 'none');
            serviceDate = firstWeekDay.dataset.serviceDate;
            const serviceFetch = firstWeekDay.dataset.serviceFetch || false;

            if (!serviceFetch && !calledOnce) {
                [...rootEl.querySelectorAll('.store-hours .wait')].forEach((waitEl) => {
                    waitEl.style.display = '';
                });
                getAvailabilityForMultipleStores(rootEl, {
                    addedServicesListArr,
                    serviceDate,
                    setDateSelectorIndex,
                    numDays,
                    getStoresInfo,
                    setStoresInfo,
                    dateStoreStep,
                    completeDateTimeSelection
                });
                calledOnce = true;
            }
        }

        showHours(rootEl, { numDays });

        setAdobeDataLayer({ event: 'sa.v2.calendarinteraction' });
    });

    showHours(rootEl, { numDays });

    setAdobeDataLayer({ event: 'sa.v2.calendarinteraction' });
};

export { previousDates };
