/**
 * Scrolls an alement to the top of the viewport. Only one of the two parameters is used.
 * @param {HTMLElement} rootEl The element to scroll to top.
 * @param {number} position The top position to scroll to.
 */
const scrollTo = (rootEl, position = null) => {
    window.scrollTo({
        top: position || rootEl?.getBoundingClientRect()?.top + window.scrollY || 0,
        left: 0,
        behavior: 'smooth'
    });
};

export { scrollTo };
