import '../scss/interrupt.scss';
import { emailSignupCoupons } from './custom-modules/email-signup-coupons';
import { recentlyViewedTire } from './custom-modules/recently-viewed-tire';
import { getCookie, setCookie } from 'common/js/library/util';
import { component } from 'common/js/library/component';
import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';
import { viewport } from 'common/js/library/viewport';
import { tirePath } from 'common/js/util/dynamic-media/tirePath';
import { stringSanitizer } from 'common/js/util/stringSanitizer';
// import { interruptTestData } from 'common/js/data/test/interruptTestData';

let interruptBox;
let leaderboard;
const init = (config) => {
    interruptBox = document.querySelector('.interrupt .interrupt-box');
    leaderboard = document.querySelector('.leaderboard-banner-container');
};

const instanceInit = (rootEl) => {
    console.log('Interrupt.js init');
    let props = {
        closeBtn: rootEl.querySelector('.close'),
        screen: rootEl.querySelector('.interrupt-screen'),
        image: rootEl.querySelector('.interrupt-image img'),
        heading: rootEl.querySelector('.heading'),
        subheading: rootEl.querySelector('.subheading'),
        copy: rootEl.querySelector('.copy'),
        customModule: rootEl.querySelector('.custom-module'),
        ctas: [...rootEl.querySelectorAll('.ctas > a')],
        flexibleMessagingArea: rootEl.querySelector('.flexible-messaging-area'),
        recentlyViewedTire: rootEl.querySelector('.recently-viewed-tires'),
        recentlyViewedTireData: getLocalStorageItem('recentlyViewedTire')
    };

    // temp: using json data file for testing
    // will be injecting in data-attribute directly through AEM
    // addTestData(interruptTestData); // TEMP
    let allData = getData();
    if (!allData) {
        console.log('Interrupt data is null');
        return;
    }
    console.log('Interrupt all data:', allData);
    let data = getDataToUse(allData);

    console.log('Interrupt data to use:', data);

    props.closeBtn.onclick = (e) => {
        e.preventDefault();
        close(rootEl, data);
    };

    populate(rootEl, data, props);
    pageBump(rootEl, data, interruptBox);
    pubsub.subscribe(viewport.topics.RESIZE, (evt) => pageBump(rootEl, data, interruptBox));

    if (data && data.customModule === 'email-signup-coupons') {
        emailSignupCoupons(rootEl.parentElement, rootEl, props.customModule, props.ctas, close);
    }
    // Recently viewed Tire Module Init
    if (data && data.customModule === 'recently-viewed-tires' && data.type !== 'interrupt-anchored-strip') {
        let currentSession = sessionStorage.getItem('recentlyViewedTireFlag');
        if (!props.recentlyViewedTireData || (currentSession && currentSession === 'true')) {
            interruptBox.classList.add('hide');
            props?.screen?.remove();
        } else {
            interruptBox.classList.add('interrupt--recently-viewed-tires');
            if (props.recentlyViewedTire) {
                props.recentlyViewedTire.classList.remove('hide');
                if (props.recentlyViewedTireData) {
                    const imageOptions = {
                            brand: stringSanitizer(props.recentlyViewedTireData.brand),
                            pattern: stringSanitizer(
                                props.recentlyViewedTireData.name.replaceAll('/', ''),
                                'lower',
                                '-'
                            ),
                            angle: '60',
                            full: true,
                            web: true,
                            buScope: 'global',
                            buOwnership: 'bsro'
                        },
                        imageSrc = tirePath(imageOptions);
                    props.recentlyViewedTireData.image = imageSrc;
                }
                recentlyViewedTire(props.recentlyViewedTire, props.recentlyViewedTireData);
            }
            setElementInnerHTML(props.flexibleMessagingArea, getDinamicFlexibleMessage());
        }
    } else {
        props.recentlyViewedTire?.remove();
    }
};

/**
 * To get a dinamic flexible message from window.siteProfile
 */
const getDinamicFlexibleMessage = () => {
    if (
        window.siteProfile &&
        window.siteProfile.vehicles &&
        window.siteProfile.vehicles.tce &&
        window.siteProfile.vehicles.tce.year &&
        window.siteProfile.vehicles.tce.make &&
        window.siteProfile.vehicles.tce.model &&
        window.siteProfile.vehicles.tce.trim
    ) {
        return `This Tire Fits Your ${window.siteProfile.vehicles.tce.year}
            ${window.siteProfile.vehicles.tce.make}
            ${window.siteProfile.vehicles.tce.model}
            ${window.siteProfile.vehicles.tce.trim}`;
    } else {
        return false;
    }
};

/**
 * To be replaced with different data source implementation.
 */
const addTestData = (rootEl, data) => {
    // temp: add data-attribute to interrupt
    rootEl.setAttribute('data-interrupt', data);
    const dataAttr = rootEl.getAttribute('data-interrupt');
    window.interruptData = JSON.parse(dataAttr);
};

const getData = () => {
    return window.interruptData;
};

/**
 * Parses the data and returns the highest priority object using the following criteria:
 * 1: It will ignore all objects that have an existing unexpired cookie.
 * 2: If any objects are remaining, it will take the one with the highest priority value.
 * 3: If no objects contain a priority value the last item in the array will be used.
 * 4: If the object as restrictions check to make sure it can be shown and if not go to the next option.
 *
 * @param {object} data - interrupt data to parse
 *
 * @return {object} null if no interrupt to be shown
 */
const getDataToUse = (data) => {
    if (!data) return;
    console.log('interrupt.getDataToUse:', data);
    let winner = null;
    const deviceType = getDeviceType();
    const interrupts = data && data.interrupts && data.interrupts.length > 0 ? data.interrupts : [];
    const bodyClass = document.body.className.split(' ');

    //we're cycling through the interrupt ids to find a match with a body tag class in case there is an A/B test class there
    //if a match is found, if cookie found we return null, otherwise we return the value
    winner = interrupts.find((item) => {
        return bodyClass.includes(item.id) && !getCookie(item.id);
    });
    if (bodyClass.includes('brake-interrupt') || bodyClass.includes('sche-interrupt')) {
        return winner;
    }

    winner = interrupts.find((item) => {
        return item.id === 'interrupt-appt-lsp';
    });

    if (winner) return winner;

    // filter out interrupts that have unexpired cookies
    // or are marked to be hidden
    const initialFilter = interrupts.filter((item, index) => {
        const id = item.id || '';
        const hasCookie = id ? Boolean(getCookie(id)) : false;

        if (item.restrictions.includes('hide')) {
            return false;
        }

        return !hasCookie;
    });

    // check for inclusion and exclusion rules
    // if no rules, it passes
    // if rules, test regex vs path to see if it passes
    const finalists = initialFilter.filter((item, index) => {
        const path = window.location.pathname;
        let regex = undefined;

        if (item.inclusionRules && item.inclusionRules === 'include' && item.includePages) {
            regex = new RegExp(item.includePages);
            return regex.test(path);
        } else if (item.inclusionRules && item.inclusionRules === 'exclude' && item.excludePages) {
            regex = new RegExp(item.excludePages);
            return !regex.test(path);
        }

        return true;
    });

    // sort finalists by priority
    finalists.sort((a, b) => {
        const ap = a.priority ? parseInt(a.priority, 10) : 0;
        const bp = b.priority ? parseInt(b.priority, 10) : 0;
        return ap >= bp ? 1 : -1;
    });
    // make highest priority first in array
    // finalists.reverse(); // AEM provides them in the reverse order already

    finalists.map((item) => {
        if (!winner) {
            let pass = true;
            let restrictions = item.restrictions ? item.restrictions.trim().split(',') : [];
            restrictions.map((restriction) => {
                if (restriction === deviceType) {
                    pass = false;
                }
            });
            if (pass) winner = item;
        }
    });
    return winner;
};

/**
 * Populates the interrupt with the data provided
 */
const populate = (rootEl, data, props) => {
    if (!data) return;

    const classes = data.classes ? data.classes.split(' ') : [];
    // if type, apply type class
    // if position, apply position class
    interruptBox.classList.add(data.type || 'interrupt-no-type');
    interruptBox.classList.add(...classes);
    // if image, apply image, else hide
    setImage(props.image, data.image);
    setElementInnerHTML(props.heading, data.heading);
    setElementInnerHTML(props.subheading, data.subheading);
    setElementInnerHTML(props.copy, data.copy);
    if (data.customModule != 'recently-viewed-tires') {
        setElementInnerHTML(props.flexibleMessagingArea, data.flexibleMessagingArea);
    }
    setCTAs(rootEl, props.ctas, data.ctas, data.type);
    setScreen(rootEl, data, props.screen);
    if (data.id) {
        rootEl.setAttribute('data-interrupt-id', data.id);
        pubsub.publish(topics.CREATE_INTERRUPT, rootEl);
    }
    if (data.type === 'interrupt-anchored-strip') {
        leaderboard && leaderboard.classList.add('hide');
    }
    rootEl.classList.remove('hide');
};

const setElementInnerHTML = (el, data) => {
    if (el) {
        if (data) {
            el.innerHTML = data;
        } else {
            el.remove();
        }
    }
};

const setImage = (el, data) => {
    if (el) {
        if (data) {
            el.setAttribute('src', data);
        } else {
            el.remove();
        }
    }
};

const setCTAs = (rootEl, els, ctasData, type) => {
    els.map((el, index) => {
        let hasMatch = false;
        ctasData.map((data, index) => {
            const btnClass = index === 0 ? 'primary' : 'secondary';

            if (data.label) {
                if (el.classList.contains(btnClass)) {
                    hasMatch = true;
                    el.innerHTML = data.label;
                    el.setAttribute('href', data.href || '#');
                    el.onclick = (e) => {
                        close(rootEl, ctasData);
                    };
                    if (type === 'interrupt-anchored-strip') {
                        el.classList.remove('btn');
                    }
                }
            }
        });
        const title = (ctasData[index] && ctasData[index].label) || '';
        el.setAttribute('title', title);

        if (!hasMatch) {
            el.remove();
        }
    });
};

const setScreen = (rootEl, data, screen) => {
    if (screen) {
        if (data.type !== 'interrupt-modal') {
            screen.remove();
        } else {
            document.body.classList.add('no-scroll');
            screen.onclick = (e) => {
                e.preventDefault();
                close(rootEl, data);
            };
        }
    }
};

/**
 * The interrupt needs to push down the page content in certain cases.
 * This should be equal to the height of the interrupt.
 * This should update if the viewport dimensions change.
 */
const pageBump = (rootEl, data, interruptBox) => {
    if (!data) return;

    if (data.type === 'interrupt-anchored-strip' && data.classes.includes('top')) {
        rootEl.style.height = `${interruptBox.offsetHeight}px`;
    }
};

/**
 * Closes the interrupt and sets a cookie.
 * The interrupt with this id will no longer be shown until the cookie expires.
 */
const close = (rootEl, data) => {
    if (!data) return;
    let { id, expiration } = data;
    expiration = expiration ? Number(expiration) : 30; // expiration is number of days
    rootEl.classList.add('hide');
    document.body.classList.remove('no-scroll');
    if (id && expiration) {
        setCookie(id.trim(), true, expiration);
    }
    leaderboard && leaderboard.classList.remove('hide');
};

const getDeviceType = () => {
    const isMobile = window.navigator.userAgent.match(/(windows phone)|(android)|(iPad)|(iPhone)/i);

    return isMobile ? 'mobile' : 'desktop';
};

component({
    selector: '[data-interrupt]',
    init,
    instanceInit,
    name: 'Interrupt'
});
