import { component } from 'common/js/library/component';
import { pubsub } from 'common/js/library/pubsub';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

let headerNavigation;
let tabMyStoreContainer;
let bodyV2Observer;
let myAccountV2Observer;
let myCartV2Observer;
let myAccountV2Loaded = false;
let myCartV2Loaded = false;
let appsLoadedInterval;
let tabletBreakpoint;
let tireSubNav;
let repairSubNav;
let maintenanceSubNav;

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('top-nav-v2')) {
        observeHeaderV2();
    }
});

const init = (config) => {
    headerNavigation = document.querySelector(config?.selector);

    if (!headerNavigation) return;

    tireSubNav = headerNavigation.querySelector('li.tires > a');
    repairSubNav = headerNavigation.querySelector('li.repair > a');
    maintenanceSubNav = headerNavigation.querySelector('li.maintenance > a');

    onClickOutside(headerNavigation, () => resetSubNavs());

    tabMyStoreContainer = document.getElementById('tab-my-store-container-v2');

    if (!tireSubNav || !repairSubNav || !maintenanceSubNav) return;

    tabletBreakpoint = window.matchMedia('(min-width: 768px) and (max-width: 1366px)');

    tabletBreakpoint.addEventListener('change', () => {
        breakpointChange(tabletBreakpoint);
    });

    breakpointChange(tabletBreakpoint);
};

const breakpointChange = (breakpoint) => {
    if (breakpoint.matches && window.matchMedia('(pointer: coarse)').matches) {
        addTabletNavigation();
    } else {
        removeTabletNavigation();
    }
};

const addTabletNavigation = () => {
    tireSubNav.addEventListener('touchstart', onTouchStart, false);
    repairSubNav.addEventListener('touchstart', onTouchStart, false);
    maintenanceSubNav.addEventListener('touchstart', onTouchStart, false);
};

const removeTabletNavigation = () => {
    tireSubNav.removeEventListener('touchstart', onTouchStart, false);
    repairSubNav.removeEventListener('touchstart', onTouchStart, false);
    maintenanceSubNav.removeEventListener('touchstart', onTouchStart, false);
};

const onTouchStart = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const anchor = event.currentTarget;

    if (anchor.classList.contains('is-open')) {
        window.location.href = `${window.location.origin}${anchor.getAttribute('href')}`;
    } else {
        resetSubNavs();
        anchor.classList.add('is-open');
    }
};

const onClickOutside = (element, callback) => {
    document.addEventListener('click', (event) => {
        if (!element.contains(event.target)) callback();
    });
};

const resetSubNavs = () => {
    const anchors = headerNavigation.querySelectorAll('a.is-open');
    [...anchors].forEach((anchor) => {
        anchor.classList.remove('is-open');
    });
};

const instanceInit = (rootEl) => {
    if (!rootEl) return;

    const myStoreWrapper = tabMyStoreContainer?.querySelector('.store-wrapper');
    const closeButton = myStoreWrapper?.querySelector('.close-btn');
    const myStoreContainer = document.querySelector('.my-store-container');
    const scheduleAppointmentLink = document.querySelector('.secondary-nav-v2 .schedule-appointment a');

    scheduleAppointmentLink?.addEventListener('click', () => {
        setAdobeDataLayer({
            event: 'cta_click',
            cta_type: 'appointment',
            cta_text: 'schedule appointment',
            cta_location: 'primary navigation'
        });
    });

    const closeMyStore = () => {
        myStoreContainer.classList.remove('is-active');
        myStoreWrapper.querySelector('.arrow-down').style.display = 'flex';
        myStoreWrapper.querySelector('.close-btn').style.display = 'none';
        tabMyStoreContainer.classList.remove('is-active');
    };

    const toggleMyStore = () => {
        if (!myStoreContainer.classList.contains('is-active')) {
            myStoreContainer.classList.add('is-active');
            myStoreWrapper.querySelector('.arrow-down').style.display = 'none';
            myStoreWrapper.querySelector('.close-btn').style.display = 'flex';
            tabMyStoreContainer.classList.add('is-active');
            if (myStoreWrapper.querySelector('.no-store')) {
                setAdobeDataLayer({
                    event: 'ui_interaction',
                    ui_type: 'my store',
                    ui_text: 'find a store',
                    ui_location: 'primary navigation'
                });
            } else {
                setAdobeDataLayer({
                    event: 'ui_interaction',
                    ui_type: 'my store',
                    ui_text: 'change store',
                    ui_location: 'primary navigation'
                });
            }
            pubsub.publish('on-my-store-open');
        } else {
            closeMyStore();
        }
    };

    myStoreWrapper.addEventListener('click', (event) => {
        event.preventDefault();
        toggleMyStore();
    });

    closeButton.addEventListener('click', (event) => {
        event.stopPropagation();
        if (myStoreContainer.classList.contains('is-active')) {
            closeMyStore();
        }
    });

    pubsub.subscribe('on-my-garage-open', () => {
        closeMyStore();
    });

    pubsub.subscribe('on-my-account-open', () => {
        closeMyStore();
    });

    pubsub.subscribe('close-my-store-globally', () => {
        closeMyStore();
    });

    pubsub.subscribe('on-my-store-clickoutside', () => {
        closeMyStore();
    });

    setTopNavIDs();
};

//For analytics requirement setting ids to navs
const setTopNavIDs = () => {
    setID('#top-nav-v2 #main-nav li.tires .tab-nav', 'nav-tires-shop');
    setID('#top-nav-v2 #main-nav li.tires .tire-brands__wrapper', 'nav-tires-bybrand');
    setID('#top-nav-v2 #main-nav li.tires', 'nav-tires');
    setID('#top-nav-v2 #main-nav li.repair', 'nav-auto_repair');
    setID('#top-nav-v2 #main-nav li.maintenance', 'nav-auto_maintenance');
    setID('#top-nav-v2 #main-nav li.coupons', 'nav-coupons');
    setID('#top-nav-v2 #main-nav li.findstore', 'nav-find_a_store');
    setID('#top-nav-v2 #main-nav li.contact-us', 'nav-contact_us');
    setID('#top-nav-v2 #main-nav li.blog', 'nav-blog');
    setID('#top-nav-v2 #main-nav li.cart', 'nav-cart');
    setID('#top-nav-v2 #primary-nav-v2 .logo-wrapper', 'nav-log_img');
};

const setID = (selector, value) => {
    let selected = document.querySelectorAll(selector);
    if (selected.length == 1 && selected[0].getAttribute('id') == null) {
        selected[0].setAttribute('id', value);
    }
};

const observeHeaderV2 = () => {
    bodyV2Observer = new MutationObserver(callbackHeaderObserver);
    myAccountV2Observer = new MutationObserver(callbackHeaderObserver);
    myCartV2Observer = new MutationObserver(callbackHeaderObserver);

    const config = {
        attributes: true,
        childList: true,
        subtree: false
    };

    const bodyV2 = document.body;
    const myAccountV2 = document.getElementById('desktopMenuApp-v2');
    const myCartV2 = document.querySelector(
        '.top-nav-v2 .primary-nav-v2 .main-nav-wrapper-container .main-nav.main-nav-wrapper ul.menu-right li.cart'
    );

    bodyV2Observer.observe(bodyV2, config);
    myAccountV2Observer.observe(myAccountV2, config);

    if (window.isStoreEcom && myCartV2) {
        myCartV2Observer.observe(myCartV2, config);
    } else {
        myCartV2Observer.disconnect();
        myCartV2Loaded = true;
    }
};

const callbackHeaderObserver = (mutationList) => {
    for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
            if (mutation.target.classList.contains('desktop-account-menu-wrap')) {
                myAccountV2Observer.disconnect();
                myAccountV2Loaded = true;
            }
            if (mutation.target.classList.contains('cart')) {
                myCartV2Observer.disconnect();
                myCartV2Loaded = true;
            }
        } else if (mutation.attributeName === 'class') {
            if (mutation.target.classList.contains('bsro-ui-global-nav-v2')) {
                bodyV2Observer.disconnect();
                if (!appsLoadedInterval) {
                    appsLoadedInterval = setInterval(headerV2AppsLoaded, 500);
                }
            }
        }
    }
};

const headerV2AppsLoaded = () => {
    if (myAccountV2Loaded && myCartV2Loaded) {
        clearInterval(appsLoadedInterval);
        appsLoadedInterval = null;
        showAnimateHeaderV2();
        addTDGLinkToSubMenu();
    }
};

const showAnimateHeaderV2 = () => {
    document.querySelector('.top-nav-v2 .primary-nav-v2 .logo-wrapper').classList.add('show-animated');
    document
        .querySelector(
            '.top-nav-v2 .primary-nav-v2 .main-nav-wrapper-container .main-nav.main-nav-wrapper ul.menu-left'
        )
        .classList.add('show-animated');
    document
        .querySelector(
            '.top-nav-v2 .primary-nav-v2 .main-nav-wrapper-container .main-nav.main-nav-wrapper ul.menu-right'
        )
        .classList.add('show-animated');
    document.querySelector('.top-nav-v2 .secondary-nav-v2').classList.add('show-animated');
};

const addTDGLinkToSubMenu = () => {
    const thirdLiV2 = document.querySelector('#top-nav-v2 .shop-for-tires ul li:nth-child(3n)');
    if (!thirdLiV2) return; // Ensure the target element exists

    // Check if the new link already exists to prevent duplicate insertion
    const existingLink = document.querySelector('#top-nav-v2 .shop-for-tires ul li a[href="/tire-decision-guide/"]');
    if (existingLink) return;

    const newLiV2 = document.createElement('li');
    newLiV2.innerHTML = `
        <span class="indicator"></span>
        <a href="/tire-decision-guide/">
            <span>Shop with Tire Decision Guide</span>
        </a>
    `;
    thirdLiV2.parentNode.insertBefore(newLiV2, thirdLiV2);
};

component({
    selector: '.header-navigation',
    instanceInit,
    init,
    name: 'HeaderNavigation'
});
