import '../scss/schedule-appointment.scss';

import { testPhone } from '../../../react-shared/utils/validation';
import { updateTotalServiceTime } from './updateTotalServiceTime';
import { addService } from './addService';
import { buildDays } from './buildDays';
import { addServiceDuration } from './service-duration';
import { confirmPost } from './confirmPost';
import { findStores } from './findStores';
import { getAccountState } from './getAccountState';
import { hasSmallViewPort } from './hasSmallViewPort';
import { initUrlParamsReferrer } from './initUrlParamsReferrer';
import { nextDates } from './nextDates';
import { onDateTimeSubmit } from './onDateTimeSubmit';
import { onServicesSubmit } from './onServicesSubmit';
import { onVehicleSubmit } from './onVehicleSubmit';
import { previousDates } from './previousDates';
import { scrollTo } from './scrollTo';
import { scrollTopElement } from './scrollTopElement';
import { submitAppointment, contactDetail } from './submitAppointment';
// import { vehicleSelectorReactAppLoader } from './vehicle-selector-app-loader/vehicle-selector-react-app-loader';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';
import { validateElement } from 'common/js/library/util';
import { isKeypressNumber } from 'common/js/util/validate/isKeypressNumber';
import { getStoreLocationListByLatLong } from 'common/js/data/services/store-location';
import { component } from 'common/js/library/component';
import { fetchServiceData } from 'common/js/util/fetchServiceData';
import { pubsub } from 'common/js/library/pubsub';
import { setLocalStorageItem, getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

let addedServicesListArr;
let datesArr;
const init = (_config) => {
    addedServicesListArr = [];
    datesArr = [];
};

// Method for closing the error modal

const instanceInit = async (rootEl) => {
    if (window?.CQ?.utils?.WCM?.getMode() !== 'edit') {
        const url = '/appointment/schedule-appointment/';
        history.replaceState(null, null, `${url}${window.location.search}`);
    }
    // vehicleSelectorReactAppLoader(store);
    const vehicleStep = rootEl.querySelector('section.step.vehicle');
    const serviceStep = rootEl.querySelector('section.step.service');
    const dateStoreStep = rootEl.querySelector('section.step.date-store');
    const contactStep = rootEl.querySelector('section.step.contact');
    const vehicleSearchInput = document.createElement('input'); //  The markup for the input has been removed, there is code that depends on it. Created this virtual input DOM element to take it's place.
    const completeVehicleSelection = vehicleStep.querySelector('.complete-vehicle-selection');
    const addedServicesList = serviceStep.querySelector('.service-items ul');
    const addServiceButtons = serviceStep.querySelectorAll('[data-add-service]');
    const additionalMessage = serviceStep.querySelector('.additional-messaging');
    const completeServicesSelection = serviceStep.querySelector('[data-complete-services-selection]');
    const datesSection = dateStoreStep.querySelector('.dates');
    const nextDatesArrows = dateStoreStep.querySelectorAll('.nav-control.next');
    const previousDatesArrows = dateStoreStep.querySelectorAll('.nav-control.prev');
    const findStoresForm = dateStoreStep.querySelector('[data-form-find-stores]');
    const findStoresFormZip = findStoresForm.querySelector('input[name=zip]');
    const findStoresFormRadius = findStoresForm.querySelector('select[name=radius]');
    const completeDateTimeSelection = dateStoreStep.querySelectorAll('[data-complete-date-time-selection]');
    const completeDateTimeSelectionAndSubmit = dateStoreStep.querySelectorAll(
        '[data-complete-date-time-selection-and-submit]'
    );
    const contactForm = contactStep.querySelector('[data-form-contact]');
    const submitButton = contactForm.querySelector('button[type="submit"]');
    const firstNameEl = contactStep.querySelector('[name=first-name]');
    const lastNameEl = contactStep.querySelector('[name=last-name]');
    const emailEl = contactStep.querySelector('[name=email]');
    const phoneEl = contactStep.querySelector('[name=phone]');
    const dropPin = dateStoreStep.querySelector('.drop-pin');
    const experience = document.querySelector('#schedule_appointment').dataset.experience;
    const accordionItems = serviceStep.querySelectorAll('.accordion-item');

    const isPinnacle = () => {
        return window?.featureSwitches?.isTrue('bsro-ui-pinnacle');
    };
    const isPinnacleStore = () => {
        return getLocalStorageItem('storeData')?.onlineExperience === 'pinnacle';
    };

    const getVehicleSelect = () => {
        return {
            vsYear: rootEl.querySelector('.year .list'),
            vsMake: rootEl.querySelector('.make .list'),
            vsModel: rootEl.querySelector('.model .list'),
            vsTrim: rootEl.querySelector('.trim .list')
        };
    };

    const referrer = window.document.referrer.toString() + window.location.href.toString();

    const now = new Date();
    const currentFullYear = now.getFullYear();
    const currentMonth = now.getMonth();
    const currentDay = now.getDate();
    const numDays = hasSmallViewPort() ? 3 : 5;
    let storesInfo = {
        stores: [],
        storesData: [],
        storesAsDataObj: {},
        storeNumbers: [],
        availableStores: {}
    };

    let appointmentDetails = {
        date: '',
        store: {
            phone: '',
            storeNumber: '',
            city: '',
            state: '',
            zip: '',
            service: ''
        },
        vehicle: {
            year: '',
            make: '',
            model: '',
            trim: '',
            submodel: '',
            searchType: 'dropdown'
        },
        services: '',
        dropoff: '',
        pickup: '',
        zip: null,
        city: '',
        state: '',
        contact: ''
    };
    let apptDataPackage = {};
    let dateSelectorIndex = 0;
    const buildDaysNum = 30;
    let preferredStoreNumber = null;
    let isLoggedIn = false;
    let eventsAdded = false;
    let serviceIdList = [];
    let totalServiceTime = 0;
    let storeNumber = 0;
    let serviceData = {};

    const setEventsAdded = (value) => {
        eventsAdded = value;
    };

    const getEventsAdded = () => {
        return eventsAdded;
    };

    const setIsLoggedIn = (value) => {
        isLoggedIn = value;
    };

    const setPreferredStoreNumber = (value) => {
        preferredStoreNumber = value;
    };

    const setDateSelectorIndex = (value) => {
        dateSelectorIndex = value;
    };

    const getDateSelectorIndex = () => {
        return dateSelectorIndex;
    };

    const getStoresInfo = () => {
        return storesInfo;
    };

    const setStoresInfo = (data) => {
        storesInfo = Object.assign({}, storesInfo, data);
        if (storesInfo?.storesData?.length === 0) {
            datesSection.style.display = 'none';
        }
    };

    const getAppointmentDetails = () => {
        return appointmentDetails;
    };

    const setAppointmentDetails = (data) => {
        appointmentDetails = Object.assign({}, appointmentDetails, data);
        getVehicleData();
    };

    const setApptDataPackage = (data) => {
        apptDataPackage = data;
    };

    const getApptDataPackage = () => {
        return apptDataPackage;
    };

    const getRadius = () => {
        return parseInt(findStoresFormRadius.value);
    };

    // this throws errors with latest scheduling app.  commenting out for now, just in case its actually needed down the road
    // if (hasSmallViewPort()) {
    //     rootEl.querySelector('#vehicle-type').checked = true;
    // }

    const getVehicleData = () => {
        const vehicleData = window.siteProfile.vehicles.tce;
        if (vehicleData) {
            appointmentDetails = Object.assign({}, appointmentDetails, { vehicle: vehicleData });
            vehicleSearchInput.value = `${vehicleData.year} ${vehicleData.make} ${vehicleData.model} ${vehicleData.trim}`;
        }
        return vehicleData;
    };

    // global access for iframe map
    window.scheduleAppointment = {};

    const setServiceDurationInLocalStorage = async (storeNumber, serviceIdList) => {
        let timeDurationData;
        if (isPinnacle() && isPinnacleStore())
            timeDurationData = await updateTotalServiceTime(storeNumber, serviceIdList, serviceStep);

        setLocalStorageItem('totalServiceTime', timeDurationData);
    };

    const setServiceDurationData = async (serviceData, serviceItems) => {
        storeNumber = window.siteProfile?.location?.storeNumber;
        serviceData = await fetchServiceData(storeNumber);
        const serviceDurationExists = serviceStep?.querySelector('.service-duration')?.length;
        //to account for preselected items ie: pay in store & create appointment
        const addedServicesListItems = serviceStep.querySelectorAll('.service-items-group .accordion-item');

        if (serviceData?.data?.length && !serviceDurationExists) {
            [...serviceItems, ...addedServicesListItems].forEach((item) => {
                addServiceDuration(item, serviceData, isPinnacleStore());
            });
        }
        if (addedServicesListArr.length > 0) {
            const addedServiceId = serviceStep?.querySelector('.service-items-group span')?.dataset?.serviceId;
            serviceIdList = serviceIdList.filter((id) => id !== undefined);
            serviceIdList.push(addedServiceId);
            setServiceDurationInLocalStorage(storeNumber, serviceIdList);
        }
    };

    addServiceButtons.forEach((item) => {
        item.addEventListener('click', (evt) => {
            let serviceId = evt.currentTarget.parentNode.querySelector('[data-service-id]')?.dataset?.serviceId;
            let accordionCopy = evt.currentTarget.parentNode.parentNode.cloneNode(true);
            serviceIdList.push(serviceId);
            window.featureSwitches?.isTrue('bsro-ui-pinnacle') &&
                setServiceDurationInLocalStorage(storeNumber, serviceIdList);
            const removeService = (evt) => {
                evt.preventDefault();
                const currentTarget = evt.currentTarget;
                let serviceName = currentTarget.dataset.removeService;
                let parent = currentTarget.parentNode;
                const idx = addedServicesListArr.indexOf(serviceName);
                const idService = evt.currentTarget.parentNode.querySelector('[data-service-id]')?.dataset?.serviceId;
                const servicesIdIndex = serviceIdList.indexOf(idService);
                if (servicesIdIndex !== -1) {
                    serviceIdList.splice(servicesIdIndex, 1);
                    window.featureSwitches?.isTrue('bsro-ui-pinnacle') &&
                        setServiceDurationInLocalStorage(storeNumber, serviceIdList);
                }
                if (window?.featureSwitches?.isTrue('bsro-ui-pinnacle')) {
                    parent = currentTarget.closest('li');
                }
                if (idx != -1) {
                    addedServicesListArr.splice(idx, 1);
                    currentTarget.remove();
                    parent.remove();

                    // some services have multiple buttons, disable all buttons for that service
                    serviceStep
                        .querySelectorAll('[data-add-service="' + serviceName + '"]')
                        .forEach((button) => (button.disabled = false));

                    let sdata = {
                        service: serviceName,
                        addremove: 'remove',
                        event: 'sa.v2.servicechange'
                    };

                    setAdobeDataLayer(sdata);
                    if (addedServicesListArr.length == 0) {
                        additionalMessage.classList.add('hide');
                    }
                }

                if (addedServicesListArr.length == 0) {
                    addedServicesList.closest('.service-items').classList.add('hide');
                }

                serviceStep.dataset.selectedServices = addedServicesListArr;
            };

            const serviceName = addService(evt, addedServicesList, removeService, accordionCopy, additionalMessage);

            if (serviceName) {
                scrollTo(serviceStep);
                addedServicesListArr.push(serviceName);
                serviceStep.dataset.selectedServices = addedServicesListArr;
            }
        });
    });

    accordionItems.forEach((accordionItem) => {
        accordionItem.addEventListener('click', (evt) => {
            if (evt?.target?.classList?.contains('details')) {
                let serviceName = evt.currentTarget?.querySelector('button')?.dataset?.addService;
                let sdata = {
                    button_text: `See Detail: ${serviceName}`,
                    appt_flow_type: 'regular flow',
                    event: 'sa.service_details_click'
                };
                setAdobeDataLayer(sdata);
            }
        });
    });

    const { queryStoreNumber, queryStoreData } = await initUrlParamsReferrer(rootEl);

    completeServicesSelection.addEventListener('click', (evt) => {
        onServicesSubmit(evt, rootEl, {
            getAppointmentDetails,
            addedServicesListArr,
            queryStoreData,
            findStoresFormZip,
            getRadius,
            queryStoreNumber,
            isLoggedIn,
            preferredStoreNumber,
            setDateSelectorIndex,
            dateStoreStep,
            numDays,
            datesArr,
            getStoresInfo,
            setStoresInfo,
            setAppointmentDetails,
            completeDateTimeSelection
        });
    });

    pubsub.subscribe('vehicle-data-cookie', (_evt) =>
        onVehicleSubmit(rootEl, {
            addedServicesListArr,
            dateStoreStep,
            getAppointmentDetails,
            getStoresInfo,
            setAppointmentDetails,
            serviceStep,
            completeDateTimeSelection,
            vehicleSearchInput,
            getVehicleData
        })
    );

    pubsub.subscribe('steps-next', (_evt) => {
        if (isPinnacle()) {
            if (document.querySelector('.step.vehicle.active')) {
                // next was clicked on vehicle step; prepare the services step
                // This is bad because it depends on the order of the steps, but:
                // 1. The (steps-next) event happens before the step is actually changed.
                // 2. For Pinnacle, the vehicle step is always the first step.
                setServiceDurationData(serviceData, accordionItems);
            }
            const stores = getStoresInfo().stores;
            const selectedStore = document.querySelector('.available.selected')?.closest('.store-item')
                .dataset?.storeNumber;
            const selectedStoreData = stores?.find((store) => store?.storeNumber === selectedStore);
            document
                .querySelector('.schedule-appointment-v2')
                ?.classList.toggle('at-pinnacle-store', selectedStoreData?.onlineExperience === 'pinnacle');
        }
    });

    completeDateTimeSelection?.forEach((completeDateTimeSelectionCta) => {
        completeDateTimeSelectionCta.addEventListener('click', async (evt) => {
            try {
                await onDateTimeSubmit(evt, rootEl, {
                    getAppointmentDetails,
                    setAppointmentDetails,
                    dateStoreStep,
                    serviceStep,
                    completeDateTimeSelection,
                    addedServicesListArr,
                    getStoresInfo,
                    isPinnacle,
                    setDateSelectorIndex,
                    setStoresInfo,
                    datesArr
                });
                return Promise.resolve('Success');
            } catch (err) {
                return Promise.reject('Error');
            }
        });
    });

    //For experience C
    completeDateTimeSelectionAndSubmit?.forEach((completeDateTimeSelectionAndSubmitCta) => {
        completeDateTimeSelectionAndSubmitCta.addEventListener('click', async (evt) => {
            try {
                await onDateTimeSubmit(evt, rootEl, {
                    getAppointmentDetails,
                    setAppointmentDetails,
                    dateStoreStep,
                    serviceStep,
                    addedServicesListArr,
                    getStoresInfo,
                    isPinnacle,
                    setDateSelectorIndex,
                    setStoresInfo,
                    datesArr
                });
                submitAppointment(evt, rootEl, {
                    appointmentDetails,
                    addedServicesListArr,
                    getApptDataPackage,
                    setApptDataPackage,
                    confirmPost,
                    submitButton,
                    getAppointmentDetails,
                    dateStoreStep,
                    getStoresInfo,
                    getEventsAdded,
                    setEventsAdded
                });

                return Promise.resolve('Success');
            } catch (err) {
                return Promise.reject('Error');
            }
        });
    });

    nextDatesArrows.forEach((nextDatesArrow) => {
        nextDatesArrow.addEventListener('click', (evt) => {
            nextDates(evt, rootEl, {
                previousDatesArrows,
                numDays,
                getDateSelectorIndex,
                setDateSelectorIndex,
                nextDatesArrow,
                addedServicesListArr,
                datesArr,
                getStoresInfo,
                setStoresInfo,
                dateStoreStep,
                completeDateTimeSelection
            });
        });
    });

    previousDatesArrows.forEach((previousDatesArrow) => {
        previousDatesArrow.addEventListener('click', (evt) => {
            previousDates(evt, rootEl, {
                previousDatesArrow,
                numDays,
                getDateSelectorIndex,
                setDateSelectorIndex,
                nextDatesArrows,
                addedServicesListArr,
                datesArr,
                getStoresInfo,
                setStoresInfo,
                dateStoreStep,
                completeDateTimeSelection
            });
        });
    });

    findStoresForm.addEventListener('submit', (evt) => {
        findStores(evt, rootEl, {
            findStoresFormZip,
            getRadius,
            queryStoreNumber,
            isLoggedIn,
            preferredStoreNumber,
            setDateSelectorIndex,
            dateStoreStep,
            numDays,
            datesArr,
            addedServicesListArr,
            getStoresInfo,
            setStoresInfo,
            getAppointmentDetails,
            setAppointmentDetails,
            completeDateTimeSelection
        });
    });

    contactForm.addEventListener('submit', (evt) => {
        evt.preventDefault();
        const phoneNum = document.querySelector('#phone').value;
        const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

        if (validateElement(phoneNum, 'phoneNum', phoneRegex) && testPhone(phoneNum)) {
            if (experience === 'c') {
                contactDetail(evt, rootEl, {
                    getApptDataPackage,
                    setApptDataPackage,
                    vehicleStep,
                    completeVehicleSelection
                });
            } else {
                submitAppointment(evt, rootEl, {
                    appointmentDetails,
                    addedServicesListArr,
                    getApptDataPackage,
                    setApptDataPackage,
                    confirmPost,
                    submitButton,
                    getAppointmentDetails,
                    dateStoreStep,
                    getStoresInfo,
                    getEventsAdded,
                    setEventsAdded
                });
            }
        }
    });

    const radioButtons = document.querySelectorAll('input[name="communication-preference"]');
    const disclaimer = document.querySelector('.communication-disclaimer');
    const disclaimerCheckbox = document.querySelector('.communication-disclaimer__checkbox');

    //re-arrange contact info form
    const emailLabel = document.querySelector('label[for="email"]');
    const emailParent = emailEl.parentNode;
    const emailParentNew = document.createElement('div');
    emailParentNew.classList.add('form-group');
    emailParentNew.appendChild(emailEl);
    emailParentNew.appendChild(emailLabel);
    phoneEl.parentElement.insertAdjacentElement('beforebegin', emailParentNew);
    disclaimer?.insertAdjacentElement('afterend', emailParent);

    radioButtons.forEach((radio) => {
        radio.addEventListener('change', function (evt) {
            if (evt.target.value === 'email') {
                disclaimer.classList.add('communication-disclaimer--hidden');
                disclaimerCheckbox.removeAttribute('required');
            } else {
                disclaimer.classList.remove('communication-disclaimer--hidden');
                disclaimerCheckbox.checked = false;
                disclaimerCheckbox.setAttribute('required', 'required');
            }
        });
    });

    phoneEl.addEventListener('input', (evt) => {
        let inputValue = '';
        let val = evt.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        inputValue = val[1] + (val[2] ? `-${val[2]}` : '') + (val[3] ? `-${val[3]}` : '');
        evt.target.value = inputValue;
        
        const _phoneNum = evt.target.value.replace(/\D/g, '');
        validateElement(_phoneNum, 'phoneNum');
    });

    firstNameEl.addEventListener('invalid', (_evt) => {
        setAdobeDataLayer({
            event: 'forms.user_generated_error',
            form_name: 'appt booking',
            step_name: 'Contact Info',
            field_name: 'First Name',
            error_message: 'Please Fill out this Field'
        });
    });

    lastNameEl.addEventListener('invalid', (_evt) => {
        setAdobeDataLayer({
            event: 'forms.user_generated_error',
            form_name: 'appt booking',
            step_name: 'Contact Info',
            field_name: 'Last Name',
            error_message: 'Please Fill out this Field'
        });
    });

    emailEl.addEventListener('invalid', (_evt) => {
        setAdobeDataLayer({
            event: 'forms.user_generated_error',
            form_name: 'appt booking',
            step_name: 'Contact Info',
            field_name: 'Email ID',
            error_message: 'Please enter valid Email Address'
        });
    });

    phoneEl.addEventListener('blur', (evt) => {
        const response = validateElement(evt.target.value, 'phoneNum');
        if (!response) {
            setAdobeDataLayer({
                event: 'forms.user_generated_error',
                form_name: 'appt booking',
                step_name: 'Contact Info',
                field_name: 'phone number',
                error_message: 'Enter valid Phone Number'
            });
        }
    });

    'input propertychange click blur focus change'.split(' ').forEach((e) => {
        vehicleSearchInput.addEventListener(e, (evt) => {
            const valid = evt.currentTarget.getAttribute('vehiclevalidated');
            completeVehicleSelection.disabled = !valid;
        });
    });

    rootEl.querySelectorAll('[name=wait-or-drop-off]').forEach((waitOrDropOffEl) => {
        waitOrDropOffEl.addEventListener('change', (evt) => {
            evt.preventDefault();

            const currentTarget = evt.currentTarget;
            const pickupPref = rootEl.querySelector('.pickup-preference');

            if (currentTarget.value == 1) {
                pickupPref.classList.add('show');
            } else {
                pickupPref.classList.remove('show');
            }
        });
    });

    rootEl.querySelector('.vehicle-mileage').addEventListener('keypress', (evt) => {
        isKeypressNumber(evt);
    });

    for (let i = 0; i < buildDaysNum; i++) {
        const tmpDate = new Date(currentFullYear, currentMonth, currentDay + i);
        const tmpDateStrArr = tmpDate.toDateString().split(' ');
        const monthNumStr = ('0' + (tmpDate.getMonth() + 1).toString()).slice(-2);
        let tmpObj = {
            dayStr: tmpDateStrArr[0],
            monthStr: tmpDateStrArr[1],
            monthNumStr,
            dateStr: tmpDateStrArr[2],
            yearStr: tmpDateStrArr[3],
            yearMonthDateStr: tmpDateStrArr[3].toString() + monthNumStr.toString() + tmpDateStrArr[2].toString()
        };

        datesArr.push(tmpObj);
    }

    getAccountState({ setIsLoggedIn, setPreferredStoreNumber });
    buildDays(rootEl, { buildDaysNum, numDays, datesArr, getDateSelectorIndex, setDateSelectorIndex });

    setAdobeDataLayer({ event: 'sa.v2.start' });

    // if siteProfile contains a vehicle, auto populate the vehicle selector
    if (window.siteProfile?.vehicles?.tce?.ymm) {
        const vehicle = window.siteProfile.vehicles.tce;
        setAppointmentDetails({ vehicle });

        vehicleSearchInput.value = `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`;
        vehicleSearchInput.setAttribute('vehiclevalidated', 'true');

        if (completeVehicleSelection) {
            completeVehicleSelection.disabled = false;
        }
    }

    findStoresFormRadius.addEventListener('change', (_evt) => {
        findStoresForm.dispatchEvent(new Event('submit'));
    });

    findStoresFormZip.addEventListener('input', (evt) => {
        const val = evt.currentTarget.value;
        const findEl = findStoresForm?.querySelector('.findStore');
        window.siteProfile.location.myZip = val;
        findEl.disabled = /^\s*$/.test(val);
    });

    dropPin.addEventListener('click', (evt) => {
        if (window.navigator.geolocation) {
            const geoCallback = async (position) => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                const response = await getStoreLocationListByLatLong(lat, lng);
                // find the zip of the closest store.
                let zip = response[0]?.zip;

                if (zip) {
                    // zip the zipcode of the closest store to user's lat/long
                    // including the ZIP+4 could make it look like we mislocated the user,
                    // so only use the first 5 digits.
                    zip = zip.substring(0, 5);
                    window.siteProfile.location.myZip = zip;
                    findStoresFormZip.value = zip;
                    findStores(evt, rootEl, {
                        findStoresFormZip,
                        getRadius,
                        queryStoreNumber,
                        isLoggedIn,
                        preferredStoreNumber,
                        setDateSelectorIndex,
                        dateStoreStep,
                        numDays,
                        datesArr,
                        addedServicesListArr,
                        getStoresInfo,
                        setStoresInfo,
                        getAppointmentDetails,
                        setAppointmentDetails,
                        completeDateTimeSelection
                    });
                }
            };

            window.navigator.geolocation.getCurrentPosition(geoCallback, (err) => {
                console.error('geolocation error:', err.message);
            });
            window.GEO_FLAG = true;
        }
    });

    if (referrer.indexOf('battery') != -1 || referrer.indexOf('batteries') != -1) {
        if (window.siteProfile?.vehicles?.aux?.ymm) {
            const vehicle = window.siteProfile.vehicles.aux;
            addEventListeners(getVehicleSelect(), vehicle, setAppointmentDetails);
        }
    }

    // if we auto populated the vehicle, scroll elements to top
    if (window.siteProfile?.vehicles?.tce?.ymm) {
        const vehicle = window.siteProfile.vehicles.tce;
        addEventListeners(getVehicleSelect(), vehicle, setAppointmentDetails);
    }

    // Mutation Observer for auto scrolliing ymm dropdowns on vehicle step if prepopulated when rendered.
    const vehicleSelectorElement = document.querySelector('.steps-list .step.vehicle');
    const vehicleSelectorConflig = { attributes: true, childList: false, subtree: false };
    const vehicleSelectorCallback = (mutationsList) => {
        if (
            mutationsList[0] &&
            mutationsList[0].type === 'attributes' &&
            mutationsList[0].target?.classList?.contains('active')
        ) {
            scrollSelectedOptionInViewPort(getVehicleSelect());
        }
    };
    const observer = new MutationObserver(vehicleSelectorCallback);
    observer.observe(vehicleSelectorElement, vehicleSelectorConflig);
};

const scrollSelectedOptionInViewPort = (vehicleSelect) => {
    //It will iterate through all the vehicle specific dropdowns (year, make, model, trim) and will auto scroll the selected options in view port.
    for (const key in vehicleSelect) {
        if (Object.prototype.hasOwnProperty.call(vehicleSelect, key)) {
            const item = vehicleSelect[key];
            const selectedItem = item?.querySelector('.radio-button:checked');
            const selectedItemParent = selectedItem?.parentElement;

            if (selectedItemParent) {
                const scrollPosition = selectedItemParent?.offsetTop - selectedItemParent?.parentElement.offsetTop;
                item.scrollTo(0, scrollPosition);
            }
        }
    }
};

const addEventListeners = (vehicleSelect, vehicle, setAppointmentDetails) => {
    setAppointmentDetails({ vehicle });

    scrollTopElement(vehicleSelect?.vsYear, vehicle?.year);
    vehicleSelect?.vsYear?.addEventListener('click', () => {
        scrollTopElement(vehicleSelect?.vsMake, vehicle?.make);
    });

    vehicleSelect?.vsMake?.addEventListener('click', () => {
        scrollTopElement(vehicleSelect?.vsModel, vehicle?.model);
    });

    vehicleSelect?.vsModel?.addEventListener('click', () => {
        scrollTopElement(vehicleSelect?.vsTrim, vehicle?.trim);
    });
};

component({
    init,
    instanceInit,
    name: 'Schedule Appointment',
    selector: '[data-schedule-appointment-v2]'
});
