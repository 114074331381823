import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

const ReviewsStarsSpinner = (props) => {
    const { className, message, styles, type } = props;
    const classes = `reviews-summary loadstar ${className}`;
    const starPath = 'https://s7d1.scene7.com/is/content/bridgestone/stars-0-icon-global-web-bsro';
    const starSize = className.includes('large') ? 25 : className.includes('small') ? 15 : 20;

    const review = () => {
        return (
            <div className={classes} style={styles}>
                <SVG src={starPath} width={starSize} height={starSize} />
                <SVG src={starPath} width={starSize} height={starSize} />
                <SVG src={starPath} width={starSize} height={starSize} />
                <SVG src={starPath} width={starSize} height={starSize} />
                <SVG src={starPath} width={starSize} height={starSize} />
            </div>
        );
    };

    const summary = () => {
        return (
            <div className={className} style={styles}>
                <div className="coral-Wait coral-Wait--center coral-Wait--large"></div>
                {/* <p className="message">{message}</p> */}
            </div>
        );
    };

    // going to return blank for now.
    // return <>{type === 'summary' ? summary() : review()}</>;
    return <></>;
};

ReviewsStarsSpinner.defaultProps = {
    type: 'summary',
    className: 'wait',
    message: 'loading',
    styles: { maxHeight: '30px' }
};

ReviewsStarsSpinner.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    message: PropTypes.string,
    styles: PropTypes.object
};

export { ReviewsStarsSpinner };
