/**
 * Converts the month of a Date object into a two-digit string.
 * 
 * @param {Date} date - The Date object from which the month will be extracted.
 * @returns {string} A two-digit string representing the month.
 * If the month is a single digit (1-9), it prefixes it with a zero to maintain the two-digit format.
 */
const dateToMM = (date) => {
    let mm = (date.getMonth() + 1).toString();
    mm = mm.length === 1 ? `0${mm}` : mm;

    return mm;
};

export { dateToMM };
