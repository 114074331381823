import { dtmTag } from './dtmTag';
import { getLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

/* DTM EVENTING

digitalData.event=[{ // event data
	  "eventName":"alignment.select",
	  "attributes":
	    {
	      "year":"[VEHICLE YEAR]",
	      "make":"[VEHICLE MAKE]",
	      "model":"[VEHICLE MODEL]",
	      "subModel":"[VEHICLE SUBMODEL]",
	      "zip":"[ZIP]"
	     }
	}];
*/

/* EXAMPLE IMPLEMENTATION

 ---- was on a form explaining data-dtm-sucess (on success)
 <element data-dtm-submit='{"event":"VirtualPageview","virtualPageURL":"pageName","virtualPageTitle":"pageTitle"}'
	  data-dtm-success='{"event":"VirtualPageview","virtualPageURL":"pageName","virtualPageTitle":"pageTitle"}' >

 ---- from doc
 BEFORE:
 <a id="btn-print-battery-quote" class="btn tracked">Print Quote</a>
 AFTER CLICK:
 <a id="btn-print-battery-quote" class="btn tracked dL_keyButtonClick" data-action-name="[ACTION NAME]" >Print Quote</a>
*/

const analytics = () => {
    console.groupCollapsed('init analytics');

    window.dtm_tag = dtmTag;

    const dtmTags = document.querySelectorAll('[data-tag]');
    dtmTags.forEach((item) => {
        item.addEventListener('click', dtmTag);
    });

    const appClickTrack = document.querySelectorAll('[class~=dL_appClick]');
    appClickTrack.forEach((item) => {
        const track = item.getAttribute('class').toString().split(' ');
        item.dataset['app'] = track[1].toString().split('_')[1];
    });

    // Analytics Custom Global Helper Method.
    // getCouponData: Get the coupon data from the local storage without latency.
    window.analytics_utils = window.analytics_utils || {};
    window.analytics_utils.getCouponData = (friendlyId) => {
        // Get the cached data from the local storage.
        const apiCache = getLocalStorageItem('apiCache');
        if (!apiCache) {
            return;
        }

        // Find the cached data for the offers by url.
        const offers = apiCache.find((item) => item.key === '/bsro/services/promotions/alloffers?friendlyId=');
        if (!offers) {
            return;
        }

        const allOffers = offers.data.data;
        if (!allOffers) {
            return;
        }

        const coupons = allOffers.coupons || [];
        const tirePromotions = allOffers.tirePromotions || [];
        const combinedOffers = [...coupons, ...tirePromotions];

        // Find the offer by the friendlyId.
        const offer = combinedOffers.find((coupon) => coupon.friendlyId === friendlyId);
        if (!offer) {
            return;
        }

        // If the offer is found, return the expected data structure.
        if (offer) {
            const {
                thumbTitle,
                storeLevel,
                offerDescription,
                thumbnail,
                promotionType: { name }
            } = offer;
            return {
                thumbTitle,
                storeLevel,
                offerDescription,
                thumbnail,
                promotionType: {
                    name
                }
            };
        }

        // If the offer is not found, return undefined.
        return;
    };

    console.groupEnd();
};

export { analytics };
