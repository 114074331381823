// Utility method for formatting a number (minutes) to a string with hours and minutes
// returns a string in the format "X hrs Y mins" or an empty string if minutes is 0

const formatTimeToHrsMins = (minutes) => {
    const totalHours = Math.floor(minutes / 60);
    const totalMinutes = minutes % 60;

    const hoursCopy = totalHours > 1 ? ' hrs ' : ' hr ';
    const minutesCopy = totalMinutes > 1 ? ' mins' : ' min';

    let totalTimeString = '';
    if (minutes > 0) {
        totalTimeString = (totalHours ? totalHours + hoursCopy : '') + (totalMinutes ? totalMinutes + minutesCopy : '');
    }

    return totalTimeString;
};

export { formatTimeToHrsMins };
