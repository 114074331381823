import Handlebars from 'common/js/library/handlebar';
import { cleanHolidays } from './cleanHolidays';
import { cleanPhone } from './cleanPhone';
import { selectStore } from './selectStore';
import { validateSearchInput } from './validateSearchInput';

const updateStoreResultsContainer = (selectedStoreNumber, rootEl, props) => {
    const {
        getSelectedStoreNum,
        setSelectedStoreNum,
        getStores,
        getPreferredStoreNumber,
        setZip,
        getZip,
        getSelectedStore,
        setSelectedStore,
        searchZipInput
    } = props;

    const _result_tmpl = rootEl.querySelector('[data-store-result-template]')?.cloneNode(true);
    const _container = rootEl.querySelector('.appt-step-1-results');
    let storeType = '';

    while (_container.firstChild) {
        _container.removeChild(_container.firstChild);
    }

    for (let i = 0; i < getStores().length; i++) {
        const _store = getStores()[i];

        _store.count = i + 1;
        _store.mystore = selectedStoreNumber == _store.storeNumber ? true : false;
        _store.myPreferredStore = _store.storeNumber == getPreferredStoreNumber() ? true : false;
        _store.activeFlag = parseInt(_store.activeFlag);
        _store.onlineAppointmentActiveFlag = parseInt(_store.onlineAppointmentActiveFlag);

        if (getPreferredStoreNumber() != null) {
            _store.mystore = false;
        }

        _store.directions =
            window.location.hostname == 'localhost'
                ? `/content/bsro/${window.AEM.siteName}/en/locate/get-directions.${_store.storeNumber}.html`
                : `/locate/get-directions/${_store.storeNumber}`;
        _store.mobileCallLink = `tel:${cleanPhone(_store.phone)}`;
        _store.holidays = cleanHolidays(_store.holidays, _store.holidayHours);
        storeType = _store.storeType;

        const _html = _result_tmpl.innerHTML;
        const _template = Handlebars.compile(_html);
        const _result = _template(_store);
        _container.appendChild(document.createRange().createContextualFragment(_result));
    }

    if (storeType == 'FFC') {
        rootEl.querySelectorAll('.info .cta').forEach((element) => {
            element.style.display = 'none';
        });
        rootEl.querySelectorAll('.info-2 .cta').forEach((element) => {
            element.style.display = 'none';
        });
        rootEl.querySelectorAll('a.store-details-cta').forEach((element) => {
            element.replaceWith(...element.childNodes);
        });
    }

    if (window.bsroOmniStoreFinder) {
        rootEl.querySelectorAll('.info .cta').forEach((element) => {
            element.style.display = 'none';
        });
    }

    const selectStoreBtns = rootEl.querySelectorAll('[data-select-store]');
    selectStoreBtns.forEach(function (selectStoreBtn) {
        selectStoreBtn.addEventListener('click', (evt) => {
            const el = evt.currentTarget;
            let resultIdx = Array.from(el.closest('.result').parentNode.children).indexOf(el.closest('.result'));

            if (resultIdx > 2) {
                resultIdx = resultIdx - 2;
            }

            setSelectedStoreNum(el.dataset.storeNumber);
            setSelectedStore(getStores()[resultIdx]);
            const selectedStore = getSelectedStore();
            setZip(selectedStore.zip.substr(0, 5));

            const callcta = 'tel:' + selectedStore.phone.slice(1, 15).replace(/\)\s/g, '-');
            rootEl.querySelector('.appt-step-2 .call-cta')?.setAttribute('href', callcta);

            window.dtm_tag(evt);

            const storeInfo = document.querySelectorAll('span.storeInfo');
            storeInfo.forEach(function (info) {
                info.innerHTML = '';
                info.innerHTML += '<strong>Store: #' + selectedStore.storeNumber + '</strong><br/>';
                info.innerHTML += selectedStore.address + '<br/>';
                info.innerHTML += selectedStore.city + ', ' + selectedStore.state;
            });

            searchZipInput.value = getZip();
            validateSearchInput(rootEl, getZip());

            selectStore(getZip(), getSelectedStoreNum(), rootEl, props);
        });
    });
};

export { updateStoreResultsContainer };
