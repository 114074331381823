import {
    TIRE_DECISION_GUIDE_SET_TDG,
    TIRE_DECISION_GUIDE_SET_STEP,
    TIRE_DECISION_GUIDE_SET_VEHICLE_SELECTED,
    TIRE_DECISION_GUIDE_SET_STORE,
    TIRE_DECISION_GUIDE_SET_TIRE_TYPE,
    TIRE_DECISION_GUIDE_SET_ZIPCODE,
    TIRE_DECISION_GUIDE_SET_PRIORITIES,
    TIRE_DECISION_GUIDE_SET_SELECTED_PRIORITIES,
    TIRE_DECISION_GUIDE_SET_TIRES,
    TIRE_DECISION_GUIDE_SET_SELECTED_TIRES,
    TIRE_DECISION_GUIDE_SET_LOGGED_IN
} from '../actions';
import merge from 'lodash-es/merge';

const { myZip, storeNumber } = window.siteProfile.location;
const zipCodeDefault = myZip || '';
const storeNumberDefault = storeNumber || '';

const initialState = {
    step: 1,
    vehicleSelected: {},
    zipCode: zipCodeDefault,
    storeNumber: storeNumberDefault,
    tireType: '',
    priorities: [],
    selectedPriorities: [],
    tires: [],
    selectedTires: []
};

function tireDecisionGuideReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case TIRE_DECISION_GUIDE_SET_TDG:
            return payload;

        case TIRE_DECISION_GUIDE_SET_STEP:
            return merge({}, state, { step: payload });

        case TIRE_DECISION_GUIDE_SET_VEHICLE_SELECTED:
            state.vehicleSelected = payload;
            return state;

        case TIRE_DECISION_GUIDE_SET_STORE:
            return merge({}, state, { storeNumber: payload });

        case TIRE_DECISION_GUIDE_SET_ZIPCODE:
            return merge({}, state, { zipCode: payload });

        case TIRE_DECISION_GUIDE_SET_TIRE_TYPE:
            return merge({}, state, { tireType: payload });

        case TIRE_DECISION_GUIDE_SET_PRIORITIES:
            state.priorities = [];
            return merge({}, state, { priorities: payload });

        case TIRE_DECISION_GUIDE_SET_SELECTED_PRIORITIES:
            state.selectedPriorities = [];
            return merge({}, state, { selectedPriorities: payload });

        case TIRE_DECISION_GUIDE_SET_TIRES:
            state.tires = [];
            return merge({}, state, { tires: payload });

        case TIRE_DECISION_GUIDE_SET_SELECTED_TIRES:
            state.selectedTires = [];
            return merge({}, state, { selectedTires: payload });

        case TIRE_DECISION_GUIDE_SET_LOGGED_IN: 
            return merge({}, state, { isLoggedIn: payload });

        default:
            return state;
    }
}

export default tireDecisionGuideReducer;
