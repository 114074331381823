import { stringSanitizer } from 'common/js/util/stringSanitizer';
import { getTDPPath } from 'common/js/util/getTDPPath';
import { tirePath } from 'common/js/util/dynamic-media/tirePath.js';
import { getVehicleSearchURL } from 'common/components/content/react-shared/utils/getVehicleSearchURL.js';

import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';

/**
 * normalizes the data that will go into the option value attribute
 * @param {string} val - form input option
 */

export const normalizeWidgetValue = (val) => {
    let str = String(val).toLowerCase();

    if (str.length !== 1) {
        str = str
            .replace(/[@#$%^&*.]/g, '')
            .replace(/\s{2,}/g, ' ')
            .replace(/[/+:\s]/g, '-');
    }

    return str;
};

/**
 * Converts an array of values to string of option tags
 * @param {array} arr - array of data points to create a list of option tags with
 * @param {string} datatype - select datatype. may allow for specialized output
 */
export const getOptions = (arr, datatype = '') => {
    let options = arr.map((item) => {
        let val = datatype === 'trim' ? item.trim : item;
        let tpms = datatype === 'trim' ? `data-tpms="${item.tpms}"` : '';
        let normy = normalizeWidgetValue(val);
        return `<option value="${val}" data-optval="${normy}"${tpms}>${val}</option>`;
    });

    return options.join('');
};

/**
 * Provides Boolean for if the profile value is found in array of values
 * verify that there is an option that matches profile data since they may not match if backup vehicle datatype is being used
 * @param {array} arr - array of items to check against
 * @param {string} val - value we're checking for a match
 * @param {string} datatype - select datatype. for conditional cases
 */
export const isProfileMatch = (arr, val, datatype = '') => {
    let match = false;

    if (val) {
        arr.map((item) => {
            let test = datatype === 'trim' ? item.trim : item;
            if (test === val) {
                match = true;
            }
        });
    }

    return match;
};

export const isTiresProfileMatch = (arr, val) => {
    let match = false;

    val &&
        arr.map((item) => {
            if (item.value === val) {
                match = true;
            }
        });

    return match;
};

export const tireCardTemplate = (tireData, index) => {
    const imageBrand = stringSanitizer(tireData.brand),
        imageOptions = {
            brand: imageBrand,
            pattern: tireData.tireName,
            angle: '60',
            full: true,
            web: true,
            buScope: 'global',
            buOwnership: 'bsro'
        },
        imageSrc = tirePath(imageOptions);
        const hrefTDP = getTDPPath(tireData.brand, tireData.tireFriendlyName || tireData.tireName, tireData.article);
    return `<a class="lid-content__card" href="${hrefTDP}" id="lid-card-${index}">
    <div class="lid-content__card__icon">
        <!-- brand icon -->
        <!-- Bridgeston -->
        ${tireData.brand === 'Bridgestone' ? '<img class="Bridgestone"  src="https://s7d1.scene7.com/is/content/bridgestone/bridgestone-shield-color-icon-web-global-bsro" alt="Bridgestone logo">' : ''}
        <!-- Firestone -->
        ${tireData.brand === 'Firestone' ? '<img class="Firestone"  src="https://s7d1.scene7.com/is/content/bridgestone/firestone-shield-color-icon-web-global-bsro" alt="Firestone logo">' : ''}
        <!-- Toyo -->
        ${tireData.brand == 'Toyo' ? '<img class="Toyo" src="https://s7d1.scene7.com/is/image/bridgestone/toyo-logo-images-tires-brands-global-web-bsro" alt="Toyo logo" />' : ''}
        <!-- Suredrive -->
        ${tireData.brand == 'SUREDRIVE' ? '<img class="SUREDRIVE" width="107" height="10" src="https://s7d1.scene7.com/is/content/bridgestone/suredrive-icon-web-global-bsro" alt="SUREDRIVE logo">' : ''}


    </div>
    <div class="lid-content__card__brand">${tireData.brand}</div>
    <div class="lid-content__card__tire">${tireData.tireTitle}</div>
    <div class="lid-content__card__tire-image">

        <img src="${imageSrc}" alt="${tireData.tireName}" />
        <div class="overlay"></div>
    </div>
    <div class="lid-content__card__tire-type">${tireData.vehicleType}</div>
    <div class="lid-content__card__reviews">

        <div class="reviews-app${tireDetailPageSwitch() ? '-v2' : ''}" 
            data-type="inline-rating" 
            data-product-type="card" 
            data-id="${tireData.id}" 
            data-tire-brand="${tireData.brand}" 

            data-tire-name="${tireData.tireName}"
            data-article="${tireData.article}"
            data-tire-name-title="${tireData.tireName}">
        </div>

    </div>
    <div class="lid-content__card__description">
            ${tireData.description}
    </div>
    <div class="lid-content__card__fit">
        <span>This tire fits your vehicle</span>
    </div>

</a>`
}

export const seeAllTiresCardTemplate = (data, index) => {
    let seeAllTiresHref = getVehicleSearchURL(data.year, data.make, data.model, data.trim);

    return `<a class="see-all-tires" href="${seeAllTiresHref}" id="lid-card-${index}" title="See All Tires">
    <div class="lid-content__card__tire">See All Tires</div>
</a>`;
};
