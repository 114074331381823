/**
 * Helper function for Array.sort() that will produce an array sorted lowest to highest
 * based on the a,b values provided.
 * If the value provided is undefined it will provide a overly high number to sort it last
 * @param {number} a
 * @param {number} b
 * @param {function} secondary (optional) - if values are equal, run the secondary sorting function
 * @param {*} a2 (optional) - secondary sort function a value
 * @param {*} b2 (optional) - secondary sort function b value
 */

const lowToHigh = (a = null, b = null, secondary = null, a2 = null, b2 = null) => {
    a = isNaN(a) || a == null ? 9999999 : a;
    b = isNaN(b) || b == null ? 9999999 : b;
    /* console.log('low-to-high:', { a, b, r: a - b }); */

    if (secondary && a === b) {
        return secondary(a2, b2);
    } else {
        return a - b;
    }
};

export { lowToHigh };
