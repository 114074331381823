import { reviewsMergeProductsV2, setReviewsV2 } from 'common/js/data/redux/actionCreators/reviews';
import { prConfig } from 'common/components/content/react-shared/utils/power-reviews/prConfig';
import { pubsub } from 'common/js/library/pubsub';
import { stringSanitizer } from 'common/js/util/stringSanitizer';
import { topics } from 'common/js/library/topics';
import { updateProductSummariesV2 } from 'common/components/content/react-shared/utils/power-reviews/updateProductSummaries';
import { setProductReviews } from 'common/components/content/react-shared/utils/power-reviews/setProductReviews';
import { isProductTypeTire } from 'common/js/library/util.js';

let sortBy = 'MostRelevant';

// request all reviews data and add to the redux store
const reviewsReduxLoaderV2 = async (store) => {
    console.log('reviewsReduxLoaderV2');

    const instantiate = async (els) => {
        let groupId,
            pageSize,
            locale = 'en-US',
            reviewsId = '';

        const idArray = Array.from(
            await Promise.all(
                els.map(async (el, i) => {
                    let id = stringSanitizer(el.dataset.id, 'upper');
                    let productType = el.dataset.productType || 'tire';

                    groupId = (prConfig && prConfig.groupId) || '';

                    console.log('reviewsReduxLoaderV2 - init map:', {
                        i,
                        type: el.dataset.type,
                        id,
                        el
                    });

                    if (el.dataset.type && el.dataset.type === 'reviews') {
                        reviewsId = id;
                        pageSize = isProductTypeTire(productType) ? 6 : 3;
                        sortBy = isProductTypeTire(productType) ? sortBy : 'Newest';
                        await setProductReviews(setReviews, reviewsId, sortBy, pageSize);
                        id = false;
                    }

                    return id || false;
                })
            )
        ).filter(Boolean);

        console.log('reviewsReduxLoaderV2 - idArray:', { idArray, els });
        // making this await because of merge conflict if it comes back after reviews
        // would need to fix merge conflict to remove.
        if (idArray.length && idArray.length > 0) {
            await updateProductSummariesV2(updateSummaries, idArray, groupId, locale);
        }
    };

    const updateSummaries = (data) => {
        const snippetData = data?.[0]?.results || []; // data in the first index is the store data "snippet"
        const reviewItems = data.filter((item) => item?.page_id); // filter out the store data "snippet"

        store.dispatch(reviewsMergeProductsV2([...snippetData, ...reviewItems]));
    };

    const setReviews = (data) => {
        const payload = Object.assign({}, data, { sortBy });
        store.dispatch(setReviewsV2(payload));
    };

    // Note: if anything triggers this on a page that reviews are on, it will reinitialize the set reviews request.
    pubsub.subscribe(topics.CREATE_REVIEWS_V2, (els) => {
        instantiate(els);
    });

    const els = Array.from(document.querySelectorAll('.reviews-app-v2'));
    instantiate(els);
};

export { reviewsReduxLoaderV2 };
