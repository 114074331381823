/**
 * Returns the user's geolocation permissions without
 * prompting the user.
 */
export const GeolocationPermission = async () => {
    return (
        navigator.permissions &&
        navigator.permissions
            .query({
                name: 'geolocation'
            })
            .then((PermissionStatus) => {
                // status whitelist.
                if (/granted|prompt|denied/.test(PermissionStatus.state)) {
                    return PermissionStatus.state;
                } else {
                    return;
                }
            })
    );
};
