import merge from 'lodash-es/merge';
import {
    ACCOUNT_LOGIN_USER,
    ACCOUNT_LOGOUT_USER,
    ACCOUNT_REMOVE_VEHICLE,
    ACCOUNT_SET_FULL_PROFILE,
    ACCOUNT_SET_VEHICLES
} from '../actions';

const initialState = {
    isLoggedIn: 'loading',
    tokenId: null,
    profile: {
        DRIVER: [],
        FILL_UP: [],
        MAINTENANCE_PERFORMED_SERVICE: [],
        MAINTENANCE_TASK: [],
        SERVICE_HISTORY_INVOICE: [],
        SERVICE_HISTORY_JOB: [],
        SERVICE_HISTORY_JOB_DETAIL: [],
        SERVICE_HISTORY_VEHICLE: [],
        STORE: [],
        VEHICLE: []
    }
};

function accountReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ACCOUNT_SET_FULL_PROFILE:
            return merge({}, state, { profile: payload });
        case ACCOUNT_LOGIN_USER:
            return merge({}, state, {
                isLoggedIn: payload.isLoggedIn,
                tokenId: payload.tokenId
            });
        case ACCOUNT_LOGOUT_USER:
            return merge({}, state, {
                isLoggedIn: 'false',
                tokenId: null
            });
        case ACCOUNT_REMOVE_VEHICLE:
            console.log('REDUCER PAYLOAD REMOVE VEHICLE: ', payload);
            console.log('EXISTING STATE: ', state);
            /* let tmpState = state;
            console.log("tmpState: ", state)
            // get VEHICLE array as variable
            let tmpVEHICLE = state.profile.VEHICLE;
            console.log("tmpVehicle", tmpVEHICLE);
            // remove vehicle by ID from array
            let newTmpVehicle = tmpVEHICLE.filter(vehicle => vehicle.ID !== payload)
            // attach new array as tmpState.VEHICLE
            tmpState.profile.VEHICLE = newTmpVehicle;
            let newState = merge({}, tmpState); */
            state.profile = payload;
            return merge({}, state);
        case ACCOUNT_SET_VEHICLES:
            return merge({}, state, {
                profile: {
                    VEHICLE: payload
                }
            });
        default:
            return state;
    }
}

export default accountReducer;
