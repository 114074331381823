/**
 *
 * @param {string} timeOfDay - 9:30am format
 * @returns {number} - the hours the time of day represents, as compared to the date object (example 2:32pm would return 14)
 */

const timeOfDayToHours = (timeOfDay) => {
    let hours = Number(timeOfDay.split(':')[0]);
    let isPM = timeOfDay.includes('pm');

    if ((hours === 12 && !isPM) || hours === 24) {
        return 0;
    }

    if (hours < 12 && isPM) {
        hours += 12;
    }

    return hours;
};

export { timeOfDayToHours };
