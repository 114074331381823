import merge from 'lodash-es/merge';
// import { removeCart } from '../../services/ecomm/cart';
// import { removeCookie } from 'common/js/library/util';
import { isEqual } from 'lodash-es';
import {
    ECOMM_CART_EXPIRED,
    ECOMM_MERGE_CART,
    ECOMM_REMOVE_CART,
    ECOMM_REPLACE_CHECKOUT_STORE_AVAILABILITY,
    ECOMM_SET_ADD_ONS,
    ECOMM_SET_CART,
    ECOMM_SET_CART_RESET_COUNTER,
    ECOMM_SET_CART_STATE,
    ECOMM_SET_CHECKOUT_APPOINTMENT,
    ECOMM_SET_CHECKOUT_APPOINTMENT_CONFIRM,
    ECOMM_SET_CHECKOUT_BILLING,
    ECOMM_SET_CHECKOUT_PAYMENT,
    ECOMM_SET_CHECKOUT_STEP,
    ECOMM_SET_CHECKOUT_STORE_AVAILABILITY,
    ECOMM_SET_CHECKOUT_SUBMITTING,
    ECOMM_SET_ECOMM_STORE,
    ECOMM_SET_FORCE_NEW_QUOTE,
    ECOMM_SET_ORDER,
    ECOMM_SET_QUOTE,
    ECOMM_SET_SERVICE_NAMES_LIST,
    ECOMM_SET_TIRE_DETAIL_BUTTON_LABEL,
    ECOMM_SET_TIRE_DETAIL_BUTTON_STATE,
    ECOMM_SET_TIRE_DETAIL_IS_IN_CART,
    ECOMM_SET_TIRE_DETAIL_IS_SCROLLED_PAST,
    ECOMM_SET_TIRE_DETAIL_QUOTE_VIEW_STATE,
    ECOMM_SET_TIRE_DETAIL_VIEW_STATE,
    ECOMM_SET_SORTED_DATA,
    ECOMM_SET_PRICING_DETAILS_TIMESTAMP
} from '../actions';

const defaultCheckout = {
    step: 0,
    daytimes: [],
    sortedData: [],
    service: {},
    appointment: {
        dropWaitOption: '',
        appointmentYYYYMMDD: '',
        appointmentDate: '',
        appointmentTime: '',
        dateTime: 0,
        pickupPreference: ''
    },
    billing: {
        city: '',
        country: 'US',
        email: '',
        firstName: '',
        lastName: '',
        mobile: '',
        state: '',
        street1: '',
        street2: '',
        zip: ''
    },
    payment: {
        paymentBrand: '',
        cc: '',
        cvv: '',
        expiryMonth: '',
        expiryYear: ''
    }
};
const defaultServices = {
    // used for making appointment requests
    default: 'Tire+Replacement', // what all service lists must contain
    current: '', // the most recent appointment request value
    addOns: [
        { value: 'Wheel Alignment', codes: ['7009500', '7009527'] },
        { value: 'Complete Vehicle Inspection', codes: ['7028789'] }
    ]
};
const defaultTireDetail = {
    viewState: 'quote', // quote, loading, range, basic, no-price
    quoteViewState: 'quote', // quote, vehicle, size, or noFit
    buttonState: 'up', // up, down, remote-down
    buttonLabel: '',
    isInCart: false,
    isScrolledPast: false
};
const initialState = {
    quote: {
        cart: {},
        vehicleFitment: {},
        cartGuid: '',
        tireQuoteId: '',
        tire: {}
    }, // from request data
    forceNewQuote: false,
    cart: {}, // from request data
    cartResetCounter: 0,
    addOns: {}, // from request data
    order: {}, // from request data
    isCartOpen: false,
    isSubmitting: false,
    services: defaultServices,
    store: {
        eCommActiveFlag: '', // expects '1' or '0'
        storeNumber:
            (window.siteProfile && window.siteProfile.location && window.siteProfile.location.storeNumber) || ''
    },
    tireDetail: defaultTireDetail,
    checkout: defaultCheckout,
    vehicleFitment: {},
    sortedData: [],
    pricingDetailsTimestamp: null
};
const emptyCart = {
    cart: {},
    addOns: {},
    checkout: Object.assign({}, defaultCheckout, { step: -1 }),
    services: defaultServices
};

function cartReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ECOMM_CART_EXPIRED:
            if (state.checkout.step < 4) {
                return Object.assign({}, state, emptyCart);
            }
            return state;
        case ECOMM_REMOVE_CART:
            return Object.assign({}, state, emptyCart);
        case ECOMM_SET_CHECKOUT_SUBMITTING:
            return merge({}, state, { checkout: { isSubmitting: payload } });
        case ECOMM_SET_QUOTE:
            return merge({}, state, { quote: payload });
        case ECOMM_SET_FORCE_NEW_QUOTE:
            return merge({}, state, { forceNewQuote: payload });
        case ECOMM_SET_CART:
            return Object.assign({}, state, payload); // overwrites any previous cart data. payload object should contain cart key
        case ECOMM_MERGE_CART:
            return merge({}, state, payload); // merges new cart data with existing data.
        case ECOMM_SET_CART_STATE:
            return merge({}, state, { isCartOpen: payload });
        case ECOMM_SET_ADD_ONS:
            return Object.assign({}, state, { addOns: payload });
        case ECOMM_SET_ORDER:
            return Object.assign({}, state, payload); // payload object should contain order key
        case ECOMM_SET_CART_RESET_COUNTER:
            return merge({}, state, { cartResetCounter: payload });
        case ECOMM_SET_CHECKOUT_STEP:
            return merge({}, state, { checkout: { step: payload } });
        case ECOMM_REPLACE_CHECKOUT_STORE_AVAILABILITY: // replaces currently stored availability with new
            return Object.assign({}, state, {
                checkout: {
                    ...state.checkout,
                    daytimes: payload.daytimes,
                    service: payload.service
                }
            });
        case ECOMM_SET_CHECKOUT_STORE_AVAILABILITY: // merges in new daytimes with existing data
            var oldDaytimes = state.checkout.daytimes.filter(
                (daytime) => !payload.daytimes.some((newDT) => newDT.availableDate === daytime.availableDate)
            );
            var newDaytimes = oldDaytimes.concat(payload.daytimes);

            return merge({}, state, {
                checkout: {
                    daytimes: newDaytimes,
                    service: payload.service
                }
            });
        case ECOMM_SET_CHECKOUT_APPOINTMENT:
            return merge({}, state, { checkout: { appointment: payload } });
        case ECOMM_SET_CHECKOUT_APPOINTMENT_CONFIRM:
            return merge({}, state, { checkout: { confirm: payload } });
        case ECOMM_SET_CHECKOUT_BILLING:
            return merge({}, state, { checkout: { billing: payload } });
        case ECOMM_SET_CHECKOUT_PAYMENT:
            return merge({}, state, { checkout: { payment: payload } });
        case ECOMM_SET_SERVICE_NAMES_LIST:
            return merge({}, state, { services: { current: payload } });
        case ECOMM_SET_ECOMM_STORE:
            return merge({}, state, { store: payload });
        case ECOMM_SET_TIRE_DETAIL_VIEW_STATE:
            return merge({}, state, { tireDetail: { viewState: payload } });
        case ECOMM_SET_TIRE_DETAIL_QUOTE_VIEW_STATE:
            return merge({}, state, {
                tireDetail: { quoteViewState: payload }
            });
        case ECOMM_SET_TIRE_DETAIL_BUTTON_STATE:
            return merge({}, state, { tireDetail: { buttonState: payload } });
        case ECOMM_SET_TIRE_DETAIL_BUTTON_LABEL:
            return merge({}, state, { tireDetail: { buttonLabel: payload } });
        case ECOMM_SET_TIRE_DETAIL_IS_IN_CART:
            return merge({}, state, { tireDetail: { isInCart: payload } });
        case ECOMM_SET_TIRE_DETAIL_IS_SCROLLED_PAST:
            return merge({}, state, {
                tireDetail: { isScrolledPast: payload }
            });

        case ECOMM_SET_SORTED_DATA: {
            if (!isEqual(state.sortedData, payload)) {
                return merge({}, state, {
                    sortedData: payload
                });
            }

            return state;
        }
        case ECOMM_SET_PRICING_DETAILS_TIMESTAMP:
            return {
                ...state,
                pricingDetailsTimestamp: payload
            };
        default:
            return state;
    }
}

export default cartReducer;
