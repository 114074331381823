/**
 * Converts a Date object into a timezone offset string between UTC and Chicago time.
 * The function calculates the difference in hours between UTC and Chicago time,
 * adjusting for daylight saving time automatically.
 * 
 * defaults to CST timezone
 * calculating difference between chicago and utc to avoid calculating daylight savings time
 * 
 * @param {Date} date - The Date object for which the timezone difference is calculated.
 * @returns {string} A string representing the timezone difference in the format of "+HH:00" or "-HH:00".
 */
export const dateToTimezone = (date) => {
    const utc = date.toLocaleString('en-US', { timeZone: 'UTC' }).replace(/[^ -~]/g, '');
    const chicago = date.toLocaleString('en-US', { timeZone: 'America/Chicago' }).replace(/[^ -~]/g, '');
    const utcMilliseconds = new Date(utc).getTime();
    const chicagoMilliseconds = new Date(chicago).getTime();
    const difference = utcMilliseconds - chicagoMilliseconds;
    const prefix = utcMilliseconds > chicagoMilliseconds ? '-' : '+';
    const hours = String(Math.floor(difference / 1000 / 60 / 60));

    return hours.length === 1 ? `${prefix}0${hours}:00` : `${prefix}${hours}:00`;
};
