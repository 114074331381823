import '../scss/battery-quote.scss';
import { cleanObject, validateElement } from 'common/js/library/util';
import { component } from 'common/js/library/component';
import { createToolTip } from 'common/js/library/tooltip';
import Handlebars from 'common/js/library/handlebar';
import { responseError, queryStringify, HTTPHeaders } from 'common/js/data/services/serviceUtilities';
import { setLocalStorageItem } from 'common/js/data/localstorage/localStorageService';

let vehicleInfoContainer;
let vehicleInfoTmpl;
let alignmentQuoteTmpl;
let quoteModal;
let overlayEl;
const init = (config) => {
    vehicleInfoContainer = document.querySelector('.vehicle-info-bar');
    vehicleInfoTmpl = document.querySelector('[data-alignment-quote-vehicle-template]')?.cloneNode(true)?.innerHTML;
    alignmentQuoteTmpl = document.querySelector('[data-alignment-quote-template]')?.cloneNode(true)?.innerHTML;
    quoteModal = document.querySelector('#quote-modal');
    overlayEl = document.querySelector('.overlay');
};

const instanceInit = (rootEl) => {
    console.log('inside battery-quote.js');

    var queryParams = getQueryParameter(window.location);

    let emailQuoteBtn = rootEl.querySelector('.ctas #emailQuoteBtn');

    if (document.querySelectorAll('[data-battery-quote-template]').length) {
        if (queryParams.productCode) {
            window.addEventListener('load', () => createBatteryQuote);
        }

        if (queryParams.quoteId) {
            window.addEventListener('load', () => getBatteryQuote(rootEl));
        }

        emailQuoteBtn.addEventListener('click', async (evt) => {
            evt.preventDefault();

            const firstNameInput = rootEl.querySelector('.firstName input');
            const lastNameInput = rootEl.querySelector('.lastName input');
            const emailAddressInput = rootEl.querySelector('.emailAddress input');
            const optInInput = rootEl.querySelector('.signup-optin input');
            const quoteIdInput = rootEl.querySelector('.quoteId input');

            const firstName = firstNameInput.value;
            const lastName = lastNameInput.value;
            const emailAddress = emailAddressInput.value.trim();
            const optIn = optInInput.checked ? 'Yes' : 'No';
            const quoteId = quoteIdInput.value;

            const data = {
                source: 'battery',
                quoteID: quoteId,
                emailAddress: emailAddress,
                optin: optIn,
                firstName: firstName,
                lastName: lastName
            };

            const emailValidRes = validateElement(emailAddress, 'email');
            if (emailValidRes) {
                const emailQuoteData = await fetchEmailQuoteData(data);
                if (emailQuoteData && emailQuoteData.data) {
                    const dataMessage = emailQuoteData.data;
                    console.log('Status:' + dataMessage);
                    if (dataMessage == 'Alignment quote email sent successfully') {
                        quoteModal.style.display = 'block';
                        const leftpos = window.innerWidth / 2 - quoteModal.offsetWidth / 2;
                        const toppos = window.innerHeight / 2 - quoteModal.offsetHeight / 2;
                        overlayEl.style.display = 'block';
                        quoteModal.style.left = leftpos + 'px';
                        quoteModal.style.top = toppos + 'px';

                        document.addEventListener('keydown', (e) => {
                            if (e.keyCode === 27) {
                                // ESC
                                hideQuoteModal(quoteModal, overlayEl);
                            }
                        });

                        overlayEl.addEventListener('click', () => {
                            hideQuoteModal(quoteModal, overlayEl);
                        });

                        document.querySelector('.close-quote')?.addEventListener('click', () => {
                            hideQuoteModal(quoteModal, overlayEl);
                        });

                        quoteModal.querySelector('.status-cta .secondary')?.addEventListener('click', () => {
                            window.location.reload();
                        });
                    } else {
                        hideQuoteModal(quoteModal, overlayEl);
                    }
                }
            }
        });
    }

    if (document.querySelectorAll('[data-alignment-quote-template]').length) {
        if (queryParams.altype) {
            window.addEventListener('load', () => createAlignmentQuote);
        }
        if (queryParams.quoteId) {
            window.addEventListener('load', () => getAlignmentQuote(rootEl));
        }

        emailQuoteBtn.addEventListener('click', async (evt) => {
            evt.preventDefault();

            const firstNameInput = rootEl.querySelector('.firstName input');
            const lastNameInput = rootEl.querySelector('.lastName input');
            const emailAddressInput = rootEl.querySelector('.emailAddress input');
            const optInInput = rootEl.querySelector('.signup-optin input');
            const quoteIdInput = rootEl.querySelector('.quoteId input');

            const firstName = firstNameInput.value;
            const lastName = lastNameInput.value;
            const emailAddress = emailAddressInput.value.trim();
            const optIn = optInInput.checked ? 'Yes' : 'No';
            const quoteId = quoteIdInput.value;

            const data = {
                source: 'alignment',
                quoteID: quoteId,
                emailAddress: emailAddress,
                optin: optIn,
                firstName: firstName,
                lastName: lastName
            };

            const emailValidRes = validateElement(emailAddress, 'email');
            if (emailValidRes) {
                const emailQuoteData = await fetchEmailQuoteData(data);
                if (emailQuoteData && emailQuoteData.data) {
                    const dataMessage = emailQuoteData.data;
                    console.log('Status:' + dataMessage);
                    if (dataMessage == 'Alignment quote email sent successfully') {
                        quoteModal.style.display = 'block';
                        const leftpos = window.innerWidth / 2 - quoteModal.offsetWidth / 2;
                        const toppos = window.innerHeight / 2 - quoteModal.offsetHeight / 2;
                        overlayEl.style.display = 'block';
                        quoteModal.style.left = leftpos + 'px';
                        quoteModal.style.top = toppos + 'px';

                        document.addEventListener('keydown', (e) => {
                            if (e.keyCode === 27) {
                                // ESC
                                hideQuoteModal(quoteModal, overlayEl);
                            }
                        });

                        overlayEl.addEventListener('click', () => {
                            hideQuoteModal(quoteModal, overlayEl);
                        });

                        document.querySelector('.close-quote')?.addEventListener('click', () => {
                            hideQuoteModal(quoteModal, overlayEl);
                        });

                        quoteModal.querySelector('.status-cta .secondary')?.addEventListener('click', () => {
                            window.location.reload();
                        });
                    } else {
                        hideQuoteModal(quoteModal, overlayEl);
                    }
                }
            }
        });
    }

    rootEl.querySelector('.ctas #printQuoteBtn')?.addEventListener('click', () => window.print());
    [...rootEl.querySelectorAll('.contact-col .form input')].forEach((inputEl) => {
        inputEl.addEventListener('change', (evt) => handleFieldChange(rootEl, emailQuoteBtn));
        inputEl.addEventListener('blur', (evt) => handleFieldChange(rootEl, emailQuoteBtn));
        inputEl.addEventListener('keyup', (evt) => handleFieldChange(rootEl, emailQuoteBtn));
    });
};

const hideQuoteModal = (quoteModal, overlayEl) => {
    quoteModal.style.display = 'none';
    overlayEl.style.display = 'none';
};

const fetchEmailQuoteData = async (paramData) => {
    const url = `/bsro/services/contact/emailquote${queryStringify(paramData)}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const handleFieldChange = (rootEl, emailQuoteBtn) => {
    let data = getFormValues(rootEl);
    let disabled = true;

    if (validate(data)) {
        disabled = false;
    }

    emailQuoteBtn.disabled = disabled;
};

const getFormValues = (rootEl) => {
    let _firstName = rootEl.querySelector('.firstName input').value.trim();
    let _lastName = rootEl.querySelector('.lastName input').value.trim();
    let _email = rootEl.querySelector('.emailAddress input').value.trim();
    let _data;

    _data = {
        firstName: _firstName,
        lastName: _lastName,
        email: _email
    };

    return _data;
};

const validate = (data) => {
    if (data.firstName !== '' && data.lastName !== '' && data.email !== '') {
        return true;
    }

    return false;
};

/**
 * Method to render selected Battery - Create Quote
 */
const createBatteryQuote = async () => {
    const quoteContainer = document.querySelector('#quote-placeholder');
    const alignmentQuoteTmpl = document.querySelector('[data-battery-quote-template]').cloneNode(true).innerHTML;

    const queryParam = getQueryParameter(window.location);

    const _data = {};
    if (typeof queryParam.productCode !== 'undefined') _data['productCode'] = queryParam.productCode;

    const createQuoteData = await createQuoteApi('battery', _data);

    if (createQuoteData && createQuoteData.data) {
        const respData = createQuoteData.data;
        const batteryQuoteCompiled = Handlebars.compile(alignmentQuoteTmpl);
        const batteryQuoteOutput = batteryQuoteCompiled(respData);
        quoteContainer.appendChild(batteryQuoteOutput);
    } else {
        return false;
    }
};

/**
 * Method to render selected Battery - Get Quote
 */
const getBatteryQuote = async (rootEl) => {
    const quoteContainer = rootEl.querySelector('#quote-placeholder');
    const vehicleInfoContainer = document.querySelector('.vehicle-info-bar');
    const vehicleInfoTmpl = document.querySelector('[data-battery-quote-vehicle-template]').cloneNode(true).innerHTML;
    const batteryQuoteTmpl = document.querySelector('[data-battery-quote-template]').cloneNode(true).innerHTML;

    const queryParam = getQueryParameter(window.location);

    const _data = {};
    if (typeof queryParam.quoteId !== 'undefined') _data['quoteId'] = queryParam.quoteId;
    if (typeof queryParam.rebateId !== 'undefined') _data['rebateId'] = queryParam.rebateId;

    const quoteDetailsData = await fetchBatteryQuoteDetails(_data);

    if (quoteDetailsData && quoteDetailsData.data) {
        const respData = quoteDetailsData.data;
        const _tmpZip = parseInt(respData.zip) || null;

        let _tag = {
            attributes: {
                year: respData.vehicle ? respData.vehicle.year : null,
                make: respData.vehicle ? respData.vehicle.make : null,
                model: respData.vehicle ? respData.vehicle.model : null,
                engine: respData.vehicle ? respData.vehicle.engine : null,
                zip: _tmpZip,
                storeNumber: respData.storeNumber || null,
                productID: respData.battery.partNumber || null,
                description: respData.battery.productCode || null,
                warranty: respData.battery.totalWarrantyMonths || null,
                subTotalPrice: respData.battery.webPrice,
                totalPrice: respData.total,
                quoteID: respData.batteryQuoteId
            }
        };

        _tag = cleanObject(_tag);

        window._satellite.setVar('searchData', _tag.attributes);
        window._satellite.track('battery.quote');

        // store quote details so schedule appt can read them
        let apptNotes =
            'battery quote id:  ' +
            respData.batteryQuoteId +
            ',  battery article number:  ' +
            respData.battery.partNumber +
            ',  battery description: ' +
            respData.battery.productName +
            ',  battery warranty: ' +
            respData.battery.totalWarrantyMonths +
            ',  battery unit price: ' +
            respData.subtotal +
            ',  battery quote End;';
        setLocalStorageItem('apptNotes', apptNotes);

        const batteryQuoteCompiled = Handlebars.compile(batteryQuoteTmpl);
        const vehicleInfoCompiled = Handlebars.compile(vehicleInfoTmpl);
        const batteryQuoteOutput = batteryQuoteCompiled(respData);
        const vehicleInfoOutput = vehicleInfoCompiled(respData);
        vehicleInfoContainer.insertAdjacentHTML('beforeend', vehicleInfoOutput);
        quoteContainer.insertAdjacentHTML('beforeend', batteryQuoteOutput);

        const moreLink = document.querySelector('.more-link');
        const detailList = document.querySelector('.detail-list');

        moreLink.addEventListener('click', function () {
            detailList.classList.toggle('expand');
            if (moreLink.textContent == 'More +') {
                moreLink.textContent = 'Less -';
            } else {
                moreLink.textContent = 'More +';
            }
        });
    }
};

/**
 * Method to render selected Alignment - Create Quote
 */
const createAlignmentQuote = async () => {
    const quoteContainer = document.querySelector('#quote-placeholder');
    const alignmentQuoteTmpl = document.querySelector('[data-alignment-quote-template]').cloneNode(true).innerHTML;

    const queryParam = getQueryParameter(window.location);

    const _data = {};
    if (typeof queryParam.storeNumber !== 'undefined') _data['storeNumber'] = queryParam.storeNumber;
    if (typeof queryParam.siteName !== 'undefined') _data['siteName'] = queryParam.siteName;
    if (typeof queryParam.ymm !== 'undefined') _data['ymm'] = queryParam.ymm;
    if (typeof queryParam.trim !== 'undefined') _data['trim'] = queryParam.trim;
    if (typeof queryParam.articleNumber !== 'undefined') _data['articleNumber'] = queryParam.articleNumber;
    if (typeof queryParam.altype !== 'undefined') _data['altype'] = queryParam.altype;
    if (typeof queryParam.alpricingId !== 'undefined') _data['alpricingId'] = queryParam.alpricingId;
    if (typeof queryParam.firstName !== 'undefined') _data['firstName'] = queryParam.firstName;
    if (typeof queryParam.lastName !== 'undefined') _data['lastName'] = queryParam.lastName;
    if (typeof queryParam.emailAddress !== 'undefined') _data['emailAddress'] = queryParam.emailAddress;

    const createQuoteData = await createQuoteApi('alignment', _data);

    if (createQuoteData && createQuoteData.data) {
        const respData = createQuoteData.data;
        const alignmentQuoteCompiled = Handlebars.compile(alignmentQuoteTmpl);
        const alignmentQuoteOutput = alignmentQuoteCompiled(respData);
        quoteContainer.appendChild(alignmentQuoteOutput);
        createToolTip(quoteContainer.querySelector('.tooltip'));
    } else {
        return false;
    }
};

const fetchAlignmentQuoteDetails = async (paramData) => {
    const url = `/bsro/services/alignment/get-quote-details${queryStringify(paramData)}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const fetchBatteryQuoteDetails = async (paramData) => {
    const url = `/bsro/services/battery/get-quote${queryStringify(paramData)}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

const createQuoteApi = async (type, paramData) => {
    const url = `/bsro/services/${type}/create-quote${queryStringify(paramData)}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

/**
 * Method to render selected Alignment - Get Quote
 */
const getAlignmentQuote = async (rootEl) => {
    let quoteContainer = rootEl.querySelector('#quote-placeholder');
    let queryParam = getQueryParameter(window.location);

    let paramData = {};
    if (typeof queryParam.quoteId !== 'undefined') paramData['quoteId'] = queryParam.quoteId;
    if (typeof queryParam.siteName !== 'undefined') paramData['siteName'] = queryParam.siteName;
    const quoteDetailsData = await fetchAlignmentQuoteDetails(paramData);

    if (quoteDetailsData && quoteDetailsData.data) {
        const respData = quoteDetailsData.data;
        const _tmpZip = parseInt(respData.zip) || null;

        let _tag = {
            attributes: {
                year: respData.vehicleYear || null,
                make: respData.vehicleMake || null,
                model: respData.vehicleModel || null,
                'sub-model': respData.vehicleSubmodel || null,
                zip: _tmpZip,
                storeNumber: respData.storeNumber || null,
                productID: respData.article || null,
                totalPrice: respData.price,
                quoteID: respData.alignmentQuoteId
            }
        };

        _tag = cleanObject(_tag);

        window._satellite.setVar('searchData', _tag.attributes);
        window._satellite.track('alignment.complete');

        // store quote details so schedule appt can read them
        const apptNotes =
            'alignment quote id:  ' +
            respData.alignmentQuoteId +
            ',  alignment article number:  ' +
            respData.article +
            ',  alignment description: ' +
            respData.pricingName +
            ',  alignment unit price: ' +
            respData.price +
            ',  alignment quote End;';
        setLocalStorageItem('apptNotes', apptNotes);

        const alignmentQuoteCompiled = Handlebars.compile(alignmentQuoteTmpl);
        const vehicleInfoCompiled = Handlebars.compile(vehicleInfoTmpl);
        const alignmentQuoteOutput = alignmentQuoteCompiled(respData);
        const vehicleInfoOutput = vehicleInfoCompiled(respData);
        vehicleInfoContainer.insertAdjacentHTML('beforeend', vehicleInfoOutput);
        quoteContainer.insertAdjacentHTML('beforeend', alignmentQuoteOutput);
        createToolTip(quoteContainer?.querySelector('.tooltip'));
    }
};

const getQueryParameter = (location) => {
    // This function return values from Query-String
    const query_string = {};
    const query = location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        // If first entry with this name
        if (typeof query_string[pair[0]] === 'undefined') {
            query_string[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        } else if (typeof query_string[pair[0]] === 'string') {
            const arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
            query_string[pair[0]] = arr;
            // If third or later entry with this name
        } else {
            query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return query_string;
};

component({
    selector: '.battery-quote',
    init,
    instanceInit,
    name: 'BatteryQuote'
});
