import '../scss/main.scss';
import $ from 'jquery';
// import Handlebars from 'common/js/library/handlebar';
import { initialSetup } from './initial-setup';
import { setStore } from './set-store';
import { tabStoreClientSideReinitialization } from './tab-store-clientside-reinitialization';
import { JSIWrapperDynamicIncludeReinitialization } from './jsi-wrapper-dynamic-include-reinitialization';
import { analytics } from 'common/js/util/analytics/analytics';
import { getUIVersion, reportUIVersion } from 'common/js/library/version';
import { chatBot } from 'common/js/library/chatbot';

console.group('common main');

// ONLY NEED JQUERY IMPORTED IF ANY CLIENTLIBS STILL RETAIN JQUERY.
// LEAVING OFF BY DEFAULT TO CATCH REMAINING CODE TO CONVERT.
window.$ = window.jQuery = $;

//require('common/js/library/handlebar');
import 'slick-carousel';

const uiVersion = getUIVersion();
console.group(`BSRO UI Version: ${uiVersion}`);
reportUIVersion();
console.groupEnd();

initialSetup();
setStore();
analytics();
tabStoreClientSideReinitialization();
JSIWrapperDynamicIncludeReinitialization();
chatBot();

if ('loading' in HTMLImageElement.prototype) {
    const images = document.querySelectorAll('img[data-src]');
    images.forEach((img) => {
        img.src = img.dataset.src;
    });
} else {
    require('common/js/library/imglazyload');
}
console.groupEnd();

// This method is used by the JSI Wrapper component on the Local SEO pages to load components on the client side.
// This method is created to update the URL selectors that will be used to request components like offers with Store No as selector.
window.computeJsiUrl = (uri) => {
    let storeInfo = document.getElementById('local-pages-store-information');
    let storeId = storeInfo ? storeInfo.dataset.storeId : '';
    if (uri) {
        // for cases not dependent on the page, like tab-store in header
        storeId = window.siteProfile.location.storeNumber;
        uri = uri.replace(/(.*\.html)\/.*/, '$1');
        return uri.replace('nocache.', storeId ? storeId + '.' : '');
    } else {
        // If uri missing, add '/jsi/' to current page Url
        let jsiUrl = window.location.pathname;
        jsiUrl = jsiUrl.endsWith('/') ? jsiUrl : jsiUrl + '/';
        return jsiUrl + (storeId ? storeId + '/jsi/' : 'jsi/');
    }
};
