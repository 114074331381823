import { openUntil } from './openUntil';
import { cleanHolidays } from './cleanHolidays';
import { cleanHours } from './cleanHours';
import { cleanPhone } from './cleanPhone';
import { coralWaitDisplay } from './coralWaitDisplay';
import { selectStore } from './selectStore';
import { validateSearchInput } from './validateSearchInput';
import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';
import Handlebars from 'common/js/library/handlebar';
import { queryStringify, HTTPHeaders } from 'common/js/data/services/serviceUtilities';
import { store } from 'common/js/data/redux/store/exportedStore';
import { reviewsReactAppLoader } from 'common/components/content/vehicle-lookup/v3/js/reviews/reviews-react-app-loader';
import { reviewsReduxLoaderV2 } from 'common/components/content/reviews/v2/js/reviewsReduxLoader';
import { tireDetailPageSwitch } from 'common/js/util/tireDetailPageSwitch';
import { setStoreByStoreNumber } from 'common/js/data/services/store-location';
import { setLocalStorageItem } from 'common/js/data/localstorage/localStorageService';
import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const findStores = (rootEl, props, evt, data) => {
    const {
        getSelectedStoreNum,
        setSelectedStoreNum,
        getIsLoggedIn,
        getStores,
        setStores,
        getPreferredStoreNumber,
        setZip,
        getZip,
        getSelectedStore,
        setSelectedStore,
        searchZipInput,
        steps,
        getCity,
        setCity,
        getState,
        setState,
        isSeoPage,
        seoDefaultZipCode,
        geoLocateSuccessful
    } = props;

    if (evt) {
        evt.preventDefault();
    }

    var _storeType;
    var _result_tmpl = rootEl.querySelector('[data-store-result-template]').cloneNode(true);
    var _inputVal = rootEl.querySelector('.search-block input[name=zip]').value;
    var _container = rootEl.querySelector('.appt-step-1-results');
    var _data = data || { city: null, state: null };
    var _service = '/bsro/services/store/location/get-list-state-city';
    var _currentStore = getSelectedStoreNum() || (window.siteProfile && window.siteProfile.location.storeNumber);
    var desktopBreakpoint = window.matchMedia('(min-width: 1024px)');
    var mobileBreakPoint = window.matchMedia('only screen and (max-width: 539px)');
    var errorResponse = () => {
        if (rootEl.querySelector('.search-block .zipcodeError')) {
            var _err = 'No stores found for location: ' + _inputVal;
            var _errLocalPages = 'No Stores Within Range';
            if (isSeoPage) {
                rootEl.querySelector('.search-block .zipcodeError').innerHTML = _errLocalPages;
                // Trigger analytics error
                const stateValue = document.querySelector('meta[name="stateName"]')?.getAttribute('content');
                const cityValue = document.querySelector('meta[name="city"]')?.getAttribute('content');
                const locationData = {
                    ...(stateValue && { state: stateValue }),
                    ...(cityValue && { city: cityValue }),
                    ...(_inputVal && { search_term: _inputVal })
                };

                setAdobeDataLayer({
                    event: 'store.no-results',
                    ...locationData
                });
            } else {
                rootEl.querySelector('.search-block .zipcodeError').innerHTML = _err;
            }

            if (evt && evt.type == 'submit') {
                // keeping this here for backwards compatibility.
                window.digitalData.event && (window.digitalData.event[0].eventName = 'store.no-results');

                window._satellite.setVar('searchData', _data);
                window._satellite.track('store.no-results');
            }
        }
    };

    if (parseInt(_inputVal)) {
        if (parseInt(_inputVal) > 0) {
            _service = '/bsro/services/store/location/get-list-by-zip';
            _data = data || { zipCode: _inputVal };
            _storeType = steps.getAttribute('data-store-type');
            if (_storeType != undefined && _storeType != null && _storeType != '') {
                _data.storeType = _storeType;
            }

            setZip(_inputVal);

            if (window.digitalData) {
                var _attr = { zip: getZip() };

                if (window.digitalData.event) {
                    if (window.digitalData.event.length == 0) {
                        window.digitalData.event[0] = { attributes: _attr };
                    } else {
                        window.digitalData.event[0].attributes = _attr;
                    }
                }
            }
        } else {
            errorResponse();
        }
    } else {
        var tmp = _inputVal.split(',');

        if (typeof tmp[1] != 'undefined') {
            setCity(tmp[0]);
            setState(tmp[1]);
        } else {
            setState(_inputVal.match(/((new|north|south|west|rhode)? ?[a-z]*)$/gi)[0].trim());
            var rx = new RegExp(',? ' + self.state);
            setCity(_inputVal.replace(rx, ''));
        }

        _data.city = getCity();
        _data.state = getState();

        _storeType = steps.getAttribute('data-store-type');
        if (_storeType != undefined && _storeType != null && _storeType != '') {
            _data.storeType = _storeType;
        }

        if (window.digitalData && window.digitalData.event) {
            if (window.digitalData.event.length == 0) {
                window.digitalData.event[0] = { attributes: _data };
            } else {
                window.digitalData.event[0].attributes = _data;
            }
        }
        if (isSeoPage) {
            if (_data.city == '' && _data.state == '') {
                return;
            }
        }
    }

    while (_container.firstChild) {
        _container.firstChild.remove();
    }

    var preferredStore = [];
    var _defer = new Promise(function (resolve) {
        if (getPreferredStoreNumber() && getIsLoggedIn()) {
            fetch('/bsro/services/store/details/' + getPreferredStoreNumber())
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    var _data = data && data.data;

                    preferredStore.push(_data);
                    resolve();
                });
        } else {
            resolve();
        }
    });

    _defer.then(function () {
        if (window.bsroOmniStoreFinder) {
            _data.siteName = 'BSRO';
        }
        if (isSeoPage) {
            console.log('_data.city ', _data.city);
            console.log('_data.state ', _data.state);
            if (
                _data.city == '' ||
                _data.state == '' ||
                _data.zipCode == '' ||
                parseInt(_data.zipCode) === 0 ||
                parseInt(_data.city) === 0
            ) {
                errorResponse();
                return;
            } else {
                rootEl.querySelector('.search-block .zipcodeError').classList.remove('no-stores-within-range');
            }
        }
        if (_data.state !== '' && _data.city !== '') {
            fetch(`${_service}${queryStringify(_data)}`, { method: 'GET', headers: HTTPHeaders() })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    var storeType = '';
                    var dataStatus = parseInt(data.status);
                    var preferredStoreIndex = null;
                    if (isSeoPage) {
                        if (_data.city == '' || _data.state == '' || _data.zipCode == '') {
                            return;
                        }
                    }

                    if (dataStatus === 200) {
                        coralWaitDisplay(false);

                        if (data && data.success === 'false') {
                            errorResponse();
                            return;
                        }

                        setStores(data.data.stores);

                        if (isSeoPage) {
                            console.log('getStores() ', getStores().length);
                            if (evt && evt.type == 'submit') {
                                geoLocateSuccessful();
                            }

                            if (getStores().length === 0) {
                                errorResponse();
                                return;
                            }

                            if (seoDefaultZipCode !== _inputVal) {
                                rootEl.querySelector('.search-block input[name=zip]').value = _inputVal;
                                setZip(_inputVal);
                            } else {
                                rootEl.querySelector('.search-block input[name=zip]').value = seoDefaultZipCode;
                                setZip(seoDefaultZipCode);
                            }
                        }

                        if (getPreferredStoreNumber()) {
                            setStores(
                                getStores().filter(function (el, idx) {
                                    if (el.storeNumber == getPreferredStoreNumber()) {
                                        preferredStoreIndex = idx;
                                        return el;
                                    }
                                })
                            );

                            if (preferredStoreIndex != null) {
                                setStores(getStores().splice(preferredStoreIndex, 1));
                            }

                            if (preferredStore.length != 0) {
                                setStores([preferredStore[0]].concat(getStores()));
                            }
                        }

                        if (evt && evt.type == 'submit') {
                            // keeping this here for backwards compatibility.
                            window.digitalData.event && (window.digitalData.event[0].eventName = 'store.search');

                            // new using setVar "searchData"
                            window._satellite.setVar('searchData', _data);
                            window._satellite.track('store.search');
                        }

                        // Make Global for iframe and load map iframe
                        window.bsro_stores = getStores();

                        var mapSrc = `/content/bsro/${window.AEM.siteName}/static/map.stores.${getStores()[0].zip}.html`;
                        rootEl.querySelector('iframe.map').setAttribute('src', mapSrc);

                        for (var i = 0; i < getStores().length; i++) {
                            var _store = getStores()[i];

                            _store.count = i + 1;
                            _store.mystore = _currentStore == _store.storeNumber ? true : false;
                            _store.myPreferredStore = _store.storeNumber == getPreferredStoreNumber() ? true : false;
                            _store.activeFlag = parseInt(_store.activeFlag);
                            _store.onlineAppointmentActiveFlag = parseInt(_store.onlineAppointmentActiveFlag);

                            if (getPreferredStoreNumber() != null) {
                                _store.mystore = false;
                            }

                            _store.directions =
                                window.location.hostname == 'localhost'
                                    ? `/content/bsro/${window.AEM.siteName}/en/locate/get-directions.${_store.storeNumber}.html`
                                    : `/locate/get-directions/${_store.storeNumber}`;
                            _store.mobileCallLink = `tel:${cleanPhone(_store.phone)}`;
                            _store.hours = cleanHours(_store.hours);
                            _store.holidays = cleanHolidays(_store.holidays, _store.holidayHours);
                            _store.openUntil = openUntil(_store.hours, _store.holidays, _store.holidayHours);
                            _store.tireDetailPageSwitch = tireDetailPageSwitch();
                            storeType = _store.storeType;

                            const _clone = _result_tmpl.cloneNode(true);
                            const _html = _clone.innerHTML;
                            const _template = Handlebars.compile(_html);
                            const _result = _template(_store);
                            _container.appendChild(document.createRange().createContextualFragment(_result));
                        }
                        // Code to make the show more stores in local seo pages
                        if (isSeoPage && mobileBreakPoint.matches) {
                            // Move the button to the correct position
                            let toggleButton = document.querySelector('.find-store-show-more-toggle.btn');
                            _container.appendChild(toggleButton);

                            const showMoreButton = document.querySelector('.find-store-show-more-toggle');
                            let currentIndex = 3;
                            if (showMoreButton) {
                                const allResults = document.querySelectorAll('.appt-step-1-results .result');

                                for (let i = 3; i < allResults.length; i++) {
                                    allResults[i].style.display = 'none';
                                }

                                showMoreButton.addEventListener('click', () => {
                                    const hiddenResults = document.querySelectorAll('.appt-step-1-results .result');
                                    const endIndex = Math.min(currentIndex + 3, hiddenResults.length);

                                    for (let i = currentIndex; i < endIndex; i++) {
                                        hiddenResults[i].style.display = 'block';
                                    }

                                    currentIndex = endIndex;

                                    // Hide the button if there is no more stores
                                    if (currentIndex >= hiddenResults.length) {
                                        showMoreButton.style.display = 'none';
                                    }
                                });
                            }
                        }
                        // end  Code to make the show more stores in local seo pages

                        if (tireDetailPageSwitch()) {
                            reviewsReactAppLoader(store);
                            reviewsReduxLoaderV2(store);
                        } else {
                            // annouce that new reviews-apps have been created
                            const reviewsSummary = Array.from(
                                rootEl.querySelectorAll('.appt-step-1-results .reviews-app')
                            );
                            pubsub.publish(topics.CREATE_REVIEWS, [reviewsSummary]);

                            // setInterval waiting for the rendered results
                            let reviewsReady = setInterval(function () {
                                let reviewsContainer = Array.from(
                                    rootEl.querySelectorAll('.appt-step-1-results .reviews')
                                );
                                if (reviewsContainer.length === getStores().length) {
                                    // setTimeout waiting for the DOM insertion
                                    setTimeout(() => {
                                        pubsub.publish(topics.CREATE_REVIEWS_READY, [reviewsContainer]);
                                    }, 500);
                                    clearInterval(reviewsReady);
                                }
                            }, 500);
                        }

                        if (storeType === 'FFC') {
                            rootEl.querySelectorAll('.info .cta').forEach(function (element) {
                                element.style.display = 'none';
                            });

                            rootEl.querySelectorAll('.info-2 .cta').forEach(function (element) {
                                element.style.display = 'none';
                            });

                            var storeDetailsCta = rootEl.querySelector('a.store-details-cta');
                            while (storeDetailsCta.firstChild) {
                                storeDetailsCta.parentNode.insertBefore(storeDetailsCta.firstChild, storeDetailsCta);
                            }
                            storeDetailsCta.parentNode.removeChild(storeDetailsCta);
                        }

                        if (window.bsroOmniStoreFinder) {
                            rootEl.querySelectorAll('.info .cta').forEach(function (element) {
                                element.style.display = 'none';
                            });
                        }

                        const apptStep = rootEl.querySelector('.appt-step');
                        const widemapView = apptStep.classList.contains('widemap');
                        if (widemapView) {
                            const searchBlockResultsCount = rootEl.querySelector('.search-block .results-count');
                            searchBlockResultsCount.textContent = getStores().length.toString();
                        }

                        const selectStoreBtns = rootEl.querySelectorAll('[data-select-store]');
                        selectStoreBtns.forEach(function (selectStoreBtn) {
                            selectStoreBtn.addEventListener('click', (evt) => {
                                const el = evt.currentTarget;
                                let resultIdx = Array.from(el.closest('.result').parentNode.children).indexOf(
                                    el.closest('.result')
                                );

                                if (resultIdx > 2) {
                                    resultIdx = resultIdx - 2;
                                }

                                setSelectedStoreNum(el.dataset.storeNumber);
                                setSelectedStore(getStores()[resultIdx]);
                                const selectedStore = getSelectedStore();
                                const selectedZip = selectedStore?.zip?.substr(0, 5);
                                const selectedStoreNumber = selectedStore?.storeNumber;
                                setZip(selectedZip);
                                setNewZipStore(selectedStoreNumber);

                                const callcta = 'tel:' + selectedStore.phone.slice(1, 15).replace(/\)\s/g, '-');
                                rootEl.querySelector('.appt-step-2 .call-cta')?.setAttribute('href', callcta);

                                window.dtm_tag(evt);

                                var storeInfo = rootEl.querySelector('span.storeInfo');
                                if (storeInfo) {
                                    storeInfo.innerHTML = '';
                                    storeInfo.innerHTML +=
                                        '<strong>Store: #' + selectedStore.storeNumber + '</strong><br/>';
                                    storeInfo.innerHTML += selectedStore.address + '<br/>';
                                    storeInfo.innerHTML += selectedStore.city + ', ' + selectedStore.state;
                                }

                                searchZipInput.value = getZip();
                                validateSearchInput(rootEl, getZip());

                                selectStore(getZip(), getSelectedStoreNum(), rootEl, props);
                            });
                        });
                    } else {
                        errorResponse();
                    }
                })
                .catch(function (error) {
                    errorResponse();
                });
        }
    });

    const setNewZipStore = async (_storeNumber) => {
        let storeData = await setStoreByStoreNumber(_storeNumber);
        if (storeData?.success === 'false' || storeData?.data?.success === 'false') {
            console.error('Error setting store by zip:');
        } else {
            window.siteProfile.location.storeNumber = storeData.storeNumber;
            window.siteProfile.location.myZip = storeData?.zip?.substr(0, 5);
            setLocalStorageItem('storeData', storeData);
        }
    };
};

export { findStores };
