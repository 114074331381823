import '../scss/articles-grid.scss';

import Swiper from 'swiper/bundle';
import { component } from 'common/js/library/component';

const instanceInit = (rootEl) => {
    console.log('RecentArticleList init');

    const breakPointSmall = 768;
    const width = window.innerWidth;
    const isMobile = width < breakPointSmall;
    if (isMobile) {
        initSwiper(rootEl);
    }
};

const initSwiper = (rootEl) => {
    const carousel = rootEl.querySelector('.swiper');
    const slidesPhone = Number(carousel.getAttribute('data-slides-phone')) || 1;

    const swiperSlides = rootEl.querySelectorAll('.carousel .swiper-slide');

    [...swiperSlides].forEach((el) => {
        rootEl.querySelector('.swiper-wrapper')?.insertAdjacentElement('beforeend', el);
    });

    new Swiper(carousel, {
        loop: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        spaceBetween: 20,
        breakpoints: {
            320: {
                slidesPerView: slidesPhone,
                slidesPerGroup: slidesPhone
            }
        }
    });
};

component({
    selector: '.articles-grid',
    instanceInit,
    name: 'ArticleGridCarousel'
});
