import { addStores } from './addStores';
import { getAvailabilityForMultipleStores } from './getAvailabilityForMultipleStores';
import { dateToYYYYMMDD } from 'common/js/util/date-time/dateToYYYYMMDD';
import { getStoreByStoreNumber } from 'common/js/data/services/store-location';
import { initModalPopover } from 'common/js/util/coral-modal/initModalPopover';

/**
 * gets new stores data or retrieves existing data
 * @param {element} rootEl
 * @param {object} [response] - response data for different types of store requests.
 * @param {object} [props] - additional properties needed
 * @returns
 */
const getStoresData = async (rootEl, props) => {
    const {
        response = null,
        getRadius,
        queryStoreNumber,
        isLoggedIn,
        preferredStoreNumber,
        setDateSelectorIndex,
        dateStoreStep,
        numDays,
        datesArr,
        addedServicesListArr,
        getStoresInfo,
        setStoresInfo,
        completeDateTimeSelection
    } = props;
    const mapFixed = dateStoreStep?.querySelector('.map-fixed');
    const storeEls = rootEl.querySelectorAll('.stores');
    const storesMax = 10;
    const siteProfileStoreNumber = window?.siteProfile?.location?.storeNumber;
    // if query string store number prioritize that.
    // if not, check the site profile store number which is the active set store.
    // if not, check if preferred store number exists. otherwise blank.  when logged in, this will be the user's preferred store.
    let useStoreNumber = queryStoreNumber || siteProfileStoreNumber || preferredStoreNumber || '';
    let storesData = getStoresInfo().storesData?.concat();
    let preferredStore = [];
    let storesArr = [];
    let err = ''; // this was killed in a merge conflict and is required. if that happens again.

    // if we have response data, add it to the store data
    if (response !== null) {
        if (response.data?.message) {
            err = response.data.message;
            storesData = [];
            setStoresInfo({ storesData });
        } else if (response.message?.error) {
            err = response.message.error;
            storesData = [];
            setStoresInfo({ storesData });
        } else if (response.data?.error) {
            err = response.data.message;
            storesData = [];
            setStoresInfo({ storesData });
        } else {
            storesData = response.data?.stores;

            // sort stores so that the useStoreNumber is first
            storesData.sort((a) => {
                if (a.storeNumber == useStoreNumber) {
                    return -1;
                } else {
                    return 0;
                }
            });

            setStoresInfo({ storesData });

            // if we have an override store number, use it
            if (useStoreNumber) {
                const response = await getStoreByStoreNumber(useStoreNumber);
                preferredStore.push(response.data);
            }
        }
    }
    mapFixed.classList.remove('hide');

    // if no store data was returned, display message.
    if (storesData.length === 0) {
        if (err) {
            storeEls.forEach((item) => {
                item.innerHTML = `<p class="noStoresFound">${err}</p>`;
                item.classList.remove('storeSelected');
            });
            mapFixed.classList.add('hide');

            if (!queryStoreNumber) {
                return;
            }
        } else {
            storeEls.forEach((item) => {
                item.innerHTML = '<p class="noStoresFound">No Stores Found</p>';
                item.classList.remove('storeSelected');
            });
            mapFixed.classList.add('hide');

            if (!queryStoreNumber) {
                return;
            }
        }
    } else if (getRadius()) {
        // if we have a radius, filter stores by distance radius
        storesArr = storesData.filter((item) => {
            const distance = parseInt(item.distance);

            return distance <= getRadius();
        });
        setStoresInfo({ stores: storesArr });

        // TODO: Notify user no stores were found with the specified or default
        // radius.
        if (storesArr.length === 0) {
            storeEls.forEach((item) => {
                item.innerHTML = '<p class="noStoresFound">No Stores Within Range</p>';
                item.classList.remove('storeSelected');
            });

            window?.scheduleAppointmentMap?.buildMap(storesData);

            if (!queryStoreNumber) {
                return;
            }
        }
    }

    // is store provided in query string, force the store
    if (queryStoreNumber) {
        const tabMyStoreContainer = document.querySelector('.primary-nav #tab-my-store-container');

        const tabStoreContainer = await fetch(
            `/content/bsro/${window.AEM.siteName}/static/top-nav/tab-store.${queryStoreNumber}.html`
        )
            .then((response) => {
                return response.text(); // When the page is loaded convert it to text
            })
            .then((html) => {
                const parser = new DOMParser();

                return parser.parseFromString(html, 'text/html');
            });

        let $map = tabStoreContainer?.querySelector('.col.map img');
        $map.setAttribute('src', $map.dataset.src);

        document.body.classList.remove('no-local-store');
        document.body.classList.add('has-local-store');

        const xmlSerializer = new XMLSerializer();
        const html = xmlSerializer.serializeToString(tabStoreContainer);

        tabMyStoreContainer.innerHTML = html;

        // from common
        window.setTimeout(initModalPopover, 500);
    }

    if (storesArr) {
        storesArr.splice(storesMax); // redux set to max number of stores
        setStoresInfo({ storeNumbers: [] }); // clear store numbers so we can add new ones

        // assign store data
        storesArr.forEach((item) => {
            const storeNum = item.storeNumber;
            const daytimes = item.daytimes || [];

            for (let dt = 0; dt < daytimes.length; dt++) {
                const times = daytimes[dt].times;

                if (times.toString().indexOf(':30') != -1) {
                    item['hasHalfHourIncrements'] = true;
                    break;
                } else {
                    item['hasHalfHourIncrements'] = false;
                }
            }

            let storesAsDataObj = Object.assign({}, getStoresInfo().storesAsDataObj);
            storesAsDataObj[storeNum] = item;
            let storeNumbers = getStoresInfo().storeNumbers.concat();
            storeNumbers.push(storeNum);
            setStoresInfo({ storesAsDataObj, storeNumbers });
        });

        addStores(rootEl, {
            numDays,
            stores: storesArr,
            setDateSelectorIndex,
            datesArr,
            preferredStoreNumber,
            getStoresInfo,
            setStoresInfo
        });
        let serviceDate = dateToYYYYMMDD(new Date());
        getAvailabilityForMultipleStores(rootEl, {
            addedServicesListArr,
            serviceDate,
            setDateSelectorIndex,
            numDays,
            getStoresInfo,
            setStoresInfo,
            dateStoreStep,
            completeDateTimeSelection
        });
    }

    window.scheduleAppointment.stores = storesArr;
};

export { getStoresData };
