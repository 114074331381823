import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    getVehicleYears,
    getVehicleMakes,
    getVehicleModels,
    getVehicleTrim
} from 'common/js/data/services/vehicle/vehicleData';

const VehicleSelectorWrapper = (props) => {
    const { onVehicleSelected, initVehicleSelector, zip, storeNumber, basePath } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState({
        years: [],
        makes: [],
        models: [],
        trims: []
    });
    const [selections, setSelections] = useState({
        year: '',
        make: '',
        model: '',
        trim: '',
        tpms: '',
        id: ''
    });
    const [preselectCounter, setPreselectCounter] = useState(0);
    const [preselectStatus, setPreselectStatus] = useState(false);

    useEffect(() => {
        console.log('VSwrapper selections:', { selections });
    }, [selections]);

    useEffect(async () => {
        let response;
        try {
            response = await getVehicleYears(null, basePath);
        } catch (err) {
            console.log(err);
        }

        if (response) {
            setOptions({
                years: response.data.year,
                makes: [],
                models: [],
                trims: []
            });
        }

        // if wrapped vehicle selector needs to initialize something after wrapper has initialized, trigger it now.
        if (initVehicleSelector) {
            initVehicleSelector();
        }
    }, []);

    const requestVehicleYears = async () => {
        let response = {};
        setIsLoading(true);
        try {
            response = await getVehicleYears('tce', basePath);
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);

        return response.data.year;
    };

    const reset = (setFieldValue) => {
        setFieldValue('make', '');
        setFieldValue('model', '');
        setFieldValue('trim', '');

        const tempOptions = {
            ...options,
            ...{ makes: [], models: [], trims: [] }
        };
        const tempSelections = {
            ...selections,
            ...{ make: '', model: '', trim: '', tpms: '', id: '' }
        };

        setOptions(tempOptions);
        setSelections(tempSelections);
    };

    const handleClearYear = () => {
        setSelections({
            year: '',
            make: '',
            model: '',
            trim: '',
            tpms: '',
            id: ''
        });
    };

    const handleRevertToYear = async (setFieldValue, selection = selections.year) => {
        const e = { target: { value: selection } };
        handleSelectYear(e, setFieldValue);
    };

    const handleRevertToMake = async (setFieldValue, selection = selections.make) => {
        const e = { target: { value: selection } };
        handleSelectMake(e, setFieldValue);
    };

    const handleRevertToModel = async (setFieldValue, selection = selections.model) => {
        const e = { target: { value: selection } };
        handleSelectModel(e, setFieldValue);
    };

    const handleSelectYear = async (e, setFieldValue) => {
        setPreselectStatus(false);
        reset(setFieldValue);
        const year = e.target.value;
        const makes = await requestVehicleMakes(year);

        setOptions({
            ...options,
            ...{ makes, models: [], trims: [] }
        });
        setSelections({
            ...selections,
            year,
            make: '',
            model: '',
            trim: '',
            tpms: '',
            id: ''
        });
    };

    const requestVehicleMakes = async (year) => {
        let response = {};
        setIsLoading(true);
        try {
            response = await getVehicleMakes('tce', year, basePath);
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);

        return response.data.makes;
    };

    const handleSelectMake = async (e, setFieldValue) => {
        setPreselectStatus(false);
        setFieldValue('model', '');
        setFieldValue('trim', '');

        const tempOptions = {
            ...options,
            ...{ models: [], trims: [] }
        };
        const tempSelections = {
            ...selections,
            ...{ model: '', trim: '', tpms: '', id: '' }
        };

        setOptions(tempOptions);
        setSelections(tempSelections);

        const make = e.target.value;
        const models = await requestVehicleModels(selections.year, make);
        let model = '';
        let trim = '';
        let tpms = '';
        let id = '';
        let trims = [];

        if (models.length === 1) {
            model = models[0];
            const trimData = await requestVehicleTrims(selections.year, make, model);
            trims = trimData.trims;

            if (trims.length === 1) {
                const values = trims[0];
                trim = values.trim;
                tpms = values.tpms;
                id = values.acesVehicleId;
            }
        }

        setOptions({
            ...options,
            ...{ models, trims: trims }
        });

        setSelections({
            ...selections,
            make,
            model,
            trim,
            tpms,
            id
        });
    };

    const requestVehicleModels = async (year, make) => {
        let response = {};
        setIsLoading(true);
        try {
            response = await getVehicleModels('tce', year, make, basePath);
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);

        return response.data.models;
    };

    const handleSelectModel = async (e, setFieldValue) => {
        setPreselectStatus(false);
        setFieldValue('trim', '');

        const tempOptions = { ...options, trims: [] };
        const tempSelections = {
            ...selections,
            ...{ trim: '', tpms: '', id: '' }
        };

        setOptions(tempOptions);
        setSelections(tempSelections);

        const model = e.target.value;
        const trimData = await requestVehicleTrims(selections.year, selections.make, model);
        const trims = trimData.trims;

        setOptions({
            ...options,
            ...{ trims }
        });
        setSelections({ ...selections, model, trim: '', tpms: '', id: '' });

        if (trims.length === 1) {
            const values = trims[0];
            const finalSelections = {
                ...selections,
                ...{ model: model, trim: values.trim, tpms: values.tpms, id: values.vehicleId }
            };

            setFinalSelections(finalSelections);
        }
    };

    const requestVehicleTrims = async (year, make, model) => {
        let response = {};
        setIsLoading(true);
        try {
            if (typeof year === 'undefined' || typeof make === 'undefined' || typeof model === 'undefined') {
                throw new Error(
                    'One or more required parameters (year, make, model) are undefined in Vehicle Selector Wrapper'
                );
            }
            response = await getVehicleTrim('tce', year, make, model, basePath);
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);

        return response.data;
    };

    const handleSelectTrim = (e) => {
        setPreselectStatus(false);
        const values = JSON.parse(e.target.value);
        const finalSelections = {
            ...selections,
            ...{ trim: values.trim, tpms: values.tpms, id: values.vehicleId }
        };

        setFinalSelections(finalSelections);
    };

    const setFinalSelections = (finalSelections) => {
        setSelections(finalSelections);
    };

    const preselectVehicle = async (vehicle) => {
        if (!vehicle) {
            return;
        }

        let years = [];
        let makes = [];
        let models = [];
        let trims = [];
        let { year, make, model, trim, tpms, engine } = vehicle;
        let id = '';

        if (!year || !make || !model || !(trim || engine)) {
            return;
        }

        model = model ? decodeURIComponent(model.replace(/\+/g, ' ')) : '';
        trim = trim ? decodeURIComponent(trim.replace(/\+/g, ' ')).trim() : '';
        tpms = tpms || '0';
        years = await requestVehicleYears();
        makes = await requestVehicleMakes(year);
        models = await requestVehicleModels(year, make);
        const trimData = await requestVehicleTrims(year, make, model);
        trims = trimData.trims;
        // use request data over profile data to prevent mismatches
        year = trimData.year;
        make = trimData.make;
        model = trimData.model;

        if (trim !== '' && trim !== undefined) {
            trims.map((item) => {
                if (item.trim.toLowerCase() === trim.toLowerCase()) {
                    trim = item.trim;
                    tpms = item.tpms;
                    id = item.acesVehicleId;
                }
            });
        }

        setFinalSelections({ year, make, model, trim, tpms, id });
        setOptions({
            years,
            makes,
            models,
            trims
        });
        setPreselectCounter(4);
    };

    const formProps = {
        ...options,
        selectedYear: selections.year,
        selectedMake: selections.make,
        selectedModel: selections.model,
        selectedTrim: selections.trim,
        selectedTpms: selections.tpms,
        selectedVehicleId: selections.id,
        clearSelectedYear: handleClearYear,
        onYearSelected: handleSelectYear,
        onMakeSelected: handleSelectMake,
        onModelSelected: handleSelectModel,
        onTrimSelected: handleSelectTrim,
        onRevertToYear: handleRevertToYear,
        onRevertToMake: handleRevertToMake,
        onRevertToModel: handleRevertToModel
    };

    const children = React.Children.map(props.children, (child) => {
        return React.cloneElement(child, {
            ...formProps,
            onVehicleSelected,
            preselectVehicle,
            preselectCounter,
            isLoading,
            zip,
            storeNumber,
            setPreselectStatus,
            preselectStatus
        });
    });

    return <>{children}</>;
};

VehicleSelectorWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    initVehicleSelector: PropTypes.func,
    onVehicleSelected: PropTypes.func.isRequired,
    zip: PropTypes.string,
    storeNumber: PropTypes.string
};

export { VehicleSelectorWrapper };
