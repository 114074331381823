import React, { Suspense, lazy, useEffect, useState } from 'react';
import { DynamicPortal } from 'common/components/content/react-shared/utils/DynamicPortal';
import { prConfig } from 'common/components/content/react-shared/utils/power-reviews/prConfig';
import { pubsub } from 'common/js/library/pubsub';
import { ReviewsStarsSpinner } from 'common/components/content/react-shared/ui/spinners-loaders/ReviewsStarsSpinner';
import { reviewsSwitch } from 'common/js/util/reviewsSwitch';
import { stringSanitizer } from 'common/js/util/stringSanitizer';
import { topics } from 'common/js/library/topics';

const ReviewsApp = lazy(() => import(/* webpackChunkName: "reviews/v2" */ './reviewsApp'));

const ReviewsLoaderV2 = () => {
    console.log('reviewsLoaderV2 - reviewsSwitch:', reviewsSwitch());

    if (!reviewsSwitch()) return null;

    // Only initialize uninitialized reviews apps
    const initialReviews = Array.from(document.querySelectorAll('.reviews-app-v2:not([style*="display: none"])'));
    let tireNameTitle = '';
    if (initialReviews.length > 0) {
        tireNameTitle = initialReviews[0].getAttribute('data-tire-name');
    }

    const reviews = (appRoots) => {
        // determine what reviews app it is and apply the matching components.
        const config = prConfig();
        const portalArray = appRoots
            .map((root, i) => {
                // get review type
                const type = root.dataset.type || '';
                const productType = root.dataset.productType || 'tire';
                const id = stringSanitizer(root.dataset.id, 'upper') || '';
                const tireBrand = stringSanitizer(root.dataset.tireBrand, 'lower') || '';
                const tireName = stringSanitizer(root.dataset.tireName, 'lower') || '';
                // const groupId = root.dataset.groupId;
                const groupId = (config && config.groupId) || 0;
                const merchId = (config && config.id) || 0;
                const apiKey = (config && config.apiKey) || '';
                const locale = root.dataset.locale || 'en-US';
                const canReview = (root.dataset.canReview && root.dataset.canReview == 'true') || false;
                const classes = root.dataset.class || '';
                const article = root.dataset.article;
                tireNameTitle = root.dataset.tireNameTitle || tireNameTitle;
                const localPageUrl = root.dataset.url;

                if (type === 'inline-rating' || type === 'reviews') {
                    const uniqueId = `${i}${new Date().getTime()}`;

                    return (
                        <React.Fragment key={i}>
                            <DynamicPortal
                                id={`reviews-portal-${uniqueId}`}
                                className="reviews-v2"
                                dynamicParentElement={root}
                                hideParent={true}
                            >
                                <Suspense fallback={<ReviewsStarsSpinner type={type} className={classes} />}>
                                    <ReviewsApp
                                        type={type}
                                        productType={productType}
                                        id={id}
                                        tireBrand={tireBrand}
                                        tireName={tireName}
                                        groupId={groupId}
                                        merchId={merchId}
                                        apiKey={apiKey}
                                        locale={locale}
                                        canReview={canReview}
                                        classes={classes}
                                        tireNameTitle={tireNameTitle}
                                        article={article}
                                        localPageUrl={localPageUrl}
                                    />
                                </Suspense>
                            </DynamicPortal>
                        </React.Fragment>
                    );
                } else {
                    // console.log('No type found for review app.');
                }

                return null;
            })
            .filter(Boolean);

        return portalArray;
    };

    const [roots, setRoots] = useState(initialReviews);
    const [portals, setPortals] = useState(reviews(roots));

    useEffect(() => {
        // console.log('ReviewsLoaderV2: useEffect-roots:', roots);
        setPortals(reviews(roots));
    }, [roots]);

    pubsub.subscribe(topics.CREATE_REVIEWS_V2, (els) => {
        setRoots(roots.concat(els));
    });

    return portals ? portals : null;
};

export { ReviewsLoaderV2 };
