export default function (data) {
    return `
        <div class="offer-details__offer" data-friendly-id="${data.friendlyId}">
            <div class="offer-details__offer-top">
                <div class="offer-details__offer-header" data-share-modal-title="${data.shareModalTitle}">
                    <h2 class="offer-details__offer-price offer-details__offer-price--mobile">${data.modalPrice}</h2>
                    <h2 class="offer-details__offer-title" data-print-promo="print promo">${data.modalTitle}</h2>
                    <h2 class="offer-details__offer-price offer-details__offer-price--desktop">${data.modalPrice}</h2>
                    <h2 class="offer-details__offer-subtitle">${data.modalSubText}</h2>
                    <h2 class="offer-details__offer-subtitle1">${data.modalSubText1}</h2>
                    <h2 class="offer-details__offer-subtitle2">${data.modalSubText2}</h2>
                    <h3 class="offer-details__offer-summary">${data.modalOfferDetails}</h3>
                </div>
                ${
                    data.defaultOfferImage
                        ? `
                    <div class="offer-details__offer-image">
                        ${
                            data.defaultOfferImage
                                ? `
                            <img src="${data.defaultOfferImage}" class="offer-image" alt="offer image"/>
                        `
                                : ''
                        }
                    </div>
                `
                        : ''
                }
            </div>

            <div class="offer-details__offer-validity">
                <img src="/etc.clientlibs/bsro/${
                    window.AEM.siteName
                }/clientlibs/clientlib-images/v1/resources/icon/offer-expire-icon.png" class="offer-details__offer-validity-icon" alt="">
                <span class="offer-details__offer-validity-date">
                    ${data.validDateDays}
                </span>
            </div>

            <div class="offer-details__modal-ctas">
                <a href="#text-to-phone-" class="btn offer-details__cta-phone btn--v2">
                    Send to Me
                </a>
	            <a href="${
                    data.printUrl
                }" class="btn dL_couponPrint offer-details__cta-print btn--v2 btn--v2-secondary" data-print="print-offer">
		            Print
	            </a>
			</div>

            <div class="offer-details__bottom">
                <div class="offer-details__highlight">
                    <span class="offer-details__highlight-title">What You Get</span>
                    <div class="offer-details__highlight-summary">${data.modalOfferDescription}</div>
                    <a class="offer-details__highlight-link" href="${
                        data.landingUrl
                    }" target="_blank" rel="noopener noreferrer">${data.rebateText}</a>
                </div>

                <div class="offer-details__body">
                    <span class="offer-details__body-title">The Fine Print</span>
                    <span class="offer-details__body-validity">${data.validDateRange}</span>
                    <div class="offer-details__body-copy">
                        <p>${data.disclaimer}</p>
                    </div>
                </div>
            </div>
        </div>
    `;
}
