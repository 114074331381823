import { convertVehicleToVehicleFromAccount } from './convertVehicleToVehicleFromAccount';
import { convertVehicleFromAccountToVehicle } from './convertVehicleFromAccountToVehicle';
import { Vehicle } from 'common/js/util/vehicle-repository/vehicle';
import {
    getLocalStorageItem,
    setLocalStorageItem,
    deleteLocalStorageItem
} from 'common/js/data/localstorage/localStorageService';
import { getCookie } from 'common/js/library/util';
import { setVehicle } from 'common/js/data/services/vehicle/vehicleData';

function expirationDate() {
    return new Date(Date.now() + 24 * 60 * 60 * 1000);
}

export class ProfileVehiclesStore {
    static getVehicles() {
        const vehicles = getLocalStorageItem('profileVehicles');
        if (!vehicles) {
            // if cache is expired, then fetch from server.
            const tokenCookie = getCookie('tokenId');
            return fetch('/bsro/services/account/get', {
                method: 'GET',
                headers: {
                    tokenId: tokenCookie
                }
            })
                .then((response) => response.json())
                .then((json) => {
                    return json?.data?.account?.VEHICLE
                        ? json.data.account.VEHICLE.map(convertVehicleFromAccountToVehicle)
                        : [];
                })
                .then((vehicles) => {
                    setLocalStorageItem('profileVehicles', vehicles, expirationDate());
                    return vehicles;
                });
        }
        const result = vehicles.map((v) => new Vehicle(v));
        return Promise.resolve(result);
    }

    static refresh() {
        // Force the vehicles to be fetched from the server.
        deleteLocalStorageItem('profileVehicles');
        return ProfileVehiclesStore.getVehicles();
    }

    /**
     * Adds a new vehicle or updates an existing one. The difference is
     * if an `id` is provided, it will update the vehicle.
     * @param {Vehicle} vehicle The vehicle to add or update.
     * @param {function} callback A callback function to call when the vehicle is added
     * @returns A promise that resolves to the updated list of vehicles.
     */
    static addOrUpdateVehicle(vehicle) {
        // undefined fields will not be serialized.
        const profileVehicle = convertVehicleToVehicleFromAccount(vehicle);
        const { YEAR, MAKE, MODEL, SUBMODEL, TPMS } = convertVehicleToVehicleFromAccount(vehicle);
        return setVehicle(YEAR, MAKE, MODEL, SUBMODEL, TPMS)
            .then(() => {
                return fetch('/bsro/services/account/update-section', {
                    method: 'POST',
                    headers: {
                        tokenId: getCookie('tokenId'),
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ VEHICLE: [profileVehicle] })
                });
            })
            .then((response) => response.json())
            .then((json) => {
                return json?.data?.account?.VEHICLE
                    ? json.data.account.VEHICLE.map(convertVehicleFromAccountToVehicle)
                    : [];
            })
            .then((vehicles) => {
                setLocalStorageItem('profileVehicles', vehicles, expirationDate());
                return vehicles;
            })
            .catch((error) => {
                console.error('Error adding vehicle:', error);
                return [];
            });
    }

    /**
     * Removes a vehicle from the user's profile. The ID is that of the vehicle
     * from the server.
     * @param {number|string} vehicleId The vehicle ID to remove.
     * @returns A promise that resolves to the updated list of vehicles.
     */
    static removeVehicle(vehicleId) {
        return fetch('/bsro/services/account/delete-vehicle', {
            method: 'POST',
            headers: {
                tokenId: getCookie('tokenId'),
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ vehicleId: String(vehicleId) })
        })
            .then((response) => response.json())
            .then((json) => {
                return json?.data?.account?.VEHICLE
                    ? json.data.account.VEHICLE.map(convertVehicleFromAccountToVehicle)
                    : [];
            })
            .then((vehicles) => {
                setLocalStorageItem('profileVehicles', vehicles, expirationDate());
                return vehicles;
            })
            .catch((error) => {
                console.error('Error deleting vehicle:', error);
                return [];
            });
    }
}
