/**
 * Converts a time string in 'HH:MMam' or 'HH:MMpm' format into an object that 
 * separates and formats the hours and minutes into a 24-hour time format. This 
 * function is particularly useful for parsing time strings into a more usable 
 * format in applications that require time processing.
 * 
 * converts an appointment time (3:00pm) to an object with hour and minutes
 *
 * @param {string} time - The time string in 'HH:MMam/pm' format.
 * @returns {{hour: string, minutes: string}} An object containing the hours 
 *          and minutes converted into strings with 24-hour formatting.
 */
export const timeToHourMinutes = (time) => {
    let hour = Number(time.split(':')[0]);
    hour = hour === 12 ? 0 : hour;
    hour = time.includes('pm') ? hour + 12 : hour;
    hour = hour.toString().padStart(2, '0');
    let minutes = time.split(':')[1].substring(0, 2);
    minutes = minutes.padStart(2, '0'); 

    return {
        hour,
        minutes
    };
};
