const toggleStoreSelection = (visible = true) => {
    const dateStore = document.querySelector('.step.date-store');
    const className = 'no-zip';

    if (visible) {
        dateStore.classList.remove(className);
    } else {
        dateStore.classList.add(className);
    }
};

export { toggleStoreSelection };
