import { CurrentVehicleStore } from './current-vehicle-store.js';
import { ProfileVehiclesStore } from './profile-vehicles-store.js';

export class VehicleRepository {
    #vehicles = new Array();
    #loading = Promise.resolve();
    static #instance = null;

    constructor() {
        if (VehicleRepository.#instance) {
            return VehicleRepository.#instance;
        }
        VehicleRepository.#instance = this; // Singleton pattern
        this.#loading = this.load();
    }

    ready() {
        return this.#loading;
    }

    /**
     * Sets the current vehicle for the user. This is the vehicle used for searches and appointments.
     * Throws an exception if the vehicle object is incomplete. This adds the vehicle to the repository
     * which is why it is async.
     * @param {Vehicle} vehicle A vehicle object containing year, make, model and either trim or engine.
     * @param {boolean} alreadyCreatedFromAccount Whether the vehicle was already created from the account.
     * @param {function} callback A callback function to call when the vehicle is set.
     */
    async setCurrentVehicle(vehicle) {
        await this.ready();
        CurrentVehicleStore.setVehicle(vehicle);
        return this.#loading;
    }

    /**
     * Gets the current vehicle for the user. This is the vehicle used for searches and appointments.
     * It may be empty or incomplete.
     * @param {boolean} withEngine Set true to include the engine in the vehicle. Like for battery search.
     * @returns {Vehicle} The current vehicle for the user.
     */
    getCurrentVehicle(withEngine = false) {
        return CurrentVehicleStore.getVehicle(withEngine);
    }

    /**
     * Loads the vehicles from storage
     * @param {boolean} force Set true to force a refresh from the server.
     * @returns {Promise<number>} A promise that resolves to the number of vehicles loaded.
     */
    async load(force = false) {
        await this.ready();
        const loadVehicles = force ? ProfileVehiclesStore.refresh() : ProfileVehiclesStore.getVehicles();
        this.#loading = loadVehicles.then((vehicles) => {
            this.#vehicles = vehicles;
            return this.#vehicles.length;
        });
        return this.#loading;
    }

    /**
     * Adds a vehicle to the repository. This will sync the local storage with the server.
     * If the vehicle already exists, it will update the existing vehicle.
     * @param {Vehicle} vehicle The vehicle to add.
     * @param {boolean} alreadyCreatedFromAccount Whether the vehicle was already created from the account.
     * @param {boolean} allowDuplicate Whether to allow duplicate vehicles.
     * @param {function} callback A callback function to call when the vehicle is added.
     * @returns {Promise<void>} A promise that resolves to updated number of vehicles
     */
    async addVehicle(vehicle, alreadyCreatedFromAccount, allowDuplicate = false) {
        await this.ready();
        let changed = true;
        const matched = this.#vehicles.find((v) => v.equals(vehicle));
        if (matched && !allowDuplicate) {
            changed = matched.update(vehicle);
            if (changed) {
                vehicle = matched;
            }
        }
        CurrentVehicleStore.setVehicle(vehicle);
        if (!alreadyCreatedFromAccount) {
            if (!changed) {
                // Nothing changed return the current count
                this.#loading = Promise.resolve(this.#vehicles.length);
            } else {
                this.#loading = ProfileVehiclesStore.addOrUpdateVehicle(vehicle).then((vehicles) => {
                    this.#vehicles = vehicles;
                    return this.#vehicles.length;
                });
            }
        } else {
            this.#loading = Promise.resolve(this.#vehicles.length);
        }
        return this.#loading;
    }

    /**
     * Removes a vehicle from the repository.
     * @param {Vehicle|number} vehicle The vehicle to remove. or the id of the vehicle to remove.
     * @returns {Promise<void>} A promise that resolves when the vehicle has been removed.
     */
    async removeVehicle(vehicle) {
        await this.ready();
        let vehicleId = typeof vehicle !== 'object' ? Number(vehicle) : vehicle.id;
        if (!vehicleId) {
            const matched = this.#vehicles.find((v) => v.equals(vehicle));
            vehicleId = matched?.id;
        }
        if (!vehicleId) {
            console.error(`Vehicle ${vehicleId} not found`);
            this.#loading = Promise.resolve(this.#vehicles.length);
        } else {
            this.#loading = ProfileVehiclesStore.removeVehicle(vehicleId).then((vehicles) => {
                this.#vehicles = vehicles;
                return this.#vehicles.length;
            });
        }
        return this.#loading;
    }

    /**
     * Returns the vehicles in the repository as an iterator. It's not exposing the internal array to prevent
     * accidental modification.
     * @returns {Iterator} An iterator of vehicles.
     */
    vehicles() {
        return this.#vehicles.entries();
    }

    setVehicles(vehicles) {
        this.#vehicles = vehicles;
    }
}
