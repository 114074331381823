import '../scss/product-carousel.scss';
import Swiper from 'swiper/bundle';
import { component } from 'common/js/library/component';

const instanceInit = (rootEl) => {
    console.log('inside product-carousel.js');

    initSwiper(rootEl);

    let productContainers = rootEl.querySelectorAll('.slide-content');
    productContainers.forEach((productContainer) => {
        productContainer?.addEventListener('click', (event) => {
            let anchor = event.currentTarget.querySelector('.product-link');
            if (anchor) {
                if (
                    event.target.classList.contains('btn-inline') ||
                    event.target.parentElement.classList.contains('.review-count')
                ) {
                    return;
                } else {
                    event.stopPropagation();
                    window.location.href = `${window.location.origin}${anchor.getAttribute('href')}`;
                }
            }
        });
    });
};

const initSwiper = (rootEl) => {
    let carousel = rootEl.querySelector('.swiper');
    if (carousel) {
        let slidesDesktop = Number(carousel.getAttribute('data-slides-desktop')) || 4;
        let slidesTablet = Number(carousel.getAttribute('data-slides-tablet')) || 3;
        let slidesPhablet = Number(carousel.getAttribute('data-slides-phablet')) || 2;
        let slidesPhone = Number(carousel.getAttribute('data-slides-phone')) || 1;

        let swiperSlides = rootEl.querySelectorAll('.carousel .swiper-slide');
        let carouselscrollBar = rootEl.querySelector('.swiper-scrollbar');

        [...swiperSlides].forEach((el) => {
            rootEl.querySelector('.swiper-wrapper').insertAdjacentElement('beforeend', el);
        });

        rootEl.querySelector('.carousel')?.remove();

        const checkArrowAndDots = (rootEl) => {
            const slides = rootEl.querySelectorAll('.swiper-wrapper .swiper-slide');
            const swiperPrev = rootEl.querySelector('.swiper-button-prev');
            const swiperNext = rootEl.querySelector('.swiper-button-next');
            const swiperPagination = rootEl.querySelector('.swiper-pagination');

            if (window.innerWidth > 1024 && slides.length > 4) {
                swiperPrev.style.display = 'block';
                swiperNext.style.display = 'block';
                swiperPagination.style.display = 'none';
            } else {
                swiperPrev.style.display = 'none';
                swiperNext.style.display = 'none';
                swiperPagination.style.display = 'block';
            }
        };

        new Swiper(carousel, {
            loop: false,
            centeredSlides: swiperSlides.length === 1 ? true : false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            scrollbar: {
                el: carouselscrollBar,
                draggable: true,
                hide: true
            },
            spaceBetween: 20,
            breakpoints: {
                320: {
                    slidesPerView: slidesPhone,
                    slidesPerGroup: slidesPhone
                },
                600: {
                    slidesPerView: slidesPhablet,
                    slidesPerGroup: slidesPhablet
                },
                768: {
                    slidesPerView: slidesTablet,
                    slidesPerGroup: slidesTablet
                },
                1024: {
                    slidesPerView: slidesDesktop,
                    slidesPerGroup: slidesDesktop
                }
            },
            on: {
                init: () => {
                    checkArrowAndDots(rootEl);
                },
                resize: () => {
                    checkArrowAndDots(rootEl);
                }
            }
        });
    }
};

component({
    selector: '.product-carousel',
    instanceInit,
    name: 'ProductCarousel'
});
