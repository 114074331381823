import { setAdobeDataLayer } from 'common/js/data/services/analytics/adobe-data-layer';

const addService = (evt, addedServicesList, removeCallback, accordionCopy, additionalMessage) => {
    evt.preventDefault();

    let currentTarget = evt.currentTarget;
    const accordion = currentTarget.closest('.accordion-item');
    let checkbox = accordion.children[0]; //input[type=checkbox];
    let serviceName = currentTarget.dataset.addService;

    const dataRemoveService = `data-remove-service="${serviceName}"`;

    let li = `<li>${serviceName} <a href="#" class="btn btn-remove" ${dataRemoveService}></a></li>`;

    if (serviceName === 'Other') {
        const label = 'I’m not sure';
        const placeholder = 'Please provide any additional information about your service request';
        li = `<li class="other-service">${label} <a href="#" class="btn btn-remove" ${dataRemoveService}></a><textarea name="other-service" id="other-service" maxlength="2000" rows="2" placeholder="${placeholder}"></textarea></li>`;
    }

    if (window?.featureSwitches?.isTrue('bsro-ui-pinnacle')) {
        const removeBtn = document.createElement('a');
        removeBtn.setAttribute('href', '#');
        removeBtn.classList.add('btn', 'btn-remove');
        removeBtn.setAttribute('data-remove-service', serviceName);

        if (serviceName === 'Other') {
            const label = document.createElement('label');
            const placeholder = 'Please provide any additional information about your service request';
            const span = document.createElement('span');
            const textarea = document.createElement('textarea');
            textarea.name = 'other-service';
            textarea.id = 'other-service';
            textarea.maxLength = 2000;
            textarea.rows = 2;
            textarea.placeholder = placeholder;
            const img = accordionCopy?.querySelector('img');

            li = document.createElement('li');
            const accItem = document.createElement('div');
            span.append(img);
            span.append(document.createTextNode('I’m not sure'));
            label.append(span);
            label.append(removeBtn);
            li.classList.add('other-service');
            accItem.classList.add('accordion-item');
            accItem.append(label);
            accItem.append(textarea);
            li.append(accItem);
        } else {
            li = document.createElement('li');
            const addButton = accordionCopy?.querySelector('button');
            addButton.remove();
            accordionCopy?.querySelector('label').append(removeBtn);
            li.append(accordionCopy);
        }
    }

    if (serviceName) {
        if (window?.featureSwitches?.isTrue('bsro-ui-pinnacle')) {
            addedServicesList.append(li);
        } else {
            addedServicesList.insertAdjacentHTML('beforeend', li);
        }
        let newEl = addedServicesList?.lastChild;
        const removeServiceBtn = newEl.querySelector('a'); //'[data-remove-service]';

        currentTarget.setAttribute('disabled', true);
        removeServiceBtn.addEventListener('click', (evt) => {
            removeCallback(evt);
        });

        checkbox.checked = false;

        let sdata = {
            service: serviceName,
            addremove: 'add',
            event: 'sa.v2.servicechange'
        };

        setAdobeDataLayer(sdata);
    }

    addedServicesList.closest('.service-items')?.classList?.remove('hide');

    if (addedServicesList.length != 0) {
        additionalMessage.classList.remove('hide');
    } else {
        additionalMessage.classList.add('hide');
    }
    return serviceName;
};

export { addService };
