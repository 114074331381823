import {
    XB_SET_VIEW,
    XB_SET_ZIPCODE,
    XB_SET_STORE,
    XB_SET_VEHICLE_SELECTED,
    XB_SET_STORE_SERVICE,
    XB_SET_CONTACT_INFO,
    XB_SET_DATETIME_SELECTED,
    XB_SET_IS_EDITING,
    XB_SET_SELECTEDTAB,
    XB_SET_TIME_SLOT_ID,
    XB_SET_IS_SCROLLED_PAST
} from '../actions';
// import merge from 'lodash-es/merge';

const { myZip, storeNumber } = window.siteProfile.location;
const zipCodeDefault = myZip || '';
const storeNumberDefault = storeNumber || '';

const initialState = {
    hasInitialized: false, // let's us know when page data-attr have been loaded in
    view: 'loading',
    zipCode: zipCodeDefault,
    isEditing: false,
    isScrolledPast: false,
    store: {
        storeNumber: storeNumberDefault,
        storeName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        hours: [],
        temporarilyClosed: ''
    },
    storeService: {
        locationId: '',
        serviceId: '',
        statusId: '',
        statusDescription: '',
        employeeId: '',
        additionalServices: ''
    },
    vehicleSelected: {},
    contactInfo: {},
    datetimeSelected: {
        selectedTab: 0
    }
};

function expeditedBookingReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case XB_SET_VIEW:
            if (payload) {
                return Object.assign({}, state, { view: payload });
            } else return state;
        case XB_SET_ZIPCODE:
            if (payload) {
                return Object.assign({}, state, { zipCode: payload });
            } else return state;
        case XB_SET_STORE:
            if (payload) {
                return Object.assign({}, state, { store: payload });
            } else return state;
        case XB_SET_VEHICLE_SELECTED:
            if (payload) {
                return Object.assign({}, state, { vehicleSelected: payload });
            } else return state;
        case XB_SET_STORE_SERVICE:
            if (payload) {
                return Object.assign({}, state, { storeService: payload });
            } else return state;
        case XB_SET_CONTACT_INFO:
            if (payload) {
                return Object.assign({}, state, { contactInfo: payload });
            } else return state;
        case XB_SET_DATETIME_SELECTED:
            if (payload) {
                return Object.assign({}, state, { datetimeSelected: payload });
            } else return state;
        case XB_SET_IS_EDITING:
            return Object.assign({}, state, { isEditing: payload });
        case XB_SET_SELECTEDTAB:
            return Object.assign({}, state, {
                datetimeSelected: Object.assign({}, state.datetimeSelected, { selectedTab: payload })
            });
        case XB_SET_TIME_SLOT_ID:
            return Object.assign({}, state, { timeSlotId: payload });
        case XB_SET_IS_SCROLLED_PAST:
            return Object.assign({}, state, { isScrolledPast: payload });
        default:
            return state;
    }
}

export default expeditedBookingReducer;
