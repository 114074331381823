/**
 * Determines the initial sorting order based on the vehicle submitted.
 * Sort hightolow for new vehicles < 4 years old.
 * For Luxury brands new is defined as <8 years old.
 * If the vehicle year or make is not provided, the default sorting order will be 'lowtohigh[LH]'.
 * If provided, we will calculate the difference between current year and the vehicle year, the sort order will be based on the calculated difference and vehicle make.
 * @returns {string} 'lowtohigh' or 'hightolow'
 */

const getInitSubsort = () => {
    const LH = 'lowtohigh';
    const HL = 'hightolow';
    const tce = (window.siteProfile && window.siteProfile.vehicles && window.siteProfile.vehicles.tce) || {};
    const { year, make } = tce;

    if (!year || !make) return LH;

    const currentYear = new Date().getFullYear();
    const lux = [
        'lexus',
        'mercedes-benz',
        'bmw',
        'acura',
        'audi',
        'volvo',
        'land rover',
        'jaguar',
        'tesla',
        'porsche',
        'maserati',
        'bentley',
        'ferrari',
        'lotus',
        'aston martin',
        'rolls-royce',
        'bugatti',
        'fisker',
        'karma',
        'lamborghini',
        'maybach',
        'mclaren',
        'cadillac',
        'lincoln',
        'infiniti'
    ];
    const diff = currentYear - year;
    const maxDiff = lux.includes(make.toLowerCase()) ? 8 : 4;

    return diff > maxDiff ? LH : HL;
};

export { getInitSubsort };
