import { batterySearchByVehicle } from 'common/js/data/services/battery/batterySearchByVehicle';

/**
 * Update the prices displayed on the battery cards. The cards some from the server
 * with a cached national price, but we can get the local price from the server. This
 * function will update the price and label elements on the battery cards.
 * @param {HTMLElement} container The container that hold the battery cards
 * @param {Object} dataPackage An object containing the ymm, engine, and storeNumber
 */
export async function freshenPrices(container, dataPackage) {
    const batteryInfo = await batterySearchByVehicle(dataPackage);
    const batteryCards = container.querySelectorAll('.column');
    batteryCards.forEach((batteryCard) => {
        const priceElement = batteryCard.querySelector('.price');
        const productCode = batteryCard.querySelector('.battery-schedule-appointment-button')?.dataset?.productCode;
        const labelElement = batteryCard.querySelector('.label');
        if (batteryInfo?.success === 'true' && batteryInfo?.data) {
            const webPrice = batteryInfo.data.find((battery) => battery.productCode === productCode)?.webPrice;
            const value = parseFloat(webPrice) * 100;
            if (value) {
                // NaN is falsey, so this will skip any NaN values
                const cents = value % 100;
                const dollars = (value - cents) / 100;
                priceElement.innerHTML = `$${dollars}.<span>${cents}</span>`;
                labelElement.textContent = 'Battery Price';
            }
        }
        // Unhide the price and label element
        priceElement.style.opacity = 1;
        labelElement.style.opacity = 1;
    });
}
