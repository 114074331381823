import { queryStringify, responseError, HTTPHeaders } from './serviceUtilities';
import { getCache, setCache } from 'common/js/util/sessionCache';

/**
 *
 * @param {object} dataPackage - {ymm, trim, storeNumber(optional)}
 * @returns
 */
export const searchByVehicle = async (dataPackage) => {
    try {
        const url = `/bsro/services/tires/search-by-vehicle${queryStringify(dataPackage)}`;

        const cachedData = getCache(url);

        if (cachedData) return cachedData;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        setCache(url, responseJson);

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};

export const searchByTireSize = async (dataPackage) => {
    try {
        const url = `/bsro/services/tires/search-by-tire-size/${queryStringify(dataPackage)}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};
/**
 *
 * @param {object} dataPackage - {crossSection, aspectRatio, rimSize}
 */
export const setTireSize = async (dataPackage) => {
    try {
        const url = `/bsro/services/tires/set-tire-size${queryStringify(dataPackage)}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: HTTPHeaders()
        });

        const responseJson = await response.json();

        if (!response.ok) {
            throw Error(responseError(responseJson));
        }

        return responseJson;
    } catch (err) {
        return { error: err, success: 'false' };
    }
};
