import { component } from 'common/js/library/component';

let keys = '';
if (window.AEM.siteName == 'fcac') {
    keys = {
        dev: 'Av_7iBRODmYJT5dawDxNVbiC1pg48etY4tP9BfPLyp39c_JzC-sAx6hJ5vt4OwjV',
        qa: 'AmoCh2jbmC1l_GMYvxGB-QEXQxCH2c5l06OwwO6_0WiGfotoClQRpLYb_ZhRcKgq',
        live: 'Askr5Pv58nInUoP5nDmjeqUMxFg-9pK5RwNjSZvO6tt6UvFkPxGLJwFLYILRs3Ud'
    };
} else if (window.AEM.siteName == 'tiresplus') {
    keys = {
        dev: 'ApUWlg1gz_OPJbPnSbLwvB5uFTP9JitfD9f39EWadzRu1mdTHbLXpdP2iZVrhB0o',
        qa: 'ApUWlg1gz_OPJbPnSbLwvB5uFTP9JitfD9f39EWadzRu1mdTHbLXpdP2iZVrhB0o',
        live: 'AlfzEgRUh6fXgyb-1Af9Xn05lMg22mYmB44EfbXzOGUQXFp3qGkBzCVMEhtyLPs1'
    };
} else if (window.AEM.siteName == 'wheelworks') {
    keys = {
        dev: 'ApUWlg1gz_OPJbPnSbLwvB5uFTP9JitfD9f39EWadzRu1mdTHbLXpdP2iZVrhB0o',
        qa: 'ApUWlg1gz_OPJbPnSbLwvB5uFTP9JitfD9f39EWadzRu1mdTHbLXpdP2iZVrhB0o',
        live: 'AkwF1k34GKHnCbytAGLmD7NeMIdjIZXVp06wfF8DWdmV8pEhBa-UWzUfpoCsQUZL'
    };
} else if (window.AEM.siteName == 'hibdontire') {
    keys = {
        dev: 'ApUWlg1gz_OPJbPnSbLwvB5uFTP9JitfD9f39EWadzRu1mdTHbLXpdP2iZVrhB0o',
        qa: 'ApUWlg1gz_OPJbPnSbLwvB5uFTP9JitfD9f39EWadzRu1mdTHbLXpdP2iZVrhB0o',
        live: 'AhihbxiTKUI3fQQ7HS3WoC_tnxTjxo-cnQ8-PMQiLU3Ktf4YgkdaMG4EEH3m8XyH'
    };
}

let hostName = location.hostname;
let env = 'dev';

if (
    hostName.indexOf('bsro-qa') != -1 ||
    hostName.indexOf('bsro-fcac') != -1 ||
    hostName.indexOf('bsro-tp') != -1 ||
    hostName.indexOf('bsro-wwt') != -1 ||
    hostName.indexOf('bsro-ht') != -1 ||
    hostName.indexOf('cwh-uat') != -1
) {
    env = 'qa';
} else if (
    hostName.indexOf('firestonecompleteautocare') != -1 ||
    hostName.indexOf('tiresplus') != -1 ||
    hostName.indexOf('wheelworks') != -1 ||
    hostName.indexOf('hibdontire') != -1
) {
    env = 'live';
}

const instanceInit = (rootEl) => {
    let selState = rootEl.querySelector('.custom-select select');
    let directionBtn = rootEl.querySelector('.btn.get-direction');
    let directionZip = rootEl.querySelector('#directionsFromZip');

    directionBtn.addEventListener('click', (e) => createDirections(e, rootEl));
    directionZip.addEventListener('change', (e) => handleFieldChange(e, rootEl));
    directionZip.addEventListener('blur', (e) => handleFieldChange(e, rootEl));
    directionZip.addEventListener('keyup', (e) => handleFieldChange(e, rootEl));
    selState.addEventListener('change', (e) => handleFieldChange(e, rootEl));

    window.addEventListener('load', (e) => componentInit(rootEl));
};

const componentInit = (rootEl) => {
    console.log('inside get-directions.js');
    let map = null;

    let _latitude = rootEl.querySelector('#myMap').getAttribute('data-latitude');
    let _longitude = rootEl.querySelector('#myMap').getAttribute('data-longitude');

    let _tostate = rootEl.querySelector('#store-info')?.getAttribute('data-state');
    let _tozip = rootEl.querySelector('#store-info')?.getAttribute('data-zip');

    rootEl.querySelector('#state').value = _tostate;
    rootEl.querySelector('#directionsFromZip').value = _tozip;

    let mapOptions = {
        zoom: 8,
        credentials: keys[env],
        mapTypeId: window.Microsoft.Maps.MapTypeId.auto,
        enableSearchLogo: false
    };

    let myMapEl = rootEl.querySelector('#myMap');
    map = new window.Microsoft.Maps.Map(myMapEl, mapOptions);

    if (_latitude != null && _longitude != null) {
        map.setView({
            center: new window.Microsoft.Maps.Location(_latitude, _longitude)
        });
    }
};

const handleFieldChange = (evt, rootEl) => {
    let disabled = false;
    let _state = rootEl.querySelector('#state').value;
    let _zip = rootEl.querySelector('#directionsFromZip').value;

    if (_state == '' && _zip == '') {
        disabled = true;
    }

    rootEl.querySelector('.btn.get-direction').disabled = disabled;
};

const createDirections = (evt, rootEl) => {
    rootEl.querySelector('.btn.get-direction').disabled = true;
    setTimeout(() => {
        rootEl.querySelector('.btn.get-direction').removeAttribute('disabled');
    }, 1500);

    let directionsManager;

    let directionsItinerary = rootEl.querySelector('#directionsItinerary');
    while (directionsItinerary.firstChild) {
        directionsItinerary.removeChild(directionsItinerary.firstChild);
    }

    let _city = rootEl.querySelector('#city').value;
    let _address = rootEl.querySelector('#address').value;
    let _zip = rootEl.querySelector('#directionsFromZip').value;
    let _state = rootEl.querySelector('#state').value;

    let fromAddr = '';

    if (_address != '') {
        fromAddr = _address + ' ';
    }
    if (_city != '') {
        fromAddr = fromAddr + _city + ' ';
    }
    if (_state != '') {
        fromAddr = fromAddr + _state + ' ';
    }
    if (_zip != '') {
        fromAddr = fromAddr + _zip;
    }

    let myMapEl = rootEl.querySelector('#myMap');
    let storeInfoEl = rootEl.querySelector('#store-info');

    let _latitude = myMapEl.getAttribute('data-latitude');
    let _longitude = myMapEl.getAttribute('data-longitude');
    let _toaddress = storeInfoEl?.getAttribute('data-address');
    let _tocity = storeInfoEl?.getAttribute('data-city');
    let _tostate = storeInfoEl?.getAttribute('data-state');
    let _tozip = storeInfoEl?.getAttribute('data-zip');
    let toAddr = '';

    if (_toaddress != '') {
        toAddr = _toaddress + ', ';
    }
    if (_tocity != '') {
        toAddr = toAddr + _tocity + ', ';
    }
    if (_tostate != '') {
        toAddr = toAddr + _tostate + ' ';
    }
    if (_tozip != '') {
        toAddr = toAddr + _tozip;
    }

    let map = new window.Microsoft.Maps.Map(myMapEl, {
        credentials: keys[env]
    });

    if (!directionsManager) {
        window.Microsoft.Maps.loadModule('window.Microsoft.Maps.Directions', {
            callback: createDrivingRoute
        });
    } else {
        createDrivingRoute(rootEl);
    }

    const createDirectionsManager = (rootEl) => {
        // let displayMessage;
        if (!directionsManager) {
            directionsManager = new window.Microsoft.Maps.Directions.DirectionsManager(map);
            // displayMessage = 'Directions Module loaded\n';
        }
        directionsManager.clearAll();
        window.Microsoft.Maps.Events.addHandler(directionsManager, 'directionsError', () => {
            rootEl.querySelector('#myMap_error').style.display = '';
            myMapEl.style.display = 'none';
            rootEl.querySelector('#directionsItinerary').style.display = 'none';
            rootEl.querySelector('#myMap_printbutton').style.display = 'none';
        });
        window.Microsoft.Maps.Events.addHandler(directionsManager, 'directionsUpdated', () => {
            rootEl.querySelector('#myMap_error').style.display = 'none';
            myMapEl.style.display = '';
            rootEl.querySelector('#directionsItinerary').style.display = '';
            rootEl.querySelector('#myMap_printbutton').style.display = '';
        });
    };

    const createDrivingRoute = (rootEl) => {
        if (!directionsManager) {
            createDirectionsManager(rootEl);
        }
        directionsManager.clearAll();
        // Set Route Mode to driving
        directionsManager.setRequestOptions({
            routeMode: window.Microsoft.Maps.Directions.RouteMode.driving
        });
        let fromWaypoint = new window.Microsoft.Maps.Directions.Waypoint({
            address: fromAddr
        });
        directionsManager.addWaypoint(fromWaypoint);

        let toWaypoint = null;
        if (toAddr != null && toAddr != '') {
            toWaypoint = new window.Microsoft.Maps.Directions.Waypoint({
                address: toAddr
            });
        } else {
            toWaypoint = new window.Microsoft.Maps.Directions.Waypoint({
                location: new window.Microsoft.Maps.Location(_latitude, _longitude)
            });
        }
        directionsManager.addWaypoint(toWaypoint);
        let directionsItinerary = rootEl.querySelector('#directionsItinerary');
        // Set the element in which the itinerary will be rendered
        directionsManager.setRenderOptions({
            itineraryContainer: directionsItinerary,
            drivingPolylineOptions: {
                strokeColor: new window.Microsoft.Maps.Color(200, 255, 0, 255),
                strokeThickness: 5
            }
        });

        directionsManager.calculateDirections();
    };
};

component({
    selector: '[data-get-directions]',
    instanceInit,
    name: 'GetDirections'
});
