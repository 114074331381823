const cleanPhone = (str) => {
    var val = '';

    // need to strip out special chars and spaces
    // ex. input: (201) 654-4050
    if (str && str !== '') {
        val = str.replace('(', '').replace(')', '').replace(' ', '-');
    }

    return val;
}

export { cleanPhone };
