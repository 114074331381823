import { pubsub } from 'common/js/library/pubsub';
import { topics } from 'common/js/library/topics';

let recentlyViewedTire = (el, data) => {
    if (el && data) {
        populate(el, data);

        const reviewsSummary = Array.from(el.querySelectorAll('.reviews-app'));

        pubsub.publish(topics.CREATE_REVIEWS, [reviewsSummary]);
        pubsub.publish(topics.CREATE_BUTTONS);
    }
};

const getQuoteButton = (ctaContainer, data) => {
    let cta = ctaContainer?.querySelector('a');

    if (
        window.siteProfile &&
        window.siteProfile.vehicles &&
        window.siteProfile.vehicles.tce &&
        window.siteProfile.vehicles.tce.year &&
        window.siteProfile.vehicles.tce.make &&
        window.siteProfile.vehicles.tce.model &&
        window.siteProfile.vehicles.tce.trim
    ) {
        cta.setAttribute('data-article', data.article);
        cta.setAttribute('data-brand', data.brand);
        cta.setAttribute('data-tire-name', data.name);
        cta.setAttribute('data-tire-logo', data.logo);
        cta.setAttribute('data-tire-image', data.image);

        // Adding class to init React App
        cta.classList.add('tire-quote-btn');
    } else {
        let quoteBtn = `<a href=${data.url} class="btn btn-rounded btn-standard">Explore Tire</a>`;
        cta.remove();
        ctaContainer.innerHTML = quoteBtn;
    }
};

const populate = (el, data) => {
    let image = el.querySelector('.tire__image img');
    let brand = el.querySelector('.tire__brand img');
    let name = el.querySelector('.tire__name');
    let price = el.querySelector('.tire__price');
    let reviews = el.querySelector('.tire__review div');
    let cta = el.querySelector('.tire__cta');
    let prices = el.querySelector('.tire__prices');
    let friendlyName = data.friendlyName || data.name;
    let logo = data.logo.startsWith('http') ? data.logo : `https://s7d1.scene7.com/is/image${data.logo}`;

    if (data.hidePrice) {
        prices.classList.add('hide');
    } else {
        prices.classList.remove('hide');
    }

    image.setAttribute('src', data.image);
    brand.setAttribute('src', logo);
    name.innerHTML = `${data.brand} ${data.name}`;
    if (data.price) {
        price.innerHTML = `${data.price} ea`;
    }
    reviews.setAttribute('data-id', `${data.brand} ${friendlyName}`);
    reviews.setAttribute('data-tire-brand', data.brand);
    reviews.setAttribute('data-tire-name', data.name);

    getQuoteButton(cta, data);

    // Adding class to init React App
    reviews.classList.add('reviews-app');
};

export { recentlyViewedTire };
